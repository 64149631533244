import React from 'react';
import { Button } from 'react-bootstrap';

export default class Subscribe extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
      <div className="col-sm-4 col-12">
        <hr></hr>
        <p>Become a subscriber to gain access to all metrics.</p>
        <Button className="subBttn" href='/subscribe' >Subscribe</Button>
      </div>
		);
	}
}
