import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import LocationMap from '../../components/location-map';
import * as apiUtil from '../../lib/ApiUtil';

const validEmail = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class ContactUsPage extends Component {
	constructor (props) {
		super(props);
		this.state = {
			value: true,
			firstName: '',
			lastName: '',
			other: '',
			inputs: {
				company: '',
				email: '',
				orgType: '',
				phone: '',
				name: ''
			},
			clearTime: 5000,
			errors: {
				company: '',
				email: '',
				phone: '',
				status: ''
			}
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.resetForm = this.resetForm.bind(this);
	};

	componentDidMount() {
    let winH = window.innerHeight;
    let bodyH = document.getElementById('root').clientHeight;
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('contact').style.paddingBottom = diffH+'px';
    }
	};

	handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		let state = this.state;
		switch (name) {
			case 'company':
				state.errors.company =
				value.length < 3
					? 'A valid company name is required.'
					: '';
				state.inputs.company = event.target.value;
				state.value = true;
				break;
			case 'email':
        state.errors.email =
          validEmail.test(value)
            ? ''
            : 'A valid email is required.';
          state.inputs.email = event.target.value;
					state.value = true;
        break;
			case 'other':
				state.inputs.orgType = event.target.value;
				break;
			case 'phone':
				/* state.errors.phone = 
				value.length !== 10 && value.length !== 0
					? 'Please provide a valid number.'
					: '';
				state.value = true; */
				state.inputs.phone = event.target.value;
				
				break;
			case 'fName':
				state.firstName = event.target.value;
				break;
			case 'lName':
				state.lastName = event.target.value;
				break;
			default:
				break;
		}
		if(state.inputs.company && state.inputs.email && !state.errors.company && !state.errors.email && !state.errors.phone) {
			state.value = false;
		}
		this.setState(state);
	};

	handleClick = event => {
		const { value } = event.target;
		let state = this.state;
		state.other = '';
		switch (value) {
			case 'Hos':
				state.inputs.orgType = 'Hospital';
				break;
			case 'SNF':
				state.inputs.orgType = 'Skilled Nursing Facility';
				break;
			case 'HHO':
				state.inputs.orgType = 'Home Health Organization';
				break;
			case 'Pro':
				state.inputs.orgType = 'Professional Services';
				break;
			case 'Other':
				state.inputs.orgType = '';
				state.other = 'Other';
				break;
			default:
				break;
		}
		this.setState(state);
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		let state = this.state;
		if(state.inputs.other) {
			state.inputs.orgType = state.other;
		} else if(!state.inputs.orgType) {
			state.inputs.orgType = 'N/A';
		}
		if(!state.inputs.phone) {
			state.inputs.phone = 'N/A';
		} else if(state.inputs.phone) {
			this.formatPhone(state.inputs.phone);
		}
		if(state.firstName && state.lastName) {
			state.inputs.name = state.firstName+' '+state.lastName;
		} else if (!state.firstName && state.lastName) {
			state.inputs.name = 'Mr/Mrs '+state.lastName;
		} else if (state.firstName && !state.lastName) {
			state.inputs.name = state.firstName;
		} else {
			state.inputs.name = 'N/A';
		}
		let body = 'Company: '+state.inputs.company+', Type: '+state.inputs.orgType;

		const response = await apiUtil.APIPost("/contact-us", {
			method: "POST",
			domain: 'hmpmetrics.com',
			name: state.inputs.name,
			phone: state.inputs.phone,
			email: state.inputs.email,
			body: body,
		})
			.then(response => {
				state.value = true;
				state.errors.status = 'Thank you. Your info has been sent.';
				this.setState(state);
				this.resetForm();
			})
			.catch(error => {
				console.log(error)
				state.errors.status = 'There was an error and your message was not sent.';
				this.setState(state);
				this.resetForm();
			})
	};

	formatPhone = (phone) => {
		let state = this.state;
		let cleaned = (''+phone).replace(/\D/g, '');
		let match =  cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		state.inputs.phone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
		this.setState(state);
	};

	resetForm = () => {
		let state = this.state;
		setInterval(() => {
			state.firstName = '';
			state.lastName = '';
			state.inputs.company = '';
			state.inputs.email = '';
			state.inputs.orgType = '';
			state.inputs.phone = '';
			state.inputs.name = '';
			state.errors.status = '';
			document.getElementById('emailForm').reset();
			this.setState(state);
		}, state.clearTime);
	};

	render() {
		// console.log(this.state);
		return (
			<div id="contact">
				<Helmet>
					<title>Contact Us | HMP Metrics</title>
					<meta name="description" content="Contact HMP Metrics based in Clearwater, FL." />
				</Helmet>
				<section id="content">
					<div className="container">
						<div className="row">
							<div className="col-12 text-center">
								<h1>Contact - HMP Metrics</h1>
							</div>
						</div>
					</div>
				</section>
				<div className="section" id="contactSect">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-12">
								<form id="emailForm" onSubmit={this.handleSubmit}>
									<p id="reqMess">* Required Fields *</p>
									<label htmlFor="cName">*Company Name</label>
									<input
										type="text"
										id="cName"
										className="req"
										name="company"
										placeholder="*Company Name*"
										value={this.state.inputs.company}
										onChange={this.handleChange}
										required
									/>
									{this.state.errors.company.length > 0 && <span className="error">{this.state.errors.company}</span>}
									<label htmlFor="email">*Email</label>
									<input
										type="email"
										id="email"
										className="req"
										name="email"
										placeholder="*Email Address*"
										value={this.state.inputs.email}
										onChange={this.handleChange}
										required
									/>
									{this.state.errors.email.length > 0 && <span className="error">{this.state.errors.email}</span>}
									{/* Honey Pot - Not Sure if needed */}
									<label htmlFor="emailConf">*Confirm Email: </label>
									<input
										type="email"
										id="emailConf"
										className="req"
										name="emailConf"
										placeholder="*Confirm Email*"
										tabIndex="-1"
										value={this.state.conEmail}
										onChange={this.handleChange}
									/>
									<h6>Organization Type</h6>
									<div id="orgFrame">
										<input 
											type="radio" 
											id="hospital" 
											name="orgType" 
											value="Hos"
											onChange={this.handleClick}
										/>
										<label htmlFor="hospital" className="orgType">
											Hospital
										</label><br />
										<input 
											type="radio" 
											id="snf" 
											name="orgType" 
											value="SNF" 
											onChange={this.handleClick}
										/>
										<label htmlFor="snf" className="orgType">
											Skilled Nursing Facility
										</label><br />
										<input 
											type="radio" 
											id="hho" 
											name="orgType" 
											value="HHO" 
											onChange={this.handleClick}
										/>
										<label htmlFor="hho" className="orgType">
											Home Health Organization
										</label><br />
										<input 
											type="radio" 
											id="proServ" 
											name="orgType" 
											value="Pro" 
											onChange={this.handleClick}
										/>
										<label htmlFor="proServ" className="orgType">
											Professional Services <span>(<em>Consulting, Accounting, etc.</em>)</span>
										</label><br />
										<input 
											type="radio" 
											id="other" 
											name="orgType" 
											value="Other" 
											onChange={this.handleClick}
										/>
										<label htmlFor="other" className="orgType">
											Other
										</label>
									</div>
									{this.state.other === 'Other' ? 
										<input 
											type="text" 
											id="other" 
											name="other" 
											placeholder="Other?" 
											value={this.state.inputs.orgType}
											onChange={this.handleChange}
										/> : ''
									}
									<label htmlFor="phone">Phone</label>
									<input 
										type="tel" 
										id="phone" 
										name="phone" 
										placeholder="(555) 867-5309" 
										value={this.state.inputs.phone} 
										onChange={this.handleChange}
									/>
									{this.state.errors.phone.length > 0 && <span className="error">{this.state.errors.phone}</span>}
									<label htmlFor="fName">First Name</label>
									<input 
										type="text" 
										id="fName" 
										name="fName" 
										placeholder="First Name" 
										value={this.state.firstName}
										onChange={this.handleChange}
									/>
									<label htmlFor="lName">Last Name</label>
									<input 
										type="text" 
										id="lName" 
										name="lName" 
										placeholder="Last Name" 
										value={this.state.lastName}
										onChange={this.handleChange}
									/>
									<input 
										type="submit" 
										id="send" 
										name="send"
										value="Send" 
										disabled={this.state.value}
									/>
								</form>
								{this.state.errors.status && <p id="results">{this.state.errors.status}</p>}
							</div>
							<div className="col-md-6 col-12 text-center" id="contactFrame">
								<div id="map">
									<LocationMap
										lat={28.010965826106702}
										lng={-82.70791562225352}
										facilityName={'HMP Metrics'}
										facilityAddress={'2454 McMullen Booth Rd., Ste 411'}
										facilityCity={'Clearwater'}
										facilityState={'FL'}
										facilityZip={'33759'}
									/>
								</div>
								<address>
									<a href="/"><strong>HMP Metrics</strong></a>
									<br />
									2454 McMullen Booth Rd., Ste 411
									<br />
									Clearwater, FL 33759
									<br />
									{/* <a href="tel:+16156012109"><i className="fas fa-phone-alt"></i> (615) 601-2109</a> */}
								</address>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default class ContactUs extends React.Component {
	render() {
		return (
			<Fragment>
				<ContactUsPage />
			</Fragment>
		);
	}
}
