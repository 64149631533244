import React, { Component } from "react";
import AtRisk from "./AtRisk";
import UserInfo from "./UserInfo";
import Verify from "./Verify";
import CardInfo from "./CardInfo";
import Confirm from "./Confirm";
import * as UserUtil from '../../../lib/UserUtil';
import AuthUser from '../../Security/Auth';
import { Auth } from "aws-amplify";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      currentReport: {},
      userInfo: {},
      regError: null,
      payInfo: null,
      userData: sessionStorage.getItem('profile'),
      tempReport: {
        selectedStates: ['Florida', 'Georgia', 'South Carolina'],
        selected: 'Hospital',
        plan: 'hos',
        planName: 'At Risk',
        price: 250
      },
      tempInfo: {
        username: 'Thomas Cribb',
        firstName: 'Thomas',
        lastName: 'Cribb',
        email: 'wtcribb@gmail.com',
        phone: '3213202510',
        company: 'HMP Metrics',
        address: '111 North Street',
        city: 'Clearwater',
        state: 'FL',
        zip: '33776',
        url: 'warped-concepts.com'
      }
    };
    this.reportComplete = this.reportComplete.bind(this);
    this.onRegisterComplete = this.onRegisterComplete.bind(this);
    this.onVerifyComplete = this.onVerifyComplete.bind(this);
    this.onPayComplete = this.onPayComplete.bind(this);
  }

  componentDidMount() {
    document.body.id = 'orderReport';
    let state = this.state;
    if(sessionStorage.getItem('report') && sessionStorage.getItem('authUser')) {
      let info = JSON.parse(sessionStorage.getItem('report'));
      this.setState(state);
      this.reportComplete(info);
    }
  }

  reportComplete = info => {
    let state = this.state,
      sessionUser = AuthUser.isUserAuthenticated();
    
    state.currentReport = info;
    if(sessionUser === true) {
      state.stage = 3;
    } else {
      state.stage = 1;
    }
    this.setState(state);
  };

  /* This will probably need to be converted to async */
  async onRegisterComplete(info) {
    let state = this.state;
    try {
      await UserUtil.registerUser(info);
      state.stage = 2;
      state.userInfo = info;
    }
    catch(err) {
      state.stage = 1;
      state.regError = result.Message;
    }
    this.setState(state);
  }

  async onVerifyComplete(code) {
    let state = this.state;
    try {
      let result = await UserUtil.confirmRegistration({userId: this.state.userInfo.email, key: code});

      if(result && result.Error) {
        state.stage = 2;
        state.regError = result.Message;
      } else {
        // Update User Profile and Token Here 
        await Auth.signIn( this.state.userInfo.email,  this.state.userInfo.password);
        await AuthUser.resetSession();
        state.stage = 3;
      }
    }
    catch(err) {
      state.stage = 2;
      state.regError = err.message;
    }
    this.setState(state);
  }

  onPayComplete = info => {
    let state = this.state;
    state.stage = 4;
    state.payInfo = info;
    this.setState(state);
  };

  render() {
    const AtRiskPage = 
      <AtRisk 
        reportComplete={this.reportComplete} 
      />;
    const UserPage =
      <UserInfo 
        currentReport={this.state.currentReport}
        regError={this.state.regError}  
        onRegister={this.onRegisterComplete} 
      />;
    const VerifyPage = 
      <Verify 
        onVerify={this.onVerifyComplete} 
        regInfo={this.state.userInfo} 
        regError={this.state.regError} 
      />
    const CardPage = 
      <CardInfo 
        currentPlan={this.state.currentReport} 
        regInfo={this.state.tempInfo} 
        onPay={this.onPayComplete} 
      />;
    const ConfirmPage = 
      <Confirm 
        currentReport={this.state.currentReport} 
        userInfo={this.state.userInfo} 
      />;
    let currPage = null;
    
    switch(this.state.stage) {
      case 1:
        currPage = UserPage;
        break;
      case 2:
        currPage = VerifyPage;
        break;
      case 3:
        currPage = CardPage;
        break;
      case 4:
        currPage = ConfirmPage;
        break;
      default: 
        currPage = AtRiskPage;
    }
    return (
      <>
        {currPage}
      </>
    );
  }
}

export default(Reports);