import React from 'react';
import * as api from '../../../lib/ApiUtil';
import * as misc from '../../../lib/misc';
import './map.css';
const BuildHeaderSection = (title, key, data) => {
	if (!data) return null;
	let options = data.find(x => {
		return x.name == key;
	});

	let rows = [];
	let totalCount = 0;

	if (options) {
		for (let i = 0; i < options.values.length; i++) {
			let item = options.values[i];
			totalCount += misc.toInt(item.count);
		}

		return (
			<h5>
				{title}: <span className="text-primary">{totalCount}</span>
			</h5>
		);
	} else return null;
};

const BuildListSection = (title, key, data) => {
	if (!data) return null;
	let options = data.find(x => {
		return x.name == key;
	});

	let rows = [];

	if (options) {
		for (let i = 0; i < options.values.length; i++) {
			let item = options.values[i];
			rows.push(
				<li>
					<label>
						{item.value}:<span className="text-primary ml-1">{item.count}</span>
					</label>
				</li>
			);
		}

		return (
			<div>
				<h5>{title}</h5>
				<ul>{rows}</ul>
			</div>
		);
	} else return null;
};
const GetData = (key, data) => {
	if (!data) return null;

	let options = data.find(x => {
		return x.name == key;
	});

	return options ? options.values : [];
};

export default class TooltipState extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			key: misc.Guid()
		};
	}
	GetStateData() {
		api
			.APIPost('/search-state', {
				state: this.props.state
			})
			.then(
				data => {
					let state = this.state;
					state.data = data.data.result.Options;
					state.key = misc.Guid();
					this.setState(state);
				},
				err => {}
			);
	}

	UNSAFE_componentWillMount() {
		this.GetStateData();
	}
	render() {
		const Series = [
			{
				xAxis: 'name',
				YAxis: 'money',
				yLabel: '% Change',
				type: 'Pie',
				name: 'value'
			}
		];

		return (
			<div className="card  " style={{ width: '500px', height: '430px' }}>
				<div className="card-header">
					<h4>{this.props.state_name}</h4>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-6">
							{BuildHeaderSection('Hospitals', 'prvdr_num', this.state.data)}
							{BuildListSection(
								'Geographic Classification',
								'urban_rural_indicator',
								this.state.data
							)}
							{BuildListSection('Hospital Type', 'hosp_type', this.state.data)}
						</div>
						<div className="col-6">
							{BuildListSection('Ownership Classification', 'ownership_type', this.state.data)}
							{BuildListSection('Teaching Status', 'final_teach_status', this.state.data)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
