import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../../tools/datagrid';
import { snfPatientFlows } from '../../Common/SkilledNursingActions';
import * as misc from '../../../lib/misc';
import _ from 'lodash';
import LoadingSpinner from '../../../tools/loadingSpinner';
import NoData from '../../../core/components/notifications/NoData';

const mapStateToProps = store => {
	return {
		fetched: store.snfSMDPatientFlow.fetched,
		fetching: store.snfSMDPatientFlow.fetching,
		data: store.snfSMDPatientFlow.data
	};
};
const mapDispatchToProps = dispatch => {
	return {
		GetSMDPatientPatterns: snfProvider => dispatch(snfPatientFlows(snfProvider, 'snf'))
	};
};
class PatientMigration extends React.Component {
	constructor(props) {
		super();
		// this.state = {
		// 	facilityId: this.props.facilityId
		// };
		// this.setState(this.state);
		// this.InitializeData();
	}

	componentDidMount() {
		this.InitializeData();
	}

	InitializeData() {
		if (!this.props.fetching && !this.props.fetched) {
			this.props.GetSMDPatientPatterns(this.props.facilityId || misc.getFacilityIds());
		}
	}

	GenerateColumns(max) {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'fac_name',
				MHGrid.DataGridHeaderBuilder('Skilled Nursing Facilities', 'center', 350),
				'350',
				MHGrid.ColType_Text
			)
		);
		let yr = max || misc.getFacilityYear();
		for (let i = 4; i >= 0; i--) {
			Cols.Add(
				new MHGrid.DataGridColumn(
					yr - i,
					new MHGrid.DataGridHeader(yr - i, '', '', 'right'),
					'150',
					MHGrid.ColType_Number
				)
			);
		}
		Cols.Add(
			new MHGrid.DataGridColumn(
				'row_total',
				new MHGrid.DataGridHeader('Total', '', '', 'right'),
				'150',
				MHGrid.ColType_Number
			)
		);

		return Cols;
	}

	render() {
		let facilityInfo = misc.getFacilityInfo();
		let clmns =
			this.props.fetched && this.props.data
				? this.GenerateColumns(_.maxBy(this.props.data.rows, 'fy_year').fy_year)
				: null;
		let data =
			this.props.fetched && this.props.data
				? _.orderBy(
						misc.PivotDataGroup(this.props.data.rows, 'fy_year', 'discharges', 'fac_name'),
						['row_total'],
						['asc']
				  ).map(a => {
						if (_.toLower(a.fac_name) == _.toLower(facilityInfo.facility_name)) {
							a.fieldStyle = 'LINE_SHADE';
						}
						return a;
				  })
				: [];
		return (
			<div className="row-no-pad">
				<div className="d-flex">
					<h4 className="tblHead text-center">
						{' Medicare Patient Flows for ' +
							(this.props.facilityInfo.county || 'Facility') +
							' County'}
						<i
							className="ml-2 MH-InfoTooltip py-auto mt-auto pb-1 mb-2 fa fa-xs fa-info-circle mr-auto"
							aria-hidden="true"
							data-toggle="popover"
							data-trigger="hover"
							data-content={
								' Medicare Patient Flows for patients that originated from  ' +
									this.props.facilityInfo.county ||
								'Facility County' + ' or admitted to a Skilled Nursing Facility in that County'
							}
						/>	
					</h4>
					
				</div>
				<div>
					<MHGrid.DataGrid
						// suppressLines={true}
						columns={clmns}
						rows={data}
						showHeaders={true}
					/>
					{this.props.fetched && !this.props.data.rows ? (
						<NoData />
					) : this.props.fetching ? (
						<LoadingSpinner />
					) : (
						''
					)}
				</div>{' '}
			</div>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PatientMigration);
