// The middleware to call the API for quotes
// import { CALL_API } from '../middleware/api';
import axios from 'axios';
import { getCustomHeader } from '../../lib/ApiUtil';

import * as misc from '../../lib/misc';

// There are three possible states for our login
// process and we need actions for each of them
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export function loginUser(creds) {
	let header = getCustomHeader();
	return {
		type: 'USER_LOGIN_FETCH',
		payload: axios.post(misc.svrUrl + '/auth/login', { creds })
	};
}
