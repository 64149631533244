import * as ApiUtil from './ApiUtil'


export const registerUser = async (params) => {
    
    try 
    {
        let result = await ApiUtil.UserPost('/create-user', params);
        if(result && result.Error)
        {
            throw result.Message;
        }
        return result.data.result;
    }
    catch(err)
    {
        throw err;
    }
}

export const confirmRegistration = async (params) => {
    
    try 
    {
        let result = await ApiUtil.UserPost('/confirm-registration', params);

        if(result && result.Error)
        {
            throw result.Message;
        }
        return result.data;
    }
    catch(err)
    {
        throw err;
    }
}

export const updateUserProfile = async (params) => {
    
    try 
    {
        let result = await ApiUtil.UserPost('/update-user', params);

        if(result && result.Error)
        {
            throw result.Message;
        }
        return result.data;
    }
    catch(err)
    {
        throw err;
    }
}

export const sendConfirmationCode = async (params) => {
    
    try 
    {
        let result = await ApiUtil.UserPost('/SendConfirmationCode', params);

        if(result && result.Error)
        {
            throw result.Message;
        }
        return result.data;
    }
    catch(err)
    {
        throw err;
    }
}

export const saveSearchFilter = async (params) => {
    
    try 
    {
        let result = await ApiUtil.UserPost('/user-filter-add', params);

        if(result && result.Error)
        {
            throw result.Message;
        }
        return result.data;
    }
    catch(err)
    {
        throw err;
    }
}

export const getSavedFilters = async () => {
    
    try 
    {
        let result = await ApiUtil.UserPost('/user-filters');

        if(result && result.Error)
        {
            throw result.Message;
        }
        return result.data;
    }
    catch(err)
    {
        throw err;
    }
}

export const deleteSavedFilter = async (params) => {
    
    try 
    {
        let result = await ApiUtil.UserPost('/user-filter-delete', params);

        if(result && result.Error)
        {
            throw result.Message;
        }
        return result.data;
    }
    catch(err)
    {
        throw err;
    }
}