import * as Types from '../Common/ActionTypes';
import { ReducerHelper, DefaultState } from '../../core/reducers/ReducerHelper';
import BenchmarkUtil from '../../lib/BenchmarkUtil';
import * as misc from '../../lib/misc';

export const snfStrategicMarketCompetitorReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.SNF_SMD_COMPETITOR_METRICS, state, action);
};

const snfCompetitorList = {
	fetching: false,
	fetched: false,
	providers: [],
	Benchmark: [],
	error: null,
	whereChanged: false,
	dataKey: null
};

export const snfCompetitorListReducer = (state = snfCompetitorList, action) => {
	switch (action.type) {
		case Types.SNF_COMPETITOR_LIST + '_PENDING': {
			return { ...state, fetching: true, fetched: false, Providers: [] };
		}
		case Types.SNF_COMPETITOR_LIST + '_FULFILLED': {
			const data = new BenchmarkUtil(action.payload.data.result.data);
			return {
				...state,
				fetching: false,
				fetched: true,
				providers: action.payload.data.result.providers,
				Benchmark: data,
				dataKey: misc.Guid()
			};
		}
		case Types.SNF_COMPETITOR_LIST + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};

const snfSMDMarketshare = {
	fetching: false,
	fetched: false,
	mdcMap: [],
	refHosp: [],
	mdcYear: [],
	mktShare: []
};

export const snfSMDMarketshareReducer = (state = snfSMDMarketshare, action) => {
	switch (action.type) {
		case Types.SNF_SMD_MARKETSHARE + '_PENDING': {
			return {
				...state,
				fetching: true,
				fetched: false,
				refHosp: [],
				mdcMap: [],
				mdcYear: [],
				mktShare: []
			};
		}
		case Types.SNF_SMD_MARKETSHARE + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				mdcMap: action.payload.data.data.mdcMap,
				refHosp: action.payload.data.data.refHosp,
				mdcYear: action.payload.data.data.mdcYear,
				mktShare: action.payload.data.data.mktShare
			};
		}
		case Types.SNF_SMD_MARKETSHARE + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};

const snfPatientFlow = { fetching: false, fetched: false, data: [] };

export const snfSMDPatientFlowReducer = (state = snfPatientFlow, action) => {
	switch (action.type) {
		case Types.SNF_SMD_PATIENT_FLOW + '_PENDING': {
			return { ...state, fetching: true, fetched: false, data: [] };
		}
		case Types.SNF_SMD_PATIENT_FLOW + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				data: action.payload.data.data
			};
		}
		case Types.SNF_SMD_PATIENT_FLOW + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};

export const snfHospCompetitorDischarges = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.SNF_HOSP_DISCHARGES, state, action);
};
