import React from 'react';
import { connect } from 'react-redux';
import { fetchQualityRating } from './SummaryAction';
import FacilityRatingPopup from './FacilityRatingPopup';
import * as misc from './../../lib/misc';

const ratingStyle = {
	paddingTop: '40px',
	fontSize: '64px',
	color: '#fff',
	fontWeight: 400,
	fontFamily: 'Roboto',
	height: '100%',
	width: '100%'
};

const containerStyle = {
	//	marginTop: '10px',
	height: '100%',
	width: '100%',
	cursor: 'pointer'
};

const borderTile = {
	border: 'solid',
	borderColor: '#fff',
	borderWidth: '1px',
	margin: 'auto'
};

const mapStateToProps = store => {
	return {
		qualityRating: store.qualityRating.qualityRating,
		whereChanged: store.qualityRating.whereChanged
	};
};

class FacilityRating extends React.Component {
	constructor(props) {
		super(props);
		this.state = { showModal: false, selectionIndex: 0 };
		this.onCloseClick = this.onCloseClick.bind(this);
		this.onSection1Click = this.onSection1Click.bind(this);
		this.onSection2Click = this.onSection2Click.bind(this);
		this.onSection3Click = this.onSection3Click.bind(this);
		this.onSection4Click = this.onSection4Click.bind(this);
		this.onSection5Click = this.onSection5Click.bind(this);
	}
	onCloseClick() {
		this.setState({ showModal: false });
	}
	onSection1Click(e) {
		this.setState({ showModal: true, selectionIndex: 0 });
	}
	onSection2Click(e) {
		this.setState({ showModal: true, selectionIndex: 1 });
	}
	onSection3Click(e) {
		this.setState({ showModal: true, selectionIndex: 2 });
	}
	onSection4Click(e) {
		this.setState({ showModal: true, selectionIndex: 3 });
	}
	onSection5Click(e) {
		this.setState({ showModal: true, selectionIndex: 4 });
	}
	formatVal(val) {
		return val ? Math.ceil(parseFloat(val).toFixed(0)) : val;
	}

	componentDidMount() {
		this.props.dispatch(fetchQualityRating(this.props.facilityId));
	}

	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.props.dispatch(fetchQualityRating(this.props.facilityId));
		}
	}

	createRankingTile(rptScore, labelCategory) {
		let score = this.formatVal(rptScore);
		let bgColor = 'bg-dark';
		let foreColor = 'text-white';

		return (
			<div className={bgColor} style={ratingStyle}>
				{score ? score + '' : '-'}
				<h5 className={foreColor} style={{ fontWeight: '300' }}>
					{labelCategory}{' '}
					<small>
						<i className="fa fa-info-circle" aria-hidden="true" style={{ color: 'white' }} />
					</small>
				</h5>
			</div>
		);
	}

	render() {
		let modalKey = misc.Guid();
		let modalGuid = '#' + modalKey;
		return (
			<div className="col-lg-4 col-md-4 col-sm-12" id="groupPerc">
				<h5 id="gpHeading">
					Peer Group Percentile Ranking{' '}
						<small>
							<i
								className="MH-InfoTooltip fa fa-info-circle text-dark"
								aria-hidden="true"
								data-toggle="modal"
								data-target={'#' + modalKey}
							/>
						</small>
				</h5>
				<div className="container-fluid">
					<div
						style={{
							textAlign: 'center',
							height: '100%',
							width: '100%',
							position: 'relative'
						}}
					>
						<div style={containerStyle}>
							<div className="row m-0 p-0" style={{}}>
								<div
									className="col m-0 p-0"
									style={{ ...borderTile }}
									onClick={this.onSection1Click}
									data-toggle="modal"
									data-target={modalGuid}
								>
									{this.createRankingTile(this.props.qualityRating.financial_score, 'Financial')}
								</div>
								<div
									className="col m-0 p-0"
									style={{ ...borderTile }}
									onClick={this.onSection2Click}
									data-toggle="modal"
									data-target={modalGuid}
								>
									{this.createRankingTile(this.props.qualityRating.quality_score, 'Quality')}
								</div>
							</div>
							<div className="row m-0 p-0" style={{}}>
								<div
									className="col m-0 p-0"
									style={{ ...borderTile }}
									onClick={this.onSection3Click}
									data-toggle="modal"
									data-target={modalGuid}
								>
									{this.createRankingTile(this.props.qualityRating.operations_score, 'Operations')}
								</div>
								<div
									className="col m-0 p-0"
									style={{ ...borderTile }}
									onClick={this.onSection4Click}
									data-toggle="modal"
									data-target={modalGuid}
								>
									{this.createRankingTile(this.props.qualityRating.strategy_score, 'Strategy')}
								</div>
							</div>
						</div>
						<FacilityRatingPopup selectionIndex={this.state.selectionIndex} modalGuid={modalKey} />
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(FacilityRating);
