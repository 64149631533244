import React, { Fragment } from "react";
import { connect } from "react-redux";
import * as misc from "../../../lib/misc";
import * as GlobalActions from "../../actions/GlobalActions";
import AutoSuggestBox from "./auto-suggest";
import "../navigation/index.css";
import "./index.css";

const mapStateToProps = ({ providers: { providers = {} } = {} }) => {
  return {
    providers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ResetStore: () => dispatch(GlobalActions.ResetStore()),
    GetProviderInfo: (domain, facility) => {
      dispatch(GlobalActions.fetchProviderInfo(domain, facility));
    },
  };
};
class searchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttons: this.props.buttons,
      redirect: false,
      redirectPath: "",
      isHome: this.props.isHome
    };

    /* Creating an error - 'Cant setState on Component that is not yet mounted */
    // this.setState(this.state);
    // console.log(this.props);
    this.redirectPage = this.redirectPage.bind(this);
  }
  
  redirectPage(prvdr_rec) {
    this.props.ResetStore();
    misc.clearFOIandCompetitors();

    if (prvdr_rec) {
      let url = "/hospital/summary/";
      switch (prvdr_rec.domain) {
        case "snf":
          url = "/skillednursing/summary/";
          break;
        case "hha":
          url = "/homehealth/summary/";
          break;
        default:
        case "hosp":
          url = "/hospital/summary/";
          break;
      }
      let targetURL = url + prvdr_rec.prvdr_num;
      window.location = misc.baseUrl + targetURL;
    }
  }
  
  render() {
    const { redirect, redirectPath, source } = this.state;
    const { value, suggestions } = this.state;
    let renderer = "";

    if (this.state.buttons == "small" && window.location.pathname == "/")
      renderer = "";
    else {
      renderer = (
        <AutoSuggestBox
          buttons={this.props.buttons ? this.props.buttons : "default"}
          className='w-100'
          redirectCallback={
            this.props.onSelect ? this.props.onSelect : this.redirectPage
          }
          isHome={this.state.isHome}
        />
      );
    }
    return renderer;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(searchBar);
