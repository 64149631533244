import axios from 'axios';
import Auth from '../Security/Auth';
import * as Types from './ActionTypes';
import * as whereBuilder from '../../lib/whereBuilder';
import fileDownload from 'js-file-download';
import * as misc from '../../lib/misc';
const apiUrl = misc.apiUrl;

const fields = [];

const getCustomHeader = function() {
	return {
		headers: { token: Auth.getToken() }
	};
};

export function fetchBenchmarkData(providers, fieldlist) {
	let filterwhere = JSON.parse(sessionStorage.getItem('where'));

	if (fieldlist) {
		return {
			type: Types.BENCHMARK_DATA_GET + '_FETCH',
			payload: axios.post(
				apiUrl + '/benchmark-data',
				{ fields: fieldlist, providers, filter: filterwhere },
				getCustomHeader()
			)
		};
	} else {
		return {
			type: Types.BENCHMARK_DATA_GET + '_FETCH',
			payload: axios.post(
				apiUrl + '/benchmark-data',
				{ fields, providers, filter: filterwhere },
				getCustomHeader()
			)
		};
	}
}

export function fetchBenchmarkDataExcel(
	providers,
	fieldlist,
	fileName = 'benchmark-data.xlsx',
	segment
) {
	let filterwhere = JSON.parse(sessionStorage.getItem('where'));
	let postData = {};
	if (fieldlist) {
		postData = {
			fields: fieldlist,
			providers,
			filter: filterwhere,
			domain: segment
		};
	} else {
		postData = { fields, providers, filter: filterwhere, domain: segment };
	}
	return {
		type: Types.BENCHMARK_DATA_EXCEL + '_FETCH',
		payload: axios
			.post(
				apiUrl + '/report-benchmark-data',
				postData,
				{ responseType: 'blob', timeout: 180000 },
				getCustomHeader()
			)
			.then(response => {
				fileDownload(response.data, fileName);
			})
	};
}
