import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/fontawesome-free-solid';
import * as MHGrid from '../../../tools/datagrid';
import * as misc from '../../../lib/misc';
import * as fmt from '../../../lib/FormatUtil';
import LoadingSpinner from '../../../tools/loadingSpinner';
import { Sankey } from 'react-vis';
import _ from 'lodash';
import '../../../styles.css';

const mapStateToProps = store => {
	return {
		fetched: store.snfSMDMarketshareData.fetched,
		fetching: store.snfSMDMarketshareData.fetching,
		mdcYear: store.snfSMDMarketshareData.mdcYear,
		mdcMap: store.snfSMDMarketshareData.mdcMap
	};
};
const mapDispatchToProps = () => {
	return {
		// GetMarketshareData:snfProvider =>
		// 	dispatch(snfMarketshare(snfProvider,'snf'))
	};
};
class DischargePatterns extends React.Component {
	constructor() {
		super();

		this._element = React.createRef();
	}

	componentDidMount() {
		this.setState({
			width: this._element.current.parentNode.parentNode.clientWidth,
			activeLink: null
		});
	}

	UpdateData(data, columns, field) {
		let nodes = [];
		let links = [];

		for (let i = 0; i < data.length; i++) {
			if (
				_.findIndex(nodes, {
					name: data[i][columns[0]]
				}) < 0
			) {
				nodes.push({
					name: data[i][columns[0]]
				});
			}
			for (let j = 1; j < columns.length; j++) {
				if (
					_.findIndex(nodes, {
						name: data[i][columns[j]]
					}) < 0
				) {
					nodes.push({
						name: data[i][columns[j]]
					});
				}
				let indxA = _.findIndex(nodes, {
					name: data[i][columns[j - 1]]
				});
				let indxB = _.findIndex(nodes, {
					name: data[i][columns[j]]
				});
				links.push({
					source: indxA,
					target: indxB,
					value: data[i][field],
					tag: data[i][columns[0]]
				});
			}
		}
		return {
			nodes: nodes,
			links: links
		};
	}

	CreateColumnsForDischargePatterns() {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'snf_provider',
				MHGrid.DataGridHeaderBuilder('Provider', 'center', 50),
				'50',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'snf_name',
				MHGrid.DataGridHeaderBuilder('Skilled Nursing Facilities', 'center', 350),
				'350',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'distance',
				MHGrid.DataGridHeaderBuilder('Distance (Mi)', 'center', 90),
				'90',
				MHGrid.ColType_Number
			)
		);
		let yr = misc.getFacilityYear();
		for (let i = 4; i >= 0; i--) {
			Cols.Add(
				new MHGrid.DataGridColumn(
					yr - i,
					new MHGrid.DataGridHeader(yr - i, '', '', 'right'),
					'120',
					MHGrid.ColType_Self
				)
			);
		}
		Cols.Add(
			new MHGrid.DataGridColumn(
				'ALL',
				new MHGrid.DataGridHeader('Average', '', '', 'right'),
				'120',
				MHGrid.ColType_Self
			)
		);
		return Cols;
	}

	DischargePatternGroups(data) {
		if (data && data.rows) {
			let results = [];
			let clmns = this.CreateColumnsForDischargePatterns();
			let groups = _.uniq(data.rows.map(a => a['provider']));
			let hospNames = _.uniq(data.rows.map(a => a['hosp_provider_name']));
			var data_groups = _.mapValues(_.groupBy(data.rows, 'provider'), items =>
				items.map(a => _.omit(a, 'provider'))
			);
			for (let i = 0; i < groups.length; i++) {
				let id = groups[i];
				let snfData = _.values(
					_.mapValues(
						_.merge(
							_.groupBy(
								data_groups[id].map(a =>
									_.set(
										{
											snf_name: a.snf_provider_name,
											snf_provider: a.snf_provider,
											distance: a.facility_distance
										},
										a.fy_year,
										{
											value: a.cases
										}
									)
								),
								'snf_provider'
							)
						),
						items => _.assign.apply(_, items)
					)
				);
				results.push(
					<a className="expLink" href={misc.baseUrl + '/hospital/summary/' + id} target="_blank">
						{hospNames[i] + ' | ' + id}
						<FontAwesomeIcon className={'ml-2 fa-xs my-auto'} icon={faExternalLinkAlt} />
					</a>
				);
				results.push(<MHGrid.DataGrid columns={clmns} rows={snfData} showHeaders={true} />);
			}
			return results;
		} else {
			return <LoadingSpinner />;
		}
	}

	render() {
		let codeit = this.DischargePatternGroups(this.props.mdcYear);
		let mapper =
			this.props.mdcMap && this.props.mdcMap.rows && this.props.mdcMap.rows.length > 0
				? this.UpdateData(
						this.props.mdcMap.rows,
						['hosp_provider_name', 'description', 'snf_provider_name'],
						'cases'
				  )
				: null;
		let _width = this.state && this.state.width ? this.state.width : '80vw';
		let _height = this.state && this.state.width ? this.state.width * 0.3 : '30vh';
		let elementItem =
			this._element.current != null && this._element.current.parentNode.parentNode.clientWidth > 0
				? this._element.current.parentNode.parentNode.clientWidth / 30
				: 100;
		return (
			<Fragment>
				<h4 className="tblHead" ref={this._element}>
					{'Medicare Discharges to Skilled Nursing Facilities By Disease State - 3 Yr Summary'}
					<i
						className="ml-2 MH-InfoTooltip py-auto mt-auto pb-1 mb-2 fa fa-xs fa-info-circle mr-auto"
						aria-hidden="true"
						data-toggle="popover"
						data-trigger="hover"
						data-content={
							'This diagram takes the most recent 3 years of Medicare Hospital discharges to Skilled Nursing Facilities and separates patients by disease state and subsequently admitting facility. Note: Hospitals were selected based on the 5 largest sources of SNF discharges closest to the selected Skilled Nursing Facility'
						}
					/>
				</h4>
				{this.props.fetching ? <LoadingSpinner /> : ''}
				<Sankey
					nodes={
						mapper != null
							? mapper.nodes.map(a => {
									return {
										name: String(a.name).substring(0, elementItem)
									};
							  })
							: []
					}
					links={
						mapper != null
							? mapper.links.map(d => {
									return {
										...d,
										opacity:
											this.state && this.state.activeLink && this.state.activeLink.tag === d.tag
												? 0.8
												: 0.2
									};
							  })
							: []
					}
					height={_height}
					width={_width}
					onLinkMouseOver={node =>
						this.setState({
							activeLink: node
						})
					}
					onLinkMouseOut={() =>
						this.setState({
							activeLink: null
						})
					}
					nodePadding={10}
					style={{
						labels: {
							'fontSize': '14px'
						}
					}}
				/>
				<div className="row mx-2" style={{ position: 'relative', top: '-30px' }}>
					<div className="col-md-4 border-top border-dark" style={{ fontSize: '10pt' }}>
						<b>{'Legend: '}</b>
						{' Discharging Hospital'}
					</div>
					<div className="col-md-4 border-top border-dark text-center" style={{ fontSize: '10pt' }}>
						Patient Major Diagnostic Code
					</div>
					<div className="col-md-4 border-top border-dark text-right" style={{ fontSize: '10pt' }}>
						Receiving Skilled Nursing Facility
					</div>
				</div>
				<div className="row-no-pad pull-right pr-3" style={{ position: 'relative', top: '-30px' }}>
					<small>Note: Volumes less than 10 suppressed to protect patient privacy</small>
				</div>
				<h4 className="tblHead clearfix">
					{'Medicare Discharges from Hospital to Skilled Nursing Facilities'}
					<i
						className="ml-2 MH-InfoTooltip py-auto mt-auto pb-1 mb-2 fa fa-xs fa-info-circle mr-auto"
						aria-hidden="true"
						data-toggle="popover"
						data-trigger="hover"
						data-content={
							'These tables outline the patient flows from the selected hospital - to the Skilled Nursing facility based on Medicare LDS Inpatient & Skilled Nursing claims. Note: All Volumes < 10 are redacted for privacy'
						}
					/>
				</h4>
				<div className="disTbls">
					{codeit}
				</div>
			</Fragment>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DischargePatterns);
