import React, { Component } from "react";
import react from 'react';
import { Auth } from "aws-amplify";
import zxcvbn from "zxcvbn";
import AuthUser from "../Security/Auth";

export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.buyLicense = this.buyLicense.bind(this);
  };

  buildHeader = () => {
    let header = Object.keys(this.props.licenses[0]);
    return header.map((key, index) => {
      if(key === 'Description') {
        return (<></>);
      } else {
        return (
          <th key={index}>{key}</th>
        );
      }
    });
  };

  getLicenses = () => {
    let info = this.props;
    if(info.licenses[0].Product !== null) {
      return info.licenses.map((value, index) => {
        let tempProd = info.licenses[index].Product,
        options = {year: 'numeric', month: 'numeric', day: 'numeric'},
        start = info.licenses[index].Started,
        newStart = new Date(start).toLocaleDateString([], options),
        end = info.licenses[index].Expires,
        newEnd = new Date(end).toLocaleDateString([], options),
        active = info.licenses[index].Status;
        if(tempProd === 'PREMIUM') {
          tempProd = 'Premium';
        } else if(tempProd === 'SNF') {
          tempProd = 'Skilled Nursing Facility';
        } else if(tempProd === 'HOSP') {
          tempProd = 'Hospital';
        }
        return (
          <tr key={index} className={active === 'Active' ? 'yes' : 'no'}>
            <td>{tempProd}{active === 'Active' ? <i className="fas fa-info-circle"><span>{info.licenses[index].Description}</span></i> : <i className="fas fa-info-circle"><span>{info.licenses[index].Description}</span></i>}</td>
            <td>{newStart}</td>
            <td>{newEnd}</td>
            <td>{info.licenses[index].Price ? <>${info.licenses[index].Price}</> : 'Promo'}</td>
            <td className={active === 'Active' ? 'yes' : 'no'}>{active}</td>
          </tr>
        );
      });
    }
  };

  buyLicense = e => {
    e.preventDefault();
    window.location.href = '/subscribe';
    // console.log('buy');
  };

  render() {
    return (
      <div id="licenseManager">
        <h3>License Manager</h3>
        <div id="tableFrame">
          {this.props.licenses[0].Product !== null ?
            <input 
              type="submit" 
              className="loginBttn" 
              id="renew" 
              name="renew" 
              value={this.props.licenses.length > 1 ? "Renew Licenses" : "Renew License"} 
              onClick={this.buyLicense} 
            />
          :
          <input 
            type="submit" 
            className="loginBttn" 
            id="buy" 
            name="buy" 
            value='Buy License' 
            onClick={this.buyLicense} 
          />
          }
          <table>
            {this.props.licenses[0].Product ?
              <caption style={{captionSide:"top"}}>Licenses: <span>{this.props.licenses.length}</span></caption>
            :
              ''
            }
            <thead>
              <tr>
                {this.buildHeader()}
              </tr>
            </thead>
            <tbody>
              {this.getLicenses()}
            </tbody>
          </table>
        </div>
        
      </div>
    );
  }
}