import React, { Fragment } from 'react';
import DropDownCtrl from './search-drop-ctrl';
import SectionLabel from './section-label';
import * as api from '../../../lib/ApiUtil';
import * as misc from '../../../lib/misc';

// import './index.css';

const getList = list => {
	return list ? list : [];
};

export default class FilterDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			options: { snf: [] },
			filters: props.filters || [],
			key: misc.Guid(),
			most_recent_report: false,
			values: {
				state_name: [],
				cbsa_name: [],
				city_name: [],
				system: [],
				provider_subtype: [],
				ownership_type: [],
				facility_type: [],
				urban_rural_indicator: [],
				bed_cohort: [],
				net_patrev_cohort_id: [],
				net_income_cohort_id: [],
				fy_year: [],
				overall_rating: []
			}
		};
		this.onFldChange = this.onFldChange.bind(this);
		this.onReset = this.onReset.bind(this);
		this.onApply = this.onApply.bind(this);
		this.onMostRecentReportChange = this.onMostRecentReportChange.bind(this);
		this.loadSearchOptions(this.state.filters);
	}

	componentDidMount() {
		if(this.props.regFunction) {
			this.props.regFunction('snf', this);
		}
	}

	loadSearchOptions(filters) {
		api.APIPost('/search-options', { domain: 'snf', filters: filters }).then(
			data => {
				let state = this.state;
				state.options = data.data.result;
				state.key = misc.Guid();
				this.initFilters(state);
				this.setState(state);
			},
			err => {}
		);
	}

	initFilters(state) {
		if(state.filters && Array.isArray(state.filters))
		{
			state.filters.forEach(e => {
				if(e.values && Array.isArray(e.values))
				{
					state.values[e.key] = e.values.map(x => { return { key: e.key, value: x, label: x }});
				}
			});
		}
	}

	onMostRecentReportChange(e) {
		let checked = e.target.checked;
		let state = this.state;

		if (checked) {
			// Remove FY_from Filters
			state.filters.push({
				key: 'most_recent_report',
				domain: 'snf',
				values: [true]
			});

			state.values.fy_year = [];

			let idx = state.filters.findIndex(x => {
				return x.key == 'fy_year';
			});
			if (idx >= 0) {
				state.filters.splice(idx, 1);
			}
			state.most_recent_report = true;
		} else {
			state.most_recent_report = false;

			let idx = state.filters.findIndex(x => {
				return x.key == 'most_recent_report';
			});
			if (idx >= 0) {
				state.filters.splice(idx, 1);
			}
		}
		this.setState(state);

		if (!this.props.model) {
			if (this.props.onFilterChanged) this.props.onFilterChanged(this.state.filters);
		}
	}

	onFldChange(key, domain, e) {
		let state = this.state;
		let opt = null;
		let optIdx = -1;

		state.values[key] = e;

		// Check to see if filter is already set
		for (let i = 0; i < state.filters.length; i++) {
			if (state.filters[i].key == key) {
				opt = state.filters[i];
				optIdx = i;
				break;
			}
		}
		if (opt) {
			// Filter is already selected
			if (e && e.length > 0) {
				opt.values = e.map(e => {
					return e.value;
				});
			} else {
				state.filters.splice(optIdx, 1);
			}
		} else if (e && e.length > 0) {
			state.filters.push({
				key: e && e.length > 0 ? e[0].key : '',
				domain: domain,
				values: e.map(e => {
					return e.value;
				})
			});
		}
		state.key = misc.Guid();
		this.setState(state);

		if (!this.props.model) {
			if (this.props.onFilterChanged) this.props.onFilterChanged(this.state.filters);
		}
	}

	centerSNFEdits(ctrWidth) {
		return (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="facility_type"
					label="Facility Type"
					placeholder="Select ..."
					options={this.state.options.snf['facility_type']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.facility_type}
				/>
			</Fragment>
		);
	}
	leftSideSNFEdits(ctrWidth) {
		return (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="provider_subtype"
					label="Provider Type"
					placeholder="Select ..."
					options={this.state.options.snf['provider_subtype']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.provider_subtype}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="ownership_type"
					label="Ownership Type"
					placeholder="Select ..."
					options={this.state.options.snf['ownership_type']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.ownership_type}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="bed_cohort"
					label="Bed Size"
					placeholder="Select ..."
					options={this.state.options.snf['bed_cohort']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.bed_cohort}
				/>
			</Fragment>
		);
	}

	rightSideSNFEdits(ctrWidth) {
		return (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="urban_rural_indicator"
					label="Urban / Rural"
					placeholder="Select ..."
					options={this.state.options.snf['urban_rural_indicator']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.urban_rural_indicator}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="net_patrev_cohort_id"
					label="Net Patient Revenue"
					placeholder="Select ..."
					options={this.state.options.snf['net_patrev_cohort_id']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.net_patrev_cohort_id}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="net_income_cohort_id"
					label="Net Profit or Loss"
					placeholder="Select ..."
					options={this.state.options.snf['net_income_cohort_id']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.net_income_cohort_id}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="overall_rating"
					label="Overall 5 Star Rating"
					placeholder="Select ..."
					options={this.state.options.snf['overall_rating']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.overall_rating}
				/>



				{this.timeFilters(ctrWidth)}
			</Fragment>
		);
	}

	timeFilters(ctrWidth) {
		return this.props.showTimeFilters == true ? (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="fy_year"
					domain="snf"
					label="Fiscal Year"
					placeholder="All Years"
					options={this.state.options.snf['fy_year']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.fy_year}
					disabled={this.state.most_recent_report}
				/>
				{this.mostRecentReport()}
			</Fragment>
		) : null;
	}

	mostRecentReport() {
		return (
			<div className="custom-control-inline d-inline-flex custom-checkbox text-primary justify-content-between ml-4 ">
				<input
					type="checkbox"
					className="custom-control-input"
					id="most_recent_report"
					name="most_recent_report"
					onChange={this.onMostRecentReportChange}
				/>
				<label className="custom-control-label" htmlFor="most_recent_report">
					Most Recent Report
				</label>
			</div>
		);
	}

	onReset(e) {
		let state = this.state;

		state.filters = [];

		state.values.state_name = [];
		state.values.cbsa_name = [];
		state.values.city_name = [];
		state.values.system = [];
		state.values.provider_subtype = [];
		state.values.ownership_type = [];
		state.values.facility_type = [];
		state.values.urban_rural_indicator = [];
		state.values.bed_cohort = [];
		state.values.net_patrev_cohort_id = [];
		state.values.net_income_cohort_id = [];
		state.values.fy_year = [];
		state.values.overall_rating = [];
		this.setState(state);
	}
	onApply(e) {
		if (this.props.onFilterChanged) this.props.onFilterChanged(this.state.filters);
	}

	locView(ctrWidth) {
		return (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="state_name"
					domain=""
					label="State"
					placeholder="Select ..."
					options={this.state.options.snf['state_name']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.state_name}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="cbsa_name"
					domain=""
					label="Core Based Statistical Area (CBSA)"
					placeholder="Select ..."
					options={this.state.options.snf['cbsa_name']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.cbsa_name}
				/>
			</Fragment>
		);
	}

	snfView() {
		let cols = misc.toInt(this.props.cols ? this.props.cols : 2);
		let ctrWidth = 275;

		switch (cols) {
			case 1:
				ctrWidth = 400;
				return (
					<SectionLabel>
						<dir className="row">
							{this.locView(ctrWidth)}
							{this.leftSideSNFEdits(ctrWidth)}
							{this.centerSNFEdits(ctrWidth)}
							{this.rightSideSNFEdits(ctrWidth)}
						</dir>
					</SectionLabel>
				);
			case 2:
				ctrWidth = 350;
				return (
					<SectionLabel>
						<div className="row">
							<div className="col-6">
								{this.locView(ctrWidth)}
								{this.leftSideSNFEdits(ctrWidth)}
							</div>
							<div className="col-6">
								{this.centerSNFEdits(ctrWidth)}
								{this.rightSideSNFEdits(ctrWidth)}
							</div>
						</div>
					</SectionLabel>
				);
			default:
				ctrWidth = 275;
				return (
					<SectionLabel>
						<div className="row">
							<div className="col-lg-4 col-sm-6 col-12">
								{this.locView(ctrWidth)}
								{this.leftSideSNFEdits(ctrWidth)}
								{this.centerSNFEdits(ctrWidth)}
							</div>
							<div className="col-lg-4 col-sm-6 col-12">{this.rightSideSNFEdits(ctrWidth)}</div>
						</div>
					</SectionLabel>
				);
		}
	}

	dlgButtons() {
		return (
			<div className="modal-footer">
				<button type="button" className="btn btn-secondary " onClick={this.onReset}>
					Reset
				</button>

				<button
					type="button"
					className="btn btn-secondary"
					data-dismiss="modal"
					onClick={this.onApply}
				>
					Apply
				</button>
			</div>
		);
	}

	render() {
		return (
			<div>
				{this.snfView()}
				{this.props.model ? this.dlgButtons() : ''}
			</div>
		);
	}
}
