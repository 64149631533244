import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../tools/datagrid';
import DataGridFooterNotation from '../../tools/datagrid/extras/DataGridFooterNotation';
import * as MHGridGroup from '../../tools/datagrid/extras/DataGridGroupHeader';
import DataGridQuartile from '../../tools/datagrid/extras/DataGridQuartile';
import BenchmarkDataExport from '../Benchmark/BenchmarkDataExport';
import FacilityCompare from '../../core/components/facilityCompare';
import * as Fields from './QualityFields';
import LoadingSpinner from '../../tools/loadingSpinner';
import QualityTab from './QualityTab';
import * as providerActions from '../../core/actions/GlobalActions';
import * as misc from '../../lib/misc';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
	faBars,
	faCheck,
	faEdit,
	faToggleOn,
	faColumns,
	faAngleDown,
	faUndo
} from '@fortawesome/fontawesome-free-solid';
import HospPeerGroup from '../Common/hospPeerGroup';
import _ from 'lodash';

const CustomGridMenu = (compareId, customBenchId, bench, active, updateInfo, toggleUpdater, tabDef, SummaryCols, data, FieldDesc) => {
	let Title = new MHGrid.DataGridTitle('Quality Metrics', '', '', 3);
	let _sectionsTemp = [summaryTab, patientExperiencesTab, timeEffectiveCareTab, complicationsDeathsTab, UnplannedReadmissionsTab, PsychiatricUnitServicesTab, PaymentValueCareTab];
	console.log(data);
	return (
		<>
			<button
				className="form-control select text-small px-0"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				Options <FontAwesomeIcon className="fa-sm" icon={faAngleDown} />
			</button>
			<ul className="dropdown-menu shadow bg-light" id="qmOptions">
				<li
					className="nav-link menu-link-label small py-1"
					data-toggle="modal"
					data-target={'#' + compareId}
				>
					<FontAwesomeIcon className="fa-1x text-primary" icon={faColumns} />
					<span className="px-2">Compare</span>
				</li>
				<div className="dropdown-divider" />
				{bench ? (
					<span className="nav-link menu-link-label small strong py-1">Custom Peer Group</span>
				) : (
					''
				)}
				<li
					className="nav-link menu-link-label small py-1"
					data-toggle="modal"
					data-target={'#' + customBenchId}
				>
					<span>{bench ? 'Create New' : 'Custom Peer Group'}</span>
				</li>
				{bench ? (
					<li className="nav-link menu-link-label small py-1" onClick={updateInfo}>
						<FontAwesomeIcon className={'fa-1x text-primary'} icon={faUndo} />
						<span className="px-2">Reset</span>
					</li>
				) : (
					''
				)}
				{bench ? (
					<li className="nav-link menu-link-label small py-1" onClick={toggleUpdater}>
						<FontAwesomeIcon
							className={active ? 'fa-1x text-primary' : 'fa-1x text-light'}
							icon={faCheck}
						/>
						<span className="px-2">Custom</span>
					</li>
				) : (
					''
				)}
				{bench ? (
					<li className="nav-link menu-link-label small py-1" onClick={toggleUpdater}>
						<FontAwesomeIcon
							className={!active ? 'fa-1x text-primary' : 'fa-1x text-light'}
							icon={faCheck}
						/>
						<span className="px-2">National</span>
					</li>
				) : (
					''
				)}
				<div className="dropdown-divider" />
				<li className="nav-link menu-link-label small py-1">
					{data ? (
						<BenchmarkDataExport
							fileName={Title.header1}
							domain={'hosp'}
							sections={_sectionsTemp}
							tabDef={tabDef}
							headAlign={'center'}
							bodyAlign={'right'}
							Cols={SummaryCols}
							data={data}
							FieldDesc={FieldDesc}
						/>
					) : null}
				</li>
			</ul>
		</>
	);
};

const summaryTab = [
	{
		title: 'General Information',
		fields: Fields.HospSummaryFields
	},
	{
		title: 'Hospital Rating',
		fields: Fields.Summary5StarFields
	},
	{
		title: 'Mortality',
		fields: Fields.SummaryMortalityFields
	},
	{
		title: 'Safety of Care',
		fields: Fields.SummarySafetyCareFields
	},
	{
		title: 'Readmission',
		fields: Fields.SummaryReadmissionFields
	},
	{
		title: 'Patient Experience',
		fields: Fields.SummaryPatientExperienceFields
	},
	{
		title: 'Effectiveness of Care',
		fields: Fields.SummaryEffectivenessCareFields
	},
	{
		title: 'Timeliness of Care',
		fields: Fields.SummaryTimelinessCareFields
	},
	{
		title: 'Efficient Use of Medical Imaging',
		fields: Fields.SummaryEfficientMedicalImagingFields
	}
];
const patientExperiencesTab = [
	{
		title: 'Patient Experiences',
		fields: Fields.patientExperiences
	}
];
const timeEffectiveCareTab = [
	{
		title: 'Sepsis Care',
		fields: Fields.SepsisCareFields
	},
	{
		title: 'Cataract Surgery Outcome',
		fields: Fields.CataractSurgeryOutcomeFields
	},
	{
		title: 'Colonoscopy Follow-Up',
		fields: Fields.ColonoscopyFields
	},
	{
		title: 'Heart Attack',
		fields: Fields.HeartAttackFields
	},
	{
		title: 'Emergency Department (ED) Throughput',
		fields: Fields.EDthroughputFields
	},
	{
		title: 'Preventive Care',
		fields: Fields.PreventiveCareFields
	},
	{
		title: 'Cancer Care',
		fields: Fields.CancerCareFields
	},
	{
		title: 'Blood Clot Prevention',
		fields: Fields.BloodClotPreventiveCareFields
	},
	{
		title: 'Pregnancy & Delivery Care',
		fields: Fields.PregnancyDeliveryCareFields
	},
	{
		title: 'Use of Medical Imaging',
		fields: Fields.MedicalImgFields
	}
];
const complicationsDeathsTab = [
	{
		title: 'Surgical Complications',
		fields: Fields.SurgicalFields
	},
	{
		title: 'Infections',
		fields: Fields.HealthAssocInfectFields
	},
	{
		title: '30-Day Death Rates',
		fields: Fields.MortalityFields
	},
	{
		title: 'Unplanned Hospital Visits',
		fields: Fields.UnplannedHospitalVisitsFields
	}
];
const UnplannedReadmissionsTab = [
	{
		title: 'Unplanned Readmissions & Hospital Return Days by Medical Condition',
		fields: Fields.MedicalConditionFields
	},
	{
		title: 'Unplanned Readmission and Hospital Visits by Procedure',
		fields: Fields.VisitsByProcedureFields
	},
	{
		title: 'Unplanned Readmission',
		fields: Fields.UnplannedReadmissionFields
	}
];
const PsychiatricUnitServicesTab = [
	{
		title: 'Preventative Care and Screening',
		fields: Fields.PsycCareScreenFields
	},
	{
		title: 'Substance Use Treatment',
		fields: Fields.PsycSubTreatmentFields
	},
	{
		title: 'Patient Safety',
		fields: Fields.PsycPatientSafetyFields
	},
	{
		title: 'Patient Experience of Care',
		fields: Fields.PsycPatientExpCareFields
	},
	{
		title: 'Facility Use of Technology',
		fields: Fields.PsycFacTechFields
	},
	{
		title: 'Follow Up Care',
		fields: Fields.PsycFollowCareFields
	},
	{
		title: 'Unplanned Readmissions',
		fields: Fields.PsycUnplannedReadFields
	}
];
const PaymentValueCareTab = [
	{
		title: 'Clinical Episode-Based Payment',
		fields: Fields.ClinicalEpisodeBasedPaymentFields
	},
	{
		title: 'Medicare Spending Per Beneficiary',
		fields: Fields.MedicareSpendingBeneficiaryFields
	},
	{
		title: 'Payment Measures',
		fields: Fields.PaymentMeasuresFields
	},
	{
		title: 'Value of Care',
		fields: Fields.ValueOfCareFields
	}
];

const readmissionTab = [
	{
		title: '30-Day Mortality Measures',
		fields: Fields.MortalityFields
	},
	{
		title: 'Surgical Complications Measures',
		fields: Fields.SurgicalFields
	}
];
const timelyTab = [
	{
		title: '',
		fields: ['op_31']
	},
	{
		title: '',
		fields: ['op_29', 'op_30']
	},
	{
		title: '',
		fields: ['op_1', 'op_4', 'op_3b', 'op_2', 'op_5']
	},
	{
		title: '',
		fields: ['ed_2b', 'edv', 'ed_1b', 'op_18b', 'op_20', 'op_21', 'op_22', 'op_23']
	},
	{
		title: '',
		fields: ['imm_2', 'imm_3_op_27_fac_adhpct']
	},
	{
		title: '',
		fields: ['stk_4']
	},
	{
		title: '',
		fields: ['vte_6', 'vte_5']
	},
	{
		title: '',
		fields: ['pc_01']
	}
];
const hospAssInfTab = [
	{
		title: '',
		fields: Fields.HealthAssocInfectFields
	}
];
const hospIPPsychTab = [
	{
		title: '',
		fields: Fields.IPPsychFields
	}
];
const medImgTab = [
	{
		title: '',
		fields: Fields.MedicalImagingFields
	}
];

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.hospQualityData.whereChanged,
		data: store.hospQualityData.Benchmark,
		dataLoaded: store.hospQualityData.fetched,
		dataLoading: store.hospQualityData.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProviderInfo: ids => dispatch(providerActions.findProviders(ids))
	};
};
class QualityDetail extends React.Component {
	constructor(props) {
		super(props);
		if (this.props.data) this.props.data.ActiveYear = 'LATEST';
		this.state = {
			currentYear: this.props.data.ActiveYear,
			selectedTab: 0,
			active: true,
			customBenchmark: misc.hasCustomBenchmark(),
			providers: misc.getFOIandCompetitors(),
			key: misc.Guid()
		};
		// if (this.props.updateInfo) {
		// 	this.UpdateInfo = this.props.updateInfo.bind(this);
		// }
		this.ResetUpdateInfo = this.ResetUpdateInfo.bind(this);
		this.UpdateInfo = this.UpdateInfo.bind(this);
		this.onYearChanged = this.onYearChanged.bind(this);
		this.tabChanged = this.tabChanged.bind(this);
		this.switchActive = this.switchActive.bind(this);
	}

	onYearChanged(year) {
		this.props.data.ActiveYear = year;
		let state = this.state;
		state.currentYear = year;
		this.setState(state);
	}

	tabChanged(selectedTab) {
		let state = this.state;
		state.selectedTab = selectedTab;
		this.setState(state);
	}
	UpdateInfo() {
		let state = this.state;
		state.providers = misc.getFOIandCompetitors();
		state.customBenchmark = misc.hasCustomBenchmark();
		state.active = state.customBenchmark;
		state.key = misc.Guid();
		this.setState(state);
		this.props.updateInfo();
	}
	ResetUpdateInfo() {
		sessionStorage.setItem('customBenchmark', JSON.stringify([]));
		sessionStorage.setItem('custom-peer-group-description', '');
		let currState = this.state;
		currState.active = false;
		currState.customBenchmark = false;
		currState.key = misc.Guid();
		this.setState(currState);
		this.props.updateInfo();
	}

	switchActive() {
		let currState = this.state;
		currState.active = !this.state.active;
		currState.key = misc.Guid();
		this.setState(currState);
	}
	getDetails() { }
	render() {
		let ContentPage = null;
		let Title = new MHGrid.DataGridTitle(
			'Quality Metrics',
			'',
			'',
			3,
			this.props.data.Years,
			this.onYearChanged,
			true,
			true
		);
		let compareId = 'compare_benchmark';
		let custmBenchId = 'custom_peergroup';
		let providers = misc.getFOIandCompetitors();

		if (this.props.dataLoaded && !this.props.dataLoading) {
			let SummaryCols = this.props.dataLoaded
				? MHGridGroup.DataGridGroupHeader(
					(this.state.active &&
						this.props.data.args != null &&
						this.props.data.args['custom'] &&
						misc.hasCustomBenchmark()) ||
					false,
					providers,
					'hosp'
				)
				: null;
			const tabDef = [
				{
					id: 0,
					title: 'Summary',
					component: (
						<QualityTab
							key={this.state.key}
							{...this.props}
							Cols={SummaryCols}
							Sections={summaryTab}
						/>
					)
				},
				{
					id: 1,
					title: 'Patient Experiences',
					component: (
						<QualityTab {...this.props} Cols={SummaryCols} Sections={patientExperiencesTab} />
					)
				},
				{
					id: 2,
					title: 'Timely & Effective Care',
					component: (
						<QualityTab {...this.props} Cols={SummaryCols} Sections={timeEffectiveCareTab} />
					)
				},
				{
					id: 3,
					title: 'Complications & Deaths',
					component: (
						<QualityTab {...this.props} Cols={SummaryCols} Sections={complicationsDeathsTab} />
					)
				},
				{
					id: 4,
					title: 'Unplanned Hospital Visits',
					component: (
						<QualityTab {...this.props} Cols={SummaryCols} Sections={UnplannedReadmissionsTab} />
					)
				},
				{
					id: 5,
					title: 'Psychiatric Unit Services',
					component: (
						<QualityTab {...this.props} Cols={SummaryCols} Sections={PsychiatricUnitServicesTab} />
					)
				},
				{
					id: 6,
					title: 'Payment & Value of Care',
					component: (
						<QualityTab {...this.props} Cols={SummaryCols} Sections={PaymentValueCareTab} />
					)
				}
			];

			let FieldDesc = this.props.FieldDesc;
			let CustomGrid = this.props.dataLoaded
				? CustomGridMenu(
					compareId,
					custmBenchId,
					this.props.data.args != null &&
					this.props.data.args['custom'] &&
					misc.hasCustomBenchmark(),
					this.state.active,
					this.ResetUpdateInfo,
					this.switchActive,
					tabDef,
					SummaryCols,
					this.props.data,
					FieldDesc
				)
				: null;

			const pillTabs = tabDef.map(({ id, title } = {}) => (
				<li
					className={
						this.state.selectedTab === id
							? 'react-tabs__tab react-tabs__tab--selected  d-block'
							: 'react-tabs__tab d-block'
					}
				>
					<a className="d-block" onClick={() => this.tabChanged(id)}>
						{title.toString()}
					</a>
				</li>
			));
			const toggleTabs = tabDef.map(({ id, title } = {}) => (
				<li
					className={
						this.state.selectedTab === id
							? 'react-tabs__tab react-tabs__tab--selected'
							: 'react-tabs__tab'
					}
					onClick={() => this.tabChanged(id)}
				>
					<a data-toggle="tab">{title.toString()}</a>
				</li>
			));
			const panelTab = tabDef.map(({ id, title } = {}) => (
				<div className={this.state.selectedTab === id ? 'tab-pane active' : 'tab-pane'}>
					{(tabDef[id] && tabDef[id].component) || null}
				</div>
			));
			const tabBody = (
				<div className="col-12">
					<div className="dropdown show d-flex mx-auto d-md-none react-tabs__tab-list">
						<a
							className="react-tabs__tab react-tabs__tab--selected"
							href="#"
							role="button"
							id="dropdownMenuLink"
							data-toggle="dropdown"
						>
							{tabDef[this.state.selectedTab].title.toString()}
						</a>

						<div className="dropdown-menu text-center mx-auto" aria-labelledby="dropdownMenuLink">
							<ul className="nav nav-stacked d-block d-md-none">{pillTabs}</ul>
						</div>
					</div>
					<ul
						className="d-none d-md-flex px-0 col-12 mx-auto react-tabs__tab-list"
						role="tablist"
					>
						{toggleTabs}
						<li className="react-tabs__tab">
							{CustomGrid}
						</li>
					</ul>
					<div className="tab-content p-0 col-12">
						<DataGridQuartile className="my-0" domain={'hosp'}>
							<FacilityCompare
								changeComparison={this.UpdateInfo}
								TargetId={compareId}
								segment="hosp"
							/>
						</DataGridQuartile>
						<HospPeerGroup updateBenchmark={this.UpdateInfo} ModalId={custmBenchId} />
						{panelTab}
					</div>
				</div>
			);

			ContentPage = (
				<>
					{Title.renderHeading()}
					<div className="row">
						{tabBody}
						<DataGridFooterNotation />
					</div>
				</>
			);
		} else {
			ContentPage = (
				<div className="container">
					<div className="row">
						<LoadingSpinner />
					</div>
				</div>
			);
		}

		return <>{ContentPage}</>;
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityDetail);
