import React, {Fragment} from 'react';
import LandingPage from './Landing-Pages/quality-landing-page';
import PageNavigation from '../../components/navigation/pageNavigation';

export default class HospQualityInfo extends React.Component {
	constructor(props) {
		super(props);
		this.domain = this.props.domain ? this.props.domain : 'info';
	}
	render() {
		document.body.id = 'landingPage';
		return (
			<Fragment>
				<PageNavigation selectedTab="3" domain={this.domain} />
				{LandingPage(this.domain)}
			</Fragment>
		);
	}
}
