import * as ActionTypes from '../actions/ActionTypes';
import * as misc from '../../lib/misc';

const defaultSearchState = {
	data: [],
	error: null,
	fetching: false,
	fetched: false
};

const defaultState = {
	providers: [],
	error: null,
	fetching: false,
	fetched: false
};

const defaultProviderState = {
	providerInfo: [],
	error: null,
	fetching: false,
	fetched: false
};

//TODO: Duplicated Needs Removed Throughout the Site
export const providerReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'PROVIDERS_FETCH_PENDING': {
			return {
				...state,
				fetching: true
			};
		}
		case 'PROVIDERS_FETCH_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				providers: action.payload.data.Providers
			};
		}
		case 'PROVIDERS_FETCH_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};

export const providerInfoReducer = (state = defaultProviderState, action) => {
	switch (action.type) {
		case ActionTypes.ASSIGNFACILITY + '_PENDING': {
			return { ...state, fetching: true };
		}
		case ActionTypes.ASSIGNFACILITY + '_FULFILLED': {
			let foi = action.payload.data.Providers;
			if (foi && foi.rows && foi.rowCount > 0) {
				misc.setCurrentFOI(foi.rows);
			}
			return {
				...state,
				fetching: false,
				fetched: true,
				providerInfo: foi
			};
		}
		case ActionTypes.ASSIGNFACILITY + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
		//set session state for result.
		//fire where changed event.
	}
	return state;
};

export const elasticSearchReducer = (state = defaultSearchState, action) => {
	switch (action.type) {
		case ActionTypes.GET_SEARCH_API + '_PENDING': {
			return { ...state, fetching: true };
		}
		case ActionTypes.GET_SEARCH_API + '_FULFILLED': {
			let result = action.payload.data.result;
			state.data[result.key] = result.data;
			return {
				...state,
				fetching: false,
				fetched: true
			};
		}
		case ActionTypes.GET_SEARCH_API + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
		//set session state for result.
		//fire where changed event.
	}
	return state;
};
