import React from 'react';
import * as products from './products';

class CardSNF extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.currentPlan;
    this.selectPlan = this.selectPlan.bind(this);
  };

  selectPlan = event => {
    event.preventDefault();
    let state = this.state;
    state.selected = event.target.name;
    state.plan = event.target.id;
    state.price = event.target.value;
    state.planName = products.snf_access.fullName;
    this.setState(state);
    if(this.props.onPlan) {
      this.props.onPlan(this.state);
    }
  };

  render() {
    let subPrice = null;
    if(this.props.currentPlan.promo) {
      subPrice = 
        <div className="promoLic text-center"> 
          <h4>Promotional License</h4>
          <h5>{this.props.currentPlan.description}</h5>
          <h6>Original Price: ${this.props.currentPlan.orgPrice} a Year</h6>
          <h6>Promotional Price: ${this.props.currentPlan.price}</h6>
          <h6>Expires: <em>{this.props.currentPlan.expDate}</em></h6>
        </div>;
    } else if(this.props.currentPlan.liceTotal === 'Unlimited') {
      subPrice =
      <div className="unlimitedLice">
        <h2>Unlimited Licenses</h2>
        <h3><sup>$</sup>{products.snf_access.price.unlimited}</h3>
        <p>Per Year</p>
      </div>;
    } else {
      subPrice = 
        <div className="limitedLice">
          <h4>{this.state.liceTotal === 1 ? 
            'Single License'
          : this.props.currentPlan.liceTotal + ' Licenses' }</h4>
          <h2>
          {this.state.liceTotal === 1 ?
              <><sup>$</sup>{products.snf_access.price.single}</>
            : '' }
            {this.state.liceTotal === 2 ?
              <><sup>$</sup>{products.snf_access.price.single} + <sup>$</sup>{this.props.currentPlan.pricePer}</>
            : '' }
            {this.state.liceTotal >= 3 ?
              <><sup>$</sup>{products.snf_access.price.single} + (<sup>$</sup>{this.props.currentPlan.pricePer}<span> * </span>{this.state.liceAdded})</>
            : ''}
          </h2>
          {this.state.liceTotal > 1 ?
            <>
              <hr />
              <h2><sup>$</sup>{this.props.currentPlan.price}</h2>
            </>
            
          : ''}
          <p>Per Year</p>
        </div>
    }
    return(
      <div className="planCard snf">
        <div className="header">
          <h3>Skilled Nursing Facilities</h3>
          <p>Access all Medicare SNF Data</p>
        </div>
        <div className="body">
          {subPrice}
          <ul>
            <li>Access to all Tools and Reports for the SNF Data Set</li>
            <li>Access to Data Explorer for the SNF Data Set</li>
            <li>Downloads of Last 5 Years of Cost Reports in Excel Format​</li>
          </ul>
          {/* <table>
            <thead>
              <tr>
                <th>Number Licenses</th>
                <th>Cost Per Year</th>
              </tr>
            </thead>
            <tbody>
              <tr
                className={this.state.liceTotal === 1 && this.props.currentPage >= 4 ? 'active' : ''}
              >
                <td>Single</td>
                <td>${products.snf_access.price.single}</td>
              </tr>
              <tr
                className={this.state.liceTotal >= 2 && this.state.liceTotal <= 5 ? 'active' : ''}
              >
                <td>2 to 5</td>
                <td>${products.snf_access.price.twoToFive}</td>
              </tr>
              <tr
                className={this.state.liceTotal >= 6 && this.state.liceTotal <= 10 ? 'active' : ''}
              >
                <td>6 to 10</td>
                <td>${products.snf_access.price.sixToTen}</td>
              </tr>
              <tr
                className={this.state.liceTotal >= 11 && this.state.liceTotal != 'Unlimited' ? 'active' : ''}
              >
                <td>11 or More</td>
                <td>${products.snf_access.price.tenPlus}</td>
              </tr>
              <tr
                className={this.state.liceTotal === 'Unlimited' ? 'active' : ''}
              >
                <td>Unlimited</td>
                <td>${products.snf_access.price.unlimited}</td>
              </tr>
            </tbody>
            
          </table> */}
        </div>
        <div className="foot">
        <button 
            type="button"
            name={products.snf_access.name}
            id={products.snf_access.id}
            value={products.snf_access.price.single}
            onClick={this.selectPlan}
          >
            Purchase
          </button>
        </div>
      </div>
    )
  }
}

export default CardSNF;