import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import * as API from '../../lib/ApiUtil';
import * as misc from '../../lib/misc';
import { loadFieldDefinitions } from './../actions/GlobalActions';
import MHModal from '../../tools/MHModal';
import $ from 'jquery';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields
	};
};
const mapDispatchToProps = dispatch => {
	return {
		LoadFieldDescriptions: () => dispatch(loadFieldDefinitions())
	};
};

const FldModel = class FldModel {
	constructor() {
		this.fieldId = 0;
		this.fieldName = '';
		this.fieldDescription = '';
		this.fieldLongDescription = '';
		this.fieldRankAsc = 'true';
		this.enableNtile = 'true';
		this.enablePopup = 'true';
		this.fieldDenominator = '';
		this.fieldNumerator = '';
		this.fieldFormat = 'NUMBER';
		this.fieldDomain = 'hosp';
	}
};

class fieldDefModel extends React.Component {
	constructor(props) {
		super(props);

		this.state = new FldModel();
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		// Find Field Def and Load State
		let fld = this.props.FieldId;
		if (fld) {
			let Model = new FldModel();

			Model.fieldId = fld.FieldId;
			Model.fieldName = fld.FieldName;
			Model.fieldDescription = fld.FieldShortName;
			Model.fieldLongDescription = fld.FieldDescription;
			Model.fieldRankAsc = misc.toBool(fld.FieldRankOrder).toString();
			Model.enableNtile = misc.toBool(fld.FieldEnableNtile).toString();
			Model.enablePopup = misc.toBool(fld.FieldIncludePopUp).toString();
			Model.fieldDenominator = fld.FieldDenominator;
			Model.fieldNumerator = fld.FieldNumerator;
			Model.fieldFormat = fld.FieldFormat;
			Model.fieldDomain = fld.FieldDomain;
			Model.fieldDenomAggregateMethod = fld.FieldDenomAggregateMethod;
			Model.fieldAggregateMethod = fld.FieldAggregateMethod;
			Model.fieldStyle = fld.FieldStyle;
			Model.fieldDomain = fld.FieldDomain;
			this.setState(Model);
		}
	}
	UNSAFE_componentWillMount() {}

	handleChange(e) {
		let id = e.target.id;
		let state = this.state;
		state[id] = e.target.value;
		this.setState(state);
	}
	handleSubmit(event) {
		API.APIPost('/field_change', this.state).then(
			success => {
				this.props.LoadFieldDescriptions();
				this.props.CloseForm();
			},
			err => {
				alert(err && err.message ? err.message : 'Failed to save changes!!!');
			}
		);
		event.preventDefault();
	}

	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<div className="container-fluid">
					<div className="row">
						<h3>
							FieldID:
							<span>{this.state.fieldId}</span>
							<span> - {this.state.fieldName}</span>
						</h3>
					</div>
					<hr />
					<div className="row">
						<div className="form-group col-lg-8 mr-2 ml-0">
							<div className="row">
								<h3>Name:</h3>
								<input
									style={{ width: '100%', border: '1px solid #304771' }}
									type="text"
									className="fieldDescription"
									value={this.state.fieldDescription}
									onChange={this.handleChange}
								/>
							</div>
							<div className="row">
								<h3>Description:</h3>
								<textarea
									className="form-control fieldLongDescription"
									rows="10"
									value={this.state.fieldLongDescription}
									onChange={this.handleChange}
									style={{ border: '1px solid #304771' }}
								/>
							</div>
							{/* <div className="form-group row">
									<label style={{ marginRight: '5px', border: '2px #000' }}>
										Tbl Abv: <b>bmh</b>-<u>base_metrics_hosp</u> <b>bmha</b>-<u>
											base_metrics_hosp_aux
										</u>{' '}
										<b>dmh</b>-<u>derived_metrics_hosp</u>
									</label>
								</div> */}
							{/* <div className="form-group row">
									<h3 style={{ marginRight: '5px' }}>Numerator: </h3>
									<textarea
										className="form-control"
										rows="2"
										id="fieldNumerator"
										value={this.state.fieldNumerator}
										onChange={this.handleChange}
										style={{ border: '1px solid #304771' }}
									/>
								</div>
								<div className="form-group row">
									<h3 style={{ marginRight: '5px' }}>Denominator: </h3>
									<textarea
										className="form-control"
										rows="2"
										id="fieldDenominator"
										value={this.state.fieldDenominator}
										onChange={this.handleChange}
										style={{ border: '1px solid #304771' }}
									/>
								</div> */}
						</div>
						<div className="col">
							<div className="form-group row">
								<h3 style={{ marginRight: '5px' }}>Popup: </h3>
								<label className="radio-inline">
									<input
										style={{}}
										type="radio"
										name="enablePopup"
										className="enablePopup"
										value={true}
										checked={this.state.enablePopup === 'true' ? true : false}
										onClick={this.handleChange}
									/>
									Yes
								</label>
								<label className="radio-inline">
									<input
										style={{}}
										type="radio"
										name="enablePopup"
										className="enablePopup"
										value={false}
										checked={this.state.enablePopup === 'false' ? true : false}
										onClick={this.handleChange}
									/>
									No
								</label>
							</div>
							<div className="form-group row">
								<h3 style={{ marginRight: '5px' }}>Ntile: </h3>
								<label className="radio-inline">
									<input
										type="radio"
										name="enableNtile"
										className="enableNtile"
										value={true}
										checked={this.state.enableNtile === 'true' ? true : false}
										onClick={this.handleChange}
									/>
									Yes
								</label>
								<label className="radio-inline">
									<input
										style={{}}
										type="radio"
										name="enableNtile"
										className="enableNtile"
										value={false}
										checked={this.state.enableNtile === 'false' ? true : false}
										onClick={this.handleChange}
									/>
									No
								</label>
							</div>
							<div className="form-group row">
								<h3 style={{ marginRight: '5px' }}>Rank: </h3>
								<label className="radio-inline">
									<input
										style={{}}
										type="radio"
										name="fieldRankAsc"
										className="fieldRankAsc"
										value={true}
										checked={this.state.fieldRankAsc === 'true' ? true : false}
										onClick={this.handleChange}
									/>
									Asc
								</label>
								<label className="radio-inline">
									<input
										style={{}}
										type="radio"
										name="fieldRankAsc"
										className="fieldRankAsc"
										value={false}
										checked={this.state.fieldRankAsc === 'false' ? true : false}
										onClick={this.handleChange}
									/>
									Desc
								</label>
							</div>
							<div className="form-group row">
								<h3>Format:</h3>
								<select
									className="form-control fieldFormat"
									value={this.state.fieldFormat}
									onChange={this.handleChange}
									style={{ border: '1px solid #304771' }}
								>
									<option value="NUMBER">Number</option>
									<option value="NUMBERDEC">Number w/Decimals</option>
									<option value="INTEGER">Integer</option>
									<option value="TEXT">Text</option>
									<option value="MONEY">Money</option>
									<option value="MONEYDEC">Money w/Decimals</option>
									<option value="PERCENT">Percent</option>
									<option value="PERCENTBAR">Percent Bar</option>
									<option value="PERNOSHIFT">Percent No Shift</option>
									<option value="SPARKLINE">Spark Line</option>
									<option value="5STAR">5 Star Rank</option>
								</select>
							</div>
							{/* <div className="form-group row">
									<h3>Aggregate Function:</h3>
									<select
										className="form-control"
										id="fieldAggregateMethod"
										value={this.state.fieldAggregateMethod}
										onChange={this.handleChange}
										style={{ border: '1px solid #304771' }}
									>
										<option value="" />
										<option value="AVG">AVG</option>
										<option value="SUM">SUM</option>
										<option value="MAX">MAX</option>
										<option value="MIN">MIN</option>
									</select>
								</div>
								<div className="form-group row">
									<h3>Denominator Aggregate Function:</h3>
									<select
										className="form-control"
										id="fieldDenomAggregateMethod"
										value={this.state.fieldDenomAggregateMethod}
										onChange={this.handleChange}
										style={{ border: '1px solid #304771' }}
									>
										<option value="" />
										<option value="AVG">AVG</option>
										<option value="SUM">SUM</option>
										<option value="MAX">MAX</option>
										<option value="MIN">MIN</option>
									</select>
								</div> */}
							<div className="form-group row">
								<h3>Style:</h3>
								<select
									className="form-control fieldStyle"
									value={this.state.fieldStyle}
									onChange={this.handleChange}
									style={{ border: '1px solid #304771' }}
								>
									<option value="" />
									<option value="TOTAL_LINE">Total Line with Bold Text</option>
									<option value="DOUBLE_LINE">Total Double Line with Bold Text</option>
									<option value="BOLD">Bold Text</option>
									<option value="UNDERLINE">Underline</option>
									<option value="ITALIC">Italic</option>
									<option value="BOLD-UNDERLINE">Bold and Underline</option>
									<option value="BOLD-ITALIC">Bold and Italic</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<input type="submit" value="Submit" />
			</form>
		);
	}
}

const FldModal = connect(
	mapStateToProps,
	mapDispatchToProps
)(fieldDefModel);

const FieldDefinitions = class FieldDefinitions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			id: misc.Guid()
		};

		this.CloseClick = this.CloseClick.bind(this);
	}

	CloseClick() {
		let tag = '#' + this.state.id;
		$(tag).modal('hide');
	}

	UNSAFE_componentWillMount() {}

	render() {
		let FieldId = this.props.fieldDef ? this.props.fieldDef : null;

		const ModalContent = <FldModal FieldId={FieldId} CloseForm={this.CloseClick} />;
		const Content = (
			<div
				className="table-responsive bg-white"
				style={{ padding: '0px 10px 10px 10px', height: '100%', width: '100%' }}
			>
				{ModalContent}
			</div>
		);

		return (
			<MHModal
				content={Content}
				title={FieldId && FieldId.FieldName ? FieldId.FieldName : ''}
				id={this.state.id}
			>
				<i
					className="fa fa-edit"
					aria-hidden="true"
					style={{
						cursor: 'pointer',
						marginLeft: '5px',
						marginRight: '5px'
					}}
				/>
			</MHModal>
		);
	}
};

export default FieldDefinitions;
