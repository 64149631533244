import React from 'react';
import _ from 'lodash';
import * as MHGrid from '../index';
import * as misc from '../../../lib/misc';
import * as fmt from '../../../lib/FormatUtil';
const foiDomain = domain => {
	switch (domain) {
		case 'market':
			return false;
		default:
			return true;
	}
};

export function DataGridGroupHeader(customToggle, providers, domain = 'hosp') {
	let custom = customToggle;

	let domainTag = domain => {
		switch (domain) {
			case 'market':
				return 'National';
			case 'snf':
				return 'National SNF';
			case 'hosp':
			default:
				return 'National GAC';
		}
	};

	const NationalTitle = domain => {
		if (foiDomain(domain))
			return new MHGrid.DataGridHeader(
				'National Benchmark',
				domainTag(domain),
				'All Bed Sizes',
				'center'
			);
		else return new MHGrid.DataGridHeader('', 'National Benchmark', '', 'center');
	};

	const StateBenchmarkTitle = (domain, provider) => {
		if (foiDomain(domain))
			return new MHGrid.DataGridHeader(
				'State Benchmark',
				provider.state || provider.facility_state,
				'All Bed Sizes',
				'center'
			);
		else
			return new MHGrid.DataGridHeader(
				'',
				'State Benchmark',
				provider.state || provider.facility_state,
				'center'
			);
	};

	let SummaryCols = null;

	if (providers) {
		// Create Column Headers for Competitors Grid
		SummaryCols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'metric',
				new MHGrid.DataGridHeader('', '', '', ''),
				300,
				MHGrid.ColType_Metric
			)
		);
		if (providers.length === 1) {
			let key = providers[0].facilityid || providers[0].prvdr_num;
			let colName = key + (custom ? '#custom' : '');
			SummaryCols.Add(
				new MHGrid.DataGridColumn(
					colName,
					MHGrid.DataGridHeaderBuilder(
						fmt.initCap(providers[0].name || providers[0].facility_name),
						'center',
						175
					),
					175,
					MHGrid.ColType_Self
				)
			);
			SummaryCols.Add(
				new MHGrid.DataGridColumn(
					'State',
					StateBenchmarkTitle(domain, providers[0]),
					175,
					MHGrid.ColType_Self
				)
			);
			if (foiDomain(domain) && custom) {
				SummaryCols.Add(
					new MHGrid.DataGridColumn(
						'custom',
						new MHGrid.DataGridHeader('', 'Custom', 'Benchmark', 'center'),
						175,
						MHGrid.ColType_Self
					)
				);
			} else if (foiDomain(domain)) {
				SummaryCols.Add(
					new MHGrid.DataGridColumn(
						'Bed',
						new MHGrid.DataGridHeader(
							'National Benchmark',
							domainTag(domain),
							providers[0].bed_cohort,
							'center'
						),
						175,
						MHGrid.ColType_Self
					)
				);
			}
			SummaryCols.Add(
				new MHGrid.DataGridColumn('National', NationalTitle(domain), 200, MHGrid.ColType_Self)
			);
		} else {
			let ids = misc.getFOI_CompetitorsIds();
			ids.forEach(pid => {
				let provider = _.find(providers, x => (x.id || x.facilityid || x.prvdr_num) === pid);
				let providerCol = pid + (custom ? '#custom' : '');
				if (provider) {
					SummaryCols.Add(
						new MHGrid.DataGridColumn(
							providerCol,
							new MHGrid.DataGridHeader(
								'',
								fmt.initCap(provider.name || provider.fac_name || provider.facility_name),
								'',
								'center'
							),
							175,
							MHGrid.ColType_Self
						)
					);
				}
			});
			if (foiDomain(domain) && custom) {
				SummaryCols.Add(
					new MHGrid.DataGridColumn(
						'custom',
						new MHGrid.DataGridHeader('', 'Custom', 'Benchmark', 'center'),
						175,
						MHGrid.ColType_Self
					)
				);
			}
			SummaryCols.Add(
				new MHGrid.DataGridColumn('National', NationalTitle(domain), 200, MHGrid.ColType_Self)
			);
		}
	}
	return SummaryCols;
}

export function DataGridProviderColumns(ids, providers, domain = 'hosp', metricsOnly = false) {
	let SummaryCols = null;
	let linker =
		misc.baseUrl +
		'/' +
		(domain == 'hosp' ? 'hospital' : domain == 'snf' ? 'skillednursing' : 'homehealth') +
		'/summary/';
	if (ids && Array.isArray(ids) && providers && Array.isArray(providers)) {
		// Create Column Headers for Competitors Grid
		SummaryCols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'metric',
				new MHGrid.DataGridHeader('', '', '', ''),
				300,
				metricsOnly ? MHGrid.ColType_Text : MHGrid.ColType_Metric
			)
		);
		debugger;
		ids.forEach(pid => {
			let provider = _.find(
				providers,
				x => (x.id || x.facilityid || x.providerId || x.prvdr_num) === pid
			);
			if (provider) {
				SummaryCols.Add(
					new MHGrid.DataGridColumn(
						pid,
						new MHGrid.DataGridHeader(
							'',
							provider.name || provider.fac_name || provider.facility_name,
							'',
							'center',
							null,
							linker + pid
						),
						175,
						MHGrid.ColType_Self
					)
				);
			}
		});
	}
	return SummaryCols;
}
