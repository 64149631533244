import * as Types from '../actions/ActionTypes';

const defaultState = {
	data: [],
	error: null,
	fetching: false,
	fetched: false,
	whereChanged: false
};

export const quartileFieldListReducer = (state = defaultState, action) => {
	switch (action.type) {
		case Types.QUARTILE_CHART_FIELDS + '_PENDING': {
			return { ...state, fetching: true, fetched: false, whereChanged: false, data: [] };
		}
		case Types.QUARTILE_CHART_FIELDS + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				whereChanged: false,
				data: action.payload.data.result
			};
		}
		case Types.QUARTILE_CHART_FIELDS + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				whereChanged: false,
				error: action.payload
			};
		}
		case 'SESSION_STORAGE_WHERE_CHANGE':
		case 'USER_LOGIN_FETCH_FULFILLED':
		case Types.ASSIGNFACILITY_COMPLETE: {
			return {
				...state,
				fetching: false,
				fetched: false,
				whereChanged: true,
				data: []
			};
		}
		default: {
			break;
		}
	}
	return state;
};
