import axios from 'axios';
import { getCustomHeader } from '../../lib/ApiUtil';
import _ from 'lodash';
import * as Types from './ActionTypes';
import * as misc from '../../lib/misc';

const apiUrl = misc.apiUrl;

export function ResetStore() {
	return {
		type: Types.GLOBAL_RESET
	};
}

export function AddNewFields(fields, currentfields, domain) {
	// debugger;
	if (currentfields && Array.isArray(currentfields)) {
		let a = currentfields.map(a => a.fieldName);
		let newFields =
			a && a.length > 0
				? _.filter(fields, function(item) {
						return !a.includes(item);
				  })
				: fields;
		if (newFields && newFields.length > 0) {
			return loadFieldDefinitions(domain, newFields);
		}
	} else {
		return loadFieldDefinitions(domain, fields);
	}
}

export function AllFields(domain) {
	return loadFieldDefinitions(domain, [], true);
}

export function loadFieldDefinitions(domain, fields, _all = false) {
	return {
		type: Types.FIELD_DEFINITION_LIST,
		payload: axios.post(
			apiUrl + '/field-definitions',
			{ domain: domain, fields: fields, all: _all },
			getCustomHeader()
		)
	};
}

export function findProviders(domain, providerIds) {
	return {
		type: Types.PROVIDER_GET_LIST,
		payload: axios.post(apiUrl + '/find-providers', { domain, providerIds }, getCustomHeader())
	};
}

export function PostObject(endpoint, data) {
	return axios.post(misc.baseUrl + '/' + endpoint, data, getCustomHeader());
}

export function fetchProviderInfo(domain, providerIds) {
	if (providerIds) {
		misc.clearFOIandCompetitors();
		return {
			type: Types.ASSIGNFACILITY,
			payload: axios.post(
				apiUrl + '/providers',
				{ domain: domain, providerIds: providerIds },
				getCustomHeader()
			)
		};
	} else return null;
}

// export function fetchProviders() {
// 	return {
// 		type: 'PROVIDERS_FETCH',
// 		payload: axios.post(apiUrl + '/providers', getCustomHeader())
// 	};
// }

export function sessionStorageWhereChange() {
	return {
		type: 'SESSION_STORAGE_WHERE_CHANGE',
		payload: {}
	};
}

export function whitePaperList() {
	return {
		type: Types.WHITE_PAPER_LIST,
		payload: axios.post(apiUrl + '/white-paper-list', {}, getCustomHeader())
	};
}

export function SearchDistanceBySNF(domain, provider, key, dist)
{
	return {
		type: Types.GET_SEARCH_API,
		payload: axios.post(
			apiUrl + '/search-dist-from-snf',
			{ domain: domain, prvdr_num: provider, key: key, dist: dist },
			getCustomHeader()
		)
	};
}

export function FetchElasticQuery(domain, query, key) {
	return {
		type: Types.GET_SEARCH_API,
		payload: axios.post(
			apiUrl + '/elastic-search',
			{ domain: domain, query: query, key: key },
			getCustomHeader()
		)
	};
}
