import * as Types from '../Common/ActionTypes';
import { ReducerHelper, DefaultState } from '../../core/reducers/ReducerHelper';

// export const benchmarkUtilizationReducer = (state = new DefaultState(), action) => {
// 	return ReducerHelper(Types.BENCHMARK_GET_UTILIZATION_METRICS, state, action);
// };

// export const benchmarkLaborReducer = (state = new DefaultState(), action) => {
// 	return ReducerHelper(Types.BENCHMARK_GET_LABOR_METRICS, state, action);
// };

// export const benchmarkFinancialReducer = (state = new DefaultState(), action) => {
// 	return ReducerHelper(Types.BENCHMARK_GET_FINANCIAL_METRICS, state, action);
// };

export const snfBenchmarkReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.BENCHMARK_GET_METRICS, state, action);
};

export const snfChartBenchmarkReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.CHART_BENCHMARK_GET_METRICS, state, action, true);
};
