import * as apiUtil from './ApiUtil';


export const Validate = async (promo_code) => {
    try 
    {
        let params = { promo_code: promo_code}
        if(promo_code)
        {
            let result = await apiUtil.APIPost('/promo-lookup', params);

            if(result && result.Error)
            {
                throw result.Message;
            }
            else if(result && result.data && result.data)
            {
                return result.data;
            }
            else
                throw "Error finding promotional information."
        }
    }
    catch(err)
    {
        throw "Error finding promotional information."
    }
};

export const redeemPromo = async (promo_code) => {
    try 
    {
        let params = { promo_code: promo_code}
        if(promo_code)
        {
            let data = await apiUtil.APIPost('/promo-redeem', params);

            if(data && data.Error)
            {
                throw data.Message;
            }
            else if(data && data.data)
            {
                return data.data;
            }
            else
                throw "Error redeeming promotional code."
        }
    }
    catch(err)
    {
        throw "Error redeeming promotional code."
    }
};
