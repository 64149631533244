import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import * as Map from '../../../tools/map';
import * as format from '../../../lib/FormatUtil';
import { LegendItem, Legend } from '../../../tools/charts/legend';
import _ from 'lodash';

const PopOptions = [
	{
		value: 'total_population_65Plus',
		display: 'Total Population 65 and Older',
		active: true
	},
	{
		value: 'total_population_less_65',
		display: 'Total Population Under 65 Years',
		active: false
	},
	{
		value: 'total_pop_less_18',
		display: 'Total Population 18 Years or Less',
		active: false
	},
	{
		value: 'total_pop_18_to_34',
		display: 'Total Population 18 to 34 Years',
		active: false
	},
	{
		value: 'total_pop_35_to_54',
		display: 'Total Population 35 to 54 Years',
		active: false
	},
	{
		value: 'total_pop_55_to_64',
		display: 'Total Population 55 to 64 Years',
		active: false
	},
	{
		value: 'total_pop_65_to_74',
		display: 'Total Population 65 to 74 Years',
		active: false
	},
	{
		value: 'total_pop_75plus',
		display: 'Total Population 75 and Older',
		active: false
	},
	{
		value: 'total_pop_male',
		display: '% Male Population',
		active: false
	},
	{
		value: 'total_pop_female',
		display: '% Female Population',
		active: false
	},
	{
		value: 'total_pop_male_less_18',
		display: 'Males 18 Years or Less',
		active: false
	},
	{
		value: 'total_pop_male_18_to_34',
		display: 'Males 18-34 Years',
		active: false
	},
	{
		value: 'total_pop_male_35_to_54',
		display: 'Males 35 to 54 Years ',
		active: false
	},
	{
		value: 'total_pop_male_55_to_64',
		display: 'Males 55 to 64 Years',
		active: false
	},
	{
		value: 'total_pop_male_65_to_74',
		display: 'Males 65 to 74 Years',
		active: false
	},
	{
		value: 'total_pop_female_less_18',
		display: 'Females 18 Years or Less',
		active: false
	},
	{
		value: 'total_pop_female_18_to_34',
		display: 'Females 18 to 34 Years',
		active: false
	},
	{
		value: 'total_pop_female_35_to_54',
		display: 'Females 35 to 54 Years',
		active: false
	},
	{
		value: 'total_pop_female_55_to_64',
		display: 'Females 55 to 64 Years',
		active: false
	},
	{
		value: 'total_pop_female_65_to_74',
		display: 'Females 65 to 74 Years',
		active: false
	},
	{
		value: 'total_pop_male_less_65_with_health_insur',
		display: 'Males Under 65 With Health Insurance ',
		active: false
	},
	{
		value: 'total_pop_male_less_65_no_health_insur',
		display: 'Males Under 65 No Health Insurance',
		active: false
	},
	{
		value: 'total_pop_female_less_65_with_health_insur',
		display: 'Females Under 65 With Health Insurance',
		active: false
	},
	{
		value: 'total_pop_female_less_65_no_health_insur',
		display: 'Female Under 65 No Health Insurance',
		active: false
	},
	{
		value: 'percent_below_poverty_level',
		display: '% of Population Below Poverty Level',
		active: false
	}
];

class DemographicsMap extends React.Component {
	constructor(props) {
		super(props);
		let initMayById = _.find(PopOptions, x => x.active === true).value;

		this.state = {
			mapBy: 'zipcode',
			mapById: initMayById
		};

		this.onZipcodeSelected = this.onZipcodeSelected.bind(this);
		this.onCountySelected = this.onCountySelected.bind(this);
		this.onSelectionChange = this.onSelectionChange.bind(this);
	}

	onSelectionChange(e) {
		let state = this.state;
		state.mapById = e.target.value;
		this.setState(state);
	}

	onZipcodeSelected(e) {
		let state = this.state;
		state.mapBy = 'zipcode';
		this.setState(state);
	}
	onCountySelected(e) {
		let state = this.state;
		state.mapBy = 'county';
		this.setState(state);
	}

	get loaded() {
		return this.props.demographics && this.props.marketData;
	}
	get center() {
		let result = [];
		let provider = this.props.provider;
		if (provider) {
			result.push(provider.lat);
			result.push(provider.lng);
		}
		return result;
	}

	BuildCompetitorPopup(Competitor) {
		return (
			<ul class="list-group">
				<li class="list-group-item">
					<b> {Competitor.prvdr_num + ' ' + Competitor.name} </b>{' '}
				</li>{' '}
				<li class="list-group-item">
					PSA Cases: <span class="badge"> {format.getInt(Competitor.total_cases_psa)} </span>{' '}
				</li>{' '}
				<li class="list-group-item">
					SSA Cases: <span class="badge"> {format.getInt(Competitor.total_cases_ssa)} </span>{' '}
				</li>{' '}
				<li class="list-group-item">
					Total Cases: <span class="badge"> {format.getInt(Competitor.total_cases)} </span>{' '}
				</li>{' '}
				<li class="list-group-item">
					Total Days Care:{' '}
					<span class="badge"> {format.getInt(Competitor.overall_total_cases)} </span>{' '}
				</li>{' '}
			</ul>
		);
	}

	get markers() {
		let result = [];
		let provider = this.props.provider;

		if (this.props.marketData && this.props.marketData.PSACompetitors) {
			let Competitors = this.props.marketData.PSACompetitors;
			// Build the FOI Popup
			let prvdr = _.find(Competitors, x => x.prvdr_num == provider.providerId);

			if (prvdr) {
				let Marker = new Map.MapMarker();
				Marker.content = this.BuildCompetitorPopup(prvdr);
				Marker.lat = prvdr.prvdr_intplat;
				Marker.lng = prvdr.prvdr_intlong;
				result.push(Marker);
			} else {
				// FOI was not in the Competitor List
				let marker = new Map.MapMarker(provider);
				result.push(marker);
			}
			Competitors.forEach(x => {
				if (x.prvdr_num != provider.providerId) {
					let Marker = new Map.MapMarker();
					Marker.content = this.BuildCompetitorPopup(x);
					Marker.lat = x.prvdr_intplat;
					Marker.lng = x.prvdr_intlong;
					result.push(Marker);
				}
			});
		}

		return result;
	}

	ZipCodePopup(x, perPSA) {
		return `
			<div class="popup-content">
				<div>${x.zipcode || ''}: ${perPSA || ''}% </div>
			</div>	
			`;
	}
	CountyCodePopup(x, perPSA) {
		return `
			<div class="popup-content">
				<div>${x.county || ''}: ${perPSA || ''}% </div>
			</div>	
			`;
	}

	get geoShapes() {
		let results = [];
		if (this.state.mapBy == 'zipcode') {
			if (this.props.demographics && this.props.demographics.Zipcode.length > 0) {
				let zips = this.props.demographics.Zipcode[0].metrics;

				zips.forEach(x => {
					if (x && x.geom_id) {
						let getColor = '';
						let val = 0;

						const metric = x[this.state.mapById];
						const total = x['total_population'];

						if (total && total != 0) val = format.toInt((metric / total) * 100);

						if (val >= 45) {
							getColor = Map.MapColors.Best;
						} else if (val >= 30 && val < 45) {
							getColor = Map.MapColors.Good;
						} else if (val >= 15 && val < 30) {
							getColor = Map.MapColors.Poor;
						} else if (val < 15) {
							getColor = Map.MapColors.Worst;
						} else {
							getColor = Map.MapColors.Worst;
						}

						let obj = new Map.MapGeoObject(
							x.geom_id,
							x.zipcode,
							x.zipcode,
							getColor,
							this.ZipCodePopup(x, val)
						);
						results.push(obj);
					}
				});
			}
		} else if (this.state.mapBy === 'county') {
			if (this.props.demographics && this.props.demographics.County.length > 0) {
				let counties = this.props.demographics.County[0].metrics;

				counties.forEach(x => {
					if (x && x.geom_id) {
						let getColor = '';
						let val = 0;

						const metric = x[this.state.mapById];
						const total = x['total_population'];

						if (total && total !== 0) val = format.toInt((metric / total) * 100);

						if (val >= 45) {
							getColor = Map.MapColors.Best;
						} else if (val >= 30 && val < 45) {
							getColor = Map.MapColors.Good;
						} else if (val >= 15 && val < 30) {
							getColor = Map.MapColors.Poor;
						} else if (val < 15) {
							getColor = Map.MapColors.Worst;
						} else {
							getColor = Map.MapColors.Worst;
						}

						let obj = new Map.MapGeoObject(
							x.geom_id,
							x.county,
							x.county,
							getColor,
							this.CountyCodePopup(x, val)
						);
						results.push(obj);
					}
				});
			}
		}

		return results;
	}

	render() {
		// let countyMetrics = null;
		// if (this.loaded) {
		// 	let providerId = this.props.provider.providerId;
		// 	let prvdrResults = this.props.demographics.result.find(x => x.providerId == providerId);
		// 	countyMetrics = prvdrResults.County[0].metrics[0];
		// }

		// Load Markers

		// Load GeoShapes

		const ListGroupItems = Options => {
			let result = [];

			Options.forEach(x => {
				if (x.active)
					result.push(
						<option key={x.value} value={x.value}>
							{' '}
							{x.display}{' '}
						</option>
					);
				else
					result.push(
						<option key={x.value} value={x.value}>
							{' '}
							{x.display}{' '}
						</option>
					);
			});
			return result;
		};

		return (
			<div
				className="col-md-12"
				style={{
					marginBottom: '10px'
				}}
			>
				<div className="row">
					<div className="col-8">
						<FormGroup controlId="formControlsSelect">
							<FormControl
								componentClass="select"
								placeholder="select"
								value={this.state.mayById}
								onChange={this.onSelectionChange}
								style={{
									borderWidth: '2px',
									marginTop: '10px',
									maxWidth: '400px'
								}}
							>
								{ListGroupItems(PopOptions)}{' '}
							</FormControl>{' '}
						</FormGroup>{' '}
					</div>{' '}
					<div className="col-4">
						<div className="text-center">
							<lead> Geographic Boundaries Displayed: </lead>{' '}
							<div className="btn-group">
								<button type="button" class="btn btn-primary" onClick={this.onZipcodeSelected}>
									Zipcode{' '}
								</button>{' '}
								<button type="button" class="btn btn-primary" onClick={this.onCountySelected}>
									County{' '}
								</button>{' '}
							</div>{' '}
						</div>{' '}
					</div>{' '}
				</div>{' '}
				<div className="row">
					<div className="col-md-12">
						<Map.DataMap
							key={this.state.mapBy + '_' + this.state.mapById}
							center={this.center}
							markers={this.markers}
							zoomControl={true}
							scrollWheelZoom={false}
							doubleClickZoom={true}
							dragging={true}
							zoom={8}
							geoShapes={this.geoShapes}
							height={'300px'}
						/>{' '}
					</div>{' '}
					<div className="col-md-12">
						{' '}
						{Legend(
							new LegendItem(Map.MapColors.Best, 'Over 45 % of Population'),
							new LegendItem(Map.MapColors.Good, '30 - 45 % of Population'),
							new LegendItem(Map.MapColors.Poor, '15 - 30 % of Population'),
							new LegendItem(Map.MapColors.Worst, 'Less Than 15 % of Population')
						)}{' '}
					</div>{' '}
				</div>{' '}
			</div>
		);
	}
}

export default DemographicsMap;
