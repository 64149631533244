const FOI = providers => {
	if (providers && Array.isArray(providers) && providers.length) {
		return providers[0];
	} else if (providers) return providers;
	else return '';
};

export const CompetitorList = (foi, MarketShareData) => {
	let provider = FOI(foi);
	let result = [provider];

	if (
		MarketShareData &&
		MarketShareData.PSACompetitors &&
		MarketShareData.PSACompetitors.length > 0
	) {
		MarketShareData.PSACompetitors.forEach(x => {
			if (x.prvdr_num !== provider && x.name !== 'Total' && x.prvdr_num !== 'other') {
				result.push(x.prvdr_num);
			}
		});
	}
	return result;
};

export const CompetitorNames = (foi, MarketShareData) => {
	let provider = { prvdr_num: foi.providerId, name: foi.name };
	let result = [provider];

	if (
		MarketShareData &&
		MarketShareData.PSACompetitors &&
		MarketShareData.PSACompetitors.length > 0
	) {
		MarketShareData.PSACompetitors.forEach(x => {
			if (x.prvdr_num !== provider.prvdr_num && x.name !== 'Total' && x.prvdr_num !== 'other') {
				result.push({ prvdr_num: x.prvdr_num, name: x.name });
			}
		});
	}
	return result;
};
