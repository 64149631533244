import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../tools/datagrid';
import QualityTab from './QualityTab';
import LoadingSpinner from '../../tools/loadingSpinner';
import * as providerActions from '../../core/actions/GlobalActions';
import * as misc from '../../lib/misc';
import NoData from '../../core/components/notifications/NoData';
import * as SnfActions from '../Common/SkilledNursingActions';
import _ from 'lodash';
import $ from 'jquery';

const mapStateToProps = store => {
	return {
		dataInspections: store.snfInspectionReportService.inspections,
		datadeficiencies: store.snfInspectionReportService.deficiencies,
		dataLoaded: store.snfInspectionReportService.fetched,
		dataLoading: store.snfInspectionReportService.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProviderInfo: ids => dispatch(providerActions.findProviders(ids)),
		getFileService: ids => dispatch(SnfActions.snfInspectionReportService(ids))
	};
};

class QualityInspectionReports extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.dataLoaded === false && this.props.dataLoading === false) {
			this.props.getFileService(misc.getFacilityIds());
		}
	}

	componentDidUpdate() {
		$('.MH-InfoTooltip').popover();
	}

	GenerateColumns() {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'title',
				MHGrid.DataGridHeaderBuilder('Inspection', 'left', 150),
				'150',
				MHGrid.ColType_Link,
				'url'
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'correction_date',
				MHGrid.DataGridHeaderBuilder('Correction', 'center', 55),
				'55',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'scope',
				MHGrid.DataGridHeaderBuilder('Scope', 'center', 75),
				'75',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'severity',
				MHGrid.DataGridHeaderBuilder('Severity', 'center', 150),
				'150',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'deficiencies',
				MHGrid.DataGridHeaderBuilder('Deficiencies', 'center', 75),
				'75',
				MHGrid.ColType_Integer
			)
		);
		return Cols;
	}

	InspectionDeficiencies() {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'tag_group',
				MHGrid.DataGridHeaderBuilder('Deficiency Group', 'center', 300),
				'300',
				MHGrid.ColType_Text,
				'url'
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'date_of_incident',
				MHGrid.DataGridHeaderBuilder('Latest Deficiency Flag', 'center', 75),
				'75',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'scope_severity_code',
				MHGrid.DataGridHeaderBuilder('Severity Code', 'center', 75),
				'75',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'prior_severity_code',
				MHGrid.DataGridHeaderBuilder('Previous Severity Code', 'center', 75),
				'75',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'current_incident_rate',
				MHGrid.DataGridHeaderBuilder('Latest Deficiency Count', 'center', 75),
				'75',
				MHGrid.ColType_Integer
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'incident_trend',
				MHGrid.DataGridHeaderBuilder('Incident Frequency', 'center', 75),
				'75',
				MHGrid.ColType_Text
			)
		);
		return Cols;
	}

	render() {
		let data = this.props.dataInspections;
		let datadef = this.props.datadeficiencies;
		if (this.props.dataLoading) {
			return <LoadingSpinner />;
		} else {
			if (this.props.dataLoaded && !this.props.dataLoading) {
				let results = [];
				let _title = <span className="text-nowrap">{`2567 Inspections`}</span>;
				results.push(
					<MHGrid.DataGrid
						suppressLines={true}
						columns={this.GenerateColumns()}
						rows={data || []}
						subcategory={_title}
					/>
				);
				let two_title = (
					<div>
						{`Facility Deficiency Trends`}
						<small>
							<i
								className=" pl-2 MH-InfoTooltip fa fa-info-circle"
								aria-hidden="true"
								data-toggle="popover"
								data-trigger="hover"
								data-content={`Deficiencies are grouped by F-Tag category to consolidate scores into their respective operational category. Note Severity Codes are scored A-L with L being the most egrarious deficiency score.`}
							/>
						</small>
					</div>
				);

				results.push(
					<QualityTab {...this.props} Cols={this.props.Cols} Sections={this.props.Sections} />
				);

				return (
					<div className="panel panel-default">
						{results}
						{this.props.children}
					</div>
				);
			} else {
				return <NoData />;
			}
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityInspectionReports);
