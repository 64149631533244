import * as Types from '../Common/ActionTypes';

const defaultState = {
	data: [],
	error: null,
	fetching: false,
	fetched: false
};

export const demographicsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case Types.SMD_LOAD_DEMOGRAPHICS + '_PENDING': {
			return { ...state, fetching: true, fetched: false, data: null };
		}
		case Types.SMD_LOAD_DEMOGRAPHICS + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				data: action.payload.data.result
			};
		}
		case Types.SMD_LOAD_DEMOGRAPHICS + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				data: null,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};
