import React from 'react';
import { connect } from 'react-redux';
import QualityDetail from './QualityDetail';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import * as misc from '../../lib/misc';
import Auth from '../../core/Security/Auth';
import LoadingSpinner from '../../tools/loadingSpinner';
import LandingPage from '../../core/DefaultPages/marketingInfo/HospQualityInfo';
import * as whereBuilder from '../../lib/whereBuilder';
import * as Actions from '../../core/actions/ActionHelper';
import * as ActionTypes from '../Common/ActionTypes';
import * as fieldDefActions from './../../core/actions/GlobalActions';
import * as quality from './QualityFields';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.hospQualityData.whereChanged,
		data: store.hospQualityData.Benchmark,
		dataLoaded: store.hospQualityData.fetched,
		dataLoading: store.hospQualityData.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		//BenchmarkData: providers => dispatch(QualityActions.fetchQualityBenchmarkData(providers)),
		GetSummaryData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.QUALITY_GET_METRICS,
					providers,
					'hosp',
					quality.SummaryFields()
				)
			),
		GetPatientExperiences: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.QUALITY_GET_METRICS,
					providers,
					'hosp',
					quality.patientExperiences
				)
			),
		GetReadCompData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.QUALITY_GET_METRICS,
					providers,
					'hosp',
					quality.ReadmissionCompFields()
				)
			),
		GetTimelyEffectiveCareData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.QUALITY_GET_METRICS,
					providers,
					'hosp',
					quality.TimelyEffectiveCareFields()
				)
			),
		GetHealthcareAccocInfectsData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.QUALITY_GET_METRICS,
					providers,
					'hosp',
					quality.HealthAssocInfectFields
				)
			),
		GetMedicalImagingData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.QUALITY_GET_METRICS,
					providers,
					'hosp',
					quality.MedicalImagingFields
				)
			),
		GetPsychiatricUnitServicesData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.QUALITY_GET_METRICS,
					providers,
					'hosp',
					quality.PsychiatricUnitServicesFields()
				)
			),
		GetPaymentValueCareData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.QUALITY_GET_METRICS,
					providers,
					'hosp',
					quality.PaymentValueCareFields()
				)
			),

		updateWhereChange: () => dispatch(fieldDefActions.sessionStorageWhereChange()),
		GetFieldInfo: (fields, basis) => {
			dispatch(fieldDefActions.AddNewFields(fields, basis, 'hosp'));
		}
	};
};

class QualityDetailPage extends React.Component {
	constructor(props) {
		super(props);

		if (!sessionStorage['competitors']) {
			sessionStorage.setItem('competitors', []);
		}
		this.initFacilityInfo();
		this.resetBenchmarkSettings = this.resetBenchmarkSettings.bind(this);
	}

	initFacilityInfo() {
		if (this.props.match && this.props.match.params && this.props.match.params.providerId) {
			let currentFacility = misc.getFacilityIds();
			let facility = this.props.match.params.providerId;
			if (currentFacility.indexOf(facility) < 0) {
				misc.clearFOIandCompetitors();
			}
		}
		whereBuilder.storeDefaultWhere('hosp', 'quality');
	}
	getBenchmarkData() {
		var ids = [];
		if (Auth.isUserHospAuthenticated()) {
			ids = ids.concat(misc.getFOI_CompetitorsIds());
			this.props.GetFieldInfo(quality.All(), this.props.FieldDesc);
			if (!(this.props.dataLoaded || this.props.dataLoading) && ids.length > 0) {
				this.props.GetSummaryData(ids);
				this.props.GetPatientExperiences(ids);
				this.props.GetReadCompData(ids);
				this.props.GetTimelyEffectiveCareData(ids);
				this.props.GetHealthcareAccocInfectsData(ids);
				this.props.GetMedicalImagingData(ids);
				this.props.GetPsychiatricUnitServicesData(ids);
				this.props.GetPaymentValueCareData(ids);
			}
		}
	}

	resetBenchmarkSettings() {
		var ids = [];
		if (Auth.isUserHospAuthenticated()) {
			this.props.updateWhereChange();
			ids = ids.concat(misc.getFOI_CompetitorsIds());
			if (!(this.props.dataLoaded || this.props.dataLoading) && ids.length > 0) {
				this.props.GetSummaryData(ids);
				this.props.GetPatientExperiences(ids);
				this.props.GetReadCompData(ids);
				this.props.GetTimelyEffectiveCareData(ids);
				this.props.GetHealthcareAccocInfectsData(ids);
				this.props.GetMedicalImagingData(ids);
				this.props.GetPsychiatricUnitServicesData(ids);
				this.props.GetPaymentValueCareData(ids);
			}
		}
	}

	UNSAFE_componentWillMount() {
		this.getBenchmarkData();
	}

	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.getBenchmarkData();
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	getDetail() {
		return (
			<div className="container" id="qualityMetrics">
				<QualityDetail
					{...this.props}
					prvdr={misc.getFOIandCompetitors()}
					fieldDef={this.props.FieldDesc}
					updateInfo={this.resetBenchmarkSettings}
				/>
			</div>
		);
	}

	render() {
		if (Auth.isUserHospAuthenticated()) {
			if (this.props.dataLoaded) {
				return (
					<>
						<PageNavigation selectedTab="3" domain="hosp" />
						<section id="content">{this.getDetail()}</section>
					</>
				);
			} else {
				return (
					<div className="container">
						<div className="row">
							<LoadingSpinner />
						</div>
					</div>
				);
			}
		} else {
			return <LandingPage domain="hosp" />;
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityDetailPage);
