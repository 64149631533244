import React from 'react';
import { FormControl, FormGroup, Button } from 'react-bootstrap';
import { faExternalLinkAlt } from '@fortawesome/fontawesome-free-solid';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../tools/loadingSpinner';
import { exportToCsv, exportToXlsx } from '../../../lib/ExportUtil';
import matchSorter from 'match-sorter';

const lightText = {
	fontSize: '13px',
	color: '173C49'
};

const mapStateToProps = store => {
	return {
		store: store.archiveDatasetDetails
	};
};

class datasetDetails extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.onSelectionChange = this.onSelectionChange.bind(this);
		this.onExport = this.onExport.bind(this);
	}
	getHeader(column) {
		return column
			.split('_')
			.map(val => val.charAt(0).toUpperCase() + val.substr(1).toLowerCase())
			.join(' ');
	}
	getColumns() {
		const columns = [],
			dataList = this.props.store.dataset_details;

		Object.keys(dataList[0]).forEach((key, dataList) => {
			let rightAlign = typeof dataList === 'number' ? 'textRightAlign' : '';
			columns.push({
				accessor: key,
				Header: this.getHeader(key),
				minWidth: 200,
				maxWidth: 500,
				className: rightAlign,
				filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: [key] }),
				filterAll: true,
				Cell: props =>
					typeof props.value === 'number' ? (
						<span>{Number(props.value).toLocaleString()}</span>
					) : (
						<span>{props.value}</span>
					)
			});
		});
		return columns;
	}
	onExport() {
		exportToCsv(this.props.selectedDataset.title + '.csv', this.props.store.dataset_details);
	}
	onSelectionChange(e) {
		let selectedValue = parseInt(e.target.value, 10);
		this.props.onDatasetSelected(selectedValue);
	}
	getSelectOptionsHtml() {
		return this.props.archive_datasets.map(dataset => {
			return (
				<option key={dataset.id} value={dataset.id}>
					{' '}
					{dataset.title}{' '}
				</option>
			);
		});
	}
	render() {
		return (
			<div className="container-fluid">
				<div className="row mx-0 px-0">
					<div className="col-lg-5 col-sm-12 ml-auto mr-0 px-0">
						<FormGroup controlId="formControlsSelect">
							<FormControl
								componentClass="select"
								placeholder="select"
								value={this.props.selectedDataset.id}
								onChange={this.onSelectionChange}
								className="col"
								style={{ borderWidth: '2px', marginTop: '10px' }}
							>
								{this.getSelectOptionsHtml()}
							</FormControl>
						</FormGroup>
					</div>
					<div className="col-lg-5 col-sm-12 mr-auto ml-0 px-0">
						<p className="pull-right">
							<div style={lightText}>
								Last Updated Date: {this.props.selectedDataset.last_update_date}
							</div>
							<div style={lightText}>File Source: {this.props.selectedDataset.source}</div>
						</p>{' '}
					</div>
				</div>
				{this.props.store.fetched ? (
					<div className="container-fluid">
						<div className="row mx-0 px-0">
							<div className="col-lg-10 col-md-12 mx-auto  px-0">
								<ReactTable
									data={this.props.store.dataset_details}
									filterable
									columns={this.getColumns()}
									pageSizeOptions={[50, 100, 500]}
									defaultPageSize={25}
									className="-striped -highlight"
								/>
							</div>
						</div>
						<div className="row mx-0 px-0">
							<div className="col-lg-10 col-md-12 mx-auto px-0">
								<p className="pull-right mt-3" style={{ paddingLeft: '10px' }}>
									<Button
										className="btn py-0 my-0"
										bsSize="xsmall"
										onClick={this.onExport}
										style={{ backgroundColor: '#4FB591', color: 'white', borderRadius: '0' }}
									>
										<div className="row mx-1">
											<div className="p-0">
												<FontAwesomeIcon icon={faExternalLinkAlt} className="fa fa-lg p-0 m-0" />
											</div>
											<div
												className="pl-1 m-1 d-none d-sm-flex"
												style={{ fontSize: '7pt', fontWeight: '600' }}
											>
												Export
												<br />
												Report
											</div>
										</div>
									</Button>
								</p>
							</div>
						</div>
					</div>
				) : (
					<LoadingSpinner />
				)}
			</div>
		);
	}
}
export default connect(mapStateToProps)(datasetDetails);
