import React from 'react';
import * as misc from '../../lib/misc';
import * as whereBuilder from '../../lib/whereBuilder';
import Dialog from '../../tools/model-popup';
import HospFilter from '../../core/components/searchBar/hosp-filter-dialog';
class HospPeerGroup extends React.Component {
	constructor(props) {
		super(props);
		this.onApply = this.props.updateBenchmark;
		this.tabChanged = this.tabChanged.bind(this);
		this.modalId = this.props.ModalId;
		this.updateBenchmark = this.updateBenchmark.bind(this);
		this.state = {
			selectedTab: 0,
			customGroup: [],
			customLabel: []
		};
		whereBuilder.storeDefaultWhere('hosp');
	}

	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}
	tabChanged(selectedTab) {
		let state = this.state;
		state.selectedTab = selectedTab;
		this.setState(state);
	}

	onValueChange(value, refKey, label) {
		let state = this.state;
		state.customGroup[refKey] = value;
		state.customLabel[refKey] = {
			labels: label,
			keys: Array.isArray(value) ? value : [value]
		};
		this.setState(state);
	}

	updateBenchmark(filter) {
		let state = this.state;
		// operator_key:
		// 1 >=,2 <=, 3 =, 4 <>, 5 in,
		// 6 notIn, 7 between, 8 notBtwn, 9 and, 10 or
		let customBenchFilter = [];
		for (let i = 0; i < filter.length; i++) {
			let f = filter[i];

			customBenchFilter.push({
				arguments: f.values,
				operator: 5,
				FieldName: f.key
			});
		}

		sessionStorage.setItem('customBenchmark', JSON.stringify(customBenchFilter));
		let group = '';
		// for (var item in this.state.customLabel) {
		// 	let itm = this.state.customLabel[item];
		// let itmKey =
		// 	optionSelect.indexOf(item) > -1
		// 		? Array(
		// 				itm.keys.map(a => {
		// 					return a.label;
		// 				})
		// 		  ).join(',')
		// 		: Array(itm.keys).join(',');
		// if (itm.keys.length > 0 && itmKey.length > 1) {
		// 	group += (group.length > 1 ? ' | ' : '') + itm.labels + ': ' + itmKey;
		// }
		// }
		sessionStorage.setItem('custom-peer-group-description', group);

		this.onApply();
	}

	render() {
		let customFilters = misc.getCustomBenchmark();
		let initFilters =
			customFilters && Array.isArray(customFilters)
				? customFilters.map(x => {
						return { key: x.FieldName, values: x.arguments };
				  })
				: [];
		let id = this.modalId || misc.Guid();

		return (
			<Dialog id={id} title="Customize Current Peer Group">
				<HospFilter
					cols="3"
					model={true}
					onFilterChanged={this.updateBenchmark}
					initFilters={initFilters}
				/>
			</Dialog>
		);
	}
}
export default HospPeerGroup;
