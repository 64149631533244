import React, {Fragment} from 'react';
import Subscribe from '../../../components/navigation/subscribe';
import * as misc from '../../../../lib/misc';
export default () => {
	return (
		<Fragment>
			<section id="content">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1>Graph Anything Export All</h1>
						</div>
						<div className="col-sm-8 col-12">
							<p>Export charts to efficiently build in-house presentations and reports.</p>
						</div>
						<Subscribe />
					</div>
				</div>
			</section>
			<section className="bgBlue">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2>Chart library features:</h2>
						</div>
						<div className="col-md-6 col-12">
							<img src="/images/chart_example.png" alt="Laptop displaying chart library" />
						</div>
						<div className="col-md-6 col-12">
							<ul className="list-unstyled">
								<li>
									Export Any Metric
								</li>
								<li>
									Evaluate any metric against the national benchmark
								</li>
								<li>
									Plot any facility's performance
								</li>
								<li>
									Export any Cost Report
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
};
