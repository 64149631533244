export const FINANCIAL_GET_INC_METRICS = 'HOSP_FINANCIAL_GET_INCOME_METRICS';
export const FINANCIAL_GET_ASSETS_METRICS = 'HOSP_FINANCIAL_GET_ASSET_METRICS';
export const FINANCIAL_GET_CALLOUT_METRICS = 'HOSP_FINANCIAL_GET_CALLOUT_METRICS';
export const HOSP_COST_REPORT_EXPORT_LIST = 'HOSP_COST_REPORT_EXPORT_LIST';
export const HOSP_SUMMARY_QUALITY_METRICS = 'HOSP_SUMMARY_QUALITY_METRICS';

export const SMD_Load_MarketShareData = 'SMD_LOAD_MARKETSHAREDATA';
export const SMD_Load_MarketPathways = 'SMD_LOAD_MARKETPATHWAYS';
export const SMD_Load_CompetitorBenchmarks = 'SMD_LOAD_COMPETITOR_BENCHMARKS';
export const SMD_LOAD_DEMOGRAPHICS = 'SMD_LOAD_DEMOGRAPHICS';
export const SMD_LOAD_INPATIENT = 'SMD_LOAD_INPATIENT';
export const SMD_PSA_COMPETITOR = 'SMD_PSA_COMPETITOR';
export const SMD_COMPETITORS_GET_METRICS = 'SMD_COMPETITORS_GET_METRICS';

export const DATAREQUEST_GET = 'DATAREQUEST_GET';

export const QUALITY_GET_METRICS = 'QUALITY_GET_METRICS';
export const SAVED_BENCHMARKS = 'GETSAVEDBENCHMARKS';
export const QUARTILE_CHART_FIELDS = 'QUALITY_GET_METRICS';
export const FIELD_BENCHMARK_CHART = 'FIELD_BENCHMARK_CHART';
export const QUALITY_GET_SUMMARY_METRICS = 'QUALITY_GET_SUMMARY_METRICS';
export const QUALITY_GET_HCAHPS_METRICS = 'QUALITY_GET_HCAHPS_METRICS';
export const QUALITY_GET_READMISSIONCOMP_METRICS = 'QUALITY_GET_READMISSIONCOMP_METRICS';
export const QUALITY_GET_TIMELYEFFECTIVECARE_METRICS = 'QUALITY_GET_TIMELYEFFECTIVECARE_METRICS';
export const QUALITY_GET_HEALTHCARE_INFECTS_METRICS = 'QUALITY_GET_HEALTHCARE_INFECTS_METRICS';
export const QUALITY_GET_IP_PSYCH_METRICS = 'QUALITY_GET_IP_PSYCH_METRICS';
export const QUALITY_GET_MEDICAL_IMAGING_METRICS = 'QUALITY_GET_MEDICAL_IMAGING_METRICS';

export const BENCHMARK_GET_UTILIZATION_METRICS = 'BENCHMARK_GET_UTILIZATION_METRICS';
export const BENCHMARK_GET_LABOR_METRICS = 'BENCHMARK_GET_LABOR_METRICS';
export const BENCHMARK_GET_FINANCIAL_METRICS = 'BENCHMARK_GET_FINANCIAL_METRICS';

export const BENCHMARK_GET_METRICS = 'BENCHMARK_GET_METRICS';
export const SMD_DEMOGRAPHICS = 'SMD_DEMOGRAPHICS';
