import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import DemographicsMap from './DemographicsMap';
import ReChartTool from '../../../tools/charts/ReChartTool';
import * as MHGrid from '../../../tools/datagrid';
import * as MHGridGroup from '../../../tools/datagrid/extras/DataGridGroupHeader';
import * as misc from '../../../lib/misc';
import LoadingSpinner from '../../../tools/loadingSpinner';
import * as Actions from '../../../core/actions/ActionHelper';
import * as ActionTypes from '../../Common/ActionTypes';
import * as Fields from './DemographicsFields';
import * as whereBuilder from '../../../lib/whereBuilder';
import * as styles from '../../../core/styles/CustomStyle';
import _ from 'lodash';
import * as API from '../../../lib/ApiUtil';

const quickFactCols = new MHGrid.DataGridColumns(
	new MHGrid.DataGridColumn(
		'fld_desc',
		new MHGrid.DataGridHeader('Description', '', '', 'left'),
		350,
		MHGrid.ColType_Text
	),
	new MHGrid.DataGridColumn(
		'county',
		MHGrid.DataGridHeaderBuilder('County', 'center', '150'),
		175,
		MHGrid.ColType_Text
	),
	new MHGrid.DataGridColumn(
		'state',
		MHGrid.DataGridHeaderBuilder('State', 'center', '150'),
		175,
		MHGrid.ColType_Text
	),
	new MHGrid.DataGridColumn(
		'national',
		MHGrid.DataGridHeaderBuilder('National', 'center', '150'),
		175,
		MHGrid.ColType_Text
	)

);

class Demographics extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentYear: this.props.data.ActiveYear,
			providers: (this.props.facilityId && this.props.facilityId) || [],
			quickFacts: []
		};

		this.onYearChanged = this.onYearChanged.bind(this);
		this.loadQuickFacts = this.loadQuickFacts.bind(this);
	}
	onYearChanged(year) {
		this.props.data.ActiveYear = year;
		let state = this.state;
		state.currentYear = year;
		this.setState(state);
	}

	async loadQuickFacts() {
		let data = await API.APIPost('/quick-facts-county', {prvdr_num: this.props.facilityId, domain: 'snf'});
		let state = this.state;
		state.quickFacts = data.data.result;
		this.setState(state);
	}

	UNSAFE_componentWillMount() {
		this.loadQuickFacts();
	}

	getData(category) {
		return this.state.quickFacts.filter(x => {return(x.fld_category == category)});
	}

	RenderBody() {
		let Title = new MHGrid.DataGridTitle(
			'County Quick Facts for ' + this.props.facilityInfo.county,
			'',
			'',
			4,
			this.props.data.Years,
			this.onYearChanged
		);

		let providers = misc.getFOIandCompetitors();
		let SummaryCols = MHGridGroup.DataGridGroupHeader(false, providers, 'market');

		const populationTitle = 'Population';
		const sexAgeTitle = 'Sex & Age';
		const raceTitle = 'Race & Hispanic Origin';
		const popCharacterTitle = 'Population Characteristics';
		const housingTitle = 'Housing';
		const familiesTitle = 'Families & Living Arrangements';
		const computerInternetTitle = 'Computer & Internet Use';
		const educationTitle = 'Education';
		const healthTitle = 'Health';
		const economyTitle = 'Economy';
		const transTitle = 'Transportation';
		const incomeTitle = 'Income & Poverty';
		const businessTitle = 'Businesses';
		const geoTitle = 'Geography';

		return (
			<Fragment>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Population')}
					subcategory={populationTitle}
					showHeaders={true}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Age and Sex')}
					subcategory={sexAgeTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Race and Hispanic Origin')}
					subcategory={raceTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Population Characteristics')}
					subcategory={popCharacterTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Housing')}
					subcategory={housingTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Families & Living Arrangements')}
					subcategory={familiesTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Computer and Internet Use')}
					subcategory={computerInternetTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Education')}
					subcategory={educationTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Health')}
					subcategory={healthTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Economy')}
					subcategory={economyTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Transportation')}
					subcategory={transTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Income & Poverty')}
					subcategory={incomeTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Businesses')}
					subcategory={businessTitle}
					showHeaders={false}
				/>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={quickFactCols}
					rows={this.getData('Geography')}
					subcategory={geoTitle}
					showHeaders={false}
				/>
			</Fragment>
		);
	}

	render() {
		const bodyRender = this.state.quickFacts ? this.RenderBody() : <LoadingSpinner />;

		return (
			<div className="marketDemo">
				{bodyRender}
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	let DemographicsWhere = whereBuilder.setDefaultBenchmark('market');

	return {
		LoadDemographics: providers =>
			dispatch(
				Actions.ActionDataRequest(
					ActionTypes.SMD_DEMOGRAPHICS,
					providers,
					'market',
					Fields.All(),
					DemographicsWhere,
					null
				)
			)
	};
};

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.market,
		whereChanged: store.snfDemographics.whereChanged,
		data: store.snfDemographics.Benchmark,
		dataLoaded: store.snfDemographics.fetched,
		dataLoading: store.snfDemographics.fetching
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Demographics);
