import * as Types from '../Common/ActionTypes';
import { ReducerHelper, DefaultState } from '../../core/reducers/ReducerHelper';

export const snfQualityReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.QUALITY_GET_METRICS, state, action);
};

const snfFiles = {
	fetching: false,
	fetched: false,
	inspections: [],
	deficiencies: [],
	scores: []
};

export const snfInspectionReportReducer = (state = snfFiles, action) => {
	switch (action.type) {
		case Types.SNF_FILE_SERVICE_LIST + '_PENDING': {
			return { ...state, fetching: true, fetched: false, files: [] };
		}
		case Types.SNF_FILE_SERVICE_LIST + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				inspections: action.payload.data.result.inspections || [],
				deficiencies: action.payload.data.result.deficiencies || [],
				scores: action.payload.data.result.scores || []
			};
		}
		case Types.SNF_FILE_SERVICE_LIST + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};

const snfcFiles = {
	fetching: false,
	fetched: false,
	costReports: []
};

export const snfCostReportExportReducer = (state = snfcFiles, action) => {
	switch (action.type) {
		case Types.SNF_COST_REPORT_EXPORT_LIST + '_PENDING': {
			return { ...state, fetching: true, fetched: false, files: [] };
		}
		case Types.SNF_COST_REPORT_EXPORT_LIST + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				costReports: action.payload.data.result.costReports || []
			};
		}
		case Types.SNF_COST_REPORT_EXPORT_LIST + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};
