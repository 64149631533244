import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';

import { OverlayTrigger, Popover } from 'react-bootstrap';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp
	};
};

const buildMetricDesc = (FieldDescription = '') => {
	return FieldDescription;
};

const InfoTooltip = class InfoTooltip extends React.Component {
	componentDidMount() {
		$('.MH-InfoTooltip').popover();
	}
	render() {
		return (
			<div
				className="MH-InfoTooltip mx-2"
				role="button"
				data-toggle="popover"
				data-trigger="hover"
				data-content={buildMetricDesc(this.props.fieldDef.FieldDescription)}
			>
				<i className="fa fa-info-circle" aria-hidden="true" />
			</div>
		);
	}
};

export default connect(mapStateToProps)(InfoTooltip);
