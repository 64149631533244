import _ from 'lodash';

export const baseUrl =
	window.location.protocol +
	'//' +
	window.location.hostname +
	(window.location.port && ':' + window.location.port);
	
export const apiUrl = '/api';
export const userUrl = '/user';
export const payUrl = '/payment';
export const authUrl = '/auth';

export const searchOptions = apiUrl + '/search-options';
export const svrUrl = '';
// export const GatewayApiUrl = 'https://api.metrics.modernhealthcare.com';
export const hospSearchApiUrl = baseUrl + '/search-hosp';
export const mainSearchApiUrl = baseUrl + '/search-provider';
export const hhaSearchApiUrl = baseUrl + '/search-hha';
export const snfSearchApiUrl = baseUrl + '/search-snf';

export const hmpMetricsHome = 'https://hmpmetrics.com';

export const toURI = uri => {
	return window.encodeURI(uri);
};

export const Guid = () => {
	function _p8(s) {
		const p = (Math.random().toString(16) + '000000000').substr(2, 8);
		return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
	}
	return 'guid' + _p8() + _p8(true) + _p8(true) + _p8();
};

export const getFacilityIds = () => {
	let foiInfo = sessionStorage.getItem('facilityList');
	let facilityList = foiInfo ? JSON.parse(foiInfo) : [];
	let facilityIds = [];
	if (facilityList && Array.isArray(facilityList)) {
		facilityIds = facilityList.map(facility => {
			return facility.prvdr_num || facility.id;
		});
	}
	return _.union(facilityIds);
};

export function clearFOIandCompetitors() {
	sessionStorage.setItem('competitors', []);
	sessionStorage.setItem('facilityList', []);
}

export function setCurrentFOI(foi) {
	sessionStorage.setItem('competitors', []);
	sessionStorage.setItem('facilityList', JSON.stringify(foi));
	sessionStorage.setItem('customBenchmark', JSON.stringify([]));
	sessionStorage.setItem('custom-peer-group-description', '');
}

export function hasCustomBenchmark() {
	let customBenchmarkObj = sessionStorage.getItem('customBenchmark');
	let customBench = customBenchmarkObj ? JSON.parse(customBenchmarkObj) : [];
	return customBench.length > 0;
}
export function getCustomBenchmark() {
	let customBenchmarkObj = sessionStorage.getItem('customBenchmark');
	let customBench = customBenchmarkObj ? JSON.parse(customBenchmarkObj) : null;
	return customBench;
}

export const getFOI_CompetitorsIds = () => {
	let facilityIds = getFacilityIds();
	let interesting = sessionStorage.getItem('competitors');
	let Competitors = interesting ? JSON.parse(interesting) : null;
	if (Competitors && Competitors !== null && Competitors !== 'null') {
		Competitors.forEach(comp => {
			if (comp && comp !== '') facilityIds.push(comp.prvdr_num);
		});
	}

	return _.union(facilityIds);
};

export const getSegment = () => {
	let prvdr_num = parseInt(
		getFacilityIds()[0]
			.toString()
			.substr(2, 4)
	);
	return prvdr_num < 5000 ? 'hosp' : prvdr_num < 6500 ? 'snf' : 'hha';
};

export const getFOIandCompetitors = () => {
	let foiInfo = sessionStorage.getItem('facilityList');
	let facilityIds = foiInfo ? JSON.parse(foiInfo) : [];
	let competitorObj = sessionStorage.getItem('competitors');
	let Competitors = competitorObj ? JSON.parse(competitorObj) : null;
	if (Competitors && Competitors !== null && Competitors !== 'null') {
		Competitors.forEach(comp => {
			if (comp && comp !== '') facilityIds.push(comp);
		});
	}
	let keys = _.uniq(facilityIds);
	return keys;
};

export const getFacilityInfo = () => {
	let foiInfo = sessionStorage.getItem('facilityList');
	let facilityIds = foiInfo ? JSON.parse(foiInfo) : null;
	if (facilityIds && facilityIds.length > 0) {
		return facilityIds[0];
	} else {
		return null;
	}
};

export const getFacilityName = () => {
	const facilityList = getFOIandCompetitors();
	if (facilityList && facilityList.length) {
		return facilityList.map(facility => {
			var stat = {};
			stat[facility] = facility.name || facility.facility_name;
			return stat;
		});
	}
	return null;
};

export const getFacilityYear = () => {
	// let currentTime = new Date();
	let foiInfo = sessionStorage.getItem('facilityList');
	if (foiInfo === '') return 2017;
	const facilityList = JSON.parse(foiInfo);
	let latestYr = 2017;
	if (facilityList && facilityList.length) {
		latestYr =
			Math.max(...facilityList.map(facility => facility.fy_year)) ||
			Math.max(facilityList.map(facility => facility.fy_year)) ||
			2018;
	}

	return latestYr;
};

export const toString = value => {
	if (value) return value.toString();
	else return '';
};
export const toBool = value => {
	if (value) return value === true || value === 'true';
	else return false;
};
export const toNumeric = value => {
	let result = null;
	try {
		if (value) result = parseFloat(value.toString().replace(/\$|,|%/g, ''));
	} catch (err) {
		result = null;
	}
	return result;
};
export const toInt = value => {
	return Math.round(toNumeric(value));
};

export const round = value => {
	if (value) return Math.round(value * 100) / 100;
	else return 0.0;
};

export const filterMetrics = (rows, filterMetrics = []) => {
	if (rows && Array.isArray(rows) && filterMetrics && Array.isArray(filterMetrics)) {
		let result = [];
		filterMetrics.forEach(fld => {
			let item = _.find(rows, row => {
				return row.fieldName === fld;
			});
			if (item) result.push(item);
		});
		return result;
	} else return [];
};

export const crossCompare = (rows, id = [], comparisons = []) => {
	if (
		rows &&
		Array.isArray(rows) &&
		id &&
		Array.isArray(id) &&
		comparisons &&
		Array.isArray(comparisons)
	) {
		let rowz = JSON.parse(JSON.stringify(rows));
		rowz.forEach(row => {
			if (row[id[0]] && row[id[0]].value) {
				let keycol = row[id[0]].value;
				row[id[0]].value = 1.0;
				row[id[0]].format = 'PERCENT';
				row[id[0]].outlier = '';
				comparisons.forEach(col => {
					let prvdrkey = row[col] && row[col].value ? row[col].value : Number.NaN;
					if (prvdrkey) {
						row[col].value = isFinite(prvdrkey / keycol) ? prvdrkey / keycol : Number.NaN;
						row[col].format = 'PERCENT';
						row[col].outlier = '';
					}
				});
			}
		});

		return rowz;
	} else return [];
};

export const compareTotal = (rows, comparisons = []) => {
	if (rows && Array.isArray(rows) && comparisons && Array.isArray(comparisons)) {
		let rowz = JSON.parse(JSON.stringify(rows));
		rowz.forEach(row => {
			let baseline = 0;
			comparisons.forEach(col => {
				let colVal = row[col] && row[col].value ? row[col].value : Number.NaN;
				baseline += isFinite(colVal) ? colVal : 0;
			});
			comparisons.forEach(col => {
				let prvdrkey = row[col] && row[col].value ? row[col].value : Number.NaN;

				if (prvdrkey) {
					row[col].value = isFinite(prvdrkey / baseline) ? prvdrkey / baseline : Number.NaN;
					row[col].format = 'PERCENT';
					row[col].outlier = '';
				}
			});
		});
		return rowz;
	} else return [];
};

export const PivotDataGroup = (data, pivot, value, group) => {
	return _.chain(data)
		.groupBy(group)
		.map((val, key) => {
			let a = {};
			a[group] = key;
			a.row_total = _.sum(
				val.map(a => {
					return a[value];
				})
			);
			return _.assign(
				a,
				_.chain(val)
					.keyBy(pivot)
					.mapValues(value)
					.value()
			);
		})
		.value();
};

export const Percentile = (arr, p) => {
	if (arr.length === 0) return 0;
	if (typeof p !== 'number') throw new TypeError('p must be a number');
	if (p <= 0) return arr[0];
	if (p >= 1) return arr[arr.length - 1];

	var index = arr.length * p,
		lower = Math.floor(index),
		upper = lower + 1,
		weight = index % 1;
	arr = _.sortBy(arr);
	if (upper >= arr.length) return arr[lower];
	return arr[lower] * (1 - weight) + arr[upper] * weight;
};
