import React from 'react';
import { connect } from 'react-redux';
import QualityDetail from './QualityDetail';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import * as misc from '../../lib/misc';
import Auth from '../../core/Security/Auth';
import LoadingSpinner from '../../tools/loadingSpinner';
import LandingPage from '../../core/DefaultPages/marketingInfo/HospQualityInfo';
import * as Actions from '../../core/actions/ActionHelper';
import * as ActionTypes from '../Common/ActionTypes';
import * as Fields from './QualityFields';
import * as fieldDefActions from './../../core/actions/GlobalActions';
import * as whereBuilder from '../../lib/whereBuilder';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.snfBenchmarkData.whereChanged,
		data: store.snfQualityData.Quality,
		dataLoaded: store.snfQualityData.fetched,
		dataLoading: store.snfQualityData.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetQualityData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.QUALITY_GET_METRICS,
					providers,
					'snf',
					Fields.QualityMetrics()
				)
			),
		updateWhereChange: () => dispatch(fieldDefActions.sessionStorageWhereChange()),
		GetFieldInfo: (fields, basis) => {
			dispatch(fieldDefActions.AddNewFields(fields, basis, 'snf'));
		}
	};
};

class QualityDetailPage extends React.Component {
	constructor(props) {
		super(props);
		if (!sessionStorage['competitors']) {
			sessionStorage.setItem('competitors', []);
		}
		this.initFacilityInfo();
		this.resetQualitySettings = this.resetQualitySettings.bind(this);
	}

	initFacilityInfo() {
		if (this.props.match && this.props.match.params && this.props.match.params.providerId) {
			let currentFacility = misc.getFacilityIds();
			let facility = this.props.match.params.providerId;
			if (currentFacility.indexOf(facility) < 0) {
				misc.clearFOIandCompetitors();
			}
		}
		whereBuilder.storeDefaultWhere('snf', 'quality');
	}
	getQualityData() {
		var ids = [];
		if (Auth.isUserSnfAuthenticated()) {
			ids = ids.concat(misc.getFOI_CompetitorsIds());
			this.props.GetFieldInfo(Fields.All(), this.props.FieldDesc);
			if (!(this.props.dataLoaded || this.props.dataLoading) && ids.length > 0) {
				this.props.GetQualityData(ids);
			}
		}
	}

	resetQualitySettings() {
		var ids = [];
		if (Auth.isUserSnfAuthenticated()) {
			this.props.updateWhereChange();
			ids = ids.concat(misc.getFOI_CompetitorsIds());
			if (!(this.props.dataLoaded || this.props.dataLoading) && ids.length > 0) {
				this.props.GetQualityData(ids);
			}
		}
	}

	UNSAFE_componentWillMount() {
		this.getQualityData();
	}

	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.getQualityData();
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getQualityData();
	}

	render() {
		if (Auth.isUserSnfAuthenticated()) {
			let details = (
				<>
					<QualityDetail
						prvdr={misc.getFOIandCompetitors()}
						fieldDef={this.props.FieldDesc}
						updateInfo={this.resetQualitySettings}
					/>
				</>
			);
			return (
				<>
					<PageNavigation selectedTab="3" domain={'snf'} />
					<section id="content" className="snfQuality">
						<div className="container">{details}</div>
					</section>
				</>
			);
		} else {
			return <LandingPage domain="snf" />;
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityDetailPage);
