import React from 'react';
import * as misc from '../../lib/misc';

export default class Accordion extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			headers: props.headers ? props.headers : [],
			content: props.content ? props.content : [],
			key: misc.Guid()
		};
	}

	render() {
		let Cards = [];

		for (let i = 0; i < this.state.headers.length; i++) {
			Cards.push(
				<div className="card">
					<div className="card-header">
						<a className="collapsed card-link" data-toggle="collapse" href={'#collapse' + i}>
							{this.state.headers[i]}
						</a>
					</div>
					<div
						id={'collapse' + i}
						className={i == 0 ? 'collapse show' : 'collapse'}
						data-parent={'#' + this.state.key}
					>
						<div className="card-body p-0">
							{this.state.content.length > i ? this.state.content[i] : ''}
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="text-primary" id={this.state.key}>
				{Cards}
			</div>
		);
	}
}
