import React from 'react';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import * as misc from '../../lib/misc';
import Auth from '../../core/Security/Auth';
import LoadingSpinner from '../../tools/loadingSpinner';

class Physicians extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <>
        <PageNavigation selectedTab="7" domain="hosp" />
        <section id="content">
          <h2 className="text-center">Detailed Physicians Information</h2>
        </section>
      </>
    );
  }
}

export default(Physicians);