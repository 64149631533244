import axios from 'axios';
import Auth from '../core/Security/Auth';
import * as misc from './misc';

const apiUrl = misc.apiUrl;
const userUrl = misc.userUrl;
const payUrl = misc.payUrl;
const authUrl = misc.authUrl;

export const searchAPI = '/search-provider';

export function CancelToken() {
	return axios.CancelToken.source();
}

export const getCustomHeader = () => {
	try
	{
		let token = Auth.getToken();
		if(token)
		{
			return {
				headers: {
					"Authorization": `${token}`
					}
			};
		}
		else
			return {};
	}
	catch(err)
	{

	}

	
};

export const UserPost = (url, data) => {
	return axios.post(userUrl + url, data, getCustomHeader());
};
export const UserGet = (url, data) => {
	return axios.get(userUrl + url, data, getCustomHeader());
};
export const PayPost = (url, data) => {
	return axios.post(payUrl + url, data, getCustomHeader());
};
export const PayGet = (url, data) => {
	return axios.get(payUrl + url, data, getCustomHeader());
};
export const AuthPost = (url, data) => {
	return axios.post(authUrl + url, data, getCustomHeader());
};
export const AuthGet = (url, data) => {
	return axios.get(authUrl + url, data, getCustomHeader());
};


export const APIPost = (url, data) => {
	return axios.post(apiUrl + url, data, getCustomHeader());
};
export const APIGet = (url, data) => {
	return axios.get(apiUrl + url, data, getCustomHeader());
};
export const isCancel = error => {
	return axios.isCancel(error);
};

const axiosConfig = {
	crossdomain: true,
	headers: {
		Accept: '*/*',
		'Cache-Control': 'no-cache',
		'Content-Type': 'application/json'
	}
};

export const URLPost = (url, data) => {
	return httpRequest(url, 'post', data, null);
};

export const httpRequest = (url, method, payload, headers) => {
	// Configuration to accept json as a default
	const config = {
		method,
		headers: {
			'Content-Type': 'application/json'
		},
		mode: 'cors'
	};
	// method = post and payload, add it to the fetch request
	if (method.toLowerCase() === 'post' && payload && payload.length > 0) {
		config.body = JSON.stringify(payload);
	}
	// if custom headers need to be set for the specific request
	// override them here
	if (headers && typeof headers === 'object' && Object.keys(headers).length > 0) {
		config.headers = headers;
	}
	return fetch(url, config).then(response => {
		// Check if the request is 200
		if (response.ok) {
			let data = response;

			// if the type is json return, interpret it as json
			if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
				data = response.json();
			}
			return data;
		}
		// if an errors, anything but 200 then reject with the actuall response
		return Promise.reject(response);
	});
};
