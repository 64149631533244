import React, { Component } from "react";
import react from "react";
import { Auth } from "aws-amplify";
import AuthUser from "../Security/Auth";
import EditUserInfo from "./EditUserInfo";
import * as UserUtil from '../../lib/UserUtil';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      error: null
    };
    this.editInfo = this.editInfo.bind(this);
    this.submitInfo = this.submitInfo.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  };

  editInfo = e => {
    e.preventDefault();
    let state = this.state;
    state.edit = true;
    this.setState(state);
  };

  async submitInfo(data) {
    let state = this.state;
    try {
      await UserUtil.updateUserProfile(data);
      await AuthUser.resetSession();
      window.location.reload(true);
    }
    catch(err) {
      state.edit = false;
      console.log(err);
    }
    this.setState(state);
    // console.log('---------------');
    // console.log(data);
  };

  handleCancel = e => {
    e.preventDefault();
    let state = this.state;
    state.edit = false;
    this.setState(state);
  };

  render() {
    // console.log(this.props);
    let verified = this.props.userData.Email_Verified;
    return (
      <div id="userInfo">
        {this.state.edit === false ? 
          <>
            <h3>User Info</h3>
            <ul className="list-unstyled">
              <li>Username: <strong>{this.props.userData.User_Name}</strong></li>
              <li>Email: <strong>{this.props.userData.Email}</strong> <span id="verified" className={verified === true ? "yes" : "no"}>{verified === true ? <i className="fas fa-check" title="Email Verified"><span>Your email is verified</span></i> : <i className="fas fa-times-circle" title="Email Not Verified"><span>Your email is not verified</span></i>}</span></li>
              <li>Phone: {this.props.userData.Phone ? <strong>{this.props.userData.Phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}</strong> : <span>Not Provided</span>}</li>
              <li>Company: {this.props.userData.Company.Company ? <strong>{this.props.userData.Company.Company}</strong> : <span>Not Provided</span>}</li>
              <li>Address: {this.props.userData.Company.Address ? <strong>{this.props.userData.Company.Address}</strong> : <span>Not Provided</span>}</li>
              <li>City: {this.props.userData.Company.City ? <strong>{this.props.userData.Company.City}</strong> : <span>Not Provided</span>}</li>
              <li>State: {this.props.userData.Company.State ? <strong>{this.props.userData.Company.State}</strong> : <span>Not Provided</span>}</li>
              <li>Zip: {this.props.userData.Company.Zip ? <strong>{this.props.userData.Company.Zip}</strong> : <span>Not Provided</span>}</li>
              <li>URL: {this.props.userData.Company.Url ? <strong>{this.props.userData.Company.Url}</strong> : <span>Not Provided</span>}</li>
            </ul>
            <input 
              type="submit" 
              className="loginBttn" 
              id="editInfo" 
              name="editInfo" 
              value="Update Information" 
              onClick={this.editInfo} 
            />
            <input 
              type="submit" 
              className="loginBttn" 
              id="changePass" 
              name="changePass" 
              value="Change Password" 
              onClick={this.props.changePass} 
            />
            <input 
              type="submit" 
              className="loginBttn" 
              id="out" 
              name="submit" 
              value="Log Out" 
              onClick={this.props.handleSignOut} 
            />
          </>
        :
          <EditUserInfo
            submitInfo={this.submitInfo} 
            handleCancel={this.handleCancel} 
            userData={this.props.userData} 
            verifyEmail={this.props.verifyEmail} 
          />
        }
      </div>
    );
  }
}

export default(UserInfo);