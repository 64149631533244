import axios from 'axios';
import Auth from '../../core/Security/Auth';
import * as Types from '../Common/ActionTypes';
import * as misc from '../../lib/misc';
import { getCustomHeader } from '../../lib/ApiUtil';

const apiUrl = misc.apiUrl;

export function QuartileFieldList(FieldIds, ProviderId) {
	let PrvdrId = '';
	if (Array.isArray(ProviderId) && ProviderId.length > 0) PrvdrId = ProviderId;
	else PrvdrId = ProviderId;

	return {
		type: Types.QUARTILE_CHART_FIELDS,
		payload: axios.post(
			apiUrl + '/quartile_field_list',
			{ fieldIds: FieldIds, providerId: PrvdrId },
			getCustomHeader()
		)
	};
}
