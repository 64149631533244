import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
class AboutUsPage extends React.Component {
	render() {
		return (
			<div id="about">
				<Helmet>
					<title>About Us | HMP Metrics</title>
					<meta
						name="description"
						content="HMP Metrics is a powerful financial insight tool that assembles HCRIS (Healthcare Cost Report Information System) data into a census and financial database."
					/>
				</Helmet>
				<section id="content">
					<div className="container">
						<div className="row">
							<div className="col-12 text-center">
								<h1>About HMP Metrics</h1>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-7 col-12">
								<h2>Our Product</h2>
								<p>
									HMP Metrics is a powerful tool that delivers insight into the financial and
									strategic position of healthcare facilities into the hands of anyone who needs to
									think analytically about investment in the delivery of care.
								</p>
								<p>
									HMP Metrics assembles Healthcare Cost Report Information System (HCRIS) data from
									more than 600,000 Medicare cost reports — filed by hospitals, nursing homes, home
									health agencies, and other providers — into an electronic database that overlays
									Census and financial data. The result is a dynamic study of a healthcare
									facility's financial and quality performance over the years in a format that
									compares metrics to peer facilities. The data is cleaned and scrubbed in a process
									that's proven to withstand legal scrutiny.
								</p>
							</div>
							<div className="col-lg-5 col-md-6 col-sm-12 bg-img-r anime slideL"></div>
							<div className="col-lg-5 col-md-6 col-sm-12 bg-img-l anime slideR"></div>
							<div className="col-lg-7 col-12">
								<h3>Healthcare Management Partners, LLC (HMP)</h3>
								<p>
									Healthcare Management Partners, LLC ("HMP") is a turnaround and consulting firm
									that specializes in assisting healthcare organizations experiencing current, or
									anticipated financial challenges navigate their way to positive outcomes. Led by
									highly experienced, C-level healthcare operators, HMP has a unique ability to not
									only quickly define and solve problems, but also lead the delivery of the right
									solutions, producing exceptional results for clients and their stakeholders. HMP's
									core services include turnaround management, financial advisory, and litigation
									support and expert testimony. HMP's work has helped dozens of healthcare
									organizations of all kinds and sizes to survive, recover, grow, and thrive in
									their communities—all the while contributing to the provision of high-quality
									healthcare services.
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default class AboutUs extends React.Component {
	render() {
		return (
			<Fragment>
				<AboutUsPage />
			</Fragment>
		);
	}
}
