import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as misc from '../../lib/misc';
import * as fieldDefActions from '../../core/actions/GlobalActions';
import { Redirect } from 'react-router';
import LoadingSpinner from '../../tools/loadingSpinner';
import QString from 'query-string';
import { loginUser } from '../LoginPage/LoginActions';
import Auth from '../Security/Auth';

const mapStateToProps = store => {
	return {
		authenticated: store.auth
	};
};
const mapDispatchToProps = dispatch => {
	return {
		AuthenticateUser: creds => dispatch(loginUser(creds))
	};
};

class CoreLoginPage extends React.Component {
	constructor(props) {
		super(props);
	}

	UNSAFE_componentWillMount() {
		this.initFacilityInfo();
		this.loginRequest = this.query && this.query.CSAuthResp ? this.query.CSAuthResp : '';
	}

	initFacilityInfo() {
		if (!Auth.isUserAuthenticated()) {
			if (this.props.location && this.props.location.search) {
				this.query = QString.parse(this.props.location.search);
				// this.props.AuthenticateUser(
				// 	this.query && this.query.CSAuthResp ? this.query.CSAuthResp : ''
				// );
			}
		}
	}

	render() {
		const result = this.props.authenticated.fetched ? (
			<div className="entry-content">{this.props.children}</div>
		) : (
			<div className="entry-content">{this.props.children}</div>
		);
		return result;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CoreLoginPage);
