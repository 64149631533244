import * as Types from '../Common/ActionTypes';
import { ReducerHelper, DefaultState } from '../../core/reducers/ReducerHelper';

export const hospFinancialReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.FINANCIAL_GET_INC_METRICS, state, action);
};

export const hospSummaryQualityReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.HOSP_SUMMARY_QUALITY_METRICS, state, action);
};


//cost report export addition
const hospcFiles = {
	fetching: false,
	fetched: false,
	costReports: []
};

export const hospCostReportExportReducer = (state = hospcFiles, action) => {
	switch (action.type) {
		case Types.HOSP_COST_REPORT_EXPORT_LIST + '_PENDING': {
			return { ...state, fetching: true, fetched: false, files: [] };
		}
		case Types.HOSP_COST_REPORT_EXPORT_LIST + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				costReports: action.payload.data.result.costReports || []
			};
		}
		case Types.HOSP_COST_REPORT_EXPORT_LIST + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};
