export const premium_access = {
  id: 'PREMIUM', 
  name: 'PREMIUM', 
  price: {
    single: 750,
    twoToFive: 600,
    sixToTen: 450,
    tenPlus: 400,
    unlimited: 25000
  },
  fullName: 'Premium'
};
export const snf_access = {
  id: 'SNF', 
  name: 'SNF', 
  price: {
    single: 395,
    twoToFive: 325,
    sixToTen: 275,
    tenPlus: 225,
    unlimited: 10000
  },
  fullName: 'Skilled Nursing Facilities'
};
export const hospital_access = {
  id: 'HOSP', 
  name: 'HOSP', 
  price: {
    single: 495
  },
  fullName: 'Hospital'
};
export const at_risk = {
  id: 'arReport',
  name: 'atRisk',
  price: {
    single: 250
  },
  fullName: 'At Risk'
};

export const getProduct = (id) => {
  switch(id) {
    case premium_access.id:
      return premium_access;
    case hospital_access.id:
      return hospital_access; 
    case snf_access.id:
      return snf_access;
    case at_risk.id:
      return at_risk;
    default:
      return null;
  }
}