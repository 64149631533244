//testing fields

export const HospFinancialFields = [
	'period_end',
	'days_in_fy',
	'inpatient_charges',
	'outpatient_charges',
	'total_charges_inpatient_and_outpatient',
	'net_patient_revenue',
	'misc_non_patient_revenue',
	'net_patient_rev_and_misc_opr_rev',
	'total_operating_expenses',
	'annual_operating_profit',
	'contributions_etc',
	'income_from_investments',
	'governmental_approp',
	'non_operating_revenue',
	'total_other_expenses',
	'net_income',
	'cash_and_cash_equivalents',
	'net_accounts_receivable',
	'other_current_assets',
	'total_current_assets',
	'annual_total_fixed_assets',
	'total_other_assets',
	'total_assets',
	'total_current_liabilities_bssummary',
	'total_long_term_liabilities_bssummary',
	'total_liabilities_bssummary',
	'total_fund_balances_bssummary',
	'total_liabilities_fundbalances'
];

export const IncomeStatementList = [
	'period_end',
	'days_in_fy',
	'inpatient_charges',
	'outpatient_charges',
	'total_charges_inpatient_and_outpatient',
	'contractual_allowance',
	'net_patient_revenue',
	'total_operating_expenses',
	//'net_income_margin_with_rpa',
	'annual_operating_profit',
	'contributions_etc',
	'income_from_investments',
	'governmental_approp',
	'misc_non_patient_revenue',
	'non_operating_revenue',
	'total_other_expenses',
	'net_patient_rev_and_misc_opr_rev',
	'net_income',
	//'net_income_with_rpa',
	'labor_cost_as_a_percent_of_revenue',
	'outpatient_charges_perc_total_charges',
	'annual_operating_profit',
	'total_profit_margin',
	'nonpatient_rev_perc_netpatient_rev',
	'days_in_ap',
	'long_term_debt_to_total_asset',
	'days_cash_on_hand',
	'days_in_net_patient_ar',
	'annual_operating_rev',
	'total_long_term_liabilities',
	'ftes_with_sub_providers',
	'total_discharges',
	'average_daily_census',
	'inpatient_days',
	'adj_aob',
	'operating_margin',
	'operating_rev_per_aob',
	'operating_cost_per_aob',
	'long_term_debt_to_total_assets',
	'days_in_ar',
	'occupancy_rate',
	'ftes_per_aob',
	'total_performance_score',
	'star_rating',
	'serious_complication_rate',
	'rate_of_readmission',
	'readmission_reduction_adjustment',
	'hvbp_payment_adjustment',
	'total_hac_score'
];

export const IncomeReportFields = [
	'period_end',
	'days_in_fy',
	'inpatient_charges',
	'outpatient_charges',
	'total_charges_inpatient_and_outpatient',
	'net_patient_revenue',
	'misc_non_patient_revenue',
	'net_patient_rev_and_misc_opr_rev',
	'total_operating_expenses',
	'annual_operating_profit',
	'contributions_etc',
	'income_from_investments',
	'governmental_approp',
	'non_operating_revenue',
	'total_other_expenses',
	'net_income'
];
export const KeyProfitLossFields = [
	'labor_cost_as_a_percent_of_revenue',
	'operating_margin',
	'operating_rev_per_aob',
	'operating_cost_per_aob',
	'total_profit_margin',
	'outpatient_charges_perc_total_charges',
	'nonpatient_rev_perc_netpatient_rev'
];
export const BalanceStatementReportFields = [
	'cash_and_cash_equivalents',
	'net_accounts_receivable',
	'other_current_assets',
	'total_current_assets',
	'annual_total_fixed_assets',
	'total_other_assets',
	'total_assets',
	'total_current_liabilities_bssummary',
	'total_long_term_liabilities_bssummary',
	'total_liabilities_bssummary',
	'total_fund_balances_bssummary',
	'total_liabilities_fundbalances'
];
export const BalanceKeyFields = [
	'days_cash_on_hand',
	'days_in_ar',
	'days_in_ap',
	'long_term_debt_to_total_assets'
];

export const BalanceStatementFields = [
	'total_current_liabilities_agg',
	'total_current_liabilities',
	'total_long_term_liabilities',
	'cash_and_cash_equivalents',
	'net_accounts_receivable',
	'other_current_assets',
	'total_current_assets',
	'fixed_assets',
	'total_other_assets',
	'annual_total_fixed_assets',
	'total_assets',
	'current_liabilities',
	'long_term_liabilities',
	'total_liabilities',
	'total_fund_balances',
	'total_liabilities_fundbalances',
	'total_liabilities_bssummary',
	'total_long_term_liabilities_bssummary',
	'total_fund_balances_bssummary',
	'total_current_liabilities_bssummary',
	'annual_cash' /* 'Cash on hand and in banks' */,
	'annual_temp_investments' /*'Temporary investments' */,
	'annual_notes_receivable' /*'Notes receivable'*/,
	'annual_ar' /*'Accounts receivable'*/,
	'annual_other_receivable' /*'Other receivables'*/,
	'annual_allowances' /*'Allowances for uncollectible notes and accounts receivable'*/,
	'annual_inventory' /*'Inventory'*/,
	'annual_prepaid_expenses' /*'Prepaid expenses'*/,
	'annual_other_current_assets' /*'Other current assets'*/,
	'annual_due_from_other_funds' /*'Due from other funds'*/,
	'annual_total_current_assets' /*'Total Current Assets'*/
];


export const IPCostCenterServicesFields = [
	'ipcc_adults_pediatrics',
	'ipcc_adults_pediatrics_ppd',
	'ipcc_intensive_care_unit',
	'ipcc_intensive_care_unit_ppd',
	'ipcc_coronary_care_unit',
	'ipcc_coronary_care_unit_ppd',
	'ipcc_burn_intensive_care_unit',
	'ipcc_burn_intensive_care_unit_ppd',
	'ipcc_surgical_intensive_care_unit',
	'ipcc_surgical_intensive_care_unit_ppd',
	'ipcc_subprovider_ipf',
	'ipcc_subprovider_ipf_ppd',
	'ipcc_subprovider_irf',
	'ipcc_subprovider_irf_ppd',
	'ipcc_other_subprovider',
	'ipcc_other_subprovider_ppd',
	'ipcc_nursery',
	'ipcc_nursery_ppd',
	'ipcc_skilled_nursing_facility' ,
	'ipcc_skilled_nursing_facility_ppd' ,
	'ipcc_nursing_facility',
	'ipcc_nursing_facility_ppd',
	'ipcc_other_long_term_care',
	'ipcc_other_long_term_care_ppd'
]

export const AncillaryServiceCostCenterFields = [

	'ascc_speech_pathology',
	'ascc_speech_pathology_ppd',
	'ascc_electrocardiology',
	'ascc_electrocardiology_ppd',
	'ascc_all_other_special_purpose_cost_centers',
	'ascc_all_other_special_purpose_cost_centers_ppd',
	'ascc_electroencephalograpy',
	'ascc_electroencephalograpy_ppd',
	'ascc_medical_supplies_charged_to_patients',
	'ascc_medical_supplies_charged_to_patients_ppd',
	'ascc_implant_devices_charged_to_patients',
	'ascc_implant_devices_charged_to_patients_ppd',
	'ascc_drugs_charged_to_patients',
	'ascc_drugs_charged_to_patients_ppd',
	'ascc_renal_dialysis',
	'ascc_renal_dialysis_ppd',
	'ascc_asc_non_distinct_part',
	'ascc_asc_non_distinct_part_ppd',
	'ascc_cardiac_rehabilitation',
	'ascc_cardiac_rehabilitation_ppd',
	'ascc_hyperbaric_oxygen_therapy',
	'ascc_hyperbaric_oxygen_therapy_ppd',
	'ascc_lithotripsy',
	'ascc_lithotripsy_ppd',
	'ascc_allogeneic_stem_cell_acquisition',
	'ascc_allogeneic_stem_cell_acquisition_ppd',
	'ascc_other_nonreimbursable_cost_centers',
	'ascc_other_nonreimbursable_cost_centers_ppd',
	'ascc_other_organ_acquisition',
	'ascc_other_organ_acquisition_ppd'
]

export const OutpatientServiceCostCenterFields = [
	'ascc_rural_health_clinic',
	'ascc_rural_health_clinic_ppd',
	'ascc_federally_qualified_health_center',
	'ascc_federally_qualified_health_center_ppd',
	'ascc_clinic',
	'ascc_clinic_ppd',
	'ascc_emergency',
	'ascc_emergency_ppd',
	'ascc_observation_beds_non_distinct_part',
	'ascc_observation_beds_non_distinct_part_ppd',
	'ascc_observation_beds_distinct_part',
	'ascc_observation_beds_distinct_part_ppd'
]
export const CostCenterGeneralServicesFields = [
	'gscc_capital_buildings_fixtures',
	'gscc_capital_buildings_fixtures_ppd',
	'gscc_capital_related_cost_movable_equipment',
	'gscc_capital_related_cost_movable_equipment_ppd',
	'gscc_other_rel_costs',
	'gscc_other_rel_costs_ppd',
	'gscc_employee_benefits_dept',
	'gscc_employee_benefits_dept_ppd',
	'gscc_admin_general',
	'gscc_admin_general_ppd',
	'gscc_maintenance_repairs',
	'gscc_maintenance_repairs_ppd',
	'gscc_operation_of_plant',
	'gscc_operation_of_plant_ppd',
	'gscc_laundry_linen_services',
	'gscc_laundry_linen_services_ppd',
	'gscc_house_keeping',
	'gscc_house_keeping_ppd',
	'gscc_dietary',
	'gscc_dietary_ppd',
	'gscc_cafeteria',
	'gscc_cafeteria_ppd',
	'gscc_maintenance_of_personnel',
	'gscc_maintenance_of_personnel_ppd',
	'gscc_nursing_admin',
	'gscc_nursing_admin_ppd',
	'gscc_central_services_supply',
	'gscc_central_services_supply_ppd',
	'gscc_pharmacy',
	'gscc_pharmacy_ppd',
	'gscc_medical_rec_lib',
	'gscc_medical_rec_lib_ppd',
	'gscc_social_service',
	'gscc_social_service_ppd',
	'gscc_other_general_service',
	'gscc_other_general_service_ppd',
	'gscc_nonphysician_anesthetists',
	'gscc_nonphysician_anesthetists_ppd',
	'gscc_nursing_school',
	'gscc_nursing_school_ppd',
	'gscc_intern_res_service_salary_fring',
	'gscc_intern_res_service_salary_fring_ppd',
	'gscc_intern_res_other_prog_costs',
	'gscc_intern_res_other_prog_cost_ppds'
]
export const OtherReimbursableCostCenterFields = [
	'orcc_home_program_dialysis',
	'orcc_home_program_dialysis_ppd',
	'orcc_ambulance_services',
	'orcc_ambulance_services_ppd',
	'orcc_durable_medical_equipment_rented',
	'orcc_durable_medical_equipment_rented_ppd',
	'orcc_durable_medical_equipment_sold',
	'orcc_durable_medical_equipment_sold_ppd',
	'orcc_other_reimbursable_cost_centers_specify',
	'orcc_other_reimbursable_cost_centers_specify_ppd',
	'orcc_cmhc',
	'orcc_cmhc_ppd',
	'orcc_corf',
	'orcc_corf_ppd',
	'orcc_opt',
	'orcc_opt_ppd',
	'orcc_oot',
	'orcc_oot_ppd',
	'orcc_osp',
	'orcc_osp_ppd',
	'orcc_intern_res_svc_not_in_approved_prog',
	'orcc_intern_res_svc_not_in_approved_prog_ppd',
	'orcc_home_health_agency',
	'orcc_home_health_agency_ppd',
	'orcc_kidney_acquisition',
	'orcc_kidney_acquisition_ppd',
	'orcc_heart_acquisition',
	'orcc_heart_acquisition_ppd',
	'orcc_liver_acquisition',
	'orcc_liver_acquisition_ppd',
	'orcc_lung_acquisition',
	'orcc_lung_acquisition_ppd',
	'orcc_pancreas_acquisition',
	'orcc_pancreas_acquisition_ppd',
	'orcc_intestine_acquisition',
	'orcc_intestine_acquisition_ppd',
	'orcc_islet_cell_acquisition',
	'orcc_islet_cell_acquisition_ppd',
	'orcc_interest_expense',
	'orcc_interest_expense_ppd',
	'orcc_utilization_review_snf',
	'orcc_utilization_review_snf_ppd',
	'orcc_asc_distinct_part',
	'orcc_asc_distinct_part_ppd',
	'orcc_hospice',
	'orcc_hospic_ppde'
]

export const NonReimbursableCostCenterFields = [
	'nrcc_gift_flower_coffee_shop_canteen',
	'nrcc_gift_flower_coffee_shop_canteen_ppd',
	'nrcc_research',
	'nrcc_research_ppd',
	'nrcc_physicians_private_offices',
	'nrcc_physicians_private_offices_ppd',
	'nrcc_nonpaid_workers',
	'nrcc_nonpaid_workers_ppd',
	'nrcc_other_nonreimbursable_cost_center',
	'nrcc_other_nonreimbursable_cost_center_ppd'

]

export const All = () => {
	let list = [];
	list = list.concat(IncomeStatementList);
	list = list.concat(BalanceStatementFields);
	list = list.concat(CostCenterGeneralServicesFields);
	list = list.concat(IPCostCenterServicesFields);
	list = list.concat(AncillaryServiceCostCenterFields);
	list = list.concat(OutpatientServiceCostCenterFields);
 	list = list.concat(OtherReimbursableCostCenterFields);
	list = list.concat(NonReimbursableCostCenterFields);
	return list;
};

export const Info = () => {
	return {
		topic: 'Financial Performance',
		groups: [
			{
				key: 'finIncome',
				label: 'Income Statement',
				fields: IncomeStatementList
			},
			{
				key: 'finKeyProfit',
				label: 'Key Profit & Loss Metrics',
				fields: BalanceKeyFields.concat(KeyProfitLossFields)
			},
			{
				key: 'finBalance',
				label: 'Balance Sheet',
				fields: BalanceStatementReportFields
			},
			{
				key: 'ccGeneralService',
				label: 'Cost Center - General Service',
				fields: CostCenterGeneralServicesFields
			},
			{
				key: 'ccIPRoutineServices',
				label: 'Cost Center - Inpatient Routine Services',
				fields: IPCostCenterServicesFields
			},
			{
				key: 'ccAncillaryServiceCostCenters',
				label: 'Cost Center - Inpatient Routine Services',
				fields: AncillaryServiceCostCenterFields
			},
			{
				key: 'ccOtherReimbursableCostCenters',
				label: 'Cost Center - Other Reimbursable',
				fields: OtherReimbursableCostCenterFields
			},
			{
				key: 'ccNonReimbursableCostCenters',
				label: 'Cost Center - Non-Reimbursable',
				fields: NonReimbursableCostCenterFields
			}
		]
	};
};
