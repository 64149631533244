import React from 'react';
import {
	ComposedChart,
	Line,
	Area,
	Bar,
	XAxis,
	YAxis,
	ZAxis,
	Cell,
	Tooltip,
	Legend,
	PieChart,
	Pie,
	ResponsiveContainer,
	CartesianGrid,
	Scatter,
	ScatterChart
} from 'recharts';
import './index.css';
import * as Util from '../../lib/FormatUtil';

const toBool = (value, defaultValue) => {
	let result = defaultValue;

	if (value !== undefined) {
		result = value === true || value === 'true';
	}
	return result;
};

const defaultSeries = [
	{ xAxis: 'name', xLabel: 'Pages', yLabel: 'Index', type: 'Bar', name: 'pv' },
	{ type: 'Area', name: 'amt' },
	{ type: 'Line', name: 'uv' }
];
// const PieSeries = [{ name: 'name', value: 'amt', type: 'Pie' }];
const defaultData = [
	{ name: 'Page A', uv: 590, pv: 800, amt: 1400 },
	{ name: 'Page B', uv: 868, pv: 967, amt: 1506 },
	{ name: 'Page C', uv: 1397, pv: 1098, amt: 989 },
	{ name: 'Page D', uv: 1480, pv: 1200, amt: 1228 },
	{ name: 'Page E', uv: 1520, pv: 1108, amt: 1100 },
	{ name: 'Page F', uv: 1400, pv: 680, amt: 1700 }
];

const defaultSize = { height: 800, width: 1000 };

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

const toMoney = (decimal, fixed = 0) => {
	return decimal.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
		currencyDisplay: 'symbol',
		maximumSignificantDigits: 3
	});
};

const toMoneyK = (decimal, fixed = 0) => {
	return (
		(decimal / 1000).toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'symbol',
			maximumSignificantDigits: 3
		}) + 'K'
	);
};

const toMoneyM = (decimal, fixed = 0) => {
	return (
		(decimal / 1000000).toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'symbol',
			maximumSignificantDigits: 3
		}) + 'M'
	);
};

const toBaseK = (decimal, fixed = 0) => {
	return (
		(decimal / 1000).toLocaleString('en-US', {
			maximumSignificantDigits: 3
		}) + 'K'
	);
};

const toBase = (decimal, fixed = 0) => {
	return decimal.toLocaleString('en-US', {
		maximumSignificantDigits: 3
	});
};

const toBaseM = (decimal, fixed = 0) => {
	return (
		(decimal / 1000000).toLocaleString('en-US', {
			maximumSignificantDigits: 3
		}) + 'M'
	);
};

const toPercent = (decimal, fixed = 0) => {
	return decimal.toLocaleString('en-US', {
		style: 'percent',
		minimumFractionDigits: 0,
		maximumFractionDigits: 2
	});
};

class ReChartTool extends React.Component {
	constructor(props) {
		super(props);
		this.series = this.props.series || defaultSeries;
		this.data = this.props.data || defaultData;
		this.height = this.props.height || defaultSize.height;
		this.width = this.props.width || defaultSize.width;
		this.YAxis = this.props.YAxis || 'default';
		this.ZAxis = this.props.ZAxis || 'default';
		this.ShowToolTip = toBool(this.props.ShowTooltip, false);
		this.title = this.props.title || null;
		this.ShowLegend = this.props.ShowLegend;
		this.ShowLogo = this.props.ShowLogo;
	}
	getXAxis() {
		return (
			<XAxis
				dataKey={this.series[0].xAxis}
				range={[0, 200]}
				label={{ value: this.series[0].xLabel, position: 'bottom' }}
			/>
		);
	}
	getYAxis() {
		if (this.YAxis && this.YAxis == 'percent') {
			return <YAxis tickFormatter={toPercent} />;
		} else if (this.YAxis && this.YAxis == 'money') {
			let mRange = Math.max.apply(null, this.data.map(a => a[this.series[0].name]));
			if (mRange > 1000000) {
				return <YAxis tickFormatter={toMoneyM} scale="auto" />;
			} else if (mRange > 10000) {
				return <YAxis tickFormatter={toMoneyK} scale="auto" />;
			} else {
				return <YAxis tickFormatter={toMoney} />;
			}
		} else if (this.YAxis && this.YAxis == 'base') {
			let mRange = Math.max.apply(null, this.data.map(a => a[this.series[0].name]));
			if (mRange > 1000000) {
				return <YAxis tickFormatter={toBaseM} scale="auto" />;
			} else if (mRange > 10000) {
				return <YAxis tickFormatter={toBaseK} scale="auto" />;
			} else {
				return <YAxis tickFormatter={toBase} scale="auto" />;
			}
		} else {
			return <YAxis label={{ value: this.series[0].yLabel, angle: -90, position: 'left' }} />;
		}
	}
	getScatterYAxis() {
		return (
			<YAxis
				dataKey={this.series[0].yAxis}
				label={{ value: this.series[0].yLabel, angle: -90, position: 'left' }}
			/>
		);
	}

	getZAxis() {
		return (
			<ZAxis
				dataKey={this.series[0].zAxis}
				label={{ value: this.series[0].yLabel, angle: -90, position: 'left' }}
				range={[60, 400]}
			/>
		);
	}

	getToolTip() {
		if (this.ShowToolTip === true) {
			return <Tooltip />;
		}
	}

	getDataChart() {
		return this.series.map((row, key) => {
			if (row.type === 'Bar') {
				return (
					<Bar
						key={`bar-${key}`}
						dataKey={row.name}
						barSize={row.barSize ? row.barSize : 30}
						fill={row.color}
						name={row.display}
					/>
				);
			}
			if (row.type === 'OutlineBar') {
				return (
					<Bar
						key={`bar-${key}`}
						dataKey={row.name}
						barSize={row.barSize ? row.barSize : 30}
						name={row.display}
						fill={row.color}
						stroke="rgba(23, 60, 73)"
					/>
				);
			}
			if (row.type === 'StackArea') {
				return (
					<Area
						key={`stackarea-${key}`}
						stackId="1"
						dataKey={row.name}
						fill={row.color}
						stroke={row.color}
						showInLegend={row.hide ? row.hide : true}
						name={row.display}
					/>
				);
			}

			if (row.type === 'Area') {
				return (
					<Area
						key={`area-${key}`}
						dataKey={row.name}
						fill={row.color}
						stroke={row.color}
						name={row.display}
						showInLegend={row.hide ? row.hide : true}
					/>
				);
			}
			if (row.type === 'Line') {
				let showDot = row.showDot === undefined || !!row.showDot;
				return (
					<Line
						connectNulls
						key={`line-${key}`}
						dataKey={row.name}
						stroke={row.color}
						name={row.display}
						strokeWidth={3}
						dot={showDot}
						showInLegend={row.hide ? row.hide : true}
					/>
				);
			}
			if (row.type === 'ThinLine') {
				return (
					<Line
						connectNulls={true}
						key={`line-${key}`}
						dataKey={row.name}
						stroke={row.color}
						name={row.display}
						strokeWidth={2}
						dot={false}
						showInLegend={row.hide ? row.hide : true}
					/>
				);
			}
			if (row.type === 'Stacked') {
				return (
					<Area
						key={`stacked-${key}`}
						dataKey={row.name}
						fill={row.color}
						name={row.display}
						showInLegend={row.hide ? row.hide : true}
					/>
				);
			}
			return null;
		});
	}
	getPie() {
		return (
			<Pie
				data={this.data}
				dataKey={this.series[0].value}
				nameKey={this.series[0].name}
				labelLine={false}
				label={renderCustomizedLabel}
			>
				{this.data.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={entry.color} />
				))}
			</Pie>
		);
	}
	getScatter() {
		return <Scatter data={this.data} name={this.series[0].name} fill="#8884d8" shape="star" />;
	}

	getLegend() {
		if (this.ShowLegend) {
			if (String(this.ShowLegend).toLowerCase() == 'smally') {
				return (
					<Legend
						layout="horizontal"
						align="center"
						verticalAlign="bottom"
						style={{ padding: 20 }}
						className={'recharts-legend-wrapper-small'}
					/>
				);
			} else {
				return (
					<Legend
						layout="horizontal"
						align="center"
						verticalAlign="bottom"
						style={{ padding: 20 }}
					/>
				);
			}
		}
	}

	chart() {
		if (this.series && this.series.length > 0) {
			if (this.series[0].type === 'Pie') {
				return (
					<PieChart style={{ margin: 'auto' }} width={this.height} height={this.height}>
						{this.getPie()}
						{this.getLegend()}
						{/* <Tooltip /> */}
					</PieChart>
				);
			} else if (this.series[0].type === 'Scatter') {
				return (
					<ResponsiveContainer height={this.height}>
						<ScatterChart data={this.data} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
							{this.getScatterYAxis()}
							{this.getXAxis()}
							{this.getZAxis()}
							{this.getScatter()}
							{this.getLegend()}
							{this.getToolTip()}
							<CartesianGrid />
						</ScatterChart>
					</ResponsiveContainer>
				);
			} else {
				return (
					<ResponsiveContainer height={this.height}>
						<ComposedChart data={this.data} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
							{this.getYAxis()}
							{this.getXAxis()}
							{this.getLegend()}
							{this.getToolTip()}
							{this.getDataChart()}
						</ComposedChart>
					</ResponsiveContainer>
				);
			}
		}
		return 'No Data Available';
	}
	chartLogo() {
		return this.ShowLogo ? <div className="chart-logo">HMP Metrics</div> : '';
	}

	render() {
		return (
			<div className="col">
				<h5>{this.title}</h5>
				{this.chart()}
				{this.chartLogo()}
			</div>
		);
	}
}

export default ReChartTool;
