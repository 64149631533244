import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../tools/datagrid';
import DataGridFooterNotation from '../../tools/datagrid/extras/DataGridFooterNotation';
import * as MHGridGroup from '../../tools/datagrid/extras/DataGridGroupHeader';
import DataGridQuartile from '../../tools/datagrid/extras/DataGridQuartile';
import BenchmarkDataExport from './BenchmarkDataExport';
import FacilityCompare from '../../core/components/facilityCompare';
import * as Fields from './BenchmarkFields';
import LoadingSpinner from '../../tools/loadingSpinner';
import BenchmarkTab from './BenchmarkTab';
import * as providerActions from '../../core/actions/GlobalActions';
import * as misc from '../../lib/misc';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faCheck, faColumns, faAngleDown, faUndo } from '@fortawesome/fontawesome-free-solid';
import HospPeerGroup from '../Common/hospPeerGroup';
import _ from 'lodash';

//this.props.data.Years, this.onYearChanged
const CustomGridMenu = (compareId, customBenchId, bench, active, updateInfo, toggleUpdater, tabDef, SummaryCols, data, FieldDesc) => {
	let Title = new MHGrid.DataGridTitle('Benchmark Metrics', '', '', 4);
	let _sectionsTemp = [UtilizationTab, LaborTab, FinancialTab];

	return (
		<div className="d-none d-md-block mb-1">
			<button
				className="form-control select text-small px-0"
				style={{
					height: '32px',
					width: '120px'
				}}
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				Options <FontAwesomeIcon className="fa-sm" icon={faAngleDown} />
			</button>
			<ul className="dropdown-menu shadow bg-light">
				<li
					className="nav-link menu-link-label small py-1"
					data-toggle="modal"
					data-target={'#' + compareId}
				>
					<FontAwesomeIcon className="fa-1x text-primary" icon={faColumns} />
					<span className="px-2">Compare</span>
				</li>
				<div className="dropdown-divider" />
				{bench ? (
					<span className="nav-link menu-link-label small strong py-1">Custom Peer Group</span>
				) : (
					''
				)}
				<li
					className="nav-link menu-link-label small py-1"
					data-toggle="modal"
					data-target={'#' + customBenchId}
				>
					<span className="px-2">{bench ? 'Create New' : 'Custom Peer Group'}</span>
				</li>
				{bench ? (
					<li className="nav-link menu-link-label small py-1" onClick={updateInfo}>
						<FontAwesomeIcon className={'fa-1x text-primary'} icon={faUndo} />
						<span className="px-2">Reset</span>
					</li>
				) : (
					''
				)}
				{bench ? (
					<li className="nav-link menu-link-label small py-1" onClick={toggleUpdater}>
						<FontAwesomeIcon
							className={active ? 'fa-1x text-primary' : 'fa-1x text-light'}
							icon={faCheck}
						/>
						<span className="px-2">Custom</span>
					</li>
				) : (
					''
				)}
				{bench ? (
					<li className="nav-link menu-link-label small py-1" onClick={toggleUpdater}>
						<FontAwesomeIcon
							className={!active ? 'fa-1x text-primary' : 'fa-1x text-light'}
							icon={faCheck}
						/>
						<span className="px-2">National</span>
					</li>
				) : (
					''
				)}
				<div className="dropdown-divider" />
				<li className="nav-link menu-link-label small py-1">
					<BenchmarkDataExport
						fileName={Title.header1}
						domain={'hosp'}
						sections={_sectionsTemp}
						tabDef={tabDef}
						headAlign={'center'}
						bodyAlign={'right'}
						Cols={SummaryCols}
						data={data}
						FieldDesc={FieldDesc}
					/>
				</li>
			</ul>
		</div>
	);
};

const UtilizationTab = [
	{
		title: 'Bed Utilizations',
		fields: Fields.BedTypeMetrics
	},
	{
		title: 'Key Utilization Indicators',
		fields: Fields.UtilizationIndicationMetrics
	},
	{
		title: 'Discharges',
		fields: Fields.DischargeMetrics
	},
	{
		title: 'Inpatient Days',
		fields: Fields.InpatientDaysMetrics
	},
	{
		title: 'Average Inpatient Length of Stay (ALOS)',
		fields: Fields.AvgLOSList
	},
	{
		title: 'Occupancy',
		fields: Fields.OccupancyList
	}
];

const LaborTab = [
	{
		title: 'Labor Metrics',
		fields: Fields.LaborMetrics
	}
];

const FinancialTab = [
	{
		title: 'Financial Summary',
		fields: Fields.FinancialMetrics
	},
	{
		title: 'Operations - Financial Metrics',
		fields: Fields.OperatingMetrics
	},
	{
		title: 'Medicare Payments',
		fields: Fields.MedicarePaymentMetrics
	}
];

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.hospBenchmarkData.whereChanged,
		data: store.hospBenchmarkData.Benchmark,
		dataLoaded: store.hospBenchmarkData.fetched,
		dataLoading: store.hospBenchmarkData.fetching
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updateProviderInfo: ids => dispatch(providerActions.findProviders(ids))
	};
};
class BenchmarkDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentYear: this.props.data.ActiveYear,
			selectedTab: 0,
			active: true,
			customBenchmark: misc.hasCustomBenchmark(),
			providers: misc.getFOIandCompetitors(),
			key: misc.Guid()
		};
		// if (this.props.updateInfo) {
		// 	this.UpdateInfo = this.props.updateInfo.bind(this);
		// }
		this.ResetUpdateInfo = this.ResetUpdateInfo.bind(this);
		this.UpdateInfo = this.UpdateInfo.bind(this);
		this.onYearChanged = this.onYearChanged.bind(this);
		this.tabChanged = this.tabChanged.bind(this);
		this.switchActive = this.switchActive.bind(this);
	}

	onYearChanged(year) {
		this.props.data.ActiveYear = year;
		let state = this.state;
		state.currentYear = year;
		this.setState(state);
	}

	tabChanged(selectedTab) {
		let state = this.state;
		state.selectedTab = selectedTab;
		this.setState(state);
	}
	UpdateInfo() {
		let state = this.state;
		state.providers = misc.getFOIandCompetitors();
		state.customBenchmark = misc.hasCustomBenchmark();
		state.active = state.customBenchmark;
		state.key = misc.Guid();
		this.setState(state);
		this.props.updateInfo();
	}
	ResetUpdateInfo() {
		sessionStorage.setItem('customBenchmark', JSON.stringify([]));
		sessionStorage.setItem('custom-peer-group-description', '');
		let currState = this.state;
		currState.active = false;
		currState.customBenchmark = false;
		currState.key = misc.Guid();
		this.setState(currState);
		this.props.updateInfo();
	}

	switchActive() {
		let currState = this.state;
		currState.active = !this.state.active;
		currState.key = misc.Guid();
		this.setState(currState);
	}

	render() {
		let ContentPage = null;
		let Title = new MHGrid.DataGridTitle(
			'Benchmark Report',
			'',
			'',
			3,
			this.props.data.Years,
			this.onYearChanged
		);
		let compareId = 'compare_benchmark';
		let custmBenchId = 'custom_peergroup';
		let providers = misc.getFOIandCompetitors();
		if (this.props.dataLoaded && !this.props.dataLoading) {
			let SummaryCols = this.props.dataLoaded
				? MHGridGroup.DataGridGroupHeader(
						(this.state.active &&
							this.props.data.args != null &&
							this.props.data.args['custom'] &&
							misc.hasCustomBenchmark()) ||
							false,
						providers,
						'hosp'
				  )
				: null;
			const tabDef = [
				{
					id: 0,
					title: 'Utilization Metrics',
					component: (
						<BenchmarkTab
							key={this.state.key}
							{...this.props}
							Cols={SummaryCols}
							Sections={UtilizationTab}
						/>
					)
				},
				{
					id: 1,
					title: 'Labor Metrics',
					component: (
						<BenchmarkTab
							key={this.state.key}
							{...this.props}
							Cols={SummaryCols}
							Sections={LaborTab}
						/>
					)
				},
				{
					id: 2,
					title: 'Financial Metrics',
					component: (
						<BenchmarkTab
							key={this.state.key}
							{...this.props}
							Cols={SummaryCols}
							Sections={FinancialTab}
						/>
					)
				}
			];
			let FieldDesc = this.props.FieldDesc;
			let CustomGrid = this.props.dataLoaded
				? CustomGridMenu(
						compareId,
						custmBenchId,
						this.props.data.args != null &&
							this.props.data.args['custom'] &&
							misc.hasCustomBenchmark(),
						this.state.active,
						this.ResetUpdateInfo,
						this.switchActive,
						tabDef,
						SummaryCols,
						this.props.data,
						FieldDesc
				  )
				: null;
			

			const pillTabs = tabDef.map(({ id, title } = {}) => (
				<li
					className={
						this.state.selectedTab === id
							? 'react-tabs__tab react-tabs__tab--selected  d-block'
							: 'react-tabs__tab d-block'
					}
				>
					<a className="d-block" onClick={() => this.tabChanged(id)}>
						{title.toString()}
					</a>
				</li>
			));
			const toggleTabs = tabDef.map(({ id, title } = {}) => (
				<li
					className={
						this.state.selectedTab === id
							? 'react-tabs__tab react-tabs__tab--selected'
							: 'react-tabs__tab'
					}
					onClick={() => this.tabChanged(id)}
				>
					<a data-toggle="tab" className="mx-3">
						{title.toString()}
					</a>
				</li>
			));
			const panelTab = tabDef.map(({ id } = {}) => (
				<div className={this.state.selectedTab === id ? 'tab-pane active' : 'tab-pane'}>
					{(tabDef[id] && tabDef[id].component) || null}
				</div>
			));
			const tabBody = (
				<div className="row mx-0">
					<div className="dropdown show d-flex col mx-auto d-md-none react-tabs__tab-list">
						<a
							className="react-tabs__tab react-tabs__tab--selected"
							href="#"
							role="button"
							id="dropdownMenuLink"
							data-toggle="dropdown"
						>
							{tabDef[this.state.selectedTab].title.toString()}
						</a>

						<div className="dropdown-menu text-center mx-auto" aria-labelledby="dropdownMenuLink">
							<ul className="nav nav-stacked d-block d-md-none">{pillTabs}</ul>
						</div>
					</div>
					<ul
						className="d-none d-md-flex px-0 col-md-12 mx-auto react-tabs__tab-list mt-1"
						role="tablist"
					>
						{toggleTabs}
						<div className="ml-auto mr-0">
							<div className="pull-right">{CustomGrid}</div>
						</div>
					</ul>
					<div className="tab-content p-0 col-md-12">
						<DataGridQuartile className="pull-left" domain={'hosp'}>
							<FacilityCompare
								changeComparison={this.UpdateInfo}
								TargetId={compareId}
								segment="hosp"
							/>
						</DataGridQuartile>
						<HospPeerGroup updateBenchmark={this.UpdateInfo} ModalId={custmBenchId} />
						{panelTab}
					</div>
				</div>
			);

			ContentPage = (
				<>
					{Title.renderHeading()}
					{tabBody}
					<DataGridFooterNotation />
				</>
			);
		} else {
			ContentPage = (
				<div className="container">
					<div className="row">
						<LoadingSpinner />
					</div>
				</div>
			);
		}

		return <>{ContentPage}</>;
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BenchmarkDetail);
