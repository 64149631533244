import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import { findProviders as loadProviderInfo } from '../../core/actions/GlobalActions';
import { loadMarketShare as loadMarketData, loadPathways } from './MarketShare/MarketDataAction';
import { loadDemographics } from './DemographicsAction';
import * as misc from '../../lib/misc';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as MHGrid from '../../tools/datagrid';
import DataGridFooterNotation from '../../tools/datagrid/extras/DataGridFooterNotation';
import * as ActionTypes from '../Common/ActionTypes';
import * as Actions from '../../core/actions/ActionHelper';
import Competitors from './Competitors/Competitors';
import PatientPathways from './PatientPathways';
import Demographics from './Demographics/index';
import MarketShare from './MarketShare/MarketShare';
import Inpatient from './Inpatient';
import Outpatient from './Outpatient';
import Auth from '../../core/Security/Auth';
import LandingPage from './LandingPage';
import './index.css';
class StrategicMarketData extends React.Component {
	constructor(props) {
		super(props);

		let facilityInfo = misc.getFacilityInfo();
		let ids = misc.getFacilityIds();
		this.state = {
			facilityIds: ids,
			facilityInfo: facilityInfo,
			marketDataTab: this.props.marketDataTab || 0,
			currentTab: 0,
			tabs: [
				{
					id: 0,
					title: 'Market Share',
					icon: 'fa-pie-chart',
					component: <MarketShare {...props} />
				},
				{
					id: 1,
					title: 'Competitors',
					icon: 'fa-bar-chart',
					component: <Competitors {...props} />
				},
				{
					id: 2,
					title: 'Inpatient',
					icon: 'fa-pie-chart',
					component: <Inpatient {...props} />
				},
				{
					id: 3,
					title: 'Outpatient',
					icon: 'fa-pie-chart',
					component: <Outpatient {...props} />
				},
				{
					id: 4,
					title: 'Demographics',
					icon: 'fa-bar-chart',
					component: <Demographics {...props} facilityInfo={facilityInfo} facilityId={ids} />
				},
				{
					id: 5,
					title: 'Patient Patterns',
					icon: 'fa-arrows-apt-h',
					component: <PatientPathways {...props} facilityInfo={facilityInfo} facilityId={ids} />
				}
			],
			activeEl: null
		};
		let yr = facilityInfo.data_hsaf_last_year
			? facilityInfo.data_hsaf_last_year
			: misc.getFacilityYear();
		this.props.loadProviderInfo('hosp', ids);
		this.props.loadMarketData(yr, ids);
		this.props.loadPathways(ids);
	}

	onPageChange = (page = 0) => {
		this.setState({ marketDataTab: page.toString() });
	};

	componentDidMount() {
		let facilityInfo = misc.getFacilityInfo();

		window.scrollTo(0, 0);
		this.props.loadProviderInfo('hosp', misc.getFacilityIds());
		this.props.loadMarketData(
			facilityInfo.data_hsaf_last_year ? facilityInfo.data_hsaf_last_year : misc.getFacilityYear(),
			misc.getFacilityIds()
		);
	}

	render() {
		let ContentPage = null;

		const tabPanels = this.state.tabs.map(
			({ id } = {}) =>
				<TabPanel>{(this.state.tabs[id] && this.state.tabs[id].component) || null}</TabPanel> ||
				null
		);
		const tabs = this.state.tabs.map(({ title } = {}) => <Tab>{title.toString()}</Tab>);

		let Title = new MHGrid.DataGridTitle('Strategic Market Data', '', '', 3, null, null);

		if (Auth.isUserHospAuthenticated()) {
			document.body.id = 'facSummary';
			ContentPage = (
				<>
					<PageNavigation selectedTab="6" domain="hosp" />
					<section id="content" className="hospSMD">
						<div className="container" id="marketshare">
							<div className="row">
								<div className="col-12">
									<h2>Strategic Market Data</h2>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<Tabs>
										<TabList>{tabs}</TabList>
										{tabPanels}
									</Tabs>
									<DataGridFooterNotation />
								</div>
							</div>
						</div>
					</section>
				</>
			);
		} else {
			ContentPage = (
				<>
					<PageNavigation selectedTab="6" domain="hosp" />
					<LandingPage />
				</>
			);
		}

		return <>{ContentPage}</>;
	}
}

StrategicMarketData.defaultProps = {};

const mapStateToProps = store => {
	return {
		prvdr: store.prvdr
	};
};

const mapDispatchToProps = dispatch => {
	return {
		hospPSACompetitors: ids =>
			dispatch(
				Actions.ActionRequest(ActionTypes.SMD_PSA_COMPETITOR, '/hosp-psa-competitors', {
					providerIds: ids
				})
			),
		loadProviderInfo: (domain, ids) => dispatch(loadProviderInfo(domain, ids)),
		loadMarketData: (year, ids) => dispatch(loadMarketData(year, ids)),
		loadPathways: ids => dispatch(loadPathways(ids))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StrategicMarketData);
