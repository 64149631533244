import React, { Component } from "react";
import ReportInfo from "./AtRisk";
import UserInfo from "./UserInfo";
import CardInfo from "./CardInfo";

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {
    let winH = window.innerHeight,
    bodyH = document.getElementById('orderReport').clientHeight;
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
  }

  render() {
    return (
      <section id="content" className="confirmReport">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>{this.props.userInfo.username}</h1>
              <h3>Thank You</h3>
              <p id="confirmP">Your purchase of our "<span>{this.props.currentReport.planName} Report</span>" is complete.</p>
            </div>
            <div className="col-md-6 col-12 text-center">
              
              <ul id="reportList">
                <li>{this.props.currentReport.planName} Report</li>
                <li><span>Covering</span><br />{this.props.currentReport.selected}</li>
                <li><span>In {this.props.currentReport.selectedStates.length > 1 ? 'the following states' : ''} </span><br /> 
                  {this.props.currentReport.selectedStates.map((value, index) => {
                    return (
                      <span className="states">{value} </span>
                    )
                  })}
                </li>
                <li><span>Charge</span><br />${this.props.currentReport.price}</li>
              </ul>
            </div>
            <div className="col-md-6 col-12">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id mauris faucibus, auctor nisl non, congue est. Aliquam pulvinar odio in molestie tristique. Etiam vel lacinia lacus, in vehicula purus.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id mauris faucibus, auctor nisl non, congue est. Aliquam pulvinar odio in molestie tristique. Etiam vel lacinia lacus, in vehicula purus.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id mauris faucibus, auctor nisl non, congue est. Aliquam pulvinar odio in molestie tristique. Etiam vel lacinia lacus, in vehicula purus.</p>
              <a href="/"><h4 className="loginBttn">Return Home</h4></a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default(Confirm);