import React from 'react';
import { connect } from 'react-redux';
import SignIn from './signIn';
import Auth from '../../Security/Auth';
import * as misc from '../../../lib/misc';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faCheck, faColumns, faAngleDown, faUndo } from '@fortawesome/fontawesome-free-solid';
/**
 * Expects the following Props:
 * For Custom Compare to work it must have the ID of the Custom Compare Target as CustomPeerGroup
 * For Custom Peergoup to work it expects CustomBenchmarkId, the CustomBenchmarkUpdat function and whether or not the benchmark is active via CustomBenchmarkActive
 * To add to the menu, pass in components as children.
 * @class CustomGridMenu
 * @extends {React.Component}
 */
export default class CustomGridMenu extends React.Component {
	constructor(props) {
		super(props);
	}

	CustomBenchmark(customBenchId, toggleUpdater, updateInfo, active) {
		return (
			<div>
				<div className="dropdown-divider" />
				<span className="nav-link menu-link-label small strong py-1">Custom Peer Group</span>
				<li
					className="nav-link menu-link-label small py-1"
					data-toggle="modal"
					data-target={'#' + customBenchId}
				>
					<span className="px-2">Custom Peer Group</span>
				</li>
				<li className="nav-link menu-link-label small py-1" onClick={updateInfo}>
					<FontAwesomeIcon className={'fa-1x text-primary'} icon={faUndo} />
					<span className="px-2">Reset</span>
				</li>
				<li className="nav-link menu-link-label small py-1" onClick={toggleUpdater}>
					<FontAwesomeIcon
						className={active ? 'fa-1x text-primary' : 'fa-1x text-light'}
						icon={faCheck}
					/>
					<span className="px-2">Custom</span>
				</li>
				<li className="nav-link menu-link-label small py-1" onClick={toggleUpdater}>
					<FontAwesomeIcon
						className={!active ? 'fa-1x text-primary' : 'fa-1x text-light'}
						icon={faCheck}
					/>
					<span className="px-2">National</span>
				</li>
			</div>
		);
	}

	render() {
		let CustomPeerGroup = this.props.CustomPeerGroup ? (
			<li
				className="nav-link menu-link-label small py-1"
				data-toggle="modal"
				data-target={'#' + this.props.CustomPeerGroup}
			>
				<FontAwesomeIcon className="fa-1x text-primary" icon={faColumns} />
				<span className="px-2">Compare</span>
			</li>
		) : null;

		let CustomComparison =
			this.props.CustomBenchmarkId &&
			this.props.CustomBenchmarksUpdate &&
			this.props.CustomBenchmarkActive &&
			this.props.CustomBenchmarkReset
				? this.CustomBenchmark(
						this.props.CustomBenchmarkId,
						this.props.CustomBenchmarksUpdate,
						this.props.CustomBenchmarkReset,
						this.props.CustomBenchmarkActive
				  )
				: null;
		let showDivider = CustomPeerGroup && CustomComparison ? <div className="dropdown-divider" /> : null;
		let submenus = [];
		if (this.props.children) {
			if (Array.isArray(this.props.children)) {
				this.props.children.map(a => {
					submenus.push(<li className="nav-link menu-link-label small py-1">{a}</li>);
				});
			} else {
				submenus.push(
					<li className="nav-link menu-link-label small py-1">{this.props.children}</li>
				);
			}
		}
		return (
			<div className="d-none d-md-block mb-1">
				<button
					className="form-control select text-small px-0"
					style={{
						width: '120px'
					}}
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					Options <FontAwesomeIcon className="fa-sm" icon={faAngleDown} />
				</button>
				<ul className="dropdown-menu shadow bg-light">
					{CustomPeerGroup}
					{CustomComparison}
					{showDivider}
					{submenus}
				</ul>
			</div>
		);
	}
}
