import React from 'react';
import { connect } from 'react-redux';
import { Popover, Modal, Button, OverlayTrigger } from 'react-bootstrap';
import * as whereBuilder from '../../../lib/whereBuilder';
import { sessionStorageWhereChange } from '../../actions/GlobalActions';
// import HospBenchmarkGroup from './PeerGroups/hospBenchmarkGroup';
import * as misc from '../../../lib/misc';

let segment = 'hosp';

const mapStateToProps = store => {
	return {
		providers: store.providers.providers.rows,
		hospWhereChanged: store.hospBenchmarkData.whereChanged
	};
};
class PeerGroupTitle extends React.Component {
	constructor() {
		super();
		this.state = {
			show: false,
			selectedTab: 0
		};
		//Reset the selected competitors since you have changed the benchmark tool.
		// whereBuilder.storeDefaultWhere();
	}

	// 	isEmpty(obj) {
	// 		for (var key in obj) {
	// 			if (obj.hasOwnProperty(key)) {
	// 				return false;
	// 			}
	// 		}
	// 		return true;
	// 	}

	getCustomDescription() {
		if (!!this.state.hasCustomWhere) {
			let customDescription = JSON.parse(sessionStorage.getItem('customBenchmarkLabel'));
			return customDescription.map(function(desc) {
				return (
					<span>
						{desc}
						<br />
					</span>
				);
			});
		}
		return '';
	}

	render() {
		let result = sessionStorage.getItem('where-description') || 'Loading Default Peer Group...';
		let tag = this.props.hospWhereChanged || true;
		let custom = tag ? sessionStorage.getItem('custom-peer-group-description') || '' : '';
		return (
			<div
				className="MH-InfoTooltip row mx-0 px-0"
				role="button"
				data-toggle="popover"
				data-trigger="hover"
				style={{ whiteSpace: 'pre-wrap' }}
				data-content={
					custom.length > 45
						? 'Current Peer Group:\n' + result + '\nCustom Peer Group: \n' + custom
						: ''
				}
				data-placement="bottom"
			>
				<span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span>
				<div className="d-flex">
					<b> National Peer Group: </b>
					{result}
				</div>
				{custom.length > 1 ? (
					<div className="d-flex">
						<span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span>
						<b>Custom Peer Group: </b>
						{custom.length > 50 ? custom.substring(0, 45).concat('...') : custom}
					</div>
				) : (
					''
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps)(PeerGroupTitle);
