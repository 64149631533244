import React, { Component } from "react";

export default class RequestReport extends Component {
  render() {
    return (
      <div id="content">
        <h1>Request Report</h1>
      </div>
    );
  }
}