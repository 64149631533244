import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../tools/datagrid';
import DataGridFooterNotation from '../../tools/datagrid/extras/DataGridFooterNotation';
import * as misc from '../../lib/misc';
import LoadingSpinner from '../../tools/loadingSpinner';
import Heading from '../../core/components/Heading';
import { AddNewFields } from '../../core/actions/GlobalActions';
import * as Actions from '../../core/actions/ActionHelper';
import * as ActionTypes from '../Common/ActionTypes';
import NoData from '../../core/components/notifications/NoData';
import * as Fields from './SummaryFields';
import * as whereBuilder from '../../lib/whereBuilder';

const buildSummaryGridColumns = () => {
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn(
			'metric',
			new MHGrid.DataGridHeader('', '', ''),
			'300',
			MHGrid.ColType_Metric
		)
	);
	let yr = misc.getFacilityYear();
	for (let i = 4; i >= 0; i--) {
		Cols.Add(
			new MHGrid.DataGridColumn(
				yr - i,
				new MHGrid.DataGridHeader(yr - i, '', '', 'right'),
				'155',
				MHGrid.ColType_Self
			)
		);
	}
	return Cols;
};
const buildQualitySummaryGridColumns = () => {
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn(
			'metric',
			new MHGrid.DataGridHeader('', '', ''),
			'300',
			MHGrid.ColType_Metric
		)
	);
	let foi = misc.getFacilityInfo();
	let yr = foi.data_quality_last_year;

	for (let i = 4; i >= 0; i--) {
		Cols.Add(
			new MHGrid.DataGridColumn(
				yr - i,
				new MHGrid.DataGridHeader(yr - i, '', '', 'right'),
				'155',
				MHGrid.ColType_Self
			)
		);
	}
	return Cols;
};

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.snf,
		whereChanged: store.snfSummaryData.whereChanged,
		data: store.snfSummaryData.Benchmark,
		fetched: store.snfSummaryData.fetched,
		fetching: store.snfSummaryData.fetching,
		datakey: store.snfSummaryData.dataKey,
		updateKey: store.fieldDef.updateKey,
		qualityData: store.snfQualitySummaryData.Benchmark,
		qualityFetched: store.snfQualitySummaryData.fetched,
		qualityFetching: store.snfQualitySummaryData.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetSummaryData: providers =>
			dispatch(
				Actions.ActionPivotDataRequest(
					ActionTypes.SNF_SUMMARY_METRICS,
					providers,
					'snf',
					Fields.Summary(),
					'fy_year',
					true
				)
			),
		GetQualitySummaryData: providers =>
			dispatch(
				Actions.ActionPivotDataRequest(
					ActionTypes.SNF_SUMMARY_QUALITY_METRICS,
					providers,
					'snf',
					Fields.QualitySummary(),
					'fy_year',
					true,
					whereBuilder.setDefaultBenchmark('snf', 'quality')
				)
			),
		GetFieldInfo: (fields, basis) => {
			dispatch(AddNewFields(fields, basis, 'snf'));
		}
	};
};

class SummaryTables extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.GetData();
	}

	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.GetData();
		}
	}

	GetData() {
		let providers = misc.getFacilityIds();
		let allFields = Fields.All();
		this.props.GetFieldInfo(allFields, this.props.FieldDesc, 'snf');
		this.props.GetSummaryData(providers);
		this.props.GetQualitySummaryData(providers);
	}

	render() {
		let datastamp = this.props.datakey;
		let updateKey = this.props.updateKey;
		// if (this.props.fetched && !this.props.fetching) {
		let data = this.props.data ? this.props.data.data : null;
		let qualityData = this.props.qualityData ? this.props.qualityData.data : null;

		let Cols = buildSummaryGridColumns();
		let QCols = buildQualitySummaryGridColumns();
		return (
			<div className="row" id="summaryTables">
				<hr />
				<div className="col-md-12" id="facLevelTbl">
					<Heading
						primary="true"
						heading="Facility Level: Financial and Operational Summary by Fiscal Year"
					/>
					<h6>Facility Level</h6>
					<div className="sticky_table_header">
						<MHGrid.DataGrid columns={Cols} headersOnly={true} />
					</div>
					<MHGrid.DataGrid
						suppressLines={true}
						columns={Cols}
						showHeaders={false}
						fieldDef={this.props.FieldDesc}
						rows={data ? misc.filterMetrics(data, Fields.FacilityLevelSegment) : []}
					/>
				</div>
				<div className="col-md-12" id="financialTbl">
					<Heading
						primary="true"
						heading="Summary Benchmark Report: Key Metric Analysis"
					/>
					<h6>Financial Metrics</h6>
					<div className="sticky_table_header">
						<MHGrid.DataGrid columns={Cols} headersOnly={true} />
					</div>
					<MHGrid.DataGrid
						suppressLines={true}
						columns={Cols}
						fieldDef={this.props.FieldDesc}
						rows={data ? misc.filterMetrics(data, Fields.FinancialListSegment) : []}
						showHeaders={false}
					/>
				</div>
				<div className="col-md-12" id="operatTbl">
					<h6>Operational Metrics</h6>
					<MHGrid.DataGrid
						suppressLines={true}
						columns={Cols}
						fieldDef={this.props.FieldDesc}
						rows={data ? misc.filterMetrics(data, Fields.OperationalListSegment) : []}
						showHeaders="false"
					/>
				</div>
				<div className="col-md-12" id="qualityTbl">
					<h6>Quality Metrics</h6>
					<MHGrid.DataGrid
						suppressLines={true}
						columns={QCols}
						fieldDef={this.props.FieldDesc}
						rows={data ? misc.filterMetrics(qualityData, Fields.QualityListSegment) : []}
					/>
				</div>
				<DataGridFooterNotation />
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SummaryTables);
