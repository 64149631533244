export const BedTypeMetrics = [
	'total_beds_snf',
	'total_beds_nf',
	// 'total_beds_icf',
	// 'total_beds_hha',
	'total_beds_ltc',
	// 'total_beds_cmhc',
	// 'total_beds_hspc',
	'total_beds',
	'total_bed_days_available'
];

export const DischargeMetrics = [
	'discharges_medicare',
	'discharges_medicaid',
	'discharges_commercial',
	'discharges_other',
	'discharges_total'
];

export const InpatientDays = [
	'total_ip_days_visits_medicare',
	'total_ip_days_visits_medicaid',
	'total_ip_days_visits_title5',
	'total_ip_days_visits_other',
	'total_ip_days_visits_total'
];

export const AvgLosByInsType = [
	'total_alos_medicare',
	'total_alos_medicaid',
	'total_alos_title5',
	'total_alos_other',
	'total_alos_all_types'
];

export const UtilizationKeyPerfIndicators = [
	'occupancy_rate',
	'days_medicare_ratio',
	'days_medicaid_ratio',
	'days_other_title5_ratio'
];

export const InpatientDaysMetrics = [
	'inpatient_days_medicare',
	'inpatient_days_medicaid',
	'inpatient_days_commercial',
	'inpatient_days_other',
	'inpatient_total_days',
	'nursing_facility_ip_days_visits_medicaid',
	'nursing_facility_ip_days_visits_title5',
	'nursing_facility_ip_days_visits_other',
	'nursing_facility_ip_days_visits_total',
	'hospice_ip_days_visits_medicare',
	'hospice_ip_days_visits_medicaid',
	'hospice_ip_days_visits_other',
	'hospice_ip_days_visits_total',
	'home_health_agency_ip_days_visits_medicare',
	'home_health_agency_ip_days_visits_medicaid',
	'home_health_agency_ip_days_visits_title5',
	'home_health_agency_ip_days_visits_other',
	'home_health_agency_ip_days_visits_total',
	'other_longtermcare_ip_days_visits_total',
	'icf_iid_ip_days_visits_total',
	'discharge_medicare_ratio',
	'discharge_medicaid_ratio'
];

export const AvgLOSList = [
	'alos_snf_commercial',
	'alos_nf_commercial',
	'alos_snf_medicare',
	'alos_snf_medicaid',
	'alos_nf_medicaid',
	'alos_icf_medicaid',
	'alos_snf_total',
	'alos_nf_total',
	'alos_icf_total',
	'alos_ltc_total'
];

export const UtilizationSection = () => {
	return {
		section: 'Utilization',
		groups: [
			{
				key: 'snfUtiliizationBeds',
				label: 'Beds',
				fields: BedTypeMetrics,
				callout: `A breakdown of the certification status of beds within a given facility. 
				Note: Lack of certified beds under a certain status does not indicate the given facility does not provide the service.`
			},
			{
				key: 'snfUtiliizationInpatientDays',
				label: 'Inpatient Days',
				fields: InpatientDays,
				callout: `Number of days utilized in a facility by payor.  Note: Medicare provides funding for 100 days of skilled nursing care per qualifying event.  Patients can change payor status during their stay within a facility.`
			},
			{
				key: '',
				label: 'Discharges',
				fields: DischargeMetrics
			},
			{
				key: '',
				label: 'Average Inpatient Length of Stay (ALOS)',
				fields: AvgLosByInsType,
				callout: `Average length of stay in a facility by payor.  Note: Medicare provides funding for 100 days of skilled nursing care per qualifying event.`
			},
			{
				key: '',
				label: 'Key Utilization Indicators',
				fields: UtilizationKeyPerfIndicators,
				callout: `Metrics that allow for comparable utilization and performance of skilled nursing facilities across location and bed size.`
			}
		]
	};
};

export const UtilizationMetrics = () => {
	let field_items = [];
	UtilizationSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};

export const LaborRNDetail = [
	'registered_nurses_pd_hr_ppd',
	'contract_labor_registered_nurses_pd_hr_ppd',
	'total_labor_registered_nurses_pd_hr_ppd',
	'rn_hrs_per_resident_day',
	'registered_nurses_avg_hourly_wage',
	'contract_labor_registered_nurses_avg_hourly_wage',
	'total_labor_registered_nurses_avg_hourly_wage',
	'registered_nurses_adj_salaries_ppd',
	'cntrct_registered_nurses_adj_salaries_ppd',
	'total_labor_registered_nurses_pd_wages_ppd'
];

export const LaborLPNDetail = [
	'licensed_practical_nurses_paid_hours_ppd',
	'cont_licensed_practical_nurses_paid_hours_ppd',
	'total_licensed_practical_nurses_pd_hr_ppd',
	'lpn_hrs_per_resident_day',
	'licensed_practical_nurses_avg_hourly_wage',
	'cont_labor_lic_practical_nurses_avg_hr_wage',
	'total_lic_practical_nurses_avg_hr_wage',
	'licensed_practical_nurses_adj_salaries_ppd',
	'contract_labor_licensed_practical_nurses_adj_salaries_ppd',
	'total_lic_practical_nurses_hr_wg_ppd'
];

export const LaborCNADetail = [
	'certified_nursing_assistants_pd_hr_ppd',
	'contract_certified_nursing_assistants_pd_hr_ppd',
	'total_certified_nursing_assistants_pd_hr_ppd',
	'cna_hrs_per_resident_day',
	'certified_nursing_assistants_avg_hourly_wage',
	'cont_labor_cert_nursing_assist_avg_hr_wage',
	'total_certified_nursing_assistants_avg_hourly_wage',
	'certified_nursing_assistants_wage_ppd',
	'cont_labor_cert_nursing_assist_adj_salaries_ppd',
	'total_cert_nursing_assist_adj_salaries_ppd'
];

export const LaborNursingDetail = [
	'total_nursing_sum_per_patient_day',
	'total_nursing_hrs_per_resident_day',
	'total_nursing_sum_lines1thru3_avg_hourly_wage',
	'total_nursing_sum_lines1thru3_adj_salaries_ppd'
];

export const LaborPhyTherapistDetail = [
	'physical_therapists_pd_hr_ppd',
	'contract_labor_physical_therapists_pd_hr_ppd',
	'total_physical_therapists_pd_hr_ppd',
	'physical_therapists_avg_hourly_wage',
	'contract_labor_physical_therapists_avg_hourly_wage',
	'total_physical_therapists_avg_wages',
	'physical_therapists_adj_salaries_ppd',
	'contract_labor_physical_therapists_amt_ppd',
	'tot_phys_therapists_adj_salaries_ppd',
	'total_physical_therapists_wages_ppd'
];

export const LaborPhyThrpyAssistDetail = [
	'physical_therapy_assistants_pd_hr_ppd',
	'contract_labor_physical_therapy_assistants_pd_hr_ppd',
	'total_labor_physical_therapy_assistants_pd_hr_ppd',
	'physical_therapy_assistants_avg_hourly_wage',
	'cont_labor_phy_therapy_assistants_avg_hr_wage',
	'total_physical_therapy_assistants_paid_avg',
	'physical_therapy_assistants_adj_salaries_ppd',
	'contract_labor_physical_therapy_assistants_adj_sal_ppd',
	'total_physical_therapy_assistants_wages_ppd'
];

export const LaborPhyThrpyAidesDetail = [
	'physical_therapy_aides_pd_hr_ppd',
	'contract_labor_physical_therapy_aides_pd_hr_ppd',
	'total_physical_therapy_aides_pd_hr_ppd',
	'physical_therapy_aides_avg_hourly_wage',
	'contract_labor_physical_therapy_aides_avg_hourly_wage',
	'total_physical_therapy_aides_avg_wage',
	'physical_therapy_aides_adj_salaries_ppd',
	'contract_labor_physical_therapy_aides_adj_sal_ppd',
	'total_physical_therapy_aides_wages_ppd'
];

export const LaborOccThrpyDetail = [
	'occupational_therapists_paid_hours_ppd',
	'contract_occupational_therapists_paid_hours_ppd',
	'total_occupational_therapists_paid_hours_ppd',
	'occupational_therapists_avg_hourly_wage',
	'contract_labor_occupational_therapists_avg_hourly_wage',
	'total_occupational_therapists_avg_hourly_wage',
	'occupational_therapists_adj_sal_ppd',
	'contract_labor_occupational_therapists_adj_sal_ppd',
	'total_occupational_therapists_adj_sal_ppd'
];

export const LaborOccThrpyAssistDetail = [
	'occupational_therapy_assistants_pd_hrs_ppd',
	'cont_labor_occ_therapy_assistants_pd_hr_ppd',
	'total_occ_therapy_assistants_pd_hr_ppd',
	'occupational_therapy_assistants_avg_hourly_wage',
	'cont_labor_occ_therapy_assistants_avg_hr_wage',
	'total_occ_therapy_assistants_avg_hr_wage',
	'occupational_therapy_assistants_pd_wage_ppd',
	'cont_labor_occ_therapy_assistants_pd_wage_ppd',
	'total_occ_therapy_assistance_pd_wage_ppd'
];

export const LaborOccThrpyAidesDetail = [
	'occupational_therapy_aides_pd_hr_ppd',
	'contract_labor_occupational_therapy_aides_pd_hr_ppd',
	'total_occupational_therapy_aides_pd_hr_ppd',
	'occupational_therapy_aides_avg_hourly_wage',
	'cont_labor_occ_therapy_aides_avg_hourly_wage',
	'total_occupational_therapy_aides_avg_hourly_wage',
	'occupational_therapy_aides_adj_wage_ppd',
	'contract_labor_occupational_therapy_aides_wage_ppd',
	'total_occupational_therapy_aides_wage_ppd'
];

export const SpeechThrpyDetail = [
	'speech_therapists_pd_hr_ppd',
	'contract_labor_speech_therapists_pd_hr_ppd',
	'total_contract_labor_speech_therapists_pd_hr_ppd',
	'speech_therapists_avg_hourly_wage',
	'contract_labor_speech_therapists_avg_hourly_wage',
	'total_speech_therapists_avg_hourly_wage',
	'speech_therapists_wage_ppd',
	'contract_labor_speech_therapists_avg_hourly_wage_ppd',
	'total_speech_therapists_avg_hourly_wage_ppd'
];

export const RespThrpDetail = [
	'respiratory_therapists_pd_hr_ppd',
	'contract_respiratory_therapists_pd_hr_ppd',
	'total_respiratory_therapist_avg_hr_ppd',
	'respiratory_therapists_avg_hourly_wage',
	'contract_labor_respiratory_therapists_avg_hourly_wage',
	'total_respiratory_therapist_avg_hr_wage',
	'respiratory_therapists_wage_ppd',
	'contract_respiratory_therapists_wage_ppd',
	'total_respiratory_therapist_avg_wage_ppd'
];

export const AlliedMedicalDetail = [
	'other_medical_staff_hrs_ppd',
	'contract_other_medical_staff_hrs_ppd',
	'total_other_medical_staff_hrs_ppd',
	'other_medical_staff_avg_hourly_wage',
	'contract_labor_other_medical_staff_avg_hourly_wage',
	'total_other_medical_staff_avg_hr_wage',
	'other_medical_staff_wage_ppd',
	'contract_other_medical_staff_wage_ppd',
	'total_other_medical_staff_wage_ppd'
];

export const LaborOverheadExpendituresAvgHrlyWage = [
	'overhead_cost_employee_benefits_avg_hourly_wage',
	'overhd_cst_admin_and_gen_avg_hourly_wage',
	'overhd_cst_plt_oper_mnt_repairs_avg_hrly_wage',
	'overhead_cost_laundry_and_linen_service_avg_hourly_wage',
	'overhead_cost_housekeeping_avg_hourly_wage',
	'overhead_cost_dietary_avg_hourly_wage',
	'overhead_cost_nursing_administration_avg_hourly_wage',
	'overhead_cost_central_services_and_supply_avg_hourly_wage',
	'overhead_cost_pharmacy_avg_hourly_wage',
	'overhead_cost_medical_records_avg_hourly_wage',
	'overhead_cost_social_service_avg_hourly_wage',
	'interns_and_records_oh_avg_hrly_wage',
	'overhead_cost_other_general_service_avg_hourly_wage',
	'salary_hrs_oh_avg_hourly_wage'
];

export const LaborOverheadAdjustedSalaries = [
	// 'overhead_cost_housekeeping_adj_salaries',
	// 'overhead_cost_dietary_adj_salaries',
	// 'overhead_cost_nursing_administration_adj_salaries',
	// 'overhead_cost_central_services_and_supply_adj_salaries',
	// 'overhead_cost_pharmacy_adj_salaries',
	// 'overhead_cost_medical_records_adj_salaries',
	// 'overhead_cost_social_service_adj_salaries',
	// 'overhead_cost_other_general_service_adj_salaries',
	// 'salary_hrs_oh'
];

export const LaborDirectCareAvgHrlyWage = [
	// 'registered_nurses_avg_hourly_wage',
	// 'licensed_practical_nurses_avg_hourly_wage',
	// 'certified_nursing_assistants_avg_hourly_wage',
	// 'physical_therapists_avg_hourly_wage',
	// 'physical_therapy_assistants_avg_hourly_wage',
	// 'physical_therapy_aides_avg_hourly_wage',
	// 'occupational_therapists_avg_hourly_wage',
	// 'occupational_therapy_assistants_avg_hourly_wage',
	// 'occupational_therapy_aides_avg_hourly_wage',
	// 'speech_therapists_avg_hourly_wage',
	// 'respiratory_therapists_avg_hourly_wage',
	// 'other_medical_staff_avg_hourly_wage'
];

export const LaborDirectCareContractAvgHrlyWage = [
	// 'contract_labor_registered_nurses_avg_hourly_wage',
	// 'cont_labor_lic_practical_nurses_avg_hr_wage',
	// 'cont_labor_cert_nursing_assist_avg_hr_wage',
	// 'contract_labor_physical_therapists_avg_hourly_wage',
	// 'cont_labor_phy_therapy_assistants_avg_hr_wage',
	// 'contract_labor_physical_therapy_aides_avg_hourly_wage',
	// 'contract_labor_occupational_therapists_avg_hourly_wage',
	// 'cont_labor_occ_therapy_assistants_avg_hr_wage',
	// 'cont_labor_occ_therapy_aides_avg_hourly_wage',
	// 'contract_labor_speech_therapists_avg_hourly_wage',
	// 'contract_labor_respiratory_therapists_avg_hourly_wage',
	// 'contract_labor_other_medical_staff_avg_hourly_wage'
];

export const LaborPpdDetails = [
	// 'speech_therapists_pd_hr_ppd',
	// 'respiratory_therapists_pd_hr_ppd',
	// 'registered_nurses_pd_hr_ppd',
	// 'physical_therapy_assistants_pd_hr_ppd',
	// 'physical_therapy_aides_pd_hr_ppd',
	// 'physical_therapists_pd_hr_ppd',
	// 'other_medical_staff_pd_hr_ppd',
	// 'occupational_therapy_assistants_pd_hrs_ppd',
	// 'occupational_therapy_aides_pd_hr_ppd',
	// 'occupational_therapists_paid_hours_ppd',
	// 'licensed_practical_nurses_paid_hours_ppd',
	// 'certified_nursing_assistants_pd_hr_ppd'
];
export const PBJStaffingMetrics = [
	'rn_hrs_per_resident_day',
	'lpn_hrs_per_resident_day',
	'cna_hrs_per_resident_day',
	'total_nursing_hrs_per_resident_day'
];

export const PBJNonNurseStaffingMetrics = [
	'admin_hrs_per_resident_day',
	'phy_service_hrs_per_resident_day',
	'therapeutic_service_hrs_per_resident_day',
	'pharmacist_hrs_per_resident_day',
	'dietary_services_hrs_per_resident_day',
	'mhsvc_hrs_per_resident_day'
];

export const LaborSection = () => {
	return {
		section: 'Labor',
		groups: [
			{
				key: 'snfLaborRNDetail',
				label: 'Registered Nursing',
				fields: LaborRNDetail
			},
			{
				key: 'snfLaborLPNDetail',
				label: 'Licensed Practical Nurses',
				fields: LaborLPNDetail
			},
			{
				key: 'snfLaborCNADetail',
				label: 'Certified Nursing Assistants/Nursing',
				fields: LaborCNADetail
			},
			{
				key: 'snfLaborNursingDetail',
				label: 'Total Nursing',
				fields: LaborNursingDetail
			},
			{
				key: 'snfLaborPhyTherapistDetail',
				label: 'Physical Therapists',
				fields: LaborPhyTherapistDetail
			},
			{
				key: 'snfLaborPhyThrpyAssistDetail',
				label: 'Physical Therapy Assistants',
				fields: LaborPhyThrpyAssistDetail
			},
			{
				key: 'snfLaborOccThrpyDetail',
				label: 'Occupational Therapists',
				fields: LaborOccThrpyDetail
			},
			{
				key: 'snfLaborOccThrpyAssistDetail',
				label: 'Occupational Therapy Assistants',
				fields: LaborOccThrpyAssistDetail
			},
			{
				key: 'snfLaborOccThrpyAidesDetail',
				label: 'Occupational Therapy Aides',
				fields: LaborOccThrpyAidesDetail
			},
			{
				key: 'snfSpeechThrpyDetail',
				label: 'Speech Therapists',
				fields: SpeechThrpyDetail
			},
			{
				key: 'snfRespThrpDetail',
				label: 'Respiratory Therapists',
				fields: RespThrpDetail
			},
			{
				key: 'snfAlliedMedicalDetail',
				label: 'Allied Medical Staff',
				fields: AlliedMedicalDetail
			},
			{
				key: 'snfLaborOverheadExpendituresAvgHrlyWage',
				label: 'Overhead Expenditures (Avg Hourly Wage)',
				fields: LaborOverheadExpendituresAvgHrlyWage,
				callout: `Facility-reported average hourly wages for staff that appear on the facility's payroll.  Note: A facility may contract certain services that would not appear on the facility's payroll while the services are still provided at the facility.`
			}
		]
	};
};

export const LaborDetail1 = () => {
	let list = [];
	list = list.concat(LaborRNDetail);
	list = list.concat(LaborLPNDetail);
	list = list.concat(LaborCNADetail);
	return list;
};
export const LaborDetail2 = () => {
	let list = [];
	list = list.concat(LaborNursingDetail);
	list = list.concat(LaborPhyTherapistDetail);
	list = list.concat(LaborPhyThrpyAssistDetail);
	return list;
};
export const LaborDetail3 = () => {
	let list = [];
	list = list.concat(LaborOccThrpyDetail);
	list = list.concat(LaborOccThrpyAssistDetail);
	list = list.concat(LaborOccThrpyAidesDetail);
	return list;
};
export const LaborDetail4 = () => {
	let list = [];
	list = list.concat(SpeechThrpyDetail);
	list = list.concat(RespThrpDetail);
	list = list.concat(AlliedMedicalDetail);
	list = list.concat(LaborOverheadExpendituresAvgHrlyWage);
	return list;
};

export const LaborMetrics = () => {
	let field_items = [];
	LaborSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};

export const OverheadCostsPPD = [
	'capital_relatedcosts_buildings_fixtures_ppd',
	'capital_relatedcosts_moveable_equipment_ppd',
	'employee_benefits_ppd',
	'administrative_and_general_ppd',
	'plant_operation_maintenance_and_repairs_ppd',
	'laundry_and_linen_service_ppd',
	'housekeeping_ppd',
	'dietary_ppd',
	'nursing_administration_ppd',
	'central_services_and_supply_ppd',
	'pharmacy_ppd',
	'medical_records_and_library_ppd',
	'social_service_ppd',
	'nursing_and_allied_health_education_ppd',
	'other_general_service_cost_ppd',
	'total_overhead_costs_ppd'
];

export const DirectCostsPPD = [
	'skillednursingfacility_routine_cost_center_net_expenses_ppd',
	'nursingfacility_routine_cost_center_net_expenses_ppd',
	'icf_iid_routine_cost_center_net_expenses_ppd',
	'other_long_term_care_routine_cost_center_net_expenses_ppd',
	'total_direct_costs_ppd'
];

export const AncillaryCostsPPD = [
	'dental_care_costs_ppd',
	'drugs_charged_to_patients_costs_ppd',
	'elctrcardio_ancillary_cc_net_expenses_ppd',
	'intravenous_therapy_ancillary_cost_center_net_expenses_ppd',
	'laboratory_ancillary_cost_center_net_expenses_ppd',
	'med_supplies_charged_to_patients_costs_ppd',
	'occupationaltherapy_ancillary_cost_center_net_expenses_ppd',
	'oxygentherapy_ancillary_cost_center_net_expenses_ppd',
	'physicaltherapy_ancillary_cost_center_net_expenses_ppd',
	'radiology_ancillary_cost_center_net_expenses_ppd',
	'speech_pathology_ancillary_cost_center_net_expenses_ppd',
	'support_service_costs_ppd',
	'total_ancillary_costs_ppd'
];

export const MedicareProfitability = [
	'medicare_marigin',
	'days_cash_on_hand',
	'days_in_ar',
	'labor_cost_as_a_percent_of_revenue',
	'total_labor_cost_ppd',
	'operating_margin',
	'total_profit_margin',
	'total_profit_margin_ecld_cap_rltd_cost',
	'days_in_ap',
	'total_ip_days_visits_total'
];

export const PDPMCalculations = [
	'returned_pdmp_claims',
	'clean_pdmp_claims',
	'avg_pdpm_nursing_ppd',
	'avg_pdpm_pt_ppd_pessimistic',
	'avg_pdpm_pt_ppd_optimistic',
	'avg_pdpm_ot_ppd_pessimistic',
	'avg_pdpm_ot_ppd_optimistic',
	'avg_pdpm_slp_ppd',
	'avg_pdpm_nta_ppd',
	'avg_pdpm_non_case_mix_ppd',
	'pdpm_optimistic_pmt',
	'pdpm_pessimistic_pmt',
	'rugiv_total_pmt',
	'pdpm_v_rug_impact'
];

export const FinancialSection = () => {
	return {
		section: 'Financial',
		groups: [
			// {
			// 	key: 'snfPDPMCalculations',
			// 	label: 'Modeled Historical PDPM Scenarios',
			// 	fields: PDPMCalculations,
			// 	callout: `CMS changes to payment models resulted in the new PDPM (Patient Driven Payment Model) that aligns with CMS's goal to shift care models in skilled nursing facilties.  The following are metrics that may prove beneficial as facilities shift focus to the new payment model.`
			// },
			{
				key: 'snfOverheadCostsMetrics',
				label: 'Medicare Profitability Analysis',
				fields: MedicareProfitability,
				callout: `Note: Medicare provides funding for 100 days of skilled nursing care per qualifying event and tends to be an area of focus for profitability for facilities.`
			},
			{
				key: 'snfOverheadCostsMetrics',
				label: 'Overhead Costs Per Patient Day',
				fields: OverheadCostsPPD
			},
			{
				key: 'snfDirectCostsMetrics',
				label: 'Direct Costs Per Patient Day',
				fields: DirectCostsPPD
			},
			{
				key: 'snfOverheadCostsMetrics',
				label: 'Ancillary Costs Per Patient Day',
				fields: AncillaryCostsPPD
			}
		]
	};
};
export const FinancialDetail1 = () => {
	let list = [];
	list = list.concat(PDPMCalculations);
	list = list.concat(MedicareProfitability);
	list = list.concat(OverheadCostsPPD);
	return list;
};
export const FinancialDetail2 = () => {
	let list = [];
	list = list.concat(DirectCostsPPD);
	list = list.concat(AncillaryCostsPPD);
	return list;
};

export const FinancialMetrics = () => {
	let field_items = [];
	FinancialSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};
export const All = () => {
	let list = [];
	list = list.concat(PDPMCalculations);
	list = list.concat(FinancialMetrics());
	list = list.concat(UtilizationMetrics());
	list = list.concat(LaborMetrics());
	return list;
};

export const Info = () => {
	return {
		topic: 'Benchmark',
		groups: [
			{
				key: 'benchUtilization',
				label: 'Utilization Metrics',
				fields: UtilizationMetrics()
			},
			{
				key: 'benchLaborMetrics',
				label: 'Labor Metrics',
				fields: LaborMetrics()
			},
			{
				key: 'benchFinancialMetrics',
				label: 'Financial Metrics',
				fields: FinancialMetrics()
			}
		]
	};
};
