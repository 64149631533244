import React, { Fragment } from 'react';

export default class TabUtil extends React.Component {
	constructor(props) {
		super(props);
		this.headers = props.headers ? props.headers : [];
		this.state = {
			selectedTab: 0
		};

		this.tabContent = props.content ? props.content : [];

		this.tabChanged = this.tabChanged.bind(this);
	}

	tabChanged(id) {
		let state = this.state;
		state.selectedTab = id;
		this.setState(state);
	}

	render() {
		let tabs = [];

		for (let i = 0; i < this.headers.length; i++) {
			let idx = i;
			tabs.push(
				<li
					className={
						this.state.selectedTab === i
							? 'react-tabs__tab react-tabs__tab--selected  d-block'
							: 'react-tabs__tab d-block'
					}
				>
					<a className="d-block" id={i} onClick={() => this.tabChanged(i)}>
						{this.headers[i].toString()}
					</a>
				</li>
			);
		}

		let tabContent = [];

		for (let i = 0; i < this.tabContent.length; i++) {
			tabContent.push(
				<div className={this.state.selectedTab === i ? 'tab-pane active' : 'tab-pane'}>
					{(this.tabContent[i] && this.tabContent[i]) || null}
				</div>
			);
		}

		return (
			<div className="row mx-0" style={{ width: '100%' }}>
				<ul
					className="d-none d-md-flex px-0 col-md-12 mx-auto react-tabs__tab-list mt-1"
					role="tablist"
				>
					{tabs}
				</ul>
				<div className="tab-content p-0 col-md-12">{tabContent}</div>
			</div>
		);
	}
}
