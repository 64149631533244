import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { snfMarketshare } from '../../Common/SkilledNursingActions';
import * as MHGrid from '../../../tools/datagrid';
import LoadingSpinner from '../../../tools/loadingSpinner';
import NoData from '../../../core/components/notifications/NoData';
import * as misc from '../../../lib/misc';

const mapStateToProps = store => {
	return {
		refHosp: store.snfSMDMarketshareData.refHosp,
		fetched: store.snfSMDMarketshareData.fetched,
		fetching: store.snfSMDMarketshareData.fetching,
		mdcMap: store.snfSMDMarketshareData.mdcMap
	};
};
const mapDispatchToProps = dispatch => {
	return {
		GetMarketshareData: snfProvider => dispatch(snfMarketshare(snfProvider, 'snf'))
	};
};

class ReferringHospitals extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			facilityId: this.props.facilityId
		};
		this.setState(this.state);
	}

	componentDidMount() {
		this.InitializeData();
	}

	InitializeData() {
		if (!this.props.fetching && !this.props.fetched) {
			this.props.GetMarketshareData(this.props.facilityId);
		}
	}

	GetColumns(fy_year) {
		let SummaryCols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'prvdr_num',
				MHGrid.DataGridHeaderBuilder('Provider', 'center', 50),
				50,
				MHGrid.ColType_Text
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'facility_name',
				MHGrid.DataGridHeaderBuilder('Facility Name', 'center', 300),
				300,
				MHGrid.ColType_Text
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'avg_los',
				MHGrid.DataGridHeaderBuilder('Hospital Avg Length of Stay', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'cases',
				MHGrid.DataGridHeaderBuilder('Total Referrals', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'total_snf_days',
				MHGrid.DataGridHeaderBuilder('Utilized SNF Days', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'snf_payments',
				MHGrid.DataGridHeaderBuilder('SNF Payments', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'fy_4',
				MHGrid.DataGridHeaderBuilder('Total % of ' + String(fy_year - 4), 'center', 100),
				100,
				MHGrid.ColType_Percent
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'fy_3',
				MHGrid.DataGridHeaderBuilder('Total % of ' + String(fy_year - 3), 'center', 100),
				100,
				MHGrid.ColType_Percent
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'fy_2',
				MHGrid.DataGridHeaderBuilder('Total % of ' + String(fy_year - 2), 'center', 100),
				100,
				MHGrid.ColType_Percent
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'fy_1',
				MHGrid.DataGridHeaderBuilder('Total % of ' + String(fy_year - 1), 'center', 100),
				100,
				MHGrid.ColType_Percent
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'fy',
				MHGrid.DataGridHeaderBuilder('Total % of ' + String(fy_year), 'center', 100),
				100,
				MHGrid.ColType_Percent
			)
		);
		return SummaryCols;
	}

	render() {
		let facility = misc.getFacilityInfo();
		let facInfo =
			this.props.fetched && this.props.refHosp
				? 'Referring Hospitals to ' + facility.facility_name + ' (Medicare)'
				: '';
		let clmns =
			this.props.fetched && this.props.refHosp
				? this.GetColumns(_.max(this.props.refHosp.rows.map(a => a.max_fy)))
				: null;
		let data = this.props.fetched && this.props.refHosp ? this.props.refHosp.rows : [];

		return (
			<div>
				<div className="d-flex">
					<h4 class="tblHead">
						{facInfo}
						<i
							className="ml-2 MH-InfoTooltip py-auto mt-auto pb-1 mb-2 fa fa-xs fa-info-circle mr-auto"
							aria-hidden="true"
							data-toggle="popover"
							data-trigger="hover"
							data-content={
								'Referral patterns are determined by mapping Medicare LDS Inpatient to Skilled Nursing admissions. Note: Less than 10 cases supressed'
							}
						/>
					</h4>
					
				</div>
				<MHGrid.DataGrid suppressLines={true} columns={clmns} rows={data} showHeaders={true} />
				{this.props.fetched && data.length < 1 ? (
					<NoData />
				) : this.props.fetching ? (
					<LoadingSpinner />
				) : (
					''
				)}
			</div>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReferringHospitals);
