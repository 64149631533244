import React, { Component } from "react";
import zxcvbn from "zxcvbn";

const validEmail = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class Register extends Component {
  constructor (props) {
    super(props);
    this.state = {
      userInfo: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        url: ''
      },
      newInput: {
        newFirst: '',
        newLast: '',
        newEmail: '',
        newPhone: '',
        newCompany: '',
        newAddress: '',
        newCity: '',
        newState: '',
        newZip: '',
        newUrl: ''
      },
      changes: {
        fName: false,
        lName: false,
        email: false,
        phone: false,
        comp: false,
        address: false,
        city: false,
        state: false,
        zip: false,
        url: false
      },
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        general: ''
      },
      disable: true,
      confirm: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.confirmChange = this.confirmChange.bind(this);
  };

  componentDidMount() {
    this.getUserInfo(this.props.userData);
  };

  getUserInfo = (data) => {
    let state = this.state;
    
    Object.entries(data).map(([key, value]) => {
      switch (key) {
        case 'First_Name':
          if(value) {
            let tempFirst = value;
            state.userInfo.firstName = tempFirst.charAt(0).toUpperCase() + tempFirst.slice(1);
          }
          break;
        case 'Last_Name':
          if(value) {
            let tempLast = value;
            state.userInfo.lastName = tempLast.charAt(0).toUpperCase() + tempLast.slice(1);
          }
          break;
        case 'Email':
          if(value) {
            state.userInfo.email = value;
          }
          break;
        case 'Phone':
          if(value) {
            state.userInfo.phone = value;
          }
          break;
        case 'Company':
          Object.entries(data.Company).map(([key, value]) => {
            switch (key) {
              case 'Company':
                if(value) {
                  state.userInfo.company = value;
                }
                break;
              case 'Address':
                if(value) {
                  state.userInfo.address = value;
                }
                break;
              case 'City':
                if(value) {
                  state.userInfo.city = value;
                }
                break;
              case 'State':
                if(value) {
                  let tempState = value;
                  state.userInfo.state = tempState.toUpperCase();
                }
                break;
              case 'Zip':
                if(value) {
                  state.userInfo.zip = value;
                }
                break;
              case 'Url':
                if(value) {
                  state.userInfo.url = value;
                }
                break;
              default:
                break;
            };
          });
        break;
        default:
          break;
      };
    });
    this.setState(state);
  };

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let state = this.state;
    switch (name) {
      case 'firstName': 
        let tempFirst = e.target.value;
        state.changes.fName = true;
        if(value.length < 3 && value.length != 0) {
          state.errors.firstName = 'First name must be at least 3 characters';
        } else if(value.length === 0) {
          state.errors.firstName = 'A First Name is Required';
        } else {
          state.errors.firstName = '';
        }
        state.newInput.newFirst = tempFirst.charAt(0).toUpperCase() + tempFirst.slice(1);
        if(state.newInput.newFirst === state.userInfo.firstName) {
          state.changes.fName = false;
        }
        break;
      case 'lastName':
        let tempLast = e.target.value;
        state.changes.lName = true;
        if(value.length < 3 && value.length != 0) {
          state.errors.lastName = 'Last name must be at least 3 characters';
        } else if(value.length === 0) {
          state.errors.lastName = 'A Last Name is Required';
        } else {
          state.errors.lastName = '';
        }
        state.newInput.newLast = tempLast.charAt(0).toUpperCase() + tempLast.slice(1);
        if(state.newInput.newLast === state.userInfo.lastName) {
          state.changes.lName = false;
        }
        break;
      case 'email':
        state.newInput.newEmail = e.target.value;
        state.changes.email = true;
        if(!validEmail.test(value) || value.length === 0) {
          state.errors.email = 'A valid email is required';
          state.disable = true;
        } else {
          state.errors.email = '';
        }
        if(state.newInput.newEmail === state.userInfo.email) {
          state.changes.email = false;
        }
        break;
      case 'phone':
        let phoneNumber = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        state.changes.phone = true;
        state.newInput.newPhone = e.target.value;
        if(value.match(phoneNumber)) {
          state.errors.phone = '';
        } else if(value.length === 0) {
          state.errors.phone = 'A Phone Number is Required';
        } else {
          state.errors.phone = 'Please provide a valid phone number';
        }
        if(state.newInput.newPhone === state.userInfo.phone) {
          state.changes.phone = false;
        }
        break;
      case 'company':
        let tempCompany = e.target.value;
        state.changes.comp = true;
        state.newInput.newCompany = tempCompany.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        if(value.length < 3 && value.length != 0) {
          state.errors.company = 'Company name must be at least 3 characters';
        } else if(value.length === 0) {
          state.errors.company = 'A Company Name is Required';
        } else {
          state.errors.company = '';
        }
        if(state.newInput.newCompany === state.userInfo.company) {
          state.changes.comp = false;
        }
        break;
      case 'address':
        let tempAddress = e.target.value;
        state.changes.address = true;
        state.newInput.newAddress = tempAddress.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        if(state.newInput.newAddress === state.userInfo.address) {
          state.changes.address = false;
        }
        break;
      case 'city':
        let tempCity = e.target.value;
        state.changes.city = true;
        state.newInput.newCity = tempCity.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        if(state.newInput.newCity === state.userInfo.city) {
          state.changes.city = false;
        }
        break;
      case 'state':
        state.changes.state = true;
        state.newInput.newState = e.target.value.toUpperCase();
        if(state.newInput.newState === state.userInfo.state) {
          state.changes.state = false;
        }
        break;
      case 'zip':
        state.changes.zip = true;
        state.newInput.newZip = e.target.value;
        if(state.newInput.newZip === state.userInfo.zip) {
          state.changes.zip = false;
        }
        break;
      case 'url':
        state.changes.url = true;
        state.newInput.newUrl = e.target.value;
        if(state.newInput.newUrl === state.userInfo.url) {
          state.changes.url = false;
        }
        break;
      default:
        break;
    };
    if(state.changes.fName || state.changes.lName || state.changes.email || state.changes.phone || state.changes.comp) {
      if(state.errors.firstName || state.errors.lastName || state.errors.email || state.errors.phone || state.errors.company) {
        state.disable = true;
      } else {
        state.disable = false;
      }
    } else if(state.changes.address || state.changes.city || state.changes.state || state.changes.zip || state.changes.url) {
      state.disable = false;
    } else {
      state.disable = true;
    }
    this.setState(state);
  };

  handleSubmit = e => {
    e.preventDefault();
    let state = this.state;
    if(state.changes.fName) {
      state.userInfo.firstName = state.newInput.newFirst;
    }
    if(state.changes.lName) {
      state.userInfo.lastName = state.newInput.newLast;
    }
    state.userInfo.username = state.userInfo.firstName + ' ' + state.userInfo.lastName;
    if(state.changes.phone) {
      state.userInfo.phone = state.newInput.newPhone;
    }
    if(state.changes.comp) {
      state.userInfo.company = state.newInput.newCompany.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    if(state.changes.address) {
      state.userInfo.address = state.newInput.newAddress.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    if(state.changes.city) {
      state.userInfo.city = state.newInput.newCity.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    if(state.changes.state) {
      state.userInfo.state = state.newInput.newState;
    }
    if(state.changes.zip) {
      state.userInfo.zip = state.newInput.newZip;
    }
    if(state.changes.url) {
      state.userInfo.url = state.newInput.newUrl;
    }
    if(state.changes.email) {
      state.userInfo.email = state.newInput.newEmail;
      if(this.props.verifyEmail) {
        this.props.verifyEmail(state.userInfo);
      } else {
        this.state.errors.general = 'We are unable to verify your email at this time';
      }
    } else {
      state.confirm = true;
    }
    this.setState(state);
  };

  confirmChange = e => {
    e.preventDefault();
    let state = this.state;
    if(this.props.submitInfo) {
      this.props.submitInfo(state.userInfo);
    } else {
      state.errors.general('We are unable to process your changes at this time');
    }
  };

  render() {
    const {errors} = this.state;
    return (
      <>
        {this.state.confirm === false ? 
          <>
            <h3>Registration Info</h3>
            <h4>{this.props.userData.Email}</h4>
            <p id="reqMess">* Required  Fields</p>
            <form id="regForm" className="inputFrame" autoComplete="off">
              <div>
                <label htmlFor="firstName"><i className="fas fa-user"></i></label>
                <input 
                  type="text" 
                  className="loginInput reqInput" 
                  id="firstName" 
                  name="firstName" 
                  placeholder="* First Name" 
                  value={this.state.changes.fName === true ? this.state.newInput.newFirst : this.state.userInfo.firstName} 
                  onChange={this.handleChange}
                  required
                  autoComplete="off"
                />
                {errors.firstName.length > 0 && <span className="error">{errors.firstName}</span>}
              </div>
              <div>
                <label htmlFor="lastName"><i className="fas fa-signature"></i></label>
                <input 
                  type="text" 
                  className="loginInput reqInput" 
                  id="lastName" 
                  name="lastName" 
                  placeholder="* Last Name" 
                  value={this.state.changes.lName ? this.state.newInput.newLast : this.state.userInfo.lastName} 
                  onChange={this.handleChange}
                  required
                />
                {errors.lastName.length > 0 && <span className="error">{errors.lastName}</span>}
              </div>
              {/* <div>
                <label htmlFor="email"><i className="fas fa-at"></i></label>
                <input
                  type="email"
                  className="loginInput reqInput" 
                  id="email" 
                  name="email" 
                  placeholder="* Email" 
                  value={this.state.changes.email ? this.state.newInput.newEmail : this.state.userInfo.email}
                  onChange={this.handleChange}
                  required
                />
                {errors.email.length > 0 && <span className="error">{errors.email}</span>}
              </div> */}
              <div>
                <label htmlFor="phone"><i className="fas fa-phone-alt"></i></label>
                <input
                  type="tel"
                  className="loginInput reqInput" 
                  id="phone" 
                  name="phone" 
                  placeholder="* Phone #"
                  value={this.state.changes.phone ? this.state.newInput.newPhone : this.state.userInfo.phone}
                  onChange={this.handleChange}
                  required
                />
                {errors.phone.length > 0 && <span className="error">{errors.phone}</span>}
              </div>
              <div>
                <label htmlFor="comp"><i className="fas fa-building"></i></label>
                <input
                  type="text"
                  className="loginInput reqInput" 
                  id="comp" 
                  name="company" 
                  placeholder="* Company Name"
                  value={this.state.changes.comp ? this.state.newInput.newCompany : this.state.userInfo.company}
                  onChange={this.handleChange}
                  required
                />
                {errors.company.length > 0 && <span className="error">{errors.company}</span>}
              </div>
              <div>
                <label htmlFor="address"><i className="fas fa-map-pin"></i></label>
                <input
                  type="text"
                  className="loginInput" 
                  id="address" 
                  name="address" 
                  placeholder="Street Address"
                  value={this.state.changes.address ? this.state.newInput.newAddress : this.state.userInfo.address}
                  onChange={this.handleChange} 
                />
              </div>
              <div>
                <label htmlFor="city"><i className="fas fa-map-marker-alt"></i></label>
                <input
                  type="text"
                  className="loginInput" 
                  id="city" 
                  name="city" 
                  placeholder="City"
                  value={this.state.changes.city ? this.state.newInput.newCity : this.state.userInfo.city}
                  onChange={this.handleChange} 
                />
              </div>
              <div id="stateWrap">
                <label htmlFor="state"><i className="fas fa-map-marked-alt"></i></label>
                <input
                  type="text"
                  className="loginInput" 
                  id="state" 
                  name="state" 
                  placeholder="State"
                  value={this.state.changes.state ? this.state.newInput.newState : this.state.userInfo.state}
                  onChange={this.handleChange} 
                />
              </div>
              <div id="zipWrap">
                <label htmlFor="zip"><i className="fas fa-map"></i></label>
                <input
                  type="text"
                  className="loginInput" 
                  id="zip" 
                  name="zip" 
                  placeholder="Zip"
                  value={this.state.changes.zip ? this.state.newInput.newZip : this.state.userInfo.zip}
                  onChange={this.handleChange} 
                />
              </div>
              <div>
                <label htmlFor="url"><i className="fas fa-globe"></i></label>
                <input
                  type="text"
                  className="loginInput" 
                  id="url" 
                  name="url" 
                  placeholder="URL"
                  value={this.state.changes.url ? this.state.newInput.newUrl : this.state.userInfo.url}
                  onChange={this.handleChange} 
                />
              </div>
            </form>
          </>
        :
          <>
            <h3>Confirm Changes</h3>
            <ul className="list-unstyled">
              <li>Username: <strong>{this.state.userInfo.username}</strong></li>
              <li>Email: <strong>{this.state.userInfo.email}</strong></li>
              <li>Phone: {this.state.userInfo.phone ? <strong>{this.state.userInfo.phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}</strong> : <span>Not Provided</span>}</li>
              <li>Company: {this.state.userInfo.company ? <strong>{this.state.userInfo.company}</strong> : 'Not Provided'}</li>
              <li>Address: {this.state.userInfo.address ? <strong>{this.state.userInfo.address}</strong> : <span>Not Provided</span>}</li>
              <li>City: {this.state.userInfo.city ? <strong>{this.state.userInfo.city}</strong> : <span>Not Provided</span>}</li>
              <li>State: {this.state.userInfo.state ? <strong>{this.state.userInfo.state}</strong> : <span>Not Provided</span>}</li>
              <li>Zip: {this.state.userInfo.zip ? <strong>{this.state.userInfo.zip}</strong> : <span>Not Provided</span>}</li>
              <li>URL: {this.state.userInfo.url ? <strong>{this.state.userInfo.url}</strong> : <span>Not Provided</span>}</li>
            </ul>
          </>
        }
        <div>
          {this.state.confirm === false ? 
            <input 
              type="submit" 
              id="sub" 
              className="loginBttn" 
              name="submit" 
              value="Submit Changes"
              onClick={this.handleSubmit}
              disabled={this.state.disable}
            />
          :
            <input 
              type="submit" 
              id="confirm" 
              className="loginBttn" 
              name="confirm" 
              value="Confirm Changes"
              onClick={this.confirmChange}
            />
          }
          <input 
            type="submit" 
            id="cancel" 
            className="loginBttn" 
            name="cancel" 
            value="Cancel"
            onClick={this.props.handleCancel}
          />
          {errors.general > 0 && <span className="error">{errors.general}</span>}
        </div>
      </>
    );
  };
};

export default(Register);