import React, { Component } from "react";
import Plan from "./Plan";
import Register from "./Register";
import Verify from "./Verify";
import Pay from "./Payment";
import Confirm from "./Confirm";
import '../../styles.css';
import { UserGet } from "../../lib/ApiUtil";
import * as UserUtil from '../../lib/UserUtil';
import * as products from './products';
import AuthUser from '../Security/Auth';
import { Auth } from "aws-amplify";
import * as promoUtil from '../../lib/PromoUtil';

class Subscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1, 
      currentPlan: {
        plan: '',
        selected: '',
        planName: '',
        orgPrice: '',
        price: '',
        pricePer: '',
        description: '',
        liceTotal: 1,
        liceAdded: null,
        promo: false,
        expDate: null
      }, 
      regInfo: {}, 
      regError: null, 
      payInfo: null,
      userData: sessionStorage.getItem('profile')
    };
    this.onPlanComplete = this.onPlanComplete.bind(this);
    this.onRegisterComplete = this.onRegisterComplete.bind(this);
    this.onVerifyComplete = this.onVerifyComplete.bind(this);
    this.liceCntChange = this.liceCntChange.bind(this);
    this.onPayComplete = this.onPayComplete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onAlreadyVerify = this.onAlreadyVerify.bind(this);

  }

  componentDidMount() {
		document.body.id = 'subscribe';
    let winH = window.innerHeight,
    bodyH = document.getElementById('subscribe').clientHeight;
    this.state.userData = JSON.parse(this.state.userData);
    if(this.state.userData && this.state.userData.Licenses[0].Product) {
      this.renewLicense();
    }
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
	}
  
  renewLicense() {
    let state = this.state;
    let prodName = state.userData.Licenses[0].Product;
    if(prodName === 'PREMIUM') {
      state.currentPlan.selected = products.premium_access.name;
      state.currentPlan.plan = products.premium_access.id;
      state.currentPlan.price = products.premium_access.price.single;
      state.currentPlan.planName = products.premium_access.fullName;
    } else if(prodName === 'SNF') {
      state.currentPlan.selected = products.snf_access.name;
      state.currentPlan.plan = products.snf_access.id;
      state.currentPlan.price = products.snf_access.price.single;
      state.currentPlan.planName = products.snf_access.fullName;
    } else {
      state.currentPlan.selected = products.hospital_access.name;
      state.currentPlan.plan = products.hospital_access.id;
      state.currentPlan.price = products.hospital_access.price.single;
      state.currentPlan.planName = products.hospital_access.fullName;
    }
    state.regInfo.email = state.userData.Email;
    state.regInfo.phone = state.userData.Phone;
    state.regInfo.firstName = state.userData.First_Name;
    state.regInfo.lastName = state.userData.Last_Name;
    state.regInfo.username = state.userData.User_Name;
    state.currentPage = 4;
    this.setState(state);
    // console.log('-------------');
    console.log(state);
  }

  async onPlanComplete(info) {
    try 
    {
      let state = this.state;
      state.currentPlan = info;
      if(state.userData) {
        state.regInfo.email = state.userData.Email;
        state.regInfo.phone = state.userData.Phone;
        state.regInfo.firstName = state.userData.First_Name;
        state.regInfo.lastName = state.userData.Last_Name;
        state.regInfo.username = state.userData.User_Name;

        if(state.currentPlan.price === 0) 
        {
            let promoResponse = await promoUtil.redeemPromo(state.currentPlan.promo);
            await AuthUser.resetSession();
            state.currentPage = 5;
        } else {
            state.currentPage = 4;
        }
      } else {
        state.currentPage = 2;
      }
      this.setState(state);
    }
    catch(err)
    {
      state.currentPage = 2;
      this.setState(state);
    }
    return; 
  }

  async onRegisterComplete(info) {
    let state = this.state;
    try {
      let regUser = await UserUtil.registerUser(info);

      if(regUser && regUser.confirmed && regUser.confirmed == true)
      {
        state.currentPage = 4;
        state.regInfo = info;
        await this.onAlreadyVerify();
      }
      else
      {
        state.currentPage = 3;
        state.regInfo = info;
      }
    }
    catch(err) {
      state.currentPage = 2;
      state.regError = err.Message;
      // console.log(err);
    }
    this.setState(state);
  }

  async onAlreadyVerify() {
    let state = this.state;
    try 
    {
        let state = this.state;

        // Update User Profile and Token Here 
        await Auth.signIn( this.state.regInfo.email,  this.state.regInfo.password);
        await AuthUser.resetSession();
        let promoResponse = null;
        if(state.currentPlan.price === 0) 
        {
            promoResponse = await promoUtil.redeemPromo(state.currentPlan.promo);
            await AuthUser.resetSession();
            state.currentPage = 5;
        } else {
            state.currentPage = 4;
        }
    }
    catch(err) 
    {
      state.currentPage = 4;
      state.regError = err.message;
    }
    this.setState(state);
  }



  async onVerifyComplete(code) {
    let state = this.state;
    
    try 
    {
      let result = await UserUtil.confirmRegistration({userId: this.state.regInfo.email, key: code});
      let state = this.state;

      if(result && result.Error) {
        state.currentPage = 3;
        state.regError = result.Message;
      } else {
        // Update User Profile and Token Here 
        await Auth.signIn( this.state.regInfo.email,  this.state.regInfo.password);
        await AuthUser.resetSession();
        let promoResponse = null;
        if(state.currentPlan.price === 0) {
          promoResponse = await promoUtil.redeemPromo(state.currentPlan.promo);
          await AuthUser.resetSession();
          state.currentPage = 5;
        } else {
          state.currentPage = 4;
        }
      }
    }
    catch(err) {
      state.currentPage = 3;
      state.regError = err.message;
    }
    this.setState(state);
  }
  
  liceCntChange(info) {
    let state = this.state;
    let planPrice = null;
    
    if(state.currentPlan.plan === 'PREMIUM') {
      planPrice = products.premium_access.price;
    } else {
      planPrice = products.snf_access.price;
    }
    if(info === true || info >= 62 && state.currentPlan.plan === 'PREMIUM') {
      state.currentPlan.liceTotal = 'Unlimited';
      state.currentPlan.price = planPrice.unlimited;
    } 
    else if(info === true || info >= 44 && state.currentPlan.plan === 'SNF') {
      state.currentPlan.liceTotal = 'Unlimited';
      state.currentPlan.price = planPrice.unlimited;
    } else {
      state.currentPlan.liceTotal = info;
      state.currentPlan.liceAdded = state.currentPlan.liceTotal - 1;
      if(info > 1 && info <= 5) {
        state.currentPlan.pricePer = planPrice.twoToFive;
        state.currentPlan.price = (planPrice.twoToFive * state.currentPlan.liceAdded) + planPrice.single;
      } else if(info >= 6 && info <= 10) {
        state.currentPlan.pricePer = planPrice.sixToTen;
        state.currentPlan.price = (planPrice.sixToTen * state.currentPlan.liceAdded) + planPrice.single;
      } else if(info >= 11) {
        state.currentPlan.pricePer = planPrice.tenPlus;
        state.currentPlan.price = (planPrice.tenPlus * state.currentPlan.liceAdded) + planPrice.single;
      } else {
        state.currentPlan.price = planPrice.single;
      }
    }
    this.setState(state);
    // console.log(state.currentPlan);
  }

  onPayComplete(info) {
    let state = this.state;
    console.log('fired');
    state.currentPage = 5;
    state.payInfo = info;
    this.setState(state);
  }

  closeModal(e) {
    let state = this.state;
    state.regError = null;
    this.setState(state);
  }
  
  render() {
    const PlanPage = 
      <Plan 
        currentPlan={this.state.currentPlan}
        onPlan={this.onPlanComplete} 
      />;
    const RegisterPage = 
      <Register 
        currentPlan={this.state.currentPlan} 
        regError={this.state.regError} 
        onRegister={this.onRegisterComplete} 
      />;
    const VerifyPage = 
      <Verify 
        onVerify={this.onVerifyComplete} 
        regInfo={this.state.regInfo} 
        regError={this.state.regError} 
      />;
    const PayPage = 
      <Pay 
        currentPage={this.state.currentPage} 
        currentPlan={this.state.currentPlan}
        regInfo={this.state.regInfo} 
        liceChange={this.liceCntChange} 
        onPay={this.onPayComplete}  
      />;
    const ConfirmPage = 
      <Confirm 
        currentPage={this.state.currentPage} 
        currentPlan={this.state.currentPlan}
        regInfo={this.state.regInfo}  
      />;
    let activePage = null;

    switch(this.state.currentPage) {
      case 1:
        activePage = PlanPage;
        break;
      case 2:
        activePage = RegisterPage;
        break;
      case 3:
        activePage = VerifyPage;
        break;
      case 4:
        activePage = PayPage;
        break;
      case 5:
        activePage = ConfirmPage;
        break;
      default:
        activePage = PlanPage;
    }
    return (
      <>
        {activePage}
        {this.state.regError && 
          <div id="regErrorModal" onClick={this.closeModal}>
            <div id="modalBody">
              <h3>System Error</h3>
              <p>We appologize for the inconvience but there was a problem processing your information and we are unable to complete your request at this time.</p>
              <p className="regError">{this.state.regError}</p>
              <p>Please try again in a few minutes. If the problem persists, please <a href="/contact">contact us</a> so we can address the problem.</p>
              <button
                type="button" 
                id="closeErrModal"
                onClick={this.closeModal}
              >Close</button>
            </div>
          </div>
        }
      </>
    );
  }
}

export default(Subscribe);