export const FacilityLevelFOSMetrics = [
	'period_end',
	'annual_operating_rev',
	'annual_operating_profit',
	'net_income',
	'total_long_term_liabilities',
	'ftes_with_sub_providers',
	'total_discharges',
	'average_daily_census',
	'inpatient_days',
	'adj_aob'
];

export const FinancialMetrics = [
	'operating_margin',
	'operating_rev_per_aob',
	'operating_cost_per_aob',
	'days_in_ar',
	'days_in_ap'
];

export const OperationalMetrics = [
	'occupancy_rate',
	'ftes_per_aob',
	'labor_cost_as_a_percent_of_revenue'
];

export const UtilizationMetrics = [
	'prop_adultpeds_days_as_medicare',
	'prop_adultpeds_days_as_medicaid',
	'prop_adultpeds_days_as_other',
	'alos_acute'
];

export const QualityPFPMetrics = [
	'total_performance_score',
	'star_rating',
	'serious_complication_rate',
	'rate_of_readmission',
	'readmission_reduction_adjustment',
	'hvbp_payment_adjustment',
	'total_hac_score'
];

export const FinancialMetricsCompare = [
	'operating_margin',
	'operating_rev_per_aob',
	'operating_cost_per_aob',
	'days_in_ar',
	'days_in_ap'
];

export const OperationalMetricsCompare = [
	'occupancy_rate',
	'ftes_per_aob',
	'labor_cost_as_a_percent_of_revenue'
];

export const QualityPFPMetricsCompare = [
	'total_performance_score',
	'star_rating',
	'serious_complication_rate',
	'rate_of_readmission',
	'readmission_reduction_adjustment',
	'hvbp_payment_adjustment',
	'total_hac_score'
];

export const FacilityLevelFOSTotalShare = [
	'annual_operating_rev',
	'annual_operating_profit',
	'net_income',
	'total_long_term_liabilities',
	'ftes_with_sub_providers',
	'total_discharges',
	'average_daily_census',
	'inpatient_days',
	'adj_aob'
];

export const SingleYearSummaryMetrics = () => {
	let list = [];
	list = list.concat(FacilityLevelFOSMetrics);
	list = list.concat(FinancialMetrics);
	list = list.concat(OperationalMetrics);
	list = list.concat(UtilizationMetrics);
	list = list.concat(QualityPFPMetrics);
	return list;
};
export const RelativePercentDifferenceMetrics = () => {
	let list = [];
	list = list.concat(FinancialMetricsCompare);
	list = list.concat(OperationalMetricsCompare);
	list = list.concat(QualityPFPMetricsCompare);
	return list;
};

export const FacilityLevelFOSTotalShareMetrics = () => {
	let list = [];
	list = list.concat(FacilityLevelFOSTotalShare);
	return list;
};

export const All = () => {
	let list = [];
	list = list.concat(SingleYearSummaryMetrics());
	list = list.concat(FacilityLevelFOSTotalShareMetrics());
	return list;
};
