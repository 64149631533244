import React, {Fragment} from 'react';
import LandingPage from './Landing-Pages/chart-landing-page';
import PageNavigation from '../../components/navigation/pageNavigation';

export default class HospStrategicMarketInfo extends React.Component {
	constructor(props) {
		super(props);
		this.domain = this.props.domain ? this.props.domain : 'info';
	}
	render() {
		return (
			<Fragment>
				<PageNavigation selectedTab="7" domain={this.domain} />
				<LandingPage />
			</Fragment>
		);
	}
}
