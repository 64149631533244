import React, { Component } from "react";
import react from 'react';
import { Auth } from "aws-amplify";
import zxcvbn from "zxcvbn";
import '../../styles.css';

export default class Verify extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stage: 1,
      checked: null,
      value: true,
      type: 'password',
      username: '',
      tempPass: '',
      password: '',
      conPass: '',
      error: '',
      errorPass: '',
      errorCon: ''
    };
    this.handleRemChange = this.handleRemChange.bind(this);
    this.subNewPass = this.subNewPass.bind(this);
  }

  componentDidMount() {
		document.body.id = 'verify';
	}
  componentWillUnmount() {
		document.body.id = '';
	}

  handleRemChange = () => {
    let state = this.state;
    state.checked = !this.state.checked;
    this.setState(state);
    if(this.state.checked === true && this.state.username) {
      localStorage.setItem('username', this.state.username);
    } else {
      localStorage.clear('username');
    }
  };

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let state = this.state;
    switch (name) {
      case 'username':
        state.username = e.target.value;
        break;
      case 'tempPass':
        state.tempPass = e.target.value;
        break;
      case 'newPass':
        state.errorPass =
          value.length < 8
            ? 'Password must be at least 8 characters'
            : '';
          state.password = e.target.value;
        if(state.conPass && state.conPass !== state.password) {
          state.errorCon = 'Passwords must match';
        } else {
          state.errorCon = '';
        }
        break;
      case 'conPass':
        if(value === state.password) {
          state.errorCon = '';
        } else {
          state.errorCon = 'Passwords must match';
          state.value = true;
        }
        state.conPass = e.target.value;
        break;
      default:
        break;
    }
    if(state.tempPass && state.password && !state.errorPass && !state.errorCon) {
      state.value = false;
    }
    this.setState(state);
  };

  passScoreLabel = result => {
    switch (result.score) {
      case 0:
        return 'N/A';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  }

  showHide = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password'
    })
  };

  subNewPass = e => {
    e.preventDefault();
    let state = this.state;
    let username = this.props.username;
    let temp_password = state.tempPass;
    let new_password = state.password;
    
    // this.state.stage = 2;
    this.setState(state);
  };

  render() {
    const testedResult = zxcvbn(this.state.password);

    return (
      <div id="content">
        <div id="loginFrame">
          <div id="placeholder"></div>
          {this.state.stage === 1 && (
            <form className="authentication__form" onSubmit={this.subNewPass}>
              <div className="inputFrame">
                <h1>Verify Account</h1>
                <p>You must change your password to verify your account. Enter your <span>username</span> (email address), <span>temporary password</span> and <span>new password</span> below to verify your account.</p>
                <div>
                  <label htmlFor="usr"><i className="fas fa-user"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="usr" 
                    name="username" 
                    placeholder="Username"
                    value={this.state.username}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="pwd"><i className="fas fa-key"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="tempPass" 
                    name="tempPass" 
                    placeholder="Temp Password"
                    value={this.state.tempPass}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div>
                  <div>
                    <label htmlFor="pwd"><i className="fas fa-unlock-alt"></i></label>
                    <input 
                      type={this.state.type} 
                      className="loginInput" 
                      id="newPass" 
                      name="newPass" 
                      placeholder="New Password"
                      autoComplete="off"
                      value={this.state.password}
                      onChange={this.handleChange}
                      required
                    />
                    <span 
                      className="showHide" 
                      onClick={this.showHide}
                    >
                      {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
                    </span>
                    {this.state.errorPass.length > 0 && <p className="error passError">{this.state.errorPass}</p>}
                  </div>
                  <div>
                    <label htmlFor="conPass"><i className="fas fa-lock"></i></label>
                    <input 
                      type={this.state.type} 
                      className="loginInput" 
                      id="conPass" 
                      name="conPass" 
                      placeholder="Confirm Password"
                      value={this.state.conPass}
                      onChange={this.handleChange}
                      required
                    />
                    <span 
                      className="showHide" 
                      onClick={this.showHide}
                    >
                      {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
                    </span>
                    <label className="passStrength" id="newPass">
                      {this.state.password && (
                        <>
                          <progress
                            className={`passScore score-${this.passScoreLabel(testedResult)}`}
                            value={testedResult.score}
                            max="4"
                          />
                          <span><strong>Password strength:</strong> {this.passScoreLabel(testedResult)}</span>
                        </>
                      )}
                    </label>
                    {this.state.errorCon.length > 0 && <p className="error passError">{this.state.errorCon}</p>}
                  </div>
                </div>
                <div id="remember">
                  <label htmlFor="rem">Remember Me</label> 
                  <input 
                    type="checkbox" 
                    id="rem" 
                    name="rem"
                    checked={this.state.checked}
                    onChange={this.handleRemChange} 
                  />
                </div>
                {this.state.error && <p className="error text-center">{this.state.error}</p>}
                <input 
                  type="submit" 
                  className="loginBttn" 
                  name="subCode" 
                  value="Change Password" 
                  disabled={this.state.value}
                />
              </div>
            </form>
          )}
          {this.state.stage === 2 && (
            <div className="inputFrame">
              <h1>Acount Verified</h1>
              <p><span>{this.state.username}</span> has been verified</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}