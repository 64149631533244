import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
class HospitalSystemsPage extends React.Component {
	render() {
		return (
			<div id="hospital">
				<Helmet>
					<title>Hospital & Health Systems | HMP Metrics</title>
					<meta
						name="description"
						content="HMP Metrics tools and reports generated from Healthcare Cost Report Information System (HCRIS) data, with more than 600,000 Medicare cost reports and incorporated Census and LDS financial data."
					/>
				</Helmet>
				<section id="content" className="divBar">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h6>Best Quality Data</h6>
							</div>
						</div>
					</div>
				</section>
				<section>
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h1 className="text-center">Hospital &amp; Health Systems</h1>
								<p>
									Explore our intuitive database to easily navigate and visualize how a facility
									performs against its peers, with instantaneous graphics and indices.
								</p>
							</div>
						</div>
					</div>
				</section>
				{/* Some Repeat Information - Skilled Nursing Facility */}
				<section id="bgCollage">
					<div className="container" id="tile1">
						<div className="bgCollageTile topTile"></div>
						<div className="bgCollageTile bottTile"></div>
						<div className="row">
							<div className="col-md-6 col-12">
								<h3>Detailed Financial Insight</h3>
								<p>
									The Financial Performance tool is a simple view into a provider's key financials
									in easy to read charts designed to illustrate financial viability. Financial
									indicators are sourced from CMS' Healthcare Cost Report Information System
									(HCRIS). Additional information regarding data sources can be found in the{' '}
									<a href="/faq">FAQ section</a>.
								</p>
							</div>
							<div className="col-md-6 col-12">
								<ul className="anime slideL">
									<li>Days Cash on Hand</li>
									<li>Labor Cost as a Percentage of Total Net Patient Revenue</li>
									<li>Inpatient vs Outpatient Charges</li>
									<li>Long Term Debt to Total Assets</li>
									<li>Inpatient and Outpatient Utilization</li>
									<li>Market share by zip code</li>
								</ul>
								<div className="bgColCover topTile"></div>
							</div>
						</div>
					</div>
					<div className="container" id="tile2">
						<div className="bgCollageTile topTile"></div>
						<div className="bgCollageTile bottTile"></div>
						<div className="row">
							<div className="col-md-6 col-12">
								<ul className="anime slideR">
									<li>30 Day Readmission rates for Heart Attack</li>
									<li>Overall Hospital Star Rating</li>
									<li>Healthcare Associated Infection Rates</li>
									<li>Timely and Effective Care Measures</li>
									<li>Key financial data points</li>
									<li>Operating statistics &amp; Quality measures</li>
								</ul>
								<div className="bgColCover topTile"></div>
							</div>
							<div className="col-md-6 col-12">
								<h3>100+ Quality Metrics</h3>
								<p>
									The Quality Metrics tool details a provider's performance across all major quality
									programs. Compare a chosen provider against state and national peer groups over a
									five-year period. Retrieve a summary or detailed information about a provider's
									scores in the Value-Based Purchasing (VBP), the Readmission Reduction (HRRP) and
									the Hospital-Acquired Conditions (HAC) Reduction Programs.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="divBar">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h6>
									Become a Subscriber to Gain Access to All Metrics:{' '}
									<a href="/subscribe">Subscribe Today</a>
								</h6>
							</div>
						</div>
					</div>
				</section>
				<section id="reports">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-12">
								<img
									className="fluidImg anime slideR"
									src="images/hmp-metrics-report-01.jpg"
									alt="HMP Metrics Provider Comparison Screenshot"
								/>
							</div>
							<div className="col-md-6 col-12">
								<h2>Compare Any Provider</h2>
								<p>
									The Benchmark Report highlights more than 100 critical utilization, labor and
									financial measures and allows for comparison against up to four national and state
									peers. Each metric is defined so users across the system can access data cleansed
									of outliers.
								</p>
								<ul>
									<li>Average Inpatient Length of Stay</li>
									<li>Medicare Case Mix Index</li>
									<li>Acute FTEs per AOB</li>
									<li>Total Net Patient Revenue per FTE</li>
									<li>Average Cost per FTE</li>
									<li>Medicare Disproportionate Share Hospital Payments</li>
								</ul>
							</div>
							<div className="col-md-6 col-12">
								<h2>Create Your Own Datasets!</h2>
								<p>
									Our easy to use Data Explorer tool will allow you to create your own analyses.
									Easily aggregate and create custom views of millions of records from over 160
									sources, including Medicare HCRIS, LDS and Census.
								</p>
								<ul>
									<li>Mix and Match Metrics You Want</li>
									<li>Set Custom Filters and Date Ranges</li>
									<li>Easily See Metrics and Facilities of Interest on one Screen</li>
									<li>Choose From All Available Metrics on the Site</li>
									<li>Export to Excel</li>
								</ul>
							</div>
							<div className="col-md-6 col-12">
								<img
									className="fluidImg anime slideL"
									src="images/hmp-metrics-report-02.jpg"
									alt="HMP Metrics Custom Dataset Screenshot"
								/>
							</div>
							<div className="col-md-6 col-12">
								<img
									className="fluidImg anime slideR"
									src="images/hmp-metrics-report-03.jpg"
									alt="HMP Metrics Cost Report Screenshot"
								/>
							</div>
							<div className="col-md-6 col-12">
								<h2>Download Any Cost Report in Excel</h2>
								<p>
									Download the latest submitted Cost Reports in Excel format instantly. Useful if
									you want to see the actual submission to CMS for Research, Legal or Quality
									Assurance programs; or as an aid in the submission of a Cost Report.
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default class AboutUs extends React.Component {
	render() {
		return (
			<Fragment>
				<HospitalSystemsPage />
			</Fragment>
		);
	}
}
