import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// import Auth from './core/Security/Auth';
// Action Links----------------------------------------------------------------------
// import * as fieldDefActions from './core/actions/GlobalActions';
import * as LoginActions from './core/LoginPage/LoginActions';
// Page Links -----------------------------------------------------------------------
import HomePage from './core/HomePage';
import AboutUs from './core/DefaultPages/AboutUs';
import FaqPage from './core/DefaultPages/faqPage';
import Terms from './core/DefaultPages/terms-of-use';
import HHS from './core/DefaultPages/hospital-systems';
import SNF from './core/DefaultPages/skilled-nursing';
import FacilityAnalyzer from './core/HomePage/facility-analyzer';
import Contact from './core/DefaultPages/contact';
import VerifyPage from './core/LoginPage/VerifyPage';
import LoginPage from './core/LoginPage';
import Welcome from './core/LoginPage';
import LicenseManager from './core/LoginPage/LicenseManager';
import Subscribe from './core/Subscribe';
import SummaryPage from './hosp/Summary';
import FinancialPage from './hosp/Financial';
import QualityPage from './hosp/Quality';
import BenchmarkPage from './hosp/Benchmark';
import HospitalMapPage from './hosp/Map';
import StrategicMarketDataPage from './hosp/StrategicMarketData';
import PhysiciansPage from './hosp/Physicians';
import ChartToolsPage from './hosp/ChartPage';
import SnfFinancialPage from './snf/Financial';
import SnfQualityPage from './snf/Quality';
import SnfBenchmarkPage from './snf/Benchmark';
import SnfSummaryPage from './snf/Summary/index';
import SnfStrategicMarketDataPage from './snf/StrategicMarketData';
import SnfChartToolsPage from './snf/ChartPage';
import SnfPhysiciansPage from './snf/Physicians';
import HospSummaryInfo from './core/DefaultPages/marketingInfo/HospSummaryInfo';
import HospBenchmarkInfo from './core/DefaultPages/marketingInfo/HospBenchmarkInfo';
import HospChartToolInfo from './core/DefaultPages/marketingInfo/HospChartToolInfo';
import HospFinancialInfo from './core/DefaultPages/marketingInfo/HospFinancialInfo';
import HospQualityInfo from './core/DefaultPages/marketingInfo/HospQualityInfo';
import HospStrategicInfo from './core/DefaultPages/marketingInfo/HospStrategicInfo';
import DataExplorer from './core/DefaultPages/dataExplorer';
import MapExplorer from './core/DefaultPages/mapExplorer';
import MenuBar from './core/components/navigation/menuBar';
import FooterSection from './core/components/navigation/FooterSection';
import DefaultPage from './core/DefaultPages/DefaultPage';
import CoreLoginPage from './core/DefaultPages/CoreLoginPage';
import fieldDefModel from './core/components/fieldDefModel';
import ArchiveDatasetPage from './core/DefaultPages/mhDatasets';
import WhitePapers from './core/DefaultPages/WhitePapers';
import Reports from './core/DefaultPages/reports';
import RequestReport from './core/LoginPage/RequestReport';
import MyReports from './core/LoginPage/MyReports';
//Manage current selected facility & associated context variables for routing
import * as misc from './lib/misc';
import {Amplify, Auth} from 'aws-amplify';
import AuthUser from './core/Security/Auth';
import awsconfig from './aws-exports';
import '@aws-amplify/ui/dist/style.css';
import Blogs from './core/Blogs';
import BuildCompBench from './core/Blogs/build-comp-bench';
import BalanceFixedConsts from './core/Blogs/balance-fixed-costs';

Amplify.configure(awsconfig);

const logout = props => {
	if (Auth.isUserAuthenticated()) {
		Auth.deauthenticateUser();
		window.location.href = props + Auth.SignOutUrl;
	} else {
		window.location.href = '/';
	}
};

const mapDispatchToProps = dispatch => {
	return {
		// PreloadPageDefinitions: (domain,fields) => dispatch(fieldDefActions.LoadDomainFields(domain,fields)),
		InitAuthUser: Creds => dispatch(LoginActions.loginUser(Creds))
	};
};

class App extends Component {
	constructor(props) {
		super(props);
		this.initSession();
		this.clickshareEnv = Auth.AuthTarget;
	}

	// cookieGetWordPressToken() {
	// 	const cookies = new Cookies();
	// 	let AllCookies = cookies.getAll(false);
	// 	let keys = Object.keys(AllCookies);
	// 	let keyId = keys.find(x => x && x.toUpperCase().indexOf('WORDPRESS_LOGGED_IN') >= 0);

	// 	if (keyId) {
	// 		let cookie = AllCookies[keyId];
	// 		return cookie;
	// 	}

	// 	return null;
	// }

	async initSession() {

		let sessionToken = AuthUser.getToken();

		if(sessionToken)
		{
			// User has a token from server
		}
		else
		{
			// Not logged in yet. Check for AWS Token then call server for Token
			// Response will come back in LoginReducer.
			let awsToken = await AuthUser.getUserToken();
			this.props.InitAuthUser(awsToken);
			this.setState({});
		}


	}

	render() {
		return (
			<Router>
				<PageLayout>
					<Switch>
						{/* Not In Use
						<Route path="/whysubscribe" component={WhySubscribe} />
						<Route path="/request-a-quote" component={RequestQuote} />
						<Route path="/privacy" component={Privacy} />
						<Route path="/init" component={InitPage} />
						*/}
						<DefaultPageRoute exact path="/" component={HomePage} default={CoreLoginPage} />
						<Route path="/aboutus" component={AboutUs} />
						<DefaultPageRoute path="/faq" component={FaqPage} default={CoreLoginPage} />
						<Route path="/terms-of-use" component={Terms} />
						<Route path="/hospital-systems" component={HHS} />
						<Route path="/skilled-nursing" component={SNF} />
						<Route path="/facility-analyzer" component={FacilityAnalyzer} />
						<Route path="/subscribe" component={Subscribe} />
						<Route path="/contact" component={Contact} />
						<Route path="/verify" component={VerifyPage} />
						<Route path="/login" component={LoginPage} />
						<Route path="/logout" render={props => logout(this.clickshareEnv)} />
						<Route path="/welcome" component={Welcome} />
						<Route path="/license" component={LicenseManager} />
						<Route path="/reports" component={Reports} />
						<Route path="/request-report" component={RequestReport} />
						<Route path="/my-reports" component={MyReports} />
						<HospitalRestrictedRoute
							path="/hospital/summary/:providerId"
							component={SummaryPage}
							default={DefaultPage}
						/>
						<HospitalRestrictedRoute
							path="/hospital/financial/:providerId"
							component={FinancialPage}
							default={DefaultPage}
						/>
						<HospitalRestrictedRoute
							path="/hospital/quality/:providerId"
							component={QualityPage}
							default={DefaultPage}
						/>
						<HospitalRestrictedRoute
							path="/hospital/benchmark/:providerId"
							component={BenchmarkPage}
							default={DefaultPage}
						/>
						<HospitalRestrictedRoute
							path="/hospital/map/:providerId" 
							component={HospitalMapPage} 
							default={DefaultPage} 
						/>
						<HospitalRestrictedRoute
							path="/hospital/strategicmarket/:providerId"
							component={StrategicMarketDataPage}
							default={DefaultPage}
						/>
						<HospitalRestrictedRoute
							path="/hospital/physicians/:providerId" 
							component={PhysiciansPage} 
							default={DefaultPage} 
						/>
						<HospitalRestrictedRoute
							path="/hospital/chartlibrary/:providerId"
							component={ChartToolsPage}
							default={DefaultPage}
						/>
						<SkilledNursingRestrictedRoute
							path="/skillednursing/summary/:providerId"
							component={SnfSummaryPage}
							default={DefaultPage}
						/>
						<SkilledNursingRestrictedRoute
							path="/skillednursing/financial/:providerId"
							component={SnfFinancialPage}
							default={DefaultPage}
						/>
						<SkilledNursingRestrictedRoute
							path="/skillednursing/quality/:providerId"
							component={SnfQualityPage}
							default={DefaultPage}
						/>
						<SkilledNursingRestrictedRoute
							path="/skillednursing/benchmark/:providerId"
							component={SnfBenchmarkPage}
							default={DefaultPage}
						/>
						<SkilledNursingRestrictedRoute
							path="/skillednursing/map/:providerId"
							component={HospitalMapPage}
							default={DefaultPage}
						/>
						<SkilledNursingRestrictedRoute
							path="/skillednursing/strategicmarket/:providerId"
							component={SnfStrategicMarketDataPage}
							default={DefaultPage}
						/>
						<SkilledNursingRestrictedRoute
							path="/skillednursing/chartlibrary/:providerId"
							component={SnfChartToolsPage}
							default={DefaultPage}
						/>
						<SkilledNursingRestrictedRoute 
							path="/skillednursing/physicians/:providerId" 
							component={SnfPhysiciansPage} 
							default={DefaultPage} 
						/>
						<HomeHealthRestrictedRoute
							path="/homehealth/summary/:providerId"
							component={SummaryPage}
							default={DefaultPage}
						/>
						<HomeHealthRestrictedRoute
							path="/homehealth/financial/:providerId"
							component={FinancialPage}
							default={DefaultPage}
						/>
						<HomeHealthRestrictedRoute
							path="/homehealth/quality/:providerId"
							component={QualityPage}
							default={DefaultPage}
						/>
						<HomeHealthRestrictedRoute
							path="/homehealth/benchmark/:providerId"
							component={BenchmarkPage}
							default={DefaultPage}
						/>
						<HomeHealthRestrictedRoute
							path="/homehealth/strategicmarket/:providerId"
							component={StrategicMarketDataPage}
							default={DefaultPage}
						/>
						<HomeHealthRestrictedRoute
							path="/homehealth/chartlibrary/:providerId"
							component={ChartToolsPage}
							default={DefaultPage}
						/>
						<RestrictedRoute path="/fielddefinitions" component={fieldDefModel} />
						<RestrictedRoute path="/whitepapers" component={WhitePapers} />
						<RestrictedRoute path="/white-papers/:file" />
						<RestrictedRoute
							path="/additional-resources/:datasetId"
							component={ArchiveDatasetPage}
						/>
						<RestrictedRoute path="/map-explorer" component={MapExplorer} default={CoreLoginPage} />
						<RestrictedRoute
							path="/data-explorer"
							component={DataExplorer}
							default={CoreLoginPage}
						/>
						<DefaultPageRoute
							path="/info/summary"
							component={HospSummaryInfo}
							default={CoreLoginPage}
						/>
						<DefaultPageRoute
							path="/info/financial"
							component={HospFinancialInfo}
							default={CoreLoginPage}
						/>
						<DefaultPageRoute
							path="/info/benchmark"
							component={HospBenchmarkInfo}
							default={CoreLoginPage}
						/>
						<DefaultPageRoute
							path="/info/quality"
							component={HospQualityInfo}
							default={CoreLoginPage}
						/>
						<DefaultPageRoute
							path="/info/chartlibrary"
							component={HospChartToolInfo}
							default={CoreLoginPage}
						/>
						<DefaultPageRoute
							path="/info/strategicmarket"
							component={HospStrategicInfo}
							default={CoreLoginPage}
						/>
						<Route path="/blogs" component={Blogs} />
						<Route path="/build-comp-bench" component={BuildCompBench} />
						<Route path="/balance-fixed-costs" component={BalanceFixedConsts} />
						{/* Effectively the 404 Handler */}
						<Route component={HomePage} />
					</Switch>
				</PageLayout>
			</Router>
		);
	}
}

const PageLayout = props => {
	// let hospitalWrapper = document.URL.match('hospital/*') ? (
	// 	<DefaultPage {...props}>{props.children}</DefaultPage>
	// ) : (
	// 	props.children
	// );
	return (
		<>
			<Helmet>
				<title>HMP Metrics</title>
				<meta
					name="description"
					content="Actionable financial, quality, and strategic insights on 5,000+ hospitals with 400+ metrics, benchmarks, and comparative analytics."
				/>
			</Helmet>
			<MenuBar />
			{props.children}
			{<FooterSection />}
		</>
	);
};

const RestrictedRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			AuthUser.isUserAuthenticated() ? <Component {...props} /> : (window.location = AuthUser.AuthURL)
		}
	/>
);

const SkilledNursingRestrictedRoute = ({ component: Component, default: Default, ...rest }) => {
	// dispatch(this.PreloadPageDefinitions('snf',fields));
	return (
		<Route
			{...rest}
			render={props => (
				<Default domain="snf" {...props}>
					<Component {...props} />
				</Default>
			)}
		/>
	);
};

const HomeHealthRestrictedRoute = ({ component: Component, default: Default, ...rest }) => {
	// dispatch(this.PreloadPageDefinitions('hha',fields));
	return (
		<Route
			{...rest}
			render={props => (
				<Default domain="hha" {...props}>
					<Component {...props} />
				</Default>
			)}
		/>
	);
};

const HospitalRestrictedRoute = ({
	component: Component,
	default: Default,
	fields: prefield,
	...rest
}) => {
	// dispatch(this.PreloadPageDefinitions('hosp',fields));
	return (
		<Route
			{...rest}
			render={props => (
				<Default domain="hosp" fields={prefield} {...props}>
					<Component {...props} />
				</Default>
			)}
		/>
	);
};

const DefaultPageRoute = ({ component: Component, default: Default, ...rest }) => (
	<Route
		{...rest}
		render={props => (
			<Default {...props}>
				<Component {...props} />
			</Default>
		)}
	/>
);

export default connect(
	null,
	mapDispatchToProps
)(App);
