import * as misc from './misc';
import _ from 'lodash';
export function storeDefaultWhere(domain, section = 'default') {
	sessionStorage.setItem('where', setDefaultBenchmark(domain, section));
	sessionStorage.setItem('where-description', buildDescription(domain));
}
const latest_year = (facInfo, section) => {
	let latest_fy_year = new Date().getFullYear() - 1;

	if (facInfo && facInfo.length) {
		switch (section) {
			case 'quality':
				latest_fy_year = facInfo[0].data_quality_last_year || latest_fy_year;
				break;
			case 'hsaf':
				latest_fy_year = facInfo[0].data_hsaf_last_year || latest_fy_year;
				break;
			case 'ip':
				latest_fy_year = facInfo[0].data_ip_last_year || latest_fy_year;
				break;
			case 'op':
				latest_fy_year = facInfo[0].data_op_last_year || latest_fy_year;
				break;
			default:
				latest_fy_year = Math.max.apply(null, facInfo.map(a => a.fy_year)) || latest_fy_year;
				break;
		}
	}

	return latest_fy_year;
};
function buildDefaultHospBenchmark(section = 'default') {
	const facInfo = JSON.parse(sessionStorage.getItem('facilityList'));
	let cahStatus = facInfo ? facInfo.map(a => a.cah) : ['N'];
	let hospitalType = facInfo ? facInfo.map(a => a.hospital_type) : [1];
	let highSubAcute = facInfo ? Math.min.apply(null, facInfo.map(a => a.per_acute_beds)) : [0];

	let latest_fy_year = latest_year(facInfo, section);

	const defaultFilters = [];

	if (hospitalType.indexOf(1) > -1) {
		defaultFilters.push({
			arguments: hospitalType,
			operator: 5,
			FieldName: 'hospital_type'
		});
		defaultFilters.push({
			arguments: cahStatus,
			operator: 5,
			FieldName: 'cah'
		});
	} else if (hospitalType.indexOf(2) > -1 || hospitalType.indexOf(4) > -1) {
		defaultFilters.push({
			arguments: hospitalType,
			operator: 5,
			FieldName: 'hospital_type'
		});
	}
	if (
		hospitalType.indexOf(2) > -1 ||
		hospitalType.indexOf(4) > -1 ||
		hospitalType.indexOf(1) > -1
	) {
		if (highSubAcute > 0.25) {
			defaultFilters.push({
				arguments: 0.25,
				operator: 1,
				FieldName: 'per_acute_beds'
			});
		} else {
			defaultFilters.push({
				arguments: 0.25,
				operator: 2,
				FieldName: 'per_acute_beds'
			});
		}
	} else {
		if (
			hospitalType.indexOf(7) > -1 ||
			hospitalType.indexOf(3) > -1 ||
			hospitalType.indexOf(5) > -1
		) {
			defaultFilters.push({
				arguments: hospitalType,
				operator: 5,
				FieldName: 'hospital_type'
			});
		}
	}

	defaultFilters.push({
		arguments: [
			latest_fy_year - 0,
			latest_fy_year - 1,
			latest_fy_year - 2,
			latest_fy_year - 3,
			latest_fy_year - 4
		],
		Operator: 5,
		FieldName: 'fy_year'
	});

	return defaultFilters;
}

function buildDefaultSnfBenchmark(section = 'default') {
	const facInfo = JSON.parse(sessionStorage.getItem('facilityList'));
	let latest_fy_year = latest_year(facInfo, section);

	const defaultFilters = [];

	defaultFilters.push({
		arguments: [
			latest_fy_year - 0,
			latest_fy_year - 1,
			latest_fy_year - 2,
			latest_fy_year - 3,
			latest_fy_year - 4
		],
		Operator: 5,
		FieldName: 'fy_year'
	});
	//   if (facInfo && Array.isArray(facInfo) && facInfo[0].bed_cohort) {
	//     defaultFilters.push({
	//       arguments: [facInfo[0].bed_cohort],
	//       Operator: 5,
	//       FieldName: "bed_cohort",
	//     });
	//   }
	//   if (facInfo && Array.isArray(facInfo) && facInfo[0].urban_status) {
	//     defaultFilters.push({
	//       arguments: [facInfo[0].urban_status],
	//       Operator: 5,
	//       FieldName: "urban_rural_indicator",
	//     });
	//   }
	if (facInfo && Array.isArray(facInfo)) {
		defaultFilters.push({
			arguments: [facInfo[0].quality_mix],
			Operator: 5,
			FieldName: 'quality_mix'
		});
	}
	defaultFilters.push({
		arguments: [true],
		Operator: 5,
		FieldName: 'last_fy_rpt'
	});

	return defaultFilters;
}
const padZero = value => {
	let str = '000' + value;
	return str.substr(str.length - 3);
};

const buildDefaultLDSBenchmark = (section = 'ip') => {
	
	const facInfo = JSON.parse(sessionStorage.getItem('facilityList'));
	let latest_fy_year = latest_year(facInfo, section);
	const defaultFilters = [];

	defaultFilters.push({
		arguments: [
			latest_fy_year - 0,
			latest_fy_year - 1,
			latest_fy_year - 2,
			latest_fy_year - 3,
			latest_fy_year - 4
		],
		Operator: 5,
		FieldName: 'fy_year'
	});

	return defaultFilters;
};


const buildDefaultMarketBenchmark = (section = 'default') => {
	const facInfo = JSON.parse(sessionStorage.getItem('facilityList'));
	let latest_fy_year = facInfo[0].data_acs_last_year;
	const defaultFilters = [];

	defaultFilters.push({
		arguments: [
			latest_fy_year - 0,
			latest_fy_year - 1,
			latest_fy_year - 2,
			latest_fy_year - 3,
			latest_fy_year - 4
		],
		Operator: 5,
		FieldName: 'fy_year'
	});

	return defaultFilters;
};

export const setLDSDefaultFilter = (domain, section) => {
	switch (domain) {
		case 'snf':
			return JSON.stringify(buildDefaultLDSBenchmark(section));
		default:
			return JSON.stringify(buildDefaultLDSBenchmark(section));
	}
}

export const setDefaultBenchmark = (domain, section) => {
	switch (domain) {
		case 'hha':
			return JSON.stringify(buildDefaultSnfBenchmark(section));
		case 'snf':
			return JSON.stringify(buildDefaultSnfBenchmark(section));
		case 'market':
			return JSON.stringify(buildDefaultMarketBenchmark(section));
		default:
			return JSON.stringify(buildDefaultHospBenchmark(section));
	}
};
export function GetLatestReportedYrOnly() {
	return JSON.stringify([{ arguments: [true], Operator: 5, FieldName: 'most_recent_report' }]);
}
export function GetLatest5Yrs() {
	const facInfo = JSON.parse(sessionStorage.getItem('facilityList'));
	let latest_fy_year = facInfo
		? Math.max.apply(facInfo.map(a => a.fy_year))
		: new Date().getFullYear() - 1;
	return [
		latest_fy_year,
		latest_fy_year - 1,
		latest_fy_year - 2,
		latest_fy_year - 3,
		latest_fy_year - 4
	];
}

function buildDescription(domain) {
	const facInfo = JSON.parse(sessionStorage.getItem('facilityList'));
	// let latest_fy_year = facInfo ? facInfo.map(a => a.most_current_reporting_year) : 2016;
	let benchmarkDescription = 'Default Peer Group ';
	if (domain == 'hosp') {
		let cahStatus = facInfo ? facInfo.map(a => a.cah) : ['N'];
		let hospitalType = facInfo ? facInfo.map(a => a.hospital_type) : [1];
		let highSubAcute = facInfo ? Math.min.apply(null, facInfo.map(a => a.per_acute_beds)) : [0];

		if (hospitalType.indexOf(1) > -1) {
			benchmarkDescription = 'Hospital Type: Short-Term Acute';
			if (cahStatus.indexOf('Y') > -1) {
				benchmarkDescription = 'Hospital Type: Critical Access Hospital';
			}
		} else if (hospitalType.indexOf(2) > -1) {
			benchmarkDescription = 'Hospital Type: Long-Term';
		} else if (hospitalType.indexOf(4) > -1) {
			benchmarkDescription = 'Hospital Type: Psychiatric';
		} else if (hospitalType.indexOf(7) > -1) {
			benchmarkDescription += "Hospital Type: Children's";
		} else if (hospitalType.indexOf(3) > -1) {
			benchmarkDescription += 'Hospital Type: Cancer';
		}
		if (highSubAcute <= 0.25) {
			benchmarkDescription += ' (High Subacute)';
		}
		if (hospitalType.indexOf(5) > -1) {
			benchmarkDescription += 'Hospital Type: Acute Rehabilitation';
		}
	} else if (domain == 'snf' && facInfo) {
		let urbanRuralFlag =
			_(facInfo)
				.map(a => a.urban_status)
				.value()
				.toString() || 'National';
		let beds =
			_(facInfo)
				.map(a => a.bed_cohort)
				.value()
				.toString() || 'Any Bed Size';
		let qualityMix =
			Boolean(
				_(facInfo)
					.map(a => a.quality_mix)
					.value()[0]
			) == true
				? 'Medicare Mix Greater Than 15%'
				: 'Medicare Mix Less Than 15%' || 'Any Medicare Mix';

		benchmarkDescription =
			urbanRuralFlag + ' Skilled Nursing Facilities with ' + beds + ' and ' + qualityMix;
	}

	return benchmarkDescription;
}
