import React from 'react';
import Products from './products';
import StayInformed from './stayInformed';
import SetsUsApart from './setsUsApart';
import './index.css';
import { Helmet } from 'react-helmet';
export default class HomePage extends React.Component {
	render() {
		return (
			<div>
				<Helmet>
					<title>HMP Metrics | Healthcare Management Partners</title>
					<meta
						name="description"
						content="HMP Metrics provides easy-to-use financial, quality, strategic, and market data on 5000+ hospitals and 15000+ skilled nursing facilities to anyone in the industry who needs to make strategic, informed decisions."
					/>
				</Helmet>
				<div id="analyzer">{<Products />}</div>
			</div>
		);
	}
}
