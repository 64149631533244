class JsonUtil {
	static transpose(originalObj) {
		const i = 0;
		const keys = Object.keys(originalObj)[i];
		const newObj = Object.values(originalObj[i]).map(obj => ({ ...newObj[obj] }));

		for (let k = 0; k < keys.length; k++) {
			for (let j = 0; j < originalObj.length; j++) {
				newObj[keys[k]].push(originalObj[j][keys[k]]);
			}
		}
		return newObj;
	}

	static getPivotArray(header, dataArray, rowIndex, colIndex, dataIndex) {
		const result = {},
			ret = [];
		const newCols = [];
		for (let i = 0; i < dataArray.length; i++) {
			if (!result[dataArray[i][rowIndex]]) {
				result[dataArray[i][rowIndex]] = {};
			}
			result[dataArray[i][rowIndex]][dataArray[i][colIndex]] = dataArray[i][dataIndex];

			//To get column names
			if (newCols.indexOf(dataArray[i][colIndex]) === -1) {
				newCols.push(dataArray[i][colIndex]);
			}
		}

		newCols.sort();
		let item = [];

		//Add Header Row
		item.push(header);
		item.push.apply(item, newCols);
		ret.push(item);
		//Add content
		if (result) {
			const keys = Object.keys(result);
			for (let k = 0; k < keys.length; k++) {
				var newObj = [];
				newObj.push(keys[k]);
				for (let j = 0; j < newCols.length; j++) {
					newObj.push(result[keys[k]][newCols[j]] || 0);
				}
				ret.push(newObj);
			}
		}
		return ret;
	}

	static arrayToHTMLTable(myArray) {
		let result = "<table border='1' cellpadding='7' cellspacing='0'>";
		for (let i = 0; i < myArray.length; i++) {
			result += '<tr>';
			for (let j = 0; j < myArray[i].length; j++) {
				result += '<td>' + myArray[i][j] + '</td>';
			}
			result += '</tr>';
		}
		result += '</table>';
		return result;
	}
}

export default JsonUtil;
