import axios from 'axios';
import * as misc from '../../lib/misc';
import * as Api from '../../lib/ApiUtil';
import Auth from '../Security/Auth';

export function ActionDataRequest(
	actionTag,
	providers,
	segment,
	fields = [],
	where = null,
	custom = null
) {
	let filterwhere = where ? JSON.parse(where) : JSON.parse(sessionStorage.getItem('where'));
	let customBenchmark = custom
		? JSON.parse(custom)
		: JSON.parse(sessionStorage.getItem('customBenchmark'));
	if (Auth.isUserAuthenticated()) {
		return {
			type: actionTag,
			payload: axios.post(
				misc.apiUrl + '/benchmark-data',
				{
					fields: fields,
					providers,
					filter: filterwhere,
					outlierAdjust: false,
					domain: segment,
					custom: customBenchmark
				},
				Api.getCustomHeader()
			)
		};
	} else {
		return null;
	}
}
export function ActionRequest(actionTag, api, params) {
	if (Auth.isUserAuthenticated()) {
		return {
			type: actionTag,
			payload: axios.post(misc.apiUrl + api, params, Api.getCustomHeader())
		};
	} else {
		return null;
	}
}
export function ActionDataRequestOutlierAdjusted(
	actionTag,
	providers,
	segment,
	fields = [],
	override = false,
	where = null,
	custom = null
) {
	let filterwhere = where ? JSON.parse(where) : JSON.parse(sessionStorage.getItem('where'));
	let customBenchmark = custom
		? JSON.parse(custom)
		: JSON.parse(sessionStorage.getItem('customBenchmark'));

	if ((Auth.isUserAuthenticated() && (segment === 'hosp' || segment === 'snf')) || override) {
		return {
			type: actionTag,
			payload: axios.post(
				misc.apiUrl + '/benchmark-data',
				{
					fields: fields,
					providers,
					filter: filterwhere,
					outlierAdjust: true,
					domain: segment,
					custom: customBenchmark
				},
				Api.getCustomHeader()
			)
		};
	} else {
		return null;
	}
}

export function ResetAction(actionTag) {
	return {
		type: actionTag + '_RESET',
		payload: {}
	};
}

export function ActionExplorerRequest(actionTag, searchparameters, fields, segment, filters = []) {
	if (Auth.isUserAuthenticated()) {
		return {
			type: actionTag,
			payload: axios.post(
				misc.apiUrl + '/explorer-data',
				{
					fields: fields,
					domain: segment,
					query: JSON.stringify(searchparameters),
					filter: JSON.stringify(filters)
				},
				Api.getCustomHeader()
			)
		};
	} else {
		return null;
	}
}

export function ActionPivotDataRequest(
	actionTag,
	providers,
	segment,
	fields = [],
	pivotOn = 'fy_year',
	override = false,
	where = null
) {
	let filterwhere = where ? JSON.parse(where) : JSON.parse(sessionStorage.getItem('where'));
	if (override || Auth.isUserAuthenticated()) {
		return {
			type: actionTag,
			payload: axios.post(
				misc.apiUrl + '/get-data',
				{
					fields: fields,
					providers,
					filter: filterwhere,
					outlierAdjust: false,
					domain: segment,
					pivot: pivotOn
					//,custom: customBenchmark
				},
				Api.getCustomHeader()
			)
		};
	} else {
		return null;
	}
}
