import React from 'react';
import * as _ from 'lodash';
import SNFFilter from '../../components/searchBar/snf-filter-dialog';
import ServiceList from './service-tree';
// import './index.css';

class snfSearchFields extends React.Component {
	constructor(props) {
		super(props);
		this.filterServiceChanged = this.props.filterServiceChanged.bind(this);
		this.filterSettingsChanged = this.props.filterSettingsChanged.bind(this);
	}

	componentDidMount() {}

	onServiceSelected(values) {}

	render() {
		return (
			<>
				<SNFFilter 
					cols="3" 
					showTimeFilters={true} 
					onFilterChanged={this.filterSettingsChanged} 
					filters={this.props.filters}
					regFunction={this.props.regFunction}  
				/>
			</>
		);
	}
}

export default snfSearchFields;
