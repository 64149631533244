import React from 'react';
import DropDownCtrl from './search-drop-ctrl';
import SectionLabel from './section-label';
import * as api from '../../../lib/ApiUtil';
import * as misc from '../../../lib/misc';
import Tabs from '../../../tools/ui-templates/tabs';

import './index.css';

const ctrWidth = 350;

const getList = list => {
	return list ? list : [];
};

export default class FilterDialog extends React.Component {
	constructor(prods) {
		super(prods);

		this.state = {
			options: { hosp: [], snf: [] },
			filters: [],
			hosp: true,
			snf: true,
			hha: true,
			key: misc.Guid(),

			values: {
				_state: [],
				_cbsa_name: [],
				hosp_city_name: [],
				hosp_system: [],
				hosp_hospital_type: [],
				hosp_hosp_ownership_type: [],
				hosp_teach_status_class: [],
				hosp_cah: [],
				hosp_urban_rural_indicator: [],
				hosp_bed_cohort: [],
				hosp_net_patrev_cohort_id: [],
				hosp_net_income_cohort_id: [],

				snf_provider_subtype: [],
				snf_ownership_type: [],
				snf_bed_cohort_id: [],
				snf_urban_rural_indicator: [],
				snf_ownership_type: [],
				snf_bed_cohort_id: [],
				snf_net_patrev_cohort_id: [],
				snf_net_income_cohort_id: []
			}
		};

		this.onOptionChange = this.onOptionChange.bind(this);
		this.onFldChange = this.onFldChange.bind(this);
		this.onReset = this.onReset.bind(this);
		this.onApply = this.onApply.bind(this);
	}

	loadSearchOptions(filters) {
		api.APIPost('/search-options', { filters: filters }).then(
			data => {
				let state = this.state;
				state.options = data.data.result;
				state.key = misc.Guid();
				this.setState(state);
			},
			err => {}
		);
	}
	UNSAFE_componentWillMount() {
		this.loadSearchOptions(this.state.filters);
	}

	onFldChange(key, domain, e) {
		let state = this.state;
		let opt = null;
		let optIdx = -1;

		state.values[domain + '_' + key] = e;

		for (let i = 0; i < state.filters.length; i++) {
			if (state.filters[i].key == key) {
				opt = state.filters[i];
				optIdx = i;
				break;
			}
		}
		if (opt) {
			// Filter is already selected
			if (e && e.length > 0) {
				opt.values = e.map(e => {
					return e.value;
				});
			} else {
				state.filters.splice(optIdx, 1);
			}
		} else if (e && e.length > 0) {
			state.filters.push({
				key: e && e.length > 0 ? e[0].key : '',
				domain: domain,
				values: e.map(e => {
					return e.value;
				})
			});
		}
		state.key = misc.Guid();
		this.setState(state);

		if (!this.props.model) {
			if (this.props.onFilterChanged) this.props.onFilterChanged(this.state.filters);
		}
	}

	leftSideSNFEdits() {
		return (
			<div>
				<DropDownCtrl
					width={ctrWidth}
					id="provider_subtype"
					domain="snf"
					label="Provider Type"
					placeholder="Select ..."
					options={this.state.options.snf['provider_subtype']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.snf_provider_subtype}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="ownership_type"
					domain="snf"
					label="Ownership Type"
					placeholder="Select ..."
					options={this.state.options.snf['ownership_type']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.snf_ownership_type}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="bed_cohort_id"
					domain="snf"
					label="Bed Size"
					placeholder="Select ..."
					options={this.state.options.snf['bed_cohort_id']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.snf_bed_cohort_id}
				/>
			</div>
		);
	}

	rightSideSNFEdits() {
		return (
			<div>
				<DropDownCtrl
					width={ctrWidth}
					id="urban_rural_indicator"
					domain="snf"
					label="Urban / Rural"
					placeholder="Select ..."
					options={this.state.options.hosp['urban_rural_indicator']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.snf_urban_rural_indicator}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="net_patrev_cohort_id"
					domain="snf"
					label="Net Patient Revenue"
					placeholder="Select ..."
					options={this.state.options.hosp['net_patrev_cohort_id']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.snf_net_patrev_cohort_id}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="net_income_cohort_id"
					domain="snf"
					label="Net Profit or Loss"
					placeholder="Select ..."
					options={this.state.options.hosp['net_income_cohort_id']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.snf_net_income_cohort_id}
				/>
			</div>
		);
	}

	centerHospEdits() {
		return (
			<div>
				<DropDownCtrl
					width={ctrWidth}
					id="hosp_ownership_type"
					label="Ownership Type"
					domain="hosp"
					placeholder="Select ..."
					options={this.state.options.hosp['hosp_ownership_type']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_hosp_ownership_type}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="bed_cohort"
					domain="hosp"
					label="Bed Size"
					placeholder="Select ..."
					options={this.state.options.hosp['bed_cohort']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_bed_cohort}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="teach_status_class"
					domain="hosp"
					label="Teaching Status"
					placeholder="Select ..."
					options={this.state.options.hosp['teach_status_class']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_teach_status_class}
				/>
			</div>
		);
	}
	leftSideHospEdits() {
		return (
			<div>
				<DropDownCtrl
					width={ctrWidth}
					id="system"
					domain="hosp"
					label="System Affiliation"
					placeholder="Select ..."
					options={this.state.options.hosp['system']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_system}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="hospital_type"
					domain="hosp"
					label="Provider Type"
					placeholder="Select ..."
					options={this.state.options.hosp['hospital_type']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_hospital_type}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="cah"
					domain="hosp"
					label="Critical Access"
					placeholder="Select ..."
					options={this.state.options.hosp['cah']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_cah}
				/>
			</div>
		);
	}

	searchTogleBtns() {
		return (
			<div className="custom-control-inline custom-checkbox text-primary my-2">
				<div className="col-3 ml-2">
					<input
						defaultChecked={this.state.hosp}
						type="checkbox"
						className="custom-control-input"
						id="search_hosp"
						name="search_hosp"
						onChange={e => this.onOptionChange('hosp', e)}
						checked={this.state.hosp ? 'checked' : ''}
					/>
					<label className="custom-control-label" htmlFor="search_hosp">
						Hospital
					</label>
				</div>
				<div className="col-9 ml-3">
					<input
						defaultChecked={this.state.snf}
						type="checkbox"
						className="custom-control-input"
						id="search_snf"
						name="search_snf"
						onChange={e => this.onOptionChange('snf', e)}
						checked={this.state.snf ? 'checked' : ''}
					/>
					<label className="custom-control-label" htmlFor="search_snf">
						Skilled Nursing Facility
					</label>
				</div>
			</div>
		);
	}

	rightSideHospEdits() {
		return (
			<div>
				<DropDownCtrl
					width={ctrWidth}
					id="urban_rural_indicator"
					domain="hosp"
					label="Urban / Rural"
					placeholder="Select ..."
					options={this.state.options.hosp['urban_rural_indicator']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_urban_rural_indicator}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="net_patrev_cohort_id"
					domain="hosp"
					label="Net Patient Revenue"
					placeholder="Select ..."
					options={this.state.options.hosp['net_patrev_cohort_id']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_net_patrev_cohort_id}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="net_income_cohort_id"
					domain="hosp"
					label="Net Profit or Loss"
					placeholder="Select ..."
					options={this.state.options.hosp['net_income_cohort_id']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_net_income_cohort_id}
				/>
				{/* <div className='row'>
          <div className='col-12'>
            <DropDownCtrl
          width={ctrWidth}
              id='provider_type'
              label='Overall Quality Score'
              placeholder='Select ...'
              options={this.state.options.hosp[""]}
              onChangeCallBack={this.onFldChange}
              values={this.state.values.provider_type}

            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <DropDownCtrl
          width={ctrWidth}
              id='services_provided'
              label='Services Provided by Facility'
              placeholder='Select ...'
              options={this.state.options.hosp[""]}
              onChangeCallBack={this.onFldChange}
              values={this.state.values.services_provided}

            />
          </div>
        </div> */}
			</div>
		);
	}

	onOptionChange(domain, e) {
		let state = this.state;
		switch (domain) {
			case 'hosp':
				state.hosp = e.target.checked;
				break;
			case 'snf':
				state.snf = e.target.checked;
				break;
			case 'hha':
				state.hha = e.target.checked;
				break;
		}
		this.setState(state);

		if (!this.props.model) {
			if (this.props.onDomainChanged)
				this.props.onDomainChanged(this.state.hosp, this.state.snf, this.state.hha);
		}
	}

	onReset(e) {
		let state = this.state;

		state.filters = [];
		state.hosp = state.snf = state.hha = true;

		state.values._state = [];
		state.values._cbsa_name = [];
		state.values.hosp_city_name = [];
		state.values.hosp_system = [];
		state.values.hosp_hospital_type = [];
		state.values.hosp_hosp_ownership_type = [];
		state.values.hosp_teach_status_class = [];
		state.values.hosp_cah = [];
		state.values.hosp_urban_rural_indicator = [];
		state.values.hosp_bed_cohort = [];
		state.values.hosp_net_patrev_cohort_id = [];
		state.values.hosp_net_income_cohort_id = [];

		state.values.snf_provider_subtype = [];
		state.values.snf_ownership_type = [];
		state.values.snf_bed_cohort_id = [];
		state.values.snf_urban_rural_indicator = [];
		state.values.snf_ownership_type = [];
		state.values.snf_bed_cohort_id = [];
		state.values.snf_net_patrev_cohort_id = [];
		state.values.snf_net_income_cohort_id = [];

		this.setState(state);
	}
	onApply(e) {
		if (this.props.onFilterChanged) this.props.onFilterChanged(this.state.filters);
		if (this.props.onDomainChanged)
			this.props.onDomainChanged(this.state.hosp, this.state.snf, this.state.hha);
	}

	locView() {
		return (
			<SectionLabel>
				<DropDownCtrl
					width={ctrWidth}
					id="state"
					domain=""
					label="State"
					placeholder="Select ..."
					options={this.state.options.hosp['state']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values._state}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="cbsa_name"
					domain=""
					label="Core Based Statistical Area (CBSA)"
					placeholder="Select ..."
					options={this.state.options.hosp['cbsa_name']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values._cbsa_name}
				/>
			</SectionLabel>
		);
	}

	snfView() {
		return (
			<div className="">
				<div className="">{this.leftSideSNFEdits()}</div>
				<div className="">{this.rightSideSNFEdits()}</div>
			</div>
		);
	}

	hospView() {
		return (
			<div>
				<div className="">{this.leftSideHospEdits()}</div>
				<div className="">{this.centerHospEdits()}</div>
				<div className="">{this.rightSideHospEdits()}</div>
			</div>
		);
	}
	tabView() {
		let TabHeaders = ['Hospital', 'Skilled Nursing Facility'];
		let TabContent = [];
		TabContent.push(this.hospView());
		TabContent.push(this.snfView());

		return (
			<div>
				{this.locView()}

				<div className="row">
					<div className="col-12">{this.searchTogleBtns()}</div>
				</div>

				<Tabs key={this.state.key} headers={TabHeaders} content={TabContent} />

				{/* 
        <ul className='nav nav-tabs'>
          <li className='nav-item'>
            <a className='nav-link active' data-toggle='tab' href='#hosp_tab'>
              Hospital
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-toggle='tab' href='#snf_tab'>
              Skilled Nursing Facility
            </a>
          </li>
        </ul>

        <div className='tab-content'>
          <div className='tab-pane container active' id='hosp_tab'>
            {this.hospView()}
          </div>
          <div className='tab-pane container fade' id='snf_tab'>
            <div>Hello</div>
          </div>
        </div> */}
			</div>
		);
	}

	dlgButtons() {
		return (
			<div className="modal-footer">
				<button type="button" className="btn btn-secondary " onClick={this.onReset}>
					Reset
				</button>

				<button
					type="button"
					className="btn btn-secondary"
					data-dismiss="modal"
					onClick={this.onApply}
				>
					Apply
				</button>
			</div>
		);
	}

	render() {
		return (
			<div>
				{this.tabView()}
				{this.props.model ? this.dlgButtons() : ''}
			</div>
		);
	}
}
