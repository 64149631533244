import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../tools/datagrid';
import LoadingSpinner from '../../tools/loadingSpinner';
import * as misc from '../../lib/misc';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/fontawesome-free-solid';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.hospBenchmarkData.whereChanged,
		data: store.hospBenchmarkData.Benchmark,
		dataLoaded: store.hospBenchmarkData.fetched,
		dataLoading: store.hospBenchmarkData.fetching,
		datakey: store.hospBenchmarkData.dataKey
	};
};
class BenchmarkTab extends React.Component {
	constructor(props) {
		super(props);
		this.SummaryCols = props.Cols;
		this.sections = props.Sections;
		this.getAddCompetitors = this.props.getCompetitors;
	}

	render() {
		let data = this.props.data;
		let datakey = this.props.datakey;
		let providers = misc.getFOIandCompetitors();
		if (this.props.dataLoading) {
			return 
				<LoadingSpinner />;
		} else {
			if (
				this.props.dataLoaded &&
				!this.props.dataLoading &&
				Array.isArray(this.sections) &&
				this.sections.length > 0
			) {
				let results = [];
				for (let i = 0; i < this.sections.length; i++) {
					if (i == 0) {
						// if (this.sections[i].title) {
						// 	results.push(
						// 		<h5 className="tblHead">{this.sections[i].title}</h5>
						// 	);
						// }
						results.push(
							<div className="sticky_table_header">
								<MHGrid.DataGrid
									suppressLines={true}
									fieldDef={this.FieldDesc}
									columns={this.SummaryCols}
									rows={data ? this.props.data.Fetch(this.sections[i].fields, providers) : []}
									showHeaders={true}
									headersOnly={true}
								/>
							</div>
						);
						results.push(
							<MHGrid.DataGrid
								suppressLines={true}
								fieldDef={this.props.FieldDesc}
								columns={this.SummaryCols}
								rows={data ? this.props.data.Fetch(this.sections[i].fields, providers) : []}
								subcategory={this.sections[i].title}
								showHeaders={false}
							/>
						);
					} else {
						results.push(
							<MHGrid.DataGrid
								suppressLines={true}
								fieldDef={this.props.FieldDesc}
								columns={this.SummaryCols}
								rows={data ? this.props.data.Fetch(this.sections[i].fields, providers) : []}
								subcategory={this.sections[i].title}
								showHeaders={false}
							/>
						);
					}
				}
				return (
					<div className="panel panel-default" id={datakey + misc.Guid()}>
						{results}
					</div>
				);
			} else {
				return (
					<div className="panel panel-default text-center content-center">
						<FontAwesomeIcon
							className="fa-8x"
							style={{ opacity: 0.3, filter: 'alpha(opacity=30)' }}
							icon={faBan}
						/>
						<h2 className="p-3">No Data Available</h2>
					</div>
				);
			}
		}
	}
}

export default connect(
	mapStateToProps,
	null
)(BenchmarkTab);
