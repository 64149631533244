import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../tools/datagrid';
import DataGridFooterNotation from '../../tools/datagrid/extras/DataGridFooterNotation';
import * as MHGridGroup from '../../tools/datagrid/extras/DataGridGroupHeader';
import DataGridQuartile from '../../tools/datagrid/extras/DataGridQuartile';
import BenchmarkDataExport from '../../hosp/Benchmark/BenchmarkDataExport';
import FacilityCompare from '../../core/components/facilityCompare';
import * as Fields from './QualityFields';
import LoadingSpinner from '../../tools/loadingSpinner';
import QualityTab from './QualityTab';
import * as providerActions from '../../core/actions/GlobalActions';
import * as misc from '../../lib/misc';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faCheck, faColumns, faAngleDown, faUndo } from '@fortawesome/fontawesome-free-solid';
import SnfPeerGroup from '../Common/snfPeerGroup';
import QualityInspectionReports from './QualityInspectionReports';
import QualityDeficiencies from './QualityDeficiencies';
import _ from 'lodash';

const CustomGridMenu = (compareId, customBenchId, bench, active, updateInfo, toggleUpdater, tabDef, SummaryCols, data, FieldDesc) => {
	let Title = new MHGrid.DataGridTitle('Quality Metrics', '', '', 3);
	let _sectionsTemp = [SummaryTab, HealthSafetyTab, FireSafetyTab, FireSafetyTab, StaffingTab, QRPTab];
	
	return (
		<div class="d-none d-md-block mb-1">
			<button
				class="form-control select text-small px-0"
				style={{
					height: '32px',
					width: '120px'
				}}
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				Options <FontAwesomeIcon className="fa-sm" icon={faAngleDown} />
			</button>
			<ul class="dropdown-menu shadow bg-light">
				<li
					className="nav-link menu-link-label small py-1"
					data-toggle="modal"
					data-target={'#' + compareId}
				>
					<FontAwesomeIcon className="fa-1x text-primary" icon={faColumns} />
					<span className="px-2">Compare</span>
				</li>
				<div class="dropdown-divider" />
				{bench ? (
					<span className="nav-link menu-link-label small strong py-1">Custom Peer Group</span>
				) : (
					''
				)}
				<li
					className="nav-link menu-link-label small py-1"
					data-toggle="modal"
					data-target={'#' + customBenchId}
				>
					<span>{bench ? 'Create New' : 'Custom Peer Group'}</span>
				</li>
				{bench ? (
					<li className="nav-link menu-link-label small py-1" onClick={updateInfo}>
						<FontAwesomeIcon className={'fa-1x text-primary'} icon={faUndo} />
						<span className="px-2">Reset</span>
					</li>
				) : (
					''
				)}
				{bench ? (
					<li className="nav-link menu-link-label small py-1" onClick={toggleUpdater}>
						<FontAwesomeIcon
							className={active ? 'fa-1x text-primary' : 'fa-1x text-light'}
							icon={faCheck}
						/>
						<span className="px-2">Custom</span>
					</li>
				) : (
					''
				)}
				{bench ? (
					<li className="nav-link menu-link-label small py-1" onClick={toggleUpdater}>
						<FontAwesomeIcon
							className={!active ? 'fa-1x text-primary' : 'fa-1x text-light'}
							icon={faCheck}
						/>
						<span className="px-2">National</span>
					</li>
				) : (
					''
				)}
				<div class="dropdown-divider" />
				<li className="nav-link menu-link-label small py-1">
					{data ? (
						<BenchmarkDataExport
							fileName={Title.header1}
							domain={'snf'}
							sections={_sectionsTemp}
							tabDef={tabDef}
							headAlign={'center'}
							bodyAlign={'right'}
							Cols={SummaryCols}
							data={data}
							FieldDesc={FieldDesc}
						/>
					) : null}
				</li>
			</ul>
		</div>
	);
};

const SummaryTab = [
	{
		title: 'Five-Star Rating Categories',
		callout:
			'CMS created the Five-Star Quality Rating System to help consumers, their families, and caregivers compare nursing homes more easily and to help identify areas about which you may want to ask questions. CMS assigns an overall rating on a scale of 1 to 5 stars, with 5 being the highest.  Note: CMS routinely makes updates to their rating system which may affect the comparably of star ratings from year-to-year. The last update occurred in April of 2019.',
		fields: Fields.QualitySummaryMetrics
	},
	{
		title: 'Health Inspection Domain',
		callout: `Ratings for
			the health inspection domain are based on the number, scope, and severity of deficiencies
			identified during the three most recent annual inspection surveys, as well as substantiated findings
			from the most recent 36 months of complaint investigations. All deficiency findings are weighted
			by scope and severity. This measure also takes into account the number of revisits required to
			ensure that deficiencies identified during the health inspection survey have been corrected. `,
		fields: Fields.HealthInspectionDomainMetrics
	},
	{
		title: 'Staffing Domain',
		callout: `Ratings on the staffing domain are
		based on two measures: 1) Registered nurse (RN) hours per resident day; and 2) total nurse
		staffing (the sum of RN, licensed practical nurse (LPN), and nurse aide) hours per resident per
		day. Other types of nursing home staff, such as clerical or housekeeping staff, are not included in
		the staffing rating calculation. The staffing measures are derived from data submitted each quarter
		through the Payroll-Based Journal (PBJ) System, along with daily resident census derived from
		Minimum Data Set, Version 3.0 (MDS 3.0) assessments, and are case-mix adjusted based on the
		distribution of MDS 3.0 assessments by Resource Utilization Groups, version IV (RUG-IV
		group). In addition to the overall staffing rating, a separate rating for RN staffing is also reported.`,
		fields: Fields.StaffingFiveStarMetrics()
	},
	{
		title: 'Quality Measures Domain',
		callout: `Ratings for the quality measures are based on performance on 17 of the QMs that are currently
		posted by CMS. These include ten long-stay measures and seven
		short-stay measures. Note that not all of the quality measures that are reported on Nursing Home
		Compare are included in the rating calculations. In addition to an overall quality of resident care
		rating, separate ratings for the quality of resident care for short-stay residents and long-stay
		residents are also reported.`,
		fields: Fields.FiveStart_QualityMeasuresMetrics
	}
];
const QRPTab = [
	{
		title: 'Short-Stay (Measures Used to Calculate the Star Rating)',
		callout:
			'The short stay resident quality measures show the average quality of resident care in a nursing home for those who stayed in a nursing home for 100 days or less or are covered under the Medicare Part A Skilled Nursing Facility (SNF) benefit.',
		fields: Fields.MDSQualityShortStayMetrics
	},
	{
		title: 'Short-Stay (Flu and Pneumonia Prevention Measures)',
		callout:
			'Note: Medicare provides funding for 100 days of skilled nursing care per qualifying event.',
		fields: Fields.MDSQualityShortStayVaccineMetrics
	},
	{
		title: 'Long-Stay (Measures Used to Calculate the Star Rating)',
		fields: Fields.MDSQualityLongStayMetrics
	},
	{
		title: 'Long-Stay (Flu and Pneumonia Prevention Measures)',
		fields: Fields.MDSQualityLongStayVaccineMetrics
	},
	{
		title: 'Long-Stay (Additional Measures)',
		fields: Fields.MDSQualityLongStayAdditionalMetrics
	}
];

const PenaltiesTab = [
	{
		title: 'Penalties',
		fields: []
	}
];

const HealthSafetyTab = [
	{
		title: 'Health Inspection Summary',
		fields: Fields.HealthInspectionsMetrics
	},
	{
		title: 'Deficiency Category',
		fields: Fields.HealthDeficiencyCategoryMetrics
	}
];

const FireSafetyTab = [
	{
		title: 'Fire Safety Inspections',
		fields: Fields.FireInspectionSummaryMetrics
	},
	{
		title: 'Deficiency Category',
		fields: Fields.FireDeficiencyCategoryMetrics
	}
];
const StaffingTab = [
	{
		title: 'Payroll-Based Journal Nurse Staffing',
		callout:
			'Note: Medicare provides funding for 100 days of skilled nursing care per qualifying event.',
		fields: Fields.PBJStaffingMetrics
	},
	{
		title: 'Payroll-Based Journal Staffing',
		callout:
			'Note: Medicare provides funding for 100 days of skilled nursing care per qualifying event.',
		fields: Fields.PBJNonNurseStaffingMetrics
	}
];

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.snfBenchmarkData.whereChanged,
		data: store.snfQualityData.Benchmark,
		dataLoaded: store.snfQualityData.fetched,
		dataLoading: store.snfQualityData.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProviderInfo: ids => dispatch(providerActions.findProviders(ids))
	};
};

class QualityDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentYear:
				this.props.data && this.props.data.currentYear ? this.props.data.currentYear : null,
			selectedTab: 0,
			active: true,
			providers: misc.getFOIandCompetitors(),
			key: misc.Guid()
		};
		// if (this.props.updateInfo) {
		// 	this.UpdateInfo = this.props.updateInfo.bind(this);
		// 	this.ResetUpdateInfo = this.props.updateInfo.bind(this);
		// }
		this.ResetUpdateInfo = this.ResetUpdateInfo.bind(this);
		this.UpdateInfo = this.UpdateInfo.bind(this);
		this.onYearChanged = this.onYearChanged.bind(this);
		this.tabChanged = this.tabChanged.bind(this);
		this.switchActive = this.switchActive.bind(this);
		this.selectedYear = 'LATEST';
	}

	onYearChanged(year) {
		this.props.data.ActiveYear = year;
		this.selectedYear = year;
		let state = this.state;
		state.currentYear = year;
		this.setState(state);
	}

	tabChanged(selectedTab) {
		let state = this.state;
		state.selectedTab = selectedTab;
		this.setState(state);
	}
	UpdateInfo() {
		let state = this.state;
		state.providers = misc.getFOIandCompetitors();
		state.customBenchmark = misc.hasCustomBenchmark();
		state.active = state.customBenchmark;
		state.key = misc.Guid();
		this.setState(state);
		this.props.updateInfo();
	}
	ResetUpdateInfo() {
		sessionStorage.setItem('customBenchmark', JSON.stringify([]));
		sessionStorage.setItem('custom-peer-group-description', '');
		let currState = this.state;
		currState.active = false;
		currState.customBenchmark = false;
		currState.key = misc.Guid();
		this.setState(currState);
		this.props.updateInfo();
	}

	switchActive() {
		let currState = this.state;
		currState.active = !this.state.active;
		currState.key = misc.Guid();
		this.setState(currState);
	}

	render() {
		let ContentPage = null;
		let Title = new MHGrid.DataGridTitle(
			'Quality Metrics',
			'',
			'',
			3,
			this.props.data.Years,
			this.onYearChanged,
			true,
			true
		);
		let compareId = 'compare_benchmark';
		let custmBenchId = 'custom_peergroup';
		let providers = misc.getFOIandCompetitors();

		if (this.props.dataLoaded && !this.props.dataLoading) {
			let SummaryCols = this.props.dataLoaded
				? MHGridGroup.DataGridGroupHeader(
						(this.state.active &&
							this.props.data.args != null &&
							this.props.data.args['custom'] &&
							misc.hasCustomBenchmark()) ||
							false,
						providers,
						'snf'
				  )
				: null;

			const tabDef = [
				{
					id: 0,
					title: 'Five-Star Quality Rating',
					component: (
						<QualityTab
							key={this.state.key}
							{...this.props}
							Cols={SummaryCols}
							Sections={SummaryTab}
						/>
					)
				},
				{
					id: 1,
					title: 'Health Inspections',
					component: (
						<QualityInspectionReports
							key={this.state.key}
							{...this.props}
							Cols={SummaryCols}
							Sections={HealthSafetyTab}
						/>
					)
				},
				{
					id: 2,
					title: 'Health Deficiencies',
					component: (
						<QualityDeficiencies
							key={this.state.key}
							{...this.props}
							Year={this.selectedYear}
							Cols={SummaryCols}
							Sections={FireSafetyTab}
						/>
					)
				},
				{
					id: 3,
					title: 'Fire Safety Inspections',
					component: (
						<QualityTab
							key={this.state.key}
							{...this.props}
							Cols={SummaryCols}
							Sections={FireSafetyTab}
						/>
					)
				},
				{
					id: 4,
					title: 'Staffing',
					component: (
						<QualityTab
							key={this.state.key}
							{...this.props}
							Cols={SummaryCols}
							Sections={StaffingTab}
						/>
					)
				},
				{
					id: 5,
					title: 'Quality of Resident Care',
					component: (
						<QualityTab key={this.state.key} {...this.props} Cols={SummaryCols} Sections={QRPTab} />
					)
				}
			];

			let FieldDesc = this.props.FieldDesc;
			let CustomGrid = this.props.dataLoaded
				? CustomGridMenu(
						compareId,
						custmBenchId,
						this.props.data.args != null &&
							this.props.data.args['custom'] &&
							misc.hasCustomBenchmark(),
						this.state.active,
						this.ResetUpdateInfo,
						this.switchActive,
						tabDef,
						SummaryCols,
						this.props.data,
						FieldDesc
				  )
				: null;
			
			const pillTabs = tabDef.map(({ id, title } = {}) => (
				<li
					className={
						this.state.selectedTab === id
							? 'react-tabs__tab react-tabs__tab--selected  d-block'
							: 'react-tabs__tab d-block'
					}
				>
					<a className="d-block" onClick={() => this.tabChanged(id)}>
						{title.toString()}
					</a>
				</li>
			));
			const toggleTabs = tabDef.map(({ id, title } = {}) => (
				<li
					className={
						this.state.selectedTab === id
							? 'react-tabs__tab react-tabs__tab--selected'
							: 'react-tabs__tab'
					}
					onClick={() => this.tabChanged(id)}
				>
					<a data-toggle="tab">
						{title.toString()}
					</a>
				</li>
			));
			const panelTab = tabDef.map(({ id, title } = {}) => (
				<div className={this.state.selectedTab === id ? 'tab-pane active' : 'tab-pane'}>
					{(tabDef[id] && tabDef[id].component) || null}
				</div>
			));
			const tabBody = (
				<div className="col-12">
					<div class="dropdown show d-flex col-12 mx-auto d-md-none react-tabs__tab-list">
						<a
							class="react-tabs__tab react-tabs__tab--selected"
							href="#"
							role="button"
							id="dropdownMenuLink"
							data-toggle="dropdown"
						>
							{tabDef[this.state.selectedTab].title.toString()}
						</a>

						<div class="dropdown-menu text-center mx-auto" aria-labelledby="dropdownMenuLink">
							<ul class="nav nav-stacked d-block d-md-none">{pillTabs}</ul>
						</div>
					</div>
					<ul
						class="d-none d-md-flex px-0 col-12 mx-auto react-tabs__tab-list mt-1"
						role="tablist"
					>
						{toggleTabs}
						<li className="react-tabs__tab">
							<div className="pull-right">{CustomGrid}</div>
						</li>
					</ul>
					<div className="tab-content p-0 col-12">
						<DataGridQuartile className="pull-left" domain={'snf'}>
							<FacilityCompare
								changeComparison={this.UpdateInfo}
								TargetId={compareId}
								segment="snf"
							/>
						</DataGridQuartile>
						<SnfPeerGroup updateBenchmark={this.UpdateInfo} ModalId={custmBenchId} />
						{panelTab}
					</div>
				</div>
			);

			ContentPage = (
				<>
					{Title.renderHeading()}
					<div className="row">
						{tabBody}
						<DataGridFooterNotation />
					</div>
				</>
			);
		} else {
			ContentPage = (
				<div className="row">
					<LoadingSpinner />
				</div>
			);
		}

		return <>{ContentPage}</>;
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityDetail);
