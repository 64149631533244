import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../../tools/datagrid';
import * as misc from '../../../lib/misc';
import LoadingSpinner from '../../../tools/loadingSpinner';
import { Row, Col } from 'react-bootstrap';
import * as Map from '../../../tools/map';
import * as format from '../../../lib/FormatUtil';
import { LegendItem, Legend } from '../../../tools/charts/legend';

import * as fieldDefActions from '../../../core/actions/GlobalActions';
import * as ActionTypes from '../../Common/ActionTypes';
import * as Actions from '../../../core/actions/ActionHelper';
import * as Fields from './CompetitorFields';
import * as Lib from '../SMDLib';
import _ from 'lodash';
import DataGridQuartile from '../../../tools/datagrid/extras/DataGridQuartile';

const BuildCompetitorPopup = Competitor => {
	return (
		<ul className="list-group">
			<li className="list-group-item">
				<b>{Competitor.prvdr_num + ' ' + Competitor.name}</b>
			</li>
			<li className="list-group-item">
				PSA Cases: <span className="badge">{format.getInt(Competitor.total_cases_psa)}</span>
			</li>
			<li className="list-group-item">
				SSA Cases: <span className="badge">{format.getInt(Competitor.total_cases_ssa)}</span>
			</li>
			<li className="list-group-item">
				Total Cases: <span className="badge">{format.getInt(Competitor.total_cases)}</span>
			</li>
			<li className="list-group-item">
				Total Days Care: <span className="badge">{format.getInt(Competitor.overall_total_cases)}</span>
			</li>
		</ul>
	);
};

const RenderPopup = x => {
	return `
		<div className="popup-content">
			<b>${x.Zipcode || ''}</b>
	
			<div>Total Cases: ${x.TotalCases || ''}</div>
			
			<div>Total Days: ${x.TotalDays || ''}</div>
			
			<div>Avg LOS: ${x.AvgLOS || ''}</div>
			
			<div>Total Charges: ${x.TotalCharges || ''}</div>
			
			<div>Proportion of Zip: ${format.getPercent(x.PropZipChargesTotal) || ''}</div>
		</div>	
		`;
};
const RenderCompetitorsMap = (provider, data) => {
	let center = [];
	let markers = [];
	let geoShapes = [];

	if (provider) {
		center.push(provider.lat);
		center.push(provider.lng);

		// Loop through the comp

		if (data && data.PSACompetitors) {
			let Competitors = data.PSACompetitors;
			// Build the FOI Popup
			let prvdr = _.find(Competitors, x => x.prvdr_num == provider.providerId);

			if (prvdr) {
				let Marker = new Map.MapMarker();
				Marker.content = BuildCompetitorPopup(prvdr);
				Marker.lat = prvdr.prvdr_intplat;
				Marker.lng = prvdr.prvdr_intlong;
				markers.push(Marker);
			} else {
				// FOI was not in the Competitor List
				let marker = new Map.MapMarker(provider);
				markers.push(marker);
			}
			Competitors.forEach(x => {
				if (x.prvdr_num != provider.providerId) {
					let Marker = new Map.MapMarker();
					Marker.content = BuildCompetitorPopup(x);
					Marker.lat = x.prvdr_intplat;
					Marker.lng = x.prvdr_intlong;
					markers.push(Marker);
				}
			});
		}
	}

	if (data && data.zipCodeMarketTrend) {
		data.zipCodeMarketTrend.forEach(x => {
			if (x && x.geom_id) {
				let getColor = '';
				const val = Number(x.PropZipChargesTotal) * 100;

				if (val >= 45) {
					getColor = Map.MapColors.Best;
				} else if (val >= 30 && val < 45) {
					getColor = Map.MapColors.Good;
				} else if (val >= 15 && val < 30) {
					getColor = Map.MapColors.Poor;
				} else if (val < 15) {
					getColor = Map.MapColors.Worst;
				} else {
					getColor = Map.MapColors.Worst;
				}

				let obj = new Map.MapGeoObject(x.geom_id, x.Zipcode, x.Zipcode, getColor, RenderPopup(x));
				geoShapes.push(obj);
			}
		});
	}

	return (
		<Row>
			<Col xs={12} md={12}>
				<Map.DataMap
					center={center}
					markers={markers}
					zoomControl={true}
					geoShapes={geoShapes}
					scrollWheelZoom={false}
					doubleClickZoom={true}
					dragging={true}
					zoom={10}
					height={'300px'}
				/>
			</Col>
			{/* <Col xs={12} md={12}>
				{Legend(
					new LegendItem(Map.MapColors.Best, 'Over 45 % of Population'),
					new LegendItem(Map.MapColors.Good, '30 - 45 % of Population'),
					new LegendItem(Map.MapColors.Poor, '15 - 30 % of Population'),
					new LegendItem(Map.MapColors.Worst, 'Less Than 15 % of Population')
				)}
			</Col> */}
		</Row>
	);
};

const BuildGridHeaderSSC = Competitors => {
	// Create Column Headers for Competitors Grid
	if (Competitors && Competitors.length > 0) {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'metric',
				new MHGrid.DataGridHeader('', '', '', ''),
				350,
				MHGrid.ColType_Metric
			)
		);
		Competitors.forEach(x => {
			if (x.prvdr_num) {
				Cols.Add(
					new MHGrid.DataGridColumn(
						x.prvdr_num,
						MHGrid.DataGridHeaderBuilder(format.initCap(x.name), 'center'),
						150,
						MHGrid.ColType_Self
					)
				);
			}
		});
		Cols.Add(
			new MHGrid.DataGridColumn(
				'National',
				new MHGrid.DataGridHeader('National Benchmark', 'National GAC', 'All Bed Sizes', 'center'),
				175,
				MHGrid.ColType_Self
			)
		);

		return Cols;
	} else return null;
};

const BuildGridHeaderRPC = Competitors => {
	// Create Column Headers for Competitors Grid
	if (Competitors && Competitors.length > 0) {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'metric',
				new MHGrid.DataGridHeader('', '', '', ''),
				350,
				MHGrid.ColType_Metric
			)
		);

		Competitors.forEach(x => {
			Cols.Add(
				new MHGrid.DataGridColumn(
					x.prvdr_num,
					MHGrid.DataGridHeaderBuilder(format.initCap(x.name), 'center'),
					150,
					MHGrid.ColType_Self
				)
			);
		});

		Cols.Add(
			new MHGrid.DataGridColumn(
				'National',
				new MHGrid.DataGridHeader('National Benchmark', 'National GAC', 'All Bed Sizes', 'center'),
				175,
				MHGrid.ColType_Self
			)
		);
		return Cols;
	} else return null;
};

const BuildFacilityLevelFOS = (data, providers, columns) => {
	// Financial & Operating Statistics
	let results = [];
	results.push(
		<div className="sticky_table_header">
			<MHGrid.DataGrid
				suppressLines={true}
				columns={columns}
				rows={[]}
				onlyHeaders={true}
				showHeaders={true}
			/>
		</div>
	);
	results.push(
		<MHGrid.DataGrid
			suppressLines={true}
			fieldDef={Fields.FacilityLevelFOSMetrics}
			columns={columns}
			rows={data ? data.Fetch(Fields.FacilityLevelFOSMetrics, providers) : []}
			showHeaders={false}
		/>
	);
	return results;
};

// const BuildFacilityLevelFOSTotalShare = (data, provider, columns, title) => {
// 	// Financial & Operating Statistics
// 	return (
// 		<MHGrid.DataGrid
// 			suppressLines={true}
// 			fieldDef={Fields.FacilityLevelFOSMetrics}
// 			columns={columns}
// 			rows={data ? data.Fetch(Fields.FacilityLevelFOSMetrics, provider) : []}
// 			subcategory="Facility Level Financial & Operating Statistics"
// 			title={title}
// 		/>
// 	);
// };

const BuildFinancialMetrics = (data, providers, columns) => {
	// Financial Metrics
	return (
		<MHGrid.DataGrid
			suppressLines={true}
			fieldDef={Fields.FinancialMetrics}
			columns={columns}
			showHeaders="false"
			rows={data ? data.Fetch(Fields.FinancialMetrics, providers) : []}
			subcategory="Financial Metrics"
		/>
	);
};

// const BuildFinancialMetricsCompare = (data, provider, competitors, columns, title) => {
// 	let prvdr = provider ? [provider.providerId] : [];
// 	//compareColumn compareTotal
// 	// Financial Metrics
// 	return (
// 		<MHGrid.DataGrid
// 			suppressLines={true}
// 			fieldDef={Fields.FinancialMetrics}
// 			columns={columns}
// 			rows={misc.crossCompare(
// 				data ? data.Fetch(Fields.FinancialMetricsCompare, provider) : [],
// 				prvdr,
// 				competitors
// 			)}
// 			subcategory="Financial Metrics"
// 			title={title}
// 		/>
// 	);
// };

const BuildOperationalMetrics = (data, providers, columns) => {
	// Operational Metrics
	return (
		<MHGrid.DataGrid
			suppressLines={true}
			fieldDef={Fields.OperationalMetrics}
			columns={columns}
			showHeaders="false"
			rows={data ? data.Fetch(Fields.OperationalMetrics, providers) : []}
			subcategory="Operational Metrics"
		/>
	);
};

// const BuildOperationalMetricsCompare = (data, provider, competitors, columns, title) => {
// 	let prvdr = provider ? [provider.providerId] : [];
// 	return (
// 		<MHGrid.DataGrid
// 			suppressLines={true}
// 			fieldDef={Fields.OperationalMetricsCompare}
// 			columns={columns}
// 			//	rows={misc.crossCompare(misc.filterMetrics(rows.Data, OperationalList), prvdr, competitors)}
// 			subcategory="Operational Metrics"
// 		/>
// 	);
// };

const BuildUtilizationMetrics = (data, providers, columns) => {
	// Utilization Metrics
	return (
		<MHGrid.DataGrid
			suppressLines={true}
			fieldDef={Fields.UtilizationMetrics}
			columns={columns}
			showHeaders="false"
			rows={data ? data.Fetch(Fields.UtilizationMetrics, providers) : []}
			subcategory="Utilization Metrics"
		/>
	);
};

const BuildQualityPFPMetrics = (data, providers, columns) => {
	// Quality & PFP Metrics
	return (
		<MHGrid.DataGrid
			suppressLines={true}
			fieldDef={Fields.QualityPFPMetrics}
			columns={columns}
			showHeaders="false"
			rows={data ? data.Fetch(Fields.QualityPFPMetrics, providers) : []}
			subcategory="Quality & PFP Metrics"
		/>
	);
};

// const BuildQualityPFPMetricsCompare = (data, provider, competitors, columns) => {
// 	let prvdr = provider ? [provider.providerId] : [];
// 	return (
// 		<MHGrid.DataGrid
// 			suppressLines={true}
// 			fieldDef={Fields.QualityPFPMetricsCompare}
// 			columns={columns}
// 			//rows={misc.crossCompare(misc.filterMetrics(rows.Data, QualityList), prvdr, competitors)}
// 			subcategory="Quality & PFP Metrics"
// 		/>
// 	);
// };

const RenderCompetitorsPage = (provider, marketShare, data) => {
	const competitorIds = Lib.CompetitorList(misc.getFacilityIds(), marketShare.result[0]);
	const Competitors = Lib.CompetitorNames(provider, marketShare.result[0]);

	let SSMcol = BuildGridHeaderSSC(Competitors);
	let RPMcol = BuildGridHeaderRPC(Competitors);
	let TitleSSC = new MHGrid.DataGridTitle('Single Year Summary by Key Metric', '', '');
	TitleSSC.header1Size = 3;

	let TitleRPM = new MHGrid.DataGridTitle(
		'Relative Percent Difference to ' + provider.name,
		'',
		''
	);
	TitleRPM.header1Size = 3;

	// let TitleRPM2 = new MHGrid.DataGridTitle('Percent of Total by Metric', '', '');
	// TitleRPM2.header1Size = 3;
	return (
		<div>
			<div className="panel panel-default" style={{}}>
				<DataGridQuartile className="pull-left" />
				{TitleSSC.render()}
				<h5 className="tblHead">
					{'Facility Level Financial & Operating Statistics'}
				</h5>
				<div>
					{BuildFacilityLevelFOS(data, provider, SSMcol)}
					{BuildFinancialMetrics(data, provider, SSMcol)}
					{BuildOperationalMetrics(data, provider, SSMcol)}
					{BuildUtilizationMetrics(data, provider, SSMcol)}
					{BuildQualityPFPMetrics(data, provider, SSMcol)}
				</div>
			</div>
		</div>
	);
};

const RenderBody = (Provider, MarketShareData, Data) => {
	return RenderCompetitorsPage(Provider, MarketShareData, Data);
};

////// Use this to access Data ////////////////////////////////////
/////   store.marketData.MarketShareData
////// ////////////////////////////////////////////////////////////

// const mapStateToProps = ({ marketData: marketShare, prvdr }) => ({
// 	marketShare,
// 	prvdr
// });

const mapStateToProps = store => {
	return {
		prvdr: store.prvdr,
		marketShare: store.marketData,
		competitors: store.competitorSummary,
		FieldDesc: store.fieldDef.fields.hosp
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetSMDCompetitors: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.SMD_COMPETITORS_GET_METRICS,
					providers,
					'hosp',
					Fields.All()
				)
			),
		updateWhereChange: () => dispatch(fieldDefActions.sessionStorageWhereChange()),
		GetFieldInfo: (fields, basis) => dispatch(fieldDefActions.AddNewFields(fields, basis, 'hosp'))
	};
};

class Competitors extends React.Component {
	UNSAFE_componentWillMount() {
		if (this.props.marketShare.fetched === true && this.props.marketShare.fetching === false) {
			let providers = Lib.CompetitorList(
				misc.getFacilityIds(),
				this.props.marketShare.MarketShareData.result[0]
			);
			this.props.GetSMDCompetitors(providers);
			//this.props.loadMarketData(misc.getFacilityYear(), providers);
		}
	}

	render() {
		let datakey = this.props.marketShare.dataKey;
		const bodyRender =
			this.props.marketShare &&
			this.props.marketShare.fetched &&
			this.props.competitors &&
			this.props.competitors.fetched ? (
				RenderCompetitorsPage(
					this.props.prvdr.Providers[0],
					this.props.marketShare.MarketShareData,
					this.props.competitors.Benchmark
				)
			) : (
				<div className="container">
					<div className="row">
						<LoadingSpinner />
					</div>
				</div>
			);

		return <div className="" id="competitors">{bodyRender}</div>;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Competitors);
