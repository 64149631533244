import React from 'react';
import { Panel } from 'react-bootstrap';

const lightText = {
	fontSize: '13px',
	color: 'gray',
	lineHeight: '16px'
};

class DatasetItem extends React.Component {
	constructor(props) {
		super(props);
		this.onDataSetClick = this.onDataSetClick.bind(this);
	}
	onDataSetClick() {
		this.props.onDatasetSelected(this.props.dataset.id);
	}
	render() {
		return (
			<Panel
				className="px-3 py-3 label-secondary col-md-8"
				style={{
					cursor: 'pointer',
					textAlign: 'left',
					paddingBottom: '30px'
				}}
				onClick={this.onDataSetClick}
			>
				<div className="display-subtitle text-primary py-2">{this.props.dataset.title}</div>
				<p>{this.props.dataset.description}</p>
				<span style={lightText}>
					Last Updated Date: {this.props.dataset.last_update_date}
					<br />
					File Source: {this.props.dataset.source}
				</span>
			</Panel>
		);
	}
}

class datasetList extends React.Component {
	render() {
		const items = this.props.archive_datasets.map((dataset, index) => {
			return (
				<DatasetItem
					dataset={dataset}
					key={index}
					onDatasetSelected={this.props.onDatasetSelected}
				/>
			);
		});
		return <div>{items}</div>;
	}
}

export default datasetList;
