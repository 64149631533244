import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SearchBar from '../components/searchBar';

export default class HomePage extends React.Component {
	componentDidMount() {
		document.body.id = 'home';
	}
	componentWillUnmount() {
		document.body.id = '';
	}
	render() {
		return (
			<>
				<Helmet>
					<title>HMP Metrics | Healthcare Management Partners</title>
					<meta
						name="description"
						content="HMP Metrics provides easy-to-use financial, quality, strategic, and market data on 5000+ hospitals and 15000+ skilled nursing facilities to anyone in the industry who needs to make strategic, informed decisions."
					/>
				</Helmet>
				<section id="content">
					<div className="jumbotron">
						<div
							id="metricsCarousel"
							className="carousel slide carousel-fade"
							data-ride="carousel"
							data-touch="true"
						>
							<ol className="carousel-indicators">
								{/* <li className="active" data-target="#metricsCarousel" data-slide-to="0"></li> */}
								<li className="active" data-target="#metricsCarousel" data-slide-to="1"></li>
								<li data-target="#metricsCarousel" data-slide-to="2"></li>
								<li data-target="#metricsCarousel" data-slide-to="3"></li>
							</ol>
							<div className="carousel-inner">
								{/* <div className="carousel-item active" id="carItem0">
									<img
										src="/images/carousel-reports.jpg"
										className="d-block w-100"
										alt="Collage of icons related to reports and meetings"
									/>
									<div className="carousel-caption">
										<h5>At Risk Facility Report</h5>
										<p>Some info about Reports</p>
										<ul className="list-unstyled">
											<li>Selling Point 1</li>
											<li>Selling Point 2</li>
											<li>Selling Point 3</li>
										</ul>
										<a href="/reports">Learn More</a>
									</div>
								</div> */}
								<div className="carousel-item active" id="carItem1">
									<img
										src="/images/carousel-sample01.jpg"
										className="d-block w-100"
										alt="Man in suit with gears in front of him"
									/>
									<div className="carousel-caption">
										<h5>Premium Access</h5>
										<p>Access all Available Data</p>
										<ul className="list-unstyled">
											<li>Access both Hospital and Skilled Nursing Facility Data Sets</li>
											<li>Immediate access to all newly added Datasets</li>
											<li>Access to all available Reports and Tools for ALL Datasets</li>
										</ul>
										<a href="/subscribe">Learn More</a>
									</div>
								</div>
								<div className="carousel-item" id="carItem2">
									<img
										src="/images/carousel-sample02.jpg"
										className="d-block w-100"
										alt="Docotor with stethoscope and medical icons in foreground"
									/>
									<div className="carousel-caption">
										<h5>Hospitals</h5>
										<p>Access all Medicare Hospital Data</p>
										<ul className="list-unstyled">
											<li>Access to all Tools and Reports for the Hospital Dataset</li>
											<li>Access to Data Explorer for the Hospital Data Set</li>
											<li>Downloads of Last 5 Years of Cost Reports in Excel Format</li>
										</ul>
										<a href="/hospital-systems">Learn More</a>
									</div>
								</div>
								<div className="carousel-item" id="carItem3">
									<img
										src="/images/carousel-sample03.jpg"
										className="d-block w-100"
										alt="User on laptop with icons connected to represent the web"
									/>
									<div className="carousel-caption">
										<h5>Skilled Nursing Facilities</h5>
										<p>Access all Medicare SNF Data</p>
										<ul className="list-unstyled">
											<li>Access to all Tools and Reports for the SNF Data Set</li>
											<li>Access to Data Explorer for the SNF Data Set</li>
											<li>Downloads of Last 5 Years of Cost Reports in Excel Format</li>
										</ul>
										<a href="/skilled-nursing">Learn More</a>
									</div>
								</div>
							</div>
							<a
								className="carousel-control-prev"
								href="#metricsCarousel"
								role="button"
								data-slide="prev"
							>
								<span className="carousel-control-prev-icon" aria-hidden="true"></span>
								<span className="sr-only">Previous</span>
							</a>
							<a
								className="carousel-control-next"
								href="#metricsCarousel"
								role="button"
								data-slide="next"
							>
								<span className="carousel-control-next-icon" aria-hidden="true"></span>
								<span className="sr-only">Next</span>
							</a>
						</div>
					</div>
				</section>
				<section id="firstDivBar" className="divBar">
					<div className="container" id="searchField">
						<div className="row">
							<div id="searchFrame" className="col-12">
								<p className="text-center">Find Your Healthcare Provider</p>
								<SearchBar allDomains={true} isHome={true} />
							</div>
						</div>
					</div>
				</section>
				<section id="rightPlace">
					<div className="container text-center">
						<div className="row">
							<div className="col-12">
								<h2>Data you can trust. Data that works for you.</h2>
								<p>Do you know how your facility stacks up against others in your state? How do things work across other facilities in your niche? It's hard to get a handle on these questions without reliable and comprehensive data. That's why we built our own data analytics platform. HMP Metrics allows you to set benchmarks, compare data centers, track trends over time, and understand more about the marketplace. Data is power, and with HMP Metrics, it's in your hands.</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-3 col-sm-6 col-12">
								<div className="iconTile anime slideR">
									<i className="fas fa-notes-medical"></i>
									<h4>700,000+</h4>
									<h5>Medicare Cost Reports</h5>
								</div>
							</div>
							<div className="col-md-3 col-sm-6 col-12">
								<div className="iconTile anime slideU">
									<i className="fas fa-hospital-alt"></i>
									<h4>6,000+</h4>
									<h5>Featured U.S. Hospitals</h5>
								</div>
							</div>
							<div className="col-md-3 col-sm-6 col-12">
								<div className="iconTile anime slideU">
									<i class="fa fa-user-md"></i>
									<h4>17,000+</h4>
									<h5>Skilled Nursing Facilities</h5>
								</div>
							</div>
							<div className="col-md-3 col-sm-12">
								<div className="iconTile anime slideL">
									<i className="fas fa-server"></i>
									<h4>20+ Years</h4>
									<h5>Of Verified Data</h5>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="divBar text-center">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h6>Committed To Helping Our Clients Succeed</h6>
								<p>HMP Metrics&trade; is a powerful data analytics platform that provides insight into the financial and strategic position of healthcare facilities across the continuum. With this insight, providers can better manage their operations and improve patient experience.</p>
							</div>
						</div>
					</div>
				</section>
				<section id="ourTech">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h4>Our Technology</h4>
								<h3>Bring data to every question and every decision.</h3>
								<p>HMP Metrics is a data product that uses a custom electronic database that includes Healthcare Cost Report Information System (HCRIS) data from more than 600,000 Medicare Cost Reports filed by hospitals, nursing homes, home health agencies, and other providers since 1994. Additionally, for hospitals only, the HMP Metrics database includes selected data elements from the Medicare Limited Data Set (100% Standard Analytic Files) database and data sets licensed by HMP.</p>
								<h4>Take our data for a drive.</h4>
								<p>The healthcare industry is one of the most complex in the world. Metrics gives you the strategic insights you need to navigate that complexity and move your business forward.</p>
							</div>
						</div>
					</div>
				</section>
				<section className="divBar text-center">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h6>Setting a New Standard for Data</h6>
								<p>Easy-to-use and customizable, Metrics gives decision makers unparalled business intelligence as they think analytically about their invesment in the delivery of care.</p>
							</div>
						</div>
					</div>
				</section>
				<section id="servSect">
					<div className="container">
						<div className="row text-center">
							<div className="col-12">
								<h2>Comprehensive Reports and Tools</h2>
							</div>
							<div className="col-md-4 col-sm-6 col-12">
								<a href="/hospital/summary/100128">
									<i className="anime slideR"></i>
									<h6>Facility Summary</h6>
									<p>
										Review a summary of a provider's performance over time across a wide range of operational, financial and quality metrics.
									</p>
								</a>
							</div>
							<div className="col-md-4 col-sm-6 col-12">
								<a href="/hospital/financial/100128">
									<i className="anime slideU"></i>
									<h6>Financial Performance</h6>
									<p>
										Compare up to 5 years of financial metrics with detailed income and balance statement insights.
									</p>
								</a>
							</div>
							<div className="col-md-4 col-sm-6 col-12">
								<a href="/hospital/quality/100128">
									<i className="anime slideL"></i>
									<h6>Quality Metrics</h6>
									<p>
										Get detailed insight into a provider's quality against peers on over 100 metrics.
									</p>
								</a>
							</div>
							<div className="col-md-4 col-sm-6 col-12">
								<a href="/hospital/benchmark/100128">
									<i className="anime slideR"></i>
									<h6>Benchmark Report</h6>
									<p>
									Compare a provider's performance against 100's of metrics to other providers against your chosen benchmark.
									</p>
								</a>
							</div>
							<div className="col-md-4 col-sm-6 col-12">
								<a href="/hospital/strategicmarket/100128">
									<i className="anime slideU"></i>
									<h6>Strategic Market Data</h6>
									<p>
										Understand a provider's position with a deep dive into its competition, demographics and market share.
									</p>
								</a>
							</div>
							<div className="col-md-4 col-sm-6 col-12">
								<a href="/hospital/chartlibrary/100128">
									<i className="anime slideL"></i>
									<h6>Data Explorer</h6>
									<p>
										Build and analyze your own custom data report from our centralized reposititory. Choose from thousands of data points to choose the exact report you need.
									</p>
								</a>
							</div>
						</div>
						<div className="row">
							<div className="col-12 text-center">
								<h2>Interested in learning more?</h2>
								<p>
									Talk to a HMP Metrics specialist or join the mailing to stay up to date on the
									latest releases and news.
								</p>
								<div className="bttn text-center">
									<a href="/contact">Contact Us</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="divBar learnMore text-center">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h6>Professional and Experienced</h6>
							</div>
						</div>
					</div>
				</section>
				<section id="apart">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-12">
								<h4 id="firstH">Why Metrics</h4>
								<h3>We help clients remove the barrier between data and decisions</h3>
								<p>At Metrics, we help our clients do a deep dive. Giving them the data, insights and strategic tools they need to move their business and their stakeholders business forward.</p>
								<ol>
									<li>
										<h4>One Stop Data Source</h4>
										<p>Comprehensive database of hospitals and health systems</p>
									</li>
									<li>
										<h4>Clean Data</h4>
										<p>Statistically valid data cleansed of outliers</p>
									</li>
									<li>
										<h4>National Benchmarks</h4>
										<p>
											Options to benchmark against customizable peer groups, locally or nationwide
										</p>
									</li>
									<li>
										<h4>Excellent Track Record</h4>
										<p>
											Thousands of metrics calculate and track financial, operational and quality
											measures
										</p>
									</li>
								</ol>
							</div>
							<div className="col-md-6 col-0"></div>
						</div>
					</div>
				</section>
				{/* 
				<section id="contactSect">
					<div id="map">
						<LocationMap
							lat={28.010965826106702}
							lng={-82.70791562225352}
							facilityName={'HMP Metrics'}
							facilityAddress={'2454 McMullen Booth Rd., Ste 411'}
							facilityCity={'Clearwater'}
							facilityState={'FL'}
							facilityZip={'33759'}
						/>
					</div>
				</section> */}
			</>
		);
	}
}
