import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../tools/datagrid';
import LoadingSpinner from '../../tools/loadingSpinner';
import * as providerActions from '../../core/actions/GlobalActions';
import * as misc from '../../lib/misc';
import NoData from '../../core/components/notifications/NoData'; //this was the error catch on the results.push - this displays if there is no data
import * as SnfActions from './SkilledNursingActions';
import _ from 'lodash';
import $ from 'jquery';

const mapStateToProps = store => {
	return {
		costReports: store.snfCostReportExportService.costReports,
		dataLoaded: store.snfCostReportExportService.fetched,
		dataLoading: store.snfCostReportExportService.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProviderInfo: ids => dispatch(providerActions.findProviders(ids)),
		getFileService: ids => dispatch(SnfActions.snfCostReportExportService(ids))
	};
};

class CostReportExports extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.dataLoaded === false && this.props.dataLoading === false) {
			this.props.getFileService(misc.getFacilityIds());
		}
	}

	componentDidUpdate() {
		$('.MH-InfoTooltip').popover();
	}

	GenerateColumns() {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'date_range',
				MHGrid.DataGridHeaderBuilder('Report Range', 'center', 100),
				'100',
				MHGrid.ColType_Link,
				'url',
				'_self'
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'rpt_rec_num',
				MHGrid.DataGridHeaderBuilder('Report Number', 'center', 75),
				'75',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'fac_name',
				MHGrid.DataGridHeaderBuilder('Facility Name', 'center', 150),
				'150',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'system_name',
				MHGrid.DataGridHeaderBuilder('System Name', 'center', 150),
				'150',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'ownership_type',
				MHGrid.DataGridHeaderBuilder('Ownership Type', 'center', 55),
				'55',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'contractor_number',
				MHGrid.DataGridHeaderBuilder('Contractor Number', 'center', 55),
				'55',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'status_code',
				MHGrid.DataGridHeaderBuilder('Submission Type', 'center', 55),
				'55',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'settlement_amount',
				MHGrid.DataGridHeaderBuilder('Settlement Amount', 'center', 55),
				'55',
				MHGrid.ColType_Money
			)
		);
		return Cols;
	}

	render() {
		let data = this.props.costReports;
		data.forEach(x => {
			x.url =
				misc.apiUrl +
				`/cost-report-export?rptYear=${x.rpt_year}&rptSeries=2010&rptRecNum=${x.rpt_rec_num}&domain=snf`;
		});
		//let datadef = this.props.datadeficiencies;
		if (this.props.dataLoading) {
			return <LoadingSpinner />;
		} else {
			if (this.props.dataLoaded && !this.props.dataLoading) {
				let results = [];
				let _title = <span className="text-nowrap">{`Cost Report Exports`}</span>;
				results.push(
					<MHGrid.DataGrid
						suppressLines={true}
						columns={this.GenerateColumns()}
						rows={data || []}
						// subcategory={_title}
					/>
				);

				return (
					<div className="panel panel-default">
						{results}
						{this.props.children}
					</div>
				);
			} else {
				return <NoData />;
			}
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CostReportExports);
