import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import { Grid, Row, Col, Panel } from 'react-bootstrap';
import TrendChart from './TrendChart';
import QuartileChart from '../../tools/charts/quartile-chart';
import * as fieldDefActions from './chartPageAction';
import * as misc from '../../lib/misc';
import Heading from '../../core/components/Heading';
import _ from 'lodash';
import Auth from '../../core/Security/Auth';
import LandingPage from '../../core/DefaultPages/marketingInfo/HospChartToolInfo';
import DataGridQuartile from '../../tools/datagrid/extras/DataGridQuartile';
import DataGridFooterNotation from '../../tools/datagrid/extras/DataGridFooterNotation';
import FacilityCompare from '../../core/components/facilityCompare';
import { sessionStorageWhereChange } from '../../core/actions/GlobalActions';
import * as benchmarkfields from '../Benchmark/BenchmarkFields';
import * as financialfields from '../Financial/FinancialFields';
import * as globalActions from '../../core/actions/GlobalActions';

const fields = [
	'days_in_ar',
	'operating_cost_per_aob',
	'operating_margin',
	'operating_rev_per_aob'
];

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		quartileChartData: store.quartileChartData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetQuartileChartData: (FieldNames, ProviderIds) => {
			dispatch(fieldDefActions.QuartileFieldList(FieldNames, ProviderIds));
		},
		GetFieldInfo: (fields, basis) => {
			dispatch(globalActions.AddNewFields(fields, basis, 'hosp'));
		}
	};
};

class ChartPage extends React.Component {
	constructor(props) {
		super(props);
		if (!sessionStorage['competitors']) {
			sessionStorage.setItem('competitors', []);
		}
	}

	UNSAFE_componentWillMount() {
		this.props.GetFieldInfo(benchmarkfields.All(), this.props.FieldDesc);
		this.props.GetFieldInfo(financialfields.All(), this.props.FieldDesc);
		this.props.GetQuartileChartData(fields, misc.getFOI_CompetitorsIds());
	}
	componentDidUpdate() {
		if (this.props.quartileChartData.whereChanged) {
			this.props.GetQuartileChartData(fields, misc.getFOI_CompetitorsIds());
		}
	}

	getField(index) {
		let fld = _.find(this.props.FieldDesc, x => {
			return x.FieldName === fields[index];
		});
		return fld;
	}

	chartData(index) {
		return this.props.quartileChartData.data ? this.props.quartileChartData.data[index] : null;
	}

	getAddCompetitors() {
		this.props.dispatch(sessionStorageWhereChange());
	}

	render() {
		// const cssPath = './summarystyle.css';

		let detail = null;
		let fieldList =
			this.props.FieldDesc.length > 0
				? _.uniqBy(_.filter(this.props.FieldDesc, fld => !!fld.FieldIncludePopUp), 'FieldName')
				: [];

		if (Auth.isUserHospAuthenticated()) {
			document.body.id = 'facSummary';
			detail = (
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2>Chart Tool</h2>
						</div>
					</div>
					<div className="row" id="trendChart">
						{!!this.props.FieldDesc && <TrendChart FieldDesc={fieldList} />}
					</div>
					<DataGridQuartile domain={'hosp'}>
						<FacilityCompare changeComparison={this.getAddCompetitors} />
					</DataGridQuartile>
					<hr />
					<div className="row charts">
						<div className="col-md-6 col-12">
							{!!this.props.FieldDesc && this.props.FieldDesc.length > 0 && (
								<QuartileChart
									FieldDef={this.getField(0)}
									ChartData={this.chartData(0)}
									Fetching={this.props.quartileChartData.fetching}
								/>
							)}
						</div>
						<div className="col-md-6 col-12">
							{!!this.props.FieldDesc && this.props.FieldDesc.length > 0 && (
								<QuartileChart
									FieldDef={this.getField(1)}
									ChartData={this.chartData(1)}
									Fetching={this.props.quartileChartData.fetching}
								/>
							)}
						</div>
					</div>
					<hr />
					<div className="row charts">
						<div className="col-md-6 col-12">
							{!!this.props.FieldDesc && this.props.FieldDesc.length > 0 && (
								<QuartileChart
									FieldDef={this.getField(2)}
									ChartData={this.chartData(2)}
									Fetching={this.props.quartileChartData.fetching}
								/>
							)}
						</div>
						<div className="col-md-6 col-12">
							{!!this.props.FieldDesc && this.props.FieldDesc.length > 0 && (
								<QuartileChart
									FieldDef={this.getField(3)}
									ChartData={this.chartData(3)}
									Fetching={this.props.quartileChartData.fetching}
								/>
							)}
						</div>
					</div>
					<DataGridFooterNotation />
				</div>
			);

			return (
				<Fragment>
					<PageNavigation selectedTab="7" domain="hosp" />
					<section id="content">{detail}</section>
				</Fragment>
			);
		} else {
			document.body.id = 'landingPage';
			return <LandingPage domain="hosp" />;
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ChartPage);
