import React from 'react';
import './index.css';

const BuildItemText = (text, active = false) => {
	let carouselClassName = active ? 'carousel-item active ' : 'carousel-item ';
	let textClassName = 'carousel-text h-100 m-auto align-items-center justify-content-center';

	return (
		<div className={carouselClassName}>
			<div className={textClassName}>
				<h1>{text}</h1>
			</div>
		</div>
	);
};

const defaultItems = [
	BuildItemText('Comprehensive database of hospitals and health systems', true),
	BuildItemText('Analyze macro sector trends to assess individual facilities or systems'),
	BuildItemText(
		'Thousands of metrics calculate and track financial, operational and quality measures'
	),
	BuildItemText('Options to benchmark against customizable peer groups, locally or nationwide'),
	BuildItemText('Statistically valid data cleansed of outliers'),
	BuildItemText('State-of-the-art Chart Library'),
	BuildItemText('Unlimited users with a single login'),
	BuildItemText(
		'Strategic Market Data provides unparalleled insight into market share, competition, and demographics'
	)
];

const CarouselIndicators = () => {
	let result = [];
	for (let i = 0; i < defaultItems.length; i++) {
		if (i == 0)
			result.push(
				<li
					data-target="#carousel-sets-us-apart-Indicators"
					data-slide-to={i.toString()}
					className="active"
				/>
			);
		else
			result.push(
				<li data-target="#carousel-sets-us-apart-Indicators" data-slide-to={i.toString()} />
			);
	}
	return result;
};

const SetsUsApart = ({ items = defaultItems }) => {
	return (
		<div id="sets-us-apart" className="d-flex-inline">
			<h3 className="title text-center pt-5">WHAT SETS US APART</h3>
			<div
				id="carousel-sets-us-apart-Indicators"
				className="carousel slide  carousel-container text-center"
				data-ride="carousel"
			>
				<div className="carousel-inner">{defaultItems}</div>
				<a
					className="carousel-control-prev"
					href="#carousel-sets-us-apart-Indicators"
					role="button"
					data-slide="prev"
				>
					<span className="carousel-control-prev-icon" aria-hidden="true" />
					<span className="sr-only">Previous</span>
				</a>
				<a
					className="carousel-control-next"
					href="#carousel-sets-us-apart-Indicators"
					role="button"
					data-slide="next"
				>
					<span className="carousel-control-next-icon" aria-hidden="true" />
					<span className="sr-only">Next</span>
				</a>
				{/* <ol className="carousel-indicators mt-1 ">{CarouselIndicators()}</ol> */}
			</div>
		</div>
	);
};

export default SetsUsApart;
