import React from 'react';

export default class Heading extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let children = this.props.children ? (
			<div className="pull-right ">{this.props.children}</div>
		) : (
			''
		);
		let primary = this.props.primary || false;
		let fontGroupSize = this.props.fontGroupSize || '18px';
		let heading = this.props.heading || '';
		return (
			<h4 className="facHeading">
				{heading}
				{children}
			</h4>
		);
	}
}
