import React from 'react';
import { connect } from 'react-redux';
// import { Button } from 'react-bootstrap';
import { faExternalLinkAlt } from '@fortawesome/fontawesome-free-solid';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import * as MHGrid from '../index';
import ReChartTool from '../../charts/ReChartTool';
import * as fieldDefActions from './quartileFieldReducer';
import * as misc from '../../../lib/misc';
import JsonUtil from '../../../lib/JsonUtil';
import LoadingSpinner from '../../../tools/loadingSpinner';
import * as quartileFormat from '../../../core/styles/CustomStyle';
import _ from 'lodash';
import Auth from '../../../core/Security/Auth';
import { exportDomToImage } from '../../../lib/ExportUtil';

const mapStateToProps = store => {
	return {
		GraphData: store.quartilePopup
	};
};
const mapDispatchToProps = dispatch => {
	return {
		GetQuartileData: (FieldId, ProviderIds, Segment) =>
			dispatch(fieldDefActions.QuartileField(FieldId, ProviderIds, Segment))
	};
};

// const convertResultData = (data, row, column, metric) => {};

class QuartileModal extends React.Component {
	constructor(props) {
		super(props);
		this.onExport = this.onExport.bind(this);
	}

	onExport() {
		exportDomToImage('quartile_popup_details', this.props.FieldDef.FieldShortName);
	}

	getQuartileData() {
		this.props.GraphData.Data = null;
		this.props.GraphData.fetching = true;
		this.props.GetQuartileData(
			this.props.FieldDef.FieldId,
			misc.getFOI_CompetitorsIds(),
			misc.getSegment()
		);
	}
	UNSAFE_componentWillMount() {
		this.getQuartileData();
	}
	componentDidUpdate() {
		if (this.props.GraphData.whereChanged) {
			this.getQuartileData();
		}
	}
	BuildPivotGrid(headers, data, facilityInfo) {
		let Cols = new MHGrid.DataGridColumns();
		if (data) {
			for (let i = 0; i < headers.length; i++) {
				Cols.Add(
					new MHGrid.DataGridColumn(
						String(headers[i]),
						new MHGrid.DataGridHeader(headers[i], '', '', 'center'),
						i == 0 ? '250' : '',
						MHGrid.ColType_Self
					)
				);
			}
			let facilityID = misc.getFOIandCompetitors();
			data = data.map(a => {
				let b = a;
				let key = _.find(facilityID, c => (c.id || c.prvdr_num) === a['Facility'].value);
				if (key) {
					b['Facility'].value = key.name || key.facility_name;
				}
				return b;
			});
		}
		return <MHGrid.DataGrid columns={Cols} rows={data ? data : []} />;
	}

	PivotGridData(pivotData) {
		let pivotTable = [];
		let facilityID = misc.getFOIandCompetitors();
		let defaultSeries = [];
		let header = [];
		for (let i = 1; i < pivotData.length; i++) {
			let record = {};
			header = pivotData[0];
			header = header.map(a => {
				let b = a;
				let key = _.find(facilityID, c => (c.id || c.prvdr_num) === b);
				if (key) {
					b = key.name || key.facility_name;
				}
				return b;
			});
			let t = 0;
			// if(_.sum(_(_.omit(record,'Year')).mapValues(x => x)) != 0)
			for (let j = 0; j < pivotData[i].length; j++) {
				let value = pivotData[i][j];
				if (header[j] !== 'Year') {
					if (
						String(header[j])
							.toLowerCase()
							.indexOf('quartile') > -1
					) {
						defaultSeries[t] = {
							xAxis: 'Year',
							xLabel: '',
							yLabel: '',
							type: 'ThinLine',
							name: header[j]
						};
						if (
							String(header[j])
								.toLowerCase()
								.indexOf('quartile 1') > -1
						) {
							defaultSeries[t].color = quartileFormat.Quartile1;
							defaultSeries[t].yLabel = 'Q1';
						}
						if (
							String(header[j])
								.toLowerCase()
								.indexOf('quartile 2') > -1
						) {
							defaultSeries[t].color = quartileFormat.Quartile2;
							defaultSeries[t].yLabel = 'Q2';
						}
						if (
							String(header[j])
								.toLowerCase()
								.indexOf('quartile 3') > -1
						) {
							defaultSeries[t].color = quartileFormat.Quartile3;
							defaultSeries[t].yLabel = 'Q3';
						}
						if (
							String(header[j])
								.toLowerCase()
								.indexOf('quartile 4') > -1
						) {
							defaultSeries[t].color = quartileFormat.Quartile4;
							defaultSeries[t].yLabel = 'Q4';
						}
					} else {
						defaultSeries[t] = {
							xAxis: 'Year',
							xLabel: '',
							yLabel: '',
							type: 'OutlineBar',
							name: header[j],
							color: quartileFormat.SeriesArray[t]
						};
					}
					t++;
				}
				record[header[j]] = value;
			}
			pivotTable[i - 1] = record;
		}
		defaultSeries = _.filter(defaultSeries, e => e);

		return { table: pivotTable, series: defaultSeries };
	}

	TransposeData(dataResult, fieldNameQuartile) {
		let gridIt = JsonUtil.getPivotArray(
			'Facility',
			dataResult,
			'hospital_name',
			'fy_year',
			fieldNameQuartile
		);
		let pivotGrid = [];
		let pivotHeader = gridIt[0];
		for (let i = 1; i < gridIt.length; i++) {
			let record = {};
			for (let j = 0; j < gridIt[i].length; j++) {
				let value = gridIt[i][j];
				record[pivotHeader[j]] = {};
				record[pivotHeader[j]].value = value;
				record[pivotHeader[j]].format = j === 0 ? 'TEXT' : this.props.FieldDef.FieldFormat;
			}
			pivotGrid[i] = record;
		}
		return { grid: pivotGrid, header: pivotHeader };
	}

	render() {
		const fieldNameQuartile = this.props.FieldDef.FieldName + '_avg';
		const fieldDescription = this.props.FieldDef.FieldDescription;
		const dataResult = _.flatten(
			_(
				this.props.GraphData.fetched && this.props.GraphData.Data && this.props.GraphData.Data.Data
					? this.props.GraphData.Data.Data.rows
					: []
			)
				.groupBy('fy_year')
				.map((v, k) => ({ val: v, aggregate: _.sumBy(v, fieldNameQuartile) }))
				.filter(a => a.aggregate != 0)
				.map(a => a.val)
				.value()
		);
		const pivotData = JsonUtil.getPivotArray(
			'Year',
			dataResult,
			'fy_year',
			'hospital_name',
			fieldNameQuartile
		);
		let pGrid = this.PivotGridData(pivotData);
		let tdata = this.TransposeData(dataResult, fieldNameQuartile);
		const dataGrid = this.BuildPivotGrid(tdata.header, tdata.grid);
		const chartGraph = this.props.GraphData.fetched ? (
			<ReChartTool
				series={pGrid.series}
				data={pGrid.table}
				height={300}
				YAxis={String(this.props.FieldDef.FieldFormat).toLowerCase()}
				ShowLegend={true}
			/>
		) : (
			''
		);
		let customBenchmark = JSON.parse(sessionStorage.getItem('customBenchmark'));
		let benchmarkCallout =
			!customBenchmark || (Array.isArray(customBenchmark) && customBenchmark.length < 1)
				? 'Quartile based on National Benchmark'
				: 'Quartile based on Custom Benchmark';
		const Definition = (
			<div className="col">
				<b>Definition: </b>
				<i>{fieldDescription}</i>
			</div>
		);

		const report = this.props.GraphData.fetching ? (
			<LoadingSpinner />
		) : (
			<div>
				<div id="quartile_popup_details">
					<h4 className="text-center yp-1">{this.props.FieldDef.FieldShortName}</h4>
					<div className="quartile-chart">{chartGraph}</div>
					<div className="quartile-grid text-right">
						<b>
							<i>Note: {benchmarkCallout}</i>
						</b>
					</div>
					<div className="quartile-grid text-right">{dataGrid}</div>
					<div className="quartile-def mt-1 d-block">{Definition}</div>
				</div>
				<button
					className="btn btn-xs py-0 my-0 px-1 pull-right"
					onClick={this.onExport}
					style={{ backgroundColor: '#4FB591', color: 'white', borderRadius: '0' }}
				>
					<div className="row mx-1">
						<div
							className="pl-1 m-1 d-none d-sm-flex"
							style={{ fontSize: '7pt', fontWeight: '600' }}
						>
							<FontAwesomeIcon icon={faExternalLinkAlt} className="fa fa-lg p-0 m-0" />
							Export
						</div>
					</div>
				</button>
			</div>
		);

		return <div className="">{report}</div>;
	}
}

const QModal = connect(
	mapStateToProps,
	mapDispatchToProps
)(QuartileModal);

class QuartileTooltip extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			quartilePopup: null
		};
		this.QuartileTooltipClick = this.QuartileTooltipClick.bind(this);
		this.QuartileCloseClick = this.QuartileCloseClick.bind(this);

		const { fieldDef: { FieldIncludePopUp = false } = {} } = props;
		this.CanPopup = FieldIncludePopUp;
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick, false);
	}

	handleClick = e => {
		if (this.node && this.state.showModal == true) {
			if (this.node.contains(e.target)) {
				return;
			}
			this.QuartileCloseClick();
		}
	};

	QuartileTooltipClick(e) {
		this.setState({
			showModal: true,
			quartilePopup: <QModal FieldDef={this.props.fieldDef} />
		});
	}

	QuartileCloseClick() {
		this.setState({
			showModal: false,
			quartilePopup: null
		});
	}

	render() {
		if (this.CanPopup === true && Auth.isUserAuthenticated() === true) {
			const id = misc.Guid();
			const ModelTarget = '#' + id;

			const fieldTitle = this.props.fieldDef ? this.props.fieldDef.FieldShortName : '';

			return (
				<div className="d-none d-sm-block">
					<i
						className="fa fa-bar-chart "
						aria-hidden="true"
						style={{
							cursor: 'pointer',
							marginLeft: '5px',
							marginRight: '5px'
						}}
						onClick={this.QuartileTooltipClick}
						title={'Chart ' + (fieldTitle || '') + ' Quartiles'}
						data-toggle="modal"
						data-target={ModelTarget}
					/>
					<div className="modal" id={id}>
						<div className="modal-dialog">
							<div className="modal-content bg-primary" ref={node => (this.node = node)}>
								<div className="modal-header py-2 bg-dark text-white border-0 rounded-0">
									{/* <h4 className="modal-title pl-1 py-0">{fieldTitle}</h4> */}
									<button type="button" className="close d-flex text-white" data-dismiss="modal">
										&times;
									</button>
								</div>
								<div className="modal-body bg-light">
									<div className="m-3 text">{this.state.quartilePopup}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default QuartileTooltip;
