import React, {Fragment} from 'react';
import * as Util from '../../lib/FormatUtil';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import InfoTooltip from './extras/infoTooltip';
import QuartileTooltip from './extras/quartile-tooltip';
import FieldEdit from '../../core/components/fieldDefModel';
import YearSelect from './year-select';
import Auth from '../../core/Security/Auth';
import _ from 'lodash';
import * as misc from '../../lib/misc';
import * as CustomCSS from '../../core/styles/CustomStyle';
import './index.css';
import ResponsiveClass from '../../tools/MobileResponsiveClass';
import MobilePopup from './extras/DataGridMobilePopup';

// Custom Formatter component
// react-grid-Header

const ColType_Text = 'TEXT';
const ColType_Percent = 'PERCENT';
const ColType_PercentInt = 'PERCENTINT';
const ColType_PercentNoShift = 'PERNOSHIFT';
const ColType_PercentBar = 'PERCENTBAR';
const ColType_Number = 'NUMBER';
const ColType_NumberDec = 'NUMBERDEC';
const ColType_Money = 'MONEY';
const ColType_MoneyDec = 'MONEYDEC';
const ColType_SparkLine = 'SPARKLINE';
const ColType_Self = 'SELF_DEFINED';
const ColType_Metric = 'METRIC';
const ColType_Integer = 'INTEGER';
const ColType_MultiYear = 'MULTIYEAR';
const ColType_MultiYearTitle = 'MULTIYEARTITLE';
const ColType_Link = 'LINK';
const ColType_5Star = '5STAR';

const STYLE_TOTAL_LINE = 'TOTAL_LINE';
const STYLE_DOUBLE_LINE = 'DOUBLE_LINE';
const STYLE_BOLD = 'BOLD';
const STYLE_UNDERLINE = 'UNDERLINE';
const STYLE_ITALIC = 'ITALIC';
const STYLE_BOLD_UNDERLINE = 'BOLD-UNDERLINE';
const STYLE_BOLD_ITALIC = 'BOLD-ITALIC';
const STYLE_LINE_SHADE = 'LINE_SHADE';

let titleStyle = {
	color: 'black',
	display: 'block'
};

let headerStyle = {
	color: 'white',
	fontSize: '12pt',
	fontWeight: 'bold',
	paddingLeft: '10px',
	paddingRight: '10px',
	// "font-family": "Verdana",
	display: 'block'
};

// td.section-header {
//     position: relative;
//     backgroundColor: #f1f1f1;
//     padding: 10px;
//   }

const textPosition = position => {
	switch (position) {
		case 'center':
			return 'text-center';
		case 'left':
			return 'text-left';
		case 'right':
			return 'text-right';
		default:
			return 'text-center';
	}
};
//----------Formatters ---------------------------------------------------------
const PercentCompleteFormatter = value => {
	const percentComplete = value + '%';
	return (
		<div className="progress" style={{ marginTop: '20px' }}>
			<div
				className="progress-bar"
				role="progressbar"
				aria-valuenow="60"
				aria-valuemin="0"
				aria-valuemax="100"
				style={{ width: percentComplete, color: 'black' }}
			>
				{percentComplete}
			</div>
		</div>
	);
};

const FiveStarFormatter = value => {
	let starCount = Math.floor(misc.toNumeric(value));
	let partStar = misc.toNumeric(value) % 1 === 0 ? false : true;
	let display = partStar ? value.toFixed(1) : starCount;

	let result = [];

	for (let i = 0; i < starCount && i < 5; i++) {
		result.push(<i className="fa fa-star ml-1" style={{ color: '#304771' }} />);
	}
	if (partStar && starCount < 5)
		result.push(<i className="fa fa-star-half ml-1" style={{ color: '#304771' }} />);

	for (let i = result.length; i < 5; i++)
		result.push(<i className="fa fa-star-o  ml-1" style={{ color: 'DarkGray' }} />);

	return (
		<div>
			{result}
			<span className="pl-1">({display})</span>
		</div>
	);
};

const SparklineFormatter = value => {
	let length = value.length - 1;
	let depth = value[length].length;
	let result = [];

	if (depth && depth > 0) {
		for (let i = 0; i < depth; i++) {
			let color = CustomCSS.SeriesArray[i];
			let data = value.map(a => {
				return a.length > i ? (_.isNumber(a[i]) ? a[i] : null) : 0;
			});
			if (
				data.filter(function(el) {
					return el != null;
				}).length > 0
			)
				result.push(
					<Sparklines data={data}>
						<SparklinesLine color={color} style={{ strokeWidth: 2, height: '40px' }} />
					</Sparklines>
				);
		}
		let blankdata = value.map(a => {
			return 0;
		});
		result.push(
			<Sparklines data={blankdata}>
				<SparklinesLine color="transparent" height={18} style={{ fill: 'none' }} />
			</Sparklines>
		); //Fix for National Sparkline
	} else {
		let color = CustomCSS.SeriesArray[9];
		result.push(
			<Sparklines data={value}>
				<SparklinesLine color={color} height={24} style={{ strokeWidth: 2 }} />
			</Sparklines>
		);
	}
	return <div className="sparkline-stacked">{result}</div>;
};

const TextFormatter = (value, position = 'left') => {
	let _className = textPosition(position);
	return (
		<div style={{}} className={_className}>
			{value}
		</div>
	);
};

const MetricFormatter = (fieldDef, value, editMode = false) => {
	return (
		<div className={'d-flex ' + textPosition('left')}>
			<div className="">{value}</div>
			{editMode === true ? (
				<div className="bg-white">
					<FieldEdit fieldDef={fieldDef} />
				</div>
			) : (
				''
			)}
			{fieldDef ? (
				<div className="">
					<InfoTooltip fieldDef={fieldDef} />
				</div>
			) : (
				''
			)}
			{fieldDef ? (
				<div className="">
					<QuartileTooltip fieldDef={fieldDef} />
				</div>
			) : (
				''
			)}
		</div>
	);
};

const PercentFormatter = value => {
	if (value && value !== '') return Util.getPercent(value);
	else return '-';
};
const PercentFormatterInt = value => {
	if (value && value !== '') return Util.getPercent(value, 0);
	else return '-';
};

const PercentNoShiftFormatter = value => {
	if (value && value !== '') return Util.getPercentNoShift(value);
	else return '-';
};

const IntegerFormatter = value => {
	if (value && value !== '') return Util.getInt(value);
	else return '-';
};

const NumberFormatter = (value, showDecimal = null) => {
	if (value && value !== '') return Util.getDecimal(value, showDecimal);
	else return '-';
};
const MoneyFormatter = value => {
	if (value && value !== '') return Util.formatDollar(value, false);
	else return '-';
};
//Multi Year National Else
const MultiYear = value => {
	let national = Object.assign({ value: value.national }, value);

	return (
		<div className="">
			{value.foi.map(a => {
				let key = Object.assign({ value: a }, value);
				return <div className="row-flex p-0 m-0 ">{SelfDecorated(a)}</div>;
			})}
			<div className="row-flex p-0 m-0">{SelfDecorated(national, true)} </div>
		</div>
	);
};
const SelfDecorated = (value, hideAttr = false) => {
	let _position = 'right';
	let result = value;
	let _formatedValue = '-';
	let bgColor = 'bg-white';
	if (result && result.ntile && !hideAttr) {
		if (Number(result.ntile) === 1) bgColor = 'mh-Q1';
		if (Number(result.ntile) === 2) bgColor = 'mh-Q2';
		if (Number(result.ntile) === 3) bgColor = 'mh-Q3';
		if (Number(result.ntile) === 4) bgColor = 'mh-Q4';
	}

	// _formatedValue = result ? result.value : null;

	if (result) {
		switch (result.format) {
			case ColType_Integer:
				_formatedValue = IntegerFormatter(result.value);
				break;
			case ColType_Percent:
				_formatedValue = PercentFormatter(result.value);
				break;
			case ColType_PercentInt:
				_formatedValue = PercentFormatterInt(result.value);
				break;
			case ColType_PercentNoShift:
				_formatedValue = PercentNoShiftFormatter(result.value);
				break;
			case ColType_Number:
				_formatedValue = NumberFormatter(result.value, false);
				break;
			case ColType_NumberDec:
				_formatedValue = NumberFormatter(result.value, true);
				break;
			case ColType_Money:
				_formatedValue = Util.formatDollar(result.value, false);
				break;
			case ColType_MoneyDec:
				_formatedValue = Util.formatDollar(result.value, true);
				break;
			case ColType_5Star:
				_formatedValue = FiveStarFormatter(result.value);
				break;
			case ColType_Text:
			case ColType_Link:
				_formatedValue = result.value;
				break;
			default:
				if (isNaN(result.value)) {
					_formatedValue = result.value || '-';
				} else {
					_formatedValue = NumberFormatter(result.value);
				}
				break;
		}
	}

	let clsName = textPosition(_position) + ' ' + bgColor;

	if (_formatedValue && _formatedValue.length < 1) {
		_formatedValue = '-';
	}
	if (result && result.outlier && !hideAttr) {
		return (
			<div
				style={{}}
				data-toggle={'tooltip'}
				title={'Metric flagged as outlier.'}
				className={clsName}
			>
				<b>** </b>
				{_formatedValue}
			</div>
		);
	} else {
		return (
			<div style={{}} className={clsName}>
				{_formatedValue}
			</div>
		);
	}
};

//------------------------------------------------------------------------------

const BuildClassName = format => {
	let _className = '';
	switch (format) {
		case ColType_Text:
		case ColType_Link:
			_className += textPosition('left');
			break;
		case ColType_Money:
		case ColType_MoneyDec:
		case ColType_Number:
		case ColType_NumberDec:
		case ColType_Integer:
		case ColType_Percent:
		case ColType_PercentInt:
		case ColType_SparkLine:
		case ColType_PercentNoShift:
		case ColType_5Star:
			_className += textPosition('right');
			break;
		default:
			_className += textPosition('center');
			break;
	}

	_className += ' small';

	return _className;
};

const FormatValue = (format, value, tag = null, options = null) => {
	switch (format) {
		case ColType_PercentBar:
			return PercentCompleteFormatter(value);
		case ColType_Percent:
			return <span> {PercentFormatter(value)}</span>;
		case ColType_PercentInt:
			return <span> {PercentFormatterInt(value)}</span>;
		case ColType_PercentNoShift:
			return <span> {PercentNoShiftFormatter(value)}</span>;
		case ColType_Integer:
			return <span> {IntegerFormatter(value)}</span>;
		case ColType_Number:
			return <span> {NumberFormatter(value, false)}</span>;
		case ColType_NumberDec:
			return <span> {NumberFormatter(value, true)}</span>;
		case ColType_Money:
			return <span> {MoneyFormatter(value)}</span>;
		case ColType_MoneyDec:
			return <span> {MoneyFormatter(value, true)}</span>;
		case ColType_SparkLine:
			return SparklineFormatter(value);
		case ColType_Self:
			return SelfDecorated(value);
		case ColType_Link:
			if (tag != null) {
				return (
					<a href={tag} target={options ? options : '_blank'} style={{ color: 'black' }}>
						{value}
						<i
							className="ml-2 MH-InfoTooltip font-awesome fa fa-external-link"
							style={{ color: 'black' }}
							role="button"
							data-toggle="popover"
							data-trigger="hover"
							data-content={'Open ' + (value || '') + ' in a new Window'}
						/>
					</a>
				);
			} else {
				return <span>{TextFormatter(value)}</span>;
			}
		case ColType_MultiYear:
			return MultiYear(value);
		case ColType_5Star:
			return <span>{FiveStarFormatter(value)}</span>;
		case ColType_Text:
		default:
			return <Fragment>{TextFormatter(value)}</Fragment>;
	}
};
const CalculateParts = (goal, start, parts) => {
	let result = { score: 0, lines: [] };

	parts.forEach(x => {});

	return result;
};
const DataGridHeaderBuilder = (headerText, position = '', width = 100) => {
	let lineGoal = Util.toInt(width) / 10;
	let len = headerText ? headerText.length : 0;
	let words = headerText ? headerText.split(' ') : [];
	let parts = words.map(x => ({ value: x, len: x.length, weight: x.length / len }));

	let headers = ['', '', ''];
	let headerLine = 0;

	parts.forEach(x => {
		// headerLine must stay under 3 (0-2)
		if (
			headers[headerLine].length > 0 &&
			headers[headerLine].length + x.value.length >= lineGoal &&
			headerLine < 2
		) {
			headerLine++;
		}
		headers[headerLine] += (headers[headerLine].length > 0 ? ' ' : '') + x.value;
	});

	return new DataGridHeader(headers[0], headers[1], headers[2], position);
};
const DataGridHeader = class DataGridHeader {
	constructor(
		header1 = '',
		header2 = '',
		header3 = '',
		position = '',
		submenu = null,
		hyperlink = null
	) {
		this.header1 = header1;
		this.header2 = header2;
		this.header3 = header3;
		this.submenu = submenu;
		this.subControl = this.submenu != null;
		this.header1Size = 6;
		this.header2Size = 6;
		this.header3Size = 6;
		this.link = hyperlink;
		if (position === '') this.position = 'left';
		else this.position = position;
	}

	set Size1(value) {
		this.header1Size = value;
	}

	set Size2(value) {
		this.header2Size = value;
	}

	set Size3(value) {
		this.header3Size = value;
	}

	set Header1(value) {
		this.header1 = value;
	}

	set Header2(value) {
		this.header2 = value;
	}

	set Header3(value) {
		this.header3 = value;
	}

	set Position(value) {
		this.position = value;
	}

	get NumLines() {
		return this.header3 !== '' ? 3 : this.header2 !== '' ? 2 : 1;
	}
};
const DataGridTitle = class DataGridTitle extends DataGridHeader {
	constructor(
		header1 = '',
		header2 = '',
		header3 = '',
		initSize = 3,
		years = [],
		yearSelectCallback = null,
		showAllYears = true,
		showLatest = false
	) {
		super(header1, header2, header3);
		this.header1Size = initSize;
		this.header2Size = initSize;
		this.header3Size = initSize;

		this.years = years;
		this.yearSelectCallback = yearSelectCallback;
		this.showAllYears = showAllYears;
		this.showLatest = showLatest;

		this.Position = 'center';
		this.position = 'center';
	}

	getYearSelect() {
		if (this.years && this.years.length > 0) {
			return (
				<Fragment>
					<YearSelect
						className=""
						years={this.years}
						callback={this.yearSelectCallback}
						showAllYears={this.showAllYears}
						showLatest={this.showLatest}
					/>
				</Fragment>
			);
		} else return <div className="col-lg-1" />;
	}

	render = () => {
		if (this.years && this.years.length > 0) {
			return (
				<div className="row">
					<div className='col-lg-12 title-select'>
						{CreateTitle(this)}
						{this.getYearSelect()}
					</div>
				</div>
			);
		} else {
			return (
				<div className="row">
					<div className={'col-lg-12 ' + textPosition(this.position)}>{CreateTitle(this)} </div>
				</div>
			);
		}
	};
	renderHeading = () => {
		return (
			<div className="row">
				<div className="col-md-10 col-sm-8 col-12">
					<h2>
						{this.header1}
					</h2>
				</div>
				<div className="col-md-2 col-sm-4 col-12 hospYrSel">
					{this.getYearSelect()}
				</div>
			</div>
		);
	};
};

const CreateTitle = (rec, totalLines = 1) => {
	let NumLines = 1;
	if (
		rec instanceof DataGridTitle ||
		rec instanceof DataGridColumn ||
		rec instanceof DataGridHeader
	) {
		let rows = [];

		NumLines = rec.header3 !== '' ? 3 : rec.header2 !== '' ? 2 : 1;

		if (rec.header1 !== '')
			rows.push(
				<h4 className="tblHead">{rec.header1}</h4>
			);
		if (rec.header2 !== '') {
			rows.push(
				<h5 className="tblHead">{rec.header2}</h5>
			);
		}
		if (rec.header3 !== '') {
			rows.push(
				<h6 className="tblHead">{rec.header3}</h6>
			);
		}

		for (let i = NumLines; i < totalLines; i++) {
			rows.unshift(<br />);
		}

		return (
			<Fragment>
				{rows}
			</Fragment>
		);
	} else
		return (
			<Fragment>
				{rec}
			</Fragment>
		);
};

const InlineHeader = rec => {
	let Result = '';

	if (
		rec instanceof DataGridTitle ||
		rec instanceof DataGridColumn ||
		rec instanceof DataGridHeader
	) {
		if (rec.header1 && rec.header1 !== '') Result += rec.header1;
		if (rec.header2 && rec.header2 !== '') Result += ' ' + rec.header2;
		if (rec.header3 && rec.header3 !== '') Result += ' ' + rec.header3;
	}

	return Result;
};
const CreateHeader = (rec, totalLines = 1) => {
	let NumLines = 1;
	if (
		rec instanceof DataGridTitle ||
		rec instanceof DataGridColumn ||
		rec instanceof DataGridHeader
	) {
		let rows = [];
		NumLines = rec.header3 !== '' ? 3 : rec.header2 !== '' ? 2 : 1;

		if (rec.header1 !== '' || (rec instanceof DataGridHeader && rec.subControl))
			rows.push(
				<span className={'h' + rec.header1Size.toString()} style={{ fontSize: 14 }}>
					{rec.submenu}
					{rec.header1}
				</span>
			);
		if (rec.header2 !== '') {
			rows.push(<br />);
			rows.push(
				<span className={'h' + rec.header2Size.toString()} style={{ fontSize: 14 }}>
					{rec.header2}
				</span>
			);
		}
		if (rec.header3 !== '') {
			rows.push(<br />);
			rows.push(
				<span className={'h' + rec.header3Size.toString()} style={{ fontSize: 14 }}>
					{rec.header3}
				</span>
			);
		}
		if (!(rec instanceof DataGridHeader && rec.subControl)) {
			for (let i = NumLines; i < totalLines; i++) {
				rows.unshift(<br />);
			}
		}
		if (rec instanceof DataGridHeader && rec.link != null) {
			return (
				<a className="" href={rec.link} target="_blank">
					<div style={headerStyle} className={textPosition(rec.position)}>
						{rows}
					</div>
				</a>
			);
		}
		return (
			<div style={headerStyle} className={textPosition(rec.position)}>
				{rows}
			</div>
		);
	} else
		return (
			<div style={headerStyle} className="h6">
				{rec}
			</div>
		);
};

const DataGridColumn = class DataGridColumn {
	constructor(key, title, width = 100, format = ColType_Text, tag = null, options = null) {
		this.key = key;
		this.name = key;
		this.width = width;
		this.title = title;
		this.format = format;
		this.tag = tag;
		this.options = options;
	}

	get totalLines() {
		if (this.title instanceof DataGridHeader) {
			return this.title.NumLines;
		} else return 1;
	}
};
const DataGridColumns = class DataGridColumns {
	constructor(...cols) {
		this.columns = [];
		for (let i = 0; cols && i < cols.length; i++) {
			if (cols[i] && typeof cols[i] === 'string') {
				let col = new DataGridColumn();
				col.name = cols[i];
				col.key = cols[i];
				this.columns.push(col);
			} else if (cols[i] instanceof DataGridColumn) {
				this.columns.push(cols[i]);
			}
		}
	}

	Add = col => {
		if (col instanceof DataGridColumn) this.columns.push(col);
		else throw Error('Invalid DataGridColumn Object');
	};

	toColDef = () => {
		let result = [];
		this.columns.forEach(x => {
			result.push({
				key: x.key,
				name: x.name,
				width: x.width,
				headerRenderer: x.title ? CreateHeader(x.title, this.totalLines) : null,
				format: x.format,
				title: InlineHeader(x.title),
				tag: x.tag,
				options: x.options
			});
		});

		return result;
	};

	get totalLines() {
		let max = 1;
		this.columns.forEach(x => {
			if (x.totalLines > max) max = x.totalLines;
		});
		return max;
	}
};

const BuildMultiYearHeaders = props => {
	let Cols = new DataGridColumns(
		new DataGridColumn('metric', new DataGridHeader('', '', ''), '350', ColType_Metric),
		new DataGridColumn('', new DataGridHeader('', '', ''), '100', ColType_MultiYearTitle)
	);
	let NumYear = 5;
	if (props && props.rows.years) {
		NumYear = props.rows.years.length;

		props.rows.years.forEach(x => {
			Cols.Add(
				new DataGridColumn(
					x.year.toString(),
					new DataGridHeader(x.year.toString(), '', '', 'right'),
					'100',
					ColType_MultiYear
				)
			);
		});
	}
	Cols.Add(
		new DataGridColumn(
			'foiTrend',
			new DataGridHeader('Facility', NumYear + '-Year Trend', '', 'center'),
			'125',
			ColType_SparkLine
		)
	);
	Cols.Add(
		new DataGridColumn(
			'nationalTrend',
			new DataGridHeader('Benchmark', NumYear + '-Year Trend', '', 'center'),
			'135',
			ColType_SparkLine
		)
	);
	return Cols;
};

const BuildMobileGrid = props => {
	if (props && props.rows && props.rows.multiYear) {
		props.columns = BuildMultiYearHeaders(props);
	}

	// let email = Auth.getUserEmail();
	// if (email === 'devans@hcmpllc.com') {
	// 	props.editMode = true;
	// } else {
	// 	props.editMode = false;
	// }
	if (props.headersOnly == false) {
		return (
			<table className="mh_table">
				{BuildMobileGridHeaders(props)}
				{BuildMobileGridRows(props)}
			</table>
		);
	} else {
		return (
			<table className="mh_table">
				{BuildMobileGridHeaders(props)}
				{BuildMobileGridRows(props)}
			</table>
		);
	}
};

const BuildMyGrid = props => {
	if (props && props.rows && props.rows.multiYear) {
		props.columns = BuildMultiYearHeaders(props);
	}

	let email = Auth.getUserEmail();
	if (
		(email &&
			email.endsWith('@hcmpllc.com') &&
			window.location.hostname.startsWith('metrics.stage')) ||
		window.location.hostname === 'localhost'
	) {
		props.editMode = true;
	} else {
		props.editMode = false;
	}
	if (props.headersOnly == true) {
		return <table className="mh_table">{BuildGridHeaders(props)}</table>;
	} else {
		return (
			<table className="mh_table">
				{BuildGridHeaders(props)}
				{BuildGridRows(props)}
			</table>
		);
	}
};

const BuildMobileGridHeaders = props => {
	let cols = [];
	let showHeader = props.subcategory && _.trim(props.subcategory) !== '';

	if (showHeader) {
		cols.push(
			<th colSpan="2" className="w-100 pl-3 page-navbar-sm text-white">
				{props.subcategory}
			</th>
		);
	} else {
		cols.push(<th colSpan="2" className="w-100" />);
	}
	return <thead className={props.showHeaders === true ? 'bg-info' : 'd-none'}><tr>{cols}</tr></thead>;
};

const BuildGridHeaders = props => {
	if (props && props.rows && props.rows.multiYear) {
		props.columns = BuildMultiYearHeaders(props);
	}
	let cols = [];
	let _columns = props.columns ? props.columns.toColDef() : null;
	let classStr = props.showHeaders === true ? '' : ''; //h-0
	if (_columns) {
		_columns.forEach((x, index) => {
			cols.push(
				<th className={classStr} style={{ width: x.width + 'px' }} key={`header-index-${index}`}>
					{/* Commented out to populate both table headers */}
					{/* {props.showHeaders === true ? x.headerRenderer : ''} */}
					{x.headerRenderer}
				</th>
			);
		});
	}

	return (
		<thead className={props.showHeaders === true ? 'bg-info mh_header' : 'bg-info mh_header d-none'}><tr>{cols}</tr></thead>
	);
};

const IsLineStyle = Style => {
	if (Style) {
		switch (Style) {
			case STYLE_TOTAL_LINE:
			case STYLE_DOUBLE_LINE:
			case STYLE_UNDERLINE:
			case STYLE_BOLD:
			case STYLE_BOLD_ITALIC:
			case STYLE_BOLD_UNDERLINE:
				return true;
			default:
				return false;
		}
	} else return false;
};

const GetStyleStr = Style => {
	switch (Style) {
		case STYLE_TOTAL_LINE:
			return '"borderTop": "3px solid gray", "fontWeight": "bold"';
		case STYLE_DOUBLE_LINE:
			return '"borderTop": "3px double gray", "fontWeight": "bold"';
		case STYLE_BOLD:
			return '"fontWeight": "bold"';
		case STYLE_UNDERLINE:
			return '"text-decoration": "underline"';
		case STYLE_ITALIC:
			return '"font-style": "italic"';
		case STYLE_BOLD_UNDERLINE:
			return '"fontWeight": "bold", "text-decoration": "underline"';
		case STYLE_BOLD_ITALIC:
			return '"fontWeight": "bold", "font-style": "italic"';
		case STYLE_LINE_SHADE:
			return '"backgroundColor": "#EDEDED"';
		default:
			return '';
	}
};

const BuildGridRowValue = (
	c,
	value,
	fieldDef,
	_rowFormat,
	_fieldStyle,
	_fieldName,
	editMode,
	tag = null,
	options = null,
	isMobile = false,
	mobileTitle = '',
	mobileData = []
) => {
	let col = <td />;


		let str =
			'{ "width": "' + c.width.toString() + 'px"' + (_fieldStyle ? ',' + _fieldStyle : '') + '}';
		let colStyle = JSON.parse(str);
		
		if (value) {
			if (c.format === ColType_Self || c.format === ColType_MultiYear) {
				let bgColor = '';
				// Quartile Shading Here
				if (value.ntile && c.format !== ColType_MultiYear) {
					if (Number(value.ntile) === 1) bgColor = 'mh-Q1';
					if (Number(value.ntile) === 2) bgColor = 'mh-Q2';
					if (Number(value.ntile) === 3) bgColor = 'mh-Q3';
					if (Number(value.ntile) === 4) bgColor = 'mh-Q4';
				}

				col = (
					<td
						className={bgColor + ' ' + BuildClassName(_rowFormat ? _rowFormat : c.format)}
						style={colStyle}
					>
						{FormatValue(c.format || value.format, value, tag, options)}
						{/* {FormatValue(c.format, value)} */}
					</td>
				);
			} else if (c.format === ColType_Metric && fieldDef && fieldDef.length) {
				let fld = _.find(fieldDef, x => {
					return x.FieldName === _fieldName;
				});
				col = (
					<td className={BuildClassName(_rowFormat ? _rowFormat : c.format)} style={colStyle}>
						{isMobile ? (
							<MobilePopup
								content={MetricFormatter(fld, value, editMode)}
								title={mobileTitle}
								data={mobileData}
							/>
						) : (
							MetricFormatter(fld, value, editMode)
						)}
					</td>
				);
			} else
				col = (
					<td className={BuildClassName(c.format)} style={colStyle}>
						{FormatValue(c.format || value.format, value, tag, options)}
					</td>
				);
		} else if (c.format === ColType_MultiYearTitle) {
			let foi = misc.getFOIandCompetitors();
			col = (
				<td className={BuildClassName(c.format)} style={colStyle}>
					<div className="row">
						{foi.map(a => {
							let name = a.name || a.facility_name;
							return (
								<div className="col-sm-12 text-small" title={a.name || a.facility_name}>
									#{a.id || a.prvdr_num || a.providerId}
								</div>
							);
						})}
						<div className="col-sm-12">National</div>
					</div>
				</td>
			);
		} else {
			col = (
				<td className={BuildClassName(_rowFormat ? _rowFormat : c.format)} style={colStyle}>
					{FormatValue(c.format, '-')}{' '}
				</td>
			);
		}

	return col;
};

const BuildMobileGridRows = props => {
	let trArray = [];
	let _columns = props.columns ? props.columns.toColDef() : null;
	let _rows = [];
	if (props.rows && props.rows.multiYear) {
		_rows = props.rows.rows;
	} else _rows = props.rows;
	if (_columns && _rows) {
		_rows.forEach(r => {
			let cols = [];
			let _rowFormat = r['format'];
			let _fieldName = r['fieldName'];
			let _fieldStyle = '';

			if (!(props.suppressLines === true && IsLineStyle(r['fieldStyle'])))
				_fieldStyle = r['fieldStyle'] ? GetStyleStr(r['fieldStyle']) : '';
			// Build Mobile Popup Data ----------------------------------------
			let MobilePopupData = [];
			for (let i = 1; i < _columns.length; i++) {
				let c = _columns && _columns.length > 0 ? _columns[i] : undefined;
				MobilePopupData.push({
					name: c.title,
					display: BuildGridRowValue(
						c,
						r[c.key],
						props.fieldDef,
						_rowFormat,
						_fieldStyle,
						_fieldName,
						props.editMode,
						r[c.tag],
						c.options
					)
				});
			}

			//---------------------------------------------------------------------

			for (let i = 0; i < _columns.length && i < 2; i++) {
				let c = _columns && _columns.length > 0 ? _columns[i] : undefined;
				cols.push(
					BuildGridRowValue(
						c,
						r[c.key],
						props.fieldDef,
						_rowFormat,
						_fieldStyle,
						_fieldName,
						props.editMode,
						r[c.tag],
						c.options,
						true,
						r[c.key],
						MobilePopupData
					)
				);
			}

			trArray.push(<tr>{cols}</tr>);
		});
	}

	return (
		<tbody className="data-grid" style={{}}>
			{trArray}
		</tbody>
	);
};

const BuildGridRows = props => {
	let trArray = [];
	let _columns = props.columns ? props.columns.toColDef() : null;
	let _rows = [];

	if (props.rows && props.rows.multiYear) {
		_rows = props.rows.rows;
	} else _rows = props.rows;
	if (_columns && _rows && Array.isArray(_rows)) {
		_rows.forEach(r => {
			let cols = [];
			let _rowFormat = r['format'];
			let _fieldName = r['fieldName'];
			let _fieldStyle = '';
			if (!(props.suppressLines === true && IsLineStyle(r['fieldStyle'])))
				_fieldStyle = r['fieldStyle'] ? GetStyleStr(r['fieldStyle']) : '';

			_columns.forEach(c => {
				cols.push(
					BuildGridRowValue(
						c,
						r[c.key],
						props.fieldDef,
						_rowFormat,
						_fieldStyle,
						_fieldName,
						props.editMode,
						r[c.tag],
						c.options
					)
				);
			});
			trArray.push(<tr>{cols}</tr>);
		});
	}

	return (
		<tbody className="table table-bordered data-grid d-flex-inline" style={{}}>
			{trArray}
		</tbody>
	);
};

const BuildSubcategory = subcategory => {
	if (subcategory)
		return (
			<h6 className="tblSubHead">{subcategory}</h6>
		);
	else return null;
};

const DataGrid = class DataGrid extends ResponsiveClass {
	constructor(props) {
		super(props);
	}
	renderMobile() {
		let minHeight = this.props.height ? this.props.height : '100%';
		let minWidth = this.props.width ? this.props.width : '100%';
		let settings = Object.assign(
			{
				years: [],
				yearSelectCallback: null,
				showHeaders: true,
				showTitle: true,
				suppressLines: false,
				headersOnly: false
			},
			this.props
		);
		let title =
			settings.showTitle && settings.title ? (
				<div className="row">
					<div className="col-md-12">{settings.title.render()}</div>
				</div>
			) : null;

		return (
			<div>
				{title}
				<div
					className="d-flex-inline"
					style={{
						width: minWidth,
						height: minHeight
					}}
				>
					{BuildMobileGrid(settings)}
				</div>
			</div>
		);
	}
	renderTabletDesktop() {
		let minHeight = this.props.height ? this.props.height : '100%';
		let minWidth = this.props.width ? this.props.width : '100%';
		let settings = Object.assign(
			{
				years: [],
				yearSelectCallback: null,
				showHeaders: true,
				showTitle: true,
				suppressLines: false,
				headersOnly: false
			},
			this.props
		);
		let title =
			settings.showTitle && settings.title ? (
				<div className="row">
					<div className="col-md-12">{settings.title.render()}</div>
				</div>
			) : null;
		// alert(JSON.stringify(settings.subcategory))
		return (
			<Fragment>
				{title}
				<div
					className="py-0"
					style={{
						width: minWidth,
						height: minHeight
					}}
				>
					<div className="text-left">{BuildSubcategory(settings.subcategory)}</div>
					{BuildMyGrid(settings)}
				</div>
			</Fragment>
		);
	}
};
export {
	DataGrid,
	ColType_Text,
	ColType_Percent,
	ColType_PercentInt,
	ColType_PercentBar,
	ColType_PercentNoShift,
	ColType_5Star,
	ColType_Number,
	ColType_NumberDec,
	ColType_Link,
	ColType_SparkLine,
	ColType_Self,
	ColType_Money,
	ColType_MoneyDec,
	ColType_Metric,
	ColType_Integer,
	DataGridColumn,
	DataGridColumns,
	DataGridHeader,
	DataGridTitle,
	DataGridHeaderBuilder
};
