import React from 'react';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/fontawesome-free-solid';

export default class NoData extends React.Component {
	constructor(props) {
		super();
	}

	render() {
		return (
			<div className="panel panel-default text-center content-center">
				<FontAwesomeIcon
					className="fa-8x"
					style={{ opacity: 0.3, filter: 'alpha(opacity=30)' }}
					icon={faBan}
				/>
				<h2 className="p-3">{this.props.message || 'No Data Available'}</h2>
			</div>
		);
	}
}
