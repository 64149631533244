import * as Types from '../actions/PageActionTypes';

export class DefaultState {
	constructor() {
		this.pageData = [];
		this.error = null;
		this.fetching = false;
		this.fetched = false;
		this.whereChanged = false;
	}
}

export const PageReducerHelper = (Tag, state, action) => {
	switch (action.type) {
		case Tag + '_PENDING': {
			return { ...state, fetching: true };
		}
		case Tag + '_FULFILLED': {
			const data = action.payload.data.pageData;
			return {
				...state,
				fetching: false,
				fetched: true,
				pageData: data
			};
		}
		case Tag + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default:
			return { ...state };
	}
};

export const ArticlePageReducer = (Tag, state) => {
	return PageReducerHelper(Tag, state, Types.KNOWLEDGEBASE_API);
};
