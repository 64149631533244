import React from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../../core/actions/ActionHelper';
import * as ActionTypes from '../../Common/ActionTypes';
import * as fieldDefActions from '../../../core/actions/GlobalActions';
import * as MHGrid from '../../../tools/datagrid';
import { DataGridProviderColumns } from '../../../tools/datagrid/extras/DataGridGroupHeader';
import * as Fields from '../SrategicMarketFields';
import * as misc from '../../../lib/misc';
import * as snfSummaryActions from '../../Common/SkilledNursingActions';
import NoData from '../../../core/components/notifications/NoData';
import LoadingSpinner from '../../../tools/loadingSpinner';
// import CompetitorMap from '../map';
import _ from 'lodash';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.snf,
		providers: store.snfCompetitorList.providers,
		dataLoaded: store.snfCompetitorList.fetched,
		dataLoading: store.snfCompetitorList.fetching,
		whereChanged: store.snfCompetitorList.whereChanged,
		data: store.snfCompetitorList.Benchmark,
		datakey: store.snfCompetitorList.dataKey,
		providerInfo: store.providerInfo
	};
};
const mapDispatchToProps = dispatch => {
	return {
		updateWhereChange: () => dispatch(fieldDefActions.sessionStorageWhereChange()),
		GetDefaultCompetitors: facilityId => {
			dispatch(snfSummaryActions.snfDefaultCompetitorList(facilityId, Fields.CompetitorTab()));
		},
		resetCompetitors: () => dispatch(Actions.ResetAction(ActionTypes.SNF_SMD_COMPETITOR_METRICS))
	};
};

class Competitors extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			facilityId: this.props.facilityId,
			facilityInfo: this.props.facilityInfo,
			running: false
		};
		this.setState(this.state);
		this.onYearChanged = this.onYearChanged.bind(this);
	}

	componentDidMount() {
		this.InitializeData();
	}

	InitializeData() {
		this.props.GetDefaultCompetitors(misc.getFacilityIds());
	}

	onYearChanged(year) {
		this.props.data.ActiveYear = year;
		let state = this.state;
		state.currentYear = year;
		this.setState(state);
	}

	render() {
		let results = [];
		let results2 = [];

		if (!this.props.dataLoaded || this.props.dataLoading) {
			results.push(<LoadingSpinner />);
		} else {
			let data = this.props.data;
			if (this.props.dataLoaded && !this.props.dataLoading && this.props.providers.length > 0) {
				let datakey = this.props.datakey;
				let facility = Array.isArray(this.state.facilityId)
					? this.state.facilityId
					: [this.state.facilityId];
				let providers = _.take(
					_.compact(
						_.union(facility, this.props.providers.map(a => a.id || a.prvdr_num || a.providerId))
					),
					6
				);
				let provInfo = _.compact(_.union([this.state.facilityInfo], this.props.providers));
				let competitorInfo = Fields.CompetitorInfo();
				let sections = competitorInfo.groups;
				let columns = DataGridProviderColumns(providers, provInfo, 'snf');
				let Title = new MHGrid.DataGridTitle(
					'Competitor Key Metrics',
					'',
					'',
					null,
					this.props.data.Years,
					this.onYearChanged,
					false,
					true
				);
				Title.header1Size = 4;
				// results.push(
				// 		<div class="text-left">
				// 			<span class="text-nowrap h6 m-0">{Title}</span>
				// 		</div>
				// 	);
				results.push(Title.render());
				results.push(
					<div class="text-left">
						<h6 className="tblSubHead">{`Facility Level Financial & Operating Statistics`}</h6>
					</div>
				);
				results2.push(
					<div className="sticky_table_header">
						<MHGrid.DataGrid
							suppressLines={true}
							columns={columns}
							rows={[]}
							showHeaders={true}
							headersOnly={true}
						/>
					</div>
				);
				for (let i = 0; i < sections.length; i++) {
					results2.push(
						<MHGrid.DataGrid
							suppressLines={true}
							fieldDef={this.props.FieldDesc}
							columns={columns}
							rows={data ? this.props.data.Fetch(sections[i].fields, providers) : []}
							subcategory={i > 0 ? sections[i].label : null}
							showHeaders={false}
						/>
					);
				}
			} else results.push(<NoData />);
		}

		return (
			<div className="panel panel-default">
				{results}
				<div>{results2}</div>
			</div>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Competitors);
