import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import CustomGridMenu from '../../core/components/navigation/customGridMenu';
import * as  MHGrid from '../../tools/datagrid';
import * as misc from '../../lib/misc';
import LoadingSpinner from '../../tools/loadingSpinner';
import Auth from '../../core/Security/Auth';
import LandingPage from '../../core/DefaultPages/marketingInfo/HospFinancialInfo';
import BenchmarkDataExport from '../Benchmark/BenchmarkDataExport';
import * as Fields from './FinancialFields';
import * as Actions from '../../core/actions/ActionHelper';
import * as ActionTypes from '../Common/ActionTypes';
import { AddNewFields } from '../../core/actions/GlobalActions';
import DataGridTabs from '../../tools/ui-templates/DataGridTabs';
import DataGridTabBody from '../../tools/ui-templates/DataGridTabBody';
import CostReportExports from '../Common/HospCostReportExports';

const mapDispatchToProps = dispatch => {
	return {
		GetFinancialData: providers =>
			dispatch(
				Actions.ActionPivotDataRequest(
					ActionTypes.FINANCIAL_GET_INC_METRICS,
					providers,
					'hosp',
					Fields.All(),
					'fy_year'
				)
			),
		GetFieldInfo: (fields, basis) => {
			dispatch(AddNewFields(fields, basis, 'hosp'));
		}
	};
};

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.hospFinancialData.whereChanged,
		data: store.hospFinancialData.Benchmark,
		fetched: store.hospFinancialData.fetched,
		fetching: store.hospFinancialData.fetching,
		datakey: store.hospFinancialData.dataKey,
		fieldKey: store.fieldDef.updateKey
	};
};

const FinancialSummaryTab = [
	{
		title: 'Income Statement',
		fields: Fields.IncomeReportFields
	},
	{
		title: 'Balance Sheet',
		fields: Fields.BalanceStatementReportFields
	}
];

const CostCenterMetricsTab = [
	{
		title: 'General Service Cost Centers',
		fields: Fields.CostCenterGeneralServicesFields
	},
	{
		title: 'Inpatient Routine Services Cost Centers',
		fields: Fields.IPCostCenterServicesFields
	},
	{
		title: 'Outpatient Service Cost Centers',
		fields: Fields.OutpatientServiceCostCenterFields
	}

];

const CostCenterMetricsTabCont = [
	{
		title: 'Ancillary Service Cost Centers',
		fields: Fields.AncillaryServiceCostCenterFields
	},
	{
		title: 'Other Reimbursable Cost Centers',
		fields: Fields.OtherReimbursableCostCenterFields
	},
	{
		title: 'Non-Reimbursable Cost Centers',
		fields: Fields.NonReimbursableCostCenterFields
	}
];

const KeyPerformanceMetricsTab = [
	{
		title: 'Key Profit & Loss Metrics',
		fields: Fields.KeyProfitLossFields
	},
	{
		title: 'Key Balance Sheet Metrics',
		fields: Fields.BalanceKeyFields
	}
];



class Financial extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 0
		};
		this.getFinancialData();
	}

	getTabDef() {
		return [
			{
				id: 0,
				title: 'Financial Summary',
				component: (
					<DataGridTabs
						{...this.props}
						Cols={this.getFinancialColumns()}
						Sections={FinancialSummaryTab}
						data={this.props.data.data}
						FieldDesc={this.props.FieldDesc}
					/>
				)
			},
			{
				id: 1,
				title: 'Key Performance Metrics',
				component: (
					<DataGridTabs
						{...this.props}
						Cols={this.getFinancialColumns()}
						Sections={KeyPerformanceMetricsTab}
						data={this.props.data.data}
						FieldDesc={this.props.FieldDesc}
					/>
				)
			},
			{
				id: 2,
				title: 'Cost Center ...',
				component: (
					<DataGridTabs
						{...this.props}
						Cols={this.getFinancialColumns()}
						Sections={CostCenterMetricsTab}
						data={this.props.data.data}
						FieldDesc={this.props.FieldDesc}
					/>
				)
			},
			{
				id: 3,
				title: 'Cost Center',
				component: (
					<DataGridTabs
						{...this.props}
						Cols={this.getFinancialColumns()}
						Sections={CostCenterMetricsTabCont}
						data={this.props.data.data}
						FieldDesc={this.props.FieldDesc}
					/>
				)
			},
			{
				id: 4,
				title: 'Cost Report Downloads',
				component: (
					<div className="d-block text-center">
						<span className="text-nowrap">
							<CostReportExports {...this.props} Cols={this.props.dataLoaded} />
						</span>
					</div>
				)
			}
		];
	}
	
	getFinancialColumns() {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'metric',
				new MHGrid.DataGridHeader('', '', ''),
				'350',
				MHGrid.ColType_Metric
			)
		);

		let yr = misc.getFacilityYear();
		for (let i = 4; i >= 0; i--) {
			Cols.Add(
				new MHGrid.DataGridColumn(
					yr - i,
					new MHGrid.DataGridHeader(yr - i, '', '', 'right'),
					'150',
					MHGrid.ColType_Self
				)
			);
		}

		return Cols;
	}
	getFinancialColumnKeys() {
		let result = ['metric'];
		let header = ['Metric'];
		let yr = misc.getFacilityYear();
		for (let i = 4; i >= 0; i--) {
			result.push(yr - i);
			header.push('Fiscal Yr ' + (yr - i).toString());
		}
		return { key: result, header: header };
	}

	getFinancialData() {
		if (Auth.isUserHospAuthenticated() === true) {
			let foi = misc.getFacilityInfo();
			this.props.GetFieldInfo(Fields.All(), this.props.FieldDesc, 'hosp');

			if (this.props.fetched === false && this.props.fetching === false && foi) {
				this.props.GetFinancialData(foi.prvdr_num);
			}
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.getFinancialData();
		}
	}

	render() {
		if (Auth.isUserHospAuthenticated()) {
			let Title = new MHGrid.DataGridTitle('Financial Performance', '', '', 3);
			let _sectionsTemp = [
				FinancialSummaryTab, 
				KeyPerformanceMetricsTab, 
				CostCenterMetricsTab, 
				CostCenterMetricsTabCont
			];
			let GridControlMenu = (
				<CustomGridMenu>
					<BenchmarkDataExport
						{...this.props}
						fileName={Title.header1}
						domain={'hosp'}
						sections={_sectionsTemp}
						tabDef={this.getTabDef()}
						headAlign={'right'}
						bodyAlign={'right'}
						Cols={this.getFinancialColumns()}
						data={this.props.data.data}
						FieldDesc={this.props.FieldDesc}
					/>
				</CustomGridMenu>
			);
			
			let details = this.props.fetched ? (
				<div className="container" id="finPerform">
					{Title.renderHeading()}
					<div className="row">
						<DataGridTabBody tabDef={this.getTabDef()} GridControl={GridControlMenu} />
					</div>
				</div>
			) : (
				<div className="container">
					<div className="row">
						<LoadingSpinner />
					</div>
				</div>
			);
			
			return (
				<>
					<PageNavigation selectedTab="2" domain={'hosp'} />
					<section id="content">{details}</section>
				</>
			);
		} else {
			return <LandingPage domain="hosp" />;
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Financial);
