import React, { Component } from "react";
import * as products from '../../Subscribe/products';

const states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"];

class ReportInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      facList: [
        {name: "Hospital", abbre: 'hos'}, 
        {name: "Skilled Nursing Facility", abbre: 'snf'}
      ],
      facSel: null,
      stateSel: [],
      disabled: true,
      reportInfo: {
        selectedStates: [],
        selected: '',
        plan: '',
        planName: products.at_risk.fullName,
        price: products.at_risk.price.single
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitInfo = this.submitInfo.bind(this);
  }

  componentDidMount() {
    // sessionStorage.removeItem('report');
  }

  handleChange = e => {
    let state = this.state,
      selValue = e.target.value,
      selName = e.target.className,
      selState = e.target.value,
      checked = e.target.checked,
      boxes = null;
    
    if(selName === "facCheckbox") {
      boxes = document.getElementsByName('facCheckbox');
      if(checked) {
        if(selValue === 'all' || state.facSel) {
          document.getElementById('all').checked = true;
          for(let i = 0; i < boxes.length; i++) {
            boxes[i].checked = true;
          }
          state.facSel = 'all';
        } else {
          state.facSel = selValue;
        }
      } else {
        state.facSel = null;
        if(selValue === 'all') {
          for(let i = 0; i < boxes.length; i++) {
            boxes[i].checked = false;
          }
          state.facSel = null;
        } else {
          document.getElementById('all').checked = false;
          for(let i = 0; i < boxes.length; i++) {
            if(boxes[i].checked === true) {
              state.facSel = boxes[i].value;
            }
          }
        }
      }
    } else {
      boxes = document.getElementsByName('statesCheckbox');
      if(checked) {
        if(selState === 'all') {
          for(let i = 0; i < boxes.length; i++) {
            boxes[i].checked = true;
          }
          state.stateSel = [];
          state.stateSel.push('All States');
        } else {
          state.stateSel.push(selState);
          if(state.stateSel.length === boxes.length) {
            document.getElementById('allStates').checked = true;
            state.stateSel = [];
            state.stateSel.push('All States');
          }
        }
      } else {
        state.stateSel = [];
        if(selState === 'all') {
          for(let i = 0; i < boxes.length; i++) {
            boxes[i].checked = false;
          }
        } else {
          document.getElementById('allStates').checked = false;
          for(let i = 0; i < boxes.length; i++) {
            if(boxes[i].checked === true) {
              state.stateSel.push(boxes[i].value);
            }
          }
        }
      }
    }
    if(state.stateSel.length > 0 && state.facSel) {
      state.disabled = false;
    } else {
      state.disabled = true;
    }
    this.setState(state);
  };

  submitInfo = (e) => {
    let state = this.state;
    e.preventDefault();
    state.reportInfo.selectedStates = state.stateSel;
    if(state.facSel === 'hos') {
      state.reportInfo.selected = 'Hospitals';
    } else if(state.facSel === 'snf') {
      state.reportInfo.selected = 'Skilled Nursign Facilities';
    } else {
      state.reportInfo.selected = 'All Facilities';
    }
    state.reportInfo.plan = state.facSel;
    if(this.props.reportComplete) {
      sessionStorage.setItem('report', JSON.stringify(state.reportInfo));
      this.props.reportComplete(state.reportInfo);
    } else {
      alert("We appologize but there was an error. Please try again.");
    }
  };

  render() {
    return (
      <section id="content" className="atRisk">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>{this.state.reportInfo.planName} Facility Report</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris vitae ultricies leo integer. Posuere morbi leo urna molestie. Interdum velit laoreet id donec ultrices tincidunt arcu non. Nisl purus in mollis nunc sed id semper risus in. Sit amet consectetur adipiscing elit ut aliquam purus sit. Tincidunt eget nullam non nisi. Ultrices sagittis orci a scelerisque purus semper eget duis at. Facilisi nullam vehicula ipsum a arcu cursus vitae. Aliquam faucibus purus in massa tempor. Faucibus ornare suspendisse sed nisi. Rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant.</p>
            </div>
            <form id="atRiskForm" onSubmit={this.submitInfo}>
              <div className="col-12 facFrame">
                <h3>Facility Type</h3>
                <ol id="facList">
                {this.state.facList.map((item, index) => {
                  return (
                    <li>
                      <input
                        name="facCheckbox" 
                        id={item.name} 
                        className="facCheckbox" 
                        type="checkbox" 
                        value={item.abbre} 
                        onChange={this.handleChange} 
                      />
                      <label key={index} for={item.name}>
                        {item.name}
                      </label>
                    </li>
                  );
                })}
                 <li>
                    <input
                      name="all" 
                      id="all" 
                      className="facCheckbox" 
                      type="checkbox" 
                      value="all" 
                      onChange={this.handleChange} 
                    />
                    <label for="all">
                      Select All
                    </label>
                  </li>
                </ol>
              </div>
              <div className="col-12 stateFrame">
                <h3>Select a State or States</h3>
                <ol>
                  <li>
                    <input 
                      name="allStates" 
                      id="allStates" 
                      className="statesCheckbox" 
                      type="checkbox" 
                      value="all" 
                      onChange={this.handleChange} 
                    />
                    <label for="allStates">
                      Select All States
                    </label>
                  </li>
                </ol>
                <ol id="stateList">
                  {states.map((value, index) => {
                    return (
                      <li>
                        <input
                          name= "statesCheckbox"
                          id={value} 
                          className="statesCheckbox" 
                          type="checkbox" 
                          value={value} 
                          onChange={this.handleChange} 
                        />
                        <label key={index} for={value}>
                          {states[index]}
                        </label>
                      </li>
                    );
                  })}
                </ol>
                <input 
                  type="submit" 
                  id="sub" 
                  className="loginBttn" 
                  name="submit" 
                  value="Next" 
                  disabled={this.state.disabled} 
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default(ReportInfo);