import React, { Component, Fragment } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/fontawesome-free-solid';
import * as misc from '../../lib/misc';
import _ from 'lodash';
import LoadingSpinner from '../../tools/loadingSpinner';
import NoData from '../../core/components/notifications/NoData';
import BenchmarkUtil from '../../lib/BenchmarkUtil';
import ReactExport from 'react-data-export';
import DataGridExport from '../../tools/DataGridExport/';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class BenchmarkDataExport extends React.Component {
	constructor(props) {
		super(props);
		this.sections = props.sections;
		this.SummaryCols = props.Cols;
		this.FieldDesc = props.FieldDesc;
	}
	
	getFileName() {
		let file = this.props.fileName;
		let dom = this.props.domain;
		let today = new Date();
		let dd = String(today.getDate()).padStart(2, '0');
		let mm = String(today.getMonth() + 1).padStart(2, '0');
		let yy = today.getFullYear().toString().substr(-2);
		today = mm+'/'+dd+'/'+yy;
		file = file.replace(/\s/g, '-');
		dom = dom.toUpperCase();
		let targetfile = 'HMP-Metrics-'+dom+'-'+file+'-'+today;
		
		return targetfile;
	}
	getData() {
		let data = this.props.data;
		let FieldDesc = this.props.sections;
		let sheets = [];
		const gridExporter = new DataGridExport();
		let providers = misc.getFOIandCompetitors();
		
		if (!data || !FieldDesc) {
			return <LoadingSpinner />;
		} else {
			if (data && this.sections && this.sections.length) {
				let options = {
					columns: this.props.Cols,
					headAlign: this.props.headAlign,
					bodyAlign: this.props.bodyAlign,
					fieldDesc: this.props.FieldDesc,
					rows: []
				};
				let _dom = this.props.domain;
				let _fName = this.props.fileName;
				var _subCatTitle = '';

				for (let i = 0; i < this.sections.length; i++) {
					let sheetTitle = this.props.tabDef[i].title;
					sheetTitle = sheetTitle.replace('&', 'and');
					let gridRows = [];
					// console.log(sheetTitle);
					for(let j = 0; j < this.sections[i].length; j++) {
						// var _subCatTitle = this.sections[i][j].title;
						if(_dom == 'snf' && _fName == 'Benchmark Metrics') {
							_subCatTitle = this.sections[i][j].label;
						} else {
							_subCatTitle = this.sections[i][j].title;
						}
						if(_subCatTitle) {
							if(j == 0) {
								gridRows.push([{value: _subCatTitle, style: {font: {color: {rgb: "304771"}, sz: "14", bold: true}}}]);
							} else {
								gridRows.push([{value: ''}],[{value: _subCatTitle, style: {font: {color: {rgb: "304771"}, sz: "14", bold: true}}}]);
							}
						}
						let rows = [];
						if(data && data instanceof BenchmarkUtil) {
							rows = data ? data.Fetch(this.sections[i][j].fields, providers) : [];
						} else {
							rows = data ? misc.filterMetrics(data, this.sections[i][j].fields) : [];
						}
						options.rows = rows;
						let tempRows = gridExporter.buildGridRows(options);
						gridRows = gridRows.concat(tempRows);
					}
					
					let dataSheet = [{columns: null, data: null}];
					let columns = gridExporter.buildSheetCols(options);
					
					dataSheet[0].columns = columns;
					dataSheet[0].data = gridRows;
					
					sheets.push(
						<ExcelSheet
							dataSet={dataSheet}
							name={sheetTitle}
						/>
					);
				}
				
				return sheets;
			} else {
				return <NoData />
			}
		}
	}

	render() {
		return (
			<>
        <ExcelFile 
					filename = {this.getFileName()}
					element={
						<div className="exportFile">
							<span className="pr-2">Export Report</span>
							<FontAwesomeIcon className="fa-1x text-primary" icon={faExternalLinkAlt} />
						</div>
					}
				>
					{this.getData()}
        </ExcelFile>
      </>
		);
	}
}

export default BenchmarkDataExport;
