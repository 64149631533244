export const LaborMetrics = [
	'operating_rev_per_fte', //Net Patient Revenue per FTE // Daniel changed from net_patient_rev_per_fte -> operating_rev_per_fte 01-27-2019
	'labor_cost_as_a_percent_of_revenue', //Labor Cost as a Percentage of Total Operating Revenue
	'ftes_per_aob', //FTE per AOB
	'total_wage_related_costs', //Total Salaries and Wages
	'contract_labor_adj_salaries', //Total Contract Labor Cost
	'total_staff_compensation', //Total Staff Compensation
	'total_emply_benefit', //Total Employee Benefit
	'total_staff_comp_and_empl_benefit', //Total Staff Compensation + Employee Benefits
	'total_staff_comp_and_bnft_as_operating_rev', //Total Staff Compensation + Benefits as a Percentage of Net Patient Revenue
	// Daniel changed from total_staff_comp_and_bnft_as_net_pat_rev -> total_staff_comp_and_bnft_as_operating_rev
	'total_emp_bnft_as_pct_staff_sal_wages', //Total Employee Benefits as a Percentage of Staff Salaries and Wages
	'contract_labor_as_pct_staff_sal_wages', //Total Contract Labor as a Percentage of Total Staff Compensation + Benefits
	'contract_labor_dir_patient_care_per_hr', //Contract Labor, Direct Patient Care, per Hour
	'ftes_acute', //Employed Full Time Equivalent Employees (FTEs)
	'contract_labor_ftes', //Contract Labor Full Time Equivalent Employees (FTEs)
	'ftes_with_sub_providers', //Total Full Time Equivalent Employees (FTEs)
	//'total_salary_adj_salaries', //Average Salary per Employed FTE
	'wage_index', //Average Weighted Wage Index
	'avg_salary_wage_index_adjusted', //Average Salary per Employed FTE adjusted by Average Weighted Wage Index
	'avg_cost_per_fte_employee', //Average Cost per FTE - Employee
	'avg_cost_per_fte_contract_labor', //Average Cost per FTE - Contract Labor
	'avg_cost_per_fte_employee_contract_labor' //Average Cost per FTE - Employee + Contract Labor
];

export const BedTypeMetrics = [
	//'facility_count',
	'total_adults_peds_number_of_beds', // General Medical/Surgical
	'icu_beds', // Intensive Care Unit (ICU)
	'coronary_care_beds', // Coronary Care Unit (CCU)
	'surgic_beds', // Surgical Intensive Care (SICU)
	'picu_beds', // Paediatric Intensive Care Unit (PICU)
	'nicu_beds', // Neonatal Intensive Care Unit (NICU)
	'burn_care_beds', // Burn Unit
	'other_ccu_beds', // Other Critical Care
	'subprovider_ipf_number_of_beds', // Inpatient Psychiatric Facility
	'subprovider_irf_number_of_beds', // Medical Rehabilitaiton
	'subprovider_other_beds', // Other
	'total_acute_care_beds', // Total Acute Care Beds
	'subacute_beds', // Total Sub-Acute Care Beds
	'total_beds', // Total Acute and Sub-Acute Inpatient Beds
	'newborn_nursery' // Normal Newborn Nursery
];

export const UtilizationIndicationMetrics = [
	'total_beds', // Total Beds
	'sub_acute_total_discharges', // Discharges - Total Acute and Sub-Acute Care  (includes Swing-Bed Patients)
	'sub_acute_total_inpatient_days', // Inpatient Days -  Total Acute and Sub-Acute Care  (includes Swing-Bed Patients)
	'sub_acute_occupancy', // Occupancy - Total Acute and Sub-Acute Care (includes Swing-Bed Patients)
	'medicare_cmi', // Medicare Case Mix Index (CMI)
	'adj_avg_daily_census', // Total Average Daily Census (ADC)
	'ftes_with_sub_providers', // Total Full Time Equivalent Employees (FTEs)
	'fte_gme_residents_fellows', // Total Equivalent GME Residents and Fellows
	'acute_ip_ct_of_total_chrg', // Acute Inpatient Charges as a Percentage of Total Charges
	'adj_aob', // Adjusted Occupied Beds (AOB)
	'ed_admission_to_hosp', // Admissions from Emergency Department
	'ed_not_resulting_hosp_admission', // ED Visits Not Resulting in Hospital Admission
	'ed_total_visits', // Total Emergency Visits
	'in_patient_surg_cases' // Inpatient Surgical Cases
];

export const DischargeMetrics = [
	'dis_adult_ped_acute_care', //Adults/Pediatrics Routine Acute Care
	'discharges_adult_distinct_part_units' //Acute Distinct Part Units
];

export const InpatientDaysMetrics = [
	'inpatient_days_adult_ped_routine_care', //Inpatient Days - Adults/Pediatrics Routine Acute Care
	'inpatient_days_acute_distinct_pts', //Inpatient Days - Acute Distinct Part Units
	'inpatient_days_total_acute_care', //Inpatient Days - Total Acute Care (excludes Swing-Bed Patients)
	'inpatient_days_acute_sub_incld_sw_bed' //Inpatient Days -  Total Acute and Sub-Acute Care  (includes Swing-Bed Patients)
];

export const AvgLOSList = [
	'alos_adult_ped_routine_care', //Average Inpatient Length of Stay (ALOS) - Adults/Pediatrics Routine Acute Care
	'alos_acute_distinct_pts', //Average Inpatient Length of Stay (ALOS) - Acute Distinct Part Units
	'alos_total_acute_care', //Average Inpatient Length of Stay (ALOS) - Total Acute Care (excludes Swing-Bed Patients)
	'alos_acute_sub_incld_sw_bed' //Average Inpatient Length of Stay (ALOS) - Total Acute and Sub-Acute Care (includes Swing-Bed Patients)
];

export const OccupancyList = [
	'occupancy_rate',
	'occupancy_adult_ped_routine_care', //Occupancy - Adults/Pediatrics Routine Acute Care Beds (excludes Swing-Bed Patients)
	'critical_care_occupancy', //Occupancy - Critical Care Beds
	'occupancy_adult_distinct_pts', //Occupancy - Acute DIstinct Part Units
	'occupancy_sub_acute_distinct_pts', //Occupancy - Sub-Acute Care Distinct Part Beds (excludes Swing-Bed Patients)
	'occupancy_acute_sub_incld_sw_bed' //Occupancy - Total Acute and Sub-Acute Care (includes Swing-Bed Patients)
];

export const UtilizationMetrics = () => {
	let list = [];
	list = list.concat(BedTypeMetrics);
	list = list.concat(UtilizationIndicationMetrics);
	list = list.concat(DischargeMetrics);
	list = list.concat(InpatientDaysMetrics);
	list = list.concat(AvgLOSList);
	list = list.concat(OccupancyList);
	return list;
};

export const MedicarePaymentMetrics = [
	'outlier_payments_for_discharges', //Medicare Outlier Payments
	'mcare_outlier_pct_of_total_mcare_rev', //Medicare Outlier Payments as a Percentage of Total Medicare Revenue
	'mcare_dsh_pymts', //Medicare DSH Payments
	'mcare_dsh_pymts_pct_of_total_mcare_rev', //Medicare DSH Payments as a Percentage of Total Medicare Revenue
	'mcare_gme_pymts', //Medicare GME Payments
	'mcare_gme_pymts_pct_of_total_mcare_rev', //Medicare GME Payments as a Percentage of Total Medicare Revenue
	'total_ip_mcare_rev', // Medicare Inpatient Revenue
	'total_op_mcare_rev', // Medicare Outpatient Revenue
	'total_othr_mcare_rev', // Medicare Other Revenue
	'total_mcare_rev' // Total Medicare Revenue
];

export const OperatingMetrics = [
	'operating_margin', //Operating Profit (Loss) Margin
	//'net_income_margin_with_rpa', //Net Profit (Loss) Margin After Related Party Adjustments
	'total_profit_margin', //Total Profit Margin
	'gross_sqft_per_bed', //Total Gross Square Footage per Bed
	'total_operating_rev_per_sqft', //Total Operating Revenue per Gross Square Footage Daniel changed from total_net_rev_per_sqft -> total_operating_rev_per_sqft
	'operating_cost_per_aob', //Total Operating Expense per AOB
	'operating_rev_per_aob', //Net Patient Revenue per AOB
	'outpatient_charges_perc_total_charges', //Outpatient Charges as a Percentage of Total Charges
	'nonpatient_rev_perc_netpatient_rev', //Non-Operating Revenue as a Percentage of Net Patient Revenue
	'avg_markup', //Average Markup
	'routine_dept_ccr', //Cost to Charge Ratio
	'medicare_patient_days_billed', //Proportion of Adults and Peds Days Billed to Medicare
	'medicaid_patient_days_billed', //Proportion of Adults and Peds Days Billed to Medicaid
	'other_patient_days_billed', //Proportion of Adults and Peds Days Billed to Other
	'days_in_net_patient_ar', //Days Net Patient Revenue in Net Patient Accounts Receivable
	'days_in_ap', //Days Expense in Accounts Payable
	'long_term_debt_to_total_assets', //Long Term Debt to Total Assets
	'days_cash_on_hand_all_sources' //Days Cash on Hand
];

export const FinancialMetrics = [
	'inpatient_charges',
	'outpatient_charges',
	'total_charges_inpatient_and_outpatient',
	'net_patient_revenue',
	'misc_non_patient_revenue',
	'net_patient_rev_and_misc_opr_rev',
	'total_operating_expenses',
	'annual_operating_profit',
	'non_operating_revenue',
	'total_other_expenses',
	'net_income'
];

export const AllFinancialMetrics = () => {
	let list = [];
	list = list.concat(MedicarePaymentMetrics);
	list = list.concat(OperatingMetrics);
	list = list.concat(FinancialMetrics);
	return list;
};

export const All = () => {
	let list = [];
	list = list.concat(AllFinancialMetrics());
	list = list.concat(UtilizationMetrics());
	list = list.concat(LaborMetrics);
	return list;
};

export const Info = () => {
	return {
		topic: 'Benchmark',
		groups: [
			{
				key: 'benchUtilization',
				label: 'Utilization',
				fields: UtilizationMetrics()
			},
			{
				key: 'benchLaborMetrics',
				label: 'Labor Metrics',
				fields: LaborMetrics
			},
			{
				key: 'benchFinancialMetrics',
				label: 'Financial Metrics',
				fields: AllFinancialMetrics()
			}
		]
	};
};
