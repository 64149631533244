import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadICD10, loadAPC, setError, setLoaded } from './actions';
import * as misc from '../../../lib/misc';
import * as format from '../../../lib/FormatUtil';
import * as MHGrid from '../../../tools/datagrid';
import ReChartTool from '../../../tools/charts/ReChartTool';
import { Tab, Tabs } from 'react-bootstrap';
import LoadingSpinner from '../../../tools/loadingSpinner';
import _ from 'lodash';

const BuildInitForProvider = name => {
	let initials = name.match(/\b\w/g) || [];
	initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

	return initials;
};

const BuildGridHeaderICD10 = (provider, marketData) => {
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn(
			'metric',
			new MHGrid.DataGridHeader('ICD-10 Code', '', '', 'left'),
			350,
			MHGrid.ColType_Text
		),
		new MHGrid.DataGridColumn(
			provider.providerId,
			MHGrid.DataGridHeaderBuilder(provider.name, 'center', '150'),
			175,
			MHGrid.ColType_Self
		)
	);

	if (marketData && marketData.PSACompetitors && marketData.PSACompetitors.length > 0) {
		for (let i = 0, c = 0; i < marketData.PSACompetitors.length && c < 5; i++) {
			if (
				marketData.PSACompetitors[i].prvdr_num !== provider.providerId &&
				marketData.PSACompetitors[i].prvdr_num !== 'other' &&
				marketData.PSACompetitors[i].name !== 'Totals'
			) {
				c++;
				Cols.Add(
					new MHGrid.DataGridColumn(
						marketData.PSACompetitors[i].prvdr_num,
						MHGrid.DataGridHeaderBuilder(
							format.initCap(marketData.PSACompetitors[i].name),
							'center',
							'150'
						),
						175,
						MHGrid.ColType_Self
					)
				);
			}
		}
	}
	return Cols;
};
const BuildGridHeaderAPC = (provider, marketData) => {
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn(
			'metric',
			new MHGrid.DataGridHeader('APC Codes', '', '', 'left'),
			280,
			MHGrid.ColType_Text
		),
		new MHGrid.DataGridColumn(
			provider.providerId,
			MHGrid.DataGridHeaderBuilder(format.initCap(provider.name), 'center', '150'),
			200,
			MHGrid.ColType_Self
		)
	);

	if (marketData && marketData.PSACompetitors && marketData.PSACompetitors.length > 0) {
		for (let i = 0, c = 0; i < marketData.PSACompetitors.length && c < 5; i++) {
			if (
				marketData.PSACompetitors[i].prvdr_num !== provider.providerId &&
				marketData.PSACompetitors[i].prvdr_num !== 'other' &&
				marketData.PSACompetitors[i].name !== 'Totals'
			) {
				c++;
				Cols.Add(
					new MHGrid.DataGridColumn(
						marketData.PSACompetitors[i].prvdr_num,
						MHGrid.DataGridHeaderBuilder(
							format.initCap(marketData.PSACompetitors[i].name),
							'center',
							'150'
						),
						200,
						MHGrid.ColType_Self
					)
				);
			}
		}
	}
	return Cols;
};
const BuildGridHeaderRevGroups = (provider, marketData) => {
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn(
			'metric',
			new MHGrid.DataGridHeader('Revenue Code Groups', '', '', 'left'),
			280,
			MHGrid.ColType_Metric
		),
		new MHGrid.DataGridColumn(
			provider.providerId,
			MHGrid.DataGridHeaderBuilder(format.initCap(provider.name), 'center', '150'),
			200,
			MHGrid.ColType_Self
		)
	);

	if (marketData && marketData.PSACompetitors && marketData.PSACompetitors.length > 0) {
		for (let i = 0, c = 0; i < marketData.PSACompetitors.length && c < 5; i++) {
			if (
				marketData.PSACompetitors[i].prvdr_num !== provider.providerId &&
				marketData.PSACompetitors[i].prvdr_num !== 'other' &&
				marketData.PSACompetitors[i].name !== 'Totals'
			) {
				c++;
				Cols.Add(
					new MHGrid.DataGridColumn(
						marketData.PSACompetitors[i].prvdr_num,
						MHGrid.DataGridHeaderBuilder(
							format.initCap(marketData.PSACompetitors[i].name),
							'center',
							'150'
						),
						200,
						MHGrid.ColType_Self
					)
				);
			}
		}
	}
	return Cols;
};

const RenderTop20ICD10s = (fieldDef, onYearChanged, provider, data, outpatient_icd10) => {
	// Create Column Headers for Competitors Grid
	let Cols = BuildGridHeaderICD10(provider, data);
	let Title = new MHGrid.DataGridTitle(
		'International Classification of Diseases, 10th Revision (ICD-10) Code',
		'',
		'',
		3,
		outpatient_icd10.Years,
		onYearChanged,
		false
	);

	let SortRows = (a, b) => {
		let r1 = a[provider.providerId] ? a[provider.providerId].value : 0;
		let r2 = b[provider.providerId] ? b[provider.providerId].value : 0;

		if (r1 && r2) return r1 > r2 ? -1 : r1 === r2 ? 0 : 1;
		else return 0;
	};

	return (
		<Fragment>
			{Title.render()}
			<div className="sticky_table_header">
				<MHGrid.DataGrid columns={Cols} rows={[]} showHeaders={true} headersOnly={true} />
			</div>
			<MHGrid.DataGrid
				columns={Cols}
				rows={outpatient_icd10.Data.sort(SortRows)}
				showHeaders={false}
				fieldDef={fieldDef}
			/>
		</Fragment>
	);
};

const RenderTop20APCs = (fieldDef, provider, data, outpatient_apc) => {
	// Create Column Headers for Competitors Grid
	let Cols = BuildGridHeaderAPC(provider, data);

	let Title = new MHGrid.DataGridTitle(
		'Total Payments for the Top 20 Medicare Patient Volume by Ambulatory Patient Classification (APC)',
		'',
		'',
		3
	);

	let BenchmarkData = [];

	if (data && data.Benchmark && data.Benchmark.length > 0) {
		BenchmarkData = data.Benchmark[0].rows;

		for (let i = 0; i < BenchmarkData.length; i++) {
			if (
				BenchmarkData[i].name &&
				(BenchmarkData[i].name.toUpperCase() === 'TOTALS' ||
					BenchmarkData[i].name.toUpperCase() === 'TOTAL')
			)
				BenchmarkData[i].fieldStyle = 'TOTAL_LINE';
		}
	}

	let SortRows = (a, b) => {
		let r1 = a[provider.providerId] ? a[provider.providerId].value : 0;
		let r2 = b[provider.providerId] ? b[provider.providerId].value : 0;

		if (r1 && r2) return r1 > r2 ? -1 : r1 === r2 ? 0 : 1;
		else return 0;
	};

	return (
		<Fragment>
			{Title.render()}
			<div className="sticky_table_header">
				<MHGrid.DataGrid columns={Cols} rows={[]} showHeaders={true} headersOnly={true} />
			</div>
			<MHGrid.DataGrid
				columns={Cols}
				rows={outpatient_apc.Data.sort(SortRows)}
				showHeaders={false}
				fieldDef={fieldDef}
			/>
		</Fragment>
	);
};
const RenderTop20RevGroups = (fieldDef, provider, data) => {
	// Create Column Headers for Competitors Grid
	let Cols = BuildGridHeaderRevGroups(provider, data);

	let Title = new MHGrid.DataGridTitle(
		'Ambulatory Service Payments Grouped by Revenue Code',
		'',
		'',
		3
	);

	let BenchmarkData = [];

	if (data && data.Benchmark && data.Benchmark.length > 0) {
		BenchmarkData = data.Benchmark[0].rows;

		for (let i = 0; i < BenchmarkData.length; i++) {
			if (
				BenchmarkData[i].name &&
				(BenchmarkData[i].name.toUpperCase() === 'TOTALS' ||
					BenchmarkData[i].name.toUpperCase() === 'TOTAL')
			)
				BenchmarkData[i].fieldStyle = 'TOTAL_LINE';
		}
	}

	return (
		<MHGrid.DataGrid
			columns={Cols}
			rows={misc.filterMetrics([])}
			fieldDef={fieldDef}
			title={Title}
		/>
	);
};

const RenderOutpatientPage = (
	fieldDef,
	onYearChanged,
	provider,
	marketData,
	outpatient_icd10,
	outpatient_apc
) => {
	return (
		<Fragment>
			{RenderTop20ICD10s(fieldDef, onYearChanged, provider, marketData, outpatient_icd10)}
			{RenderTop20APCs(fieldDef, provider, marketData, outpatient_apc)}
		</Fragment>
	);
};

const RenderBody = (
	fieldDef,
	onYearChanged,
	providers,
	marketData,
	outpatient_icd10,
	outpatient_apc
) => {
	let data = null;

	if (providers) {
		if (providers.length > 1) {
			const tabs = [];
			providers.forEach(x => {
				if (marketData) {
					data = _.find(marketData.result, j => {
						return j.providerId === x.providerId;
					});
				}
				tabs.push(
					<Tab eventKey={x.providerId} title={x.name}>
						{RenderOutpatientPage(
							fieldDef,
							onYearChanged,
							x,
							data,
							outpatient_icd10,
							outpatient_apc
						)}
					</Tab>
				);
			});
			return <Tabs id=""> {tabs} </Tabs>;
		} else if (providers.length === 1) {
			if (marketData && Array.isArray(marketData.result)) {
				data = _.find(marketData.result, j => {
					return j.providerId === providers[0].providerId;
				});
			}

			return RenderOutpatientPage(
				fieldDef,
				onYearChanged,
				providers[0],
				data,
				outpatient_icd10,
				outpatient_apc
			);
		}
	} else return <div />;
};

class Outpatient extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentYear: this.props.outpatient_icd10.ActiveYear
		};
		this.onYearChanged = this.onYearChanged.bind(this);
	}
	UNSAFE_componentWillMount() {
		this.props.loadICD10(misc.getFacilityIds());
		this.props.loadAPC(misc.getFacilityIds());
	}

	onYearChanged(year) {
		let state = this.state;
		this.props.outpatient_icd10.ActiveYear = year;
		this.props.outpatient_apc.ActiveYear = year;
		state.currentYear = year;
		this.setState(state);
	}

	render() {
		const bodyRender = !this.props.loading ? (
			RenderBody(
				this.props.fieldDef,
				this.onYearChanged,
				this.props.providers,
				this.props.marketData,
				this.props.outpatient_icd10,
				this.props.outpatient_apc
			)
		) : (
			<div className="container">
				<div className="row">
					<LoadingSpinner />
				</div>
			</div>
		);

		return <div className="outpatient">{bodyRender}</div>;
	}
}

Outpatient.propTypes = {
	loadICD10: PropTypes.func.isRequired,
	setError: PropTypes.func.isRequired,
	setLoaded: PropTypes.func.isRequired,
	outpatient_apc: PropTypes.array.isRequired,
	outpatient_icd10: PropTypes.array.isRequired,
	marketData: PropTypes.array.isRequired,
	error: PropTypes.oneOfType(PropTypes.bool.isRequired, PropTypes.object.isRequired)
};

Outpatient.defaultProps = {
	loadICD10: () => console.warn('load not passed'),
	loadAPC: () => console.warn('load not passed'),
	setError: () => console.warn('setError not passed'),
	setLoaded: () => console.warn('setLoaded not passed'),
	outpatient_icd10: [],
	outpatient_apc: [],
	error: null,
	marketData: []
};

const mapStateToProps = store => {
	return {
		outpatient_icd10: store.outpatient_icd10.data,
		outpatient_apc: store.outpatient_apc.data,
		marketData: store.marketData.MarketShareData,
		error: store.marketData.error,
		loading: store.marketData.fetching,
		providers: store.prvdr.Providers,
		fieldDesc: store.fieldDef.fields.hosp
	};
};

// const mapStateToProps = ({
// 	prvdr: { Providers: providers },
// 	marketData: { error, fetching: loading, MarketShareData: marketData = [] },
// 	fieldDef: { FieldDefinitionList: fieldDesc },
// 	outpatient: { data: outpatients }
// }) => ({
// 	outpatients,
// 	marketData,
// 	error,
// 	loading,
// 	providers,
// 	fieldDesc
// });

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			loadICD10,
			loadAPC,
			setError,
			setLoaded
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Outpatient);
