import React from 'react';
import { connect } from 'react-redux';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import LoadingSpinner from '../../../tools/loadingSpinner';
import * as _ from 'lodash';
import { AddNewFields } from '../../../core/actions/GlobalActions';
import * as HospQualityFields from '../../../hosp/Quality/QualityFields';
import * as HospFinancialFields from '../../../hosp/Financial/FinancialFields';
import * as HospOperationalFields from '../../../hosp/Benchmark/BenchmarkFields';
import * as HospFacilityFields from '../../../hosp/Common/FacilityFields';
import * as SnfQualityFields from '../../../snf/Quality/QualityFields';
import * as SnfFinancialFields from '../../../snf/Financial/FinancialFields';
import * as SnfOperationalFields from '../../../snf/Benchmark/BenchmarkFields';
import * as SnfFacilityFields from '../../../snf/Common/FacilityFields';
import { faChevronLeft } from '@fortawesome/fontawesome-free-solid';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
// import './index.css';

const mapStateToProps = store => {
	return {
		fieldDef: store.fieldDef
	};
};

const itemLimits = 50;

const staticColumns = ['prvdr_num', 'facility_name', 'facility_city', 'fy_year']; //note you need this duplicate to fetch column names

class availableFields extends React.Component {
	constructor(props) {
		super(props);
		this.fieldsChanged = this.props.fieldsChanged.bind(this);
		this.domainChanged = this.props.domainChanged.bind(this);
		this.domainChanged = this.domainChanged.bind(this);
		this.resetFields = this.props.resetFields.bind(this);
		this.toggleRow = this.toggleRow.bind(this);
		this.state = {
			selected: {},
			initiated: false,
			domain: this.props.domain || 'hosp',
			selection: [],
			selectAll: 0
		};
	}

	FieldCategories() {
		let fields = [];
		let allFields = _.uniq(
			this.props.fieldDef.fields[this.state.domain].map(a => {
				return a.FieldName;
			})
		);
		if (this.state.domain === 'hosp') {
			fields.push(HospFacilityFields.Info());
			fields.push(HospFinancialFields.Info());
			fields.push(HospOperationalFields.Info());
			fields.push(HospQualityFields.Info());
		}
		if (this.state.domain === 'snf') {
			fields.push(SnfFacilityFields.Info());
			fields.push(SnfFinancialFields.Info());
			fields.push(SnfOperationalFields.Info());
			fields.push(SnfQualityFields.Info());
		}
		fields.push({
			topic: null,
			groups: [
				{
					key: 'allFinancialMetrics',
					label: 'All Metrics',
					fields: allFields
				}
			]
		});
		return fields;
	}
	
	componentDidMount()
	{
		this.initiateFields();
		if(this.props.regFunction)
			this.props.regFunction(this.state.domain, 'metrics', this);
	}

	domainChanged() {
		this.initiateFields();
	}


	setMetrics(metrics) {
		let state = this.state;

		if(metrics && Array.isArray(metrics))
		{
			metrics.forEach(x => {
				state.selected[x] = true;
			});
		}
		this.setState(state);
	}

	initiateFields() {
		let requestedFields = ['prvdr_num', 'facility_name', 'facility_city', 'fy_year'];

		function add(item) {
			if (requestedFields.indexOf(item) === -1) {
				requestedFields.push(item);
			}
		}
		if (this.state.domain == 'hosp' || this.state.domain == 'snf') {
			let key = this.FieldCategories();
			this.SelectFieldGroup(key[0].groups[0]);
			key.map(a => {
				return a.groups.map(b => {
					return b.fields.map(c => add(c));
				});
			});
		}
		if (requestedFields.length > 0) {
			this.props.dispatch(
				AddNewFields(
					requestedFields,
					this.props.fieldDef.fields[this.state.domain] || [],
					this.state.domain
				)
			);
		}
	}

	getAvailableFields() {
		function getfields(options, fieldDef, domain) {
			return fieldDef && fieldDef.fields[domain] && options && options.length > 0
				? _.filter(fieldDef.fields[domain], function(data) {
						return options.indexOf(data.FieldName) > -1;
				  }).map(data => {
						return {
							Field: data.FieldShortName,
							Description: data.FieldDescription,
							Id: data.FieldName
						};
				  })
				: [];
		}
		if (this.props.fieldDef.fetched) {
			return {
				options: _.uniqBy(
					getfields(this.state.selection.fields, this.props.fieldDef, this.props.domain),
					'Field'
				)
			};
		}
	}

	componentDidUpdate() {
		if (this.props.fieldDef.fieldsReset && this.state.initiated === true) {
			this.clearSelected();
		}
	}

	limitReached() {
		return (
			<h4 className="container-fluid text-center my-0 bg-warning p-1 text-strong">
				Limit of {itemLimits} metrics reached!
			</h4>
		);
	}

	clearSelected() {
		const newSelected = Object.assign({}, this.state.selected);
		_.forOwn(newSelected, function(_e, key) {
			newSelected[key] = false;
		});
		this.setState({
			selected: newSelected,
			initiated: false
		});
		this.resetFields();
		this.initiateFields();
	}

	toggleRow(firstName) {
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[firstName] = !this.state.selected[firstName];
		if (_.sum(Object.values(newSelected)) <= itemLimits) {
			this.setState({
				selected: newSelected,
				initiated: true
			});
			this.fieldsChanged(firstName);
		}
	}

	getFieldOptions() {
		let cats = [];
		switch (this.state.domain) {
			case 'hha':
				break;
			default:
			case 'hosp':
			case 'snf':
				let ops = this.FieldCategories();
				cats = ops.map(a => {
					return a;
				});
				break;
		}

		let options = [];

		cats.map(a => {
			if (a.topic && a.topic.length > 0) {
				options.push(
					<li className="list-group-item bg-secondary">
						<strong>{a.topic}</strong>
					</li>
				);
				if (a.groups && a.groups.length) {
					a.groups.map(b => {
						options.push(
							<li
								className={
									this.state.selection.key == b.key
										? 'list-group-item tab-button selected-tab-option'
										: 'list-group-item tab-button'
								}
								onClick={() => {
									this.SelectFieldGroup(b);
								}}
							>
								{b.label}
								{this.state.selection.key == b.key ? (
									<b>
										<FontAwesomeIcon icon={faChevronLeft} />
										{'    '}
									</b>
								) : (
									''
								)}
							</li>
						);
					});
				}
			} else {
				if (a.groups && a.groups.length) {
					a.groups.map(b => {
						options.push(
							<li
								className={
									this.state.selection.key == b.key
										? 'list-group-item tab-button selected-tab-option'
										: 'list-group-item tab-button'
								}
								onClick={() => {
									this.SelectFieldGroup(b);
								}}
							>
								<b>{b.label}</b>
							</li>
						);
					});
				}
			}
		});

		return options;
	}

	getSelectedFields() {
		if (this.props.fieldDef.fields[this.props.domain]) {
			let fields = _.uniqBy(
				this.props.fieldDef.fields[this.props.domain].map(data => {
					return {
						Field: data.FieldShortName,
						Description: data.FieldDescription,
						Id: data.FieldName
					};
				}),
				'Field'
			);
			let selectedItems = Object.assign({}, this.state.selected);

			let options = [];
			fields.map(a => {
				if (selectedItems[a.Id]) {
					options.push(
						<li className={'list-group-item'}>
							<span>
								{a.Field}
							</span>
							<button
								className="clearBttn"
								style={{ background: '#F44E21' }}
								onClick={() => this.toggleRow(a.Id)}
							>
								<i className="fa fa-times" />
							</button>
						</li>
					);
				}
			});
			return options;
		} else return [];
	}

	getFields() {
		let options = [];
		let fields = this.getAvailableFields();
		if (fields && fields.options.length > 0) {
			fields.options.map(b => {
				options.push(
					<li
						className={
							this.state.selected && this.state.selected.indexOf(b.Id) > -1
								? 'list-group-item tab-button bg-secondary'
								: 'list-group-item tab-button'
						}
						onClick={() => {
							this.toggleRow(b);
						}}
					>
						<text className="ml-3" title={b.Description}>
							{b.Field}
						</text>
					</li>
				);
			});
		}
		return options;
	}

	SelectFieldGroup(value) {
		this.setState({
			selection: value,
			selectAll: 0
		});
	}

	toggleSelectAll() {
		let newSelected = Object.assign({}, this.state.selected);
		let selectedAll = this.state.selectAll;
		selectedAll = selectedAll == 0 ? 1 : 0;
		_.forEach(this.state.selection.fields, function(value) {
			newSelected[value] =
				selectedAll == 1 && _.sum(Object.values(newSelected)) < itemLimits ? true : false;
		});

		this.state.selection.fields.map(a => {
			if (newSelected[a] == true) {
				this.fieldsChanged(a);
			}
		});

		this.state.selectAll = selectedAll;

		if (_.sum(Object.values(newSelected)) <= itemLimits) {
			this.setState({ selected: newSelected, initiated: true, selectAll: selectedAll });
		}
	}

	render() {

		// let propFields = this.props.fieldDef.fields ? this.props.fieldDef.fields : [];
		let categories =
			this.props.fieldDef.fields[this.state.domain].length > 0 ? this.getFieldOptions() : null;
		let fields =
			this.props.fieldDef.fields[this.state.domain].length > 0 ? this.getAvailableFields() : null;
		let message = '';
		if (_.sum(Object.values(this.state.selected)) > itemLimits - 1) {
			message = this.limitReached();
		}
		let dimRows = fields ? _.uniqBy(fields.options, 'Field') : [];

		let selected_fields =
			this.props.fieldDef.fields[this.state.domain].length > 0 ? this.getSelectedFields() : null;

		return (
			<>
				{this.props.fieldDef.fields[this.props.domain] &&
				this.props.fieldDef.fields[this.props.domain].length > 0 ? (
					<div className="row dataExplorerMetrics">
						<div className="col-md-4 col-sm-6 col-12">
							<ul className="list-group">
								{categories}
							</ul>
						</div>
						<div className="col-md-4 col-sm-6 col-12">
							{this.props.fieldDef.fields[this.props.domain] &&
							this.props.fieldDef.fields[this.props.domain].length > 0 ? (
								<ReactTable
									data={dimRows}
									filterable
									resizable={false}
									columns={[
										{
											Header: !this.state.selection ? (
												'Selected Fields'
											) : (
												<div className="facInfo">
													<strong>
														{this.state.selection ? this.state.selection.label : 'Selected Fields'}
													</strong>
													<button
														id="checkAll" 
														className="dataExplorerBttn"
														ref={input => {
															if (input) {
																input.indeterminate = this.state.selectAll === 2;
															}
														}}
														onClick={() => this.toggleSelectAll()}
													>
														{this.state.selectAll === 0 ? 'Check All' : 'Uncheck All'}
													</button>
												</div>
											),
											headerClassName: 'list-group-item bg-secondary',
											headerStyle: { borderRadius: '0px', borderTop: '0px' },
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, {
													keys: ['Field', 'Description']
												}),
											filterAll: true,
											accessor: 'Field',
											Cell: row => {
												return (
													<div
														className="list-group-item tab-button border-0"
														style={{
															backgroundColor: 'transparent'
														}}
														title={row.original.Description}
													>
														{' '}
														{row.value}{' '}
													</div>
												);
											}
										}
									]}
									defaultPageSize={200}
									minRows={0}
									className="-highlight d-block"
									style={{ width: '100%', height: '86vh', overflowY: 'auto' }}
									showPagination={false}
									getTrProps={(state, rowInfo) => {
										if (rowInfo && rowInfo.row) {
											return {
												onClick: e => {
													this.toggleRow(rowInfo.original.Id);
												},
												style: {
													background: this.state.selected[rowInfo.original.Id] ? '#F1F1F1' : '',
													paddingLeft: '10px'
												}
											};
										} else {
											return {};
										}
									}}
								/>
							) : (
								<LoadingSpinner />
							)}{' '}
						</div>
						<div className="col-md-4 col-12">
							<>{message}</>
							<div className="selectedFields bg-secondary">
								<strong>Selected Fields</strong>
								<button
									id="clearAll" 
									className="dataExplorerBttn" 
									onClick={() => this.clearSelected()}
								>
									{'Clear All'}
								</button>
							</div>
							<div className="border-top">{selected_fields}</div>
						</div>
					</div>
				) : (
					<LoadingSpinner />
				)}
			</>
		);
	}
}
export default connect(mapStateToProps)(availableFields);
