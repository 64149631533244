import * as misc from '../../lib/misc';
import Amplify from 'aws-amplify';
import * as LoginActions from '../LoginPage/LoginActions';
import * as ApiUtil from '../../lib/ApiUtil';

class Auth {
	static get ReturnUrl() {
		return misc.baseUrl + '/init';
	}
	static get SignOutUrl() {
		return `/iump-account-page/?ihcdologout=1 `;
	}
	static get AuthURL() {
		return this.GetAuthURL(Auth.ReturnUrl);
	}
	static GetAuthURL(returnURL) {
		return 'iump-login';
	}
	static get AuthTarget() {
		if (process.env.REACT_APP_SECRET_CODE === 'stage') {
			return 'https://hmpmetrics.com/';
		} else {
			return 'https://hmpmetrics.com/';
		}
	}
	/**
	 * Authenticate a user. Save a token string in Local Storage
	 *
	 * @param {string} token
	 */
	static authenticateUser(token) {
		sessionStorage.setItem('token', token);
	}

	
	static storeUserProfile(profile) {
		sessionStorage.setItem('profile', JSON.stringify(profile));
		sessionStorage.setItem('profileEmail', profile.Email);
	}
	/**
	 * Check if a user is authenticated - check if a token is saved in Local Storage
	 *
	 * @returns {boolean}
	 */
	static isUserAuthenticated() {
		let profile = Auth.getUserProfile();
		if(profile && profile.Authenticated)
			return true;
		else
			return false;
	}

	static isUserHospAuthenticated() {
		let profile = Auth.getUserProfile();
		if (profile) {
			return profile && (profile.Roles &= 32) > 0;
		} else return false; 

		
	}

	static isUserSnfAuthenticated() {
		/* let profile = this.getUserProfile();
		if (profile) {
			return profile && (profile.Roles &= 64) > 0;
		} else return false; */
		return Auth.isUserAuthenticated();
	}

	/**
	 * Deauthenticate a user. Remove a token from Local Storage.
	 *
	 */
	static deauthenticateUser() {
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('profile');
		sessionStorage.removeItem('profileEmail');
	}

	/**
	 * Get a token value.
	 *
	 * @returns {string}
	 */

	static getToken() {
		return sessionStorage.getItem('token');
	}
	static getUserProfile() {
		let profile = sessionStorage.getItem('profile');
		if (profile) return JSON.parse(profile);
		else return null;
	}
	static getUserEmail() {
		return sessionStorage.getItem('profileEmail');
	}

	static async getUserToken() {
		try
		{
			let data = await Amplify.Auth.currentSession();
			

			let accessToken = data.accessToken.jwtToken;
			let idToken = data.idToken.jwtToken;
			let refreshToken = data.refreshToken.token;
			//let jwt = accessToken.getJwtToken()
			
			return {
				AccessToken: accessToken,
				IdToken: idToken,
				RefreshToken: refreshToken
			}
		} 
		catch(err)
		{
			
			// No current user
			return null;
		}
	}

	static async getAuthUser() {
		try {
			return await Amplify.Auth.currentAuthenticatedUser();
		}
		catch (err) {
			return err;
		}
	}
	static async getUserCredentials() {
		try
		{
				let creds = await Amplify.Auth.currentUserCredentials();
				return creds;
		} 
		catch(err)
		{
				return err;
		}
	}
	static getSessionUser() {
		return sessionStorage.getItem('authUser');
	}
	static clearSessionUser() {
		sessionStorage.removeItem('authUser');
	}

	static async resetSession() {

		try 
		{
			this.deauthenticateUser();
			let awsToken = await this.getUserToken();
			let userSession = await ApiUtil.AuthPost('/login', {creds: awsToken});
			let token = userSession.data.session.token;
			let profile = userSession.data.session.profile;

			this.authenticateUser(token);
			this.storeUserProfile(profile);
	
		}
		catch(err)
		{
			throw err;
		}
	}


}

export default Auth;