import BenchmarkUtil from '../../lib/BenchmarkUtil';
import * as Types from '../actions/ActionTypes';
import * as misc from '../../lib/misc';
export class DefaultState {
	constructor() {
		this.Benchmark = [];
		this.error = null;
		this.fetching = false;
		this.fetched = false;
		this.whereChanged = false;
		this.dataKey = null;
	}
}

export const ReducerHelper = (Tag, state, action, reset = false) => {
	switch (action.type) {
		case Tag + '_PENDING': {
			return { ...state, fetching: true, whereChanged: false };
		}
		case Tag + '_FULFILLED': {
			if ((Array.isArray(state.Benchmark) && state.Benchmark.length < 1) || reset) {
				const data = new BenchmarkUtil(action.payload.data.result);
				return {
					...state,
					fetching: false,
					fetched: true,
					whereChanged: false,
					Benchmark: data,
					dataKey: misc.Guid()
				};
			} else {
				state.Benchmark.AppendData(action.payload.data.result);
				return {
					...state,
					fetching: false,
					fetched: true,
					whereChanged: false,
					dataKey: misc.Guid()
				};
			}
		}
		case Tag + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				whereChanged: false,
				error: action.payload
			};
		}
		case 'SESSION_STORAGE_WHERE_CHANGE':
		case 'USER_LOGIN_FETCH_FULFILLED':
		case Tag + '_RESET':
		case Types.ASSIGNFACILITY_COMPLETE: {
			return {
				...state,
				whereChanged: true,
				fetching: false,
				fetched: false,
				Benchmark: []
			};
		}
		default:
			return { ...state };
	}
};
export class GenericDefaultState {
	constructor() {
		this.data = null;
		this.error = null;
		this.fetching = false;
		this.fetched = false;
		this.dataKey = null;
	}
}

export const GenericReducerHelper = (Tag, state, action) => {
	switch (action.type) {
		case Tag + '_PENDING': {
			return { ...state, fetching: true, whereChanged: false };
		}
		case Tag + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				data: action.payload.data.result,
				dataKey: misc.Guid()
			};
		}
		case Tag + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		case 'SESSION_STORAGE_WHERE_CHANGE':
		case 'USER_LOGIN_FETCH_FULFILLED':
		case Types.ASSIGNFACILITY_COMPLETE: {
			return new GenericDefaultState();
		}
		default:
			return { ...state };
	}
};
