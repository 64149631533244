import axios from 'axios';
import Auth from '../../core/Security/Auth';
import * as whereBuilder from '../../lib/whereBuilder';
import * as misc from '../../lib/misc';
import {
	HospFinancialFields,
	BalanceStatementFields,
	IncomeStatementList
} from './FinancialFields';
import * as ActionTypes from '../Common/ActionTypes';

const apiUrl = misc.apiUrl;

const getCustomHeader = function() {
	return {
		headers: { token: Auth.getToken() }
	};
};

export function fetchHospFinancials(providerNumber) {
	let filterwhere = JSON.parse(sessionStorage.getItem('where'));
	return {
		type: ActionTypes.FINANCIAL_GET_INC_METRICS,
		payload: axios.post(
			apiUrl + '/benchmark-data',
			{ providerIds: providerNumber, fields: HospFinancialFields, where: filterwhere },
			getCustomHeader()
		)
	};
}

export function fetchBalanceSheet(providerNumber) {
	let filterwhere = JSON.parse(sessionStorage.getItem('where'));

	return {
		type: 'BALANCESHEET_FETCH',
		payload: axios.post(
			apiUrl + '/balancesheet',
			{ providerIds: providerNumber, fields: BalanceStatementFields, where: filterwhere },
			getCustomHeader()
		)
	};
}

export function fetchIncomeStatement(providerNumber) {
	let filterwhere = JSON.parse(sessionStorage.getItem('where'));
	return {
		type: 'INCOMESTATEMENT_FETCH',
		payload: axios.post(
			apiUrl + '/incomestatement',
			{ providerIds: providerNumber, fields: IncomeStatementList, where: filterwhere },
			getCustomHeader()
		)
	};
}

export function fetchBenchmarkReport(facilityList, year) {
	return {
		type: 'BENCHMARKREPORT_FETCH',
		payload: axios.post(apiUrl + '/benchmarkreport', { facilityList, year }, getCustomHeader())
	};
}
export function fetchBenchmarkReportNational(facilityList, year) {
	return {
		type: 'BENCHMARKREPORTNATIONAL_FETCH',
		payload: axios.post(
			apiUrl + '/benchmarkreportnational',
			{ facilityList, year },
			getCustomHeader()
		)
	};
}
export function fetchBenchmarkReportState(facilityList, year) {
	return {
		type: 'BENCHMARKREPORTSTATE_FETCH',
		payload: axios.post(apiUrl + '/benchmarkreportstate', { facilityList, year }, getCustomHeader())
	};
}
export function fetchBenchmarkReportBedSize(facilityList, year) {
	return {
		type: 'BENCHMARKREPORTBEDSIZE_FETCH',
		payload: axios.post(
			apiUrl + '/benchmarkreportbedsize',
			{ facilityList, year },
			getCustomHeader()
		)
	};
}

export function fetchBenchmarkFacilityList(searchCriteria) {
	return {
		type: 'BENCHMARK_SEARCH_FETCH',
		payload: axios.post(apiUrl + '/benchmarkfacilitylist', searchCriteria, getCustomHeader())
	};
}

export function fetchGetSaveBenchmark(UserId) {
	return {
		type: 'GETSAVEBENCHMARK_FETCH',
		payload: axios.post(apiUrl + '/getsavebenchmark', '1', getCustomHeader())
	};
}

export function fetchBenchmarkReportCustom(TemplateId, year) {
	return {
		type: 'BENCHMARKREPORTCUSTOM_FETCH',
		payload: axios.post(apiUrl + '/benchmarkreportcustom', { TemplateId, year }, getCustomHeader())
	};
}

export function fetchBenchmarkReportFacility(facilityList, year) {
	return {
		type: 'BENCHMARKREPORTFACILITY_FETCH',
		payload: axios.post(
			apiUrl + '/benchmarkreportfacility',
			{ facilityList, year },
			getCustomHeader()
		)
	};
}

export function fetchBenchmarkRowCustom(UserId) {
	return {
		type: 'BENCHMARKROWCUSTOM_FETCH',
		payload: axios.post(apiUrl + '/benchmarkrowcustom', '1', getCustomHeader())
	};
}
export function saveBenchmarkRowCustom(rowedit) {
	return {
		type: 'BENCHMARKROWCUSTOM_FETCH',
		payload: axios.post(apiUrl + '/savebenchmarkrowcustom', rowedit, getCustomHeader())
	};
}

export function benchmarkReportMultipleYear(yearval) {
	return {
		type: 'BENCHMARKREPORTMULTIPLEYEAR_FETCH',
		payload: axios.post(apiUrl + '/benchmarkreportmultipleyear', yearval, getCustomHeader())
	};
}

// Accordion Actions
export function fetchDetailedBalanceSheet(providerNumber) {
	return {
		type: 'DETAILED_BALANCESHEET_FETCH',
		payload: axios.post(apiUrl + '/detailedbalancesheet', providerNumber, getCustomHeader())
	};
}

// export function fetchDetailedIncomeStatement(providerNumber) {
// 	return {
// 		type: 'DETAILED_INCOMESTATEMENT_FETCH',
// 		payload: axios.post(apiUrl + '/detailed-incomestatement', providerNumber, getCustomHeader())
// 	};
// }

//Pagination Actions
export const bencmarkPaginationChange = newPage => {
	return {
		type: 'PAGINATION_BENCHMARK_CHANGE',
		page: newPage
	};
};

export function hospCostReportExportService(provider) {
	if (Auth.isUserHospAuthenticated()) {
		return {
			type: ActionTypes.HOSP_COST_REPORT_EXPORT_LIST,
			payload: axios.post(
				apiUrl + '/hosp-cost-report-export-service',
				{ facilityId: provider, domain: 'hosp' },
				getCustomHeader()
			)
		};
	} else {
		return null;
	}
}
