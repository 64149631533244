import React from 'react';
export const marketShareMap = () => {
	return (
		<article className="demopara">
			<h2>Market Share</h2>
			<p>
				<b>Primary Service Area (PSA):</b> A hospital’s primary service area is determined by the
				lowest number of ZIP codes from which the hospital draws 80% of its patients. This
				collection of ZIP codes is considered the Primary Service Area.
			</p>
			<br />
			<p>
				<b>Secondary Service Area (SSA):</b> A hospital’s secondary service area is determined by
				the ZIP codes from which the hospital draws the next 10% of patient outside of the PSA. This
				collection of ZIP codes is considered the Secondary Service Area.
			</p>
			<br />
			<p>
				<b>Tertiary Service Area (TSA):</b> A hospital’s tertiary service area is determined by the
				ZIP codes from which the hospital draws the remaining patients outside of the PSA and SSA.
			</p>
			<br />
			<p>
				<b>Source of Information</b> Hospital Service Area File - The Hospital Service Area file is
				a summary of calendar year Medicare inpatient hospital fee-for-service claims data. It
				contains number of discharges, total days of care, and total charges summarized by hospital
				provider number and the ZIP code of the Medicare beneficiary.
			</p>
		</article>
	);
};
