import axios from 'axios';
import Auth from '../../../core/Security/Auth';
import * as Types from '../../Common/ActionTypes';
import * as misc from '../../../lib/misc';
const apiUrl = misc.apiUrl;

const getCustomHeader = function() {
	return {
		headers: { token: Auth.getToken() }
	};
};
export function loadMarketShare(year, providerNumber) {
	return {
		type: Types.SMD_Load_MarketShareData,
		payload: axios.post(
			apiUrl + '/marketshare',
			{ year, providerIds: providerNumber },
			getCustomHeader()
		)
	};
}

export function loadPathways(providerNumber) {
	return {
		type: Types.SMD_Load_MarketPathways,
		payload: axios.post(
			apiUrl + '/marketshare-pathways',
			{ providerIds: providerNumber },
			getCustomHeader()
		)
	};
}
