export const FINANCIAL_GET_INC_METRICS = 'SNF_FINANCIAL_GET_INCOME_METRICS';
export const FINANCIAL_GET_ASSETS_METRICS = 'SNF_FINANCIAL_GET_ASSET_METRICS';
export const FINANCIAL_GET_CALLOUT_METRICS = 'SNF_FINANCIAL_GET_CALLOUT_METRICS';

export const BENCHMARK_GET_METRICS = 'SNF_BENCHMARK_GET_METRICS';
export const QUALITY_GET_METRICS = 'SNF_QUALITY_GET_METRICS';
export const CHART_BENCHMARK_GET_METRICS = 'CHART_BENCHMARK_GET_METRICS';

export const SNF_OCCUPANCY_METRICS = 'SNF_OCCUPANCY_METRICS';
export const SNF_RANKING_SCORE = 'SNF_RANKING_SCORE';
export const SNF_SUMMARY_METRICS = 'SNF_SUMMARY_METRICS';
export const SNF_SUMMARY_QUALITY_METRICS = 'SNF_SUMMARY_QUALITY_METRICS';
export const SNF_SMD_COMPETITOR_METRICS = 'SNF_SMD_COMPETITOR_METRICS';
export const SNF_COMPETITOR_LIST = 'SNF_COMPETITOR_LIST';
export const SNF_HOSP_DISCHARGES = 'SNF_HOSP_DISCHARGES';
export const SNF_SMD_MARKETSHARE = 'SNF_SMD_MARKETSHARE';
export const SMD_LOAD_DEMOGRAPHICS = 'SMD_LOAD_DEMOGRAPHICS';
export const SNF_FILE_SERVICE_LIST = 'SNF_FILE_SERVICE_LIST';
export const SNF_COST_REPORT_EXPORT_LIST = 'SNF_COST_REPORT_EXPORT_LIST';

export const SMD_DEMOGRAPHICS = 'SMD_DEMOGRAPHICS';
export const SNF_SMD_PATIENT_FLOW = 'SNF_SMD_PATIENT_FLOW';
export const SNF_QUARTILE_CHART_FIELDS = 'SNF_QUARTILE_CHART_FIELDS';
