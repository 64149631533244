import React from 'react';
import { connect } from 'react-redux';
import BenchmarkDetail from './BenchmarkDetail';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import * as misc from '../../lib/misc';
import Auth from '../../core/Security/Auth';
import LoadingSpinner from '../../tools/loadingSpinner';
import LandingPage from '../../core/DefaultPages/marketingInfo/HospBenchmarkInfo';
import * as whereBuilder from '../../lib/whereBuilder';
import * as Actions from '../../core/actions/ActionHelper';
import * as ActionTypes from '../Common/ActionTypes';
import * as Fields from './BenchmarkFields';
import * as fieldDefActions from './../../core/actions/GlobalActions';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.hospBenchmarkData.whereChanged,
		data: store.hospBenchmarkData.Benchmark,
		dataLoaded: store.hospBenchmarkData.fetched,
		dataLoading: store.hospBenchmarkData.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetUtilizationData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'hosp',
					Fields.UtilizationMetrics()
				)
			),
		GetLaborData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'hosp',
					Fields.LaborMetrics
				)
			),
		GetFinancialData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'hosp',
					Fields.AllFinancialMetrics()
				)
			),
		updateWhereChange: () => dispatch(fieldDefActions.sessionStorageWhereChange()),
		GetFieldInfo: (fields, basis) => {
			dispatch(fieldDefActions.AddNewFields(fields, basis, 'hosp'));
		}
	};
};

class BenchmarkDetailPage extends React.Component {
	constructor(props) {
		super(props);

		if (!sessionStorage['competitors']) {
			sessionStorage.setItem('competitors', []);
		}
		this.initFacilityInfo();
		this.resetBenchmarkSettings = this.resetBenchmarkSettings.bind(this);
	}

	initFacilityInfo() {
		if (this.props.match && this.props.match.params && this.props.match.params.providerId) {
			let currentFacility = misc.getFacilityIds();
			let facility = this.props.match.params.providerId;
			if (currentFacility.indexOf(facility) < 0) {
				misc.clearFOIandCompetitors();
			}
		}
		whereBuilder.storeDefaultWhere('hosp');
	}
	getBenchmarkData() {
		var ids = [];
		if (Auth.isUserHospAuthenticated()) {
			ids = ids.concat(misc.getFOI_CompetitorsIds());
			this.props.GetFieldInfo(Fields.All(), this.props.FieldDesc);
			if (!(this.props.dataLoaded || this.props.dataLoading) && ids.length > 0) {
				this.props.GetUtilizationData(ids);
				this.props.GetFinancialData(ids);
				this.props.GetLaborData(ids);
			}
		}
	}

	resetBenchmarkSettings() {
		var ids = [];
		if (Auth.isUserHospAuthenticated()) {
			this.props.updateWhereChange();
			ids = ids.concat(misc.getFOI_CompetitorsIds());
			if (!(this.props.dataLoaded || this.props.dataLoading) && ids.length > 0) {
				this.props.GetUtilizationData(ids);
				this.props.GetFinancialData(ids);
				this.props.GetLaborData(ids);
			}
		}
	}

	UNSAFE_componentWillMount() {
		this.getBenchmarkData();
	}

	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.getBenchmarkData();
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		let details = null;
		if (Auth.isUserHospAuthenticated()) {
			details = (
				<div className="container" id="benchmarks">
					<BenchmarkDetail
						{...this.props}
						prvdr={misc.getFOIandCompetitors()}
						fieldDef={this.props.FieldDesc}
						updateInfo={this.resetBenchmarkSettings}
					/>
				</div>
			);
			return (
				<>
					<PageNavigation selectedTab="4" domain="hosp" />
					<section id="content">{details}</section>
				</>
			);
		} else {
			return <LandingPage domain="hosp" />;
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BenchmarkDetailPage);
