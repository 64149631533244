import React, {Fragment} from 'react';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import FacilityInfo from './facility-info';
import * as misc from '../../lib/misc';
import LoadingSpinner from '../../tools/loadingSpinner';
import * as whereBuilder from '../../lib/whereBuilder';
import './index.css';
import OccupancyTrend from './OccupancyTrend';
import FacilityRating from './FacilityRating';
import SummaryTables from './SummaryTables';

const summaryFieldList = ['facility_name', 'type_of_control', 'urban_status'];

class SummaryPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = { showModal: false, facilityId: misc.getFacilityIds() };
		whereBuilder.storeDefaultWhere('snf');
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	render() {
		document.body.id = 'facSummary';
		const result =
			this.state.facilityId.length > 0 ? (
				<Fragment>
					<PageNavigation selectedTab="1" domain="snf" />
					<section className="snfSummary" id="content">
						<FacilityInfo />
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-12">
									<h5>Weighted Average Occupancy Rate</h5>
									<OccupancyTrend facilityId={misc.getFacilityIds()} />
								</div>
								<FacilityRating facilityId={misc.getFacilityIds()} />
							</div>
							<SummaryTables />
						</div>
					</section>
				</Fragment>
			) : (
				<LoadingSpinner />
			);
		return result;
	}
}

export default SummaryPage;
