import * as Types from '../../Common/ActionTypes';
import * as misc from '../../../lib/misc';
import { GenericReducerHelper, GenericDefaultState } from '../../../core/reducers/ReducerHelper';

const defaultState = {
	MarketShareData: {
		providerId: '',
		zipCodeMarketTrend: [],
		PSAMarketBasedMedCases: [],
		PSACompetitors: [],
		pathways: []
	},
	dataKey: null,
	error: null,
	fetching: false,
	fetched: false
};

export const marketDataReducer = (state = defaultState, action) => {
	switch (action.type) {
		case Types.SMD_Load_MarketShareData + '_PENDING': {
			return {
				...state,
				fetching: true,
				fetched: false,
				MarketShareData: null,
				dataKey: misc.Guid()
			};
		}
		case Types.SMD_Load_MarketShareData + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				dataKey: misc.Guid(),
				MarketShareData: action.payload.data.result
			};
		}
		case Types.SMD_Load_MarketShareData + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				MarketShareData: null,
				dataKey: misc.Guid(),
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};

const defaultCompetitorBenchmarkState = {
	Benchmark: null,
	error: null,
	fetching: false,
	fetched: false,
	dataKey: null
};

export const competitorBenchmarkReducer = (state = defaultCompetitorBenchmarkState, action) => {
	switch (action.type) {
		case Types.SMD_Load_CompetitorBenchmarks + '_PENDING': {
			return { ...state, fetching: true, fetched: false, Benchmark: null, dataKey: misc.Guid() };
		}
		case Types.SMD_Load_CompetitorBenchmarks + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				dataKey: misc.Guid(),
				Benchmark: action.payload.data.result
			};
		}
		case Types.SMD_Load_CompetitorBenchmarks + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				Benchmark: null,
				dataKey: misc.Guid(),
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};

export const hospSMDPathways = (state = new GenericDefaultState(), action) => {
	return GenericReducerHelper(Types.SMD_Load_MarketPathways, state, action);
};

//Deprecate?
export const hospSMDPSACompetitor = (state = new GenericDefaultState(), action) => {
	return GenericReducerHelper(Types.SMD_PSA_COMPETITOR, state, action);
};
