import {
	OUTPATIENT_APC_GET_LIST,
	OUTPATIENT_APC_GET_LIST_SUCCESS,
	OUTPATIENT_APC_GET_LIST_ERROR,
	OUTPATIENT_ICD10_GET_LIST,
	OUTPATIENT_ICD10_GET_LIST_SUCCESS,
	OUTPATIENT_ICD10_GET_LIST_ERROR
} from './constants';
import axios from 'axios/index';
import * as misc from '../../../lib/misc';
import * as api from '../../../lib/ApiUtil';
const apiUrl = misc.apiUrl;
axios.defaults.withCredentials = true;

export function loadICD10(providers) {
	return {
		type: OUTPATIENT_ICD10_GET_LIST,
		payload: axios.post(
			apiUrl + '/outpatient_icd10',
			{ providers: providers },
			api.getCustomHeader()
		)
	};
}
export function loadAPC(providers) {
	return {
		type: OUTPATIENT_APC_GET_LIST,
		payload: axios.post(apiUrl + '/outpatient_apc', { providers: providers }, api.getCustomHeader())
	};
}

export function setLoaded() {
	return {
		type: OUTPATIENT_APC_GET_LIST_SUCCESS
	};
}

export function setError(error) {
	return {
		type: OUTPATIENT_APC_GET_LIST_ERROR,
		error
	};
}
