import React from 'react';
import { connect } from 'react-redux';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import { Grid, Row, Col, Panel } from 'react-bootstrap';
import TrendChart from './TrendChart';
import QuartileChart from '../../tools/charts/quartile-chart';
import * as fieldDefActions from './chartPageAction';
import * as misc from '../../lib/misc';
import Heading from '../../core/components/Heading';
import _ from 'lodash';
import Auth from '../../core/Security/Auth';
import LandingPage from '../../core/DefaultPages/marketingInfo/HospChartToolInfo';
import DataGridQuartile from '../../tools/datagrid/extras/DataGridQuartile';
import DataGridFooterNotation from '../../tools/datagrid/extras/DataGridFooterNotation';
import FacilityCompare from '../../core/components/facilityCompare';
import { sessionStorageWhereChange } from '../../core/actions/GlobalActions';
import * as benchmarkfields from '../Benchmark/BenchmarkFields';
import * as financialfields from '../Financial/FinancialFields';
import * as globalActions from '../../core/actions/GlobalActions';
import LoadingSpinner from '../../tools/loadingSpinner';

const fields = [
	'net_patient_rev_and_misc_opr_rev',
	'total_operating_profit',
	'total_line5_plus25',
	'total_liabilities_bssummary'
];

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.snf,
		quartileChartData: store.quartileChartData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetQuartileChartData: (FieldNames, ProviderIds) => {
			dispatch(fieldDefActions.QuartileFieldList(FieldNames, ProviderIds));
		},
		GetFieldInfo: (fields, basis) => {
			dispatch(globalActions.AddNewFields(fields, basis, 'snf'));
		}
	};
};

class ChartPage extends React.Component {
	constructor(props) {
		super(props);
		if (!sessionStorage['competitors']) {
			sessionStorage.setItem('competitors', []);
		}
	}

	UNSAFE_componentWillMount() {
		this.props.GetFieldInfo(benchmarkfields.All(), this.props.FieldDesc);
		this.props.GetFieldInfo(financialfields.All(), this.props.FieldDesc);
		this.props.GetQuartileChartData(fields, misc.getFOI_CompetitorsIds());
	}
	componentDidUpdate() {
		if (this.props.quartileChartData.whereChanged) {
			this.props.GetQuartileChartData(fields, misc.getFOI_CompetitorsIds());
		}
	}

	getField(index) {
		let fld = _.find(this.props.FieldDesc, x => {
			return x.FieldName === fields[index];
		});
		return fld;
	}

	chartData(index) {
		return this.props.quartileChartData.data ? this.props.quartileChartData.data[index] : null;
	}

	getAddCompetitors() {
		this.props.dispatch(sessionStorageWhereChange());
	}

	render() {
		// const cssPath = './summarystyle.css';
		let detail = null;
		let fieldList =
			this.props.FieldDesc.length > 0
				? _.uniqBy(_.filter(this.props.FieldDesc, fld => !!fld.FieldIncludePopUp), 'FieldName')
				: [];

		if (Auth.isUserSnfAuthenticated()) {
			if (fieldList.length > 0) {
				detail = (
					<div className="container">
						<Heading fontGroupSize="24px" primary="true" heading="Chart Tool" />
						<Row
							className="mx-0 px-0"
							style={{ borderBottom: '2px solid #2C302E', marginTop: '10px', marginBottom: '10px' }}
						>
							<Col xs={18} md={12}>
								<Panel className="mx-0 px-0 chartExport">
									{!!this.props.FieldDesc && <TrendChart FieldDesc={fieldList} />}
								</Panel>
							</Col>
						</Row>
						<DataGridQuartile domain={'snf'}>
							<FacilityCompare changeComparison={this.getAddCompetitors} />
						</DataGridQuartile>
						<hr />
						<Row
							className=" mx-0"
							style={{ borderBottom: '2px solid #2C302E', marginTop: '10px', marginBottom: '10px' }}
						>
							<Col xs={9} md={6}>
								{!!this.props.FieldDesc && this.props.FieldDesc.length > 0 && (
									<QuartileChart
										FieldDef={this.getField(0)}
										ChartData={this.chartData(0)}
										Fetching={this.props.quartileChartData.fetching}
									/>
								)}
							</Col>
							<Col xs={9} md={6}>
								{!!this.props.FieldDesc && this.props.FieldDesc.length > 0 && (
									<QuartileChart
										FieldDef={this.getField(1)}
										ChartData={this.chartData(1)}
										Fetching={this.props.quartileChartData.fetching}
									/>
								)}
							</Col>
						</Row>
						<hr />
						<Row>
							<Col xs={9} md={6}>
								{!!this.props.FieldDesc && this.props.FieldDesc.length > 0 && (
									<QuartileChart
										FieldDef={this.getField(2)}
										ChartData={this.chartData(2)}
										Fetching={this.props.quartileChartData.fetching}
									/>
								)}
							</Col>
							<Col xs={9} md={6}>
								{!!this.props.FieldDesc && this.props.FieldDesc.length > 0 && (
									<QuartileChart
										FieldDef={this.getField(3)}
										ChartData={this.chartData(3)}
										Fetching={this.props.quartileChartData.fetching}
									/>
								)}
							</Col>
						</Row>
						<DataGridFooterNotation />
					</div>
				);

				return (
					<>
						<PageNavigation selectedTab="7" domain="snf" />
						<section id="content">{detail}</section>
					</>
				);
			} else {
				<LoadingSpinner />;
			}
		} else {
			return <LandingPage domain="snf" />;
		}
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ChartPage);
