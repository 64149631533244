import React from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../tools/datagrid';
import LoadingSpinner from '../../tools/loadingSpinner';
import * as providerActions from '../../core/actions/GlobalActions';
import * as misc from '../../lib/misc';
import NoData from '../../core/components/notifications/NoData';
import * as SnfActions from '../Common/SkilledNursingActions';
import _ from 'lodash';
import ReChartTool from '../../tools/charts/ReChartTool';

const mapStateToProps = store => {
	return {
		dataInspections: store.snfInspectionReportService.inspections,
		datadeficiencies: store.snfInspectionReportService.deficiencies,
		dataScores: store.snfInspectionReportService.scores,
		dataLoaded: store.snfInspectionReportService.fetched,
		dataLoading: store.snfInspectionReportService.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateProviderInfo: ids => dispatch(providerActions.findProviders(ids)),
		getFileService: ids => dispatch(SnfActions.snfInspectionReportService(ids))
	};
};

class QualityDeficiencies extends React.Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		if (this.props.dataLoaded === false && this.props.dataLoading === false) {
			this.props.getFileService(misc.getFacilityIds());
		}
	}

	GetYear() {
		if (this.props.Year) {
			if (this.props.Year == 'LATEST') {
				let maxRec = this.props.dataScores.reduce((a, b) => {
					return a.fy_year >= b.fy_year ? a : b;
				});
				return maxRec.fy_year;
			} else if (this.props.Year == 'ALL') {
				return 'ALL';
			} else {
				return this.props.data.ActiveYear;
			}
		} else return this.props.data.ActiveYear;
	}
	GetHealthScores() {
		if (this.props.dataLoaded && !this.props.dataLoading) {
			return this.props.dataScores
				.filter(x => x.fy_year == this.GetYear() || this.GetYear() == 'ALL')
				.map(r => ({
					severity: r.severity,
					severity_description: r.severity_description,
					isolated: r.isolated ? r.isolated + ' (' + r.isolated_points + 'pts) ' : '-',
					pattern: r.pattern ? r.pattern + ' (' + r.pattern_points + 'pts)' : '-',
					widespread: r.widespread ? r.widespread + ' (' + r.widespread_points + 'pts)' : '-',
					standard_deficiencies: r.standard_deficiencies
						? r.standard_deficiencies + ' (' + r.standard_points + 'pts)'
						: '-',
					complaint_deficiencies: r.complaint_deficiencies
						? r.complaint_deficiencies + ' (' + r.complaint_points + 'pts)'
						: '-',
					deficiencies: r.deficiencies ? r.deficiencies + ' (' + r.total_points + 'pts)' : '-'
				}));
		} else {
			return [];
		}
	}

	GetHealthInspections() {
		if (this.props.dataLoaded && !this.props.dataLoading) {
			return this.props.datadeficiencies.filter(
				x => x.survey_type === 'Health' && (x.fy_year == this.GetYear() || this.GetYear() == 'ALL')
			);
		} else {
			return [];
		}
	}

	GenerateHealthScoreColumns() {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'severity_description',
				MHGrid.DataGridHeaderBuilder('Severity', 'left', 200),
				'200',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'isolated',
				MHGrid.DataGridHeaderBuilder('Isolated', 'center', 25),
				'25',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'pattern',
				MHGrid.DataGridHeaderBuilder('Pattern', 'center', 25),
				'25',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'widespread',
				MHGrid.DataGridHeaderBuilder('Widespread', 'center', 25),
				'25',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'standard_deficiencies',
				MHGrid.DataGridHeaderBuilder('Standard', 'center', 25),
				'25',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'complaint_deficiencies',
				MHGrid.DataGridHeaderBuilder('Complaints', 'center', 25),
				'25',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'deficiencies',
				MHGrid.DataGridHeaderBuilder('Total', 'center', 25),
				'25',
				MHGrid.ColType_Text
			)
		);

		return Cols;
	}

	GenerateColumns() {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'survey_title',
				MHGrid.DataGridHeaderBuilder('Survey', 'left', 80),
				'80',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'correction_date',
				MHGrid.DataGridHeaderBuilder('Correction', 'center', 20),
				'20',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'scope',
				MHGrid.DataGridHeaderBuilder('Scope', 'center', 25),
				'25',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'tab',
				MHGrid.DataGridHeaderBuilder('Citation', 'center', 10),
				'10',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'description',
				MHGrid.DataGridHeaderBuilder('Description', 'left', 300),
				'300',
				MHGrid.ColType_Text
			)
		);

		return Cols;
	}

	render() {
		let data = this.props.datadeficiencies;

		const Series = [
			{
				xAxis: 'points',
				yAxis: 'severity',
				zAxis: 'points',
				xLabel: '',
				yLabel: 'Severity',
				zLabel: 'Severity',
				type: 'Scatter',
				barSize: '25',
				//color: styles.Series1,
				name: 'Health',
				display: '% Change in Population'
			}
		];

		const title = new MHGrid.DataGridTitle('Deficiencies', '', '', 5);

		if (this.props.dataLoading) {
			return <LoadingSpinner />;
		} else {
			if (this.props.dataLoaded && !this.props.dataLoading) {
				let results = [];

				// results.push(
				// 	<ReChartTool
				// 		title={title.render()}
				// 		height={300}
				// 		series={Series}
				// 		YAxis={'severity'}
				// 		ZAxis={'points'}
				// 		data={this.props.datadeficiencies}
				// 		ShowTooltip={true}
				// 		ShowLegend={true}
				// 	/>
				// );
				let _scoreTitle = <span className="text-nowrap">{`Health Inspection Score`}</span>;
				results.push(
					<MHGrid.DataGrid
						suppressLines={true}
						columns={this.GenerateHealthScoreColumns()}
						rows={this.GetHealthScores()}
						subcategory={_scoreTitle}
					/>
				);

				results.push(<h6 className="tblSubHead"><span className="text-nowrap">{`Health Deficiencies`}</span></h6>);
				results.push(
					<div>
						<div className="sticky_table_header">
							<MHGrid.DataGrid
								suppressLines={true}
								columns={this.GenerateColumns()}
								rows={[]}
								showHeaders={true}
								headersOnly={true}
							/>
						</div>
						<MHGrid.DataGrid
							suppressLines={true}
							columns={this.GenerateColumns()}
							rows={this.GetHealthInspections()}
							showHeaders={false}
						/>
					</div>
				);
				let two_title = (
					<div>
						{`Facility Deficiency Trends`}
						<small>
							<i
								className="pl-2 MH-InfoTooltip fa fa-info-circle"
								aria-hidden="true"
								data-toggle="popover"
								data-trigger="hover"
								data-content={`Deficiencies are grouped by F-Tag category to consolidate scores into their respective operational category. Note Severity Codes are scored A-L with L being the most egrarious deficiency score.`}
							/>
						</small>
					</div>
				);

				return (
					<div className="panel panel-default">
						{results}
						{this.props.children}
					</div>
				);
			} else {
				return <NoData />;
			}
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(QualityDeficiencies);
