import * as ActionTypes from '../actions/ActionTypes';

const DefaultState = class DefaultState {
	constructor() {
		this.data = [];
		this.error = null;
		this.fetching = false;
		this.fetched = false;
	}
};

export const WhitePaperReducer = (state = new DefaultState(), action) => {
	switch (action.type) {
		case ActionTypes.WHITE_PAPER_LIST + '_PENDING': {
			return { ...state, fetching: true };
		}
		case ActionTypes.WHITE_PAPER_LIST + '_FULFILLED': {
			const data = action.payload.data.result;
			return {
				...state,
				fetching: false,
				fetched: true,
				data: data
			};
		}
		case ActionTypes.WHITE_PAPER_LIST + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default:
			return { ...state };
	}
};

export default WhitePaperReducer;
