import axios from 'axios';
import Auth from '../../../core/Security/Auth';
import * as Types from '../../../core/actions/ActionTypes';
import * as misc from '../../../lib/misc';
import { getCustomHeader } from '../../../lib/ApiUtil';

const apiUrl = misc.apiUrl;

export function QuartileField(FieldId, provider, segment = 'hosp') {
	let PrvdrId = '';

	if (Array.isArray(provider) && provider.length > 0) PrvdrId = provider;
	else PrvdrId = [provider];
	let filterwhere = JSON.parse(sessionStorage.getItem('where'));
	let customBenchmark = JSON.parse(sessionStorage.getItem('customBenchmark'));
	let filter =
		!customBenchmark || (Array.isArray(customBenchmark) && customBenchmark.length < 1)
			? filterwhere
			: customBenchmark;
	return {
		type: Types.FIND_QUARTILE_FIELD,
		payload: axios.post(
			apiUrl + '/quartile_field',
			{
				fieldId: FieldId,
				providerId: provider,
				where: filter,
				domain: segment
			},
			getCustomHeader()
		)
	};
}

/*

	if (Auth.isUserAuthenticated()) {
		return {
			type: actionTag,
			payload: axios.post(
				misc.apiUrl + '/benchmark-data',
				{
					fields: fields,
					providers,
					filter: filterwhere,
					outlierAdjust: true,
					domain: segment,
					custom: customBenchmark
*/
