// export const CostReportExportFields = [
// 	'period_end' //tester field
// ];

export const IncomeReportFields = [
	'period_end',
	'days_in_fy',
	'total_patient_revenues', // Total Charges
	'contractual_allowances',
	'net_patient_revenue', //Net Patient Revenue
	'misc_non_patient_revenue', //Misc Other Revenue
	'net_patient_rev_and_misc_opr_rev', //Total Operating Revenue
	'total_salary_reported', // Salary & Wages
	'wage_related_costs_core_adj_salaries', //Employee Benefits
	'contract_labor_patient_related_mgmt_adj_salaries', //Contract Labor
	'total_staff_compensation', // Total Labor Cost
	'capital_relatedcosts', //Capital Related Costs
	'total_other_expenses', //Total Other Expenses
	'total_operating_expenses', //Total Operating Expenses
	'total_operating_profit', // Total Operating Profit
	'contributions_donations_bequests', // Contributions / Donations / Bequests
	'income_from_investments', //Income From Investments
	'governmental_appropriations', //Governmental Appropriations
	'non_operating_revenue', //Total Non Operating Revenue
	'total_other_expenses_sumlines27to29', //Total Other Expenses
	'total_line5_plus25', //Total Income
	'capital_relatedcosts_addback', // Addback: Capital Related Costs
	'total_income_before_ebitdar' //Total Income before Capital Related Copy
];

export const BalanceStatementReportFields = [
	'cash_and_cash_equivalents', //Cash and Cash Equivalents
	'temporary_investments', //Temporary Investments
	'net_accounts_receivable', //Net Accounts Receivable
	'other_current_assets', // Other Current Assets
	'total_current_assets', // Total Current Assets
	'fixed_land_assets', // Total Land & Fixed Assets
	'fixed_building_assets', //Total Buildings
	'total_vehicle_eqptmnt_fixed_assets', //Total Vehicle Assets
	'annual_total_fixed_assets', //Total Fixed Assets
	'total_other_assets', //Total Other Assets
	'total_assets', // Total Assets
	'general_fund_accounts_payable', // Accounts Payable
	'general_fund_current_liabilities_salaries_wages_fees', //Salaries, Wages, and Fees Payable
	'general_fund_current_liabilities_payroll_taxes_payable', //Payroll Taxes Payable
	'general_fund_current_liabilities_notes_loans_payable', //Notes and Loans Payable (Short-Term)
	'other_curr_liabilities', // Other Current Liabilities
	'total_current_liabilities_bssummary', // Total Current Liabilities
	'mortgage_accounts_payable', // Mortage Payable
	'longterm_notes_payable', // Notes Payable (Long-Term)
	'specific_purpose_fund_current_liabilities_deferred_income', //Unsecured Loans
	'general_fund_longterm_liabilities_loans_from_owners', //Loans from Owners
	'other_longterm_liabilities', // Other Long Term Liabilities
	'total_long_term_liabilities_bssummary', //Total Long Term Liabilities
	'total_liabilities_bssummary', //Total Liabilities
	'total_fund_balances_bssummary', // Total Fund Balances
	'total_liabilities_fundbalances' // Total Balances
];

export const OverheadCosts = [
	'capital_relatedcosts_buildings_fixtures',
	'capital_relatedcosts_buildings_fixtures_ppd',
	'capital_relatedcosts_moveable_equipment',
	'capital_relatedcosts_moveable_equipment_ppd',
	'employee_benefits',
	'employee_benefits_ppd',
	'administrative_and_general',
	'administrative_and_general_ppd',
	'plant_operation_maintenance_and_repairs',
	'plant_operation_maintenance_and_repairs_ppd',
	'laundry_and_linen_service',
	'laundry_and_linen_service_ppd',
	'housekeeping',
	'housekeeping_ppd',
	'dietary',
	'dietary_ppd',
	'nursing_administration',
	'nursing_administration_ppd',
	'central_services_and_supply',
	'central_services_and_supply_ppd',
	'pharmacy',
	'pharmacy_ppd',
	'medical_records_and_library',
	'medical_records_and_library_ppd',
	'social_service',
	'social_service_ppd',
	'nursing_and_allied_health_education',
	'nursing_and_allied_health_education_ppd',
	'other_general_service_cost',
	'other_general_service_cost_ppd',
	'total_overhead_costs',
	'total_overhead_costs_ppd'
];

export const OverheadCostsPPD = [
	'capital_relatedcosts_buildings_fixtures_ppd',
	'capital_relatedcosts_moveable_equipment_ppd',
	'employee_benefits_ppd',
	'administrative_and_general_ppd',
	'plant_operation_maintenance_and_repairs_ppd',
	'laundry_and_linen_service_ppd',
	'housekeeping_ppd',
	'dietary_ppd',
	'nursing_administration_ppd',
	'central_services_and_supply_ppd',
	'pharmacy_ppd',
	'medical_records_and_library_ppd',
	'social_service_ppd',
	'nursing_and_allied_health_education_ppd',
	'other_general_service_cost_ppd',
	'total_overhead_costs_ppd'
];

export const DirectCosts = [
	'skillednursingfacility_routine_cost_center_net_expenses',
	'skillednursingfacility_routine_cost_center_net_expenses_ppd',
	'nursingfacility_routine_cost_center_net_expenses',
	'nursingfacility_routine_cost_center_net_expenses_ppd',
	'icf_iid_routine_cost_center_net_expenses',
	'icf_iid_routine_cost_center_net_expenses_ppd',
	'other_long_term_care_routine_cost_center_net_expenses',
	'other_long_term_care_routine_cost_center_net_expenses_ppd',
	'total_direct_costs',
	'total_direct_costs_ppd'
];

export const DirectCostsPPD = [
	'skillednursingfacility_routine_cost_center_net_expenses_ppd',
	'nursingfacility_routine_cost_center_net_expenses_ppd',
	'icf_iid_routine_cost_center_net_expenses_ppd',
	'other_long_term_care_routine_cost_center_net_expenses_ppd',
	'total_direct_costs_ppd'
];

export const AncillaryCosts = [
	'dental_care_costs',
	'dental_care_costs_ppd',
	'drugs_charged_to_patients_costs',
	'drugs_charged_to_patients_costs_ppd',
	'electrocardiology_ancillary_cost_center_net_expenses',
	'elctrcardio_ancillary_cc_net_expenses_ppd',
	'intravenous_therapy_ancillary_cost_center_net_expenses',
	'intravenous_therapy_ancillary_cost_center_net_expenses_ppd',
	'laboratory_ancillary_cost_center_net_expenses',
	'laboratory_ancillary_cost_center_net_expenses_ppd',
	'med_supplies_charged_to_patients_costs',
	'med_supplies_charged_to_patients_costs_ppd',
	'occupationaltherapy_ancillary_cost_center_net_expenses',
	'occupationaltherapy_ancillary_cost_center_net_expenses_ppd',
	'oxygentherapy_ancillary_cost_center_net_expenses',
	'oxygentherapy_ancillary_cost_center_net_expenses_ppd',
	'physicaltherapy_ancillary_cost_center_net_expenses',
	'physicaltherapy_ancillary_cost_center_net_expenses_ppd',
	'radiology_ancillary_cost_center_net_expenses',
	'radiology_ancillary_cost_center_net_expenses_ppd',
	'speech_pathology_ancillary_cost_center_net_expenses',
	'speech_pathology_ancillary_cost_center_net_expenses_ppd',
	'support_service_costs',
	'support_service_costs_ppd',
	'total_ancillary_costs',
	'total_ancillary_costs_ppd'
];

export const AncillaryCostsPPD = [
	'radiology_ancillary_cost_center_net_expenses_ppd',
	'laboratory_ancillary_cost_center_net_expenses_ppd',
	'intravenous_therapy_ancillary_cost_center_net_expenses_ppd',
	'oxygentherapy_ancillary_cost_center_net_expenses_ppd',
	'physicaltherapy_ancillary_cost_center_net_expenses_ppd',
	'occupationaltherapy_ancillary_cost_center_net_expenses_ppd',
	'speech_pathology_ancillary_cost_center_net_expenses_ppd',
	'elctrcardio_ancillary_cc_net_expenses_ppd',
	'med_supplies_charged_to_patients_costs_ppd',
	'drugs_charged_to_patients_costs_ppd',
	'dental_care_costs_ppd',
	'support_service_costs_ppd'
];

export const MedicareProfitability = [
	'inpatient_pps_payments',
	'nursing_allied_ed_activities',
	'adj_reimb_mdcare_bad_debts',
	'mcare_rtn_cost_cap_cmpnt',
	'mcare_ancillary_cost',
	'mcare_profit_or_loss',
	'medicare_marigin'
];

export const SNFMalpracticePayments = [
	'malpractice_ins_requirement',
	'malpractice_ins_type',
	'malpractice_premiums',
	'malpractice_paid_losses',
	'malpractice_self_insurance'
];
export const BalanceKeyFields = [
	//TODO: Direct Nursing Salaries, Fringe & Contract Labor - Cost per Hour
	'days_cash_on_hand',
	'days_in_ar',
	'labor_cost_as_a_percent_of_revenue',
	'total_labor_cost_ppd',
	'operating_margin',
	'total_profit_margin',
	'total_profit_margin_ecld_cap_rltd_cost',
	'days_in_ap',
	'total_ip_days_visits_total'
];

export const IncomeStatementSection = () => {
	return {
		section: 'Income Statement',
		groups: [
			{
				key: 'snfIncomeStatement',
				label: null,
				fields: IncomeReportFields
			}
		]
	};
};

// export const CostReportExportSection = () => {
// 	return {
// 		section: 'Cost Report Exports',
// 		groups: [
// 			{
// 				key: 'snfCostReportExport',
// 				label: null,
// 				fields: CostReportExportFields
// 			}
// 		]
// 	};
// };

export const BalanceSheetSection = () => {
	return {
		section: 'Balance Sheet',
		groups: [
			{
				key: 'snfBalanceSheet',
				label: null,
				fields: BalanceStatementReportFields
			}
		]
	};
};

export const KeyProfitLossSection = () => {
	return {
		section: 'Key Profit / Loss Metrics and Callouts',
		groups: [
			{
				key: 'snfKeyProfitLossMetricsandCalloutsProfitability',
				label: 'Medicare Profitability Analysis',
				callout: `CMS's orginal purpose of the Medicare cost report was to reimburse a provider for its allowable Medicare cost.  Since it's initial introduction in the late 60's, CMS has changed payment methodologies away from a cost based approach but have continued to require that the mechnics of the cost reporting process remain intact. The following profitability analysis compares Medicare payments to the Provider's cost for caring for its Medicare patients. Note: this analysis includes all payments from Medicare, the Medicare patient (e.g., deductibles and coinurance) and any secondary payor payments.`,
				fields: MedicareProfitability
			},
			{
				key: 'snfKeyProfitLossMetricsandCalloutsPremiums',
				label: 'Malpractice Premiums',
				fields: SNFMalpracticePayments
			},
			{
				key: 'snfKeyProfitLossMetricsandCalloutsFinancialPerformance',
				label: 'Financial Performance Metrics',
				fields: BalanceKeyFields
			}
			// {
		]
	};
};
export const CostCenterCostingSection = () => {
	return {
		section: 'Cost Center Costing on a Per Patient Day Basis',
		callout: `The following data comes from the provider's Medicare cost report, which is required to be filed annual to CMS via the MAC.  CMS requires providers to use a coding methodology to classify their annual expenditures into predefined cost centers.  Generally, cost center are divided into five categories: General services (aka overhead), Nursing services, Ancillary services, Outpatient services and Non-Reimbursable services.  At the cost center level, analysis can be generated to compare data from year-to-year and with other providers.  More meaningful analysis can be done when cost center level expenditures are divided by provider patient days.  Cost per patient day can be compared to other years for the same provider; or compared to peers.  In short, the per day approach standardizes size and volume dissparity, allowing for meaningful comparisons to other providers.`,
		groups: [
			{
				key: 'snfKeyProfitLossMetricsandCalloutsCostOverheadCost',
				label: 'Overhead Costs',
				fields: OverheadCosts
			},
			{
				key: 'snfKeyProfitLossMetricsandCalloutsCostDirectCost',
				label: 'Direct Costs',
				fields: DirectCosts
			},
			{
				key: 'snfKeyProfitLossMetricsandCalloutsCostAncilaryCost',
				label: 'Ancillary Costs',
				fields: AncillaryCosts
			}
		]
	};
};

export const KeyProfitFields = () => {
	let field_items = [];
	KeyProfitLossSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};

export const CostCenterCostingFields = () => {
	let field_items = [];
	CostCenterCostingSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};

export const All = () => {
	let list = [];
	//list = list.concat(CostReportExportFields);
	list = list.concat(IncomeReportFields);
	list = list.concat(BalanceStatementReportFields);
	list = list.concat(KeyProfitFields());
	list = list.concat(CostCenterCostingFields());
	return list;
};

export const Info = () => {
	return {
		topic: 'Financial Performance',
		groups: [
			// {
			// 	key: 'snfCostReportExport',
			// 	label: 'Cost Report Export',
			// 	fields: CostReportExportFields
			// },
			{
				key: 'snfFinIncome',
				label: 'Income Statement',
				fields: IncomeReportFields
			},
			{
				key: 'snfFinKeyProfit',
				label: 'Key Profit / Loss Metrics & Callouts',
				fields: KeyProfitFields()
			},
			{
				key: 'snfFinKeyProfitCCPPD',
				label: 'Cost Center Costing per Patient Day',
				fields: CostCenterCostingFields()
			},
			{
				key: 'snfFinBalance',
				label: 'Balance Sheet',
				fields: BalanceStatementReportFields.concat(BalanceKeyFields)
			}
		]
	};
};
