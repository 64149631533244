import React from 'react';
import * as misc from '../lib/misc';

export default class MHModal extends React.Component {
	constructor(props) {
		super(props);
		let id = props.id ? props.id : misc.Guid();
		let idTag = '#' + id;

		this.state = {
			id: id,
			show: props.show === undefined || props.show === false ? false : true
		};
	}

	render() {
		const TopButton =
			this.props.hideX && this.props.hideX === true ? null : (
				<button type="button" className="close" data-dismiss="modal">
					&times;
				</button>
			);

		const Title = this.props.title ? (
			<div className="modal-header py-2 bg-primary text-white border-0 rounded-0">
				<h4 className="modal-title pl-1 py-0">{this.props.title}</h4>
				{TopButton}
			</div>
		) : null;

		const ModalContent = this.props.content ? this.props.content : null;

		return (
			<div>
				<div className="" data-toggle="modal" data-target={'#' + this.state.id}>
					{this.props.children}
				</div>

				<div className="modal" id={this.state.id}>
					<div className="modal-dialog">
						<div className="modal-content bg-primary text-white">
							{Title}
							<div className="modal-body bg-white text-dark">{ModalContent}</div>
							<div className="modal-footer" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
