import React from 'react';
import { connect } from 'react-redux';
import * as ActionTypes from '../Common/ActionTypes';
import * as Actions from '../../core/actions/ActionHelper';
import LoadingSpinner from '../../tools/loadingSpinner';
import ReChartTool from '../../tools/charts/ReChartTool';
import * as Fields from './SummaryFields';
import * as misc from '../../lib/misc';

const mapDispatchToProps = dispatch => {
	return {
		GetOccupancyInfo: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.SNF_OCCUPANCY_METRICS,
					providers,
					'snf',
					Fields.Occupancy,
					true
				)
			)
	};
};

const mapStateToProps = store => {
	return {
		whereChanged: store.snfOccupancyData.whereChanged,
		data: store.snfOccupancyData.Benchmark,
		dataLoaded: store.snfOccupancyData.fetched,
		dataLoading: store.snfOccupancyData.fetching
	};
};

class OccupancyTrend extends React.Component {
	componentDidMount() {
		if (this.props.facilityId && this.props.facilityId.length > 0) {
			this.props.GetOccupancyInfo(this.props.facilityId);
		}
	}

	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.props.GetOccupancyInfo(this.props.facilityId);
		}
	}

	render() {
		let facInfo = misc.getFacilityInfo();
		if (facInfo.length > 0) {
			return <LoadingSpinner />;
		} else {
			let bedchohort = facInfo.bed_cohort;
			let defaultSeries = [
				{
					xAxis: 'year',
					xLabel: '',
					yLabel: 'Occupancy Rate',
					type: 'Bar',
					name: 'national_rate',
					display: 'National',
					color: '#304771'
				},
				{
					xAxis: 'year',
					xLabel: '',
					yLabel: 'Occupancy Rate',
					type: 'Bar',
					name: 'state_rate',
					display: 'State',
					color: '#BBBDC9'
				},
				{
					xAxis: 'year',
					xLabel: '',
					yLabel: 'Occupancy Rate',
					type: 'Bar',
					name: 'bed_cohort_rate',
					display: bedchohort,
					color: '#2792C4'
				},
				{
					xAxis: 'year',
					xLabel: '',
					yLabel: 'Occupancy Rate',
					type: 'Line',
					name: 'facility_rate',
					display: facInfo.facility_name,
					color: '#2c302e'
				}
			];

			let data = this.props.data && this.props.data.data ? this.props.data.data : [];
			let chartData =
				data && data.length > 0
					? data
							.sort(function(a, b) {
								return a.year - b.year;
							})
							.reduce(
								(r, a) =>
									r.push({
										year: a.year,
										facility_rate: a.rows[0][this.props.facilityId]
											? a.rows[0][this.props.facilityId].value
											: 0.0,
										national_rate: a.rows[0]['National'] ? a.rows[0]['National'].value : 0.0,
										state_rate: a.rows[0]['State'] ? a.rows[0]['State'].value : 0.0,
										bed_cohort_rate: a.rows[0]['Bed'] ? a.rows[0]['Bed'].value : 0.0
									}) && r,
								[]
							)
					: [];

			let Chart = () =>
				chartData ? (
					<ReChartTool
						className="p-0 m-0"
						height={440}
						series={defaultSeries}
						data={chartData}
						YAxis={'percent'}
						ShowToolTip={true}
						ShowLegend={true}
					/>
				) : null;

			let renderEl =
				!this.props.dataLoading && this.props.dataLoaded ? <Chart /> : <LoadingSpinner />;
			return <div className="p-0 m-0">{renderEl}</div>;
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(OccupancyTrend);
