import React, { Fragment } from 'react';
import {
	MultiDropdownList,
	MultiDropdownRange,
	CategorySearch,
	MultiList
} from '@appbaseio/reactivesearch';
// import './index.css';
import * as _ from 'lodash';
import HospFilter from '../../components/searchBar/hosp-filter-dialog';
import ServiceList from './service-tree';

class hospSearchFields extends React.Component {
	constructor(props) {
		super(props);
		this.filterServiceChanged = this.props.filterServiceChanged.bind(this);
		this.filterSettingsChanged = this.props.filterSettingsChanged.bind(this);
	}

	componentDidMount() {}

	onServiceSelected(values) {}

	render() {
		return (
			<div className="row">
				<HospFilter
					cols="3"
					showTimeFilters={true} 
					onFilterChanged={this.filterSettingsChanged}
					filters={this.props.filters} 
					regFunction={this.props.regFunction} 
				/>
				<ServiceList 
					onChange={this.filterServiceChanged} 
					services={this.props.services} 
					regFunction={this.props.regFunction} 
				/>
				{/* </div><div className="col-5">
					<div className="row my-2">
						<div className="col-12">
							<ServiceList onChange={this.filterServiceChanged} />
						</div>
					</div>
					<div className="row">
						<div className="col-12"></div>
					</div>
				</div> */}
			</div>
		);
	}
}

export default hospSearchFields;
