import React, { Component } from "react";
import react from 'react';
import { Auth } from "aws-amplify";
import AuthUser from "../Security/Auth";
import UserInfo from "./UserInfo";
import LicenseManager from "./LicenseManager";
import ChangePass from "./ChangePass";
import Verify from "../Subscribe/Verify";

export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      userData: AuthUser.getUserProfile(),
      verify: false,
      regInfo: {}
    };
    this.changePass = this.changePass.bind(this);
    this.cancel = this.cancel.bind(this);
  };

  componentDidMount() {
    // let winH = window.innerHeight,
    // bodyH = document.getElementById('root').clientHeight;
    // if(winH > bodyH) {
    //   let diffH = winH - bodyH;
    //   document.getElementById('content').style.paddingBottom = diffH+'px';
    // }
	};

  changePass = e => {
    e.preventDefault();
    let state = this.state;
    state.stage = 2;
    this.setState(state);
  };

  async handleSignOut(e) {
    e.preventDefault();
    const { user, password } = '';
    await Auth.signOut();
    AuthUser.deauthenticateUser();
    AuthUser.clearSessionUser();
    window.location.href = '/';
  };

  cancel = e => {
    if(e) {
      e.preventDefault();
    }
    let state = this.state;
    state.stage = 1;
    this.setState(state);
  };

  confirmChange = data => {
    let state = this.state;
    state.stage = 1;
    this.setState(state);
  };
  
  verifyEmail = data => {
    let state = this.state;
    state.verify = true;
    state.regInfo = data;
    state.stage = 3;
    this.setState(state);
  };

  render() {
    return (
      <>
        {this.state.stage === 1 && (
          <section id="welcome">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h1>Welcome - {this.state.userData.First_Name}</h1>
                </div>
                <div className="col-md-6 col-sm-12">
                  <UserInfo
                    userData={this.state.userData}
                    changePass={this.changePass}
                    handleSignOut={this.handleSignOut}
                    confirmChange={this.confirmChange} 
                    verifyEmail={this.verifyEmail} 
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <LicenseManager 
                    licenses={this.state.userData.Licenses}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
        {this.state.stage === 2 && (
          <section id="welcome">
            <div className="container">
              <div className="row">
                <ChangePass
                  cancel={this.cancel} 
                />
              </div>
            </div>
          </section>
        )}
        {this.state.stage === 3 && (
          <Verify 
            cancel={this.cancel} 
            regInfo={this.state.regInfo}
          />
        )}
      </>
    );
  }
}