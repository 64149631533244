//SNF_SMD_MARKETSHARE
import axios from 'axios';
import Auth from '../../core/Security/Auth';
import * as ActionTypes from './../Common/ActionTypes';

import * as misc from '../../lib/misc';
const apiUrl = misc.apiUrl;

const getCustomHeader = function() {
	return {
		headers: { token: Auth.getToken() }
	};
};
export function snfMarketshare(prvdr_num, domain = 'snf') {
	return {
		type: ActionTypes.SNF_SMD_MARKETSHARE,
		payload: axios.post(
			apiUrl + '/snf-marketshare',
			{ facilityId: prvdr_num, domain: domain },
			getCustomHeader()
		)
	};
}
export function snfPatientFlows(prvdr_num, domain = 'snf') {
	return {
		type: ActionTypes.SNF_SMD_PATIENT_FLOW,
		payload: axios.post(
			apiUrl + '/snf-patient-flows',
			{ facilityId: prvdr_num, domain: domain },
			getCustomHeader()
		)
	};
}
export function snfRankingScore(providerNumber) {
	return {
		type: ActionTypes.SNF_RANKING_SCORE,
		payload: axios.post(apiUrl + '/snf-ranking', { facilityId: providerNumber }, getCustomHeader())
	};
}
export function snfDefaultCompetitorList(provider, fields = null) {
	if (Auth.isUserSnfAuthenticated()) {
		let where = JSON.parse(sessionStorage.getItem('where'));
		return {
			type: ActionTypes.SNF_COMPETITOR_LIST,
			payload: axios.post(
				apiUrl + '/snf-default-competitors',
				{ facilityId: provider, fields: fields, filters: where },
				getCustomHeader()
			)
		};
	} else {
		return null;
	}
}
export function snfInspectionReportService(provider) {
	if (Auth.isUserSnfAuthenticated()) {
		return {
			type: ActionTypes.SNF_FILE_SERVICE_LIST,
			payload: axios.post(
				apiUrl + '/snf-inspection-report-service',
				{ facilityId: provider },
				getCustomHeader()
			)
		};
	} else {
		return null;
	}
}

export function snfCostReportExportService(provider) {
	if (Auth.isUserSnfAuthenticated()) {
		return {
			type: ActionTypes.SNF_COST_REPORT_EXPORT_LIST,
			payload: axios.post(
				apiUrl + '/snf-cost-report-export-service',
				{ facilityId: provider, domain: 'snf' },
				getCustomHeader()
			)
		};
	} else {
		return null;
	}
}
