import React, { Fragment } from 'react';
import Accordion from 'react-tiny-accordion';
import FaqFieldList from './faqFieldList';
import FaqDatasetList from './faqDatasetList';
import { Helmet } from 'react-helmet';

class faqPage extends React.Component {
	constructor(props, context) {
		super(props, context);
		let selectedTab = 0;
		switch (this.props.location.pathname) {
			case '/faq/glossary': {
				selectedTab = 1;
				break;
			}
			case '/faq/sources': {
				selectedTab = 2;
				break;
			}
			default: {
				selectedTab = 0;
				break;
			}
		}
		this.state = { selectedTab: selectedTab };
		this.handleTabClick = this.handleTabClick.bind(this);
	}
	componentDidMount() {
		document.body.id = 'faq';
	}
	componentWillUnmount() {
		document.body.id = '';
	}
	handleTabClick(e) {
		let state = this.state;
		state.selectedTab = e;
		this.setState(state);
		return false;
	}

	headerTag() {
		let headerTag = 'FAQ ';
		switch (this.state.selectedTab) {
			case 1: {
				headerTag += 'Glossary - HMP Metrics';
				break;
			}
			case 2: {
				headerTag += 'Data Sources - HMP Metrics';
				break;
			}
			default: {
				headerTag += ' / Glossary / Data Sources - HMP Metrics';
				break;
			}
		}
		return (
			<Helmet>
				<title>{headerTag}</title>
				<meta
					name="description"
					content={
						'Frequently asked questions, like what is HMP Metics, what is data cleansing, metrics, analyses and more.'
					}
				/>
			</Helmet>
		);
	}

	FaqPanelGroup() {
		return (
			<Accordion className="accordion" transitionDuration={5} transitionTimingFunction="linear" >
				<div className="accordion-item" data-header="What is HMP Metrics?">
					<p>HMP Metrics is a data product that uses a custom electronic database that includes Healthcare Cost Report Information System (HCRIS) data from more than 600,000 Medicare Cost Reports filed by hospitals, nursing homes, home health agencies, and other providers since 1994. Additionally, for hospitals only, the HMP Metrics database includes selected data elements from the Medicare Limited Data Set (100% Standard Analytic Files) database and data sets licensed by HMP.</p>
				</div>
				<div className="accordion-item" data-header="How were these metrics determined?">
					<p>The metrics reported are standard financial, operational and quality metrics used across the healthcare industry to measure provider performance. Each metric was chosen as it highlights a specific aspect of a provider’s performance.</p>
				</div>
				<div className="accordion-item" data-header="How often is it updated?">
					<p>Data on HMP Metrics is updated continuously as it is released by the relevant source. For example, the CMS Cost Reports are updated as they are released by the Centers for Medicare and Medicaid Services (CMS) on a quarterly basis.</p>
				</div>
				<div className="accordion-item" data-header="How do I sign up for HMP Metrics?">
					<p>You can subscribe to HMP Metrics on our <a href="/subscribe">Subscribe Page</a>.</p>
				</div>
				<div className="accordion-item" data-header="How can I reset my password?">
					<p>If you already have an account, you can change your password through your <a href="/login">profile page</a>.</p>
				</div>
				<div className="accordion-item"
					data-header="I'm having trouble staying logged in to HMP Metrics, what might be wrong?" >
					<p>In order to stay logged in to HMP Metrics, your web browser must have cookies turned ON. If cookie settings are not properly set, you may be asked to log in more often than necessary.</p>
					<p><em>Your email address and password will be used to subsequently log in to the site. You won't be required to log in each time you access the site because we use cookies to keep this process as seamless as possible. The only time you may need to log in is if your cookies are deleted from your computer or when you access the site from a different web browser or device.</em></p>
				</div>
				<div className="accordion-item" data-header="How can I cancel my subscription to HMP Metrics?" >
					<p>Subscriptions to HMP Metrics are non-cancellable.</p>
				</div>
				<div className="accordion-item" data-header="What is data cleansing?">
					<p>Data contained in the HMP Metrics database has been “scrubbed” to exclude partial period or statistically aberrant data elements reported by individual providers. This careful data validation process produces highly accurate and defensible benchmarks for the hundreds of industry metrics reported in the database.</p>
				</div>
				<div className="accordion-item" data-header="What type of analyses can I use this site for?" >
					<p>The site gives you unparalleled access to robust and detailed data on the performance of healthcare providers across the country, and with this, the ability to conduct in depth analysis and inform important decisions. Examples of the kind of analysis enabled by HMP Metrics include:</p>
					<ul>
						<li>Quickly identifying key areas of opportunity and challenges within a healthcare organization.</li>
						<li>Comparing a provider’s performance over time against its peer groups locally, state-wide and nationally in a range of key financial, operational and quality metrics.</li>
						<li>Understanding a provider’s competitive positioning, market size, growth expectations and marketshare, and consequently, to advise on or make key strategic decisions.</li>
						<li>Conducting virtual due diligence of an entity in advance of acquiring, lending to, or transacting with an organization, which provides a ‘sense check’ of whether more formal due diligence is worth investing in, while also educating lenders of the current state of the market.</li>
						<li>Informing damage identification, quantification and bounding in expert-litigation cases.</li>
					</ul>
				</div>
				<div className="accordion-item" data-header="What is a customized report and how can I get one?" >
					<p>Once you have identified the data pertinent to your analysis, it can be exported and combined into a consolidated report by our team to use as you require. If you would like to discuss your specific requirements, please <a href="/contact">contact us</a> and we will be happy to help.</p>
				</div>
				<div className="accordion-item" data-header="How are the quartiles determined?">
					<p>The provider’s performance is stratified into quartiles to illustrate the benchmarks from poor to exceptional performance for each metric used, allowing for easy comparison within peer groups. The first quartile contains the top 25% of the best performing providers in an applicable peer group, the second quartile contains those providers falling in the 26% to 50% range, the third quartile contains those providers falling in the 51% to 75% range, and finally the fourth quartile contains those providers falling below 76% of the peer group benchmark.</p>
				</div>
				<div className="accordion-item" data-header="What are peer groups? How can I use these?">
					<p>Peer groups are cohorts of providers that can be compared against each other due to having certain characteristics in common. Peer groups can be grouped by provider type, geography, ownership type, bed size, etc. As a user of HMP Metrics, you can fully customize benchmarks by selecting a peer group that you would like to compare the chosen provider against.</p>
				</div>
				<div className="accordion-item" data-header="What about closed facilities / historical data?" >
					<p>Any facility that has reported financial information within the past 4 years is included in search results, as it is not uncommon for a facility to re-emerge following bankruptcy, other extenuating circumstances. If a customer would like information on a facility that hasn't reported more current information please contact us with your specific query and our team will work to assist in aggregating data as far back as 1995.</p>
				</div>
				<div className="accordion-item" data-header="Who do I contact for follow-up questions?">
					<p>Please <a href="/contact">contact us</a> and we will be happy to answer any questions you may have</p>
				</div>
				<div className="accordion-item" data-header="What is included in each section of HMP Metrics?" >
					<h5>Facility Summary</h5>
					<p> The Facility Summary provides an overview of a provider’s performance through key financial, operational and quality indicators over a 5-year period.
					</p>
					<p>The summary information section at the top of the page contains high level statistics and includes the propriatory Well's Viability Index, which shows a provider's viability based on its financial, quality, operations and strategic ratings. The remainder of the page contains tables showing the chosen provider's performance over time across a range of financial, operational and quality metrics. For each metric shown, hovering over the 'information' icon reveals its description and clicking on the 'graph' icon reveals a pop-up chart of that metric.</p>
					<p>All financial and operational indicators are sourced from CMS' Healthcare Cost Report Information System (HCRIS). All quality indicators are sourced from Hospital Compare, a federal government website managed by CMS. For more information on data sources, please refer to the 'Data Sources' section within the FAQ (Authenticated users only).</p>
					<h5>Financial</h5>
					<p>The Financial page provides a detailed insight into a provider's financial performance over a 5-year period. Included are tables showing the provider's Income Statement, Key Profit and Loss Metrics, the Balance Sheet and Key Balance Sheet Metrics. For each metric shown, hovering over the 'information' icon reveals its description and clicking on the 'graph' icon reveals a pop-up chart of that metric.</p>
					<p>All financial indicators are sourced from CMS' Healthcare Cost Report Information System (HCRIS). For more information on data sources, please refer to the 'Data Sources' section within the FAQ (Authenticated users only).</p>
					<h5>Quality</h5>
					<p>The Quality section provides a detailed insight into a provider's quality performance compared to national and state benchmarks.</p>
					<p>The Summary section shows an overview of the provider's performance in the Value-Based Purchasing (VBP), the Readmission Reduction (HRRP) and the Hospital-Acquired Conditions (HAC) Reduction Programs. Subsequent subsections provide detailled information about the provider's scores in metrics that contribute to their overall scores in these programs, including in the Hospital Consumer Assessment of Healthcare Providers and Systems (HCAHPS) Survey and Timely and Effective Care domains (both of which contribute to the VBP Program), and Healthcare Associated Infections (part of HAC Program). The provider's quality in outpatient imaging is also shown in detail.</p>
					<p>For each metric shown in any of the quality sections, hovering over the 'information' icon reveals its description and clicking on the 'graph' icon reveals a pop-up chart of that metric. Different reporting periods can also be selected using the drop-down menu on the right-hand side of the page.</p>
					<p>Quality indicators are sourced from CMS' Hospital Compare database and reports. For more information on data sources, please refer to the 'Data Sources' section within our FAQ (Authenticated users only).</p>
					<h5>Benchmark Report</h5>
					<p>The Benchmark Report provides a detailed insight into a provider's performance across a range of key utilization, labor and financial metrics compared to national and state benchmarks. These benchmarks are fully cutomizable. In addition to these benchmarks, different reporting periods can be selected using the drop-down menu on the right-hand side of the page. This will show the provider's changing patterns of utilization over the selected period of time.</p>
					<p>For each metric shown in any of the sections within the Benchmark Report, hovering over the 'information' icon reveals its description and clicking on the 'graph' icon reveals a pop-up chart of that metric.</p>
					<p>Financial, utilization and labor metrics are sourced from CMS' Healthcare Cost Report Information System (HCRIS). For more information on data sources, please refer to the 'Data Sources' section within the FAQ (Authenticated users only).</p>
					<h5>Strategic Market Data</h5>
					<p>The 'Strategic Market Data' provides an unparalleled insight into a provider's strategic position with a deep dive into its competition, demographics, market share and inpatient and outpatient data.</p>
					<p>The 'Competitors' subsection allows a thorough examination of a provider's performance compared to its direct competition in key financial, operational and quality metrics over the most recent reporting period. The 'Demographics' subsection provides a valuable insight into the sociodemographic characteristics of a provider's geographic region.The 'Market Share' subsection provides a powerful analysis of a provider's market share in its patient service area relative to its competition over time, down to the zip code level. The 'Inpatient' and 'Outpatient' subsections show a provider's inpatient and outpatient utilization compared to its competitors. Inpatient metrics show comparative utilization based on Medicare discharges and volume by Major Diagnostic Category (MDC), while outpatient metrics include comparative utilization based on volume by Ambulatory Payment Classification (APC). Together, the sections comprising the 'Strategic Market Data' give the user an unprecedented, comprehensive insight into a provider's relative strategic position and empower them to make effective strategic decisions.</p>
					<p>For each metric shown in any of the subsections within the Strategic Market Data, hovering over the 'information' icon reveals its description. Financial, utilization and labor metrics are sourced from CMS' Healthcare Cost Report Information System (HCRIS). Quality indicators are sourced from CMS' Hospital Compare database and reports. Other data sources include CMS' Limited Data Sets (LDS) files and the US Census Bureau. For more information on data sources, please refer to the 'Data Sources' section within the FAQ (Authenticated users only).</p>
					<h5>Chart Tool</h5>
					<p>Create engaging, state-of the art charts that display your selected metrics and benchmarks. Choose any metric from the drop-down menu and a chart will populate to illustrate how a provider's performance in the selected metric compares to your chosen benchmark over time. The data is automatically quartiled for a clear, visual representation of the provider's relative performance in your chosen metric. All charts can be exported to use as required.</p>
					<p>For information on data sources, please refer to the 'Data Sources' section within the FAQ (Authenticated users only).</p>
				</div>
			</Accordion>
		);
	}
	render() {
		const tabDef = [
			{
				id: 0,
				title: 'Frequently Asked Questions',
				component: this.FaqPanelGroup()
			},
			{
				id: 1,
				title: 'Glossary / Terms',
				component: (
					<div style={{ paddingTop: '1%' }}>
						<FaqFieldList />
					</div>
				)
			},
			{
				id: 2,
				title: 'Data Sources',
				component: (
					<div style={{ paddingTop: '1%' }}>
						<FaqDatasetList />
					</div>
				)
			}
		];

		const pillTabs = tabDef.map(({ id, title } = {}) => (
			<li
				className={
					this.state.selectedTab === id
						? 'react-tabs__tab react-tabs__tab--selected d-block'
						: 'react-tabs__tab d-block'
				}
			>
				<a className="d-block" onClick={() => this.handleTabClick(id)}>
					{title.toString()}
				</a>
			</li>
		));
		const toggleTabs = tabDef.map(({ id, title } = {}) => (
			<li
				className={
					this.state.selectedTab === id
						? 'react-tabs__tab react-tabs__tab--selected'
						: 'react-tabs__tab'
				}
			>
				<a data-toggle="tab" onClick={() => this.handleTabClick(id)}>
					{title.toString()}
				</a>
			</li>
		));
		const panelTab = tabDef.map(({ id, title } = {}) => (
			<div className={this.state.selectedTab === id ? 'tab-pane active' : 'tab-pane'}>
				{(tabDef[id] && tabDef[id].component) || null}
			</div>
		));
		const headerTag =
			this.state.selectedTab && tabDef[this.state.selectedTab]
				? tabDef[this.state.selectedTab].title
				: 'FAQ / Glossary / Data Sources';
		const tabBody = (
			<Fragment>
				<div className="dropdown show d-flex col mx-auto d-md-none react-tabs__tab-list">
					<a
						className="react-tabs__tab react-tabs__tab--selected py-1"
						href="#"
						role="button"
						id="dropdownMenuLink"
						data-toggle="dropdown"
					>
						{headerTag}
					</a>
					<div className="dropdown-menu text-center mx-auto" aria-labelledby="dropdownMenuLink">
						<ul className="nav nav-stacked d-block d-md-none text-white">{pillTabs}</ul>
					</div>
				</div>

				<ul className="d-none d-md-flex col-12 react-tabs__tab-list" role="tablist">
					{toggleTabs}
				</ul>

				<div className="tab-content col-12">
					{this.headerTag()}
					{panelTab}
				</div>
			</Fragment>
		);
		
		return (
			<Fragment>
				<section id="content">
					<div className="container">
						<div className="row">
							<div className="col-12 text-center">
								<h1>Frequently Asked Questions</h1>
							</div>
						</div>
						<div className="row">{tabBody}</div>
					</div>
				</section>
			</Fragment>
		);
	}
}
export default faqPage;
