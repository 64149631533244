import React from 'react';
import LandingPage from './Landing-Pages/summary-landing-page';
import PageNavigation from '../../components/navigation/pageNavigation';

export default class HospSummaryInfo extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className="content-fluid">
				<PageNavigation selectedTab="1" domain="info" />
				<LandingPage />
			</div>
		);
	}
}
