import * as misc from './misc';
import _ from 'lodash';
import { exportToXlsx } from './ExportUtil';

const SortData = (a, b) => {
	return a.year > b.year ? 1 : a.year < b.year ? -1 : 0;
};
const SortDataDesc = (a, b) => {
	return a.year < b.year ? 1 : a.year > b.year ? -1 : 0;
};

const BenchmarkUtil = class BenchmarkUtil {
	constructor(Data) {
		this.data = Array.isArray(Data) ? Data : [];
		this.args = null;
		if (
			typeof Data === 'object' &&
			!Array.isArray(Data) &&
			Data.data &&
			(Array.isArray(Data.data) || (Data.data.rows && Array.isArray(Data.data.rows)))
		) {
			this.data = Array.isArray(Data.data) ? Data.data : Data.data.rows;
			this.args = Data.args || null;
		}
		this.activeYear = this.MaxYear;
		this.multiYear = false;
		this.latestData = false;
	}

	get ActiveYear() {
		return this.activeYear;
	}
	set ActiveYear(value) {
		if (value === 'ALL') {
			this.multiYear = true;
			this.latestData = false;
		} else if (value === 'LATEST') {
			this.multiYear = false;
			this.latestData = true;
		} else {
			this.activeYear = value;
			this.multiYear = false;
			this.latestData = false;
		}
	}
	get Data() {
		return this.Fetch();
	}
	AppendData(items) {
		let _items = items;
		let _args = null;
		if (typeof _items === 'object' && Array.isArray(_items.data)) {
			_args = _items.args;
			_items = _items.data;
		}

		if (this.data && Array.isArray(this.data) && Array.isArray(_items)) {
			let zdata = this.data;
			for (let i = 0; i < _items.length; i++) {
				let indx = _.findIndex(zdata, function(x) {
					return x.year == _items[i].year;
				});
				if (indx < 0) {
					zdata = _.concat(zdata, _items[i]);
				} else {
					zdata[indx].rows = _.concat(zdata[indx].rows, _items[i].rows);
				}
			}
			// for(let j = 0; j < _args.length; j++)
			// {
			// 	this.args.push(_args[j]);
			// }
			this.data = zdata;
		} else {
			this.data = _items;
			this.args = _args;
		}
	}

	Fetch(metricList, providers) {
		let providerId = '';
		if (providers) {
			if (Array.isArray(providers)) {
				if (providers.length > 0) providerId = providers[0].id;
			} else providerId = providers.id;
		}
		if (!providerId || providerId === '') {
			providerId = misc.getFacilityIds();
		}
		if (this.data && this.data.length > 0) {
			if (this.multiYear === true) {
				let tempRows = [];
				let data = {
					multiYear: true,
					years: [],
					rows: []
				};
				providerId = misc.getFOI_CompetitorsIds();
				this.data.sort(SortData).forEach(x => {
					if (tempRows.length === 0) {
						tempRows = x.rows.map(r => ({
							metric: r.metric,
							categoryId: r.categoryId,
							subcategoryId: r.subcategoryId,
							category: r.category,
							subcategory: r.subcategory,
							fieldName: r.fieldName,
							hasNtile: r.hasNtile,
							format: r.format,
							fieldDescription: r.fieldDescription,
							fieldStyle: r.fieldStyle,
							[x.year.toString()]: {
								format: r.National && r.National.format ? r.National.format : '',
								national: r.National && r.National.value ? r.National.value : '',
								foi: providerId.map(a => {
									return r[a];
								}),
								ntile: r[providerId] && r[providerId].ntile ? r[providerId].ntile : '',
								outlier: r[providerId] && r[providerId].outlier ? r[providerId].outlier : ''
							},
							foiTrend: [
								providerId.map(a => {
									return r[a] && r[a].value ? r[a].value : '';
								})
							],
							nationalTrend: [r.National && r.National.value ? r.National.value : '']
						}));
					} else {
						for (let i = 0; i < x.rows.length; i++) {
							let r = x.rows[i];
							let fois = providerId.map(a => {
								return r[a];
							});
							let foisTrend = providerId.map(a => {
								return r[a] && r[a].value ? r[a].value : 0;
							});
							tempRows[i][x.year.toString()] = {
								format: r.National && r.National.format ? r.National.format : '',
								national: r.National && r.National.value ? r.National.value : '',
								foi: fois,
								ntile: r[providerId] && r[providerId].ntile ? r[providerId].ntile : '',
								outlier: r[providerId] && r[providerId].outlier ? r[providerId].outlier : ''
							};
							tempRows[i]['foiTrend'].push(
								providerId.map(a => {
									return r[a] && r[a].value ? r[a].value : '';
								})
							);
							tempRows[i]['nationalTrend'].push(
								r.National && r.National.value ? r.National.value : ''
							);
						}
					}
					const yearData = { year: x.year };
					data.years.push(yearData);
				});

				if (metricList) {
					data.rows = misc.filterMetrics(tempRows, metricList);
				} else data.rows = tempRows;

				return data;
			} else if (this.latestData === true) {
				let maxYear = this.MaxYear;
				let maxPrior = misc.toInt(this.MaxYear) - 1;

				let data = _.find(this.data, x => x.year.toString() === maxYear.toString());
				let tempData = { rows: [], year: data.year };

				data.rows.forEach(e => {
					let obj = Object.create(e, {});
					let foi_value = obj[providerId] && obj[providerId].value ? obj[providerId] : null;
					let field_name = obj.fieldName;
					let priorData = null;
					let priorElement = null;

					if (!foi_value) {
						this.data.sort(SortDataDesc).forEach(x => {
							if (!foi_value) {
								priorElement = x.rows.find(r => r.fieldName === field_name);
								if (priorElement) {
									foi_value =
										priorElement[providerId] && priorElement[providerId].value
											? priorElement[providerId]
											: null;
								}
							}
						});

						if (foi_value) {
							obj = Object.assign(obj, priorElement);
						}
					}

					tempData.rows.push(obj);
				});

				if (tempData) {
					if (metricList) {
						return misc.filterMetrics(tempData.rows, metricList);
					} else return tempData.rows;
				}
			} else {
				let data = _.find(this.data, x => x.year.toString() === this.ActiveYear.toString());
				if (data) {
					if (metricList) {
						return misc.filterMetrics(data.rows, metricList);
					} else return data.rows;
				}
			}
		}

		return [];
	}
	get MaxYear() {
		let result = 0;

		if (this.data && Array.isArray(this.data)) {
			this.data.forEach(x => {
				if (result < x.year) result = x.year;
			});
		}

		return result;
	}
	get Years() {
		let result = [];
		if (this.data && Array.isArray(this.data)) {
			this.data.forEach(x => {
				result.push(x.year);
			});
			result.sort((a, b) => {
				return a < b ? 1 : a > b ? -1 : 0;
			});
		}

		return result;
	}

	Normalize(columns) {
		let result = [];
		if (Array.isArray(columns) && columns.length > 0) {
				let tempData = (this.data && this.data.rows) ? this.data.rows : this.data;
				tempData.forEach(x => {
						result.push(
								columns.map(y => {
										return x[y] && x[y].value ? x[y].value : x[y];
								})
						);
				});
		}
		return result;
	}
};

export default BenchmarkUtil;
