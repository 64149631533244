import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as misc from '../../../lib/misc';
import * as format from '../../../lib/FormatUtil';
import * as MHGrid from '../../../tools/datagrid';
import LoadingSpinner from '../../../tools/loadingSpinner';
import _ from 'lodash';
import * as Actions from '../../../core/actions/ActionHelper';
import * as ActionTypes from '../../Common/ActionTypes';
import * as Fields from './inpatientFields';
import Auth from '../../../core/Security/Auth';
import * as fieldDefActions from '../../../core/actions/GlobalActions';
import * as Lib from '../SMDLib';
import DataGridQuartile from '../../../tools/datagrid/extras/DataGridQuartile';
import * as whereBuilder from '../../../lib/whereBuilder';

const BuildGridHeader = (providers, data, marketShare) => {
	let provider =
		providers && providers.length > 0 ? providers[0] : { providerId: 'Unknown', name: 'Unknown' };
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn(
			'metric',
			new MHGrid.DataGridHeader('', '', '', 'left'),
			350,
			MHGrid.ColType_Metric
		),
		new MHGrid.DataGridColumn(
			provider.providerId,
			MHGrid.DataGridHeaderBuilder(format.initCap(provider.name), 'center', '150'),
			175,
			MHGrid.ColType_Self
		)
	);

	let Competitors = marketShare.MarketShareData.result[0];

	if (Competitors && Competitors.PSACompetitors && Competitors.PSACompetitors.length > 0) {
		for (let i = 0, c = 0; i < Competitors.PSACompetitors.length && c < 5; i++) {
			if (
				Competitors.PSACompetitors[i].prvdr_num !== provider.providerId &&
				Competitors.PSACompetitors[i].prvdr_num !== 'other' &&
				Competitors.PSACompetitors[i].name !== 'Totals'
			) {
				c++;
				Cols.Add(
					new MHGrid.DataGridColumn(
						Competitors.PSACompetitors[i].prvdr_num,
						MHGrid.DataGridHeaderBuilder(
							format.initCap(Competitors.PSACompetitors[i].name),
							'center',
							'150'
						),
						175,
						MHGrid.ColType_Self
					)
				);
			}
		}
	}

	Cols.Add(
		new MHGrid.DataGridColumn(
			'National',
			new MHGrid.DataGridHeader('National Benchmark', 'National GAC', 'All Bed Sizes', 'center'),
			200,
			MHGrid.ColType_Self
		)
	);

	return Cols;
};
const RenderComparativeUtilization = (Cols, fieldDef, provider, data, onYearChanged) => {
	// Create Column Headers for Competitors Grid
	let Title = new MHGrid.DataGridTitle(
		'Comparative Utilization Based on Total Medicare Discharges',
		'',
		'',
		3,
		data.Years,
		onYearChanged
	);

	return (
		<MHGrid.DataGrid
			columns={Cols}
			rows={data ? data.Fetch(Fields.ComparativeUtilizationMetrics(), provider) : []}
			fieldDef={fieldDef}
			title={Title}
		/>
	);
};
const RenderMedicarePatientVol = (Cols, fieldDef, provider, data) => {
	// Create Column Headers for Competitors Grid

	let Title = new MHGrid.DataGridTitle(
		'Medicare Patient Volume by Major Diagnostic Category (MDC)',
		'',
		'',
		3
	);

	return (
		<div className="">
			{Title.render()}
			<div className="sticky_table_header">
				<MHGrid.DataGrid columns={Cols} rows={[]} showHeaders={true} headersOnly={true} />
			</div>
			<MHGrid.DataGrid
				columns={Cols}
				rows={data ? data.Fetch(Fields.MedicarePatientVolumneMetrics(), provider) : []}
				showHeaders={false}
				fieldDef={fieldDef}
			/>
		</div>
	);
};

const RenderInpatientPage = (fieldDef, year, provider, data, marketShare, onYearChanged) => {
	const Cols = BuildGridHeader(provider, data, marketShare);
	return (
		<div className="">
			<DataGridQuartile className="pull-left" />
			{RenderComparativeUtilization(Cols, fieldDef, provider, data, onYearChanged)}
			{RenderMedicarePatientVol(Cols, fieldDef, provider, data)}
		</div>
	);
};

class Inpatient extends Component {
	constructor(props) {
		super(props);
		this.competitors = [];

		this.state = {
			currentYear: this.props.data.ActiveYear,
			active: true,
			providers: misc.getFacilityIds()
		};

		this.onYearChanged = this.onYearChanged.bind(this);
	}
	UNSAFE_componentWillMount() {
		this.getBenchmarkData();
		//		this.props.load(misc.getFacilityIds());
	}

	getCompetitors() {
		if (this.props.marketShare) {
			let Competitors = this.props.marketShare.MarketShareData.result[0];
			let provider = this.props.providers[0];

			this.competitors = [];
			this.competitors.push({
				prvdr_num: provider.providerId,
				prvdr_name: format.initCap(provider.name)
			});

			if (Competitors && Competitors.PSACompetitors && Competitors.PSACompetitors.length > 0) {
				for (let i = 0, c = 0; i < Competitors.PSACompetitors.length && c < 5; i++) {
					if (
						Competitors.PSACompetitors[i].prvdr_num !== provider.providerId &&
						Competitors.PSACompetitors[i].prvdr_num !== 'other' &&
						Competitors.PSACompetitors[i].name !== 'Totals'
					) {
						this.competitors.push({
							prvdr_num: Competitors.PSACompetitors[i].prvdr_num,
							prvdr_name: format.initCap(Competitors.PSACompetitors[i].name)
						});
					}
				}
			}
		}
	}

	RenderBody(fieldDef, year, providers, data, marketShare, onYearChanged) {
		if (providers) {
			this.getCompetitors();
			return RenderInpatientPage(fieldDef, year, providers, data, marketShare, onYearChanged);
		} else return <div />;
	}

	onYearChanged(year) {
		this.props.data.ActiveYear = year;
		let state = this.state;
		state.currentYear = year;
		this.setState(state);
	}
	getBenchmarkData() {
		let ids = [];
		if (Auth.isUserAuthenticated()) {
			if (this.props.marketShare.fetched === true && this.props.marketShare.fetching === false) {
				ids = Lib.CompetitorList(
					misc.getFacilityIds(),
					this.props.marketShare.MarketShareData.result[0]
				);

				this.props.GetInpatientData(ids);
			}
		}
	}

	render() {
		const bodyRender = this.props.dataLoaded ? (
			this.RenderBody(
				Fields.All(),
				misc.getFacilityYear(),
				this.props.providers,
				this.props.data,
				this.props.marketShare,
				this.onYearChanged
			)
		) : (
			<div className="container">
				<div className="row">
					<LoadingSpinner />
				</div>
			</div>
		);

		return <div className="inpatient">{bodyRender}</div>;
	}
}

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.inpatient.whereChanged,
		marketShare: store.marketData,
		data: store.inpatient.Benchmark,
		dataLoaded: store.inpatient.fetched,
		dataLoading: store.inpatient.fetching,
		providers: store.prvdr.Providers
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetInpatientData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.SMD_LOAD_INPATIENT,
					providers,
					'hosp',
					Fields.All(),
					null,
					whereBuilder.setLDSDefaultFilter('hosp', 'ip')
				)
			),
		updateWhereChange: () => dispatch(fieldDefActions.sessionStorageWhereChange()),
		GetFieldInfo: (fields, basis) => {
			dispatch(fieldDefActions.AddNewFields(fields, basis, 'hosp'));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Inpatient);
