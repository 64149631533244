import { snfFinancialReducer } from '../../snf/Financial/FinancialReducer';
import {
	snfBenchmarkReducer,
	snfChartBenchmarkReducer
} from '../../snf/Benchmark/BenchmarkReducer';
import {
	snfStrategicMarketCompetitorReducer,
	snfCompetitorListReducer,
	snfSMDMarketshareReducer,
	snfSMDPatientFlowReducer,
	snfHospCompetitorDischarges
} from '../../snf/StrategicMarketData/StrategicMarketDataReducer';
import {
	snfQualityReducer,
	snfInspectionReportReducer,
	snfCostReportExportReducer
} from '../../snf/Quality/QualityReducer';
import {
	snfOccupancyReducer,
	snfRankingsReducer,
	snfSummaryReducer,
	snfQualitySummaryReducer
} from '../../snf/Summary/SummaryReducer';

import { marketReducer } from '../../snf/StrategicMarketData/Demographics/DemographicsReducer';

export const Reducers = {
	snfFinancialData: snfFinancialReducer,
	snfBenchmarkData: snfBenchmarkReducer,
	snfBenchmarkChart: snfChartBenchmarkReducer,
	snfInspectionReportService: snfInspectionReportReducer,
	snfCostReportExportService: snfCostReportExportReducer,
	snfSMDCompetitorData: snfStrategicMarketCompetitorReducer,
	snfSMDHospDischargeData: snfHospCompetitorDischarges,
	snfSMDMarketshareData: snfSMDMarketshareReducer,
	snfSMDPatientFlow: snfSMDPatientFlowReducer,
	snfCompetitorList: snfCompetitorListReducer,
	snfQualityData: snfQualityReducer,
	snfOccupancyData: snfOccupancyReducer,
	snfRankingsScore: snfRankingsReducer,
	snfSummaryData: snfSummaryReducer,
	snfQualitySummaryData: snfQualitySummaryReducer,
	snfDemographics: marketReducer
};
