import React, {Fragment} from 'react';
import Subscribe from '../../../components/navigation/subscribe';
import * as misc from '../../../../lib/misc';

export default domain => {
	let snfMetrics = (
		<Fragment>
			<ul className="list-unstyled">
				<li>
					Overall Five Star Rating
				</li>
				<li>
					Post-Discharge Readmission Measures
				</li>
				<li>
					Short Stay Record Definitions
				</li>
				<li>
					Long Stay Record Definitions
				</li>
			</ul>
		</Fragment>
	);

	let hospMetrics = (
		<Fragment>
			<ul className="list-unstyled">
				<li>
					30 Day Readmission rates for Heart Attack
				</li>
				<li>
					Overall Hospital Star Rating
				</li>
				<li>
					Healthcare Associated Infection Rates
				</li>
				<li>
					Timely and Effective Care Measure
				</li>
			</ul>
		</Fragment>
	);

	let metrics = null;
	if (domain == 'info')
		metrics = (
			<div>
				<u>
					<h3>Hospital Metrics</h3>
				</u>
				{hospMetrics}
				<u>
					<h3>Skilled Nursing Facility (SNF) Metrics</h3>
				</u>
				{snfMetrics}
			</div>
		);
	else if (domain === 'snf') metrics = snfMetrics;
	else metrics = hospMetrics;

	return (
		<Fragment>
			<section id="content">
				<div className="container" id="landingPage">
					<div className="row">
						<div className="col-12">
							<h1>100+ Quality Metrics</h1>
						</div>
						<div className="col-md-8 col-12">
							<p>The Quality Metrics tool details a provider’s performance across all major quality programs. Compare a chosen provider against state and national peer groups over a five-year period. Retrieve a summary or detailed information about a provider’s scores in the Value-Based Purchasing (VBP), the Readmission Reduction (HRRP) and the Hospital-Acquired Conditions (HAC) Reduction Programs.</p>
						</div>
						<Subscribe />
					</div>
				</div>
			</section>
			<section className="bgBlue">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2>Assess and track performance of quality measures including:</h2>
						</div>
						<div className="col-md-6 col-12">
							<img src="/images/quality_example.png" alt="Laptop displaying quality metrics" />
						</div>
						<div className="col-md-6 col-12">
							{metrics}
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
};
