import React from 'react';

const MobileSize = 360;
const TabletSize = 768;
const DesktopSize = 1024;

const isMobile = () => {
	return window.innerWidth <= MobileSize ? true : false;
};
const isTablet = () => {
	return window.innerWidth > MobileSize && window.innerWidth <= DesktopSize ? true : false;
};
const isDesktop = () => {
	return window.innerWidth >= DesktopSize ? true : false;
};

const ScreenSize = () => {
	return {
		isMobile: isMobile(),
		isTablet: isTablet(),
		isDesktop: isDesktop()
	};
};
const MobleResponseClass = class MobleResponseClass extends React.Component {
	constructor(props) {
		super(props);
		this.state = ScreenSize();

		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	handleWindowResize = () => {
		const NewState = ScreenSize();

		if (NewState !== this.state) this.setState(NewState);
	};

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	renderMobile() {
		return null;
	}
	renderTablet() {
		return null;
	}
	renderDesktop() {
		return null;
	}
	renderTabletMobile() {
		return null;
	}
	renderTabletDesktop() {
		return null;
	}

	render() {
		let Response = null;

		if (this.state.isTablet || this.state.isDesktop) Response = this.renderTabletDesktop();
		if (Response != null) return Response;

		if (this.state.isMobile || this.state.isTablet) Response = this.renderTabletMobile();
		if (Response != null) return Response;

		if (this.state.isMobile) Response = this.renderMobile();
		if (Response != null) return Response;

		if (this.state.isTablet) Response = this.renderTablet();
		if (Response != null) return Response;

		if (this.state.isDesktop) Response = this.renderDesktop();
		if (Response != null) return Response;

		return <div> Failed to return Render()</div>;
	}
};

export default MobleResponseClass;
