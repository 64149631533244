import React, { Component } from "react";

export default class MyReports extends Component {
  render() {
    return (
      <div id="content">
        <h1>My Reports</h1>
      </div>
    );
  }
}