import React from 'react';
import * as misc from '../../../lib/misc';

export default class DataGridMobilePopup extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const id = misc.Guid();
		const ModelTarget = '#' + id;
		const Data = this.props.data;

		const Grid = [];

		if (Data) {
			Data.forEach(x => {
				Grid.push(
					<tr className="w-100">
						<td className="text-left col-7">{x.name}</td>
						{x.display}
					</tr>
				);
			});
		}

		const Display = <table className="d-flex-inline">{Grid}</table>;

		return (
			<div>
				<div className="" data-toggle="modal" data-target={ModelTarget}>
					{this.props.content}
				</div>

				<div className="modal" id={id}>
					<div className="modal-dialog">
						<div className="modal-content bg-primary">
							<div className="modal-header bg-primary text-white">
								<h5 className="modal-title">{this.props.title}</h5>
								<button type="button" className="close" data-dismiss="modal">
									&times;
								</button>
							</div>
							<div className="modal-body bg-light">{Display}</div>
							<div className="modal-footer" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
