import * as Types from '../Common/ActionTypes';
import { ReducerHelper, DefaultState } from '../../core/reducers/ReducerHelper';

export const snfOccupancyReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.SNF_OCCUPANCY_METRICS, state, action);
};
export const snfRankingsReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.SNF_RANKING_SCORE, state, action);
};
export const snfSummaryReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.SNF_SUMMARY_METRICS, state, action, true);
};
export const snfQualitySummaryReducer = (state = new DefaultState(), action) => {
	return ReducerHelper(Types.SNF_SUMMARY_QUALITY_METRICS, state, action, true);
};
