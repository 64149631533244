import * as Types from '../actions/ActionTypes';

const defaultState = {
	fetching: false,
	fetched: false,
	Providers: [
		{
			providerId: '',
			name: '',
			bedType: '',
			facilityType: '',
			system: '',
			state: '',
			location: '',
			cah: '',
			hospitalType: ''
		}
	]
};

export const PrvdrReducer = (state = defaultState, action) => {
	switch (action.type) {
		case Types.PROVIDER_GET_LIST + '_PENDING': {
			return { ...state, fetching: true, fetched: false, Providers: [] };
		}
		case Types.PROVIDER_GET_LIST + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				Providers: action.payload.data.result
			};
		}
		case Types.PROVIDER_GET_LIST + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};
