import React from 'react';
import { connect } from 'react-redux';
import SearchBar from '../components/searchBar';
import Heading from '../components/Heading';
import * as GlobalActions from '../actions/GlobalActions';
import './index.css';

const mapDispatchToProps = dispatch => {
	return {
		ResetStore: () => dispatch(GlobalActions.ResetStore())
	};
};

class Products extends React.Component {
	constructor(props) {
		super(props);
		this.props.ResetStore();
		this.state = {
			pageContent: 'default',
			showModal: false,
			modalImage: '',
			showFacilityAnalyzer: false
		};
		this.onShowModalClick = this.onShowModalClick.bind(this);
		this.onCloseClick = this.onCloseClick.bind(this);
		this.onLoadContent = this.onLoadContent.bind(this);
	}

	onCloseClick() {
		this.setState({ showModal: false });
	}

	onShowModalClick(content) {
		const modalImage = content.target.dataset['image'];
		this.setState({ showModal: true, modalImage });
	}

	onLoadContent(content) {
		const contentPage = content.target.dataset['page'];
		this.setState({ pageContent: contentPage }, () => {
			console.log(this.state.pageContent, 'pageContentNew');
		});
	}

	productCard(card) {
		return (
			<div className="col-lg-2 col-md-4 col-sm-5 mx-auto px-0">
				<a href={card.hyperlink}>
					<div className="d-flex-row mx-1">
						<img className="product-card-icon" src={card.imageUrl} alt={card.title} />
					</div>
					<div className="row my-auto mx-0 px-0" style={{ maxWidth: '300px' }}>
						<h5 className="card-title text-white strong">{card.title}</h5>
						<p className="d-none d-lg-block text-white" fontSize="14px">
							{card.desc}
						</p>
					</div>
				</a>
			</div>
		);
	}

	render() {
		const ProductCards = [
			this.productCard({
				title: 'Facility Summary',
				imageUrl: '/images/MDHC_Metrics_FacilityAnalyzer.png',
				desc:
					'Review a summary of a provider’s performance over time across a wide range of operational, financial and quality metrics',
				hyperlink: '/info/summary'
			}),
			this.productCard({
				title: 'Financial Performance',
				imageUrl: '/images/MDHC_Metrics_ChartTool.png',
				desc:
					'Compare up to 5 years of financial metrics with detailed income and balance statement insights',
				hyperlink: '/info/financial'
			}),
			this.productCard({
				title: 'Quality Metrics',
				imageUrl: '/images/MDHC_Metrics_QualityReport.png',
				desc:
					"Get detailed insight into a provider's quality performance against peers on over 100 metrics",
				hyperlink: '/info/quality'
			}),
			this.productCard({
				title: 'Benchmark Report',
				imageUrl: '/images/MDHC_Metrics_Benchmark.png',
				desc:
					"Compare a provider's performance across hundreds of metrics to other providers against your chosen benchmarks",
				hyperlink: '/info/benchmark'
			}),
			this.productCard({
				title: 'Strategic Market Data',
				imageUrl: '/images/MDHC_Metrics_StrategicMarket.png',
				desc:
					"Understand a provider's strategic position with a deep dive into its competition, demographics, and market share.",
				hyperlink: '/info/strategicmarket'
			})
		];

		const productList = (
			<div className="col-lg-10 col-md-10 col-sm-12 mx-auto py-4">
				<Heading fontGroupSize="24px" heading="Comprehensive Reports and Tools" />
				<div className="card-deck pt-2 row mx-auto">{ProductCards}</div>
			</div>
		);

		const AuthSearch = (
			<div className="row mx-0">
				<div className="col-lg-10 col-md-10 col-sm-12 mx-auto px-0 pb-5">
					<h1>
						<b>Clean. Comprehensive. Reliable.</b>
					</h1>
					<h2>
						<b>Data you can trust to guide strategic decisions</b>
					</h2>
				</div>
				<div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 mx-auto pt-5">
					<div className="px-0 mx-auto">
						<div className="bg-white p-4 row mx-auto" style={{ borderRadius: '20px' }}>
							<div className="col-lg-10 col-md-11 col-sm-12 mx-auto">
								<h4 className="my-2 text-primary">Try HMP Metrics by searching for a facility:</h4>
								<div className="pb-4">
									<SearchBar allDomains={true} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		return (
			<div className="">
				<div className="mx-auto px-0 mb-5">{AuthSearch}</div>
				{/* <div className="bg-reports-and-tools">{productList}</div> */}
			</div>
		);
	}
}

export default connect(
	null,
	mapDispatchToProps
)(Products);
