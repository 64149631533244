import React from 'react';
// import { Button } from 'react-bootstrap';
import * as misc from '../../lib/misc';
import LoadingSpinner from '../../tools/loadingSpinner';
// import Control from 'react-leaflet-control';
import { Map, TileLayer, Marker, Popup, Polyline, GeoJSON } from 'react-leaflet';
import Leaflet from 'leaflet';
import _ from 'lodash';
export default class MarketshareMap extends React.Component {
	constructor(props) {
		super(props);
		this.settings = Object.assign(
			{
				lat: this.props.facilityInfo.latitude || 27.9381,
				lng: this.props.facilityInfo.longitude || -82.459,
				height: '100%',
				width: '100%',
				center: [0, 0],
				markers: [],
				geoShapes: [],
				zoom: 12,
				zoomControl: false,
				scrollWheelZoom: false,
				doubleClickZoom: false,
				dragging: false,
				selected: []
			},
			props
		);
		this.state = {
			facilityInfo: this.props.facilityInfo
		};

		// 	// selected: this.props.selected || [],
		// 	// defaultIds: this.props.defaultIds || [],
		// 	mapKey: misc.Guid(),
		// 	primaryIds: facInfo.prvdr_num
		// };
		this.setState(this.state);
		// this.ToggelPanel = this.ToggelPanel.bind(this);
		// this.pushpinHandler = this.pushpinHandler.bind(this);
		// this.facilityToggle = this.facilityToggle.bind(this);
		// this.updateSelected = this.props.updateSelectedFacilities.bind(this);
	}

	get MapStyle() {
		return { height: '400px', width: '100%' };
	}

	BuildMarkers(selected) {
		let result = [];
		let primaryId =
			this.state.facilityInfo.prvdr_num ||
			this.state.facilityInfo.id ||
			this.state.facilityInfo.providerId;
		if (
			this.props.facilities &&
			Array.isArray(this.props.facilities) &&
			this.props.facilities.length > 0
		) {
			this.props.facilities
				.filter(marker => Boolean(marker.location !== null))
				.forEach((x, k) => {
					let coordinates =
						x.location != null ? x.location.split(',').map(a => parseFloat(a)) : [,];
					let markerProps = { position: [coordinates[0], coordinates[1]] };
					//Format Icon Here
					let selectState = false; //selected.indexOf(x.id) > 0;

					let domainIcon = 'fas fa-user-md';
					let color = '#2990B0';
					let facilityType = 'Facility:';
					let hyperlink = '';
					let isFocal = (x.prvdr_num || x.id || x.providerId) == primaryId;
					if (isFocal) {
						domainIcon = 'fa fa-map-marker fa-2x';
						color = 'red';
					} else {
						switch (x.domain) {
							case 'hosp':
								domainIcon = 'fa fa-plus-square fa-lg';
								color = '#2990B0';
								facilityType = 'Hospital';
								hyperlink =
									misc.baseUrl + '/hospital/summary/' + (x.prvdr_num || x.id || x.providerId);
								break;
							case 'hha':
								color = '#adadad';
								domainIcon = 'fas fa-home fa-lg';
								facilityType = 'Home Health';
								hyperlink = '';
							case 'snf':
							default:
								domainIcon = 'fa fa-user-md fa-lg';
								color = selectState ? '#304771' : '#2792C4';
								facilityType = 'Skilled Nursing';
								hyperlink =
									misc.baseUrl + '/skillednursing/summary/' + (x.prvdr_num || x.id || x.providerId);
								break;
						}
					}
					markerProps = Object.assign(markerProps, {
						icon: new Leaflet.divIcon({
							iconSize: new Leaflet.Point(0, 0),
							html:
								'<p class="btn font-awesome ' + domainIcon + '" style="color:' + color + '"></p>'
						})
					});
					let systemInfo = (x.system_name && x.system_name.length > 0) ? (
							<div class="row">
								<i>{'Part of ' + x.system_name}</i>
							</div>
						) : (
							''
						);
					let _icon = <p class={'btn font-awesome my-0 ' + domainIcon} style={{ color: color }} />;
					let row = (
						<Marker {...markerProps} className="transparent border-0">
							<Popup>
								<div class="mb-2">
									<div class="row">
										<a href={hyperlink} target="_blank">
											<b>
												{_icon}
												{x.name}
											</b>
										</a>
									</div>
									<div class="row">{x.address}</div>
									{systemInfo}
									<div class="row pull-right">{facilityType + ' Facility'}</div>
								</div>
							</Popup>
						</Marker>
					);
					result.push(row);
				});
		}
		return result;
	}
	render() {
		let key = this.props.setMapKey;
		let position = [this.settings.lat, this.settings.lng];
		return (
			<Map
				center={position}
				zoom={this.settings.zoom}
				zoomControl={true}
				scrollWheelZoom={false}
				doubleClickZoom={true}
				dragging={true}
				style={{ height: '400px', width: '100%' }}
			>
				<TileLayer
					attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OSM</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
					url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
				/>
				{this.BuildMarkers(this.props.selected)}
				{/* <Control position="topright" className="mr-0">
					<Button onClick={this.props.ToggleClicked} className={'btn rounded-0 bg-white m-0 p-0'}>
						<i class="btn rounded-0 bg-white fa fa-user-plus " />
					</Button>
				</Control> */}
			</Map>
		);
	}
}
