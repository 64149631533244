export const All = () => {
	let list = [
		'prvdr_num',
		'system',
		'facility_address',
		'facility_city',
		'facility_zip',
		'facility_state',
		'cbsa_name',
		'hosp_type_cat',
		'hosp_ownership_type',
		'urban_rural_flag',
		'final_teach_status',
		'hospital_type',
		'cah',
		'fac_phone',
		'prvdr_website_url',
		'total_beds'
	];
	return list;
};

export const Info = () => {
	return {
		topic: 'Facility Summary',
		groups: [
			{
				key: 'facInfo',
				label: 'Facility Info',
				fields: All()
			},
			{
				key: 'facServices',
				label: 'Facility Services Offered',
				fields: [
					'svc_ardial_flag',
					'svc_adult_ip_psyc_flag',
					'svc_chemotherapy_flag',
					'svc_clinical_lab_flag',
					'svc_ccu_flag',
					'svc_ct_scan_flag',
					'svc_dental_flag',
					'svc_designated_trama_flag',
					'svc_diag_radiology_flag',
					'svc_emgncy_dept_flag',
					'svc_emrgncy_psyc_flag',
					'svc_eswl_flag',
					'svc_dietary_flag',
					'svc_frgnsc_psych_flag',
					'svc_grytrc_svc_flag',
					'svc_grntlgc_spc_flag',
					'svc_inp_surg_flag',
					'svc_med_sug_icu_flag',
					'svc_med_crt_trans_flag',
					'svc_mri_flag',
					'svc_neo_icu_flag',
					'svc_neo_nurs_flag',
					'svc_nurosurg_flag',
					'svc_non_mc_transplnt_flag',
					'svc_nclr_mdcn_flag',
					'svc_obstrc_flag',
					'svc_opthm_flag',
					'svc_opt_flag',
					'svc_opr_rm_flag',
					'svc_ortho_surg_flag',
					'svc_occ_thrpy_flag',
					'svc_out_psy_flag',
					'svc_out_rehab_flag',
					'svc_out_surg_flag',
					'svc_pediatric_flag',
					'svc_pediatric_icu_flag',
					'svc_pet_flag',
					'svc_postop_flag',
					'svc_psych_child_flag',
					'svc_phy_thrpy_flag',
					'svc_reconstrctv_surg_flag',
					'svc_resptry_flag',
					'svc_social_svc_flag',
					'svc_spch_path_flag',
					'svc_surg_icu_flag',
					'svc_thrpy_rad_flag',
					'svc_urgnt_care_cntr_flag',
					'svc_carf_ip_rehab_flag',
					'svc_card_thoratic_surgery_flag',
					'svc_cardiac_cath_flag',
					'svc_burn_care_unit_flag',
					'svc_audiology_flag',
					'svc_anesthesia_flag',
					'svc_drug_alc_flag'
				]
			}
		]
	};
};
