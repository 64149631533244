import React, { Fragment } from 'react';
import './search-filters.css';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Modal from '../../../tools/MHModal';
import * as misc from '../../../lib/misc';
import Dialog from './filter-dialog';

const DlgBody = <Dialog></Dialog>;

export default class SearchFilter extends React.Component {
	constructor(props) {
		super();

		this.modalKey = misc.Guid();

		this.state = {};
	}

	getBuildPopup(content) {
		return (
			<div className="modal" id={this.modalKey}>
				<div className="modal-dialog-centered mx-auto col-8">
					<div className="modal-content bg-primary">
						<div class="modal-header py-2 bg-primary text-white border-0 rounded-0">
							<h4 class="modal-title pl-1 py-0">{'Search Filters'}</h4>
							<button type="button" className="close" data-dismiss="modal">
								&times;
							</button>
						</div>
						<div class="modal-body bg-white">
							<div className="m-3 text">
								<Dialog
									onFilterChanged={this.props.onFilterChanged}
									onDomainChanged={this.props.onDomainChanged}
								></Dialog>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<Fragment>
				<button className="search-button" data-toggle="modal" data-target={'#' + this.modalKey}>
					<FontAwesomeIcon
						className="text-primary left"
						icon="sliders-h"
						size="lg"
						style={{ width: '35px' }}
					/>
				</button>

				{this.getBuildPopup(DlgBody)}
			</Fragment>
		);
	}
}
