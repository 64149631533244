import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import CustomGridMenu from '../../core/components/navigation/customGridMenu';
import * as MHGrid from '../../tools/datagrid';
import BenchmarkDataExport from '../../hosp/Benchmark/BenchmarkDataExport';
import * as misc from '../../lib/misc';
import LoadingSpinner from '../../tools/loadingSpinner';
import Auth from '../../core/Security/Auth';
import LandingPage from '../../core/DefaultPages/marketingInfo/HospFinancialInfo';
import * as Fields from './FinancialFields';
import { AddNewFields } from '../../core/actions/GlobalActions';
import * as Actions from '../../core/actions/ActionHelper';
import * as ActionTypes from '../Common/ActionTypes';
import DataGridTabs from '../../tools/ui-templates/DataGridTabs';
import DataGridTabBody from '../../tools/ui-templates/DataGridTabBody';
import CostReportExports from '../Common/SnfCostReportExports';
// import FinancialDetail from './FinancialDetail';
import DataGridFooterNotation from '../../tools/datagrid/extras/DataGridFooterNotation';
import Heading from '../../core/components/Heading';
import NoData from '../../core/components/notifications/NoData';

const mapDispatchToProps = dispatch => {
	return {
		GetFinancialData: providers =>
			dispatch(
				Actions.ActionPivotDataRequest(
					ActionTypes.FINANCIAL_GET_INC_METRICS,
					providers,
					'snf',
					Fields.All(),
					'fy_year'
				)
			),
		GetFieldInfo: (fields, basis) => {
			dispatch(AddNewFields(fields, basis, 'snf'));
		}
	};
};

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.snf,
		whereChanged: store.snfFinancialData.whereChanged,
		data: store.snfFinancialData.Benchmark,
		fetched: store.snfFinancialData.fetched,
		fetching: store.snfFinancialData.fetching,
		datakey: store.snfFinancialData.dataKey,
		fieldKey: store.fieldDef.updateKey
	};
};

let Cols = new MHGrid.DataGridColumns(
	new MHGrid.DataGridColumn(
		'metric',
		new MHGrid.DataGridHeader('', '', ''),
		'350',
		MHGrid.ColType_Metric
	)
);

let yr = misc.getFacilityYear();
for (let i = 4; i >= 0; i--) {
	Cols.Add(
		new MHGrid.DataGridColumn(
			yr - i,
			new MHGrid.DataGridHeader(yr - i, '', '', 'right'),
			'150',
			MHGrid.ColType_Self
		)
	);
}

const FinancialSummaryTab = [
	{
		title: 'Income Statement',
		fields: Fields.IncomeReportFields
	},
	{
		title: 'Balance Sheet',
		fields: Fields.BalanceStatementReportFields
	},
	{
		title: 'Medicare Profitability Analysis',
		fields: Fields.MedicareProfitability,
		callout: `CMS's orginal purpose of the Medicare cost report was to reimburse a provider for its allowable Medicare cost.  Since it's initial introduction in the late 60's, CMS has changed payment methodologies away from a cost based approach but have continued to require that the mechnics of the cost reporting process remain intact. The following profitability analysis compares Medicare payments to the Provider's cost for caring for its Medicare patients. Note: this analysis includes all payments from Medicare, the Medicare patient (e.g., deductibles and coinurance) and any secondary payor payments.`
	},
	{
		title: 'Malpractice Premiums',
		fields: Fields.SNFMalpracticePayments
	},
	{
		title: 'Financial Performance Metrics',
		fields: Fields.BalanceKeyFields
	}
];

const CostCenterMetricsTab = [
	{
		title: 'Overhead Costs',
		fields: Fields.OverheadCosts
	},
	{
		title: 'Overhead Costs PPD',
		fields: Fields.OverheadCostsPPD
	},
	{
		title: 'Direct Costs',
		fields: Fields.DirectCosts
	},
	{
		title: 'Direct Costs PPD',
		fields: Fields.DirectCostsPPD
	},
	{
		title: 'Ancilliary Costs',
		fields: Fields.AncillaryCosts
	},
	{
		title: 'Ancillary Costs PPD',
		fields: Fields.AncillaryCostsPPD
	}
];

class Financial extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 0
		};
		this.getFinancialData();
	}

	getTabDef() {
		return [
			{
				id: 0,
				title: 'Financial Summary',
				component: (
					<DataGridTabs
						{...this.props}
						Cols={this.getFinancialColumns()}
						Sections={FinancialSummaryTab}
						data={this.props.data.data}
						FieldDesc={this.props.FieldDesc}
					/>
				)
			},
			{
				id: 1,
				title: 'Cost Center Metrics',
				component: (
					<DataGridTabs
						{...this.props}
						Cols={this.getFinancialColumns()}
						Sections={CostCenterMetricsTab}
						data={this.props.data.data}
						FieldDesc={this.props.FieldDesc}
					/>
				)
			},
			{
				id: 2,
				title: 'Cost Report Downloads',
				component: (
					<div className="d-block text-center">
						<span className="text-nowrap">
							<CostReportExports
								{...this.props}
								Cols={this.props.dataLoaded}
								//Sections={HealthSafetyTab}
							/>
						</span>
					</div>
				)
			}
		];
	}

	getFinancialColumns() {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'metric',
				new MHGrid.DataGridHeader('', '', ''),
				'350',
				MHGrid.ColType_Metric
			)
		);

		let yr = misc.getFacilityYear();
		for (let i = 4; i >= 0; i--) {
			Cols.Add(
				new MHGrid.DataGridColumn(
					yr - i,
					new MHGrid.DataGridHeader(yr - i, '', '', 'right'),
					'150',
					MHGrid.ColType_Self
				)
			);
		}

		return Cols;
	}
	getFinancialColumnKeys() {
		let result = ['metric'];
		let header = ['Metric'];
		let yr = misc.getFacilityYear();
		for (let i = 4; i >= 0; i--) {
			result.push(yr - i);
			header.push('Fiscal Yr ' + (yr - i).toString());
		}
		return { key: result, header: header };
	}

	getFinancialData() {
		if (Auth.isUserSnfAuthenticated() === true) {
			let foi = misc.getFacilityInfo();
			this.props.GetFieldInfo(Fields.All(), this.props.FieldDesc, 'snf');

			if (this.props.fetched === false && this.props.fetching === false && foi) {
				this.props.GetFinancialData(foi.prvdr_num);
			}
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.getFinancialData();
		}
	}

	render() {
		if (Auth.isUserSnfAuthenticated()) {
			let Title = new MHGrid.DataGridTitle('Financial Performance ', '', '', 3);
			let _sectionsTemp = [FinancialSummaryTab, CostCenterMetricsTab];
			let GridControlMenu = (
				<CustomGridMenu>
					<BenchmarkDataExport
						{...this.props}
						fileName={Title.header1}
						domain={'snf'}
						sections={_sectionsTemp}
						tabDef={this.getTabDef()}
						headAlign={'right'}
						bodyAlign={'right'}
						Cols={this.getFinancialColumns()}
						data={this.props.data.data}
						FieldDesc={this.props.FieldDesc}
					/>
				</CustomGridMenu>
			);
			let details = this.props.fetched ? (
				<>
					{Title.renderHeading()}
					<DataGridTabBody tabDef={this.getTabDef()} GridControl={GridControlMenu} />
				</>
			) : (
				<LoadingSpinner />
			);

			return (
				<>
					<PageNavigation selectedTab="2" domain={'snf'} />
					<section id="content">
						<div className="container" id="finPerform">
							{details}
						</div>
					</section>
				</>
			);
		} else {
			return <LandingPage domain="snf" />;
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Financial);
