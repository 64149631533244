import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import DatasetList from './datasetList';
import DatasetDetails from './datasetDetails';
import { connect } from 'react-redux';
import { fectchArchiveDatasets, fetchArchiveDatasetDetails } from './ArchiveActions';
import _ from 'lodash';

const mapStateToProps = store => {
	return {
		archiveDatasetStore: store.archiveDataset
	};
};

class mhDatasets extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedId: parseInt(this.props.match.params.datasetId, 10)
		};
		this.onDatasetSelected = this.onDatasetSelected.bind(this);
		this.getSelectedDataSet = this.getSelectedDataSet.bind(this);
	}
	UNSAFE_componentWillMount() {
		this.props.dispatch(fectchArchiveDatasets());
		if (this.state.selectedId) {
			this.props.dispatch(fetchArchiveDatasetDetails(this.state.selectedId));
		}
	}

	onDatasetSelected(datasetId) {
		this.setState({ selectedId: datasetId });
		this.props.dispatch(fetchArchiveDatasetDetails(datasetId));
		this.props.history.push('/additional-resources/' + datasetId);
	}
	getSelectedDataSet() {
		if (this.props.archiveDatasetStore.fetched) {
			return _.find(
				this.props.archiveDatasetStore.archive_datasets,
				dataset => dataset.id === this.state.selectedId
			);
		}
		return {};
	}
	render() {
		return (
			<div className="container-fluid  px-0" style={{ width: '100%', paddingBottom: '10%' }}>
				<div
					className="navbar navbar-default navbar-flex p-0 m-0 page-navbar"
					style={{ height: '56px' }}
				>
					<h3 className="nav pl-3 mx-auto my-auto col-lg-10 col-md-12 col-sm-12 text-white strong">
						Additional Resources
					</h3>
				</div>
				<div className="container-fluid bg-default">
					<Col>
						<Row>
							{this.props.archiveDatasetStore.fetched && !this.state.selectedId && (
								<div className="container-fluid">
									<div className="row mx-0 px-0">
										<div className="col-lg-10 col-md-12 mx-auto  px-0">
											<DatasetList
												archive_datasets={this.props.archiveDatasetStore.archive_datasets}
												onDatasetSelected={this.onDatasetSelected}
											/>
										</div>
									</div>
								</div>
							)}
							{this.props.archiveDatasetStore.fetched && !!this.state.selectedId && (
								<DatasetDetails
									selectedDataset={this.getSelectedDataSet()}
									archive_datasets={this.props.archiveDatasetStore.archive_datasets}
									dataset_details={this.props.archiveDatasetStore.dataset_details}
									onDatasetSelected={this.onDatasetSelected}
								/>
							)}
						</Row>
						<Row>
							<div
								className="col-lg-10 mx-auto"
								style={{ paddingTop: '30px', color: 'gray', width: '100%' }}
							>
								<i>
									{' '}
									<small>
										Disclaimer / Notice of Data Integrity : The above datasets are provided for
										historical & retention purposes and are outside the scope of Metrics. Please
										reach out to Healthcare Management Partners Customer Service with any questions{' '}
									</small>
								</i>
							</div>
						</Row>
					</Col>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(mhDatasets);
