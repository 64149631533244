export const PROVIDER_GET_LIST = 'PROVIDER_GET_LIST';
export const FIELD_DEFINITION_LIST = 'FIELD_DEFINITION_LIST';
export const FIND_QUARTILE_FIELD = 'FIND_QUARTILE_FIELD';
export const DATA_EXPLORER_LIST = 'DATA_EXPLORER_LIST';
export const ASSIGNFACILITY = 'ASSIGNFACILITY';
export const ASSIGNFACILITY_COMPLETE = 'ASSIGNFACILITY_FULFILLED';
export const BENCHMARK_DATA_GET = 'BENCHMARK_DATA';
export const BENCHMARK_DATA_EXCEL = 'BENCHMARK_DATA_EXCEL';
export const QUARTILE_CHART_FIELDS = 'QUALITY_GET_METRICS';
export const GLOBAL_RESET = 'RESET';
export const WHITE_PAPER_LIST = 'WHITE_PAPER_LIST';
export const GET_SEARCH_API = 'GET_SEARCH_API';
