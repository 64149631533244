import { balanceSheetReducer } from '../../hosp/Financial/BalanceSheetReducer';
import { incomeReducer } from '../../hosp/Financial/IncomeReducer';
import {
	hospFinancialReducer,
	hospCostReportExportReducer,
	hospSummaryQualityReducer
} from '../../hosp/Financial/HospFinancialReducer';
import { hospQualityReducer } from '../../hosp/Quality/QualityReducer';
import { hospBenchmarkReducer } from '../../hosp/Benchmark/BenchmarkReducer';
import { BenchmarkReducer } from './BenchmarkReducer';
import { demographicsReducer } from '../../hosp/StrategicMarketData/DemographicsReducer';
import { hospSMDInpatientReducer } from '../../hosp/StrategicMarketData/Inpatient/reducer';
import { qualityRatingReducer } from '../../hosp/Summary/QualityRatingReducer';
import { acuteOccupancyReducer } from '../../hosp/Summary/AcuteOccupanyReducer';
import { hospSMDCompetitorReducer } from '../../hosp/StrategicMarketData/Competitors/CompetitorReducer';
import {
	marketDataReducer,
	competitorBenchmarkReducer,
	hospSMDPathways
} from '../../hosp/StrategicMarketData/MarketShare/MarketDataReducer';
import {
	outpatient_icd10,
	outpatient_apc
} from '../../hosp/StrategicMarketData/Outpatient/reducer';

export const Reducers = {
	balanceSheet: balanceSheetReducer,
	incomeStatement: incomeReducer,
	hospFinancialData: hospFinancialReducer,
	hospQualitySummary: hospSummaryQualityReducer,
	hospCostReportExportService: hospCostReportExportReducer,
	// qualitySummary: QualitySummaryBenchmarkReducer,
	// quality_HCAHPS: QualityHCAHPSBenchmarkReducer,
	// quality_ReadmissionComp: QualityReadmissionCompReducer,
	// quality_TimelyEffectiveCare: QualityTimelyEffectiveCareReducer,
	// quality_HealthcareAssocInfects: QualityHealthcareAssocInfectsReducer,
	// quality_IPPsych: QualityIPPsychReducer,
	// quality_MedicalImaging: QualityMedicalImagingReducer,
	hospQualityData: hospQualityReducer,
	hospBenchmarkData: hospBenchmarkReducer,
	qualityRating: qualityRatingReducer,
	acuteOccupancy: acuteOccupancyReducer,
	marketData: marketDataReducer,
	demographics: demographicsReducer,
	competitorSummary: hospSMDCompetitorReducer,
	competitorBenchmark: competitorBenchmarkReducer,
	hospPathways: hospSMDPathways,
	BenchmarkDetail: BenchmarkReducer,
	outpatient_icd10: outpatient_icd10,
	outpatient_apc: outpatient_apc,
	inpatient: hospSMDInpatientReducer
};
