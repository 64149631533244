import * as Types from '../../core/actions/ActionTypes';

const defaultState = {
	acuteOccupancy: {},
	error: null,
	whereChanged: false,
	fetching: false,
	fetched: false
};

export const acuteOccupancyReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'ACUTE_OCCUPANCY_FETCH_PENDING': {
			return { ...state, fetching: true };
		}
		case 'ACUTE_OCCUPANCY_FETCH_FULFILLED': {
			return {
				...state,
				whereChanged: false,
				fetching: false,
				fetched: true,
				acuteOccupancy: action.payload.data.result
			};
		}
		case 'ACUTE_OCCUPANCY_FETCH_REJECTED': {
			return {
				...state,
				whereChanged: false,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		case Types.ASSIGNFACILITY_COMPLETE: {
			return {
				...state,
				whereChanged: true,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};
