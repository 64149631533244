const defaultState = {
	incomeStatement: [],
	error: null,
	fetching: false,
	fetched: false
};

export const incomeReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'INCOMESTATEMENT_FETCH_PENDING': {
			return { ...state, fetching: true };
		}
		case 'INCOMESTATEMENT_FETCH_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				incomeStatement: action.payload.data
			};
		}
		case 'INCOMESTATEMENT_FETCH_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};
