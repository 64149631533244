import React from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../../core/actions/ActionHelper';
import * as ActionTypes from '../../Common/ActionTypes';
import * as fieldDefActions from '../../../core/actions/GlobalActions';
import * as MHGrid from '../../../tools/datagrid';
import { DataGridProviderColumns } from '../../../tools/datagrid/extras/DataGridGroupHeader';
import * as Fields from '../SrategicMarketFields';
import * as misc from '../../../lib/misc';
import * as whereBuilder from '../../../lib/whereBuilder';
import LoadingSpinner from '../../../tools/loadingSpinner';
import MarketshareMap from '../map';
import SnfMarketshare from './SnfMarketshare';
import ReferringHospitals from './ReferringHospitals';
import _ from 'lodash';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.snf,
		hospFieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.snfSMDHospDischargeData.whereChanged,
		hospData: store.snfSMDHospDischargeData.Benchmark,
		hospDataLoaded: store.snfSMDHospDischargeData.fetched,
		hospDataLoading: store.snfSMDHospDischargeData.fetching,
		hospDatakey: store.snfSMDHospDischargeData.dataKey,
		providerInfo: store.providerInfo,
		searchResults: store.searchResult
	};
};
const mapDispatchToProps = dispatch => {
	return {
		GetHospitalMarketShareInfo: hospProviders =>
			dispatch(
				Actions.ActionDataRequest(
					ActionTypes.SNF_HOSP_DISCHARGES,
					hospProviders,
					'hosp',
					Fields.HospitalMarketshareFields,
					whereBuilder.GetLatestReportedYrOnly()
				)
			),
		updateWhereChange: () => dispatch(fieldDefActions.sessionStorageWhereChange()),
		GetFieldInfo: (fields, basis) => {
			dispatch(fieldDefActions.AddNewFields(fields, basis, 'snf'));
		},
		GetHospFieldInfo: (fields, basis) => {
			dispatch(fieldDefActions.AddNewFields(fields, basis, 'hosp'));
		}
	};
};

class Marketshare extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			facilityId: this.props.facilityId,
			selected: [],
			default: [],
			facilityInfo: this.props.facilityInfo
		};
		this.setState(this.state);
		this.MapButton = this.MapButton.bind(this);
	}

	componentDidMount() {
		this.InitializeData();
	}

	InitializeData() {
		if (!this.props.hospDataLoaded && !this.props.hospDataLoading) {
			this.props.GetFieldInfo(Fields.CompetitorTab(), this.props.Fields);
			this.props.GetHospFieldInfo(Fields.HospitalMarketshareFields, this.props.hospFieldDesc);
			this.UpdateData();
		}
	}

	UpdateData() {
		let hospitals = this.props.searchResults.data['nearest_hospitals']
			? this.props.searchResults.data['nearest_hospitals'] : [];
		if (hospitals.length > 0) {
			this.props.GetHospitalMarketShareInfo(hospitals);
		}
	}

	updateSelectedFacilities(facility) {
		let selector = this.state;
		if (selector.selected.indexOf(facility.id) > -1) {
			selector.selected = _.remove(selector.selected, function(id) {
				return id == facility.id;
			});
		} else {
			selector.selected = _.take(
				_.compact(_.union(_.union([facility], selector.selected), this.state.default)),
				5
			);
		}
		this.setState(selector);
		this.UpdateData();
	}

	MapButton() {
		// let collapse = !this.state.collapsed;
		// this.setState({ collapsed: collapse });
	}
	// AvailableFacilities() {
	// 	let options = [];
	// 	let selector = this.state.selected.map(a => a.id || a.prvdr_num || a.providerId);
	// 	this.props.closestSkilled.map(b => {
	// 		if (b.id != this.state.facilityId) {
	// 			options.push(
	// 				<li
	// 					className={
	// 						selector.indexOf(b.id) > -1
	// 							? 'list-group-item tab-button bg-secondary text-sm'
	// 							: 'list-group-item tab-button text-sm'
	// 					}
	// 					onClick={() => {
	// 						this.updateSelectedFacilities(b);
	// 					}}
	// 				>
	// 					<small>
	// 						<text className="ml-3">{b.name + ' - ' + Math.round(b.sort[0]).toPrecision(2)}</text>
	// 					</small>
	// 				</li>
	// 			);
	// 		}
	// 	});
	// 	return options;
	//}

	render() {
		let mapKey = misc.Guid();
		let closestSNFs = this.props.searchResults.data['nearest_snf_facilities']
			? this.props.searchResults.data['nearest_snf_facilities']
			: [];
		let closestHospitals = this.props.searchResults.data['nearest_hospitals']
			? this.props.searchResults.data['nearest_hospitals']
			: [];

		let closestFacilities = closestSNFs.concat(closestHospitals);

		if(this.props.facilityInfo && this.props.facilityInfo.prvdr_num)
		{
			closestFacilities.push(
				{ 
					"id": this.props.facilityId,  
					"prvdr_num": this.props.facilityInfo.prvdr_num,
					"domain": "snf",
					"longitude": this.props.facilityInfo.longitude,
					"latitude": this.props.facilityInfo.latitude, 
					"location": this.props.facilityInfo.latitude + ',' + this.props.facilityInfo.longitude,
					"system_name": this.props.facilityInfo.system_name
				}
			);
		}

		if (!this.props.hospDataLoaded && !this.props.hospDataLoading && closestHospitals.length > 0) {
			this.UpdateData();
		}
		let hospitals =
			Array.isArray(closestHospitals) && closestHospitals.length > 0
				? closestHospitals
				: [];
		let columns = DataGridProviderColumns(
			hospitals,
			closestHospitals,
			'hosp',
			true
		);
		let loading = this.props.hospDataLoading && !this.props.hospData.data ? <LoadingSpinner /> : '';

		return (
			<div className="panel panel-default">
				<div className="row-no-pad">
					<div className="col-12 px-0 border d-flex">
						<MarketshareMap
							ToggleClicked={this.MapButton}
							facilities={closestFacilities}
							setMapKey={mapKey}
							facilityInfo={this.state.facilityInfo}
							zoom="9"
						/>
					</div>
				</div>
				<hr />
				<div className="row-no-pad">
					<SnfMarketshare facilityId={this.props.facilityId} />
					<hr />
					<div className="d-flex">
						<h4 class="tblHead">
							{'Closest Hospital Facilities Key Metrics'}
							<i
								className="ml-2 MH-InfoTooltip py-auto mt-auto pb-2 mb-2 fa fa-xs fa-info-circle mr-auto"
								aria-hidden="true"
								data-toggle="popover"
								data-trigger="hover"
								data-content={
									'Closest Hospitals to the Facility of Interest. Note: Includes short-term acute crae, rehabilitation, pediatric, psychiatric facilities'
								}
							/>
						</h4>
					</div>
					<MHGrid.DataGrid
						suppressLines={true}
						fieldDef={this.props.hospFieldDesc}
						columns={columns} //this.props.data.Fetch(this.sections[i].fields, providers)
						rows={
							this.props.hospData.data && this.props.hospData.data.length > 0
								? this.props.hospData.data[this.props.hospData.data.length - 1].rows
								: []
						}
						showHeaders={true}
					/>
					{loading}
				</div>
				<hr />
				<div className="row-no-pad">
					<ReferringHospitals facilityId={this.props.facilityId} />
				</div>
			</div>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Marketshare);
