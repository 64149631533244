import React from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import AuthUser from '../../Security/Auth';
import SearchBar from '../searchBar';
import * as misc from '../../../lib/misc';

const mapStateToProps = store => {
	return {
		authenticated: store.auth
	};
};

class menuBar extends React.Component {
	constructor(props) {
		super(props);
		this.onSimpleSearch = this.onSimpleSearch.bind(this);
		this.state = {
      username: null,
      email: null,
      user: null,
			searchVisible: false,
			navOpen: false,
			dropDown: false
    };
		this.openCloseNav = this.openCloseNav.bind(this);
		this.dropDownToggle = this.dropDownToggle.bind(this);
	}

	async handleSignOut(event) {
    event.preventDefault();
    const { user, password } = '';
    await Auth.signOut();
		AuthUser.deauthenticateUser();
    AuthUser.clearSessionUser();
    window.location.href = '/';
  };

	menuSearch = () => {
		this.setState(prevState => ({ searchVisible: !prevState.searchVisible }));
	};

	onSimpleSearch(selectedFacilities) {
		sessionStorage.setItem('facilityList', JSON.stringify(selectedFacilities[0]));
		this.setState({ showFacilityAnalyzer: true });
	};

	openCloseNav = e => {
		e.preventDefault();
		let state = this.state,
		navState = state.navOpen;
		if(navState === false) {
			state.navOpen = true;
		} else {
			state.navOpen = false;
			state.dropDown = false;
			state.searchVisible = false;
		}
		this.setState(state);
	};

	dropDownToggle = e => {
		e.preventDefault();
		let state = this.state,
		dropDown = e.currentTarget.id,
		winW = window.innerWidth;
		if(dropDown != state.dropDown && winW <= 991) {
			state.dropDown = dropDown;
		} else {
			state.dropDown = false;
		}
		this.setState(this.state);
	};

	render() {
		// let facilities = misc.getFacilityInfo();
		// let buttonText = AuthUser.isUserAuthenticated() ? 'Sign Out' : 'Sign In';
		// alert(this.state.authState+' - '+AuthState.SignedIn);
		// let authUser = AuthUser.getAuthUser();
		let sessionUser = AuthUser.isUserAuthenticated();
		let dataExplorer = null;
		let reports = null;
		let loginLogout = null;
		const { searchVisible, navOpen, dropDown } = this.state;
		

		if(sessionUser === true) {
			dataExplorer = 
				<li className="nav-item">
					<a className="nav-link" href="/data-explorer" target="_self" title="Data Explorer">
						Data Explorer
					</a>
				</li>;
			reports = 
				<>
					{/* <li className={`nav-item dropdown ${dropDown === "data" ? "openDrop" : ""}`} >
						<div
							id="reports"
							className="nav-link dropdown-toggle"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<div
								onClick={this.dropDownToggle}
							>
								Reports
							</div>
							<ul className="dropdown-menu" aria-labelledby="dropdown1" >
								<a
									className="dropdown-item"
									href="/request-report"
									title="Request Report"
									target="_self"
								>
									<li>Request Report</li>
								</a>
								<a
									className="dropdown-item"
									href="/my-reports"
									title="My Reports"
									target="_self"
								>
									<li>My Reports</li>
								</a>
							</ul>
						</div>
					</li>; */}
					<li className="nav-item">
						<a className="nav-link" href="/reports" target="_self" title="Report">
							Reports
						</a>
					</li>
				</>;
			loginLogout =
				<li id="userProfile" className={`nav-item dropdown ${dropDown === "activeUser" ? "openDrop" : ""}`} >
					<div
						id="loggedIn"
						className="nav-link dropdown-toggle"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<div
							id="activeUser"	
							onClick={this.dropDownToggle}
						>
							<div id="loggedInIcon"></div>
						</div>
						
						<ul className="dropdown-menu" aria-labelledby="dropdown1">
							<a
								className="dropdown-item"
								href='/welcome'
								title="Profile"
							>
								<li>Profile</li>
							</a>
							<a 
								className="dropdown-item" 
								href="/login" 
								target="_self" 
								title="Log Out" 
								onClick={this.handleSignOut}
							>
								<li>Log Out</li>
							</a>
						</ul>
					</div>					
				</li>;
		} else {
			dataExplorer = 
				<li className="nav-item">
					<a className="nav-link" href="/subscribe" target="_self" title="Subscribe">
					Subscribe
					</a>
				</li>;
			reports = 
				<li className="nav-item">
					<a className="nav-link" href="/reports" target="_self" title="Report">
						Reports
					</a>
				</li>;
			loginLogout =
				<li className="nav-item login">
					<a className="nav-link" href="/login" target="_self" title="Login">
						Log In
					</a>
				</li>;
		}
		
		return (
			<>
				<a href="#content" id="conJump">
					Go to Content
				</a>
				<nav id="menuBar" className="navbar navbar-expand-lg">
					<div className="container">
						<div className="row">
							<a className="navbar-brand" href="/" title="Home Page | HMP Metrics">
								<img src="images/brandTxt.png" alt="bars" onError={i => i.target.style.display='none'} />
								<span id="brandTxt">
									HMP{' '}
									<span>
										Metrics
										<br />
										<span>Defining Exceptional</span>
									</span>
								</span>
							</a>
							<button
								className="navbar-toggler ml-auto"
								type="button"
								data-toggle="collapse"
								data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent"
								aria-expanded="false"
								aria-label="Toggle navigation" 
								onClick={this.openCloseNav} 
							>
								<span className={`navbar-toggler-icon ${navOpen ? "open" : ""}`} >
									<div></div>
									<div></div>
									<div></div>
								</span>
							</button>
							<div className="collapse navbar-collapse" id="navbarSupportedContent">
								<ul className="navbar-nav ml-auto">
									<li className={`nav-item dropdown ${dropDown === "company" ? "openDrop" : ""}`} >
										<div className="nav-link dropdown-toggle" aria-haspopup="true" aria-expanded="false" >
											<div 
												id="company"
												onClick={this.dropDownToggle}
											>
												Company
											</div>
											<ul className="dropdown-menu" aria-labelledby="dropdown0" >
												<a className="dropdown-item" href="/aboutus" title="About Us" target="_self">
													<li>About Us</li>
												</a>
												<a
													className="dropdown-item"
													href="/faq"
													title="Frequently Asked Questions"
													target="_self"
												>
													<li>FAQ</li>
												</a>
												<a
													className="dropdown-item"
													href="/terms-of-use"
													title="Terms of Use"
													target="_self"
												>
													<li>Terms of Use</li>
												</a>
												<a
													className="dropdown-item"
													href="https://www.hcmpllc.com/"
													title="Healthcare Management Partners"
													target="_blank"
													rel="noopener"
												>
													<li>Healthcare Management Partners</li>
												</a>
												<a
													className="dropdown-item"
													href="https://hmpseniorsolutions.com/"
													title="HMP Senior Solutions"
													target="_blank"
													rel="noopener"
												>
													<li>HMP Senior Solutions</li>
												</a>
											</ul>
										</div>
									</li>
									<li className={`nav-item dropdown ${dropDown === "data" ? "openDrop" : ""}`} >
										<div
											className="nav-link dropdown-toggle"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<div
												id="data"
												onClick={this.dropDownToggle}
											>
												Data Sets
											</div>
											<ul className="dropdown-menu" aria-labelledby="dropdown1" >
												<a
													className="dropdown-item"
													href="/hospital-systems"
													title="Hospital &amp; Health System"
													target="_self"
												>
													<li>Hospital &amp; Health System</li>
												</a>
												<a
													className="dropdown-item"
													href="/skilled-nursing"
													title="Skilled Nursing Facility (SNF)"
													target="_self"
												>
													<li>Skilled Nursing Facility (SNF)</li>
												</a>
											</ul>
										</div>
									</li>
									{dataExplorer}
									{/* {reports} */}
									<li className="nav-item">	
										<a 
											className="nav-link" 
											href="/blogs" 
											target="_self" 
											title="Blogs Page"
										>
											Blogs
										</a>
									</li>
									<li className="nav-item">	
										<a 
											className="nav-link" 
											href="/contact" 
											target="_self" 
											title="Contact Us"
										>
											Contact Us
										</a>
									</li>
									{loginLogout}
									<li 
										className="nav-item" 
										id="search"
									>
										<div
											className="nav-link dropdown-toggle"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<i 
												className="fas fa-search" 
												title="Facility Search"
												onClick={this.menuSearch}
											></i>
											<ul id="dropSearch" className={`box ${searchVisible ? "active" : ""}`}>
												<li>
													<SearchBar allDomains={true} />
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</nav>
			</>
		);
	}
}
export default connect(
	mapStateToProps,
	null
)(menuBar);
