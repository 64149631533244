import React, { Component } from "react";
import zxcvbn from "zxcvbn";
import CardPrem from './CardPrem';
import CardHosp from './CardHosp';
import CardSNF from './CardSNF';

const validEmail = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class Register extends Component {
  constructor (props) {
    super(props);
    this.state = {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      url: '',
      password: '',
      conPass: '',
      type: 'password',
      score: 'null',
      value: true,
      errors: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        password: '',
        conPass: '',
        passMatch: ''
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  };

  handleChange = ( event ) => {
    event.preventDefault();
    const { name, value } = event.target;
    let state = this.state;
    
    switch (name) {
      case 'firstName': 
        let tempFirst = event.target.value;
        if(value.length < 3 && value.length != 0) {
          state.errors.firstName = 'First name must be at least 3 characters';
          state.value = true;
        } else {
          state.errors.firstName = '';
        }
        state.firstName = tempFirst.charAt(0).toUpperCase() + tempFirst.slice(1);
        // state.firstName = event.target.value;
        break;
      case 'lastName':
        let tempLast = event.target.value;
        if(value.length < 3 && value.length != 0) {
          state.errors.lastName = 'Last name must be at least 3 characters';
          state.value = true;
        } else {
          state.errors.lastName = '';
        }
        state.lastName = tempLast.charAt(0).toUpperCase() + tempLast.slice(1);
        break;
      case 'email':
        if(!validEmail.test(value)) {
          state.errors.email = 'A valid email is required';
          state.value = true;
        } else {
          state.errors.email = '';
        }
        state.email = event.target.value;
        break;
      case 'phone':
        let phone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if(value.match(phone)) {
          state.errors.phone = '';
        } else {
          state.errors.phone = 'Please provide a valid phone number';
          state.value = true;
        }
        state.phone = event.target.value;
        break;
      case 'company':
        if(value.length < 3 && value.length != 0) {
          state.errors.company = 'Company must be at least 3 characters';
          state.value = true;
        } else {
          state.errors.company = '';
        }
        state.company = event.target.value;
        break;
      case 'address':
        state.address = event.target.value;
        break;
      case 'city':
        state.city = event.target.value;
        break;
      case 'state':
        let tempState = event.target.value;
        state.state = tempState.toUpperCase();
        break;
      case 'zip':
        state.zip = event.target.value;
        break;
      case 'url':
        state.url = event.target.value;
        break;
      case 'password':
        if(value.length < 8 && value.length != 0) {
          state.errors.password = 'Password must be at least 8 characters';
          state.value = true;
        } else  {
          state.errors.password = '';
        }
        state.password = event.target.value;
        if(state.conPass && state.password !== state.conPass) {
          state.errors.conPass = 'Passwords must match';
        } else {
          state.errors.conPass = '';
        }
        break;
      case 'conPass':
        if(value === state.password) {
          state.errors.conPass = '';
        } else {
          state.errors.conPass = 'Passwords must match';
          state.value = true;
        }
        state.conPass = event.target.value;
        break;
      default:
        break;
    };
    if(state.firstName && state.lastName && state.email && state.phone && state.company && state.password && state.conPass && !state.errors.firstName && !state.errors.lastName && !state.errors.email && !state.errors.phone && !state.errors.company && !state.errors.password && !state.errors.conPass) {
      state.value = false;
    }
    this.setState(state);
  };

  showHide = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password'
    });
  };

  passScoreLabel = result => {
    switch (result.score) {
      case 0:
        return 'N/A';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    };
  };

  handleSubmit = e => {
    e.preventDefault();
    let state = this.state;
    state.username = state.firstName + ' ' + state.lastName;
    state.company = state.company.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    state.address = state.address.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    state.city = state.city.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    state.value = true;
    this.setState(state);
    if(this.props.onRegister) {
      this.props.onRegister(this.state);
    } else {
      alert("There was an error");
    }
  };

  render() {
    const {errors} = this.state;
    const testedResult = zxcvbn(this.state.password);
    const prem = <CardPrem currentPlan={this.props.currentPlan} />;
    const hosp = <CardHosp currentPlan={this.props.currentPlan} />;
    const snf = <CardSNF currentPlan={this.props.currentPlan} />;
    let activePlan = null;
    let regError = this.props.regError;
    let passError = this.state.passMatch;
    
    switch(this.props.currentPlan.selected) {
      case 'hosp':
        activePlan = hosp;
        break;
      case 'SNF':
        activePlan = snf;
        break;
      case 'PREMIUM':
        activePlan = prem;
        break;
    };

    return (
      <section id="content">
        <div id="register" className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Subscribe to HMP Metrics</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12" id="loginFrame">
              <h3>Registration Info</h3>
              <p id="reqMess">* Required Fields</p>
              <form id="regForm" className="inputFrame" onSubmit={this.handleSubmit} autoComplete="off">
                <div>
                  <label htmlFor="firstName"><i className="fas fa-user"></i></label>
                  <input 
                    type="text" 
                    className="loginInput reqInput" 
                    id="firstName" 
                    name="firstName" 
                    placeholder="* First Name" 
                    value={this.state.firstName} 
                    onChange={this.handleChange}
                    required
                    autoComplete="off"
                  />
                  {errors.firstName.length > 0 && <span className="error">{errors.firstName}</span>}
                </div>
                <div>
                  <label htmlFor="lastName"><i className="fas fa-signature"></i></label>
                  <input 
                    type="text" 
                    className="loginInput reqInput" 
                    id="lastName" 
                    name="lastName" 
                    placeholder="* Last Name" 
                    value={this.state.lastName} 
                    onChange={this.handleChange}
                    required
                  />
                  {errors.lastName.length > 0 && <span className="error">{errors.lastName}</span>}
                </div>
                <div>
                  <label htmlFor="email"><i className="fas fa-at"></i></label>
                  <input
                    type="email"
                    className="loginInput reqInput" 
                    id="email" 
                    name="email" 
                    placeholder="* Email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                  />
                  {errors.email.length > 0 && <span className="error">{errors.email}</span>}
                </div>
                <div>
                  <label htmlFor="phone"><i className="fas fa-phone-alt"></i></label>
                  <input
                    type="tel"
                    className="loginInput reqInput" 
                    id="phone" 
                    name="phone" 
                    placeholder="* Phone #"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    required
                  />
                  {errors.phone.length > 0 && <span className="error">{errors.phone}</span>}
                </div>
                <div>
                  <label htmlFor="comp"><i className="fas fa-building"></i></label>
                  <input
                    type="text"
                    className="loginInput reqInput" 
                    id="comp" 
                    name="company" 
                    placeholder="* Company Name"
                    value={this.state.company}
                    onChange={this.handleChange}
                    required
                  />
                  {errors.company.length > 0 && <span className="error">{errors.company}</span>}
                </div>
                <div>
                  <label htmlFor="address"><i className="fas fa-map-pin"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="address" 
                    name="address" 
                    placeholder="Street Address"
                    value={this.state.address}
                    onChange={this.handleChange} 
                  />
                </div>
                <div>
                  <label htmlFor="city"><i className="fas fa-map-marker-alt"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="city" 
                    name="city" 
                    placeholder="City"
                    value={this.state.city}
                    onChange={this.handleChange} 
                  />
                </div>
                <div id="stateWrap">
                  <label htmlFor="state"><i className="fas fa-map-marked-alt"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="state" 
                    name="state" 
                    placeholder="State"
                    value={this.state.state}
                    onChange={this.handleChange} 
                  />
                </div>
                <div id="zipWrap">
                  <label htmlFor="zip"><i className="fas fa-map"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="zip" 
                    name="zip" 
                    placeholder="Zip"
                    value={this.state.zip}
                    onChange={this.handleChange} 
                  />
                </div>
                <div>
                  <label htmlFor="url"><i className="fas fa-globe"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="url" 
                    name="url" 
                    placeholder="URL"
                    value={this.state.url}
                    onChange={this.handleChange} 
                  />
                </div>
                <div>
                  <label htmlFor="pwd"><i className="fas fa-unlock-alt"></i></label>
                  <input 
                    type={this.state.type} 
                    className="loginInput reqInput" 
                    id="pwd" 
                    name="password" 
                    placeholder="* Password"
                    autoComplete="off"
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                  />
                  <span 
                    className="showHide" 
                    onClick={this.showHide}
                  >
                    {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
                  </span>
                  {errors.password.length > 0 && <span className="error">{errors.password}</span>}
                  <label className="passStrength">
                    {this.state.password && (
                      <>
                        <progress
                          className={`passScore score-${this.passScoreLabel(testedResult)}`}
                          value={testedResult.score}
                          max="4"
                        />
                        <span><strong>Password strength:</strong> {this.passScoreLabel(testedResult)}</span>
                      </>
                    )}
                  </label>
                </div>
                <div>
                  <label htmlFor="conPass"><i className="fas fa-lock"></i></label>
                  <input 
                    type={this.state.type} 
                    className="loginInput reqInput" 
                    id="conPass" 
                    name="conPass" 
                    placeholder="* Confirm Password"
                    value={this.state.conPass}
                    onChange={this.handleChange}
                    required
                  />
                  <span 
                    className="showHide" 
                    onClick={this.showHide}
                  >
                    {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
                  </span>
                  {errors.conPass.length > 0 && <span className="error">{errors.conPass}</span>}
                </div>
                <div>
                  <input 
                    type="submit" 
                    id="sub" 
                    className="loginBttn" 
                    name="submit" 
                    value="Next"
                    disabled={this.state.value}
                  />
                </div>
              </form>
              {passError && <h5 className="regError">{passError}</h5>}
              {regError && <h5 className="regError">{regError}</h5>}
            </div>
            <div className="col-md-6 col-12" id="activePlan">
              {activePlan}
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center subInfo">
              <h4>HMP Metrics provides the integrated, objective and interactive data to power strategic decisions.</h4>
              <p>We've designed an intuitive database so you can easily navigate, and visualize, how a facility performs against its peers with instantaneous graphics and indices.</p>
              <h5>No matter what you do in the industry,<br />HMP Metrics is what you've been looking for.<br />Data that's Easy to Digest.</h5>
              <p><a href="/terms-of-use" target="_blank">Terms of Use</a></p>
            </div>
          </div>
        </div>
      </section>
    );
  };
};

export default(Register);