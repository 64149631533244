import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import * as misc from '../../lib/misc';
import Auth from '../../core/Security/Auth';
import LandingPage from '../../core/DefaultPages/marketingInfo/HospStrategicInfo';
import * as Fields from './SrategicMarketFields';
import * as fieldDefActions from './../../core/actions/GlobalActions';
import * as MHGrid from '../../tools/datagrid';
import NoData from '../../core/components/notifications/NoData';
import * as MarketSearch from './MarketSearchExplorer';
import loadingSpinner from '../../tools/loadingSpinner';
import Competitors from './Competitors';
import Marketshare from './Marketshare';
import DischargePatterns from './DischargePatterns';
import Demographics from './Demographics';
import PatientMigration from './PatientMigration';
import * as whereBuilder from '../../lib/whereBuilder';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.snf,
		searchResults: store.searchResult
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateWhereChange: () => dispatch(fieldDefActions.sessionStorageWhereChange()),
		GetFieldInfo: (fields, basis) => {
			dispatch(fieldDefActions.AddNewFields(fields, basis, 'snf'));
		},
		GetSearchResults: (domain, prvdr_num, key, dist) => {
			dispatch(fieldDefActions.SearchDistanceBySNF(domain, prvdr_num, key, dist));
		}
	};
};
class StrategicMarketData extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 0,
			facilityIds: misc.getFacilityIds(),
			facilityInfo: misc.getFacilityInfo()
		};
		this.tabChanged = this.tabChanged.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (Auth.isUserSnfAuthenticated()) {
			this.InitiateComponent();
		}
	}

	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.InitiateComponent();
		}
	}

	InitiateComponent() {
		whereBuilder.storeDefaultWhere('snf');
		this.props.GetFieldInfo(Fields.All(), this.props.FieldDesc);
		this.props.GetSearchResults(
			'hosp',
			this.state.facilityInfo.prvdr_num,
			'nearest_hospitals',
			30
		);
		this.props.GetSearchResults(
			'snf',
			this.state.facilityInfo.prvdr_num,
			'nearest_snf_facilities',
			30
		);
		// this.props.GetSearchResults(
		// 	null,
		// 	MarketSearch.getResultsWithin100Mi(
		// 		this.state.facilityInfo.latitude,
		// 		this.state.facilityInfo.longitude
		// 	),
		// 	'facilities_within_100mi'
		// );
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	tabChanged(selectedTab) {
		let state = this.state;
		state.selectedTab = selectedTab;
		this.setState(state);
	}

	render() {
		let details = '';
		let Title = new MHGrid.DataGridTitle('Strategic Market Data', '', '', 3, null, null);
		let closestFacilitiesList = this.props.searchResults.data['facilities_within_100mi'];
		let closestSkilledList = this.props.searchResults.data['nearest_snf_facilities'];

		let closestFacilities = closestFacilitiesList ? closestFacilitiesList.map(e => {return e.prvdr_num;}) : [];
		let closestSkilled = closestSkilledList ? closestSkilledList.map(e => {return e.prvdr_num;}) : [];

		if (Auth.isUserSnfAuthenticated()) {
			const tabDef = [
				{ id: 0, title: 'Market Share' },
				{ id: 1, title: 'Competitors' },
				{ id: 2, title: 'Patient Migration' },
				{ id: 3, title: 'Demographics' },
				{ id: 4, title: 'Discharge Patterns' }
			];
			const pillTabs = tabDef.map(({ id, title } = {}) => (
				<li
					className={
						this.state.selectedTab === id
							? 'react-tabs__tab react-tabs__tab--selected  d-block'
							: 'react-tabs__tab d-block'
					}
				>
					<a className="d-block" onClick={() => this.tabChanged(id)}>
						{title.toString()}
					</a>
				</li>
			));
			const toggleTabs = tabDef.map(({ id, title } = {}) => (
				<li
					className={
						this.state.selectedTab === id
							? 'react-tabs__tab react-tabs__tab--selected'
							: 'react-tabs__tab'
					}
					onClick={() => this.tabChanged(id)}
				>
					<a data-toggle="tab" className="mx-3">
						{title.toString()}
					</a>
				</li>
			));
			details = (
				<div className="container">
					{Title.renderHeading()}
					<div className="row">
						<div class="dropdown show d-flex col-12 mx-auto d-md-none react-tabs__tab-list">
							<a
								class="react-tabs__tab react-tabs__tab--selected"
								href="#"
								role="button"
								id="dropdownMenuLink"
								data-toggle="dropdown"
							>
								{tabDef[this.state.selectedTab].title.toString()}
							</a>

							<div class="dropdown-menu text-center mx-auto" aria-labelledby="dropdownMenuLink">
								<ul class="nav nav-stacked d-block d-md-none">{pillTabs}</ul>
							</div>
						</div>
						<ul
							class="d-none d-md-flex col-12 mx-auto react-tabs__tab-list mt-1"
							role="tablist"
						>
							{toggleTabs}
						</ul>
						<div className="tab-content col-12">
							<div className={this.state.selectedTab === 0 ? 'tab-pane active' : 'tab-pane'}>
								<Marketshare
									facilityInfo={this.state.facilityInfo}
									facilityId={this.state.facilityIds}
								/>
							</div>
							<div className={this.state.selectedTab === 1 ? 'tab-pane active' : 'tab-pane'}>
								<Competitors
									facilityInfo={this.state.facilityInfo}
									facilityId={this.state.facilityIds}
									closestFacilities={closestFacilities}
									closestSkilled={closestSkilled}
								/>
							</div>
							<div className={this.state.selectedTab === 2 ? 'tab-pane active' : 'tab-pane'}>
								<PatientMigration
									facilityId={this.state.facilityIds}
									facilityInfo={this.state.facilityInfo}
								/>
							</div>
							<div className={this.state.selectedTab === 3 ? 'tab-pane active' : 'tab-pane'}>
								<Demographics
									facilityInfo={this.state.facilityInfo}
									facilityId={this.state.facilityIds}
									closestFacilities={closestFacilities}
									closestSkilled={closestSkilled}
								/>
							</div>
							<div className={this.state.selectedTab === 4 ? 'tab-pane active' : 'tab-pane'}>
								<DischargePatterns />
							</div>
						</div>
					</div>
				</div>
			);

			return (
				<Fragment>
					<PageNavigation selectedTab="5" domain="snf" />
					<section id="content" className="snfSMD">{details}</section>
				</Fragment>
			);
		} else {
			return <LandingPage domain="snf" />;
		}
		// NOTE: This sets up elastic search to setup the default competitors / nearest hospitals, and closest skilled facilities
		// to enable us to automatically get the 'context' for any facility, even those that are not nearby.
		// the default is set to the 10 closest facilities.
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StrategicMarketData);
