import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import reducers from './core/reducers';

const middleWare = applyMiddleware(promise(), thunk);

export const store = createStore(
	reducers,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
	middleWare
);

if (module.hot) {
	module.hot.accept('./core/reducers', () => {
		store.replaceReducer(reducers);
	});
}

export default store;
