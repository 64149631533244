import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/fontawesome-free-solid';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as MHGrid from '../../../tools/datagrid';
import * as misc from '../../../lib/misc';
import LoadingSpinner from '../../../tools/loadingSpinner';
import NoData from '../../../core/components/notifications/NoData';
import _ from 'lodash';

const mapStateToProps = store => {
	return {
		fetched: store.hospPathways.fetched,
		fetching: store.hospPathways.fetching,
		paths: store.hospPathways.data
	};
};
class DischargePatterns extends React.Component {
	constructor() {
		super();

		this._element = React.createRef();
	}

	componentDidMount() {
		this.setState({
			width: this._element.current.parentNode.parentNode.clientWidth,
			activeLink: null
		});
	}

	UpdateData(data, columns, field) {
		let nodes = [];
		let links = [];

		for (let i = 0; i < data.length; i++) {
			if (_.findIndex(nodes, { name: data[i][columns[0]] }) < 0) {
				nodes.push({ name: data[i][columns[0]] });
			}
			for (let j = 1; j < columns.length; j++) {
				if (_.findIndex(nodes, { name: data[i][columns[j]] }) < 0) {
					nodes.push({ name: data[i][columns[j]] });
				}
				let indxA = _.findIndex(nodes, {
					name: data[i][columns[j - 1]]
				});
				let indxB = _.findIndex(nodes, {
					name: data[i][columns[j]]
				});
				links.push({
					source: indxA,
					target: indxB,
					value: data[i][field],
					tag: data[i][columns[0]]
				});
			}
		}
		return {
			nodes: nodes,
			links: links
		};
	}

	GetDischargePathColumns(years, admitView = true) {
		let Cols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'provider',
				MHGrid.DataGridHeaderBuilder(
					admitView ? 'Patient Origin' : 'Subsequent Visit',
					'center',
					150
				),
				'150',
				MHGrid.ColType_Text
			)
		);
		Cols.Add(
			new MHGrid.DataGridColumn(
				'distance',
				MHGrid.DataGridHeaderBuilder('Distance (Mi)', 'center', 50),
				'50',
				MHGrid.ColType_Integer
			)
		);
		if (admitView == true) {
			Cols.Add(
				new MHGrid.DataGridColumn(
					'emergency',
					MHGrid.DataGridHeaderBuilder('ER/Urgent Admits', 'center', 50),
					'50',
					MHGrid.ColType_Integer
				)
			);
			Cols.Add(
				new MHGrid.DataGridColumn(
					'elective',
					MHGrid.DataGridHeaderBuilder('Elective Admits', 'center', 50),
					'50',
					MHGrid.ColType_Integer
				)
			);
		}
		for (let i = 1; i <= years.length; i++) {
			Cols.Add(
				new MHGrid.DataGridColumn(
					'y' + i,
					new MHGrid.DataGridHeader(years[i - 1], '', '', 'right'),
					'50',
					MHGrid.ColType_Integer
				)
			);
		}
		Cols.Add(
			new MHGrid.DataGridColumn(
				'total',
				new MHGrid.DataGridHeader('Total', '', '', 'right'),
				'50',
				MHGrid.ColType_Integer
			)
		);
		return Cols;
	}

	GetAdmitPatientFlows(data) {
		if (this.props.fetched && !this.props.fetching) {
			if (data && Array.isArray(data) && data.length > 0) {
				let results = [];
				let provider_map = 'admit_provider';
				let facility_map = 'source_facility';
				let hospitalAdmits = _.groupBy(data, 'admit_facility_type').hosp || [];
				let years = _(data)
					.groupBy('admit_year')
					.map((obj, key) => key)
					.uniq()
					.takeRight(5)
					.value(); //get the column years we are interested in.
				let clmns = this.GetDischargePathColumns(years);
				let keyFacilities = _(hospitalAdmits)
					.groupBy(provider_map)
					.map((obj, key) => ({ provider: key, total_cases: _.sumBy(obj, 'cases') }))
					.orderBy('total_cases', 'desc')
					.take(10)
					.map(a => {
						return a.provider;
					})
					.value();
				keyFacilities = _.uniq(_.concat(this.props.facilityId, keyFacilities));

				for (let i = 0; i < keyFacilities.length; i++) {
					let subset = _(data)
						.filter({ admit_provider: keyFacilities[i] })
						.value();
					let admitting = _(data)
						.filter({ admit_provider: keyFacilities[i] })
						.head();
					let snfset = _(subset)
						.groupBy(facility_map)
						.map((obj, key) => ({
							provider: key,
							distance: _.head(obj).distance,
							emergency:
								_.sumBy(
									_.filter(subset, { source_facility: key, description: 'Emergency' }),
									'cases'
								) +
								_.sumBy(_.filter(subset, { source_facility: key, description: 'Urgent' }), 'cases'),
							elective: _.sumBy(
								_.filter(subset, { source_facility: key, description: 'Elective' }),
								'cases'
							),
							y1: _.sumBy(
								_.filter(subset, { source_facility: key, admit_year: years[0] * 1 }),
								'cases'
							),
							y2: _.sumBy(
								_.filter(subset, { source_facility: key, admit_year: years[1] * 1 }),
								'cases'
							),
							y3: _.sumBy(
								_.filter(subset, { source_facility: key, admit_year: years[2] * 1 }),
								'cases'
							),
							y4: _.sumBy(
								_.filter(subset, { source_facility: key, admit_year: years[3] * 1 }),
								'cases'
							),
							y5: _.sumBy(
								_.filter(subset, { source_facility: key, admit_year: years[4] * 1 }),
								'cases'
							),
							total: _.sumBy(_.filter(subset, { source_facility: key }), 'cases')
						}))
						.orderBy('total', 'desc')
						.value();
					if (snfset.length > 0) {
						results.push(
							<h5 className="tblHead">
								<a target="_blank">
									{admitting.admit_facility + ' | ' + admitting.admit_provider}
									{/* <FontAwesomeIcon className={'ml-2 fa-xs my-auto'} icon={faExternalLinkAlt} /> */}
								</a>
							</h5>
						);
						results.push(
							<div>
								<div className="sticky_table_header">
									<MHGrid.DataGrid columns={clmns} rows={[]} showHeaders={true} />
								</div>
								<MHGrid.DataGrid columns={clmns} rows={snfset} showHeaders={false} />
							</div>
						);
					}
				}
				return results;
			} else {
				return <NoData />;
			}
		} else {
			return <LoadingSpinner />;
		}
	}

	GetDischargePatientFlows(data) {
		if (this.props.fetched && !this.props.fetching) {
			if (data && Array.isArray(data) && data.length > 0) {
				let results = [];

				let hospitalAdmits = _.groupBy(data, 'admit_facility_type').hosp || [];
				let years = _(data)
					.groupBy('admit_year')
					.map((obj, key) => key)
					.uniq()
					.takeRight(5)
					.value(); //get the column years we are interested in.
				let clmns = this.GetDischargePathColumns(years, false);
				let keyFacilities = _(hospitalAdmits)
					.groupBy('admit_provider') //Only looking at the same hospitals as on the prior tab
					.map((obj, key) => ({ provider: key, total_cases: _.sumBy(obj, 'cases') }))
					.orderBy('total_cases', 'desc')
					.map(a => {
						if (a.provider != 'null') {
							return a.provider;
						}
					})
					.take(10)
					.value();
				keyFacilities = _.uniq(_.concat(this.props.facilityId, keyFacilities));
				for (let i = 0; i < keyFacilities.length; i++) {
					let subset = _(data)
						.filter({ source_provider: keyFacilities[i] })
						.value();
					let admitting = _(data)
						.filter({ source_provider: keyFacilities[i] })
						.head();
					let snfset = _(subset)
						.groupBy('admit_facility')
						.map((obj, key) => ({
							provider: key,
							distance: _.head(obj).distance,
							y1: _.sumBy(
								_.filter(subset, { admit_facility: key, admit_year: years[0] * 1 }),
								'cases'
							),
							y2: _.sumBy(
								_.filter(subset, { admit_facility: key, admit_year: years[1] * 1 }),
								'cases'
							),
							y3: _.sumBy(
								_.filter(subset, { admit_facility: key, admit_year: years[2] * 1 }),
								'cases'
							),
							y4: _.sumBy(
								_.filter(subset, { admit_facility: key, admit_year: years[3] * 1 }),
								'cases'
							),
							y5: _.sumBy(
								_.filter(subset, { admit_facility: key, admit_year: years[4] * 1 }),
								'cases'
							),
							total: _.sumBy(_.filter(subset, { admit_facility: key }), 'cases')
						}))
						.orderBy('total', 'desc')
						.value();
					if (snfset.length > 0) {
						results.push(
							<h5 className="tblHead">
								<a target="_blank">
									{admitting.source_facility + ' | ' + admitting.source_provider}
									{/* <FontAwesomeIcon className={'ml-2 fa-xs my-auto'} icon={faExternalLinkAlt} /> */}
								</a>
							</h5>
						);
						results.push(
							<div>
								<div className="sticky_table_header">
									<MHGrid.DataGrid columns={clmns} rows={[]} showHeaders={true} />
								</div>
								<MHGrid.DataGrid columns={clmns} rows={snfset} showHeaders={false} />
							</div>
						);
					}
				}
				return results;
			} else {
				return <NoData />;
			}
		} else {
			return <LoadingSpinner />;
		}
	}

	render() {
		let admitFlow = this.GetAdmitPatientFlows(
			!this.props.fetching && this.props.paths && this.props.paths.result
				? this.props.paths.result
				: []
		);
		let dischargeFlow = this.GetDischargePatientFlows(
			!this.props.fetching && this.props.paths && this.props.paths.result
				? this.props.paths.result
				: []
		);

		return (
			<div className="patPattern" ref={this._element}>
				<div className="col-12 text-center">
					<h4 className="tblHead">
						{'Top Medicare Admission / Discharges pathways from other Providers'}
					</h4>
				</div>
				<Tabs>
					<TabList>
						<Tab>Admissions</Tab>
						<Tab>Discharges</Tab>
					</TabList>
					<TabPanel>
						{this.props.fetching && !this.props.fetched ? (
							<LoadingSpinner />
						) : (
							admitFlow || <NoData />
						)}
					</TabPanel>
					<TabPanel>
						{this.props.fetching && !this.props.fetched ? (
							<LoadingSpinner />
						) : (
							dischargeFlow || <NoData />
						)}
					</TabPanel>
				</Tabs>
			</div>
		);
	}
}
export default connect(mapStateToProps)(DischargePatterns);
