export const QualitySummaryMetrics = [
	'overall_rating',
	'health_inspection_rating',
	'staffing_rating',
	'qm_rating'
];

export const FiveStart_QualityMeasuresMetrics = ['qm_rating'];

export const HealthInspectionDomainMetrics = [
	'health_inspection_rating',
	'last_survey_inspection',
	'health_citations',
	'health_complaint_deficiency'
];

export const PBJStaffingMetrics = [
	'rn_hrs_per_resident_day',
	'lpn_hrs_per_resident_day',
	'cna_hrs_per_resident_day',
	'total_nursing_hrs_per_resident_day'
];

export const PBJNonNurseStaffingMetrics = [
	'admin_hrs_per_resident_day',
	'phy_service_hrs_per_resident_day',
	'therapeutic_service_hrs_per_resident_day',
	'pharmacist_hrs_per_resident_day',
	'dietary_services_hrs_per_resident_day',
	'mhsvc_hrs_per_resident_day'
];

export const QualityReportingProgramMetrics = [
	'pressure_ulcers_obs_rate',
	'discharge_to_community_post_acute_care_dtc_rs_rate_2_5',
	'pressure_ulcers_adj_rate',
	'discharge_to_community_post_acute_care_dtc_volume',
	'discharge_to_community_post_acute_care_dtc_number',
	'discharge_to_community_post_acute_care_dtc_comp_perf',
	'discharge_to_community_post_acute_care_dtc_rs_rate',
	'application_of_functional_assessment_care_plan_obs_rate',
	'discharge_to_community_post_acute_care_dtc_obs_rate',
	'application_of_falls_obs_rate',
	'ptnt_prev_30_day_post_disc_readm_ppr_pd_obs',
	'ptnt_prev_30_day_post_disc_readm_ppr_pd_vol',
	'ptnt_prev_30_day_post_disc_readm_ppr_pd_perf',
	'ptnt_prev_30_day_post_disc_readm_ppr_pd_rsrr',
	'med_spend_per_ben_post_acute_care_mspb_score',
	'ptnt_prev_30_day_post_dc_radm_ppr_pd_obs_readm',
	'med_spend_per_ben_post_acute_care_mspb_numb'
];

export const MDSQualityShortStayVaccineMetrics = [
	'short_stay_res_given_seasonal_influenza_vaccine',
	'short_stay_res_given_the_pneumococcal_vac'
];
export const MDSQualityShortStayMetrics = [
	'short_stay_res_rehosp_nursing_home_admission',
	'short_stay_res_outpatient_ed_visit',
	'short_stay_res_who_newly_received_an_antipsychotic_medication',
	// 'short_stay_res_with_pressure_ulcers_that_are_new_or_worsened',
	'long_stay_res_self_report_mod_to_sev_pain',
	// 'short_stay_res_given_seasonal_influenza_vac',
	'short_stay_res_who_made_improvements_in_function'
];
export const MDSQualityLongStayAdditionalMetrics = [
	'long_stay_res_rec_anti_hypnotic_medication',
	'long_stay_res_lose_control_bowels_or_bladder',
	'long_stay_res_physically_restrained',
	'long_stay_res_lose_too_much_weight',
	'long_stay_res_depressive_symptoms'
];
export const MDSQualityLongStayVaccineMetrics = [
	'long_stay_res_given_pneumococcal_vaccine',
	'long_stay_res_given_seasonal_influenza_vaccine'
];
export const MDSQualityLongStayMetrics = [
	'long_stay_outpatient_ed_visits_per_res_days',
	'long_stay_num_hospitalizations_per_res_days',
	'long_stay_res_help_daily_activities',
	'long_stay_res_self_report_mod_to_sev_pain',
	'long_stay_res_catheter_left_in_bladder',
	'long_stay_res_urinary_tract_infection',
	'long_stay_res_exp_one_or_more_falls_major_injury',
	'long_stay_res_antipsychotic_medication',
	'long_stay_res_ability_to_move_worsened',
	'long_stay_res_with_pressure_ulcers'

	// 'residents_who_lose_too_much_weight',
	// 'residents_who_were_physically_restrained',
	// 'residents_who_self_rpt_mod_to_severe_pain_long_stay',
	// 'residents_with_a_urinary_tract_infection',
	// 'high_risk__residents_with_pressure_ulcers',
	// 'low_risk_res_who_lose_control_bowels_bladder',
	// 'residents_whose_ability_to_move_independently_worsened',
	// 'residents_who_have_depressive_symptoms',
	// 'residents_who_received_an_antipsychotic_medication',
	// 'res_assessed_given_seasonal_influenza_vaccine',
	// 'res_assd_appr_given_pneumococcal_vaccine',
	// 'res_whose_need_help_with_daily_act_has_inc',
	// 'res_who_rec_an_anti_or_hypn_medication',
	// 'res_experiencing_one_or_more_falls_major_inj',
	// 'res_with_catheter_inserted_left_in_bladder'
];
export const FireInspectionSummaryMetrics = [
	'fire_insp_last_survey',
	'fire_insp_num_survey',
	'fire_insp_total_deficiencies'
];
export const FireDeficiencyCategoryMetrics = [
	'count_of_emergency_preparedness_deficiencies',
	'count_of_automatic_sprinkler_systems_deficiencies',
	'count_of_construction_deficiencies',
	'count_of_services_deficiencies',
	'count_of_corridor_walls_and_doors_deficiencies',
	'count_of_egress_deficiencies',
	'count_of_electical_deficiencies',
	'count_of_fire_alarm_systems_deficiencies',
	'count_of_smoke_deficiencies',
	'count_of_interior_deficiencies',
	'count_of_gas_and_vacuum_and_electrical_systems_deficiencies',
	'count_of_hazardous_areas_deficiencies',
	'count_of_illumination_and_emergency_power_deficiencies',
	'count_of_medical_gases_and_anesthetizing_areas_deficiencies',
	'count_of_smoking_regulations_deficiencies',
	'count_of_miscellaneious_deficiencies'
];

export const FireInspectionMetrics = () => {
	let list = ['staffing_rating'];
	list = list.concat(FireInspectionSummaryMetrics);
	list = list.concat(FireDeficiencyCategoryMetrics);
	return list;
};

export const HealthInspectionsMetrics = [
	'health_insp_last_survey',
	'health_insp_num_survey',
	'health_insp_total_deficiencies'
];
export const HealthDeficiencyCategoryMetrics = [
	'freedom_abuse_neglect_exploitation_def',
	'quality_of_life_and_care_def',
	'res_assess_care_planning_def',
	'nursing_and_physician_serv_def',
	'res_rights_deficiencies',
	'nutrition_and_dietary_deficiencies',
	'pharmacy_service_deficiencies',
	'environmantal_deficiencies',
	'administration_deficiencies'
];
export const HealthInspectionMetrics = () => {
	let list = ['staffing_rating'];
	list = list.concat(HealthInspectionsMetrics);
	list = list.concat(HealthDeficiencyCategoryMetrics);
	return list;
};

export const StaffingFiveStarMetrics = () => {
	let list = ['staffing_rating'];
	list = list.concat(PBJStaffingMetrics);
	return list;
};
export const StaffingMetrics = () => {
	let list = [];
	list = list.concat(PBJStaffingMetrics);
	list = list.concat(PBJNonNurseStaffingMetrics);
	return list;
};

export const QualityMetrics = () => {
	let list = [];
	list = list.concat(HealthInspectionDomainMetrics);
	list = list.concat(QualitySummaryMetrics);
	list = list.concat(FireInspectionMetrics());
	list = list.concat(HealthInspectionMetrics());
	list = list.concat(MDSQualityShortStayMetrics);
	list = list.concat(MDSQualityShortStayVaccineMetrics);
	list = list.concat(MDSQualityLongStayVaccineMetrics);
	list = list.concat(MDSQualityLongStayMetrics);
	list = list.concat(MDSQualityLongStayAdditionalMetrics);
	list = list.concat(StaffingMetrics());
	return list;
};

export const All = () => {
	let list = [];
	list = list.concat(QualityMetrics());
	return list;
};

export const Info = () => {
	return {
		topic: 'Quality',
		groups: [
			{
				label: 'Five-Star Rating Categories',
				key: 'snfQualityTab_1',
				fields: QualitySummaryMetrics
			},
			{
				label: 'Health Inspection Domain',
				key: 'snfQualityTab_2',
				fields: HealthInspectionDomainMetrics
			},
			{
				label: 'Staffing Domain',
				key: 'snfQualityTab_3',
				fields: StaffingFiveStarMetrics()
			},
			{
				label: 'Quality Measures Domain',
				key: 'snfQualityTab_4',
				fields: FiveStart_QualityMeasuresMetrics
			},
			{
				label: 'Percentage of Long-Stay',
				key: 'snfQualityTab_5',
				fields: MDSQualityLongStayMetrics
			},
			{
				label: 'Percentage of Short-Stay',
				key: 'snfQualityTab_6',
				fields: MDSQualityShortStayMetrics
			}
		]
	};
};
