import React, { Component } from "react";
import zxcvbn from "zxcvbn";
import SignIn from '../../LoginPage/SignIn';

const validEmail = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        url: '',
        password: '',
        conPass: '',
      },
      type: 'password',
      score: 'null',
      value: true,
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        password: '',
        conPass: '',
        passMatch: ''
      },
      login: false,
      username: null,
      email: null,
      phone_number: null,
      user: null
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if(localStorage.getItem('username')) {
      this.setState({
        username: localStorage.getItem('username')
      });
    }
  }

  handleClick = e => {
    let state = this.state,
      selForm = e.currentTarget.id;
    
    if(selForm === 'log') {
      state.login = true;
    } else {
      state.login = false;
    }
    this.setState(state);
    console.log(state.login);
  }

  handleChange = e => {
    e.preventDefault();
    const {name, value} = e.target;
    let state = this.state,
      tempData = e.target.value;

    switch (name) {
      case 'firstName':
        if(value.length < 3 && value.length != 0) {
          state.errors.firstName = 'First name must be at least 3 characters';
          state.value = true;
        } else {
          state.errors.firstName = '';
        }
        state.userInfo.firstName = tempData.charAt(0).toUpperCase() + tempData.slice(1);
        break;
      case 'lastName':
        if(value.length < 3 && value.length != 0) {
          state.errors.lastName = 'Last name must be at least 3 characters';
          state.value = true;
        } else {
          state.errors.lastName = '';
        }
        state.userInfo.lastName = tempData.charAt(0).toUpperCase() + tempData.slice(1);
        break;
      case 'email':
        if(!validEmail.test(value)) {
          state.errors.email = 'A valid email is required';
          state.value = true;
        } else {
          state.errors.email = '';
        }
        state.userInfo.email = tempData;
        break;
      case 'phone': 
        let phone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if(value.match(phone)) {
          state.errors.phone = '';
        } else {
          state.errors.phone = 'Please provide a valid phone number';
          state.value = true;
        }
        state.userInfo.phone = tempData;
        break;
      case 'company': 
        if(value.length < 3 && value.length != 0) {
          state.errors.company = 'Company name must be at least 3 characters';
          state.value = true;
        } else {
          state.errors.company = '';
        }
        state.userInfo.company = tempData;
        break;
      case 'address': 
        if(value.length < 3 && value.length != 0) {
          state.errors.address = 'A valid address is required';
          state.value = true;
        } else {
          state.errors.address = '';
        }
        state.userInfo.address = tempData;
        break;
      case 'city': 
        if(value.length < 2 && value.length != 0) {
          state.errors.city = 'A valid city is required';
          state.value = true;
        } else {
          state.errors.city = '';
        }
        state.userInfo.city = tempData;
        break;
      case 'state':
        if(value.length < 2 && value.length != 0) {
          state.errors.state = 'A valid state is required';
          state.value = true;
        } else {
          state.errors.state = '';
        }
        if(value.length === 2) {
          state.userInfo.state = tempData.toUpperCase();
        } else {
          state.userInfo.state = value;
        }
        break;
      case 'zip': 
        if(value.length < 5 && value.length != 0) {
          state.errors.zip = 'A valid zip code is required';
          state.value = true;
        } else {
          state.errors.zip = '';
        }
        state.userInfo.zip = tempData;
        break;
      case 'url': 
        state.userInfo.url = tempData;
        break;
      case 'password':
        if(value.length < 8 && value.length != 0) {
          state.errors.password = 'Password must be at least 8 characters';
          state.value = true;
        } else  {
          state.errors.password = '';
        }
        state.userInfo.password = e.target.value;
        if(state.userInfo.conPass && state.userInfo.password !== state.userInfo.conPass) {
          state.errors.conPass = 'Passwords must match';
        } else {
          state.errors.conPass = '';
        }
        break;
      case 'conPass':
        if(value === state.userInfo.password) {
          state.errors.conPass = '';
        } else {
          state.errors.conPass = 'Passwords must match';
          state.value = true;
        }
        state.userInfo.conPass = e.target.value;
        break;
      default:
        break;
    }
    if(state.userInfo.firstName && state.userInfo.lastName && state.userInfo.email && state.userInfo.phone && state.userInfo.company && state.userInfo.address && state.userInfo.city && state.userInfo.state && state.userInfo.zip && state.userInfo.password && state.userInfo.conPass && !state.errors.firstName && !state.errors.lastName && !state.errors.email && !state.errors.phone && !state.errors.company && !state.errors.address && !state.errors.city && !state.errors.state && !state.errors.zip && !state.errors.password && !state.errors.conPass) {
      state.value = false;
    }
    this.setState(state);
  };

  showHide = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password'
    });
  };

  passScoreLabel = result => {
    switch (result.score) {
      case 0:
        return 'N/A';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    };
  };

  handleSubmit = e => {
    e.preventDefault();
    let state = this.state;
    state.userInfo.username = state.userInfo.firstName + ' ' + state.userInfo.lastName;
    state.userInfo.company = state.userInfo.company.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    state.userInfo.address = state.userInfo.address.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    state.userInfo.city = state.userInfo.city.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    state.value = true;
    this.setState(state);
    if(this.props.onRegister) {
      this.props.onRegister(this.state.userInfo);
    } else {
      alert("There was an error");
    }
  };

  handleFormInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const testedResult = zxcvbn(this.state.userInfo.password);
    const regForm =
      <form id="regForm" className="inputFrame" onSubmit={this.handleSubmit} autoComplete="off">
        <p id="reqMess">* Required Fields</p>
        <div>
          <label htmlFor="firstName"><i className="fas fa-user"></i></label>
          <input 
            type="text" 
            className="loginInput reqInput" 
            id="firstName" 
            name="firstName" 
            placeholder="* First Name" 
            value={this.state.firstName} 
            onChange={this.handleChange}
            required
          />
          {this.state.errors.firstName.length > 0 && <span className="error">{this.state.errors.firstName}</span>}
        </div>
        <div>
          <label htmlFor="lastName"><i className="fas fa-signature"></i></label>
          <input 
            type="text" 
            className="loginInput reqInput" 
            id="lastName" 
            name="lastName" 
            placeholder="* Last Name" 
            value={this.state.lastName} 
            onChange={this.handleChange}
            required
          />
          {this.state.errors.lastName.length > 0 && <span className="error">{this.state.errors.lastName}</span>}
        </div>
        <div>
          <label htmlFor="email"><i className="fas fa-at"></i></label>
          <input
            type="email"
            className="loginInput reqInput" 
            id="email" 
            name="email" 
            placeholder="* Email"
            value={this.state.email}
            onChange={this.handleChange}
            required
          />
          {this.state.errors.email.length > 0 && <span className="error">{this.state.errors.email}</span>}
        </div>
        <div>
          <label htmlFor="phone"><i className="fas fa-phone-alt"></i></label>
          <input
            type="tel"
            className="loginInput reqInput" 
            id="phone" 
            name="phone" 
            placeholder="* Phone #"
            value={this.state.phone}
            onChange={this.handleChange}
            required
          />
          {this.state.errors.phone.length > 0 && <span className="error">{this.state.errors.phone}</span>}
        </div>
        <div>
          <label htmlFor="comp"><i className="fas fa-building"></i></label>
          <input
            type="text"
            className="loginInput reqInput" 
            id="comp" 
            name="company" 
            placeholder="* Company Name"
            value={this.state.company}
            onChange={this.handleChange} 
            required 
          />
          {this.state.errors.company.length > 0 && <span className="error">{this.state.errors.company}</span>}
        </div>
        <div>
          <label htmlFor="address"><i className="fas fa-map-pin"></i></label>
          <input
            type="text"
            className="loginInput reqInput" 
            id="address" 
            name="address" 
            placeholder="* Street Address"
            value={this.state.address}
            onChange={this.handleChange} 
            required 
          />
          {this.state.errors.address.length > 0 && <span className="error">{this.state.errors.address}</span>}
        </div>
        <div>
          <label htmlFor="city"><i className="fas fa-map-marker-alt"></i></label>
          <input
            type="text"
            className="loginInput reqInput" 
            id="city" 
            name="city" 
            placeholder="* City"
            value={this.state.city}
            onChange={this.handleChange} 
            required 
          />
          {this.state.errors.city.length > 0 && <span className="error">{this.state.errors.city}</span>}
        </div>
        <div id="stateWrap">
          <label htmlFor="state"><i className="fas fa-map-marked-alt"></i></label>
          <input
            type="text"
            className="loginInput reqInput" 
            id="state" 
            name="state" 
            placeholder="* State"
            value={this.state.state}
            onChange={this.handleChange} 
            required 
          />
          {this.state.errors.state.length > 0 && <span className="error">{this.state.errors.state}</span>}
        </div>
        <div id="zipWrap">
          <label htmlFor="zip"><i className="fas fa-map"></i></label>
          <input
            type="text"
            className="loginInput reqInput" 
            id="zip" 
            name="zip" 
            placeholder="* Zip"
            value={this.state.zip}
            onChange={this.handleChange} 
            required 
          />
          {this.state.errors.zip.length > 0 && <span className="error">{this.state.errors.zip}</span>}
        </div>
        <div>
          <label htmlFor="url"><i className="fas fa-globe"></i></label>
          <input
            type="text"
            className="loginInput" 
            id="url" 
            name="url" 
            placeholder="URL"
            value={this.state.url}
            onChange={this.handleChange} 
          />
        </div>

        <div>
          <label htmlFor="pwd"><i className="fas fa-unlock-alt"></i></label>
          <input 
            type={this.state.type} 
            className="loginInput reqInput" 
            id="pwd" 
            name="password" 
            placeholder="* Password"
            autoComplete="off"
            value={this.state.userInfo.password}
            onChange={this.handleChange}
            required
          />
          <span 
            className="showHide" 
            onClick={this.showHide}
          >
            {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
          </span>
          {this.state.errors.password.length > 0 && <span className="error">{this.state.errors.password}</span>}
          <label className="passStrength">
            {this.state.userInfo.password && (
              <>
                <progress
                  className={`passScore score-${this.passScoreLabel(testedResult)}`}
                  value={testedResult.score}
                  max="4"
                />
                <span><strong>Password strength:</strong> {this.passScoreLabel(testedResult)}</span>
              </>
            )}
          </label>
        </div>
        <div>
          <label htmlFor="conPass"><i className="fas fa-lock"></i></label>
          <input 
            type={this.state.type} 
            className="loginInput reqInput" 
            id="conPass" 
            name="conPass" 
            placeholder="* Confirm Password"
            value={this.state.userInfo.conPass}
            onChange={this.handleChange}
            required
          />
          <span 
            className="showHide" 
            onClick={this.showHide}
          >
            {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
          </span>
          {this.state.errors.conPass.length > 0 && <span className="error">{this.state.errors.conPass}</span>}
        </div>
        <div>
          <input 
            type="submit" 
            id="sub" 
            className="loginBttn" 
            name="submit" 
            value="Next"
            disabled={this.state.value}
          />
        </div>
      </form>;
    const logForm = 
      <div id="login">
        <SignIn
          username={this.state.username}
          handleFormInput={this.handleFormInput}
          inputs={this.state}
        />
      </div>;
    let currForm = null;
    if(this.state.login) {
      currForm = logForm;
    } else {
      currForm = regForm;
    }
    return (
      <section id="content" className="userInfo">
        <div id="register" className="container">
          <div id="regFrame" className="row">
            <div className="col-12 text-center">
              <h1 className="text-center">User Info</h1>
            </div>
            <div className="col-md-6 col-12" id="bodyCopy">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et odio pellentesque diam volutpat commodo sed. Bibendum arcu vitae elementum curabitur vitae nunc sed velit dignissim. Eget sit amet tellus cras adipiscing enim eu turpis. Urna duis convallis convallis tellus id interdum velit laoreet. Libero volutpat sed cras ornare arcu dui vivamus arcu felis.</p>
              <ul id="reportList">
                <li><span>Report Type: </span>{this.props.currentReport.planName}</li>
                <li><span>Facility Type: </span>{this.props.currentReport.selected}</li>
                <li><span>{this.props.currentReport.selectedStates.length > 1 || this.props.currentReport.selectedStates[0] === 'All States'? 'States Selected:': 'Selected State:'} </span> 
                  {this.props.currentReport.selectedStates.map((value, index) => {
                    return (
                      <span className="states">{value}</span>
                    )
                  })}
                </li>
                <li><span>Price: </span>${this.props.currentReport.price}</li>
              </ul>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et odio pellentesque diam volutpat commodo sed. Bibendum arcu vitae elementum curabitur vitae nunc sed velit dignissim. Eget sit amet tellus cras adipiscing enim eu turpis. Urna duis convallis convallis tellus id interdum velit laoreet. Libero volutpat sed cras ornare arcu dui vivamus arcu felis.</p>
            </div>
            <div className="col-md-6 col-12" id="regLogin">
              <div className="tabFrame">
                <h3 
                  id="reg" 
                  className={this.state.login ? "" : "active"} 
                  onClick={this.handleClick} 
                >
                  Register
                </h3>
                <h3 
                  id="log" 
                  className={this.state.login ? "active" : ""}
                  onClick={this.handleClick}
                >
                  Login
                </h3>
              </div>
              {currForm}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default(UserInfo);