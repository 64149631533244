import React, { Component } from "react";
import * as products from './products';
import CardPrem from './CardPrem';
import CardHosp from './CardHosp';
import CardSNF from './CardSNF';
import Promo from './Promo';


class Confirm extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    return (
      <section id="content">
        <div id="plan" className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Select Your Plan</h1>
              <h5>Or</h5>
              <h3>Enter a License Code</h3>
              {/* <p>We know offer the purchasing of multiple licenses.<br />Purchasing more than one license saves time and money.</p> */}
            </div>
          </div>
          <div id="plansRow" className="row">
            <div className="col-md-6 col-12 cardPrem">
              <CardPrem 
                currentPlan={this.props.currentPlan}
                onPlan={this.props.onPlan} 
              />
            </div>
            {/* <div className="col-lg-4 col-md-6 col-12 cardSnf">
              <CardSNF 
                currentPlan={this.props.currentPlan}
                onPlan = {this.props.onPlan} 
              />
            </div> */}
            <div className="col-md-6 col-12" id="loginFrame">
              <Promo
                currentPlan={this.props.currentPlan} 
                onPlan = {this.props.onPlan} 
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default(Confirm);