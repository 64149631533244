import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup, Polyline, GeoJSON } from 'react-leaflet';
import Leaflet, { bounds } from 'leaflet';
import poi from './assets/red.png';
import blueIcon from './assets/blue.png';
import * as styles from '../../core/styles/CustomStyle';

export const MapColors = {
	Best: styles.Q1bg,
	Good: styles.Q2bg,
	Poor: styles.Q3bg,
	Worst: styles.Q4bg
};

export const MapMarker = class MapMarker {
	constructor(provider) {
		this.lat = 0;
		this.lng = 0;
		this.icon = null;
		this.content = null;

		if (provider) {
			this.lat = provider.lat;
			this.lng = provider.lng;
			this.content = <span>{provider.name} </span>;
		}
	}
};
export const MapMarkers = class MapMarkers extends Array {
	constructor(...item) {
		super(item);
		this.length = 0;
	}

	Add(lat, lng, content) {
		if (!lat) {
			console.error('no lat param passed to MapMarkers Add method');
		}

		if (!lng) {
			console.error('no lng param passed to MapMarkers Add method');
		}

		if (!content) {
			console.error('no content param passed to MapMarkers Add method');
		}
		if (!lat || !lng) {
			throw new Error('no lat and or long passed returning early!!!!!');
			return null;
		}
		let rec = new MapMarker();
		rec.lat = lat;
		rec.lng = lng;
		rec.content = content;
		this.push(rec);
	}
};
export const MapGeoObject = class MapGeoObject {
	constructor(geometry, key, name, color, popupContent) {
		this.type = 'Feature';
		this.key = key;
		this.name = name;
		this.color = color;
		this.popupContent = popupContent;
		this.geometry = geometry;
	}
};

class DataMapGeoShapes extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { map, layerContainer } = this.props;
		return null;
	}
}

export const MarkerIcon = icon => {
	return new Leaflet.Icon({
		iconUrl: icon,
		iconSize: [38, 38],
		iconAnchor: [22, 38],
		popupAnchor: [-3, -76],
		shadowSize: [68, 68],
		shadowAnchor: [22, 22]
	});
};

export const MarkerDivIcon = (fa_tag, clsName = 'fa-lg', IconColor = 'black') => {
	let divIcon = `<i class="${fa_tag} ${clsName ? clsName : ''}" ${
		IconColor ? 'style="color: ' + IconColor + '"' : ''
	} />`;
	return new Leaflet.divIcon({
		html: divIcon,
		iconAnchor: [22, 38],
		popupAnchor: [-3, -76],
		className: clsName
	});
};

export class DataMap extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign(
			{
				height: '100%',
				width: '100%',
				center: [0, 0],
				markers: [],
				geoShapes: [],
				zoom: 10,
				zoomControl: false,
				scrollWheelZoom: false,
				doubleClickZoom: false,
				dragging: false
			},
			props
		);
	}

	get MapStyle() {
		return { height: this.state.height, width: this.state.width };
	}

	BuildMarkers() {
		let result = [];
		if (this.state.markers && Array.isArray(this.state.markers)) {
			this.state.markers
				.filter(marker => Boolean(marker.lat !== null && marker.lng !== null))
				.forEach((x, k) => {
					const icon = {
						iconUrl: null,
						iconSize: [38, 38],
						iconAnchor: [22, 38],
						popupAnchor: [-3, -76],
						shadowSize: [68, 68],
						shadowAnchor: [22, 22]
					};
					let markerProps = {
						position: [x.lat, x.lng]
					}; 
					if (k === 0) {
						icon.iconUrl = poi;
					} else {
						icon.iconUrl = blueIcon;
					}
					markerProps = Object.assign(markerProps, {
						icon: new Leaflet.Icon(icon)
					});
					let row = (
						<Marker {...markerProps}>
							<Popup>{x.content}</Popup>
						</Marker>
					);

					result.push(row);
				});
		}
		return result;
	}

	buildGeoShapes() {
		const onFeatureEach = (feature, layer) => {
			let popupContent = '<p></p>';

			if (feature.properties && feature.properties.popupContent) {
				popupContent += feature.properties.popupContent;
			}
			layer.bindPopup(popupContent);
		};

		let result = [];
		this.state.geoShapes.forEach(x => {
			if (x !== null) {
				const feature = {
					type: x.type,
					properties: {
						name: x.name,
						popupContent: x.popupContent
					},
					geometry: x.geometry
				};

				const featureColor = x.color ? { color: x.color, fillColor: x.color } : {};
				result.push(
					<GeoJSON key={x.key} data={feature} style={featureColor} onEachFeature={onFeatureEach} />
				);
			}
		});

		return result;
	}

	buildPolygons() {
		const hasPolygons = this.state.polygons && Array.isArray(this.state.polygons);
		const onFeatureEach = (feature, layer) => {
			let popupContent = '<p></p>';

			if (feature.properties && feature.properties.popupContent) {
				popupContent += feature.properties.popupContent;
			}

			layer.bindPopup(popupContent);
		};

		const getColor = x => {
			const val = Number(x.MarketShare) * 1000;

			if (val >= 100) {
				return '#FF0000';
			} else if (70 >= val < 100) {
				return '#ff8d04';
			} else if (60 >= val < 70) {
				return '#FFFF00';
			} else if (50 >= val < 60) {
				return '#00E000';
			} else if (40 >= val < 50) {
				return '#0080FF';
			} else if (val < 39) {
				return '#0000FF';
			} else {
				return '#0000FF';
			}
		};

		const featureStyle = x => ({
			color: getColor(x),
			opacity: 0.08,
			fillColor: getColor(x),
			radius: 8,
			weight: 1,
			fillOpacity: 0.4
		});

		const mapGeoItem = (zipcode = {}) => ({
			...zipcode,
			geometry: {
				...zipcode.geom_id,
				properties: {
					popupContent: `
					<div class="popup-content">
						<h6>${zipcode.Zipcode || ''}</h6>
				
						<div>Total Cases: ${zipcode.TotalCases || ''}</div>
						
						<div>Total Days: ${zipcode.TotalDays || ''}</div>
						
						<div>AvgLOS: ${zipcode.AvgLOS || ''}</div>
						
						<div>TotalCharges: ${zipcode.TotalCharges || ''}</div>
						
						<div>PropZipChargesTotal: ${zipcode.PropZipChargesTotal || ''}</div>
					</div>	
					`
				}
			}
		});
		const GeoEl = ea => {
			return <GeoJSON data={ea.geom_id} style={featureStyle(ea)} onEachFeature={onFeatureEach} />;
		};

		return hasPolygons ? this.state.polygons.map(mapGeoItem).map(GeoEl) : null;
	}

	render() {
		const gradient = {
			0.1: '#89BDE0',
			0.2: '#96E3E6',
			0.4: '#82CEB6',
			0.6: '#FAF3A5',
			0.8: '#F5D98B',
			'1.0': '#DE9A96'
		};
		let maxBounds = [
			[5.49955, -167.276413], //Southwest
			[83.162102, -52.23304] //Northeast
		];

		return (
			<Map
				center={this.state.center}
				zoom={this.state.zoom}
				zoomControl={this.state.zoomControl}
				scrollWheelZoom={this.state.scrollWheelZoom}
				doubleClickZoom={this.state.doubleClickZoom}
				dragging={this.state.dragging}
				style={this.MapStyle}
				maxBounds={maxBounds}
			>
				<TileLayer
					attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OSM</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
					url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
				/>
				{this.BuildMarkers()}
				{this.buildGeoShapes()}
				{this.buildPolygons()}
				{this.props.children}
				<DataMapGeoShapes />
			</Map>
		);
	}
}
