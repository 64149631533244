import React from 'react';

const lightText = {
	fontSize: '13px',
	color: 'gray',
	lineHeight: '16px'
};

class DataSourceItem extends React.Component {
	render() {
		return (
			<div className="label-secondary">
				<h5>{this.props.dataset.title}</h5>
				<span>{this.props.dataset.description}</span>
				<br />
				{/* <span style={lightText}>Last Updated Date: {this.props.dataset.updateDate}</span> */}
			</div>
		);
	}
}

class FaqDatasetList extends React.Component {
	constructor(props) {
		super(props);
		this.faqDataset = this.defaultDataset();
	}

	defaultDataset() {
		var datasource = [];
		datasource.push({
			id: 1,
			title: 'CMS Limited Data Sets (LDS) - Standard Analytic Files (SAFs)',
			description:
				'Data from CMS that contain beneficiary level claims records as submitted by their providers. They exclude data elements that may identify specific beneficiaries. Information contained includes claim date, diagnosis and procedural information, revenue center codes and charge and payments records. There are separate SAF files for each type of provider, including hospitals, home health, skilled nursing facilities and hospice. For hospitals, there are inpatient and outpatient SAF files. The inpatient SAF files contain all lnpatient Prospective Payment System (IPPS) claims from Medicare beneficiaries. The Outpatient SAF files contain records of only those hospitals using the Outpatient Prospective Payment System (OPPS). Information on claims by Medicare beneficiaries using outpatient services includes diagnosis codes, bill type, outlier payments, and service revenue payments. HMP Metrics has assembled a data base of all provider claims paid by Medicare since 2006. Currently the accessible data base contains over 20 billion claims. (Source: CMS)',
			updateDate: '3/31/2018'
		});

		datasource.push({
			id: 2,
			title: 'CMS Hospital Compare',
			description:
				"Hospital Compare is a website created by the joint efforts of CMS and Hospital Quality Alliance (HQA). It provides information about the quality of care that providers deliver to their patients. Various measures of quality are reported and in recent years, many measures have been added which inform CMS' quality programs and the reimbursement received by the providers that participate in them. Programs include the Value-Based Purchasing (VAB) Program, the Readmission Reduction (HRRP) and the Hospital-Acquired Conditions (HAC) Reduction Program. Quality data is used to support patients in making informed decisions and to support efforts to improve quality in U.S. hospitals (Source: CMS)",
			updateDate: '10/22/2018'
		});

		datasource.push({
			id: 3,
			title: 'National Provider Identifiers (NPI)',
			description:
				"The National Provider Identifier is the standard unique health identifier for healthcare providers adopted by the Secretary of Health and Human Services in accordance with the Health Insurance Portability and Accountability Act of 1996 (HIPAA). It can be obtained from CMS's National Plan and Provider Enumeration System (NPPES). ",
			updateDate: '11/13/2018'
		});

		datasource.push({
			id: 4,
			title: 'Provider of Services (POS) File',
			description:
				'The POS file contains data on characteristics of hospitals and other types of healthcare facilities, including the name and address of the facility and the type of Medicare services the facility provides, among other information. (Source: CMS, https://www.cms.gov/Research-Statistics-Data-and-Systems/Downloadable-Public-Use-Files/Provider-of-Services/)',
			updateDate: '10/29/2018'
		});

		datasource.push({
			id: 5,
			title: 'Hospital Service Area File',
			description:
				'The Hospital Service Area File is a summary of calendar year Medicare inpatient hospital fee-for-service claims data. It contains number of discharges, total days of care and total charges summarized by hospital provider number and the ZIP code of the Medicare beneficiary. (Source: CMS, https://www.cms.gov/Research-Statistics-Data-and-Systems/Files-for-Order/NonIdentifiableDataFiles/HospitalServiceAreaFile.html)',
			updateDate: '02/12/2018'
		});

		datasource.push({
			id: 6,
			title: 'United States Census Bureau',
			description:
				'Sociodemographic information is sourced from the United States Census Bureau. (https://www.census.gov)',
			updateDate: '01/01/2018'
		});

		datasource.push({
			id: 7,
			title: 'CMS Impact File',
			description:
				'Various files that provide data on the estimated payment impacts of policy changes to the Inpatient Prospective Payment System (IPPS). Data points include case mix index (CMI), wage index, Hospital Readmissions Reduction Program (HRRP) adjustment factors, and more. (Source: CMS)',
			updateDate: '01/01/2018'
		});

		return datasource;
	}

	render() {
		var items = this.faqDataset.map(a => {
			return <DataSourceItem dataset={a} key={a.id} />;
		});
		return (
			<>
				<div className="label-secondary">
					<h5>CMS Cost Reports</h5>
					<p>The most comprehensive public database available to examine a national provider of hospital services is the Healthcare Cost Report Information System (“HCRIS”), maintained by the Centers for Medicare &amp; Medicaid Services (“CMS”). CMS was created by Congress to administer the Medicare program and the federal component of the Medicaid Program.</p>
					<p>Before a healthcare service provider can bill Medicare for services to eligible Medicare beneficiaries, it must first enter into a provider agreement with CMS, which requires among its many and complex conditions of participation, that the service provider (1) bill on a uniform basis for <strong>ALL</strong> in and outpatient services, regardless of payer or insurance coverage and (2) file a Medicare cost report with CMS or its designated fiscal intermediary by the last day of the fifth month following the close of its fiscal year. A Medicare cost report can contain more than 3,000 lines of data including a balance sheet and detailed information on revenues, expenses and profitability. It also contains detailed information on hours worked by staff and the total units of service provided by the provider. Each Medicare cost report must be certified by either the Chief Executive Officer (“CEO”) or the Chief Financial Officer (“CFO”) of the Medicare provider organization (hospital). Multi-hospital systems are also required to file a “Home Office” cost report which details the allocation of allowable corporate office overhead costs to each of its Medicare provider subsidiaries.</p>
					<p>The Medicare cost report certifications are broad and expansive in tone. As a preface to the cost report’s certification, the following warning appears:</p>
					<p><strong>MISREPRESENTATION OR FALSIFICATION OF ANY INFORMATION CONTAINED IN THIS COST REPORT MAY BE PUNISHABLE BY CRIMINAL, CIVIL AND ADMINISTRATIVE ACTION, FINE AND/OR IMPRISONMENT UNDER FEDERAL LAW. FURTHERMORE, IF SERVICES IDENTIFIED IN THIS REPORT WERE PROVIDED OR PROCURED THROUGH THE PAYMENT DIRECTLY OR INDIRECTLY OF A KICKBACK OR WERE OTHERWISE ILLEGAL, CRIMINAL, CIVIL AND ADMINISTRATIVE ACTION, FINES AND/OR IMPRISONMENT MAY RESULT.</strong></p>
					<p>This advisory is followed by the actual certification language itself:</p>
					<p><strong>CERTIFICATION BY OFFICER OR ADMINISTRATOR OF PROVIDER(S).</strong></p>
					<p><strong>I HEREBY CERTIFY that I have read the above statement and that I have examined the accompanying electronically filed or manually submitted cost report and the Balance Sheet and Statement of Revenue and Expenses prepared by [name of provider, ID number of provider] for the cost reporting period beginning [date] and ending [date] and that to the best of my knowledge and belief, it is a true, correct and complete statement prepared from the books and records of the provider in accordance with applicable instructions, except as noted.</strong></p>
					<p><strong>I further certify that I am familiar with the laws and regulations regarding the provision of the health care services, and that the services identified in this cost report were provided in compliance with such laws and regulations (emphasis added). (This is followed by: signature of provider’s officer, title and date).</strong></p>
					<p>Because most hospitals, nursing homes and home health agencies are part of multi-provider systems most are no longer individually audited by the systems’ independent accountants. As a result, the Medicare cost report with its enforceable certification is the best publicly available source of financial and operating information for participating institutions.</p>
					<p>HMP Metrics which includes all Medicare cost reports (hospital, skilled nursing home, home health and hospice) filed since 1996, the first year they were available in an electronic format. Currently we have approximately 600,000 complete Medicare cost reports in our database, including approximately cost reports.</p>
				</div>
				{items}
			</>
		);
	}
}

export default FaqDatasetList;
