import React, { Component } from "react";
import CardUser from './CardUser';
import CardPrem from './CardPrem';
import CardHosp from './CardHosp';
import CardSNF from './CardSNF';

class Pay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liceTotal: 1,
      unlimited: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.unlimitedChange = this.unlimitedChange.bind(this);
  };
  
  componentDidMount() {
    let winH = window.innerHeight,
    bodyH = document.getElementById('subscribe').clientHeight;
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
	};

  handleChange = e => {
    e.preventDefault();
    const {name, value} = e.target;
    let state = this.state;
  
    switch(name) {
      case 'licenseCnt':
        if(state.liceTotal < 1) {
          state.liceTotal = 1;
        } else {
          state.liceTotal = Math.floor(e.target.value);
          this.props.liceChange(state.liceTotal);
          if(state.liceTotal >= 62 && this.props.currentPlan.plan === 'PREMIUM' || state.liceTotal >= 44 && this.props.currentPlan.plan === 'SNF') {
            state.unlimited = true;
          }
        }
        break;
      default:
        break;
    };
  };

  unlimitedChange = e => {
    let state = this.state;
    if(state.unlimited === false) {
      state.unlimited = true;
      this.props.liceChange(state.unlimited);
    } else {
      state.unlimited = false;
      if(state.liceTotal >= 62 && this.props.currentPlan.plan === 'PREMIUM') {
        state.liceTotal = 61;
      }
      else if(state.liceTotal >= 44 && this.props.currentPlan.plan === 'SNF') {
        state.liceTotal = 43;
      }
      this.props.liceChange(state.liceTotal);
    }
  };

  render() {
    const prem = <CardPrem currentPage={this.props.currentPage} currentPlan={this.props.currentPlan} />;
    const hosp = <CardHosp currentPage={this.props.currentPage} currentPlan={this.props.currentPlan} />;
    const snf = <CardSNF currentPage={this.props.currentPage} currentPlan={this.props.currentPlan} />;
    let activePlan = null;
    switch(this.props.currentPlan.selected) {
      case 'HOSP':
        activePlan = hosp;
        break;
      case 'SNF':
        activePlan = snf;
        break;
      case 'PREMIUM':
        activePlan = prem;
        break;
    }
    
    return(
      <section id="content">
        <div id="pay" className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Licenses &amp; Payment</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12" id="cardFrame">
              {/* <h3>Licenses</h3>
              <div id="licCntFrame">
                {this.state.unlimited === false ?
                  <div className="licCntRow">
                    <label htmlFor="licCnt"><i className="fas fa-user-plus"></i></label>
                    <input 
                      type="number"
                      id="licCnt"
                      className={this.state.liceTotal === 1 ? "loginBttn": "loginBttn changed"}
                      name="licenseCnt"
                      value={this.state.liceTotal}
                      onChange={this.handleChange}
                      min="1"
                    />
                    <span>* Number of Licenses</span>
                  </div>
                :
                ''}
                <div className="licCntRow">
                  <input 
                    type="checkbox"
                    id="unlimited"
                    className="loginBttn"
                    name="unlimited"
                    onChange={this.unlimitedChange}
                    checked={this.state.unlimited}
                  />
                  <span>*Unlimited Licenses</span>
                </div>
              </div> */}
              <CardUser 
                currentPlan={this.props.currentPlan} 
                regInfo={this.props.regInfo} 
                onPay={this.props.onPay}
              />
            </div>
            <div className="col-md-6 col-12">
              {activePlan}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default(Pay);