import React from 'react';

export class LegendItem {
	constructor(color, text) {
		this.color = color;
		this.text = text;
	}

	render() {
		return (
			<span className="col">
				<i
					className="fa fa-square"
					style={{ 'fontSize': '20px', color: this.color, marginRight: '3px' }}
				/>
				{this.text}
			</span>
		);
	}
}
export const Legend = (...legendItems) => {
	return (
		<div>
			<div className=" navbar ">{legendItems.map(x => x.render())}</div>
		</div>
	);
};
