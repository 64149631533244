import React, { Component } from "react";
import react from "react";
import { Auth } from "aws-amplify";
import zxcvbn from "zxcvbn";

export default class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passOld: '',
      passNew: '',
      passCon: '', 
      message: '',
      disabled: true,
      type: 'password'
    };
    this.handleChange = this.handleChange.bind(this);
    this.showHide = this.showHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let state = this.state;
    switch(name) {
      case 'passOld':
        state.passOld = e.target.value;
        state.message = state.passOld.length >= 8 || state.passOld.length === 0 ? '' : 'Passwords must be at least 8 characters';
        break;
      case 'passNew':
        state.passNew = e.target.value;
        if(state.passNew.length < 8 && state.passNew.length > 0) {
          state.message = 'Passwords must be at least 8 characters';
        } else if(state.passOld && state.passNew === state.passOld) {
          state.message = "New password can't match old password";
        } else if(state.passCon && state.passCon !== state.passNew) {
          state.message = 'Passwords must match';
        } else {
          state.message = '';
        }
        break;
      case 'passCon':
        state.passCon = e.target.value;
        if(state.passCon != state.passNew) {
          state.message = "Passwords must match";
        } else if( state.passCon === state.passOld) {
          state.message = "New password can't match old password";
        } else {
          state.message = '';
        }
        break;
      default:
        break;
    }
    if(state.passOld && state.passNew && state.passCon && !state.message) {
      state.disabled = false;
    } else {
      state.disabled = true;
    }
    this.setState(state);
  };

  passScoreLabel = result => {
    switch (result.score) {
      case 0:
        return 'N/A';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  }

  showHide = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password'
    })
  };
  
  handleSubmit = e => {
    e.preventDefault();
    let state = this.state;
    state.disabled = true;
    Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, state.passOld, state.passNew);
    })
    .then(data => {
      // console.log(data);
      state.message = 'Your password has been successfully updated';
      this.setState(state);
      setTimeout(this.props.cancel, 2000);
    })
    .catch(err => {
      // console.log(err);
      state.message = err.message;
      this.setState(state);
    });
  };

  render() {
    const testedResult = zxcvbn(this.state.passNew);
    return (
      <div id="loginFrame" className="col-12">
        <form className="authentication__form" onSubmit={this.handleSubmit}>
          <div className="inputFrame">
            <h2>Change Password</h2>
            <div>
              <label htmlFor="pwd"><i className="fas fa-key"></i></label>
              <input
                type={this.state.type}
                id="passOld" 
                className="loginInput" 
                name="passOld" 
                placeholder="Current Password" 
                autoComplete="off" 
                value={this.state.passOld}
                onChange={this.handleChange}
                required
              />
            </div>
            <hr />
            <div>
              <label htmlFor="pwd"><i className="fas fa-unlock-alt"></i></label>
              <input 
                type={this.state.type} 
                id="passNew" 
                className="loginInput" 
                name="passNew" 
                placeholder="New Password"
                autoComplete="off"
                value={this.state.passNew}
                onChange={this.handleChange}
                required
              />
              <span 
                className="showHide" 
                onClick={this.showHide}
              >
                {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
              </span>
            </div>

            <div>
              <label htmlFor="passCon"><i className="fas fa-lock"></i></label>
              <input 
                type={this.state.type} 
                id="passCon" 
                className="loginInput" 
                name="passCon" 
                placeholder="Confirm Password"
                value={this.state.passCon}
                onChange={this.handleChange}
                required
              />
              <span 
                className="showHide" 
                onClick={this.showHide}
              >
                {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
              </span>
              <label className="passStrength" id="newPass">
                {this.state.passNew && (
                  <>
                    <progress
                      className={`passScore score-${this.passScoreLabel(testedResult)}`}
                      value={testedResult.score}
                      max="4"
                    />
                    <span><strong>Password strength:</strong> {this.passScoreLabel(testedResult)}</span>
                  </>
                )}
              </label>
            </div>
            {this.state.message.length > 0 && <p className="error passError">{this.state.message}</p>}
            <div className="text-center">
              <input 
                type="submit" 
                id="subNewPass"
                className="loginBttn" 
                name="subCode" 
                value="Confirm Change" 
                disabled={this.state.disabled}
              />
              <input 
                type="submit" 
                id="cancel"
                className="loginBttn" 
                name="cancel" 
                value="Cancel" 
                onClick={this.props.cancel}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}