export const ComparativeUtilizationFields = [
	'ip_case_mix_index',
	'ip_medical_ms_drg',
	'ip_surgical_ms_drg',
	'ip_routine_discharges_to_home',
	'ip_discharges_to_other_acute_care_hosp',
	'ip_discharges_to_home_health',
	'ip_discharges_to_hospice',
	'ip_discharges_to_snf',
	'ip_deaths',
	'ip_other_discharges',
	'ip_medicare_adv_discharges'
];

export const MajorDiagnosticCategoryFields = [
	'ip_mdc_pre_mdc',
	'ip_mdc_dd_nervous_system',
	'ip_mdc_dd_eye',
	'ip_mdc_dd_ear_nose_mouth_throat',
	'ip_mdc_dd_respiratory_sys',
	'ip_mdc_dd_circulatory_sys',
	'ip_mdc_dd_digestive_sys',
	'ip_mdc_dd_hepatobiliary_sys_pancreas',
	'ip_mdc_dd_musculoskeletal_sys_connective_tissue',
	'ip_mdc_dd_skin_subcutaneous_tissue_breast',
	'ip_mdc_dd_endocrine_nutritional_metabolic_system',
	'ip_mdc_dd_kidney_urinary_tract',
	'ip_mdc_dd_male_reproductive_sys',
	'ip_mdc_dd_female_reproductive_sys',
	'ip_mdc_pregnancy_childbirth_puerperium',
	'ip_mdc_newborn_other_neonates',
	'ip_mdc_dd_blood_blood_forming_organs_immunological',
	'ip_mdc_dd_myeloproliferative',
	'ip_mdc_dd_infectious_parasitic',
	'ip_mdc_dd_mental',
	'ip_mdc_dd_alcohol_drug_use_induced_mental',
	'ip_mdc_injuries_poison_toxic_effect_drugs',
	'ip_mdc_burns',
	'ip_mdc_fact_influencing_status_contacts_hth_serv',
	'ip_mdc_multiple_significant_trauma',
	'ip_mdc_human_immunodeficiency_virus_infection',
	'ip_mdc_mdc_category_missing',
	'ip_mdc_total_medicare_discharges'
];

export const ComparativeUtilizationMetrics = () => {
	let list = [];
	list = list.concat(ComparativeUtilizationFields);
	return list;
};

export const MedicarePatientVolumneMetrics = () => {
	let list = [];
	list = list.concat(MajorDiagnosticCategoryFields);
	return list;
};

export const All = () => {
	let list = [];
	list = list.concat(ComparativeUtilizationMetrics());
	list = list.concat(MedicarePatientVolumneMetrics());
	return list;
};

export const Info = () => {
	return {
		topic: 'SMD Inpatient',
		groups: [
			{
				key: 'smdComparativeUtilization',
				label: 'Comparative Utilization',
				fields: ComparativeUtilizationMetrics()
			},
			{
				key: 'smdMedicarePatientVol',
				label: 'Medicare Patient Volume',
				fields: MedicarePatientVolumneMetrics()
			}
		]
	};
};
