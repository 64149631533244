export const All = () => {
	let result = [
		'street',
		'state',
		'system_name',
		'county',
		'city',
		'cbsa_code',
		'provider_ccn',
		'fac_phone',
		'prvdr_website_url',
		'chow_date',
		'entirely_skilled_nursing_facility',
		'chow',
		'afs_different_hcris',
		'chain_org_affiliation',
		'zip_code',
		'urban_status',
		'snf_ownership_type',
		'total_ip_days_visits_total',
		'county',
		'total_beds'
	];
	return result;
};

export const Info = () => {
	return {
		topic: 'Facility Summary',
		groups: [
			{
				key: 'facInfo',
				label: 'Facility Info',
				fields: All()
			}
		]
	};
};
