export const HospitalMarketshareFields = [
	'period_end',
	'adults_peds_medicare_ip_discharges',
	'adults_peds_medicaid_ip_discharges',
	'adults_peds_titlev_ip_discharges',
	'total_discharges',
	'routine_dept_swing_bed_snf_days',
	'routine_dept_swing_bed_nf_days',
	'nursing_facility_total_inpatient_days'
];
export const BedTypeMetrics = [
	'total_beds_snf',
	'total_beds_nf',
	'total_beds_icf',
	'total_beds_hha',
	'total_beds_ltc',
	'total_beds_cmhc',
	'total_beds_hspc',
	'total_beds',
	'total_bed_days_available'
];

export const DischargeMetrics = [
	'discharges_medicare',
	'discharges_medicaid',
	'discharges_commercial',
	'discharges_other',
	'discharges_total'
];

export const InpatientDays = [
	'total_ip_days_visits_medicare',
	'total_ip_days_visits_medicaid',
	'total_ip_days_visits_title5',
	'total_ip_days_visits_other',
	'total_ip_days_visits_total'
];

export const AvgLosByInsType = [
	'total_alos_medicare',
	'total_alos_medicaid',
	'total_alos_title5',
	'total_alos_other',
	'total_alos_all_types'
];

export const UtilizationKeyPerfIndicators = [
	'occupancy_rate',
	'days_medicare_ratio',
	'days_medicaid_ratio',
	'days_other_title5_ratio'
];

export const InpatientDaysMetrics = [
	'inpatient_days_medicare',
	'inpatient_days_medicaid',
	'inpatient_days_commercial',
	'inpatient_days_other',
	'inpatient_total_days',
	'nursing_facility_ip_days_visits_medicaid',
	'nursing_facility_ip_days_visits_title5',
	'nursing_facility_ip_days_visits_other',
	'nursing_facility_ip_days_visits_total',
	'hospice_ip_days_visits_medicare',
	'hospice_ip_days_visits_medicaid',
	'hospice_ip_days_visits_other',
	'hospice_ip_days_visits_total',
	'home_health_agency_ip_days_visits_medicare',
	'home_health_agency_ip_days_visits_medicaid',
	'home_health_agency_ip_days_visits_title5',
	'home_health_agency_ip_days_visits_other',
	'home_health_agency_ip_days_visits_total',
	'other_longtermcare_ip_days_visits_total',
	'icf_iid_ip_days_visits_total',
	'discharge_medicare_ratio',
	'discharge_medicaid_ratio'
];

export const AvgLOSList = [
	'alos_snf_commercial',
	'alos_nf_commercial',
	'alos_snf_medicare',
	'alos_snf_medicaid',
	'alos_nf_medicaid',
	'alos_icf_medicaid',
	'alos_snf_total',
	'alos_nf_total',
	'alos_icf_total',
	'alos_ltc_total'
];

export const MarketShareSection = () => {
	return {
		section: 'Market Share',
		groups: [
			{
				key: 'snfUtiliizationBeds',
				label: 'Beds',
				fields: BedTypeMetrics
			},
			{
				key: 'snfUtiliizationInpatientDays',
				label: 'Inpatient Days',
				fields: InpatientDays
			},
			{
				key: '',
				label: 'Discharges',
				fields: DischargeMetrics
			},
			{
				key: '',
				label: 'Average Inpatient Length of Stay (ALOS)',
				fields: AvgLosByInsType
			},
			{
				key: '',
				label: 'Key Utilization Indicators',
				fields: UtilizationKeyPerfIndicators
			}
		]
	};
};

export const MarketShareFields = () => {
	let field_items = [];
	MarketShareSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};

export const CompetitorFields = () => {
	let field_items = [];
	MarketShareSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};

export const PatientMigrationFields = () => {
	let field_items = [];
	MarketShareSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};

export const DemographicFields = () => {
	let field_items = [];
	MarketShareSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};

export const DischargePatternFields = () => {
	let field_items = [];
	MarketShareSection().groups.map(a => {
		field_items = field_items.concat(a.fields);
	});
	return field_items;
};

export const All = () => {
	let list = [];
	list = list.concat(MarketShareFields());
	list = list.concat(CompetitorFields());
	list = list.concat(PatientMigrationFields());
	list = list.concat(DemographicFields());
	list = list.concat(DischargePatternFields());
	return list;
};

export const StrategicMarketInfo = () => {
	return {
		topic: 'Strategic Market',
		groups: [
			{
				key: 'snfSMDMarketShare',
				label: 'Market Share',
				fields: MarketShareFields()
			},
			{
				key: 'snfSMDCompetitors',
				label: 'Competitors',
				fields: CompetitorFields()
			},
			{
				key: 'snfSMDPatientMigration',
				label: 'Patient Migration',
				fields: PatientMigrationFields()
			},
			{
				key: 'snfSMDDemographics',
				label: 'Demographics',
				fields: DemographicFields()
			},
			{
				key: 'snfSMDMarketDrivers',
				label: 'Discharge Patterns',
				fields: DischargePatternFields()
			}
		]
	};
};

export const FacilityLevelSegment = [
	'net_patient_rev_and_misc_opr_rev', //Total Operating Revenue
	'total_operating_profit', // Total Operating Profit
	'total_line5_plus25', //Total Income
	'total_income_before_ebitdar', //Total Income before Capital Related Copy
	'discharges_medicare',
	'discharges_total',
	'total_ip_days_visits_total',
	'total_alos_all_types'
];

// Operating Metrics
export const FinancialListSegment = [
	'operating_margin',
	'operating_revenue_ppd',
	'total_operating_expenses_ppd',
	'long_term_debt_to_assets',
	'days_in_ar',
	'days_in_ap'
];

// Financial Metric Section
export const FinancialSegment = [
	'net_accounts_receivable', //Net Accounts Receivable
	'total_current_assets', // Total Current Assets
	'total_assets', // Total Assets
	'total_current_liabilities_bssummary', // Total Current Liabilities
	'total_liabilities_bssummary', //Total Liabilities
	'total_fund_balances_bssummary', // Total Fund Balances
	'total_liabilities_fundbalances' // Total Balances
];

// Operational Metrics
export const OperationalMetricsSegment = ['occupancy_rate', 'days_medicare_ratio'];

//Direct Costs Per Patient Day
export const DirectCostsPPD = [
	'skillednursingfacility_routine_cost_center_net_expenses_ppd',
	'nursingfacility_routine_cost_center_net_expenses_ppd',
	'icf_iid_routine_cost_center_net_expenses_ppd',
	'other_long_term_care_routine_cost_center_net_expenses_ppd',
	'total_direct_costs_ppd'
];
// Quality & PFP Metrics
export const QualitySummaryMetrics = [
	'overall_rating',
	'health_inspection_rating',
	'qm_rating',
	'staffing_rating',
	'rn_staffing_rating'
];

export const CompetitorTab = () => {
	let list = [];
	list = list.concat(FacilityLevelSegment);
	list = list.concat(FinancialListSegment);
	list = list.concat(OperationalMetricsSegment);
	list = list.concat(DirectCostsPPD);
	list = list.concat(QualitySummaryMetrics);
	list = list.concat(FinancialSegment);
	return list;
};

export const CompetitorInfo = () => {
	return {
		topic: 'Market Competitors',
		groups: [
			{
				key: 'snfSMDFacLvlSgmt',
				label: 'Facility Level Financial & Operating Statistics',
				fields: FacilityLevelSegment
			},
			{
				key: 'snfSMDFinMtrcLvlSgmt',
				label: 'Financial Metrics',
				fields: FinancialListSegment
			},
			{
				key: 'snfSMDOpMtrcSgmt',
				label: 'Key Utilization Indicators',
				fields: OperationalMetricsSegment
			},
			{
				key: 'snfSMDDirLvlSgmt',
				label: 'Direct Costs',
				fields: DirectCostsPPD
			},
			{
				key: 'snfSMDFacilityLvlSgmt',
				label: 'Quality Metrics',
				fields: QualitySummaryMetrics
			},
			{
				key: 'snfSMDFacilityLvlSgmt',
				label: 'Operational Metrics',
				fields: FinancialSegment
			}
		]
	};
};
