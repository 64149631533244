import React from 'react';
import * as api from '../../../lib/ApiUtil';
import {
	faAngleDown
} from '@fortawesome/fontawesome-free-solid';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
// import './index.css';

export default class ServiceTree extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			service_option: [],
			values: this.props.services || []
		};
		
		this.service_options_changed = this.service_options_changed.bind(this);
	}

	onSetFilter(services) {
		let state = this.state;
		if(services) {
			state.values = services;
			this.setState(state);
		}
	}

	updateValue(id, value) {
		let state = this.state;
		let idx = state.values.findIndex(x => {
			return x == id;
		});

		if (idx >= 0) {
			state.values.splice(idx, 1);
		} else {
			state.values.push(id);
		}

		this.setState(state);
	}

	loadServiceOptions() {
		api.APIPost('/prvdr_service-options', null).then(
			data => {
				let state = this.state;
				state.service_option = data.data.result;
				this.setState(state);
			},
			err => {}
		);
	}

	service_options_changed(e) {
		this.updateValue(e.target.id, e.target.checked);

		if (this.props.onChange) {
			this.props.onChange(this.state.values);
		}
	}

	componentDidMount() {
		this.loadServiceOptions();
		if(this.props.regFunction) {
			this.props.regFunction('hosp', 'srv', this);
		}
	}

	buildServiceList(list) {
		let result = [];
		if (list && Array.isArray(list)) {
			list.forEach(x => {
				let found = this.state.values.find(e => {
					return (
						e == x.service_id
					);
				});
				result.push(
					<li className="checkbox ">
						<div className="custom-control-inline custom-checkbox text-primary">
							<input
								type="checkbox"
								className="custom-control-input"
								id={x.service_id}
								name={x.service_id}
								onChange={this.service_options_changed} 
								checked={found}
							/>
							<label className="custom-control-label" for={x.service_id}>
								{x.service}
							</label>
						</div>
					</li>
				);
			});
		}
		return result;
	}

	buildTree() {
		let result = [];
		let groupCount = 0;

		if (this.state.service_option) {
			this.state.service_option.forEach(x => {
				groupCount++;
				let card = (
					<div className="card">
						<div className="card-header" id={'heading_'+groupCount}>
							<button
								className="btn btn-link text-primary collapsed"
								data-toggle="collapse"
								data-target={'#card_' + groupCount}
								aria-expanded="true"
								aria-controls={'card_' + groupCount}
							>
								{x.group}
							</button>
							<FontAwesomeIcon
								icon={faAngleDown}
								className="fa text-primary"
							/>
						</div>

						<div
							id={'card_' + groupCount}
							className="collapse"
							aria-labelledby={'heading_'+groupCount}
							data-parent="#accordion"
						>
							<div className="card-body">
								<ul className="list-unstyled my-0">{this.buildServiceList(x.services)}</ul>
							</div>
						</div>
					</div>
				);

				result.push(card);
			});

			return result;
		}
	}

	render() {
		return (
			<div className="col-lg-3 col-sm-6 col-12" id="serviceAccord">
				<div id="accordion">{this.buildTree()}</div>
			</div>
		);
	}
}
