import axios from 'axios';
import Auth from '../../Security/Auth';

import * as misc from '../../../lib/misc';
const apiUrl = misc.apiUrl;

const getCustomHeader = function() {
	return {
		headers: { token: Auth.getToken() }
	};
};
export function fectchArchiveDatasets() {
	return {
		type: 'ARCHIVE_DATASET_FETCH',
		payload: axios.post(apiUrl + '/archive-dataset', {}, getCustomHeader())
	};
}

export function fetchArchiveDatasetDetails(tableId) {
	return {
		type: 'ARCHIVE_DATASET_DETAILS_FETCH',
		payload: axios.post(apiUrl + '/archive-dataset-details', { id: tableId }, getCustomHeader())
	};
}
