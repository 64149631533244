import React from 'react';
import Auth from '../../core/Security/Auth';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import * as misc from '../../lib/misc';
import * as api from '../../lib/ApiUtil';
import { initCap } from '../../lib/FormatUtil';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import ReactExport from 'react-data-export';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/fontawesome-free-solid';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
let facArray = [],
  fileName = '',
  sheetName = '',
  allArray = [],
  hospArray = [],
  snfArray = [],
  alfArray = [],
  filteredFacilities = [],
  facDataSet = [
  {
    columns: [
      {title: 'Facility Name', width: {wpx: 300}, style: {fill: {patternType: 'solid', fgColor: {rgb: '0c5991'}}, font:{sz: '14', bold: true, color: {rgb: 'ffffff'}}}},
      {title: 'Provider #', width: {wpx: 80}, style: {fill: {patternType: 'solid', fgColor: {rgb: '0c5991'}}, font:{sz: '14', bold: true, color: {rgb: 'ffffff'}}}},
      {title: 'Facility Type', width: {wpx: 180}, style: {fill: {patternType: 'solid', fgColor: {rgb: '0c5991'}}, font:{sz: '14', bold: true, color: {rgb: 'ffffff'}}}},
      {title: 'Beds', width: {wpx: 60}, style: {fill: {patternType: 'solid', fgColor: {rgb: '0c5991'}}, font:{sz: '14', bold: true, color: {rgb: 'ffffff'}}}},
      {title: 'Distance', width: {wpx: 130}, style: {fill: {patternType: 'solid', fgColor: {rgb: '0c5991'}}, font:{sz: '14', bold: true, color: {rgb: 'ffffff'}}}},
      {title: 'Street Address', width: {wpx: 400}, style: {fill: {patternType: 'solid', fgColor: {rgb: '0c5991'}}, font:{sz: '14', bold: true, color: {rgb: 'ffffff'}}}},
      {title: 'City', width: {wpx: 180}, style: {fill: {patternType: 'solid', fgColor: {rgb: '0c5991'}}, font:{sz: '14', bold: true, color: {rgb: 'ffffff'}}}},
      {title: 'State', width: {wpx: 50}, style: {fill: {patternType: 'solid', fgColor: {rgb: '0c5991'}}, font:{sz: '14', bold: true, color: {rgb: 'ffffff'}}}},
      {title: 'Latitude/Longitude', width: {wpx: 250}, style: {fill: {patternType: 'solid', fgColor: {rgb: '0c5991'}}, font:{sz: '14', bold: true, color: {rgb: 'ffffff'}}}}
    ],
    data: []
  }
];

class HospitalMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prvdr_num: props.match.params.providerId,
      domain: sessionStorage.getItem('initDomain'),
      dist: 5,
      maxDist: 100,
      facInfo: misc.getFacilityInfo(),
      latLng: null,
      filterArr: [],
      markers: [],
      update: false,
      markerGroup: null,
      map: null
    };
    this.initMap = this.initMap.bind(this);
    this.mileChange = this.mileChange.bind(this);
    this.filterMap = this.filterMap.bind(this);
    this.allSelected = this.allSelected.bind(this);
    this.updateMap = this.updateMap.bind(this);
  }

  componentDidMount() {
    let state = this.state;

    state.latLng = [state.facInfo.latitude, state.facInfo.longitude];
    this.setState(state);
    this.initMap();
  };

  initMap = () => {
    let state = this.state,
      latLngs = [],
      iconSizeDef = [15, 30],
      iconAnchorDef = [7, 0],
      popupAnchorDef = [7, 0],
      shadowUrlDef = '/images/marker-shadow.png',
      shadowSizeDef = [20, 15],
      shadowAnchorDef = [-7, -15];
    const locIcon = L.icon({
      iconUrl: '/images/marker-red.png',
      iconSize: [20, 35],
      iconAnchor: [10, 0],
      popupAnchor: [10, 0],
      shadowUrl: shadowUrlDef,
      shadowSize: [25, 17],
      shadowAnchor: [-10, -17]
    });
    const alfIcon = L.icon({
      iconUrl: '/images/marker-assisted.png',
      iconSize: iconSizeDef,
      iconAnchor: iconAnchorDef,
      popupAnchor: popupAnchorDef,
      className: 'alfMarker',
      shadowUrl: shadowUrlDef,
      shadowSize: shadowSizeDef,
      shadowAnchor: shadowAnchorDef
    });
    const hospIcon = L.icon({
      iconUrl: '/images/marker-hospital.png',
      iconSize: iconSizeDef,
      iconAnchor: iconAnchorDef,
      popupAnchor: popupAnchorDef,
      className: 'hospMarker',
      shadowUrl: shadowUrlDef,
      shadowSize: shadowSizeDef,
      shadowAnchor: shadowAnchorDef
    });
    const snfIcon = L.icon({
      iconUrl: '/images/marker-skilled.png',
      iconSize: iconSizeDef,
      iconAnchor: iconAnchorDef,
      popupAnchor: popupAnchorDef,
      className: 'snfMarker',
      shadowUrl: shadowUrlDef,
      shadowSize: shadowSizeDef,
      shadowAnchor: shadowAnchorDef
    });
    
    sheetName = 'Healthcare Facilities within '+state.dist+' miles';
    if(!state.update) {
      state.map = L.map(('map'), {scrollWheelZoom: false}).setView(state.latLng, 10);
      let marker = L.marker(state.latLng, {icon:locIcon, alt:state.facInfo.facility_name, zIndexOffset:250})
          .bindPopup('<h5><a href='+state.facInfo.prvdr_website_url+' target="_blank">'+initCap(state.facInfo.facility_name)+'</a></h5><p>'+initCap(state.facInfo.facility_address)+'<br />'+initCap(state.facInfo.facility_city)+', '+state.facInfo.facility_state_abbr+' '+state.facInfo.facility_zip+'</p>').addTo(state.map);
      
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors'
      }).addTo(state.map);
      state.markerGroup = L.layerGroup().addTo(state.map);
      this.getFileName();
    }
    api
			.APIPost('/map-nearby-facilities', {
				prvdr_num: state.prvdr_num,
        domain: state.domain,
        dist: state.dist
			})
			.then(
				data => {
          facArray = data.data.result;
          document.getElementById('map').classList.add('all');
          document.getElementById('loading').classList.add('hide');
          for(let i = 0; i < facArray.length; i++) {
            let facLL = [facArray[i].latitude, facArray[i].longitude],
              facType = facArray[i].domain,
              distance = facArray[i].distance_to,
              disRnd = misc.round(distance),
              iconType = null;
            
            if(facType === 'alf') {
              iconType = alfIcon;
            } else if(facType === 'hosp') {
              iconType = hospIcon;
            } else {
              iconType = snfIcon;
            }
            if(distance > 0.0) {
              let marker = new L.marker(facLL, {icon:iconType, alt:facArray[i].facility_type})
                .bindPopup('<h5>'+facArray[i].facility_name+'</h5><h6>'+facArray[i].facility_type+'</h6><p>'+facArray[i].full_address+'</p><p>'+disRnd+' miles</p>')
                .addTo(state.markerGroup),
                tempArray = [],
                tempBeds = 0;
              if(facArray[i].beds) {
                tempBeds = facArray[i].beds;
              }
              latLngs.push(facLL);
              tempArray.push(
                {value: facArray[i].facility_name, style: {font: {sz: '12'}, alignment: {wrapText: true}}},
                {value: misc.toInt(facArray[i].prvdr_num), style: {font: {sz: '12'}, alignment: {horizontal: 'left'}}},
                {value: facArray[i].facility_type, style: {font: {sz: '12'}, alignment: {wrapText: true}}},
                {value: tempBeds, style: {font: {sz: '12'}, alignment: {horizontal: 'left'}}},
                {value: disRnd, style: {font: {sz: '12'}, alignment: {horizontal: 'left'}}},
                {value: facArray[i].full_address, style: {font: {sz: '12'}, alignment: {wrapText: true}}},
                {value: facArray[i].city, style: {font: {sz: '12'}}},
                {value: facArray[i].state, style: {font: {sz: '12'}}},
                {value: facArray[i].location, style: {font: {sz: '12'}}}
              );
              allArray.push(tempArray);
              if(facArray[i].domain === 'hosp') {
                hospArray.push(tempArray);
              }
              else if(facArray[i].domain === 'snf') {
                snfArray.push(tempArray);
              }
              else if(facArray[i].domain === 'alf') {
                alfArray.push(tempArray);
              }
            }
          }
          state.map.fitBounds(latLngs, {padding: [10, 10]});
          document.getElementsByClassName('filter').checked = false;
          document.getElementById('allFac').checked = true;
          state.markers = state.markerGroup;
          facDataSet[0].data = allArray;
          this.setState(state);
				},
				err => {
          console.log(err);
        }
			);
  };

  getFileName() {
    let state = this.state;
		let file = 'Healthcare-Map';
    let facName = state.facInfo.facility_name;
		let today = new Date();
		let dd = String(today.getDate()).padStart(2, '0');
		let mm = String(today.getMonth() + 1).padStart(2, '0');
		let yy = today.getFullYear().toString().substr(-2);
    facName = facName.replace(/\s/g, '-');
    facName = facName.replace('&', 'and');
		today = mm+'-'+dd+'-'+yy;
		let targetfile = 'HMP-Metrics-'+file+'-'+facName+'-'+state.dist+'-Miles-'+today;
		fileName = targetfile;
	}

  mileChange = (e) => {
    let state = this.state,
      value = e.target.value;
    
    if(value > state.maxDist) {
      value = state.maxDist;
    } else {
      state.dist = value;
    }
    this.setState(state);
  };

  filterMap = e => {
    let state = this.state, 
      filter = e.target.value, 
      id = e.target.id;

    if(document.getElementById(id).checked) {
      if(id === 'allFac') {
        this.allSelected();
      } else {
        state.filterArr.push(filter);
        if(state.filterArr.length === 3) {
          this.allSelected();
        } else {
          document.getElementById('allFac').checked = false;
          document.getElementById('map').classList.remove('all');
          document.getElementById('map').classList.add(filter);
          if(filter === 'hosp') {
            for(let i = 0; i < hospArray.length; i++) {
              filteredFacilities.push(hospArray[i]);
            }
          }
          if(filter === 'snf') {
            for(let i = 0; i < snfArray.length; i++) {
              filteredFacilities.push(snfArray[i]);
            }
          }
          if(filter === 'alf') {
            for(let i = 0; i < alfArray.length; i++) {
              filteredFacilities.push(alfArray[i]);
            }
          }
          facDataSet[0].data = filteredFacilities;
        }
      }
    } else {
      state.filterArr = state.filterArr.filter(e => e !== filter);
      if(state.filterArr.length === 0) {
        this.allSelected();
      } else {
        document.getElementById('map').classList.remove(filter);
        filteredFacilities = [];
        if(state.filterArr.length > 0) {
          for(let i = 0; i < state.filterArr.length; i++) {
            if(state.filterArr[i] === 'hosp') {
              for(let i = 0; i < hospArray.length; i++) {
                filteredFacilities.push(hospArray[i]);
              }
            }
            if(state.filterArr[i] === 'snf') {
              for(let i = 0; i < snfArray.length; i++) {
                filteredFacilities.push(snfArray[i]);
              }
            }
            if(state.filterArr[i] === 'alf') {
              for(let i = 0; i < alfArray.length; i++) {
                filteredFacilities.push(alfArray[i]);
              }
            }
          }
          facDataSet[0].data = filteredFacilities;
          console.log(facDataSet[0].data);
        }
      }
    }
    this.setState(state);
  };

  allSelected = () => {
    let state = this.state;

    if(state.filterArr.length > 0) {
      state.filterArr = [];
    }
    document.getElementById('allFac').checked = true;
    document.getElementById('hospFilter').checked = false;
    document.getElementById('snfFilter').checked = false;
    document.getElementById('alfFilter').checked = false;
    document.getElementById('map').classList.remove('hosp', 'snf', 'alf');
    document.getElementById('map').classList.add('all');
    facDataSet[0].data = allArray;
  };

  updateMap = e => {
    e.preventDefault();
    let state = this.state;
    state.markers.clearLayers();
    state.update = true;
    document.getElementById('loading').classList.remove('hide');
    this.setState(state);
    this.initMap();
  };

  render() {
    document.body.id = 'compMap';
    return (
      <>
        <PageNavigation selectedTab="5" domain="hosp" />
        <section id="content">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h2>{initCap(this.state.facInfo.facility_name)} Comparison Map</h2>
              </div>
              <div className="col-lg-10 col-md-8 col-sm-12" id="mapFrame">
                <div id="map"></div>
                <div id="loading"><img src="./images/loading.gif" alt="Data Loading" /></div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-12" id="ctrl">
                <form id="slideFrame" onSubmit={this.updateMap}>
                  <h5>Distance</h5>
                  <input 
                    type="range" 
                    id="milesSlide" 
                    min="1" max={this.state.maxDist} 
                    onChange={this.mileChange} 
                    step="1" 
                    default 
                    value={this.state.dist} 
                  />
                  <input 
                    type="number" 
                    id="milesInput" 
                    className="text-center" 
                    min="1" max={this.state.maxDist} 
                    onChange={this.mileChange} 
                    step="1" 
                    default 
                    value={this.state.dist} 
                  />
                  <label for="milesInput">Miles <span>(max {this.state.maxDist})</span></label>
                  <input 
                    type="submit" 
                    className="loginBttn" 
                    name="submit" 
                    value="Update Distance" 
                  />
                </form>
                <div id="mapFilters">
                  <h5>Filter by Type</h5>
                  <input type="checkbox" id="allFac" className="filter" name="all" value="all" onChange={this.filterMap} />
                  <label for="all">All</label><br />
                  <input type="checkbox" id="hospFilter" className="filter" name="hosp" value="hosp" onChange={this.filterMap} />
                  <label for="hosp">Hospital</label><img src="/images/marker-hospital.png" alt="Hospital Marker" /><br />
                  <input type="checkbox" id="snfFilter" className="filter" name="snf" value="snf" onChange={this.filterMap} />
                  <label for="snf">Skilled Nursing</label><img src="/images/marker-skilled.png" alt="Skilled Nursing Marker" /><br />
                  <input type="checkbox" id="alfFilter" className="filter" name="alf" value="alf" onChange={this.filterMap} />
                  <label for="alf">Assisted Living</label><img src="/images/marker-assisted.png" alt="Assisted Living Marker" /><br />
                </div>
                <div id="exportMap">
                  <ExcelFile 
                    filename={fileName} 
                    element={
                      <div className="exportFile text-center">
                        <span className="pr-2">Export Report</span>
                        <FontAwesomeIcon className="fa-1x text-primary" icon={faExternalLinkAlt} />
                        <span className="expLabel">(.xlsx format)</span>
                      </div>
                    }
                  >
                    <ExcelSheet dataSet={facDataSet} name={sheetName} />
                  </ExcelFile>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default(HospitalMap);