/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": `${process.env.REACT_APP_REGION}`,
    "aws_cognito_identity_pool_id": `${process.env.REACT_APP_REGION}:${process.env.REACT_APP_IDENTITY_POOL_ID}`,
    "aws_cognito_region": `${process.env.REACT_APP_REGION}`,
    "aws_user_pools_id": `${process.env.REACT_APP_USER_POOL_ID}`,
    "aws_user_pools_web_client_id": `${process.env.REACT_APP_USER_POOL_CLIENT_ID}`,
    "oauth": {}
};

export default awsmobile;


// "aws_project_region": "us-east-2",
// "aws_cognito_identity_pool_id": "us-east-2:c4a033fd-2dc6-4d1d-b7a3-7ef3f662e23f",
// "aws_cognito_region": "us-east-2",
// "aws_user_pools_id": "us-east-2_eNCghjJo9",
// "aws_user_pools_web_client_id": "1tl1oom7cv542mhlrrkcvp7onh",
// "oauth": {}
