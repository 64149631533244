import htmlToImage from 'html-to-image';
import * as _ from 'lodash';
import zipcelx from './zipcelx';

export function exportDomToImage(divId, fileName) {
	htmlToImage
		.toJpeg(document.getElementById(divId), {
			quality: 0.95,
			backgroundColor: '#FFFFFF'
		})
		.then(function(dataUrl) {
			var link = document.createElement('a');
			link.download = fileName + '.jpeg';
			link.href = dataUrl;
			link.click();
		});
	//   .then(function (blob) {
	// 	const FileSaver = require('file-saver');
	// 	FileSaver.saveAs(blob, fileName + '.png');
	//   });
}

export function exportToCsv(filename, rows, headers = null, keyOverride = null) {
	return exportToXlsx(filename, rows, headers, keyOverride);
}

export function exportToXlsx(filename, rows, headers = null, keyOverride = null) {
	filename = filename.replace(/[^\w\s]/gi, '');
	filename = filename.slice(0, 31);
	const keys = Array.isArray(keyOverride) ? keyOverride : [];
	
	const processRow = function(row) {
		
		if (keys.length > 0) {
			return keys.map(a => {
				return {
					value: row[a] || '',
					type: !isNaN(row[a]) ? 'number' : 'string'
				};
			});
		} else {
			return row.map(a => {
				return { value: a || '', type: !isNaN(row[a]) ? 'number' : 'string' };
			});
		}
	};

	let csvHeader = [];
	let csvFile = [];
	if (headers && Array.isArray(headers)) {
		csvHeader = headers.map(a => {
			return { value: a, type: 'label' };
		});
	}
	csvFile.push(csvHeader);
	for (let i = 0; i < rows.length; i++) {
		csvFile.push(processRow(rows[i]));
	}
	const configXLSX = {
		filename: filename,
		sheet: {
			data: csvFile
		}
	};
	
	zipcelx(configXLSX);
}
