import {
	OUTPATIENT_ICD10_GET_LIST,
	OUTPATIENT_ICD10_GET_LIST_SUCCESS,
	OUTPATIENT_ICD10_GET_LIST_ERROR,
	OUTPATIENT_APC_GET_LIST,
	OUTPATIENT_APC_GET_LIST_SUCCESS,
	OUTPATIENT_APC_GET_LIST_ERROR
} from './constants';
import BenchmarkUtil from '../../../lib/BenchmarkUtil';

const initialStateICD10 = {
	loading: false,
	error: false,
	data: new BenchmarkUtil()
};
const initialStateAPC = {
	loading: false,
	error: false,
	data: new BenchmarkUtil()
};

export const outpatient_icd10 = (state = initialStateICD10, action) => {
	switch (action.type) {
		case OUTPATIENT_ICD10_GET_LIST: {
			return { ...state, loading: true, error: false };
		}
		case OUTPATIENT_ICD10_GET_LIST_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				data: new BenchmarkUtil(action.payload.data.data)
			};
		}
		case OUTPATIENT_ICD10_GET_LIST_ERROR: {
			return { ...state, loading: false, error: action.payload.data.error };
		}
		default:
			return state;
	}
};
export const outpatient_apc = (state = initialStateAPC, action) => {
	switch (action.type) {
		case OUTPATIENT_APC_GET_LIST: {
			return { ...state, loading: true, error: false };
		}
		case OUTPATIENT_APC_GET_LIST_SUCCESS: {
			return {
				...state,
				loading: false,
				error: false,
				data: new BenchmarkUtil(action.payload.data.data)
			};
		}
		case OUTPATIENT_APC_GET_LIST_ERROR: {
			return { ...state, loading: false, error: action.payload.data.error };
		}
		default:
			return state;
	}
};
