import React, {Fragment} from 'react';
import Subscribe from '../../../components/navigation/subscribe';
import * as misc from '../../../../lib/misc';

export default domain => {
	let snfMetrics = (
		<Fragment>
			<ul className="list-unstyled">
				<li>
					Income Statement
				</li>
				<li>
					Balance Sheet
				</li>
				<li>
					Malpractice Premiums
				</li>
				<li>
					Financial Performance Metrics
				</li>
				<li>
					Overhead Costs
				</li>
			</ul>
		</Fragment>
	);

	let hospMetrics = (
		<Fragment>
			<ul className="list-unstyled">
				<li>
					Days Cash on Hand
				</li>
				<li>
					Labor Cost as a Percentage of Total Net Patient Revenue
				</li>
				<li>
					Inpatient vs Outpatient Charges
				</li>
				<li>
					Long Term Debt to Total Assets
				</li>
			</ul>
		</Fragment>
	);

	let finMetrics = null;
	if (domain == 'info')
		finMetrics = (
			<div>
				<u>
					<h3>Hospital Metrics</h3>
				</u>
				{hospMetrics}
				<u>
					<h3>Skilled Nursing Facility (SNF) Metrics</h3>
				</u>
				{snfMetrics}
			</div>
		);
	else if (domain === 'snf') finMetrics = snfMetrics;
	else finMetrics = hospMetrics;

	return (
		<Fragment>
			<section id="content">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1>Detailed Financial Insight</h1>
						</div>
						<div className="col-sm-8 col-12">
							<p>The Financial Performance tool is a simple view into a provider’s key financials in easy to read charts designed to illustrate financial viability. Financial indicators are sourced from CMS’ Healthcare Cost Report Information System (HCRIS). Additional information regarding data sources can be found in the FAQ section.</p>
						</div>
						<Subscribe />
					</div>
				</div>
			</section>
			<section className="bgBlue">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2>Monitor financial performance with measures, including:</h2>
						</div>
						<div className="col-md-6 col-12">
							<img src="/images/financial_example.png" alt="Laptop displaying financial metrics" />
						</div>
						<div className="col-md-6 col-12">
							{finMetrics}
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
};
