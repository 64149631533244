import React, {Fragment} from 'react';
import './index.css';
const YearSelect = class YearSelect extends React.Component {
	constructor(props) {
		super(props);
		this.years = this.props.years;
		this.showAllYears = props.showAllYears === false ? false : true;
		this.showLatest = props.showLatest === false ? false : true;
		this.handleChange = this.handleChange.bind(this);

		if (this.showLatest == true) this.state = { year: 'LATEST' };
		else this.state = { year: 0 };
	}
	handleChange(e) {
		let state = this.state;
		state.year = e.target.value;
		if (this.props.callback) {
			this.props.callback(state.year);
		}

		this.setState(state);
	}

	buildOptions() {
		let options = [];
		if (this.years && Array.isArray(this.years)) {
			this.years.forEach(x => {
				options.push(<option value={x}>{x}</option>);
			});
			if (this.showAllYears === true) options.push(<option value={'ALL'}>{'All Yrs'}</option>);
			if (this.showLatest === true) options.push(<option value={'LATEST'}>{'Latest'}</option>);
		}
		return options;
	}

	render() {
		return (
			<div className="select-wrapper">
				<select
					id="selectYear"
					value={this.state.year}
					onChange={this.handleChange}
					style={{ height: '32px', width: '95px' }}
				>
					{this.buildOptions()}
				</select>
			</div>
		);
	}
};

export default YearSelect;
