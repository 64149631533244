import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Popover, Button, Form, InputGroup } from 'react-bootstrap';
import * as misc from '../../lib/misc';
import './navigation/index.css';
import { DataSearch, ReactiveBase } from '@appbaseio/reactivesearch';
import SearchBar from './searchBar';
import * as providerActions from '../../core/actions/GlobalActions';

let segment = 'hosp';

const mapDispatchToProps = dispatch => {
	return {
		updateWhereChange: () => dispatch(providerActions.sessionStorageWhereChange()),
		updateProviderInfo: ids => dispatch(providerActions.findProviders(ids))
	};
};
class facilityCompare extends React.Component {
	constructor(props, context) {
		super(props, context);

		if (this.props.changeComparison) {
			this.changeComparison = this.props.changeComparison.bind(this);
		}
		let storedCompetitors = sessionStorage.getItem('competitors');
		this.comparisons = new Array(4);
		if (storedCompetitors && storedCompetitors !== 'null') {
			this.comparisons = JSON.parse(storedCompetitors);
		}
		this.handleShow = this.handleShow.bind(this);
		this.resetCompetitors = this.resetCompetitors.bind(this);
		this.competitorUpdated = this.competitorUpdated.bind(this);
		this.facilitySearch = this.facilitySearch.bind(this);
		this.onCompetitorSelected = this.onCompetitorSelected.bind(this);

		this.compareId = this.props.TargetId || misc.Guid();

		this.state = {
			comparisons: this.comparisons,
			segment: this.props.segment || 'hosp',
			selectionChanged: false
		};

		this.setState(this.state);
		segment = this.props.segment || 'hosp';
	}

	setSearchDomain() {
		switch (segment) {
			case 'snf':
				return misc.snfSearchApiUrl;
			case 'hha':
				return misc.hhaSearchApiUrl;
			default:
				return misc.hospSearchApiUrl;
		}
	}
	changeComparison() {}

	competitorUpdated() {
		sessionStorage.setItem('competitors', JSON.stringify(this.state.comparisons));
		this.setState({
			show: false
		});
		this.props.updateWhereChange();
		this.changeComparison();
	}

	resetCompetitors() {
		this.setState({
			comparisons: [],
			show: false
		});
		sessionStorage.setItem('competitors', JSON.stringify([]));
		this.props.updateWhereChange();
		this.changeComparison();
	}

	handleShow() {
		this.setState({
			show: true
		});
	}

	onCompetitorSelected(prvdr_rec) {
		if (!this.state.comparisons) {
			this.state.comparisons = new Array(4);
		}
		_.remove(this.state.comparisons, _.isUndefined);
		let targetFacility = prvdr_rec;

		if (!_.find(this.state.comparisons, targetFacility)) {
			this.state.comparisons.push(targetFacility);
		}

		this.state.comparisons.concat(new Array(4));
		this.state.comparisons = this.state.comparisons.slice(0, 4);

		this.state.selectionChanged = true;
		this.setState({ comparisons: this.state.comparisons });
	}

	facilitySearch(selectedFacilities, cause, source) {
		if (!this.state.comparisons) {
			this.state.comparisons = new Array(4);
		}
		_.remove(this.state.comparisons, _.isUndefined);
		let targetFacility = source;

		if (!_.find(this.state.comparisons, targetFacility)) {
			this.state.comparisons.push(targetFacility);
		}

		this.state.comparisons.concat(new Array(4));
		this.state.comparisons = this.state.comparisons.slice(0, 4);

		this.state.selectionChanged = true;
		this.setState({ comparisons: this.state.comparisons });
	}

	removeThisFacility(facility) {
		if (facility != undefined && this.state.selectionChanged === false) {
			_.remove(this.state.comparisons, facility);
			this.setState({ comparisons: this.state.comparisons });
			this.state.selectionChanged = true;
		}
	}

	comparedProviders() {
		if (this.state.selectionChanged === true) {
			if (!this.state.comparisons || this.state.comparisons === undefined) {
				this.state.comparisons = new Array(4);
			}
			if (this.state.comparisons.length < 4) {
				this.state.comparisons.concat(new Array(4));
				this.state.comparisons = this.state.comparisons.slice(0, 4);
			}
		}
		let result = [];
		for (let i = 0; i < 4; i++) {
			let t = this.state.comparisons[i];
			if (!t || t === undefined) {
				result.push(
					<a className="list-group-item list-group-item-action col-md-6 bg-light">
						<strong className="d-flex align-content-center">Add Comparison # {i + 1}</strong>
					</a>
				);
			} else {
				result.push(
					<a className="list-group-item list-group-item-action col-md-6 bg-light">
						<small className="d-flex w-100 justify-content-between">
							<div className="mb-2 mx-2">
								<div className="row text-capitalize">{t.fac_name}</div>
								<div className="row">{t.city_name + ', ' + t.state_name}</div>
							</div>
							<small>
								<Button
									bsStyle="default"
									Tooltip="Remove"
									onClick={() => this.removeThisFacility(t)}
								>
									x
								</Button>
							</small>
						</small>
					</a>
				);
			}
		}
		this.state.selectionChanged = false;
		return result;
	}

	facilityFinder() {
		let facilityTabs = '';
		let ops = this.props.providers ? this.props.providers : [];
		let searchURL = this.setSearchDomain();
		return (
			<div className="panel-body">
				<ReactiveBase
					app={segment || 'hosp'}
					url={searchURL}
					theme={{
						typography: {
							fontFamily: 'Roboto, apple-system, "Helvetica Neue", sans-serif'
						},
						component: {
							padding: 1
						}
					}}
				>
					<DataSearch
						componentId="providerSearchSection"
						dataField={[
							'id',
							'name',
							'system_name',
							'address',
							'city_name',
							'state_abbrev',
							'state_name',
							'zip_code',
							'cbsa_name'
						]}
						placeholder="Search for a provider"
						autosuggest={true}
						queryFormat="or"
						fuzziness={0}
						debounce={100}
						onSuggestion={suggestion => ({
							label: (
								<div style={{ minWidth: '510px' }}>
									<div className="px-2 strong text-capitalize">
										{suggestion._source.id} |{' '}
										{suggestion._source.name ? suggestion._source.name.toLowerCase() : ''}{' '}
										<small className="text-capitalize">
											{suggestion._source.system_name.length > 1
												? ' - ' +
												  (suggestion._source.system_name
														? suggestion._source.system_name.toLowerCase()
														: '')
												: ''}
										</small>
									</div>
									<div className="px-4 text-capitalize">
										<small>
											{suggestion._source.address ? suggestion._source.address.toLowerCase() : ''}{' '}
											{suggestion._source.city_name
												? suggestion._source.city_name.toLowerCase()
												: ''}
											, {suggestion._source.state_name}
										</small>
									</div>
								</div>
							),
							value: '',
							source: suggestion._source // for onValueSelected to work with onSuggestion
						})}
						onValueSelected={this.facilitySearch}
						className="fa col mx-0 px-0 my-1"
					/>
				</ReactiveBase>
			</div>
		);
	}

	render() {
		const selection = this.comparedProviders();
		const facilityFinder = (
			<SearchBar
				className="w-100"
				domain={segment}
				buttons="hide"
				onSelect={this.onCompetitorSelected}
			/>
		); // this.facilityFinder(); //
		const id = this.compareId;
		const ModelTarget = '#' + id;
		return (
			<div>
				<div className="modal" id={id}>
					<div className="modal-dialog-centered modal-lg mx-auto">
						<div className="modal-content bg-primary">
							<div className="modal-header py-2 bg-primary text-white border-0 rounded-0">
								<h4 className="modal-title pl-1 py-0">Select Comparison Facility</h4>
								<button type="button" className="close" data-dismiss="modal">
									&times;
								</button>
							</div>
							<div className="modal-body bg-light w-100">
								<div className="flex p-0 m-0">
									<div className="flex p-0 my-1">{facilityFinder}</div>
									<div
										className="flex modal-body row p-0 m-0"
										style={{
											marginLeft: '15px !important',
											marginRight: '15px !important'
										}}
									>
										{selection}
									</div>
								</div>
							</div>
							<div className="modal-footer bg-light">
								<Button bsStyle="primary" onClick={this.resetCompetitors} data-dismiss="modal">
									Reset
								</Button>
								<Button bsStyle="primary" onClick={this.competitorUpdated} data-dismiss="modal">
									Apply
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	null,
	mapDispatchToProps
)(facilityCompare);
