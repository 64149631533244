import React from 'react';
import * as products from './products';
import * as promos from '../../lib/PromoUtil';

class CardPrem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: '',
      disabled: true,
      currentPlan: this.props.currentPlan,
      error: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    e.preventDefault();
    const value = e.target;
    let state = this.state;
    state.promoCode = e.target.value;
    state.error = '';
    if(state.promoCode.length > 3) {
      state.disabled = false;
    } else {
      state.disabled = true;
    }
    this.setState(state);
  }

  handleSubmit = async e => {
    e.preventDefault();
    let state = this.state;
    let PromoInfo = null;
    this.setState({disabled: true})
    try 
    {
      let result = await promos.Validate(state.promoCode);
      if(result.Error === false) {
        PromoInfo = result.result;
        state.currentPlan.orgPrice = PromoInfo.org_price;
        state.currentPlan.price = PromoInfo.new_price;
        state.currentPlan.promo = PromoInfo.code;
        state.currentPlan.selected = PromoInfo.product;
        state.currentPlan.expDate = PromoInfo.expire_date;
        state.currentPlan.description = PromoInfo.description;
        if(PromoInfo.product === 'PREMIUM') {
          state.currentPlan.planName = 'Premium';
          state.currentPlan.plan = products.premium_access.id;
        } else if(PromoInfo.product === 'SNF') {
          state.currentPlan.planName = 'Skilled Nursing Facilities';
          state.currentPlan.plan = products.snf_access.id;
        } else {
          state.currentPlan.planName = 'Hospitals';
          state.currentPlan.plan = products.hospital_access.id;
        }
        this.setState(state);
        if(this.props.onPlan) {
          this.props.onPlan(state.currentPlan);
        }
      } else {
        this.setState({error: result.Message, promoCode: ''});
      }
    }
    catch(err)
    {
      state.error = err;
      state.promoCode = '';
      this.setState(state);
      console.log(state.error);
    }
  }

  render() {
    return(
      <div className="planCard hospital">
        <div className="header">
          <h3>Enter License Code</h3>
          <p>Access Data with Promotional License</p>
        </div>
        <div className="body">
          <form id="promoForm" className="inputFrame">
            <p>
              <div className="promoFrame">
                <label htmlFor="promoCode" id="promoLabel"><i className="fas fa-laptop-code"></i></label>
                <input
                  type="text" 
                  className="loginInput" 
                  id="promoCode" 
                  name="promoCode" 
                  placeholder="License Code" 
                  value={this.state.promoCode} 
                  onChange={this.handleChange} 
                  autoComplete='off'
                />
              </div>
            </p>
            <ul>
              <li>Redeem User License and Promotional Codes</li>
              <li>Access to Data Explorer for the Hospital and SNF Data Sets</li>
              <li>Downloads of Last 5 Years of Cost Reports in Excel Format​</li>
            </ul>
          </form>
        </div>
        <div className="foot">
          {this.state.error ? 
            <h6 className="regError">{this.state.error}</h6>
          :
            <input 
              type="submit" 
              id="sub" 
              className="loginBttn" 
              name="submit" 
              value="Submit Code" 
              disabled={this.state.disabled}
              onClick={this.handleSubmit}
            />
          }
        </div>
      </div>
    );
  }
}

export default CardPrem;