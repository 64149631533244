import React from 'react';
import * as misc from '../lib/misc';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
	faSquareFull,
	faQuestionCircle,
	faChevronRight
} from '@fortawesome/fontawesome-free-solid';
import Modal from './model-popup';

class InfoPopup extends React.Component {
	constructor(props) {
		super(props);
		this.modalKey = misc.Guid();
		this.state = {};
		this.title = props.title ? props.title : '';
	}
	getButton() {
		return (
			<div className="pull-right">
				<FontAwesomeIcon
					icon={faQuestionCircle}
					size="lg"
					data-toggle="modal"
					data-target={'#' + this.modalKey}
				/>
				<Modal id={this.modalKey} title={this.title}>
					{this.props.children}
				</Modal>
			</div>
		);
	}

	render() {
		return this.getButton();
	}
}
export default InfoPopup;
