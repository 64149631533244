import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import BenchmarkDetail from './BenchmarkDetail';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import * as misc from '../../lib/misc';
import * as whereBuilder from '../../lib/whereBuilder';
import Auth from '../../core/Security/Auth';
import LandingPage from '../../core/DefaultPages/marketingInfo/HospBenchmarkInfo';

import * as Actions from '../../core/actions/ActionHelper';
import * as ActionTypes from '../Common/ActionTypes';
import * as Fields from './BenchmarkFields';
import * as fieldDefActions from './../../core/actions/GlobalActions';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.hosp,
		whereChanged: store.snfBenchmarkChart.whereChanged,
		data: store.snfBenchmarkData.Benchmark,
		dataLoaded: store.snfBenchmarkData.fetched,
		dataLoading: store.snfBenchmarkData.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetUtilizationData: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'snf',
					Fields.UtilizationMetrics()
				)
			),
		GetLaborData1: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'snf',
					Fields.LaborDetail1()
				)
			),
		GetLaborData2: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'snf',
					Fields.LaborDetail2()
				)
			),
		GetLaborData3: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'snf',
					Fields.LaborDetail3()
				)
			),
		GetLaborData4: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'snf',
					Fields.LaborDetail4()
				)
			),

		GetFinancialData1: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'snf',
					Fields.FinancialDetail1()
				)
			),
		GetFinancialData2: providers =>
			dispatch(
				Actions.ActionDataRequestOutlierAdjusted(
					ActionTypes.BENCHMARK_GET_METRICS,
					providers,
					'snf',
					Fields.FinancialDetail2()
				)
			),

		updateWhereChange: () => dispatch(fieldDefActions.sessionStorageWhereChange()),
		GetFieldInfo: (fields, basis) => {
			dispatch(fieldDefActions.AddNewFields(fields, basis, 'snf'));
		}
	};
};

class BenchmarkDetailPage extends React.Component {
	constructor(props) {
		super(props);

		if (!sessionStorage['competitors']) {
			sessionStorage.setItem('competitors', []);
		}
		this.initFacilityInfo();
		this.resetBenchmarkSettings = this.resetBenchmarkSettings.bind(this);
	}

	initFacilityInfo() {
		if (this.props.match && this.props.match.params && this.props.match.params.providerId) {
			let currentFacility = misc.getFacilityIds();
			let facility = this.props.match.params.providerId;
			if (currentFacility.indexOf(facility) < 0) {
				misc.clearFOIandCompetitors();
			}
		}
		whereBuilder.storeDefaultWhere('snf');
	}
	getBenchmarkData() {
		var ids = [];
		if (Auth.isUserSnfAuthenticated()) {
			ids = ids.concat(misc.getFOI_CompetitorsIds());
			this.props.GetFieldInfo(Fields.All(), this.props.FieldDesc);
			if (!(this.props.dataLoaded || this.props.dataLoading) && ids.length > 0) {
				this.props.GetUtilizationData(ids);
				this.props.GetLaborData1(ids);
				this.props.GetLaborData2(ids);
				this.props.GetLaborData3(ids);
				this.props.GetLaborData4(ids);
				this.props.GetFinancialData1(ids);
				this.props.GetFinancialData2(ids);
			}
		}
	}

	resetBenchmarkSettings() {
		var ids = [];
		if (Auth.isUserSnfAuthenticated()) {
			this.props.updateWhereChange();
			ids = ids.concat(misc.getFOI_CompetitorsIds());
			if (!(this.props.dataLoaded || this.props.dataLoading) && ids.length > 0) {
				this.props.GetUtilizationData(ids);
				this.props.GetLaborData1(ids);
				this.props.GetLaborData2(ids);
				this.props.GetLaborData3(ids);
				this.props.GetLaborData4(ids);
				this.props.GetFinancialData1(ids);
				this.props.GetFinancialData2(ids);
			}
		}
	}

	UNSAFE_componentWillMount() {
		this.getBenchmarkData();
	}

	componentDidUpdate() {
		if (this.props.whereChanged) {
			this.getBenchmarkData();
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		let details = null;
		if (Auth.isUserSnfAuthenticated()) {
			details = (
				<section id="content">
					<BenchmarkDetail
						{...this.props}
						prvdr={misc.getFOIandCompetitors()}
						fieldDef={this.props.FieldDesc}
						updateInfo={this.resetBenchmarkSettings}
					/>
				</section>
			);

			return (
				<Fragment>
					<PageNavigation selectedTab="6" domain={'snf'} />
					{details}
				</Fragment>
			);
		} else {
			return <LandingPage domain="snf" />;
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BenchmarkDetailPage);
