import * as Types from '../../core/actions/ActionTypes';
const defaultState = {
	qualityRating: {
		financial_score: 0.0,
		quality_score: 0.0,
		operations_score: 0.0,
		strategy_score: 0.0,
		distress_index: 0
	},
	whereChanged: false,
	error: null,
	fetching: false,
	fetched: false
};

export const qualityRatingReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'QUALITYRATING_FETCH_PENDING': {
			return { ...state, fetching: true };
		}
		case 'QUALITYRATING_FETCH_FULFILLED': {
			if (action.payload.data.QualityRating)
				return {
					...state,
					fetching: false,
					fetched: true,
					whereChanged: false,
					qualityRating: action.payload.data.QualityRating
				};
			else return { ...state, fetching: false, fetched: true };
		}
		case 'QUALITYRATING_FETCH_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				whereChanged: false,
				error: action.payload
			};
		}
		case Types.ASSIGNFACILITY_COMPLETE: {
			return {
				...state,
				fetching: false,
				fetched: false,
				whereChanged: true,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};
