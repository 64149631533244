import React, {Fragment} from 'react';
import { Button, utils } from 'react-bootstrap';
import Select from 'react-select';

import { connect } from 'react-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/fontawesome-free-solid';
import { fetchBenchmarkData } from '../../core/actions/BenchmarkActions';
import ReChartTool from '../../tools/charts/ReChartTool';
import LoadingSpinner from '../../tools/loadingSpinner';
import * as misc from '../../lib/misc';
import { exportDomToImage } from '../../lib/ExportUtil';
import futil from '../../lib/FormatUtil';
import * as miscStyle from '../../core/styles/CustomStyle';
import _ from 'lodash';
import './index.css';

const mapStateToProps = store => {
	return {
		benchmarkDetail: store.BenchmarkDetail
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetQuartileChartData: (ProviderIds, FieldIds) =>
			dispatch(fetchBenchmarkData(ProviderIds, FieldIds))
	};
};

class TrendChart extends React.Component {
	constructor(props) {
		super(props);

		this.onTrendMappingChange = this.onTrendMappingChange.bind(this);
		this.getTrendChartData = this.getTrendChartData.bind(this);
		this.onExport = this.onExport.bind(this);
		this.setupSelectedItems();
	}

	setupSelectedItems() {
		if (this.props.FieldDesc.length > 0) {
			let field = this.props.FieldDesc[0] || {
				FieldName: null,
				FieldShortName: null
			};

			let states = {
				selectedTrend: field.FieldName || 'fy_year',
				selectedTitle: field.FieldShortName || 'Fiscal Year'
			};
			this.setState(states);
		}
	}

	componentDidMount() {
		this.setupSelectedItems();
		if (this.state && this.state.selectedTrend) {
			this.GetChartData(this.state.selectedTrend);
		}
	}
	componentDidUpdate() {
		if (this.props.benchmarkDetail.whereChanged && this.state && this.state.selectedTrend) {
			this.GetChartData(this.state.selectedTrend);
		}
	}
	GetChartData(selectedTrend) {
		const ids = misc.getFOI_CompetitorsIds();
		this.props.GetQuartileChartData(ids, [selectedTrend]);
	}

	getSelectOptionsHtml() {
		let fieldList = _.orderBy(
			_.orderBy(_.uniq(this.props.FieldDesc, 'FieldShortName'), 'FieldTopic', 'asc'),
			'FieldShortName',
			'asc'
		);

		let categories = _.groupBy(fieldList, 'FieldTopic');
		let categoryResults = [];
		_.forEach(categories, function(value, key) {
			let keys = !key || key === undefined || key === 'null' ? 'Other' : key;
			let values = _.orderBy(value, 'FieldShortName', 'asc');
			categoryResults.push({
				label: keys,
				value: keys,
				options: values.map(option => {
					return {
						key: option.FieldName,
						value: option.FieldName,
						label: option.FieldShortName
					};
				})
			});
		});
		return categoryResults;
	}

	onTrendMappingChange(e) {
		let field = _.find(this.props.FieldDesc, field => field.FieldName === e.key);
		if (field) {
			this.setState({
				selectedTrend: field.FieldName,
				selectedTitle: field.FieldShortName
			});
			this.GetChartData(field.FieldName);
		}
	}

	getTrendChartSeries() {
		const facInfo = misc.getFOIandCompetitors();
		const bedchohort = facInfo[0].bed_cohort;
		let seriesNational = [
			{
				xAxis: 'year',
				xLabel: '',
				type: 'Line',
				name: 'national_rate',
				display: 'National',
				color: miscStyle.SeriesArray[0]
			}
		];
		let series = [
			{
				xAxis: 'year',
				xLabel: '',
				type: 'Line',
				name: 'state_rate',
				display: 'State',
				color: miscStyle.SeriesArray[1]
			},
			{
				xAxis: 'year',
				xLabel: '',
				type: 'Line',
				name: 'bed_cohort_rate',
				display: bedchohort,
				color: miscStyle.SeriesArray[2]
			}
		];

		let facSeries = [];
		for (let i = 0; i < facInfo.length; i++) {
			facSeries.push({
				xAxis: 'year',
				xLabel: '',
				type: 'OutlineBar',
				name: 'facility_rate_' + i.toString(),
				display: facInfo[i].name || facInfo[i].facility_name,
				color: miscStyle.SeriesArray[i + 3]
			});
		}
		series =
			facSeries.length > 1
				? seriesNational.concat(facSeries)
				: seriesNational.concat(series).concat(facSeries);
		return series;
	}
	getFieldFormat(value) {
		return Math.round(value * 100) / 100;
	}
	getTrendChartData() {
		let facilityId = misc.getFOI_CompetitorsIds();
		let series = this.getTrendChartSeries();
		let data = this.props.benchmarkDetail.BenchmarkData.data;
		let result =
			data && data.length > 0
				? data
						.sort((a, b) => a.year - b.year)
						.reduce(
							(r, a) =>
								r.push({
									year: a.year,
									facility_rate_0:
										facilityId[0] && a.rows[0][facilityId[0]]
											? this.getFieldFormat(a.rows[0][facilityId[0]].value)
											: 0.0,
									facility_rate_1:
										facilityId[1] && a.rows[0][facilityId[1]]
											? this.getFieldFormat(a.rows[0][facilityId[1]].value)
											: 0.0,
									facility_rate_2:
										facilityId[2] && a.rows[0][facilityId[2]]
											? this.getFieldFormat(a.rows[0][facilityId[2]].value)
											: 0.0,
									facility_rate_3:
										facilityId[3] && a.rows[0][facilityId[3]]
											? this.getFieldFormat(a.rows[0][facilityId[3]].value)
											: 0.0,
									facility_rate_4:
										facilityId[4] && a.rows[0][facilityId[4]]
											? this.getFieldFormat(a.rows[0][facilityId[4]].value)
											: 0.0,
									national_rate: a.rows[0]['National']
										? this.getFieldFormat(a.rows[0]['National'].value)
										: 0.0,
									state_rate: a.rows[0]['State']
										? this.getFieldFormat(a.rows[0]['State'].value)
										: 0.0,
									bed_cohort_rate: a.rows[0]['Bed']
										? this.getFieldFormat(a.rows[0]['Bed'].value)
										: 0.0
								}) && r,
							[]
						)
				: [];
		return result;
	}
	onExport() {
		exportDomToImage('trendChart', this.state.selectedTrend);
	}

	render() {
		if (this.state && this.state.selectedTrend && this.state.selectedTitle) {
			const { selectedTrend, selectedTitle } = this.state;
			const options = this.getSelectOptionsHtml();
			const chartData =
				selectedTrend != null &&
				this.props.benchmarkDetail.fetched &&
				this.props.benchmarkDetail.BenchmarkData &&
				this.props.benchmarkDetail.BenchmarkData.data &&
				this.props.benchmarkDetail.BenchmarkData.data.length > 0 &&
				this.props.benchmarkDetail.BenchmarkData.data[0].rows[0].fieldName === selectedTrend
					? this.getTrendChartData()
					: [];
			let Yformat =
				selectedTrend != null &&
				this.props.benchmarkDetail.fetched &&
				this.props.benchmarkDetail.BenchmarkData &&
				this.props.benchmarkDetail.BenchmarkData.data &&
				this.props.benchmarkDetail.BenchmarkData.data.length > 0 &&
				this.props.benchmarkDetail.BenchmarkData.data[0].rows[0].fieldName === selectedTrend
					? this.props.benchmarkDetail.BenchmarkData.data[0].rows[0].format.toLowerCase()
					: 'base';
			Yformat = ['money', 'percent', 'base'].indexOf(Yformat) >= 0 ? Yformat : 'base';

			return selectedTrend && selectedTitle ? (
				<Fragment>
					<div className="col-md-6 col-12">
						<Select
							placeholder={selectedTitle || ''}
							value={selectedTrend || ''}
							onChange={this.onTrendMappingChange}
							options={options}
						/>
					</div>
					<div className="col-md-6 col-12">
						<Button
							className="btn"
							bsSize="xsmall"
							onClick={this.onExport}
						>
							<FontAwesomeIcon icon={faExternalLinkAlt} className="fa fa-lg" />
							Export<br />Chart
						</Button>
					</div>
					<div className="col-12" id="trendChart">
						{!!this.props.benchmarkDetail.fetched ? (
							<ReChartTool
								series={this.getTrendChartSeries()}
								data={chartData}
								title={selectedTitle || ''}
								YAxis={Yformat}
								height={400}
								ShowLegend={true}
								ShowTooltip={true}
								ShowLogo={true}
							/>
						) : (
							<LoadingSpinner />
						)}
					</div>
				</Fragment>
			) : (
				'No Data Returned'
			);
		}
		return 'No Data Returned';
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TrendChart);
