export const Occupancy = ['occupancy_rate'];

export const FacilityLevelSegment = [
	'net_patient_rev_and_misc_opr_rev',
	'total_operating_profit',
	'total_line5_plus25',
	'total_liabilities_bssummary'
];

// Financial Metrics
export const FinancialListSegment = [
	'operating_margin',
	'operating_revenue_ppd',
	'total_operating_expenses_ppd',
	'long_term_debt_to_assets',
	'days_in_ar',
	'days_in_ap'
];

// Operational Metrics
export const OperationalListSegment = [
	'occupancy_rate',
	'total_fte_per_ppd',
	'labor_cost_as_a_percent_of_revenue'
];

// Quality & PFP Metrics
export const QualityListSegment = [
	'overall_rating',
	'health_inspection_rating',
	'staffing_rating',
	'qm_rating'
];

export const Summary = () => {
	let list = [];
	list = list.concat(FacilityLevelSegment);
	list = list.concat(Occupancy);
	list = list.concat(FinancialListSegment);
	list = list.concat(OperationalListSegment);
	return list;
};
export const QualitySummary = () => {
	let list = [];
	list = list.concat(QualityListSegment);
	return list;
};

export const All = () => {
	let list = [];
	list = list.concat(Summary());
	list = list.concat(QualitySummary());
	return list;
};

export const Info = () => {
	return {
		topic: 'Summary Statistics',
		groups: [
			{
				key: 'snfSummaryStats',
				label: '',
				fields: FacilityLevelSegment
			}
		]
	};
};
