import React from 'react';
import LoadingSpinner from '../../../tools/loadingSpinner';
import * as fieldDefActions from '../../actions/GlobalActions';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import './index.css';
const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.all
	};
};
const mapDispatchToProps = dispatch => {
	return {
		fetchSnfFieldInfo: () => {
			dispatch(fieldDefActions.AllFields('snf'));
		},
		fetchHospFieldInfo: () => {
			dispatch(fieldDefActions.AllFields('hosp'));
		}
	};
};

class FaqFieldList extends React.Component {
	componentDidMount() {
		this.props.fetchSnfFieldInfo('snf');
		this.props.fetchHospFieldInfo('hosp');
	}
	getHeader(column) {
		return column
			.split('_')
			.map(function(val) {
				return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
			})
			.join(' ');
	}
	
	getColumns() {
		const columns = [];
		columns.push({
			accessor: 'Segment',
			Header: 'Segment',
			filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['Segment'] }),
			className: 'text-center',
			filterAll: true,
			maxWidth: 100
		});
		columns.push({
			accessor: 'Field',
			Header: 'Field',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ['Segment', 'Field', 'Description'] }),
			filterAll: true,
			maxWidth: 500
		});
		columns.push({
			accessor: 'Description',
			Header: 'Description',
			filterMethod: (filter, rows) =>
				matchSorter(rows, filter.value, { keys: ['Segment', 'Field', 'Description'] }),
			filterAll: true,
			minWidth: 400
		});
		
		return columns;
	}
	
	render() {
		const dataList = this.props.FieldDesc
			? this.props.FieldDesc.map(data => {
					return {
						Segment: data.FieldDomain,
						Field: data.FieldShortName,
						Description: data.FieldDescription
					};
			  })
			: [];
		
		return (
			<div className="container-fluid">
				{dataList && dataList.length > 0 ? (
					<ReactTable
						data={dataList}
						filterable
						columns={this.getColumns()}
						className="-striped -highlight"
						style={{
							minHeight: '200px',
							width: '100%'
						}}
						defaultPageSize={50}
						showJumpPage={false}
					/>
				) : (
					<LoadingSpinner />
				)}
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FaqFieldList);
