import React, { Component } from "react";
import CardUser from '../../Subscribe/CardUser';

class CardInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let winH = window.innerHeight,
    bodyH = document.getElementById('orderReport').clientHeight;
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
    // console.log(this.props);
  }

  handleChange = e => {
    e.preventDefault();
    const {name, value} = e.target;
    let state = this.state;
  };

  render() {
    return (
      <section id="content" className="cardInfo">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Payment Page</h1>
            </div>
            <div className="col-md-6 col-12">
              <h4>Thank you:</h4>
              <h3>{this.props.regInfo.firstName}</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id mauris faucibus, auctor nisl non, congue est. Aliquam pulvinar odio in molestie tristique. Etiam vel lacinia lacus, in vehicula purus.</p>
              <ul id="reportList">
                <li><span>Report Type:</span> {this.props.currentPlan.planName}</li>
                <li><span>Facility Type:</span> {this.props.currentPlan.selected}</li>
                <li><span>States Selected:</span> 
                  {this.props.currentPlan.selectedStates.map((value, index) => {
                    return (
                      <span className="states">{value} </span>
                    )
                  })}
                </li>
                <li><span>Price: </span>${this.props.currentPlan.price}</li>
              </ul>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id mauris faucibus, auctor nisl non, congue est. Aliquam pulvinar odio in molestie tristique. Etiam vel lacinia lacus, in vehicula purus.</p>
            </div>
            <div className="col-md-6 col-12">
              <CardUser 
                currentPlan={this.props.currentPlan} 
                regInfo={this.props.regInfo} 
                handleChange={this.handleChange} 
              />
            </div>
          </div>
        </div>
        
      </section>
    );
  }
}

export default(CardInfo);