import React from 'react';
import { connect } from 'react-redux';
import Auth from '../../Security/Auth';
import './index.css';
import { Redirect } from 'react-router-dom';
import * as misc from '../../../lib/misc';

const mapStateToProps = store => {
	return {
		authenticated: store.auth
	};
};

const logout = () => {
	if (Auth.isUserAuthenticated()) {
		Auth.deauthenticateUser();
		window.location.href = Auth.AuthTarget + Auth.SignOutUrl;
	} else {
		window.location.href = '/';
	}
};
const login = () => {
	let targetUrl = '/login';
	window.location.href = targetUrl;
};

const RedirectUrl = props => {
	if (Auth.isUserAuthenticated()) logout();
	else login();
};

class signIn extends React.Component {
	constructor(props) {
		super(props);
		this.clickshareEnv = Auth.AuthTarget;
	}

	render() {
		let buttonText = Auth.isUserAuthenticated() ? 'Sign Out' : 'Sign In';
		return (
			<li className="nav-item">
				<a
					className="nav-link menu-link-label text-nowrap"
					onClick={() => {
						RedirectUrl();
					}}
				>
					{buttonText}
				</a>
			</li>
		);
	}
}
export default connect(
	mapStateToProps,
	null
)(signIn);
