import React from 'react';
import { Helmet } from 'react-helmet';

export default class TemplateChange extends React.Component {
  componentDidMount() {
    document.body.id = 'blog';
    let winH = window.innerHeight,
    bodyH = document.getElementById('root').clientHeight;
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Balancing Fixed Costs | HMP Metrics</title>
          <meta
            name='description' 
            content='Hospital fixed costs are expenses that do not change based on the number of patients or procedures performed. This includes things like insurance, equipment, and utilities.'
          />
        </Helmet>
        <section id="content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>Balancing Staff and Patients - FTE Per AOB</h2>
                <h4 className="greyHeader">Share this post</h4>
                <div id="shareFrame">
                  <a href="http://www.linkedin.com/shareArticle?mini=true&url=https://hmpmetrics.com/blogs/balance-fixed-costs" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                  <a href="https://twitter.com/intent/tweet?url=https://hmpmetrics.com/blogs/balance-fixed-costs&text=How To Balance Hospital Fixed Costs Through Full-Time Equivalent Staff" target="_blank"><i class="fab fa-twitter"></i></a>
                  <a href="https://www.facebook.com/sharer/sharer.php?u=https://hmpmetrics.com/blogs/balance-fixed-costs" target="_blank"><i class="fab fa-facebook-f"></i></a>
                </div>
                <p id="author">By: Daniel Evans</p>
                <p>Hospital fixed costs are expenses that do not change based on the number of patients or procedures performed. This includes things like insurance, equipment, and utilities. 
                When a hospital is operating at a high capacity, these fixed costs will be most of its budget. If it is operating under-occupancy, it will still make up most of its budget but to a lesser extent.</p>
                <p>One area of cost where an administrator has some flexibility would be staffing. Hospitals have to maintain a certain number of full-time equivalent employees, but there is flexibility in the types of staff they can hire. For example, they can employ more part-time or contract medical personnel.
                This is an important consideration for hospital administrators because it allows them to control their costs while still providing the level of service that patients expect.</p>
                <h4>Benchmarking</h4>
                <p>So how do you know when you have the right mix of hospital staffing? A good measure for this is FTEs per AOB.
                When managing your hospital's full-time equivalent staff, there are a few different measures you can use to determine if you have the right mix of staffing.</p>
                <p>One such measure is FTE per AOB. This is the number of full-time employees per average operating bed. It is an important metric you can use to ensure your hospital’s success and have a full understanding of how many Full-Time Equivalent Staff (FTEs) you have in relation to your patient load. This is where applications such as <a href="http://hmpmetrics.com/" target="_self">HMPMetrics.com</a> can help! By finding comparable facilities that are successful, you can get a determination of what a successful mix of FTEs-to-patient should look like. At the end of the day, you want to be sure that each FTE is providing a positive impact on your bottom line. When you have too many FTEs, it can lead to a loss of productivity as well as higher costs due to staffing resources.</p>
                <h4>Takeaway</h4>
                <p>Managing a hospital's finances efficiently is a challenging task. Healthcare remains an industry heavily impacted by regulation, and hospitals are required to follow strict regulatory guidelines as a condition of receiving payments from Medicare, Medicaid, and Private Health Insurance. One of the most effective ways for hospitals to manage their budgets is to benchmark themselves and their processes against similar hospitals in their regions. Doing this will not only help you identify areas of waste that can be improved upon, but it will also give your hospital the opportunity to stand out if you're achieving more than your peers.</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}