import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as misc from '../../lib/misc';
import * as fieldDefActions from '../../core/actions/GlobalActions';
import { Redirect } from 'react-router';
import LoadingSpinner from '../../tools/loadingSpinner';
import QString from 'query-string';
import { loginUser } from '../LoginPage/LoginActions';
import Auth from '../Security/Auth';

const mapStateToProps = store => {
	return {
		providerInfo: store.providerInfo
	};
};
const mapDispatchToProps = dispatch => {
	return {
		GetProviderInfo: (domain, facility) => {
			dispatch(fieldDefActions.fetchProviderInfo(domain, facility));
		},
		AuthenticateUser: creds => dispatch(loginUser(creds))
	};
};

class DefaultPage extends React.Component {
	constructor(props) {
		super(props);
	}

	UNSAFE_componentWillMount() {
		this.initFacilityInfo();
		// this.initFields();
		this.loginRequest = this.query && this.query.CSAuthResp ? this.query.CSAuthResp : '';
	}

	initFacilityInfo() {
		if (
			this.props.match &&
			this.props.match.params &&
			this.props.match.params.providerId &&
			!this.props.providerInfo.fetching &&
			!this.props.providerInfo.fetched
		) {
			let currentFacility = misc.getFacilityIds();
			let facility = this.props.match.params.providerId;
			let domain = this.props.domain || 'hosp';
			if (currentFacility.indexOf(facility) < 0) {
				misc.clearFOIandCompetitors();
				this.props.GetProviderInfo(domain, facility);
			} else {
				this.props.providerInfo.fetched = true;
			}
		}

		if (!Auth.isUserAuthenticated()) {
			if (this.props.location && this.props.location.search) {
				this.query = QString.parse(this.props.location.search);
				alert("DefaultPage");
				this.props.AuthenticateUser(
					this.query && this.query.CSAuthResp ? this.query.CSAuthResp : ''
				);
			}
		}
	}

	// initFields() {
	// 	let domain = this.props.domain || 'hosp';
	// 	let fields = this.props.fields || [];
	// 	if(fields.length > 0)
	// 	{
	// 		this.props.PreFetchFields(domain,fields);
	// 	}
	// }

	validSiteCheck() {
		let currentFacility = misc.getFacilityIds();
		let facility = this.props.match.params.providerId;
		let matchFacility = currentFacility.indexOf(facility) > -1 && this.props.providerInfo.fetched;
		return matchFacility;
	}

	render() {
		let validSite = this.validSiteCheck();
		const result = this.props.providerInfo.fetched ? (
			validSite ? (
				<Fragment>{this.props.children}</Fragment>
			) : (
				<Redirect to="/" />
			)
		) : (
			<LoadingSpinner />
		);
		return result;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DefaultPage);
