import React, {Fragment} from 'react';
import Subscribe from '../../../components/navigation/subscribe';
import * as misc from '../../../../lib/misc';
export default domain => {
	let snfMetrics = (
		<Fragment>
			<ul className="list-unstyled">
				<li>
					Modeled Historical PDPM Scenarios
				</li>
				<li>
					Medicare Profitability Analysis
				</li>
				<li>
					Overhead Costs Per Patient Day
				</li>
				<li>
					Inpatient Days
				</li>
				<li>
					Discharges
				</li>
				<li>
					Overhead Expenditures (Avg Hourly Wage)
				</li>
				<li>
					Direct Care Labor Related Expenditures (Avg Hourly Wage)
				</li>
				<li>
					Paid Hours per Patient Day
				</li>
			</ul>
		</Fragment>
	);

	let hospMetrics = (
		<Fragment>
			<ul className="list-unstyled">
				<li>
					Average Inpatient Length of Stay
				</li>
				<li>
					Medicare Case Mix Index
				</li>
				<li>
					Acute FTEs per AOB
				</li>
				<li>
					Total Net Patient Revenue per FTE
				</li>
				<li>
					Average Cost per FTE
				</li>
				<li>
					Medicare Disproportionate Share Hospital Payments
				</li>
			</ul>
		</Fragment>
	);

	let metrics = null;
	if (domain == 'info')
		metrics = (
			<div>
				<u>
					<h3>Hospital Metrics</h3>
				</u>
				{hospMetrics}
				<u>
					<h3>Skilled Nursing Facility (SNF) Metrics</h3>
				</u>
				{snfMetrics}
			</div>
		);
	else if (domain === 'snf') metrics = snfMetrics;
	else metrics = hospMetrics;

	return (
		<Fragment>
			<section id="content">
				<div className="container" id="landingPage">
					<div className="row">
						<div className="col-12">
							<h1>Compare Any Provider</h1>
						</div>
						<div className="col-sm-8 col-12">
							<p>The Benchmark Report highlights more than 100 critical utilization, labor and financial measures and allows for comparison against up to four national and state peers. Each metric is defined so users across the system can access data cleansed of outliers.</p>
						</div>
						<Subscribe />
					</div>
				</div>
			</section>
			<section className="bgBlue">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2>Compare performance in a variety of measures, including:</h2>
						</div>
						<div className="col-md-6 col-12">
							<img src="/images/benchmark_example.png" alt="Laptop displaying benchmark metrics" />
						</div>
						<div className="col-md-6 col-12">
							{metrics}
						</div>
					</div>
				</div>
				<em>
					<br />
				</em>
			</section>
		</Fragment>
	);
};
