import React, { Component } from "react";
import { Auth } from "aws-amplify";
import * as ApiUtil from '../../lib/ApiUtil';

class Verify extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      validationKey: '',
      value: true,
      error: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let winH = window.innerHeight;
    let bodyH = document.getElementById('root').clientHeight;
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
	}
  
  handleChange = ( event ) => {
    event.preventDefault();
    const { name, value } = event.target;
    let state = this.state;
    if(value.length !== 0 && value.length !== 6) {
      state.error = 'Valid code required';
      state.value = true;
    } else {
      state.error = '';
      state.value = false;
    }
    state.validationKey = event.target.value;
    this.setState(state);
  };

  handleSubmit = event => {
    event.preventDefault();
    let state = this.state;
    state.value = true;
    this.setState(state);
    if(this.props.onVerify) {
      this.props.onVerify(this.state.validationKey);
    }
  }
  
  getVerifyCode = e => {
    e.preventDefault();
    let username = this.props.regInfo.email;
    ApiUtil.UserPost('/SendConfirmationCode', {email: username});
  };

  render() {
    return (
      <section id="content">
        <div id="verify" className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Verify Your Identity</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center" id="loginFrame">
              <p>We have sent a verification code to the email:<br /><strong>{this.props.regInfo.email}</strong></p>
              <p>Please enter the code below and submit to verify your email address.</p>
              <form className="inputFrame" onSubmit={this.handleSubmit} >
                <div>
                  <input 
                    type="text" 
                    className="loginInput" 
                    id="verifyKey" 
                    name="verify" 
                    value={this.state.validationKey} 
                    onChange={this.handleChange}
                    required
                  />
                  {this.state.error.length > 0 && <span className="error">{this.state.error}</span>}
                  <input 
                    type="submit" 
                    id="sub" 
                    className="loginBttn" 
                    value="Submit" 
                    disabled={this.state.value}
                    onClick={this.handleSubmit}
                  />
                  <input 
                    type="submit" 
                    className="forgot verForgot"  
                    name="getCode" 
                    value="Get Another Code" 
                    onClick={this.getVerifyCode}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default(Verify);