import React from 'react';
import { Redirect } from 'react-router';
import PeerGroupTitle from './peerGroupTitle';
import * as misc from '../../../lib/misc';
import * as formatUtil from '../../../lib/FormatUtil';
import { Helmet } from 'react-helmet';
import './index.css';
class pageNavigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: this.props.selectedTab || '1'
		};
		this.handleTabChange = this.handleTabChange.bind(this);
		this.pageToRedirect = this.pageToRedirect.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.fixNavBarToTop);
		window.addEventListener('resize', this.fixNavBarToTop);
		this.fixNavBarToTop();
	}

	handleTabChange(e) {
		this.setState({
			selectedTab: e.target.id
		});
		return false;
	}

	fixNavBarToTop() {
		let header = document.getElementById('page_navigation_header');
		if (header) {
			if (window.pageYOffset >= 125) {
				header.classList.add('fixed-top');
			} else {
				header.classList.remove('fixed-top');
			}
		}

		let stick = document.getElementsByClassName('sticky_table_header');
		if (stick) {
			let sticking = true;
			for (let i = 0; i < stick.length; i++) {
				let headerItem = stick[i].getBoundingClientRect();
				let parentElement = stick[i].parentElement;
				let parentBox = parentElement.getBoundingClientRect();

				if (
					parentBox.top <= 104 &&
					parentBox.height > 2 * headerItem.height &&
					sticking == true &&
					parentBox.bottom >= 104 - 1.75 * headerItem.height
				) {
					stick[i].classList.add('fixed_header');
					sticking = false;
					let tempW = parentBox.width;
					stick[i].style.width = tempW+'px';
				} else if (stick[i].classList.contains('fixed_header')) {
					stick[i].classList.remove('fixed_header');
					stick[i].style.width = '100%';
				}
			}
		}
	}

	parseDomain() {
		let domain = this.props.domain;
		switch (domain) {
			case 'hosp':
				return 'hospital';
			case 'snf':
				return 'skillednursing';
			case 'hha':
				return 'homehealth';
			case 'info':
				return 'info';
			default:
				return '';
		}
	}

	pageToRedirect(facilityId) {
		let redirectPath = '/';

		let rootPath = '/' + this.parseDomain();
		switch (this.state.selectedTab) {
			case '1': {
				redirectPath = rootPath + '/summary/' + facilityId;
				break;
			}
			case '2': {
				redirectPath = rootPath + '/financial/' + facilityId;
				break;
			}
			case '3': {
				redirectPath = rootPath + '/quality/' + facilityId;
				break;
			}
			case '4': {
				redirectPath = rootPath + '/benchmark/' + facilityId;
				break;
			}
			case '5': {
				redirectPath = rootPath + '/map/' + facilityId;
				break;
			}
			case '6': {
				redirectPath = rootPath + '/strategicmarket/' + facilityId;
				break;
			}
			case '7': {
				redirectPath = rootPath + '/physicians/' + facilityId;
				break;
			}
			default: {
				break;
			}
		}
		return redirectPath;
	}

	pageDescriptionNoField() {
		let description = '';
		switch (this.state.selectedTab) {
			case '1': {
				description = 'Facility info with 400+ Metrics | HMP Metrics';
				break;
			}
			case '2': {
				description =
					'Get the most recent 5 years of Income Statements, Balance Sheet, and Key Profit and Loss Metrics for any facility | HMP Metrics';
				break;
			}
			case '3': {
				description =
					'Over 100 Quality Metrics detailing Readmissions, Total Performance for any facility | HMP Metrics';
				break;
			}
			case '4': {
				description =
					'Benchmark Data with detailed comparisons on 100+ metrics compared to similar facilities | HMP Metrics';
				break;
			}
			case '5': {
				description =
					'Healthcare facilities distance map | HMP Metrics';
				break;
			}
			case '6': {
				description = 'Strategic Market Data for any facility | HMP Metrics';
				break;
			}
			case '7': {
				description = 'Detailed Physician Information | HMP Metric Information';
				break;
			}
		}
		return description;
	}

	pageDescription(facilityInfo) {
		let description = '';
		switch (this.state.selectedTab) {
			case '1': {
				description =
					facilityInfo.facility_name +
					' is a ' +
					facilityInfo.hospital_type +
					' ' +
					facilityInfo.hosp_ownership_type +
					' facility ' +
					(facilityInfo.total_beds ? 'with ' + facilityInfo.total_beds.toString() + ' beds ' : '') +
					'in ' +
					facilityInfo.facility_city +
					', ' +
					facilityInfo.facility_state +
					' with ' +
					formatUtil.formatComma(facilityInfo.inpatient_days_total_acute_care) +
					' employees as of ' +
					facilityInfo.fy_year.toString() +
					' .. 400+ Metrics | HMP Metrics';
				break;
			}
			case '2': {
				description =
					facilityInfo.facility_name +
					(facilityInfo.system ? ' part of the ' + facilityInfo.system : '') +
					' most recent 5 years of Income Statements, Balance Sheet, and Key Profit and Loss Metrics thru ' +
					facilityInfo.fy_year.toString();
				break;
			}
			case '3': {
				description =
					'Over 100 Quality Metrics detailing Readmissions, Total Performance, on ' +
					facilityInfo.facility_name +
					(facilityInfo.system ? ' part of ' + facilityInfo.system : '') +
					' and details from ' +
					formatUtil.formatComma(facilityInfo.total_acute_cr_days) +
					' patient days in ' +
					facilityInfo.fy_year.toString();
				break;
			}
			case '4': {
				description =
					'Benchmark Data with detailed comparisons on 100+ metrics compared to other ' +
					facilityInfo.hospitaltype +
					' facilities, peers with ' +
					facilityInfo.bed_cohort +
					' and other facilities in ' +
					facilityInfo.state_name;
				break;
			}
			case '5': {
				description =
					'Healthcare facilities by distance map for ' + facilityInfo.facility_name;
				break;
			}
			case '6': {
				description =
					'Strategic Market Data for ' +
					facilityInfo.facility_name +
					' keen insights on key competitors within the Primary Service Area with inpatient / outpatient payment insights';
				break;
			}
			case '7': {
				description = 'Detailed Physicians information for ' + facilityInfo.facility_name;
				break;
			}
		}
		return description;
	}

	pageTitle(facilityName) {
		let redirectPath = facilityName === '' ? 'HMP Metrics' : facilityName + ' | HMP Metrics ';

		switch (this.state.selectedTab) {
			case '1': {
				redirectPath += 'Summary';
				break;
			}
			case '2': {
				redirectPath += 'Financials';
				break;
			}
			case '3': {
				redirectPath += ' Quality';
				break;
			}
			// case '4': {
			// 	redirectPath = '/operations';
			// 	break;
			// }
			case '4': {
				redirectPath += 'Benchmarking';
				break;
			}
			case '5': {
				redirectPath += 'Map';
				break;
			}
			case '6': {
				redirectPath += 'Marketshare';
				break;
			}
			case '7': {
				redirectPath += 'Physicians';
				break;
			}
			default: {
				break;
			}
		}
		return redirectPath;
	}
	pageMenuIcon() {
		let redirectPath = '';
		switch (this.state.selectedTab) {
			case '1': {
				redirectPath = (
					<img
						className="page-nav-icon"
						src="/images/MDHC_Metrics_FacilityAnalyzer.png"
						alt="Summary"
					/>
				);
				break;
			}
			case '2': {
				redirectPath = (
					<img
						className="page-nav-icon"
						src="/images/MDHC_Metrics_ChartTool.png"
						alt="Financials"
					/>
				);
				break;
			}
			case '3': {
				redirectPath = (
					<img
						className="page-nav-icon"
						src="/images/MDHC_Metrics_QualityReport.png"
						alt="Quality"
					/>
				);
				break;
			}
			case '4': {
				redirectPath = (
					<img
						className="page-nav-icon"
						src="/images/MDHC_Metrics_Benchmark.png"
						alt="Benchmarks"
					/>
				);
				break;
			}
			case '5': {
				redirectPath = (
					<img
						className="page-nav-icon"
						src="/images/MDHC_Metrics_Benchmark.png"
						alt="Healthcare Map"
					/>
				);
				break;
			}
			case '6': {
				redirectPath = (
					<img
						className="page-nav-icon"
						src="/images/MDHC_Metrics_StrategicMarket.png"
						alt="Marketshare"
					/>
				);
				break;
			}
			case '7': {
				redirectPath = (
					<img
						className="page-nav-icon"
						src="/images/MDHC_Metrics_ChartTool.png"
						alt="Chart Library"
					/>
				);
				break;
			}
			default: {
				break;
			}
		}
		return redirectPath;
	}

	render() {
		const tabChanged = this.props.selectedTab !== this.state.selectedTab;
		const facilityInfo = misc.getFacilityInfo();
		const facilityData = facilityInfo
			? facilityInfo.facility_name + ', ' + facilityInfo.facility_state_abbr
			: '';
		const facilityID = facilityInfo ? facilityInfo.prvdr_num : '';
		const newPage = this.pageToRedirect(facilityID);
		const pageDescript = facilityInfo
			? this.pageDescription(facilityInfo)
			: this.pageDescriptionNoField();
		const geoCoordinates =
			facilityInfo && facilityInfo.latitude && facilityInfo.longitude ? (
				<meta
					name="geo.position"
					content={facilityInfo.latitude.toString() + ';' + facilityInfo.longitude.toString()}
				/>
			) : (
				''
			);

		if (tabChanged) return <Redirect to={newPage} push={true} />;
		else
			return (
				<section id="page_navigation_header" className="page-navbar">
					<Helmet>
						<title>{this.pageTitle(facilityData)}</title>
						<meta name="description" content={pageDescript} />
						{geoCoordinates}
					</Helmet>
					<nav id="pageNav">
						<div className="container">
							<div className="row">
								<ul className="navbar">
									<li
										className={
											this.state.selectedTab == 1
												? 'pl-2 nav-left nav-link page-navbar-link px-2 active'
												: 'pl-2 page-navbar-link nav-left nav-link px-2'
										}
										id="1"
										onClick={this.handleTabChange}
									>
										Summary
									</li>
									<li
										className={
											this.state.selectedTab == 2
												? 'nav-left nav-link page-navbar-link px-2 active'
												: ' page-navbar-link nav-left nav-link px-2'
										}
										id="2"
										onClick={this.handleTabChange}
									>
										Financials
									</li>
									<li
										className={
											this.state.selectedTab == 3
												? 'nav-left nav-link page-navbar-link px-2 active'
												: ' page-navbar-link nav-left nav-link px-2'
										}
										id="3"
										onClick={this.handleTabChange}
									>
										Quality
									</li>
									<li
										className={
											this.state.selectedTab == 4
												? 'nav-left nav-link page-navbar-link px-2 active'
												: ' page-navbar-link nav-left nav-link px-2'
										}
										id="4"
										onClick={this.handleTabChange}
									>
										Benchmarks
									</li>
									<li
										className={
											this.state.selectedTab == 5
												? 'nav-left nav-link page-navbar-link px-2 active'
												: ' page-navbar-link nav-left nav-link px-2'
										}
										id="5"
										onClick={this.handleTabChange}
									>
										Healthcare Map
									</li>
									<li
										className={
											this.state.selectedTab == 6
												? 'nav-left nav-link page-navbar-link px-2 active'
												: ' page-navbar-link nav-left nav-link px-2'
										}
										id="6"
										onClick={this.handleTabChange}
									>
										Marketshare
									</li>
									{/* <li
										className={
											this.state.selectedTab == 7
												? 'nav-left nav-link page-navbar-link px-2 active'
												: ' page-navbar-link nav-left nav-link px-2'
										}
										id="7"
										onClick={this.handleTabChange}
									>
										Physicians
									</li> */}
								</ul>
							</div>
						</div>
					</nav>
					{facilityInfo ? (
						<div className="navbar-submenu d-none d-md-block">
							<div className="container d-none d-md-flex">
								<div className="row facilityData">
									{facilityData}
									<span style={{ marginLeft: '10px', marginRight: '10px' }}>|</span>
									{facilityID}
									<PeerGroupTitle domain="hosp" />
									{/* <BenchmarkToolBar
										facilityName={
											facilityInfo && facilityInfo.facility_name
												? facilityInfo.facility_name
												: ''
										}
										facilityCoordinate={
											facilityInfo &&
											facilityInfo.facility_name &&
											facilityInfo.latitude &&
											facilityInfo.longitude
												? facilityInfo.latitude.toString() +
													',' +
													facilityInfo.longitude.toString()
												: ''
										}
									/> */}
								</div>
							</div>
						</div>
					) : (
						''
					)}
					<div className="navbar navbar-default bg-primary p-0 m-0 d-flex d-md-none" id="mobileSubNav">
						<button
							className="navbar-toggler border-0"
							style={{ width: '100%' }}
							data-toggle="collapse"
							data-target="#pagenavbarCollapse"
						>
							<div className="page-navbar-sm shadow-none text-center">
								{facilityInfo ? (
									<div className="navbar px-0 mx-0">
										<p className="text-white">
											{this.pageMenuIcon()}
											{facilityData}
										</p>
									</div>
								) : (
									' '
								)}
							</div>
						</button>
						<nav className="collapse navbar-collapse" id="pagenavbarCollapse">
							<ul className="nav navbar-nav mx-auto" style={{ width: '100%' }}>
								<li
									className={
										this.state.selectedTab == 1
											? 'nav-left nav-link page-navbar-link  active'
											: ' page-navbar-link nav-left nav-link'
									}
									id="1"
									onClick={this.handleTabChange}
									style={{ cursor: 'pointer' }}
								>
									<img className="page-nav-icon" src="/images/MDHC_Metrics_FacilityAnalyzer.png" />
									Summary
								</li>
								<li
									className={
										this.state.selectedTab == 2
											? 'nav-left nav-link page-navbar-link  active'
											: ' page-navbar-link nav-left nav-link'
									}
									id="2"
									onClick={this.handleTabChange}
									style={{ cursor: 'pointer' }}
								>
									<img className="page-nav-icon" src="/images/MDHC_Metrics_ChartTool.png" />
									Financials
								</li>
								<li
									className={
										this.state.selectedTab == 3
											? 'nav-left nav-link page-navbar-link  active'
											: ' page-navbar-link nav-left nav-link'
									}
									id="3"
									onClick={this.handleTabChange}
									style={{ cursor: 'pointer' }}
								>
									<img className="page-nav-icon" src="/images/MDHC_Metrics_QualityReport.png" />
									Quality
								</li>
								<li
									className={
										this.state.selectedTab == 4
											? 'nav-left nav-link page-navbar-link  active'
											: ' page-navbar-link nav-left nav-link'
									}
									id="4"
									onClick={this.handleTabChange}
									style={{ cursor: 'pointer' }}
								>
									<img className="page-nav-icon" src="/images/MDHC_Metrics_Benchmark.png" />
									Benchmarks
								</li>
								<li
									className={
										this.state.selectedTab == 5
											? 'nav-left nav-link page-navbar-link  active'
											: ' page-navbar-link nav-left nav-link'
									}
									id="5"
									onClick={this.handleTabChange}
									style={{ cursor: 'pointer' }}
								>
									<img className="page-nav-icon" src="/images/MDHC_Metrics_StrategicMarket.png" />
									Healthcare Map
								</li>
								<li
									className={
										this.state.selectedTab == 6
											? 'nav-left nav-link page-navbar-link  active'
											: ' page-navbar-link nav-left nav-link'
									}
									id="6"
									onClick={this.handleTabChange}
									style={{ cursor: 'pointer' }}
								>
									<img className="page-nav-icon" src="/images/MDHC_Metrics_StrategicMarket.png" />
									Marketshare
								</li>
								{/* <li
									className={
										this.state.selectedTab == 7
											? 'nav-left nav-link page-navbar-link  active'
											: ' page-navbar-link nav-left nav-link'
									}
									id="7"
									onClick={this.handleTabChange}
									style={{ cursor: 'pointer' }}
								>
									{' '}
									<img className="page-nav-icon" src="/images/MDHC_Metrics_ChartTool.png" />
									Physicians
								</li> */}
								<li className={'nav-link page-navbar-link active text-right mx-auto'}></li>
							</ul>
						</nav>
					</div>
				</section>
			);
	}
}

export default pageNavigation;
