import React, {Fragment} from 'react';
import Subscribe from '../../../components/navigation/subscribe';
import * as misc from '../../../../lib/misc';

export default domain => {
	let snfMetrics = (
		<Fragment>
			<ul className="list-unstyled">
				<li>
					Closest Hospital Facilities Key Metrics
				</li>
				<li>
					Top Referring Hospitals
				</li>
				<li>
					Competitor Key Metrics
				</li>
				<li>
					Medicare Patient Flows
				</li>
				<li>
					County Demographics
				</li>
				<li>
					Medicare Discharges To Skilled Nursing Facilities
				</li>
			</ul>
		</Fragment>
	);

	let hospMetrics = (
		<Fragment>
			<ul className="list-unstyled">
				<li>
					Inpatient and Outpatient Utilization
				</li>
				<li>
					Market share by zip code
				</li>
				<li>
					Key financial data points
				</li>
				<li>
					Operating statistics &amp; Quality measures
				</li>
			</ul>
		</Fragment>
	);

	let metrics = null;
	if (domain == 'info')
		metrics = (
			<div>
				<u>
					<h3>Hospital Metrics</h3>
				</u>
				{hospMetrics}
				<u>
					<h3>Skilled Nursing Facility (SNF) Metrics</h3>
				</u>
				{snfMetrics}
			</div>
		);
	else if (domain === 'snf') metrics = snfMetrics;
	else metrics = hospMetrics;

	return (
		<Fragment>
			<section id="content">
				<div className="container" id="landingPage">
					<div className="row">
						<div className="col-12">
							<h1>100+ Strategic Market Data Sets</h1>
						</div>
						<div className="col-sm-8 col-12">
							<p>Analyze market position within a facility’s primary and secondary service area demographics, market share, inpatient and outpatient data to spot trends in the patient populations and changes in market power.</p>
						</div>
						<Subscribe />
					</div>
				</div>
			</section>
			<section className="bgBlue">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2>Evaluate a facility’s market share relative to primary and secondary competition on measures, including:</h2>
						</div>
						<div className="col-md-6 col-12">
							<img src="/images/strategic_market_example.png" alt="Laptop displaying marketshare metrics" />
						</div>
						<div className="col-md-6 col-12">
							{metrics}
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
};
