import * as Types from '../actions/ActionTypes';
import BenchmarkUtil from '../../lib/BenchmarkUtil';

const defaultState = {
	BenchmarkData: new BenchmarkUtil(),
	fetching: false,
	fetched: false,
	error: null,
	whereChanged: false,
	dataFetchKey: null
};

export const BenchmarkReducer = (state = defaultState, action) => {
	switch (action.type) {
		case Types.BENCHMARK_DATA_GET + '_FETCH_PENDING': {
			return {
				...state,
				fetching: true,
				fetched: false,
				whereChanged: false,
				BenchmarkData: new BenchmarkUtil()
			};
		}
		case Types.BENCHMARK_DATA_GET + '_FETCH_FULFILLED': {
			const data = new BenchmarkUtil(action.payload.data.result);
			return { ...state, fetching: false, fetched: true, whereChanged: false, BenchmarkData: data };
		}
		case Types.BENCHMARK_DATA_GET + '_FETCH_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				whereChanged: false,
				error: action.payload
			};
		}
		case 'SESSION_STORAGE_WHERE_CHANGE':
		case 'USER_LOGIN_FETCH_FULFILLED':
		case Types.ASSIGNFACILITY_COMPLETE: {
			return {
				...state,
				whereChanged: true,
				fetching: false,
				fetched: false,
				BenchmarkData: new BenchmarkUtil()
			};
		}
		default:
			return { ...state };
	}
};

// export const DataGetterReducer = (state = defaultState, action) => {
// 	switch (action.type) {
// 		case Types.GOGODATA_GET + '_FETCH_PENDING': {
// 			return { ...state, fetching: true };
// 		}
// 		case Types.GOGODATA_GET + '_FETCH_FULFILLED': {
// 			return {
// 				...state,
// 				fetching: false,
// 				fetched: true,
// 				Data: action.payload.data.result
// 			};
// 		}
// 		case Types.GOGODATA_GET + '_FETCH_REJECTED': {
// 			return {
// 				...state,
// 				fetching: false,
// 				fetched: false,
// 				error: action.payload
// 			};
// 		}
// 		default:
// 			return { ...state };
// 	}
// };
