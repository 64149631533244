import React from 'react';
import numbro from 'numbro';
import Moment from 'moment';

export function formatDate(date) {
	let _dt = Moment(date);
	return _dt.format('L');
}

export function formatDollar(num, showDecimal) {
	if (num === undefined || num === null || num === '') {
		return '-';
	} else {
		let _num = parseFloat(num);
		if (!isNaN(_num)) {
			let showDec = _num % 1 === 0 ? false : true;

			_num = showDec ? _num : toInt(num);

			return numbro(_num).formatCurrency({
				thousandSeparated: true,
				negative: 'parenthesis',
				mantissa: showDecimal ? 2 : 0,
				currencyPosition: 'prefix'
			});
		} else {
			return num;
		}
	}
}

export function formatDollarText(num, showDecimal) {
	if (num === '') {
		return '-';
	} else {
		num = showDecimal ? parseFloat(num) : parseInt(num, 10);
		if (!isNaN(num)) {
			return numbro(num).format('$##,##0;($##,##0)');
		} else {
			return '-';
		}
	}
}

export function mathRound(num) {
	if (num !== null && num !== '') {
		return Math.round(num);
	} else {
		return '';
	}
}

export function formatComma(num) {
	if (num === '' || num === null) {
		return '';
	} else {
		return numbro(num).format('0,0');
	}
}

export function formatPercentage(val) {
	if (val !== null && val !== '') {
		if (val > 300) {
			return '+300%';
		} else if (val < -300) {
			return <span className="">(-300%)</span>;
		}
		return this.getPercent(val, 1);
	} else {
		return '-';
	}
}

export function getDollar(val) {
	try {
		if (val !== null && val !== '') {
			return numbro(val).formatCurrency({
				thousandSeparated: true,
				negative: 'parenthesis'
			});
		} else {
			return '-';
		}
	} catch (err) {
		return '-';
	}
}

export function getPercent(val, num_dec = 1) {
	if (!isNaN(val)) {
		if (val !== null && val !== '') {
			return numbro(val).format({
				output: 'percent',
				mantissa: num_dec,
				negative: 'parenthesis'
			});
		} else {
			return '-'; //Default format for blank data
		}
	} else return val;
}

export function getPercentNoShift(val) {
	try {
		if (val !== null && val !== '') {
			let per = numbro(val).format('0[.]0');
			return `${per}%`;
		} else {
			return '-'; //Default format for blank data
		}
	} catch (err) {
		return '-';
	}
}

export function getDecimal(val, showDecimal = null) {
	try {
		if (!isNaN(val)) {
			if (val !== null && val !== '') {
				return numbro(val).format({
					thousandSeparated: true,
					mantissa: showDecimal ? 2 : 0,
					negative: 'parenthesis'
				});
			} else {
				return '-';
			}
		} else return val;
	} catch (err) {
		return '-';
	}
}

export function getInt(val) {
	try {
		return getDecimal(val, false);
	} catch (err) {
		return '-';
	}
}
export function getReal(val) {
	try {
		if (val !== null && val !== '') {
			return val.toLocaleString(undefined, {
				minimumFractionDigits: 4,
				maximumFractionDigits: 4
			});
		} else {
			return '-'; //Default format for blank data
		}
	} catch (err) {
		return '-';
	}
}
export function toFloat(val) {
	try {
		if (val !== null && val !== '') {
			return parseFloat(val);
		} else {
			return null; //Default format for blank data
		}
	} catch (err) {
		return null;
	}
}
export function toInt(value) {
	return Math.round(toNumeric(value));
}
export function toNumeric(value) {
	let result = null;
	try {
		if (value === 'Not Available' || value === '--') result = null;
		else if (value) result = parseFloat(value.toString().replace(/\$|,|%/g, ''));
	} catch (err) {
		result = null;
	}
	return result;
}
export function toBool(value) {
	if (value !== undefined && value !== null && value !== 'Not Available' && value !== '--') {
		if (value === '1' || value.toLowerCase() === 'true') return true;
		else if (value === '0' || value.toLowerCase() === 'false') return false;
		else return null;
	}
	return null;
}

export function initCap(value) {
	if (value) {
		return value.toLowerCase().replace(/(?:^|\b)[a-z]/g, function(m) {
			return m.toUpperCase();
		});
	} else return '';
}
