import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
class TermsOfUsePage extends React.Component {
	render() {
		return (
			<div id="terms">
				<Helmet>
					<title>Terms of Use | HMP Metrics</title>
					<meta
						name="description"
						content="HMP Metrics terms and conditions of use agreement, outlining copyright, limitations of use, disclaimer of warranties and liability, user provided content and license, miscellaneous standards."
					/>
				</Helmet>
				<section>
					<div className="jumbotron text-center">
						<div className="container">
							<div className="row">
								<div className="col-12"></div>
							</div>
						</div>
					</div>
				</section>
				<section id="content">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h1 className="text-center">HMP Metrics - Terms of Use</h1>
								<h3>Terms and Conditions of Use Agreement</h3>
								<p>
									HMP Metrics, hmpmetrics.com and any newsletters, information, data, news alerts
									and all news, images, specifications, reviews, videos, and other content
									(collectively referred to as the "Service") is a data, analytical and news product
									of HMP Metrics, LLC. By using the Service, you agree to be bound by all of the
									terms in this Terms and Conditions of Use Agreement (the "Agreement"). If you do
									not fully agree with this Agreement, do not use the Service. Your failure to
									follow this Agreement may result in termination of your access to the Service,
									without notice and in addition to any other remedies available to HMP. Access to
									part of the Service may be restricted to users who have registered and paid for
									certain content ("Restricted Content"). If you have been issued a login ID and
									password in order to access our Restricted Content, you may not share your login
									ID and password with others. When you use your login ID and password to access our
									Restricted Content, you warrant that you are the authorized user and that you
									provided accurate information about yourself and your company when you signed up
									for the Restricted Content. HMP may change the terms in this Agreement at any
									time. When material portions of the terms are changed, HMP will notify you by
									posting a notice on the Service, and the changes will appear in this document. By
									using the Service after any change in the Agreement is posted, you agree to be
									bound by all of the changes as well.
								</p>
								<h3>Copyright&copy; and Limitations on Use</h3>
								<p>
									The information available through the Service is the property of HMP and others
									and is protected by copyright and other intellectual property laws. Information
									received through the Service may be displayed, reformatted and printed for your
									personal, noncommercial use only, provided you abide by this Agreement.
								</p>
								<p>Without the prior written consent of HMP, you agree not to:</p>
								<ul>
									<li>
										Store, post, create a database from or otherwise archive the articles, data,
										information, images, works or other materials from the Service (the "Content")
										on a personal computer, local or wide area networks, online services, electronic
										bulletin boards or forums, Internet sites or any other server that is
										Internet-enabled;
									</li>
									<li>
										Reproduce, retransmit, distribute, sell, publicly display, publish, post to
										newsgroups or electronic bulletin boards, or broadcast the Content received
										through the Service to anyone, including to others in the same company or
										organization;
									</li>
									<li>
										Use any Content in competition with the Service (whether or not such use would
										constitute a fair use under 17 U.S.C. § 107 or is substantially similar to the
										Content), including without limitation by using such Content as a principal
										source of a work distributed or displayed in connection with a commercial print-
										or web-based information source;
									</li>
									<li>
										Create abstracts from, scrape or display headlines from the Service for use on
										another web site or service; or Frame, or use other means of redirecting the
										Service Content.
									</li>
									<li>
										You may, on an occasional basis, include insubstantial portions of information
										from the Service in memoranda, reports and presentations, but only if such
										memoranda, reports and presentations are for non-competitive purpose, to a
										limited number of individuals. You must include in all such memoranda, reports
										and presentations the phrase "Reprinted with permission from HMP Metrics, LLC,
										Copyright 2020". You may also occasionally utilize the "share" and/or "email to
										a friend" (or similar) features on an article-by-article basis if such features
										are provided in the Service.
									</li>
									<li>
										Additionally, you agree to pay and authorize HMP to assess your licensing fees
										at HMP's standard reprint pricing for your use of the Content in violation of
										the Copyright and Limitations of Use restrictions above.
									</li>
								</ul>
								<h3>Disclaimer of Warranties and Liability</h3>
								<p>
									Due to the number of sources from which information on the Service is obtained,
									and the inherent hazards of electronic distribution, there may be delays,
									omissions or inaccuracies in such information and the Service. The Service may
									include facts, views, opinions and recommendations of individuals and
									organizations deemed of interest. HMP does not guarantee the accuracy,
									completeness or timeliness of, or otherwise endorse these views, opinions or
									recommendations.
								</p>
								<p>
									ALL MATERIALS, INFORMATION, SOFTWARE, PRODUCTS AND SERVICES INCLUDED IN OR
									AVAILABLE THROUGH THE SERVICE ARE PROVIDED "AS IS". HMP AND ITS AGENTS AND
									LICENSORS DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT,
									MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE NEWS AND INFORMATION
									AVAILABLE THROUGH THE SERVICE. NEITHER HMP, NOR ANY OF ITS AGENTS OR LICENSORS
									SHALL BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR INJURY CAUSED IN WHOLE OR
									PART BY ITS NEGLIGENCE OR CONTINGENCIES BEYOND ITS CONTROL IN PROCURING,
									COMPILING, INTERPRETING, REPORTING OR DELIVERING THE SERVICE AND ANY NEWS AND
									INFORMATION THROUGH THE SERVICE.
								</p>
								<p>
									IN NO EVENT WILL HMP, ITS AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR
									ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH INFORMATION OR FOR
									ANY CONSEQUENTIAL, SPECIAL OR SIMILAR DAMAGES, EVEN IF ADVISED OF THE POSSIBILITY
									OF SUCH DAMAGES. YOU AGREE THAT THE LIABILITY OF HMP, ITS AGENTS AND LICENSORS, IF
									ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM (WHETHER IN CONTRACT, TORT OR
									OTHERWISE) IN ANY WAY CONNECTED WITH THE SERVICE OR THE INFORMATION IN THE SERVICE
									SHALL NOT EXCEED THE AMOUNT, IF ANY, THAT YOU PAID TO HMP FOR USE OF THE SERVICE.
								</p>
								<h3>User-Provided Content and License</h3>
								<p>
									Certain portions of the Service may allow you to post or submit comments, reviews,
									ideas, or creative content ("User Content") for use by the Service. We may offer
									you ways to access and remove your User Content.nbsp; You retain all rights and
									responsibility for the User Content you post.
								</p>
								<p>
									By posting or submitting such User Content, you represent and warrant that you own
									or otherwise have sufficient rights to the User Content that you provide, that the
									User Content is accurate, that it does not violate this Agreement or any
									applicable law, and that it will not violate the rights of any person or entity.
								</p>
								<p>
									You further grant us a non-exclusive, royalty-free, perpetual, irrevocable license
									and right to publicly display, distribute, reproduce, modify, translate, edit,
									create derivative works and use the User Content throughout the world in any media
									solely as part of the features of the Service (including as an incidental part of
									advertising or marketing). This grant of rights and license applies to use of your
									name, photo, likeness, voice, and other personal information, if included in the
									User Content. If you remove the User Content, the Service will discontinue further
									use of the User Content but may retain copies for archival or backup purposes.
									Posting of User Content does not influence HMP's news coverage but HMP editors may
									comment on or post notable or interesting User Content and HMP reserves its fair
									use rights under applicable copyright laws to use User Content in HMP's news
									reporting operations.
								</p>
								<p>
									HMP reserves the right to delete or edit any of your comments or content at any
									time, for any reason, but has no obligation to review or remove any such comments
									or content. We further reserve the right to disclose any information about you,
									including registration data, in order to comply with any applicable laws and/or
									requests under legal process, to operate our systems properly, to protect our
									property or rights, and to safeguard the interests of others.You agree to not post
									material that:
								</p>
								<ul>
									<li>
										Contains vulgar, profane, abusive or hateful language, inflammatory attacks of a
										personal, racial or religious nature, or expressions of bigotry, racism,
										discrimination or hate;
									</li>
									<li>
										Is defamatory, threatening, false, misleading, fraudulent, unfair, contains
										gross exaggeration or unsubstantiated claims, or violates the privacy rights of
										any third party;
									</li>
									<li>
										Violates any municipal, state or federal law, rule, regulation or ordinance, or
										attempts to encourage such an evasion or violation;
									</li>
									<li>
										Unfairly interferes with the uninterrupted use and enjoyment of the Service;
									</li>
									<li>
										Advertises or offers to trade any goods or services, except in areas
										specifically designated for such purpose;
									</li>
									<li>
										Includes copyrighted or other proprietary material of any kind without the
										express permission of the owner of that material; or
									</li>
									<li>
										Contains or transmits viruses or other harmful, disruptive or destructive files.
									</li>
								</ul>
								<h3>Miscellaneous</h3>
								<p>
									Through your use of the Service, you may be provided with hyperlinks to other
									Internet sites or resources, including hyperlinks provided by third-party
									advertisers and sponsors to the Service. HMP has no control over those sites and
									resources, or over the advertising material presented by third-party advertisers
									and sponsors through the Service. You agree that HMP will not be responsible or
									liable, directly or indirectly, for any damage or loss caused or alleged to be
									caused by or in connection with use of or reliance on any such information, goods
									or services available on or through any such site or resource.
								</p>
								<p>
									HMP may discontinue or change the Service, or its availability to you, at any time
									without notice. This Agreement is personal to you, and you may not assign your
									rights or obligations to anyone. If any provision of this Agreement is invalid
									under applicable law, the remaining provisions will continue in full force and
									effect. The Privacy Policy and this Agreement are, together, the complete and
									entire agreement between you and HMP and supersedes any prior agreement, whether
									oral or written.
								</p>
								<p>
									This Agreement, all intellectual property issues, and your rights and obligations
									shall be governed by the laws of the United States of America and the State of
									Illinois governing contracts wholly entered into and wholly performed within
									Illinois and any action to enforce this Agreement or in any manner related to the
									Service shall be brought exclusively in the federal or state courts located in
									Chicago, Illinois, and you agree to submit to the jurisdiction of such courts. You
									agree to be responsible for any damages resulting from your breach of this
									Agreement and reimburse us for all of the costs, expenses and fees (including all
									attorney's fees) arising from or relating to your breach.
								</p>
								<p>
									If you believe that your work has been copied and is accessible through the
									Service in a way that constitutes copyright infringement, or that the Service
									contains a link or other reference to another online location that contains
									materials or activity that infringes on your copyright rights, you may notify HMP
									Metrics, LLC by providing the information required by the Online Copyright
									Infringement Liability Limitation Act of the Digital Millennium Copyright Act, 17
									U.S.C. &#167; 512 to the following address:
								</p>
								<ul className="list-unstyled">
									<li>HMP Metrics</li>
									<li>Attn: HMP Legal Counsel</li>
									<li>2454 McMullen Booth Rd., Ste 411</li>
									<li>Clearwater, FL 33759</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default class AboutUs extends React.Component {
	render() {
		return (
			<Fragment>
				<TermsOfUsePage />
			</Fragment>
		);
	}
}
