export const patientExperiences = [
	'hosp_rating_star',
	'nurse_comms_star',
	'nurse_comms',
	'doc_comms_star',
	'doc_comms',
	'receive_help_star',
	'receive_help',
	'meds_explained_star',
	'meds_explained',
	'clean_star',
	'bathroom_clean',
	'quiet_star',
	'quiet_room',
	'recovery_at_home_star',
	'recovery_at_home',
	'recovery_at_home_no',
	'understood_care_star',
	'understood_care',
	'understood_care_agree',
	'recomend_star',
	'recommend_hosp_prob',
	'recommend_hosp',
	'recommend_hosp_no',
	'hospital_rating',
	'hospital_rating_med',
	'hospital_rating_low'
];

export const SepsisCareFields = ['sep_1'];
export const CataractSurgeryOutcomeFields = ['op_31'];
export const ColonoscopyFields = ['op_29', 'op_30'];
export const HeartAttackFields = ['op_3b', 'op_5', 'op_2'];
export const EDthroughputFields = ['op_22', 'op_23', 'edv', 'ed_1b', 'ed_2b', 'op_18b', 'op_18c'];
export const PreventiveCareFields = ['imm_2', 'imm_3_fac_adhpct'];

export const BloodClotPreventiveCareFields = ['vte_6'];
export const PregnancyDeliveryCareFields = ['pc_01'];
export const CancerCareFields = ['op_33'];
export const MedicalImgFields = [
	'lumbar_score',
	'mammography_score',
	'abdomen_score',
	'thorax_score',
	'stress_score',
	'brain_score'
];

export const ValueBasedPurchasing = [
	'readmission_reduction_adjustment',
	'weighted_normalized_safety_score',
	'total_performance_score',
	'weighted_normalized_efficiency',
	'hvbp_payment_adjustment',
	'weighted_outcomes_domain_score',
	'weighted_patient_experience',
	'star_rating',
	'weighted_process_of_care'
];

export const ReadmissionPenaltyScorefields = [
	'copd_excess_ratio',
	'rate_of_readmission',
	'heart_attack_excess_ratio',
	'hip_knee_excess_ratio',
	'cabg_excess_ratio',
	'pneumonia_excess_ratio',
	'heart_failure_excess_ratio'
];
export const HospAcquiredConditionfields = [
	'cdi_score',
	'domain_2_score',
	'total_hac_score',
	'ahrq_psi_90_score',
	// 'yes_proportion', //TODO: REPLACE THIS FIELD
	'ssi_score',
	'clabsi_score',
	'mrsa_score',
	'domain_1_score',
	'cauti_score'
	// 'yes_payment_reduction_freq' //TODO: REPLACE THIS FIELD
];

export const Summary5StarFields = ['hospital_overall_rating', 'hosp_rating_star'];
export const SummaryMortalityFields = [
	'mortality_national_comparison',
	'mort_30_ami',
	'mort_30_cabg',
	'mort_30_copd',
	'mort_30_hf',
	'mort_30_pn',
	'mort_30_stk',
	'psi_4_surg_comp'
];
export const SummarySafetyCareFields = [
	'safety_of_care_national_comparison',
	'hai_1_measure_score',
	'hai_2_measure_score',
	'hai_3_measure_score',
	'hai_4_measure_score',
	'hai_5_measure_score',
	'hai_6_measure_score',
	'comp_hip_knee',
	'psi_90_safety'
];

export const SummaryReadmissionFields = [
	'readmission_national_comparison',
	'readm_30_cabg',
	'readm_30_copd',
	'readm_30_hip_knee',
	'edac_30_ami',
	'edac_30_hf',
	'edac_30_pn',
	'op_32'
];
export const SummaryPatientExperienceFields = [
	'patient_experience_national_comparison',
	'clean_star',
	'nurse_comms_star',
	'doc_comms_star',
	'receive_help_star',
	'meds_explained_star',
	'recommend_hosp',
	'hosp_rating_star',
	'quiet_star',
	'recomend_star',
	'understood_care_star'
];
export const SummaryEffectivenessCareFields = [
	'effectiveness_of_care_national_comparison',
	'imm_2',
	'imm_3',
	'op_22',
	'op_23',
	'op_29',
	'op_30',
	'op_33',
	'pc_01',
	'sep_1',
	'vte_6'
];
export const SummaryTimelinessCareFields = [
	'timeliness_of_care_national_comparison',
	'ed_1b',
	'ed_2b',
	'op_2',
	'op_3b',
	'op_5',
	'op_18b'
];
export const SummaryEfficientMedicalImagingFields = [
	'efficient_use_of_medical_imaging_national_comparison'
	// 'op_8',
	// 'op_10',
	// 'op_11',
	// 'op_13',
	// 'op_14'
];

export const SummaryMeasureGroupsFields = [
	'mortality_national_comparison',
	'safety_of_care_national_comparison',
	'readmission_national_comparison',
	'patient_experience_national_comparison',
	'effectiveness_of_care_national_comparison',
	'timeliness_of_care_national_comparison',
	'efficient_use_of_medical_imaging_national_comparison'
];
export const HospSummaryFields = [
	'emergency_services',
	'meets_criteria_for_meaningful_use_of_ehrs'
];

export const SummaryFields = () => {
	let list = [];
	list = list.concat(Summary5StarFields);
	list = list.concat(HospSummaryFields);
	list = list.concat(SummaryMeasureGroupsFields);
	list = list.concat(SummaryMortalityFields);
	list = list.concat(SummarySafetyCareFields);
	list = list.concat(SummaryReadmissionFields);
	list = list.concat(SummaryPatientExperienceFields);
	list = list.concat(SummaryTimelinessCareFields);
	list = list.concat(SummaryEfficientMedicalImagingFields);
	list = list.concat(SummaryEffectivenessCareFields);

	return list;
};

export const MedicalConditionFields = [
	'readm_30_copd',
	'readm_30_ami',
	'edac_30_ami',
	'readm_30_hf',
	'edac_30_hf',
	'readm_30_pn',
	'edac_30_pn'
];

export const VisitsByProcedureFields = ['readm_30_cabg', 'readm_30_hip_knee', 'op_32'];
export const UnplannedReadmissionFields = ['rate_of_readmission'];

export const UnplannedHospitalVisitsFields = () => {
	let list = [];
	list = list.concat(MedicalConditionFields);
	list = list.concat(VisitsByProcedureFields);
	list = list.concat(UnplannedReadmissionFields);
	return list;
};

export const ValueOfCareFields = [
	'mort_30_ami_compared',
	'paym_30_ami_compared',
	'mort_30_hf_compared',
	'paym_30_hf_compared',
	'mort_30_pn_compared',
	'paym_30_pn_compared',
	'comp_hip_knee_compared',
	'paym_90_hip_knee_compared'
];

export const MortalityFields = [
	'mort_30_copd',
	'mort_30_ami',
	'mort_30_hf',
	'mort_30_stk',
	'mort_30_pn',
	'mort_30_cabg'
];

export const SurgicalFields = [
	'comp_hip_knee',
	'psi_90_safety',
	'psi_4_surg_comp',
	'psi_3_ulcer',
	'psi_6_iat_ptx',
	'psi_8_post_hip',
	'psi_9_post_hem',
	'psi_10_post_kidney',
	'psi_11_post_resp',
	'psi_12_postop_pulmemb_dvt',
	'psi_13_post_sepsis',
	'psi_14_postop_dehis',
	'psi_15_acc_lac'
];

export const PsycCareScreenFields = ['smd_per', 'sub_1_per', 'tob_1_per', 'imm_2_per', 'hcp_per'];
export const PsycSubTreatmentFields = [
	'sub_2_per',
	'sub_2a_per',
	'sub_3_per',
	'sub_3a_per',
	'tob_2_per',
	'tob_2a_per',
	'tob_3_per',
	'tob_3a_per'
];
export const PsycPatientSafetyFields = ['hours_of_physical_restraint_use', 'hours_of_seclusion'];
export const PsycPatientExpCareFields = ['ass_patient_experience_care'];
export const PsycFacTechFields = ['ehr_use_response', 'ehr_hisp_response'];
export const PsycFollowCareFields = [
	'tr_1_per',
	'tr_2_per',
	'hbips_5_per',
	'fuh_30_per',
	'fuh_7_per'
];
export const PsycUnplannedReadFields = ['readm_30_ipf_rate'];

export const PsychiatricUnitServicesFields = () => {
	let list = [];
	list = list.concat(PsycCareScreenFields);
	list = list.concat(PsycSubTreatmentFields);
	list = list.concat(PsycPatientSafetyFields);
	list = list.concat(PsycPatientExpCareFields);
	list = list.concat(PsycFacTechFields);
	list = list.concat(PsycFollowCareFields);
	list = list.concat(PsycUnplannedReadFields);
	return list;
};

export const ReadmissionCompFields = () => {
	let list = [];
	list = list.concat(UnplannedHospitalVisitsFields());
	list = list.concat(MortalityFields);
	list = list.concat(SurgicalFields);
	return list;
};

export const TimelyEffectiveCareFields = () => {
	let list = [];
	list = list.concat(SepsisCareFields);
	list = list.concat(CataractSurgeryOutcomeFields);
	list = list.concat(ColonoscopyFields);
	list = list.concat(HeartAttackFields);
	list = list.concat(EDthroughputFields);
	list = list.concat(PreventiveCareFields);
	list = list.concat(BloodClotPreventiveCareFields);
	list = list.concat(PregnancyDeliveryCareFields);
	list = list.concat(CancerCareFields);
	list = list.concat(MedicalImgFields);
	return list;
};

export const MedicalImagingFields = [
	'stress_score',
	'lumbar_score',
	'mammography_score',
	'abdomen_score',
	'thorax_score',
	'brain_score'
];

export const IPPsychFields = [
	'hours_of_seclusion',
	'post_discharge_cont_care_plan_created',
	'influenza_immunization',
	'healthcare_personnel_influenza_vac',
	//'post_discharge_cont_care_plan_transmitted',
	'percent_patients_rec_follow_up_care_7_days_hosp_mental_ill',
	'ass_patient_experience_care',
	'hours_of_physical_restraint_use',
	'patients_discharged_multiple_antipsychotic_with_just',
	'percent_patients_rec_follow_up_care_30_days_hosp_mental_ill'
];

export const HealthAssocInfectFields = [
	'clabsi_events',
	'cauti_events',
	'ssi_colon_events',
	'ssi_hyster_events',
	'mrsa_events',
	'cdiff_events'
];

export const ClinicalEpisodeBasedPaymentFields = [
	'cebp_aortic_aneurysm',
	'cebp_cellulitis',
	'cebp_chole_cde',
	'cebp_gi',
	'cebp_kidney_uti',
	'cebp_spinal_fusion'
];
export const MedicareSpendingBeneficiaryFields = ['mspb_1'];
export const PaymentMeasuresFields = [
	'paym_30_ami',
	'paym_30_hf',
	'paym_30_pn',
	'paym_90_hip_knee'
];

export const PaymentValueCareFields = () => {
	let list = [];
	list = list.concat(ClinicalEpisodeBasedPaymentFields);
	list = list.concat(MedicareSpendingBeneficiaryFields);
	list = list.concat(PaymentMeasuresFields);
	list = list.concat(ValueOfCareFields);
	return list;
};

export const All = () => {
	let list = [];
	// list = list.concat(IPPsychFields);
	list = list.concat(HealthAssocInfectFields);
	list = list.concat(MedicalImagingFields);
	list = list.concat(TimelyEffectiveCareFields());
	list = list.concat(ReadmissionCompFields());
	list = list.concat(patientExperiences);
	list = list.concat(SummaryFields());
	list = list.concat(PsychiatricUnitServicesFields());
	list = list.concat(PaymentValueCareFields());
	return list;
};

export const Info = () => {
	return {
		topic: 'Quality Metrics',
		groups: [
			{
				key: 'qualSummary',
				label: 'Quality Summary',
				fields: SummaryFields()
			},
			{
				key: 'qualReadmission',
				label: 'Readmission & Mortality',
				fields: ReadmissionCompFields()
			},
			{
				key: 'qualTimely',
				label: 'Timely & Effective Care',
				fields: TimelyEffectiveCareFields()
			},
			{
				key: 'qualInfections',
				label: 'Hospital Associated Infections',
				fields: HealthAssocInfectFields
			},
			{
				key: 'qualMedImaging',
				label: 'Medical Imaging',
				fields: MedicalImagingFields
			}
		]
	};
};
