import React, {Fragment} from 'react';
import Subscribe from '../../core/components/navigation/subscribe';
import { hmpMetricsHome } from '../../lib/misc';

export default class HospMarketInfo extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		document.body.id = 'landingPage';
		return (
			<Fragment>
				<section id="content">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h1>100+ Strategic Market Data Sets</h1>
							</div>
							<div className="col-sm-8 col-12">
								<p>Analyze market position within a facility’s primary and secondary service area demographics, market share, inpatient and outpatient data to spot trends in the patient populations and changes in market power.</p>
							</div>
							<Subscribe />
						</div>
					</div>
				</section>
				<section className="bgBlue">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h2>Evaluate a facility’s market share relative to primary and secondary competition on measures, including:</h2>
							</div>
							<div className="col-md-6 col-12">
								<img src="/images/strategic_market_example.png" alt="" />
							</div>
							<div className="col-md-6 col-12">
								<ul className="list-unstyled">
									<li>
										Inpatient and Outpatient Utilization
									</li>
									<li>
										Market share by zip code
									</li>
									<li>
										Key financial data points
									</li>
									<li>
										Operating statistics &amp; Quality measures
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			</Fragment>
		);
	}
};
