import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Auth from '../../Security/Auth';
//import './footer.css';

class FooterSection extends React.Component {
	constructor() {
		super();
		this.clickshareEnv = Auth.AuthTarget;
	}
	render() {
		let targetURL = this.clickshareEnv + Auth.GetAuthURL(document.URL);
		return (
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-sm-4 col-12 icons text-left">
							<a
								href="https://www.hud.gov/program_offices/fair_housing_equal_opp"
								title="FHEO Home | HUD.gov"
								target="_blank"
								rel="noreferrer"
							></a>
							<a
								href="https://www.hud.gov/program_offices/fair_housing_equal_opp/disabilities/accessibilityR"
								title="Accessibility Requirements for Buildings | HUD.gov"
								target="_blank"
								rel="noreferrer"
							></a>
							<a
								href="pdf/HMP-Metrics-Privacy-Policy.pdf"
								title="Privacy Policy"
								target="_blank"
								rel="noopener"
							></a>
						</div>
						<div className="col-sm-4 col-12 text-center">
							<p>
								<a href="/">HMP Metrics</a> © <span id="year">{new Date().getFullYear()}</span>. All
								rights reserved.
							</p>
						</div>
						<div className="col-sm-4 col-12 text-right">
							<p>
								Powered by:{' '}
								<a
									href="/"
									title="HMP Metrics"
									target="_blank"
									rel="noopener"
									id="hmpMetrics"
								>
									HMP{' '}
									<span>
										<i></i>
									</span>{' '}
									Metrics
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default FooterSection;
