import React from 'react';

class loadingSpinner extends React.Component {
	render() {
		return (
			<div className="loading col-12">
				<div className="loadIcon" role="status">
					<img src="./images/loading.gif" alt="Data Loading" />
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}
}

export default loadingSpinner;
