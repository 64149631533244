import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { snfMarketshare } from '../../Common/SkilledNursingActions';
import * as MHGrid from '../../../tools/datagrid';
import LoadingSpinner from '../../../tools/loadingSpinner';
import NoData from '../../../core/components/notifications/NoData';
import * as misc from '../../../lib/misc';

const mapStateToProps = store => {
	return {
		refHosp: store.snfSMDMarketshareData.refHosp,
		fetched: store.snfSMDMarketshareData.fetched,
		fetching: store.snfSMDMarketshareData.fetching,
		mktShare: store.snfSMDMarketshareData.mktShare
	};
};
const mapDispatchToProps = dispatch => {
	return {
		GetMarketshareData: snfProvider => dispatch(snfMarketshare(snfProvider, 'snf'))
	};
};

class SnfMarketshare extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			facilityId: this.props.facilityId
		};
		this.setState(this.state);
	}

	componentDidMount() {
		this.InitializeData();
	}

	InitializeData() {
		if (!this.props.fetching && !this.props.fetched) {
			this.props.GetMarketshareData(this.props.facilityId);
		}
	}

	//'prvdr_num','inpatient_total_days','home_health_agency_ip_days_visits_total','hospice_ip_days_visits_total',	'icf_iid_ip_days_visits_total','nursing_facility_ip_days_visits_total',	'other_longtermcare_ip_days_visits_total','total_ip_days_visits_total'
	GetColumns(fy_year) {
		let SummaryCols = new MHGrid.DataGridColumns(
			new MHGrid.DataGridColumn(
				'prvdr_num',
				MHGrid.DataGridHeaderBuilder('Provider', 'center', 50),
				50,
				MHGrid.ColType_Text
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'facility_name',
				MHGrid.DataGridHeaderBuilder('Facility Name', 'center', 300),
				300,
				MHGrid.ColType_Text
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'period_end',
				MHGrid.DataGridHeaderBuilder('Fiscal End', 'center', 100),
				100,
				MHGrid.ColType_Text
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'inpatient_total_days',
				MHGrid.DataGridHeaderBuilder('Skilled Nursing Days', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'nursing_facility_ip_days_visits_total',
				MHGrid.DataGridHeaderBuilder('Nursing Facility Days', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'icf_iid_ip_days_visits_total',
				MHGrid.DataGridHeaderBuilder('Long-Term Care Days', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'home_health_agency_ip_days_visits_total',
				MHGrid.DataGridHeaderBuilder('Home Health Agency Visits', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'other_longtermcare_ip_days_visits_total',
				MHGrid.DataGridHeaderBuilder('Nursing Facility Days', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);
		SummaryCols.Add(
			new MHGrid.DataGridColumn(
				'total_ip_days_visits_total',
				MHGrid.DataGridHeaderBuilder('Total Patient Days', 'center', 100),
				100,
				MHGrid.ColType_Number
			)
		);

		return SummaryCols;
	}

	render() {
		let facility = misc.getFacilityInfo();

		let clmns =
			this.props.fetched && this.props.mktShare
				? this.GetColumns() //_.max(this.props.refHosp.rows.map(a => a.max_fy))
				: null;
		let data = this.props.fetched && this.props.mktShare ? this.props.mktShare.rows : [];

		return (
			<div>
				<div className="d-flex">
					<h4 class="tblHead">
						{'Largest Skilled Nursing Competitors of ' + facility.facility_name || 'Facility'}
						<i
							className="ml-2 MH-InfoTooltip py-auto mt-auto pb-1 mb-2 fa fa-xs fa-info-circle mr-auto"
							aria-hidden="true"
							data-toggle="popover"
							data-trigger="hover"
							data-content={
								"Competitors are determined by the share / overlap a facility's core Medicare service area (County), and distance from the facility of interest."
							}
						/>
					</h4>
				</div>
				<MHGrid.DataGrid suppressLines={true} columns={clmns} rows={data} showHeaders={true} />
				{this.props.fetched && data.length < 1 ? (
					<NoData />
				) : this.props.fetching ? (
					<LoadingSpinner />
				) : (
					''
				)}
			</div>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SnfMarketshare);
