import React from 'react';
import { Helmet } from 'react-helmet';
import * as GenActions from '../actions/GlobalActions';
import { connect } from 'react-redux';
import LoadingSpinner from '../../tools/loadingSpinner';

const mapDispatchToProps = dispatch => {
	return {
		LoadWhitePapers: () => dispatch(GenActions.whitePaperList())
	};
};

const mapStateToProps = store => {
	return {
		whitePapers: store.whitePapers
	};
};

const whitePaper = rec => {
	let paper = '/blogs/' + rec.filename,
		image = 'images/blogs/' + rec.thumbnail,
		posted_dt = new Date(rec.date_posted);
		/* winH = window.innerHeight,
		bodyH = document.getElementById('root').clientHeight;
	if(winH > bodyH) {
		let diffH = winH - bodyH;
		document.getElementById('content').style.paddingBottom = diffH+'px';
	} */
	return (
		<div className="col-sm-6 col-12">
			<a className="blogLink" href={paper} target="_self">
				<div className="blogsTile">
					<div className="blogHeader">
						<img src={image} alt="Placeholder" />
					</div>
					<div className="blogBody">
						<h4>{rec.name}</h4>
						<p>{rec.description}</p>
						<p className="author">Author: <span>{rec.author}</span></p>
					</div>
					<div className="blogFooter">
						<span><i class="fas fa-book-reader"></i> Read More</span>
						<p className="pubDate">{posted_dt.toLocaleDateString('en-US')}</p>
					</div>
				</div>
			</a>
		</div>
	);
};

const body = papers => {
	let paperList = [];

	papers.forEach(p => {
		paperList.push(whitePaper(p));
	});
	document.body.id = 'blogs';
	return (
		<>
			<Helmet>
				<title>Blogs | HMP Metrics</title>
				<meta
					name='description' 
					content=''
				/>
			</Helmet>
			<section id="content">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<h1>Blogs</h1>
						</div>
					</div>
					<div className="row">
						{paperList}
					</div>
				</div>
			</section>
		</>
	);
};

const WhitePapers = class WhitePapers extends React.Component {
	componentDidMount() {
		this.props.LoadWhitePapers();
	}

	render() {
		return this.props.whitePapers.fetched ? body(this.props.whitePapers.data) : <LoadingSpinner />;
	}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WhitePapers);
