import React from 'react';
import * as css from './DataGrid.css';
import * as misc from '../../../lib/misc';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
	faSquareFull,
	faQuestionCircle,
	faChevronRight
} from '@fortawesome/fontawesome-free-solid';
import { Modal } from 'react-bootstrap';

class DataGridQuartile extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			domain: this.props.domain || 'hosp'
		};
		this.OpenClick = this.OpenClick.bind(this);
		this.CloseClick = this.CloseClick.bind(this);
	}

	OpenClick() {
		let _state = this.state || {};
		_state.showModal = true;
		this.setState(_state);
	}

	CloseClick() {
		let _state = this.state || {};
		_state.showModal = false;
		this.setState(_state);
	}

	quartileDefinition() {
		let peerGroupDefs =
			this.props.domain == 'snf' ? this.SkilledProviderText() : this.HospProviderText();

		return (
			<div>
				<h5>Quartiles:</h5>
				<div className="row">
					<div className="col-md-6 col-sm-12 small">
						Every facility is compared against like facilities in the national benchmark. Quartiles
						are calculated by identifying where the facility of interest ranks compared to the
						distribution of its respective peers.
					</div>
					<div className="col-md-6 d-flex col-sm-none">
						<img
							src="/images/quartile_example.png"
							alt=""
							style={{ maxWidth: '270px', maxHeight: '110px' }}
						/>
					</div>
				</div>
				<hr />
				<div className="row my-3">
					<div className="col small">
						All peer-groups values are checked for outlier’s as occasionally aberrant data will be
						reported by a facility (for example reporting employee hours instead of employee
						head-count). Values are flagged as outliers when their value falls outside the outlier
						threshold (1.5 IQR) or other metric specific business rules (for example Days in AR >
						365).
					</div>
				</div>
				<h5>Benchmarks:</h5>
				<div className="row">
					<div className="col small">{peerGroupDefs}</div>
				</div>
			</div>
		);
	}

	HospProviderText() {
		return (
			<div className="quartModal">
				<p><i>National benchmarks are selected based on key factors for the facility of interest.</i></p>
				<hr />
				<p><strong>Default National Peer-Groups:</strong></p>
				<ul>
					<li>
						<strong>Short-Term Acute: </strong>Any Short-Term Acute Hospital (excluding Critical
						Access Hospitals), with acute care beds representing more than 25% of the facility’s beds.
					</li>
					<li>
						<strong>Short-Term High Subacute:</strong> Any Short-Term Acute care Hospital (excluding
						Critical Access Hospitals), with acute care beds representing less than 25% of the
						facility’s beds.
					</li>
					<li>
						<strong>Critical Access Hospital:</strong> Any Short-Term Acute Critical Access Hospital
						with acute care beds representing more than 25% of the facility’s available beds.
					</li>
					<li>
						<strong>Critical Access Hospital High Subacute:</strong> Any Short-Term Acute Critical
						Access Hospital with acute care beds representing less than 25% of the facility’s
						available beds.
					</li>
					<li>
						<strong>Psychiatric Hospital:</strong> Any hospital registered as a Psychiatric Hospital
						with acute care beds representing more than 25% of the facility’s available beds.
					</li>
					<li>
						<strong>Psychiatric Hospital High Subacute:</strong> Any hospital registered as a
						Psychiatric Hospital with acute care beds representing fewer than 25% of the facility’s
						available beds.
					</li>
					<li>
						<strong>Long-Term Hospital:</strong> Any specialized hospital registered as a Long-Term
						Care Hospital
					</li>
					<li>
						<strong>Acute Rehabilitation Hospital:</strong> Any specialized hospital registered as a
						Rehabilitation Hospital
					</li>
					<li>
						<strong>Children’s Hospital:</strong> Any specialized hospital registered as a Children’s
						Hospital.
					</li>
					<li>
						<strong>Cancer Hospital:</strong> Any specialized hospital registered as a Cancer Hospital
					</li>
					<li>
						<strong>Other / Non-Medical Hospitals:</strong> Any Religious Non-Medical or facility not
						captured by the above peer-groups.{' '}
					</li>
				</ul>
			</div>
		);
	}

	SkilledProviderText() {
		return (
			<div className="quartModal">
				<p><i>National benchmarks are selected based on key factors for the facility of interest.</i></p>
				<hr />
				<p><strong>Default National Peer-Group Factors:</strong></p>
				<ul>
					<li>
						<strong>Urban / Rural: </strong>Free-Standing Skilled Nursing Facilities are split into
						Urban / Rural Peer-Groups.
					</li>
					<li>
						<strong>Bed-Size: </strong>Facilities are split into three peer-groups based on size.
						<br />
						<FontAwesomeIcon icon={faChevronRight} className={'ml-4 mr-1 fa-xs'} />1 - 60 Beds
						<br />
						<FontAwesomeIcon icon={faChevronRight} className={'ml-4 mr-1 fa-xs'} />
						61 - 150 Beds
						<br />
						<FontAwesomeIcon icon={faChevronRight} className={'ml-4 mr-1 fa-xs'} />
						150+ Beds
						<br />
					</li>
					<li>
						<strong>Medicare Mix:</strong> Medicare Mix - or the percentage of Patient Days that are
						Medicare Patients is based off of CMS Cost Report Worksheet S3, Part I Column 4 Line 8 and
						Worksheet S3, Part I Column 7 Line 8
					</li>
				</ul>
			</div>
		);
	}

	render() {
		let modalKey = misc.Guid();
		return (
			<div className="quartile">
				<div className=" row mx-auto my-0 py-0 d-none d-md-flex strong">
					<div className="col-sm col-md-2">
						<FontAwesomeIcon icon={faSquareFull} size="lg" className="mh-theme-Q1" /> 1<sup>st</sup>{' '}
						Quartile
					</div>
					<div className="col-sm col-md-10">
						<FontAwesomeIcon icon={faSquareFull} size="lg" className="mh-theme-Q4" /> 4<sup>th</sup>{' '}
						Quartile
						<div className="pull-right">
							<FontAwesomeIcon
								icon={faQuestionCircle}
								size="lg"
								data-toggle="modal"
								data-target={'#' + modalKey}
							/>
						</div>
					</div>
					{this.props.children}
				</div>
				<div className=" mx-auto my-0 py-0 d-none d-sm-flex d-md-none row">
					<div className="col">
						<FontAwesomeIcon icon={faSquareFull} size="lg" className="mh-theme-Q1" /> 1<sup>st</sup>
					</div>
					<div className="col">
						<FontAwesomeIcon icon={faSquareFull} size="lg" className="mh-theme-Q2" /> 2<sup>nd</sup>
					</div>
					<div className="col">
						<FontAwesomeIcon icon={faSquareFull} size="lg" className="mh-theme-Q3" /> 3<sup>rd</sup>
					</div>
					<div className="col">
						<FontAwesomeIcon icon={faSquareFull} size="lg" className="mh-theme-Q4" /> 4<sup>th</sup>
					</div>
					<div className="col">
						<FontAwesomeIcon
							icon={faQuestionCircle}
							size="lg"
							data-toggle="modal"
							data-target={'#' + modalKey}
						/>
					</div>
					{this.props.children}
				</div>
				<br />
				<div className="modal" id={modalKey}>
					<div className="modal-dialog-centered mx-auto col-md-6">
						<div className="modal-content bg-primary">
							<div className="modal-header py-2 bg-primary text-white border-0 rounded-0">
								<h4 className="modal-title pl-1 py-0">Quartile Methods and Peer-Group Definitions</h4>
								<button type="button" className="close" data-dismiss="modal">
									&times;
								</button>
							</div>
							<div className="modal-body bg-white">
								<div className="m-3 text">{this.quartileDefinition()}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default DataGridQuartile;
