import React from 'react';
import { Panel, Button } from 'react-bootstrap';
import * as MHGrid from '../datagrid';
import ReChartTool from './ReChartTool';
import JsonUtil from '../../lib/JsonUtil';
import LoadingSpinner from '../../tools/loadingSpinner';
import * as quartileFormat from '../../core/styles/CustomStyle';
import { exportDomToImage } from '../../lib/ExportUtil';
import * as misc from '../../lib/misc';
import _ from 'lodash';
class QuartileChart extends React.Component {
	constructor(props) {
		super(props);

		this.onExport = this.onExport.bind(this);
	}

	BuildPivotGrid(headers, data, facilityInfo) {
		let Cols = new MHGrid.DataGridColumns();
		if (data) {
			for (let i = 0; i < headers.length; i++) {
				Cols.Add(
					new MHGrid.DataGridColumn(
						String(headers[i]),
						new MHGrid.DataGridHeader(headers[i], '', '', 'center'),
						i == 0 ? '250' : '',
						MHGrid.ColType_Self
					)
				);
			}

			let facilityID = misc.getFOIandCompetitors();

			data = data.map(a => {
				let b = a;

				let key = _.find(facilityID, c => c.id || c.prvdr_num === a['Facility'].value);
				if (key) {
					b['Facility'].value = key.name || key.facility_name;
				}
				return b;
			});
		}
		return <MHGrid.DataGrid height="" columns={Cols} rows={data ? data : []} />;
	}

	PivotGridData(pivotData) {
		let pivotTable = [];
		let facilityID = misc.getFOIandCompetitors();
		let defaultSeries = [];
		let header = [];
		for (let i = 1; i < pivotData.length; i++) {
			let record = {};
			header = pivotData[0];
			header = header.map(a => {
				let b = a;
				let key = _.find(facilityID, c => c.id || c.prvdr_num === b);

				if (key) {
					b = key.name || key.facility_name;
				}
				return b;
			});
			let t = 0;
			for (let j = 0; j < pivotData[i].length; j++) {
				let value = pivotData[i][j];
				if (header[j] !== 'Year') {
					if (
						String(header[j])
							.toLowerCase()
							.indexOf('quartile') > -1
					) {
						defaultSeries[t] = {
							xAxis: 'Year',
							xLabel: '',
							yLabel: '',
							type: 'ThickLine',
							name: header[j]
						};
						if (
							String(header[j])
								.toLowerCase()
								.indexOf('quartile 1') > -1
						) {
							defaultSeries[t].color = quartileFormat.Quartile1;
							defaultSeries[t].yLabel = 'Q1';
						}
						if (
							String(header[j])
								.toLowerCase()
								.indexOf('quartile 2') > -1
						) {
							defaultSeries[t].color = quartileFormat.Quartile2;
							defaultSeries[t].yLabel = 'Q2';
						}
						if (
							String(header[j])
								.toLowerCase()
								.indexOf('quartile 3') > -1
						) {
							defaultSeries[t].color = quartileFormat.Quartile3;
							defaultSeries[t].yLabel = 'Q3';
						}
						if (
							String(header[j])
								.toLowerCase()
								.indexOf('quartile 4') > -1
						) {
							defaultSeries[t].color = quartileFormat.Quartile4;
							defaultSeries[t].yLabel = 'Q4';
						}
					} else {
						defaultSeries[t] = {
							xAxis: 'Year',
							xLabel: '',
							yLabel: '',
							type: 'OutlineBar',
							name: header[j],
							color: quartileFormat.SeriesArray[t]
						};
					}
					t++;
				}
				record[header[j]] = value;
			}
			pivotTable[i - 1] = record;
		}
		defaultSeries = _.filter(defaultSeries, e => e);

		return { table: pivotTable, series: defaultSeries };
	}

	TransposeData(dataResult, fieldNameQuartile) {
		let gridIt = JsonUtil.getPivotArray(
			'Facility',
			dataResult,
			'hospital_name',
			'fy_year',
			fieldNameQuartile
		);
		let pivotGrid = [];
		let pivotHeader = gridIt[0];

		for (let i = 1; i < gridIt.length; i++) {
			let record = {};
			for (let j = 0; j < gridIt[i].length; j++) {
				let value = gridIt[i][j];
				record[pivotHeader[j]] = {};
				record[pivotHeader[j]].value = value;
				record[pivotHeader[j]].format = j === 0 ? 'TEXT' : this.props.FieldDef.FieldFormat;
			}
			pivotGrid[i] = record;
		}
		return { grid: pivotGrid, header: pivotHeader };
	}

	onExport() {
		exportDomToImage(
			'quartileChartPanel' + this.props.FieldDef.FieldId,
			this.props.FieldDef.FieldShortName
		);
	}

	render() {
		const fieldNameQuartile = this.props.FieldDef ? this.props.FieldDef.FieldName + '_avg' : '';

		// const fieldRankAsc = this.props.FieldDef.FieldRankOrder;
		// const fieldDescription = this.props.FieldDef.FieldDescription;
		const fieldTitle = this.props.FieldDef ? this.props.FieldDef.FieldShortName : '';
		const dataResult = this.props.ChartData ? this.props.ChartData.rows : [];
		const pivotData = JsonUtil.getPivotArray(
			'Year',
			dataResult,
			'fy_year',
			'hospital_name',
			fieldNameQuartile
		);

		let pGrid = this.PivotGridData(pivotData);
		let tdata = this.TransposeData(dataResult, fieldNameQuartile);
		const dataGrid = this.BuildPivotGrid(tdata.header, tdata.grid);
		const chartGraph = this.props.ChartData ? (
			<ReChartTool series={pGrid.series} data={pGrid.table} height={250} />
		) : (
			''
		);
		const report = this.props.Fetching ? (
			<LoadingSpinner />
		) : (
			<div>
				{chartGraph}
				{dataGrid}
			</div>
		);
		return this.props.FieldDef ? (
			<div>
				{/* <div style={{ position: 'absolute', top: '5px', right: '20px' }}>
					<Button bsSize="xsmall" onClick={this.onExport}>
						<i className="fa fa-line-chart" aria-hidden="true" />
					</Button>
				</div> */}
				<div>
					<Panel id={'quartileChartPanel' + this.props.FieldDef.FieldId}>
						<h6>{fieldTitle}</h6>
						<div
							style={{
								alignItems: 'center',
								lineHeight: '1.5em'
							}}
						>
							{report}
						</div>
					</Panel>
				</div>
			</div>
		) : (
			'No Data Returned'
		);
	}
}

export default QuartileChart;
