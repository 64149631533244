import React from 'react';
import * as misc from '../lib/misc';

class ModalPopup extends React.Component {
	constructor(props) {
		super(props);
		this.modalKey = props.id ? props.id : misc.Guid();
		this.state = {};
		this.title = props.title ? props.title : '';
	}

	getBuildPopup() {
		let styleTxt = {
			height: this.props.height ? this.props.height + 'px' : null,
			width: this.props.width ? this.props.width + 'px' : null
		};

		return (
			<div className="modal metric-modal" id={this.modalKey}>
				<div className="modal-dialog-centered mx-auto col-lg-8 col-md-10 col-12">
					<div className="modal-content bg-primary">
						<div className="modal-header py-2 bg-primary text-white border-0 rounded-0">
							<h4 className="modal-title pl-1 py-0">{this.title}</h4>
							<button type="button" className="close text-white" data-dismiss="modal">
								&times;
							</button>
						</div>
						<div className="modal-body bg-white " style={styleTxt}>
							{this.props.children}
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return this.getBuildPopup();
	}
}
export default ModalPopup;
