import React, { Fragment } from 'react';

export default class SectionLabel extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Fragment>
				{this.props.children}
			</Fragment>
		);
	}
}
