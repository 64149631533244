export const All = [
	'annual_operating_rev',
	'annual_operating_profit',
	'net_income',
	'total_long_term_liabilities',
	'ftes_with_sub_providers',
	'total_discharges',
	'average_daily_census',
	'inpatient_days',
	'adj_aob',
	'operating_margin',
	'operating_rev_per_aob',
	'operating_cost_per_aob',
	'long_term_debt_to_total_assets',
	'days_in_ar',
	'days_in_ap',
	'total_performance_score',
	'star_rating',
	'hosp_rating_star',
	'serious_complication_rate',
	'rate_of_readmission',
	'readmission_reduction_adjustment',
	'hvbp_payment_adjustment',
	'total_hac_score'
];

export const FOSList = [
	'annual_operating_rev',
	'annual_operating_profit',
	'net_income',
	'total_long_term_liabilities',
	'ftes_with_sub_providers',
	'total_discharges',
	'average_daily_census',
	'inpatient_days',
	'adj_aob'
];
export const FinancialList = [
	'operating_margin',
	'operating_rev_per_aob',
	'operating_cost_per_aob',
	'long_term_debt_to_total_assets',
	'days_in_ar',
	'days_in_ap'
];
export const OperationalList = [
	'occupancy_rate',
	'ftes_per_aob',
	'labor_cost_as_a_percent_of_revenue'
];
export const QualityList = [
	'total_performance_score',
	'hosp_rating_star',
	'star_rating',
	'serious_complication_rate',
	'rate_of_readmission',
	'readmission_reduction_adjustment',
	'hvbp_payment_adjustment',
	'total_hac_score'
];

export const SummaryTableFields = () => {
	let list = [];
	list = list.concat(FOSList);
	list = list.concat(FinancialList);
	list = list.concat(OperationalList);
	return list;
};
