import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ReactiveBase, SelectedFilters } from '@appbaseio/reactivesearch';
import HospSearchFields from './hospSearchFields';
import SnfSearchFields from './snfSearchFields';
import { Button, ButtonGroup } from 'react-bootstrap';
import AvailableFields from './availableFields';
import * as providerActions from '../../../core/actions/GlobalActions';
import * as _ from 'lodash';
import $, { get } from 'jquery';
import Auth from '../../../core/Security/Auth';
import * as Actions from '../../actions/ActionHelper';
import * as ActionTypes from '../../actions/ActionTypes';
import * as Util from '../../../lib/FormatUtil';
import * as misc from '../../../lib/misc';
import * as ExpUtil from '../../../lib/ExportUtil';
import {
	faExternalLinkAlt,
	faQuestionCircle,
	faArrowAltCircleRight,
	faBars
} from '@fortawesome/fontawesome-free-solid';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Heading from '../../components/Heading';
import * as UserUtil from '../../../lib/UserUtil';
import DropDownCtrl from '../../components/searchBar/search-drop-ctrl';

const mapStateToProps = store => {
	return {
		fieldDef: store.fieldDef.fields,
		explorerData: store.explorerData.Benchmark,
		explorerDataLoaded: store.explorerData.fetched,
		explorerDataLoading: store.explorerData.fetching
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetExplorerData: (selectedItems, search, segment, filter = []) => {
			dispatch(
				Actions.ActionExplorerRequest(
					ActionTypes.DATA_EXPLORER_LIST,
					search,
					selectedItems,
					segment,
					filter
				)
			);
		},
		ResetData: () => {
			dispatch(providerActions.sessionStorageWhereChange());
		}
	};
};
let currentFilters = {};
let currentMeasures = [];
const staticColumns = ['prvdr_num', 'facility_name', 'facility_city', 'fy_year'];
let filterUpdated = false;

const ReactTableFixedColumns = withFixedColumns(ReactTable);
class DataExplorer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			domain: this.props.domain || setDomain(),
			filters: this.props.filters || [],
			measures: this.props.measures || [],
			elements: this.props.elements || [],
			selectedTab: 0,
			isHidden: true,
			query: { services: [] },
			dataRefreshNeeded: true,
			disabled: true,
			saveName: '',
			savedFilters: [],
			dialogs: [],
			error: '',
			rename: null
		};
		this.tabChanged = this.tabChanged.bind(this);
		this.onMetricChanged = this.onMetricChanged.bind(this);
		this.resetFields = this.resetFields.bind(this);
		this.RefreshData = this.RefreshData.bind(this);
		this.filterSettingsChanged = this.filterSettingsChanged.bind(this);
		this.filterServiceChanged = this.filterServiceChanged.bind(this);
		this.exportButton = this.exportButton.bind(this);
		this.savedSearches = this.savedSearches.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.saveSearch = this.saveSearch.bind(this);
		this.onMySearchSelected = this.onMySearchSelected.bind(this);
		this.registerDialog = this.registerDialog.bind(this);
		this.editClick = this.editClick.bind(this);
		this.deleteSave = this.deleteSave.bind(this);

		function setDomain() {
			if (Auth.isUserHospAuthenticated()) {
				return 'hosp';
			} else if (Auth.isUserSnfAuthenticated()) {
				return 'snf';
			}
			return null;
		}
	}

	registerDialog(domain, type, obj) {
		let state = this.state;
		state.dialogs.push({domain: domain, type: type, tab: obj});
		this.setState(state);
	}

	UNSAFE_componentWillMount() {
		window.addEventListener('scroll', this.fixNavBarToTop);
		window.addEventListener('resize', this.fixNavBarToTop);
		this.fixNavBarToTop();
	}

	componentDidMount() {
		let winH = window.innerHeight;
    let bodyH = document.getElementById('root').clientHeight;
		
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
		this.savedSearches();
	}
	
	componentDidUpdate() {
		$('.MH-InfoTooltip').popover();
	}

	fixNavBarToTop() {
		let header = document.getElementById('page_navigation_header');
		if (header) {
			if (window.pageYOffset >= 104) {
				header.classList.add('fixed-top');
			} else {
				header.classList.remove('fixed-top');
			}
		}
	}

	tabChanged(selectedTab) {
		let state = this.state;
		state.selectedTab = selectedTab;
		this.setState(state);
		if (selectedTab === 2) {
			this.RefreshData();
		}
	}

	onMetricChanged(metric) {
		let state = this.state;
		state.measures = state.measures || [];
		currentMeasures = currentMeasures || [];
		if (state.measures.indexOf(metric) > -1) {
			state.measures.splice(state.measures.indexOf(metric), 1);
			currentMeasures.splice(currentMeasures.indexOf(metric), 1);
		} else {
			state.measures.push(metric);
			currentMeasures.push(metric);
		}
		filterUpdated = true;
		this.setState(state);
	}

	resetFields() {
		let state = this.state;
		state.measures = [];
		currentMeasures = [];
		this.setState(state);
		//this.props.explorerDataLoaded = false;
		filterUpdated = true;
	}

	filterSettingsChanged(filters) {
		let state = this.state;
		if (this.state && filters) {
			let state = this.state;
			state.filters = filters;
			state.dataRefreshNeeded = true;
			this.setState(state);
			filterUpdated = true;
		}
	}

	filterServiceChanged(values) {
		// Add to Search.
		let state = this.state;
		state.query.services = values;
		this.setState(state);
		filterUpdated = true;
	}

	RefreshData() {
		if (!this.props.explorerDataLoading) {
			this.props.ResetData();
			let fields = _.union(staticColumns, currentMeasures);
			filterUpdated = false;
			let filterArray = [];

			if (this.state.filters && this.state.filters.length > 0) {
				for (let i = 0; i < this.state.filters.length; i++) {
					let flt = this.state.filters[i];
					filterArray.push({
						arguments: flt.values,
						operator: 5,
						fieldName: flt.key
					});
				}
			}
			this.props.GetExplorerData(fields, this.state.query, this.state.domain, filterArray);
		}

		let state = this.state;
		state.dataRefreshNeeded = false;
		this.setState(state);
	}

	selectedMetrics() {
		let fields = [];
		if (this.state.measures != null && this.state.measures.length > 0) {
			fields.push('');
		} else {
			fields.push(
				<div className="row">
					<h3 className="fieldError">
						No Fields Selected <br />
						<span>
							Please select at least 1 metric.
						</span>
					</h3>
				</div>
			);
		}
		return <>{fields}</>;
	}

	exportToCsv() {
		let requestedData =
			this.props.explorerDataLoaded && this.props.explorerData && this.props.explorerData.data
				? this.props.explorerData.data
				: [];
		if (this.props.fieldDef && requestedData) {
			let records = staticColumns
				.concat(currentMeasures)
				.map(col => {
					let record = _.find(this.props.fieldDef[this.state.domain], {
						FieldName: col
					});
					if (record !== undefined) {
						return { key: record.FieldName, name: record.FieldShortName };
					}
				})
				.filter(function(x) {
					return x;
				});
			let filename = 'mhm_extract_' + Date.now().toString();
			ExpUtil.exportToCsv(
				filename,
				requestedData,
				records.map(a => a.name),
				records.map(a => a.key)
			);
		}
	}

	formatColumn(props, dynamic) {
		if (props.value && props.value !== '') {
			switch (dynamic.FieldFormat) {
				case 'MONEY':
					return <span className="pull-right px-2">{Util.formatDollar(props.value)}</span>;
				case 'INTEGER':
					return <span className="pull-right px-2">{Util.getInt(props.value)}</span>;
				case 'NUMBER':
					return <span className="pull-right px-2">{Util.getDecimal(props.value)}</span>;
				case 'PERCENT':
					return <span className="pull-right px-2">{Util.getDecimal(props.value * 100)}%</span>;
				default:
					return <span className="pull-left px-2">{props.value}</span>;
			}
		} else {
			return <span className="text-center px-2 align-content-center">{'-'}</span>;
		}
	}

	formatLink(props) {
		let url = '';
		switch (this.state.domain) {
			case 'snf':
				url = misc.baseUrl + '/skillednursing/summary/' + props.original.prvdr_num;
				break;
			case 'hha':
				url = misc.baseUrl + '/homehealth/summary/' + props.original.prvdr_num;
				break;
			case 'hosp':
			default:
				url = misc.baseUrl + '/hospital/summary/' + props.original.prvdr_num;
				break;
		}
		if (props.original.fy_year > new Date().getFullYear() - 5) {
			return (
				<span
					type="link"
					className="btn-link"
					onClick={() => {
						window.open(url, '_blank');
					}}
				>
					{props.value}
				</span>
			);
		} else {
			return <span>{props.value}</span>;
		}
	}

	BuildDataResult() {
		let result = '';
		let fields = this.props.fieldDef[this.state.domain];
		if (
			this.props.explorerDataLoaded &&
			this.props.explorerData &&
			currentFilters &&
			currentMeasures.length > 0
		) {
			let fixedCols = [];
			//'facility_name', 'facility_city', 'fy_year'
			let staticCol = _.find(fields, { FieldName: 'facility_name' });
			if (staticCol) {
				fixedCols.push({
					Header: (
						<div
							className="MH-InfoTooltip bg-info text-white py-1 mx-0 px-0"
							data-toggle="popover"
							data-trigger="hover"
							data-content={staticCol.FieldDescription || staticCol.FieldShortName}
						>
							{staticCol.FieldShortName}
						</div>
					),
					headerClassName: 'bg-info',
					accessor: staticCol.FieldName,
					Cell: props => this.formatLink(props),
					width: 250
				});
			}
			let staticState = _.find(fields, { FieldName: 'facility_city' });
			if (staticState) {
				fixedCols.push({
					Header: (
						<div
							className="MH-InfoTooltip bg-info text-white py-1 mx-0 px-0"
							data-toggle="popover"
							data-trigger="hover"
							data-content={staticState.FieldDescription || staticState.FieldShortName}
						>
							{staticState.FieldShortName}
						</div>
					),
					headerClassName: 'bg-info',
					accessor: staticState.FieldName,
					width: 120
				});
			}
			let staticYr = _.find(fields, { FieldName: 'fy_year' });
			if (staticYr) {
				fixedCols.push({
					Header: (
						<div
							className="MH-InfoTooltip bg-info text-white py-1 mx-0 px-0" 
							data-toggle="popover" 
							data-trigger="hover" 
							data-content={staticYr.FieldDescription || staticYr.FieldShortName} 
						>
							{staticYr.FieldShortName}
						</div>
					),
					headerClassName: 'bg-info',
					accessor: staticYr.FieldName,
					style: {
						textAlign: 'center',
						paddingRight: '5px'
					},
					width: 50
				});
			}

			let dynamicCols = [];
			currentMeasures.map(a => {
				let dynamic = _.find(fields, { FieldName: a });
				if (dynamic) {
					dynamicCols.push({
						Header: (
							<div
								className="MH-InfoTooltip bg-info text-white py-1 mx-0 px-0 text-truncate"
								style={{ textOverflow: 'ellipsis', wordWrap: 'nowrap' }}
								data-toggle="popover"
								data-trigger="hover"
								data-content={dynamic.FieldShortName + ' | ' + dynamic.FieldDescription}
							>
								{dynamic.FieldShortName}
							</div>
						),
						style: {
							textAlign: 'right',
							paddingLeft: '5px'
						}, 
						Cell: props => this.formatColumn(props, dynamic),
						headerClassName: 'bg-info',
						accessor: dynamic.FieldName
					});
				}
			});
			result = (
				<ReactTableFixedColumns
					defaultPageSize={50}
					minRows={50}
					showPagination={true}
					showPaginationBottom={true}
					showPageSizeOptions={false}
					data={
						this.props.explorerData && this.props.explorerData.data
							? this.props.explorerData.data
							: []
					}
					columns={[
						{
							Header: 'Facility',
							fixed: 'left',
							columns: fixedCols,
							headerClassName: 'bg-info text-white my-0 mx-0 px-0 py-2'
						},
						{
							Header: 'Metrics',
							columns: dynamicCols,
							headerClassName: 'bg-info text-white my-0 mx-0 px-0 py-2'
						}
					]}
					style={{ height: '80vh' }}
					className="-striped col px-0"
				/>
			);
		}
		return result;
	}
	refreshingButton() {
		let refreshBtn = 'btn-primary btn-sm ';
		let refreshText = 'Results ';
		let iconBtn = 'fa fa-refresh fa-lg fa-spin';
		if (this.props.explorerDataLoading) {
			refreshBtn = 'btn-primary btn-sm';
			iconBtn = 'fa fa-refresh fa-lg fa-spin';
			refreshText = 'Loading ';
		}
		if (!this.props.explorerDataLoading) {
			refreshBtn = 'btn-primary btn-sm';
			iconBtn = 'fa fa-refresh fa-lg';
			refreshText = 'Report ';
		}
		if (filterUpdated && currentMeasures.length > 0) {
			refreshBtn = 'btn-primary btn-sm';
			iconBtn = 'fa fa-refresh fa-lg refresh-update';
			refreshText = 'Update ';
		}
		return (
			<Fragment>
				{' '}
				{refreshText}
				<i className={iconBtn}/>
			</Fragment>
		);
	}

	toggleHidden() {
		this.setState({ isHidden: !this.state.isHidden });
	}

	pickNewDomain(event) {
		let st = this.state;
		if (st.domain != event) {
			this.resetFields();
			st.domain = event;
			st.filters = [];
			st.measures = [];
			this.setState(st);
			this.savedSearches();
		}
	}
	selectDomainURL() {
		switch (this.state.domain) {
			case 'hha':
				return misc.hhaSearchApiUrl;
			case 'snf':
				return misc.snfSearchApiUrl;
			default:
				return misc.hospSearchApiUrl;
		}
	}
	searchControl() {
		switch (this.state.domain) {
			case 'hha':
				return misc.hhaSearchApiUrl;
			case 'snf':
				return Auth.isUserSnfAuthenticated() ? (
					<SnfSearchFields
						filterServiceChanged={this.filterServiceChanged}
						filterSettingsChanged={this.filterSettingsChanged}
						filters={this.state && this.state.filters ? this.state.filters : []} 
						regFunction={this.registerDialog} 
					/>
				) : (
					''
				);
			default:
				return Auth.isUserHospAuthenticated() ? (
					<HospSearchFields
						filterServiceChanged={this.filterServiceChanged}
						filterSettingsChanged={this.filterSettingsChanged}
						filters={this.state.filters ? this.state.filters : []}
						services={this.state.query.services ? this.state.query.services : []} 
						regFunction={this.registerDialog} 
					/>
				) : (
					''
				);
		}
	}

	exportButton() {
		if (this.state.dataRefreshNeeded) return null;
		else
			return (
				<>
					<div
						id="exportReport"
						onClick={() => this.exportToCsv()}
					>
						<div className="icon">
							<FontAwesomeIcon icon={faExternalLinkAlt} className="fa fa-lg" />
						</div>
						<div className="text">Export<br />Report</div>
					</div>
				</>
			);
	}

	// Populate saved dropdown
	searchOptions() {
		let state = this.state,
		result = [];
		if(state.savedFilters) {
			state.savedFilters.forEach(e => {
				if(state.domain == e.domain) {
					result.push(<option key={e.key} value={e.id}>{e.name}</option>);
				}
			})
		}
		return(
			result
		);
	}

	// Get saved filters/metrics
	savedSearches = async() => {
		let state = this.state;

		try {
			let results = await UserUtil.getSavedFilters();
			if(results.Error == false) {
				state.savedFilters = results.result;
			}
			this.setState(state);
		}
		catch(err) {
			state.error = err.message;
		}
	}

	handleChange = e => {
		e.preventDefault();
		const name = e.target.value;
		let state = this.state;
		if(name.length >= 2) {
			state.disabled = false;
		} else {
			state.disabled = true;
		}
		state.saveName = name;
		this.setState(state);
	}

	saveSearch = async() => {
		let state = this.state,
		tempName = state.saveName;
		
		if(state.rename) {
			this.deleteSave(state.rename);
			state.rename = null;
			this.setState(state);
		}
		try {
			let results = await UserUtil.saveSearchFilter({name: tempName, domain: state.domain, filters: state.filters, query:{services: state.query.services}, measures: state.measures});
		}
		catch(err) {
			state.error = error.message;
		}
		state.filters = [];
		state.query.services = [];
		state.measures = [];
		state.saveName = '';
		this.setState(state);
		$('#saveModal').modal('hide');
		this.savedSearches();
	}

	onMySearchSelected = e => {
		e.preventDefault();
		let state = this.state,
			id = e.target.value,
			selectedFilter = state.savedFilters.find(e => {
				return (
					e.id == id
				);
		});

		console.log(state);
		console.log(id);
		console.log(selectedFilter);
		console.log('-------------------');

		if(id === 'Edit') {
			$('#editSaveModal').modal('show');
			document.getElementById('savedSel').selectedIndex = 0;
		} else if(id != 'Select') {
			let filter = JSON.parse(selectedFilter.filters),
				tempQuery = JSON.parse(selectedFilter.query),
				tempMeasures = JSON.parse(selectedFilter.measures);
			state.saveName = selectedFilter.name;
			state.filters = filter;
			state.query = tempQuery;
			state.measures = tempMeasures;
			currentMeasures = tempMeasures;

			let tab = state.dialogs.forEach(e => {
				if(e.domain == this.state.domain) {
					if(e.type == 'dlg') {
						e.tab.onReset();
						e.tab.onSetFilter(filter);
						filterUpdated = true;
					} else if(e.type == 'metrics') {
						e.tab.setMetrics(tempMeasures);
					} else {
						e.tab.onSetFilter(tempQuery.services);
					}
				}});
		} else {
			let tab = state.dialogs.find(e => {return e.domain = this.state.domain});
			if(tab) {
				tab.tab.onReset();
			}
		}
		this.setState(state);
		filterUpdated = true;
	}

	populateEditModal() {
		let state = this.state,
		editResult = [];
		if(state.savedFilters) {
			state.savedFilters.forEach(e => {
				editResult.push(<li key={e.key}>
					{e.name} 
					<button 
						type="button" 
						value={e.id} 
						title="Delete" 
						className="deleteSave" 
						onClick={this.editClick}
					>
						Delete
					</button>
					<button 
						type="button" 
						value={e.id} 
						title="Rename" 
						className="renameSave" 
						id={e.name}
						onClick={this.editClick} 
					>
						Rename
					</button>
				</li>);
			})
		}
		return (
			editResult
		);
	}

	editClick = (e) => {
		let state = this.state,
		saveId = e.target.value,
		editType = e.target.title,
		tempName = e.target.id;

		if(editType === 'Rename') {
			$('#editSaveModal').modal('hide');
			saveId = parseInt(saveId);
			let selectedFilter = state.savedFilters.find(e => {
				return (
					e.id == saveId
				);
			}),
			tempFilters = JSON.parse(selectedFilter.filters),
			tempMeasures = JSON.parse(selectedFilter.measures),
			tempQuery = JSON.parse(selectedFilter.query);
			state.rename = saveId;
			state.saveName = tempName;
			state.filters = tempFilters;
			state.measures = tempMeasures;
			state.query = tempQuery;
			this.setState(state);
			$('#saveModal').modal('show');
		} else {
			this.deleteSave(saveId);
		}
	}

	deleteSave = async(saveId) => {
		let state = this.state;
		let results = null;
		try {
			results = await UserUtil.deleteSavedFilter({search_id: saveId});
		}
		catch(err) {
			state.error = error.message;
		}
		this.savedSearches();
		this.setState(state);
		if(state.savedFilters.length === 1) {
			$('#editSaveModal').modal('hide');
		}
	}

	render() {
		let refreshBtn = this.refreshingButton();
		let metricList = this.selectedMetrics();
		// let domainBtn = this.ToggleOptions();
		let appDomain = this.state.domain || 'hosp';
		let dataResult =
			this.props.explorerDataLoaded && this.props.explorerData ? this.BuildDataResult() : '';
		let searchURL = this.selectDomainURL();
		let searchCntrl = this.searchControl();
		return (
			<div className="container dataExp" id="content">
				<ReactiveBase
					app={appDomain}
					url={searchURL}
				>
					<div className="row" id="page-nav-row">
						<div id="page_navigation_header">
							<div className="col-12">
								<div className="nav-link">
									Data Explorer:
								</div>
								<div
									className={appDomain === 'hosp' ? 'active nav-link' : 'nav-link'}
									onClick={() => (Auth.isUserHospAuthenticated() ? this.pickNewDomain('hosp') : '')}
								>
									Hospital
								</div>
								<div
									className={appDomain === 'snf' ? 'active nav-link' : 'nav-link'}
									onClick={() => (Auth.isUserSnfAuthenticated() ? this.pickNewDomain('snf') : '')}
								>
									Skilled Nursing
								</div>
							</div>
						</div>
						<div id="page_subnav_header">
							<div
								className="navbar-submenu d-none d-md-block"
							>
								<div className="col-12">
									<SelectedFilters
										className="filter-item"
										innerClass={{
											button: 'pull-left text-white px-1 py-0 my-0 bg-transparent filter-item'
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					
					<div className="row">
						{/* <div className="col-12">
							<Heading
								primary="true"
								heading={appDomain == 'snf' ? 'Skilled Nursing' : 'Hospital'}
							/>
						</div> */}
						<div className="col-12">
							<ul className="react-tabs__tab-list" role="tablist">
								<li
									className={
										this.state.selectedTab === 0
											? 'react-tabs__tab react-tabs__tab--selected'
											: 'react-tabs__tab'
									}
									data-toggle="tab"
									onClick={() => this.tabChanged(0)}
								>
									<span>Filters </span>
								</li>
								<li
									className={
										this.state.selectedTab === 1
											? 'react-tabs__tab react-tabs__tab--selected '
											: 'react-tabs__tab'
									}
									data-toggle="tab"
									onClick={() => this.tabChanged(1)}
								>
									{' '}
									<span>Metrics</span>
								</li>
								<li
									className={
										this.state.selectedTab === 2
											? 'react-tabs__tab react-tabs__tab--selected '
											: 'react-tabs__tab'
									}
									data-toggle="tab"
									onClick={() => this.tabChanged(2)}
								>
									<span>{refreshBtn}</span>
								</li>
								<li
									className='react-tabs__tab'
									data-toggle="modal"
									data-target="#saveModal"
									title="Save Search Modal" 
								>
									<span>Save Search</span>
								</li>
								<Button
									id="infoBttn"
									data-toggle="modal"
									data-target="#modalHowToUse"
									title="Select for information about how to use the Data Explorer"
								>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										className="fa fa-2x text-primary"
									/>
								</Button>
								{this.exportButton()}
								{this.state.savedFilters.length ?
									<div id="savedSearch">
										<label htmlFor="savedSel">My Searches:</label>
										<select id="savedSel" onChange={this.onMySearchSelected}>
											<option key="-1" value="Select">Select</option>
											{this.searchOptions()}
											<option key="-2" value="Edit">Edit Saves</option>
										</select>
									</div>
								: ''}
							</ul>
						</div>
					</div>

					<div className="tab-content">
						<div id="tabFilter" className={this.state.selectedTab === 0 ? 'tab-pane active' : 'tab-pane'}>
							{searchCntrl}
						</div>
						<div id="tabMetrics" className={this.state.selectedTab === 1 ? 'tab-pane active' : 'tab-pane'}>
							<AvailableFields
								fieldsChanged={this.onMetricChanged}
								fieldsReset={_.isEmpty(this.state.measures)}
								resetFields={this.resetFields}
								domain={this.state.domain}
								domainChanged={this.pickNewDomain}
								regFunction={this.registerDialog} 
							/>
						</div>
						<div id="tabReport" className={this.state.selectedTab === 2 ? 'tab-pane active' : 'tab-pane'}>
							{metricList}
							{dataResult}
						</div>
					</div>
				</ReactiveBase>
				{/* Example Modal */}
				<div
					className="modal fade"
					id="modalHowToUse"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h3 className="modal-title" id="exampleModalLabel">
									Data Explorer Help
								</h3>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									data-target={'#modalHowToUse'}
									aria-label="Close"
								>
									<span aria-hidden="true" className="text-white">
										&times;
									</span>
								</button>
							</div>
							<div className="modal-body">
								<h5>
									The Data Explorer is designed to empower users to quickly glean insights on any
									HMP Metrics facility's financial, operational, or quality performance.
								</h5>
								<h3>There are 3 steps to using the Data Explorer:</h3>
								<hr />
								<h4>Step 1: Define your search criteria</h4>
								<p>
									The Data Explorer is accessed via the three tabs in the upper right hand corner.
									The first step is to define your search criteria. Select the Filters tab and
									identify the specific facilities you are interested in.
								</p>
								<ul>
									<li>
										<b>
											NOTE: The Data Explorer is limited to extracting 1,000 facilities at a time.
										</b>
									</li>
									<li>
										If you wish to only see the most recent data for each provider, select the
										"Only Show Most Recent" in settings
									</li>
								</ul>
								<hr />
								<h3>Step 2: Select your metrics</h3>
								<p>
									Create a report with up to 50 metrics. Each collection of metrics is grouped
									into categories. Note if you wish to generate a list of all the available
									metrics, select the <b>All Metrics</b> option at the bottom of the first column.
								</p>
								<ul>
									<li>
										Hint: Move the cursor over any of the field's to get a definition of that
										metric.
									</li>
								</ul>
								<hr />
								<h3>Step 3: Generate the report</h3>
								<p>
									Click the refresh icon to generate the report. Note: if you wish to generate a
									list of all the available metrics, select the "All Metrics" option at the bottom
									of the first column.
								</p>
								<ul>
									<li>
										Select any underlined hospital to open that facility's "Summary Page" in a new
										window.
									</li>
									<li>
										Once you are satisfied with the information you have pulled click the "Export"
										button to download the data.
									</li>
								</ul>
							</div>
							<div className="modal-footer">
								<p><a href="/contact">Contact us</a> if you have any questions about our product or services</p>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									data-target={'#modalHowToUse'}
									aria-label="Close"
								>
									<span aria-hidden="true">
										&times;
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				{/* Save Search Modal */}
				<div
					className="modal fade"
					id="saveModal"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="saveModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
									<h3 className="modal-title" id="saveModalLabel">
										Save Selected Parameters
									</h3>
									<button
										type="button" 
										className="close" 
										data-dismiss="modal" 
										data-target={'#saveModal'} 
										aria-label="Close"
									>
										<span aria-hidden="true" className="text-white">
											&times;
										</span>
									</button>
							</div>
							<div className="modal-body">
									<label htmlFor="saveName">Name Your Search:</label><br />
									<input 
										type="text" 
										id="saveName" 
										name="saveName" 
										value={this.state.saveName}
										onChange={this.handleChange} 
										autoComplete="off" 
										autoFocus 
									/>
							</div>
							<div className="modal-footer">
								<input 
									type="submit" 
									className="loginBttn" 
									id="cancelSave" 
									name="cancelSave" 
									value="Cancel" 
									data-dismiss="modal" 
									data-target={'#saveModal'} 
									aria-label="Cancel"
								/>
								<input 
									type="submit" 
									className="loginBttn" 
									id="saveSearchBttn" 
									name="saveSearchBttn" saveSearch
									value="Save" 
									onClick={this.saveSearch} 
									disabled={this.state.disabled} 
								/>
							</div>
						</div>
					</div>
				</div>
				{/* Edit Saved Searches Modal */}
				<div
					className="modal fade"
					id="editSaveModal"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="editSaveModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
									<h3 className="modal-title" id="editSaveModalLabel">
										Edit Your Saved {this.state.savedFilters.length > 1 ? 'Searches' : 'Search'}
									</h3>
									<button
										type="button" 
										className="close" 
										data-dismiss="modal" 
										data-target={'#editSaveModal'} 
										aria-label="Close"
									>
										<span aria-hidden="true" className="text-white">
											&times;
										</span>
									</button>
							</div>
							<div className="modal-body">
									<ul className="editModalList">
										{this.populateEditModal()}
									</ul>
							</div>
							<div className="modal-footer">
								<input 
									type="submit" 
									className="loginBttn cancelSave" 
									id="" 
									name="cancelSave" 
									value="Cancel" 
									data-dismiss="modal" 
									data-target={'#editSaveModal'} 
									aria-label="Cancel"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DataExplorer);
