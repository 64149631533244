import React from 'react';
import { Helmet } from 'react-helmet';

export default class TemplateChange extends React.Component {
  componentDidMount() {
    document.body.id = 'blog';
    let winH = window.innerHeight,
    bodyH = document.getElementById('root').clientHeight;
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Building Competitive Benchmarking | HMP Metrics</title>
          <meta
            name='description' 
            content='Benchmarking is a technique used to measure, compare, and improve performance.'
          />
        </Helmet>
        <section id="content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>How to Build a Competitive Benchmarking Strategy for Your Hospital</h2>
                <h4 className="greyHeader">Share this post</h4>
                <div id="shareFrame">
                  <a href="http://www.linkedin.com/shareArticle?mini=true&url=https://hmpmetrics.com/blogs/build-comp-bench" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                  <a href="https://twitter.com/intent/tweet?url=https://hmpmetrics.com/blogs/build-comp-bench&text=How to Build a Competitive Benchmarking Strategy for Your Hospital" target="_blank"><i class="fab fa-twitter"></i></a>
                  <a href="https://www.facebook.com/sharer/sharer.php?u=https://hmpmetrics.com/blogs/build-comp-bench" target="_blank"><i class="fab fa-facebook-f"></i></a>
                </div>
                <p id="author">By: Daniel Evans</p>
                <p>Benchmarking is a technique used to measure, compare, and improve performance. Benchmarking can help you identify opportunities for growth and improvement within your hospital by comparing it against similar facilities in your region or peer group. It's also a valuable tool for identifying gaps between where you are now and where you want to be.</p>
                <h4>What are some of the measures you can use to evaluate your hospital's performance against its competitors?</h4>
                <p>A good way to measure the health of your hospital is by tracking KPIs (key performance indicators). We've compiled a list of 5 essential operational, quality, and financial metrics your hospital should be tracking to compete in your market.</p>
                <h5>1. Operating Margin</h5>
                <p>The operating margin is a measure of the hospital's ability to generate profit from its operating revenues. It's measured by dividing the net operating profit by total operating revenues. Good performance requires that the operating margin be consistently between the first quartile <strong>(20.5%)<sup>2</sup></strong> and second quartile <strong>(7.2%)<sup>3</sup></strong> of the national average of GAC<sup>4</sup> hospitals.</p>
                <p><strong>Operating Margin<sup>5</sup> = Net Operating Profit or Loss / Total Operating Revenues</strong></p>
                <p><strong>Net Operating Profit or Loss<sup>5</sup></strong> is defined as the amount of profit (or loss) a provider realizes from its operations after deducting operating expenses from operating revenue (Total Net Patient Revenue).</p>
                <p><strong>Total Operating Revenues<sup>5</sup></strong> is the revenue that remains after related deductions are made to the provider's gross inpatient and outpatient charges, including contractual allowances, charity care, and often bad debts.</p>
                <h6 className="greyHeader">Quartile Based on National Benchmark</h6>
                <img src="./images/blogs/operating-profit-loss-margin.jpg" alt="Operating profit (loss) margin chart" />
                <h5>2. Inpatient Occupancy Rate</h5>
                <p>The inpatient occupancy rate represents the percentage of hospital beds that were occupied by patients during a specified period. This metric is important because it measures how well the hospital is utilizing its physical capacity. It can be calculated by dividing the total inpatient days by the total number of beds licensed during the year. A good performance requires that the occupancy rate be consistently between the first quartile <strong>(Avg 82.9%)<sup>2</sup></strong> and second quartile <strong>(Avg 61.8%)<sup>3</sup></strong> of the national benchmark for GAC<sup>4</sup> hospitals.</p>
                <p><strong>Inpatient Occupancy Rate<sup>5</sup> = Inpatient days / (Total Licensed Beds x 365)</strong></p>
                <p><strong>Inpatient Days<sup>5</sup>:</strong> The total number of days for which an inpatient was admitted to the hospital during the specified period. This includes patients admitted for observation or medical tests and those who had surgery or were treated for a medical condition. Time spent in observation units and emergency rooms is not included; however, patients who leave a hospital before discharge are included in this measure.</p>
                <p><strong>Licensed Beds<sup>5</sup></strong>: The total number of licensed beds available at the hospital at any time. This includes all types of beds including private rooms and semi-private rooms.</p>
                <h6 className="greyHeader">Quartile Based on National Benchmark</h6>
                <img src="./images/blogs/accute-occupancy-rate.jpg" alt="Accute occupancy rate chart" />
                <h5>3. Overall Facility Star Rating</h5>
                <p>The overall facility star rating is an overall "Star Rating" for various quality measures as defined by the U.S. Centers for Medicare and Medicaid Services (CMS). It is a composite score that represents a summary of various quality measures that reflect how well a hospital performed on mortality, safety, readmission, patient experience, and timely and effective care.</p>
                <p>The “Star Rating” score ranges from excellent 5-Star as the highest possible rating to 1-Star as the lowest. The national average for GAC<sup>4</sup> hospitals is 3.26 which means that any star rating below a 3-Star rating will rank below average.</p>
                <h5>4. Total Labor Cost as a Percentage of Total Operating Revenue</h5>
                <p>Total labor cost as a percentage of total operating revenue is an important labor statistic. It tells you how much of your revenue is going to employee compensation. If it's too high, it can negatively impact your organization's ability to meet financial goals. For example, if you have an average labor cost as a percentage of revenue of 50% and the industry average is 38%, you may need to cut costs or increase revenue to achieve your budgeted numbers. This metric is calculated by dividing total labor cost by total operating revenue. Based on the national GAC<sup>4</sup> average below, your facility should strive to be between <strong>29%<sup>2</sup></strong> and <strong>38%<sup>3</sup></strong>.</p>
                <p><strong>Total Labor Cost as a Percentage of Total Operating Revenue = Total Labor Cost / Total Operating Revenue<sup>5</sup></strong></p>
                <p><strong>Total Labor Cost<sup>5</sup></strong> is defined as the sum of amounts paid for: employee salaries and wages plus total contract labor, including agency staff plus employee fringe benefits.</p>
                <p><strong>Total Operating Revenue<sup>5</sup></strong> is defined as the revenue that remains after related deductions are made to the provider's gross inpatient and outpatient charges, including contractual allowances, charity care and often bad debts.</p>
                <h6 className="greyHeader">Quartile Based on National Benchmark</h6>
                <img src="./images/blogs/total-labor-cost-as-a-percentage-of-total-operating-revenue.jpg" alt="Total labor cost as a percentage of total operating revenue chart" />
                <h5>5. Days Net Patient Revenue in Net Patient Accounts Receivable</h5>
                <p>The days of net patient revenue in accounts receivable indicates how long it will take, on average, for a provider to collect all their outstanding patient balances. It is defined by dividing net patient accounts receivable by total net patient revenue for that year. This metric can gauge a provider's cash flow efficiency and ability to pay vendors and other creditors on time. A higher percentage means that a provider's patients are paying their bills slower than average. Good performance requires that a hospital be consistently between the first quartile <strong>(30 Days in AR)<sup>2</sup></strong> and second quartile <strong>(41 Days in AR)<sup>3</sup></strong> of the national average of GAC<sup>4</sup> hospitals.</p>
                <p><strong>Days Net Patient Revenue in Net Patient Accounts Receivable5 = Total Net Patient Accounts Receivable / (Total Net Patient Revenue / 365).</strong></p>
                <p><strong>Total Net Patient Accounts Receivable<sup>5</sup></strong> is defined as a hospital's outstanding invoices or the money owed to the hospital by its patients and payors. Accounts receivable refers to accounts a business has a right to receive because it has delivered a product or service.</p>
                <p><strong>Total Net Patient Revenue<sup>5</sup></strong> is defined as the revenue that remains after related deductions are made to the provider's gross inpatient and outpatient charges, including contractual allowances, charity care and often bad debts.</p>
                <h6 className="greyHeader">Quartile Based on National Benchmark</h6>
                <img src="./images/blogs/days-net-patient-revenue-in-net-patient-accounts-receivable.jpg" alt="Days net patient revenue in net patient accounts receivable chart" />
                <h5>Takeaway</h5>
                <p>Benchmarking can be an extremely useful tool for hospital leaders seeking to drive organizational change. Studying the strengths and weaknesses of similar hospitals can help to identify best practices, determine how your organization stacks up against others, and help set benchmarks for improvement. Once you've identified areas where your hospital needs to improve, benchmarking can also be used to create plans outlining target areas for success.</p>
                <hr />
                <ol className="refList">
                  <li><a href="https://hcmpllc.com/daniel-evans.html" target="_blank">Daniel Evans</a> is a Managing Director of <a href="https://www.hcmpllc.com/" target="_blank">Healthcare Management Partners, LLC.</a> and CEO of HMP Metrics, LLC.</li>
                  <li>Computed as the 5-year average of 1st quartile for national GAC facilities between fiscal years 2017 and 2021.</li>
                  <li>Computed as the 5-year average of the 2nd quartile for national GAC facilities between fiscal years 2017 and 2021.</li>
                  <li>GAC is an acronym for General Acute Care.</li>
                  <li>Data and calculations provided by HMP Metrics, LLC. (<a href="https://hmpmetrics.com/" >HMPMetrics.com</a>) which is a wholly-owned subsidiary of Healthcare Management Partners, LLC.</li>
                  <li>The average of 3.2 is the national average of “Overall Star Rating” for all GAC hospitals for any bed size. Provided by <a href="https://hmpmetrics.com/" >HMPMetrics.com</a>.</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}