import React from 'react';
import { connect } from 'react-redux';
import PageNavigation from '../../core/components/navigation/pageNavigation';
import OccupancyTrend from './OccupancyTrend';
import FacilityRating from './FacilityRating';
import FacilityInfo from './FacilityInfo';
import Heading from '../../core/components/Heading';
import * as misc from '../../lib/misc';
import { fetchBalanceSheet, fetchIncomeStatement } from '../Financial/FinancialActions';
import { AddNewFields } from '../../core/actions/GlobalActions';
import * as MHGrid from '../../tools/datagrid';
import DataGridFooterNotation from '../../tools/datagrid/extras/DataGridFooterNotation';
import LoadingSpinner from '../../tools/loadingSpinner';
import './index.css';
import * as SummaryFields from './SummaryFields';
import * as whereBuilder from '../../lib/whereBuilder';
import * as Actions from '../../core/actions/ActionHelper';
import * as ActionTypes from '../Common/ActionTypes';
import CustomGridMenu from '../../core/components/navigation/customGridMenu';
import BenchmarkDataExport from '../Benchmark/BenchmarkDataExport';


const QualityList = [
	'total_performance_score',
	'hosp_rating_star',
	'star_rating',
	'serious_complication_rate',
	'rate_of_readmission',
	'readmission_reduction_adjustment',
	'hvbp_payment_adjustment',
	'total_hac_score'
];


const buildQualitySummaryGridColumns = () => {
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn(
			'metric',
			new MHGrid.DataGridHeader('', '', ''),
			'300',
			MHGrid.ColType_Metric
		)
	);
	let foi = misc.getFacilityInfo();
	let yr = foi.data_quality_last_year;

	for (let i = 4; i >= 0; i--) {
		Cols.Add(
			new MHGrid.DataGridColumn(
				yr - i,
				new MHGrid.DataGridHeader(yr - i, '', '', 'right'),
				'155',
				MHGrid.ColType_Self
			)
		);
	}
	return Cols;
};


const buildSummaryGridColumns = () => {
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn(
			'metric',
			new MHGrid.DataGridHeader('', '', ''),
			'300',
			MHGrid.ColType_Metric
		)
	);
	let yr = misc.getFacilityYear();
	for (let i = 4; i >= 0; i--) {
		Cols.Add(
			new MHGrid.DataGridColumn(
				yr - i,
				new MHGrid.DataGridHeader(yr - i, '', '', 'right'),
				'155',
				MHGrid.ColType_Self
			)
		);
	}

	return Cols;
};

const buildFinancialAndOperStatistics = (Data, FieldDef) => {
	let Cols = buildSummaryGridColumns();

	// Financial & Operating Statistics
	let FOSList = [
		'annual_operating_rev',
		'annual_operating_profit',
		'net_income',
		'total_long_term_liabilities',
		'ftes_with_sub_providers',
		'total_discharges',
		'average_daily_census',
		'inpatient_days',
		'adj_aob'
	];
	return (
		<div className="col-md-12" id="facLevelTbl">
			<Heading
				primary="true"
				heading="Facility Level: Financial and Operational Summary by Fiscal Year"
			/>
			<h6>Facility Level</h6>
			<div className="sticky_table_header">
				<MHGrid.DataGrid columns={Cols} headersOnly={true} />
			</div>
			<MHGrid.DataGrid
				suppressLines={true}
				columns={Cols}
				fieldDef={FieldDef}
				rows={Data ? misc.filterMetrics(Data, FOSList) : []}
				showHeaders={false}
			/>
		</div>
	);
};
const buildKeyMetricAnalysis = (Data, QData, FieldDef) => {
	let Cols = buildSummaryGridColumns();
	let QCols = buildQualitySummaryGridColumns();

	// let Title = new MHGrid.DataGridTitle('Summary Benchmark Report: Key Metric Analysis', '', '');
	// Title.header1Size = 3;

	// Financial Metrics
	let FinancialList = [
		'operating_margin',
		'operating_rev_per_aob',
		'operating_cost_per_aob',
		'long_term_debt_to_total_assets',
		'days_in_ar',
		'days_in_ap'
	];
	// Operational Metrics
	let OperationalList = ['occupancy_rate', 'ftes_per_aob', 'labor_cost_as_a_percent_of_revenue'];

	// Quality & PFP Metrics

	

	return (
		<>
			<div className="col-md-12" id="financialTbl">
				<Heading
					primary="true"
					heading="Summary Benchmark Report: Key Metric Analysis"
				/>
				<h6>Financial Metrics</h6>
				<div className="sticky_table_header">
					<MHGrid.DataGrid columns={Cols} headersOnly={true} />
				</div>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={Cols}
					fieldDef={FieldDef}
					rows={Data ? misc.filterMetrics(Data, FinancialList) : []}
					showHeaders={false}
				/>
			</div>
			<div className="col-md-12" id="operatTbl">
				<h6>Operational Metrics</h6>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={Cols}
					fieldDef={FieldDef}
					rows={Data ? misc.filterMetrics(Data, OperationalList) : []}
					showHeaders="false"
				/>
			</div>
			<div className="col-md-12" id="qualityTbl">
				<h6>Quality Metrics</h6>
				<MHGrid.DataGrid
					suppressLines={true}
					columns={QCols}
					fieldDef={FieldDef}
					rows={QData ? misc.filterMetrics(QData, QualityList) : []}
				/>
			</div>
		</>
	);
};

const mapStateToProps = store => {
	return {
		// benchmarkrowcustom: store.benchmarkRowCustom.benchmarkRowCustom,
		providerInfo: store.providerInfo,
		balancesheet: store.balanceSheet,
		incomestatement: store.incomeStatement,
		FieldDesc: store.fieldDef.fields.hosp,
		qualityData: store.hospQualitySummary.Benchmark,
		qualityFetched: store.hospQualitySummary.fetched,
		qualityFetching: store.hospQualitySummary.fetching

	};
};

class SummaryPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = { showModal: false, facilityId: misc.getFacilityIds() };
		this.onShowModalClick = this.onShowModalClick.bind(this);
		this.onCloseClick = this.onCloseClick.bind(this);
	}

	onCloseClick() {
		this.setState({ showModal: false });
	}

	onShowModalClick() {
		this.setState({ showModal: true });
	}

	getFinancialData() {
		this.state.facilityId = misc.getFacilityIds();
		this.props.dispatch(AddNewFields(SummaryFields.All, this.props.FieldDesc));
		if (this.state.facilityId && this.state.facilityId.length > 0) {
			whereBuilder.storeDefaultWhere('hosp');
			if (
				this.props &&
				this.props.balanceSheet &&
				this.props.balancesheet.fetching === false &&
				this.props.balancesheet.fetched === false
			) {
				this.props.dispatch(fetchBalanceSheet(misc.getFacilityIds()));
			}

			if (
				this.props.incomestatement.fetching === false &&
				this.props.incomestatement.fetched === false
			) {
				this.props.dispatch(fetchIncomeStatement(misc.getFacilityIds()));
			}

			if (this.props.qualityFetched === false && this.props.qualityFetching === false)
			{
				this.props.dispatch(Actions.ActionPivotDataRequest(
					ActionTypes.HOSP_SUMMARY_QUALITY_METRICS,
					this.state.facilityId,
					'hosp',
					QualityList,
					'fy_year',
					true,
					whereBuilder.setDefaultBenchmark('hosp', 'quality')
				));
			}


		}
	}
	UNSAFE_componentWillMount() {
		window.scrollTo(0, 0);
		this.getFinancialData();
	}
	componentDidUpdate() {
		if (this.props.balancesheet.whereChanged) {
			this.getFinancialData();
		}
	}
	render() {
		document.body.id = 'facSummary';
		const iData =
			this.props.incomestatement.fetched === true &&
			this.props.incomestatement.incomeStatement.result &&
			this.props.incomestatement.incomeStatement.result.data &&
			this.props.incomestatement.incomeStatement.result.data.rows
				? this.props.incomestatement.incomeStatement.result.data.rows
				: [];
		const QData = this.props.qualityFetched ? this.props.qualityData.data : [];				
		const FieldDesc = this.props.FieldDesc;
		/* let Title = new MHGrid.DataGridTitle('Financial Performance', '', '', 3);
		let GridControlMenu = (
			<CustomGridMenu>
				<BenchmarkDataExport
					{...this.props}
					fileName={Title.header1}
					domain={'hosp'}
					sections={_sectionsTemp}
					tabDef={this.getTabDef()}
					headAlign={'right'}
					bodyAlign={'right'}
					Cols={this.getFinancialColumns()}
					data={this.props.data.data}
					FieldDesc={this.props.FieldDesc}
				/>
			</CustomGridMenu>
		); */
		const result =
			this.state.facilityId.length > 0 ? (
				<>
					<PageNavigation selectedTab="1" domain="hosp" />
					<section id="content">
						<FacilityInfo />
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-12">
									<h5>Weighted Average Acute Occupancy Rate:</h5>
									<OccupancyTrend facilityId={misc.getFacilityIds()} />
								</div>
								<FacilityRating facilityId={misc.getFacilityIds()} />
							</div>
							<div className="row" id="summaryTables">
								<hr />
								{buildFinancialAndOperStatistics(iData, FieldDesc)}
								{buildKeyMetricAnalysis(iData, QData, FieldDesc)}
								<DataGridFooterNotation />
							</div>
						</div>
					</section>
				</>
			) : (
				<div className="container">
					<div className="row">
						<LoadingSpinner />
					</div>
				</div>
			);
		return result;
	}
}

export default connect(mapStateToProps)(SummaryPage);
