export const buttonStyle = {
	primary: {
		backgroundColor: '#51b792',
		borderColor: '#3d7d69'
	}
};
export const FOI = '#173C49';
export const Quartile1 = '#304771';
export const Quartile2 = '#4fb591';
export const Quartile3 = '#F7A014';
export const Quartile4 = '#912896';

export const Q1bg = '#d4eff7';
export const Q2bg = '#b7e1d2';
export const Q3bg = '#fce1b5';
export const Q4bg = '#cec0f3';

export const Series1 = '#909590';
export const Series2 = '#2792C4';
export const Series3 = '#304771';
export const Series4 = '#BBBDC9';

export const SeriesArray = [
	'#909590',
	'#2792C4',
	'#304771',
	'#BBBDC9',
	'#009bc9',
	'#3B856A',
	'#112C36',
	'#fdd89b',
	'#BEC5C8',
	'#304771',
	'#C0D6DC'
];
