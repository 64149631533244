import LoadingSpinner from '../../tools/loadingSpinner';
import React from 'react';
export class MarketSearchExplorer extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		//using this as a shadow component to handle the results of custom queries

		if (this.props.hits && this.props.hits.length > 0) {
			this.props.GetResults(
				this.props.hits.map(a => {
					let row = a._source;
					row.sort = a.sort;
					return row;
				})
			);
		}
		return null;
	}
}

export function getResultsWithin100Mi(latitude, longitude) {
	return {
		query: {
			bool: {
				filter: {
					geo_distance: {
						distance: '100mi',
						location: { lat: latitude, lon: longitude }
					}
				}
			}
		},
		sort: [
			{
				_geo_distance: {
					location: { lat: latitude, lon: longitude },
					order: 'asc',
					unit: 'mi'
				}
			}
		],
		size: 100
	};
}

export function getClosestFacilities(latitude, longitude, facilityType = 'hosp', number = 10) {
	return {
		query: { bool: { must: [{ bool: { must: [{ terms: { domain: [facilityType] } }] } }] } },
		sort: [
			{
				_geo_distance: {
					location: { lat: latitude, lon: longitude },
					order: 'asc',
					unit: 'mi'
				}
			}
		],
		size: number
	};
}
