import React from 'react';

export default class CheckboxList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: props.data ? props.data : [],
			key: props.value ? props.value : 'value',
			label: props.label ? props.label : 'label'
		};
		this.onChecked = this.onChecked.bind(this);
	}
	fireOnChange() {
		if (this.props.onChange) {
			let values = [];
			if (this.state.data && Array.isArray(this.state.data))
				this.state.data.forEach(x => {
					if (x.checked) values.push(x[this.state.key]);
				});

			this.props.onChange(values);
		}
	}
	onChecked(e) {
		let state = this.state;
		state.data[e].checked = state.data[e].checked ? !state.data[e].checked : true;
		this.setState(state);
		this.fireOnChange();
	}
	checkBox(idx) {
		return (
			<li className="list-group-item">
				<div className="custom-control custom-checkbox" onClick={() => this.onChecked(idx)}>
					<input
						type="checkbox"
						className="custom-control-input"
						checked={this.state.data[idx].checked}
						id={idx}
					/>
					<label className="custom-control-label" for={idx}>
						{this.state.data[idx][this.state.label]}
					</label>
				</div>
			</li>
		);
	}

	render() {
		let listBody = [];
		for (let i = 0; i < this.state.data.length; i++) {
			if (this.state.data[i].checked === undefined) {
				this.state.data[i].checked = true;
			}
			listBody.push(this.checkBox(i));
		}

		return (
			<ul className="list-group overflow-auto" style={{ 'max-height': '500px' }}>
				{listBody}
			</ul>
		);
	}
}
