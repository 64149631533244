import React, { Fragment } from 'react';
import Select from 'react-select';

export default class SearchDropDown extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return this.props.disabled ? null : (
			<Fragment>
				<span className="label label-default text-primary">{this.props.label}</span>
				<Select
					key={this.props.key}
					placeholder={this.props.placeholder}
					//   value={selectedTrend || ""}
					//   onChange={this.onTrendMappingChange}
					options={this.props.options}
					className="w-100 reactSelect"
					isMulti
					onChange={e => {
						if (this.props.onChangeCallBack)
							this.props.onChangeCallBack(
								this.props.id,
								this.props.domain ? this.props.domain : '',
								e
							);
					}}
					value={this.props.values ? this.props.values : null}
				/>
			</Fragment>
		);
	}
}
