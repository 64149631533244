import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../../tools/datagrid';
import LoadingSpinner from '../../tools/loadingSpinner';
import * as misc from '../../lib/misc';
import NoData from '../../core/components/notifications/NoData';

const mapStateToProps = store => {
	return {
		FieldDesc: store.fieldDef.fields.snf,
		whereChanged: store.snfBenchmarkData.whereChanged,
		data: store.snfQualityData.Benchmark,
		dataLoaded: store.snfQualityData.fetched,
		dataLoading: store.snfQualityData.fetching
	};
};

class QualityTab extends React.Component {
	constructor(props) {
		super(props);
		this.SummaryCols = props.Cols;
		this.sections = props.Sections;
		this.getAddCompetitors = this.props.getCompetitors;
	}

	render() {
		let data = this.props.data;
		let providers = misc.getFOIandCompetitors();
		if (this.props.dataLoading) {
			return <LoadingSpinner />;
		} else {
			if (
				this.props.dataLoaded &&
				!this.props.dataLoading &&
				Array.isArray(this.sections) &&
				this.sections.length > 0
			) {
				let results = [];
				for (let i = 0; i < this.sections.length; i++) {
					let _title = this.sections[i].title;
					if (this.sections[i].callout) {
						_title = (
							<Fragment>
								{this.sections[i].title}
								<small>
									<i
										className=" pl-2 MH-InfoTooltip fa fa-info-circle"
										aria-hidden="true"
										data-toggle="popover"
										data-trigger="hover"
										data-content={this.sections[i].callout}
									/>
								</small>
							</Fragment>
						);
					}
					if (i == 0) {
						if (this.sections[i].title) {
							results.push(
								<div className="text-left">
									<h5 className="tblSubHead">{_title}</h5>
								</div>
							);
						}
						results.push(
							<div className="sticky_table_header">
								<MHGrid.DataGrid
									suppressLines={true}
									columns={this.SummaryCols}
									rows={data ? this.props.data.Fetch(this.sections[i].fields, providers) : []}
									showHeaders={true}
									headersOnly={true}
								/>
							</div>
						);
					}
					results.push(
						<MHGrid.DataGrid
							suppressLines={true}
							fieldDef={this.props.FieldDesc}
							columns={this.SummaryCols}
							rows={data ? this.props.data.Fetch(this.sections[i].fields, providers) : []}
							subcategory={i != 0 ? _title : null}
							showHeaders={false}
						/>
					);
				}
				return (
					<div className="panel panel-default">
						{results}
						{this.props.children}
					</div>
				);
			} else {
				return <NoData />;
			}
		}
	}
}

export default connect(
	mapStateToProps,
	null
)(QualityTab);
