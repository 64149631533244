import 'react-hot-loader/patch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css'; // Old Styles
import './styles.css'; // New Styles
import 'jquery';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap.min';
// const MOUNT_NODE = document.getElementsByTagName('body')[0];
const MOUNT_NODE = document.getElementById('root');

// Create redux store with history
const render = () => {
	ReactDOM.render(
		<AppContainer>
			<Provider store={store}>
				<App />
			</Provider>
		</AppContainer>,
		MOUNT_NODE
	);
};

render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
	// Hot reloadable React components and translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept('./App', () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE);
		render(App);
	});
}

serviceWorker.unregister();
