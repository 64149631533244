import React from 'react';
import * as css from './DataGrid.css';
import { Row, Col } from 'react-bootstrap';
// import Halogen from 'halogen';
// import { Modal } from 'react-bootstrap';

class DataGridFooterNotation extends React.Component {
	render() {
		let defaultText = (
			<p className="footNotation">
				<i>Notation:</i> - (dash): Data not reported. ** (asterisk): Metric flagged as
				possible outlier.
			</p>
		);
		let messageText = this.props.text ? <p className="footNotation">{this.props.text} </p> : defaultText;

		return (
			<div className="col-12 text-right">
				{messageText}
				{this.props.children}
			</div>
		);
	}
}

export default DataGridFooterNotation;
