import React, { Fragment } from 'react';
import DropDownCtrl from './search-drop-ctrl';
import SectionLabel from './section-label';
import * as api from '../../../lib/ApiUtil';
import * as misc from '../../../lib/misc';
import Tabs from '../../../tools/ui-templates/tabs';

// import './index.css';

const getList = list => {
	return list ? list : [];
};

export default class FilterDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			options: { hosp: [] },
			filters: props.filters ? props.filters : [],
			key: misc.Guid(),
			most_recent_report: false,
			values: {
				state_name: [],
				cbsa_name: [],
				city_name: [],
				system: [],
				hospital_type: [],
				hosp_ownership_type: [],
				teach_status_class: [],
				cah: [],
				urban_rural_indicator: [],
				bed_cohort: [],
				net_patrev_cohort_id: [],
				net_income_cohort_id: [],
				fy_year: [],
				star_rating: []
			}
		};
		this.onFldChange = this.onFldChange.bind(this);
		this.onReset = this.onReset.bind(this);
		this.onApply = this.onApply.bind(this);
		this.onMostRecentReportChange = this.onMostRecentReportChange.bind(this);
		this.initFilters = this.initFilters.bind(this);
		this.loadSearchOptions(this.state.filters);
		this.onSetFilter = this.onSetFilter.bind(this);
	}

	componentDidMount() {
		if(this.props.regFunction) {
			this.props.regFunction('hosp', 'dlg', this);
		}
	}

	loadSearchOptions(filters) {
		api.APIPost('/search-options', { domain: 'hosp', filters: filters }).then(
			data => {
				let state = this.state;
				state.options = data.data.result;
				state.key = misc.Guid();
				this.initFilters(state);
				this.setState(state);
			},
			err => {}
		);
	}

	initFilters(state) {
		if(state.options && state.options.hosp && state.filters && Array.isArray(state.filters))
		{
			state.filters.forEach(e => {
				if(e.values && Array.isArray(e.values))
				{	
					let opts = state.options.hosp[e.key];

					if(opts)
					{
						e.values.forEach(x => {

							let opt = state.options.hosp[e.key].find(i => { return i.value == x});
							if(opt) state.values[e.key].push(opt);		
						});
					}
  				}
			});
		}
	}

	onMostRecentReportChange(e) {
		let checked = e.target.checked;
		let state = this.state;

		if (checked) {
			// Remove FY_from Filters
			state.filters.push({
				key: 'most_recent_report',
				domain: 'hosp',
				values: [true]
			});

			state.values.fy_year = [];

			let idx = state.filters.findIndex(x => {
				return x.key == 'fy_year';
			});
			if (idx >= 0) {
				state.filters.splice(idx, 1);
			}
			state.most_recent_report = true;
		} else {


			state.most_recent_report = false;

			let idx = state.filters.findIndex(x => {
				return x.key == 'most_recent_report';
			});
			if (idx >= 0) {
				state.filters.splice(idx, 1);
			}
		}
		this.setState(state);


		if (!this.props.model) {
			if (this.props.onFilterChanged) this.props.onFilterChanged(this.state.filters);
		}
	}

	onFldChange(key, domain, e) {
		let state = this.state;
		let opt = null;
		let optIdx = -1;

		state.values[key] = e;
		// Check to see if filter is already set
		for (let i = 0; i < state.filters.length; i++) {
			if (state.filters[i].key == key) {
				opt = state.filters[i];
				optIdx = i;
				break;
			}
		}
		if (opt) {
			// Filter is already selected
			if (e && e.length > 0) {
				opt.values = e.map(e => {
					return e.value;
				});
			} else {
				state.filters.splice(optIdx, 1);
			}
		} else if (e && e.length > 0) {
			state.filters.push({
				key: e && e.length > 0 ? e[0].key : '',
				domain: domain,
				values: e.map(e => {
					return e.value;
				})
			});
		}
		state.key = misc.Guid();
		this.setState(state);
		if (!this.props.model) {
			if (this.props.onFilterChanged) this.props.onFilterChanged(this.state.filters);
		}
	}

	leftSideHospEdits(ctrWidth) {
		return (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="system"
					domain="hosp"
					label="System Affiliation"
					placeholder="Select ..."
					options={this.state.options.hosp['system']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.system}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="hospital_type"
					domain="hosp"
					label="Provider Type"
					placeholder="Select ..."
					options={this.state.options.hosp['hospital_type']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hospital_type}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="cah"
					domain="hosp"
					label="Critical Access"
					placeholder="Select ..."
					options={this.state.options.hosp['cah']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.cah}
				/>
			</Fragment>
		);
	}

	centerHospEdits(ctrWidth) {
		return (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="hosp_ownership_type"
					label="Ownership Type"
					domain="hosp"
					placeholder="Select ..."
					options={this.state.options.hosp['hosp_ownership_type']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.hosp_ownership_type}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="bed_cohort"
					domain="hosp"
					label="Bed Size"
					placeholder="Select ..."
					options={this.state.options.hosp['bed_cohort']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.bed_cohort}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="teach_status_class"
					domain="hosp"
					label="Teaching Status"
					placeholder="Select ..."
					options={this.state.options.hosp['teach_status_class']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.teach_status_class}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="star_rating"
					domain="hosp"
					label="Overall Star Rating"
					placeholder="Select ..."
					options={this.state.options.hosp['star_rating']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.star_rating}
				/>



			</Fragment>
		);
	}

	rightSideHospEdits(ctrWidth) {
		return (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="urban_rural_indicator"
					domain="hosp"
					label="Urban / Rural"
					placeholder="Select ..."
					options={this.state.options.hosp['urban_rural_indicator']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.urban_rural_indicator}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="net_patrev_cohort_id"
					domain="hosp"
					label="Net Patient Revenue"
					placeholder="Select ..."
					options={this.state.options.hosp['net_patrev_cohort_id']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.net_patrev_cohort_id}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="net_income_cohort_id"
					domain="hosp"
					label="Net Profit or Loss"
					placeholder="Select ..."
					options={this.state.options.hosp['net_income_cohort_id']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.net_income_cohort_id}
				/>
				{this.timeFilters(ctrWidth)}
			</Fragment>
		);
	}

	timeFilters(ctrWidth) {
		return this.props.showTimeFilters == true ? (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="fy_year"
					domain="hosp"
					label="Fiscal Year"
					placeholder="All Years"
					options={this.state.options.hosp['fy_year']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.fy_year}
					disabled={this.state.most_recent_report}
				/>
				{this.mostRecentReport()}
			</Fragment>
		) : null;
	}

	mostRecentReport() {
		return (
			<div className="custom-control-inline d-inline-flex custom-checkbox text-primary justify-content-between ml-4 ">
				<input
					type="checkbox"
					className="custom-control-input"
					id="most_recent_report"
					name="most_recent_report"
					onChange={this.onMostRecentReportChange}
					checked={this.state.most_recent_report == true ? "checked" : null }
				/>
				<label className="custom-control-label" htmlFor="most_recent_report">
					Most Recent Report
				</label>
			</div>
		);
	}

	onSetFilter(filters) {
		let state = this.state;
		state.filters = filters;


		this.initFilters(state);
		this.setState(state);

		if(filters && Array.isArray(filters))
		{
			let filter = filters.find(x => { return x.key == 'most_recent_report'});
			if(filter)
			{
				if(filter.values && Array.isArray(filter.values) && filter.values.length > 0)
				{
					state.most_recent_report = filter.values[0];
				}
			}
		}

		if(this.props.onFilterChanged) {
			this.props.onFilterChanged(this.state.filters);
		}
		
	}

	onReset(e) {
		let state = this.state;
		state.filters = [];
		state.values.state_name = [];
		state.values.cbsa_name = [];
		state.values.city_name = [];
		state.values.system = [];
		state.values.hospital_type = [];
		state.values.hosp_ownership_type = [];
		state.values.teach_status_class = [];
		state.values.cah = [];
		state.values.urban_rural_indicator = [];
		state.values.bed_cohort = [];
		state.values.net_patrev_cohort_id = [];
		state.values.net_income_cohort_id = [];
		state.values.fy_year = [];
		state.values.star_rating = [];
		this.setState(state);
	}

	onApply(e) {
		if (this.props.onFilterChanged) this.props.onFilterChanged(this.state.filters);
	}

	locView(ctrWidth) {
		return (
			<Fragment>
				<DropDownCtrl
					width={ctrWidth}
					id="state_name"
					domain=""
					label="State"
					placeholder="Select ..."
					options={this.state.options.hosp['state_name']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.state_name}
				/>
				<DropDownCtrl
					width={ctrWidth}
					id="cbsa_name"
					domain=""
					label="Core Based Statistical Area (CBSA)"
					placeholder="Select ..."
					options={this.state.options.hosp['cbsa_name']}
					onChangeCallBack={this.onFldChange}
					values={this.state.values.cbsa_name}
				/>
			</Fragment>
		);
	}

	hospView() {
		let ctrWidth = 275;
		let cols = this.props.cols ? this.props.cols : 2;

		switch (cols) {
			case 1:
				ctrWidth = 400;
				return (
					<SectionLabel>
						<div className="row">
							{this.locView(ctrWidth)}
							{this.leftSideHospEdits(ctrWidth)}
							{this.centerHospEdits(ctrWidth)}
							{this.rightSideHospEdits(ctrWidth)}
						</div>
					</SectionLabel>
				);
			case 2:
				ctrWidth = 350;
				return (
					<SectionLabel>
						<div className="row">
							<div className="col-6">
								{this.locView(ctrWidth)}
								{this.leftSideHospEdits(ctrWidth)}
							</div>
							<div className="col-6">
								{this.centerHospEdits(ctrWidth)}
								{this.rightSideHospEdits(ctrWidth)}
							</div>
						</div>
					</SectionLabel>
				);
			default:
				ctrWidth = 275;
				return (
					<SectionLabel>
						<div className="col-lg-4 col-sm-6 col-12">
							{this.locView(ctrWidth)}
							{this.leftSideHospEdits(ctrWidth)}
						</div>
						<div className="col-lg-4 col-sm-6 col-12">{this.centerHospEdits(ctrWidth)}</div>
						<div className="col-lg-4 col-sm-6 col-12">{this.rightSideHospEdits(ctrWidth)}</div>
					</SectionLabel>
				);
		}
	}

	dlgButtons() {
		return (
			<div className="row">
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary " onClick={this.onReset}>
						Reset
					</button>

					<button
						type="button"
						className="btn btn-secondary"
						data-dismiss="modal"
						onClick={this.onApply}
					>
						Apply
					</button>
				</div>
			</div>
		);
	}

	render() {
		return (
			<Fragment>
				{this.hospView()}
				{this.props.model ? this.dlgButtons() : ''}
			</Fragment>
		);
	}
}
