import React from 'react';
import * as misc from '../../lib/misc';
import * as whereBuilder from '../../lib/whereBuilder';
import Dialog from '../../tools/model-popup';
import SNFFilter from '../../core/components/searchBar/snf-filter-dialog';

class SnfPeerGroup extends React.Component {
	constructor(props) {
		super(props);
		this.onApply = this.props.updateBenchmark;
		this.tabChanged = this.tabChanged.bind(this);
		this.modalId = this.props.ModalId;
		this.updateBenchmark = this.updateBenchmark.bind(this);
		this.state = {
			selectedTab: 0,
			customGroup: [],
			customLabel: []
		};
		whereBuilder.storeDefaultWhere('snf');
	}

	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	}
	tabChanged(selectedTab) {
		let state = this.state;
		state.selectedTab = selectedTab;
		this.setState(state);
	}

	onValueChange(value, refKey, label) {
		let state = this.state;
		state.customGroup[refKey] = value;
		state.customLabel[refKey] = {
			labels: label,
			keys: Array.isArray(value) ? value : [value]
		};
		this.setState(state);
	}

	updateBenchmark(filter) {
		let state = this.state;
		// operator_key:
		// 1 >=,2 <=, 3 =, 4 <>, 5 in,
		// 6 notIn, 7 between, 8 notBtwn, 9 and, 10 or
		let customBenchFilter = [];
		for (let i = 0; i < filter.length; i++) {
			let f = filter[i];

			customBenchFilter.push({
				arguments: f.values,
				operator: 5,
				FieldName: f.key
			});
		}

		sessionStorage.setItem('customBenchmark', JSON.stringify(customBenchFilter));
		let group = '';
		// for (var item in this.state.customLabel) {
		// 	let itm = this.state.customLabel[item];
		// let itmKey =
		// 	optionSelect.indexOf(item) > -1
		// 		? Array(
		// 				itm.keys.map(a => {
		// 					return a.label;
		// 				})
		// 		  ).join(',')
		// 		: Array(itm.keys).join(',');
		// if (itm.keys.length > 0 && itmKey.length > 1) {
		// 	group += (group.length > 1 ? ' | ' : '') + itm.labels + ': ' + itmKey;
		// }
		// }
		sessionStorage.setItem('custom-peer-group-description', group);

		this.onApply();
	}

	//   updateBenchmark() {
	//     let state = this.state;
	//     // operator_key:
	//     // 1 >=,2 <=, 3 =, 4 <>, 5 in,
	//     // 6 notIn, 7 between, 8 notBtwn, 9 and, 10 or
	//     let customBenchFilter = [];
	//     let multiSelect = [
	//       "system_name",
	//       "provider_subtype",
	//       "snf_ownership_type",
	//       "state_name",
	//       "cbsa_name",
	//     ];
	//     for (let i = 0; i < multiSelect.length; i++) {
	//       let key = multiSelect[i];
	//       let token = state.customGroup[key];
	//       if (token && token.length > 0) {
	//         customBenchFilter.push({
	//           arguments: token,
	//           operator: 5,
	//           FieldName: key,
	//         });
	//       }
	//     }

	//     let optionSelect = [
	//       "net_patrev_cohort_id",
	//       "total_fte_on_payroll",
	//       "total_beds",
	//     ];
	//     for (let j = 0; j < optionSelect.length; j++) {
	//       let jkey = optionSelect[j];
	//       let token = state.customGroup[jkey];
	//       if (token && Array.isArray(token) && token.length > 0) {
	//         let cases = [];
	//         if (token.length == 1 && token[0].start && token[0].end) {
	//           customBenchFilter.push({
	//             arguments: [token[0].start, token[0].end],
	//             operator: 7,
	//             FieldName: jkey,
	//           });
	//         } else {
	//           for (let i = 0; i < token.length; i++) {
	//             if (token && token[i].start && token[i].end) {
	//               cases.push({
	//                 arguments: [token[i].start, token[i].end],
	//                 operator: 7,
	//                 FieldName: jkey,
	//               });
	//             }
	//           }
	//           customBenchFilter.push({ arguments: cases, operator: 10 });
	//         }
	//       } else if (token && token.start && token.end) {
	//         customBenchFilter.push({
	//           arguments: [token.start, token.end],
	//           operator: 7,
	//           FieldName: jkey,
	//         });
	//       }
	//     }
	//     sessionStorage.setItem(
	//       "customBenchmark",
	//       JSON.stringify(customBenchFilter)
	//     );
	//     let group = "";
	//     for (var item in this.state.customLabel) {
	//       let itm = this.state.customLabel[item];
	//       let itmKey =
	//         optionSelect.indexOf(item) > -1
	//           ? Array(
	//               itm.keys.map((a) => {
	//                 return a.label;
	//               })
	//             ).join(",")
	//           : Array(itm.keys).join(",");
	//       if (itm.keys.length > 0 && itmKey.length > 1) {
	//         group += (group.length > 1 ? " | " : "") + itm.labels + ": " + itmKey;
	//       }
	//     }
	//     sessionStorage.setItem("custom-peer-group-description", group);
	//     this.onApply();
	//   }

	render() {
		let customFilters = misc.getCustomBenchmark();
		let initFilters =
			customFilters && Array.isArray(customFilters)
				? customFilters.map(x => {
						return { key: x.FieldName, values: x.arguments };
				  })
				: [];
		let id = this.modalId || misc.Guid();

		return (
			<Dialog id={id} title="Customize Current Peer Group">
				<SNFFilter
					cols="2"
					model={true}
					onFilterChanged={this.updateBenchmark}
					initFilters={initFilters}
				/>
			</Dialog>
		);
	}
}
export default SnfPeerGroup;
