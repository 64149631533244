const defaultState = {
	dataset_details: [],
	error: null,
	fetching: false,
	fetched: false
};

export const archiveDatasetDetailsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'ARCHIVE_DATASET_DETAILS_FETCH_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				error: action.payload
			};
		}
		case 'ARCHIVE_DATASET_DETAILS_FETCH_PENDING': {
			return { ...state, fetching: true, fetched: false, dataset_details: [] };
		}
		case 'ARCHIVE_DATASET_DETAILS_FETCH_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				dataset_details: action.payload.data.result
			};
		}
		default: {
			break;
		}
	}
	return state;
};
