import React, {Fragment}  from 'react';
import { connect } from 'react-redux';
import * as MHGrid from '../datagrid';
import LoadingSpinner from '../loadingSpinner';
import * as misc from '../../lib/misc';
import NoData from '../../core/components/notifications/NoData';

class DataGridTabs extends React.Component {
	constructor(props) {
		super(props);
		this.SummaryCols = props.Cols;
		this.sections = props.Sections;
		this.getAddCompetitors = this.props.getCompetitors;
		this.FieldDesc = props.FieldDesc;
		this.suppressLines = props.SuppressLines ? props.SuppressLines : false;
	}

	render() {
		let data = this.props.data;
		let providers = misc.getFOIandCompetitors();
		
		if (!data) {
			return <LoadingSpinner />;
		} else {
			if (data && this.sections && this.sections.length) {
				let results = [];
				
				for (let i = 0; i < this.sections.length; i++) {
					let _title = this.sections[i].title;
					if (this.sections[i].callout) {
						_title = (
							<span className="text-nowrap">
								{this.sections[i].title}
								<small>
									<i
										className=" pl-2 MH-InfoTooltip fa fa-info-circle"
										aria-hidden="true"
										data-toggle="popover"
										data-trigger="hover"
										data-content={this.sections[i].callout}
									/>
								</small>
							</span>
						);
						if (i == 0) {
							results.push(
								<div class="text-left">
									<span class="text-nowrap h6 m-0">{_title}</span>
								</div>
							);
						}
					}
					if (i == 0) {
						results.push(
							<div className="sticky_table_header">
								<MHGrid.DataGrid
									suppressLines={this.suppressLines}
									fieldDef={this.FieldDesc}
									columns={this.SummaryCols}
									rows={data ? misc.filterMetrics(data, this.sections[i].fields) : []}
									showHeaders={true}
									headersOnly={true}
								/>
							</div>
						);
					}
					results.push(
						<MHGrid.DataGrid
							suppressLines={this.suppressLines}
							fieldDef={this.FieldDesc}
							columns={this.SummaryCols}
							rows={data ? misc.filterMetrics(data, this.sections[i].fields) : []}
							subcategory={_title}
							showHeaders={false}
						/>
					);
				}
				
				return (
					<div className="panel panel-default">
						{results}
						{this.props.children}
					</div>
				);
			} else {
				return <NoData />;
			}
		}
	}
}

export default DataGridTabs;
