import React from 'react';
import * as products from './products';

class CardHosp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: '',
      selected: '',
      price: ''
    }
    this.selectPlan = this.selectPlan.bind(this);
  }

  selectPlan = event => {
    event.preventDefault();
    let state = this.state;
    state.selected = event.target.name;
    state.plan = event.target.id;
    state.price = event.target.value;
    state.planName = products.hospital_access.fullName;
    if(this.props.onPlan) {
      this.props.onPlan(this.state);
    }
    this.setState(state);
  }

  render() {
    return(
      <div className="planCard hospital">
        <div className="header">
          <h3>Hospitals</h3>
          <p>Access all Medicare Hospital Data</p>
        </div>
        <div className="body">
          <h2><sup>$</sup>{products.hospital_access.price}</h2>
          <p>Per Year</p>
          <ul>
            <li>Access to all Tools and Reports for the Hospital Dataset</li>
            <li>Access to Data Explorer for the Hospital Data Set</li>
            <li>Downloads of Last 5 Years of Cost Reports in Excel Format</li>
          </ul>
        </div>
        <div className="foot">
          <button 
            type="button"
            name={products.hospital_access.name}
            id={products.hospital_access.id}
            value={products.hospital_access.price}
            onClick={this.selectPlan}
          >
            Purchase
          </button>
        </div>
      </div>
    )
  }
}

export default CardHosp;