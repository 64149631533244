import axios from 'axios';
import Auth from '../../core/Security/Auth';
import * as whereBuilder from '../../lib/whereBuilder';

import * as misc from '../../lib/misc';
const apiUrl = misc.apiUrl;

const getCustomHeader = function() {
	return {
		headers: { token: Auth.getToken() }
	};
};
export function fetchQualityRating(providerNumber) {
	return {
		type: 'QUALITYRATING_FETCH',
		payload: axios.post(
			apiUrl + '/qualityrating',
			{ facilityId: providerNumber },
			getCustomHeader()
		)
	};
}

export function fetchAcuteOccupancy(providerNumber) {
	let filterwhere = JSON.parse(sessionStorage.getItem('where'));
	return {
		type: 'ACUTE_OCCUPANCY_FETCH',
		payload: axios.post(
			apiUrl + '/acute-occupancy',
			{ providers: providerNumber, filter: filterwhere },
			getCustomHeader()
		)
	};
}

export function fetchBenchmarkReportNationalMultiyear(facilityId) {
	whereBuilder.GetLatest5Yrs();
	return {
		type: 'BENCHMARKREPORTNATIONAL_MULTIYEAR_FETCH',
		payload: axios.post(
			apiUrl + '/benchmarkreportnationalmultiyear',
			{ facilityId, years: whereBuilder.GetLatest5Yrs() },
			getCustomHeader()
		)
	};
}

export function fetchBenchmarkReportFacilityMultiyear(facilityId) {
	return {
		type: 'BENCHMARKREPORTFACILITY_MULTIYEAR_FETCH',
		payload: axios.post(
			apiUrl + '/benchmarkreportfacilitymultiyear',
			{ facilityId, years: whereBuilder.GetLatest5Yrs() },
			getCustomHeader()
		)
	};
}
