import React, { Component, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import * as ApiUtil from '../../lib/ApiUtil';
import AuthUser from '../Security/Auth';

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "rgba(12,89,145,1)",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      border: "1px solid #f00",
      ":-webkit-autofill": {
        color: "#fce883"
      },
      "::placeholder": {
        color: "rgba(12,89,145,1)"
      }
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee"
    }
  }
};

const CardField = ({ onChange }) => (
  <div id="cardField">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  faCode,
  onChange
}) => (
  <div>
    <label htmlFor={id} className="FormRowLabel">
      <i className={'fas '+faCode}></i>
    </label>
    <input
      className="loginInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const ConfirmButton = ({ processing, disabled }) => (
  <input 
    type="submit" 
    id="sub" 
    className="loginBttn"
    name="submit" 
    value="Confirm"
    disabled={processing || disabled}
  />
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [checkoutError, setCheckoutError] = useState();
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    email: props.regInfo.email,
    phone: props.regInfo.phone,
    name: props.regInfo.username
  });
  const [currentPlan] = useState(props.currentPlan);
  const [isChecked, setIsChecked] = useState(true);
  const repeatCheck = (e) => {
    if(e.target.checked === true) {
      setBillingDetails({
        email: props.regInfo.email,
        phone: props.regInfo.phone,
        name: props.regInfo.firstName + ' ' + props.regInfo.lastName
      });
    } else {
      setBillingDetails({
        email: "",
        phone: "",
        name: ""
      });
    }
  }

  const handleConfirm = async (event) => {
    event.preventDefault();
    try
    {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      if (cardComplete) {
        setProcessing(true);
      }
      
      const cardElement = elements.getElement("card");
      const result = await ApiUtil.PayPost('/payment-intent', { product: currentPlan })
      
      let intent = null;
      if(result && result.data && result.data.result && result.data.result.intent)
      {
        intent = result.data.result.intent;
      }
      else
      {
          throw new Error('Failed to init Credit Card Payment.');
      }

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: billingDetails
      });

      if(payload && payload.error) {
        elements.getElement("card").focus();
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return; 
      }

      const { error } = await stripe.confirmCardPayment(intent, {
        payment_method: payload.paymentMethod.id
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }    
      setPaymentMethod(payload.paymentMethod);
      await AuthUser.resetSession();
    }
    catch(err)
    {
      setCheckoutError(error.message);
      alert(err.message);
    }
  }

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: "",
      phone: "",
      name: ""
    });
    setIsChecked(false);
  };

  return paymentMethod ? (
    <div id="conPay">
      <h5>Order Confirmation</h5>
      <p>Thank you for your purchase. Your <strong>{props.currentPlan.planName} license</strong> is now active and you are logged in. You can begin searching metrics and creating reports immediately.<br /> You can login at any time using the email: <strong>{props.regInfo.email}</strong> and the password you provided.</p>
      <p>Here is your order confirmation details:</p>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">User: <span>{props.regInfo.username}</span></li>
        <li className="list-group-item">Email: <span>{props.regInfo.email}</span></li>
        <li className="list-group-item">Phone: <span>{props.regInfo.phone}</span></li>
        <li className="list-group-item">Company: <span>{props.regInfo.company}</span></li>
        <li className="list-group-item">Plan: <span>{props.currentPlan.plan}</span></li>
        <li className="list-group-item">Price: <span>${props.currentPlan.price}</span></li>
      </ul>
      <div className="col-12 text-center">
        <a href="/"><h4 className="loginBttn" id="homeBttn">Return Home</h4></a>
      </div>
    </div>
  ) : ( 
    <>  
      <h4>Payment Information</h4>
      <form className="Form inputFrame" onSubmit={handleConfirm}>
        <div id="sameInfo">
          <input
            className="repeatInfo"
            type="checkbox"
            onChange={(e) => {
              setIsChecked(e.currentTarget.checked);
              repeatCheck(e);
            }}
            checked={isChecked}
          />
          <p>Same as Registration Information</p>
        </div>
        <fieldset className="FormGroup">
          <Field
            label="Name"
            faCode="fa-user"
            id="name"
            type="text"
            placeholder="Name on Card"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value });
            }}
          />
          <Field
            label="Email"
            faCode="fa-at"
            id="email"
            type="email"
            placeholder="cardholder@email.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, email: e.target.value });
            }}
          />
          <Field
            label="Phone"
            faCode="fa-phone-alt"
            id="phone"
            type="tel"
            placeholder="(555) 867-5309"
            required
            autoComplete="tel"
            value={billingDetails.phone}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, phone: e.target.value });
            }}
          />
        </fieldset>
        <fieldset className="FormGroup">
          <CardField
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </fieldset>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <ConfirmButton />
        {checkoutError && <span style={{ color: "red" }}>{checkoutError}</span>}
      </form>
    </>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
    }
  ]
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLIC_KEY}`);

const CardInput = (props) => {
  return (
    <div id="payInfo" >
      <div className="AppWrapper" id="loginFrame">
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm currentPlan={props.currentPlan} regInfo={props.regInfo} />
        </Elements>
      </div>
    </div>    
  );
};

export default(CardInput);