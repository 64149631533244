import * as Types from '../../core/actions/ActionTypes';
const defaultState = {
	balanceSheet: [],
	error: null,
	fetching: false,
	fetched: false,
	whereChanged: false
};

export const balanceSheetReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'BALANCESHEET_FETCH_PENDING': {
			return { ...state, fetching: true, whereChanged: false };
		}
		case 'BALANCESHEET_FETCH_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				whereChanged: false,
				balanceSheet: action.payload.data
			};
		}
		case 'BALANCESHEET_FETCH_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				whereChanged: false,
				error: action.payload
			};
		}
		case 'SESSION_STORAGE_WHERE_CHANGE':
		case 'USER_LOGIN_FETCH_FULFILLED':
		case Types.ASSIGNFACILITY_COMPLETE: {
			return {
				...state,
				whereChanged: true,
				fetching: false,
				fetched: false,
				balanceSheet: []
			};
		}
		default: {
			break;
		}
	}
	return state;
};
