import React, { Component } from "react";
import LoadingSpinner from '../../tools/loadingSpinner';
import SignIn from "./SignIn";
import Welcome from "./Welcome";
import { Auth } from "aws-amplify";
import AuthUser from "../Security/Auth";

export default class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      email: null,
      phone_number: null,
      user: null,
      status: null,
      stage: null
    };
  }
  async checkLoginStatus() {
    let user = await AuthUser.getAuthUser();
    let userCreds = await AuthUser.getUserCredentials();
    let sessionUser = AuthUser.isUserAuthenticated();
    if(sessionUser === true) {
      this.setState( {
        username: user.attributes.email,
        email: user.attributes.email,
        phone_number: user.attributes.phone,
        user: user,
        status: "Welcome"
      });
    } else if(localStorage.getItem('username')) {
      this.setState({
        username: localStorage.getItem('username'),
        email: "",
        password: "",
        phone_number: "",
        code: "",
        user: null,
        status: "SignIn"
      });
    } else {
      this.setState( {
        username: "",
        email: "",
        password: "",
        phone_number: "",
        code: "",
        user: null,
        status: "SignIn"
      });
    }
  }
  UNSAFE_componentWillMount() {
    this.checkLoginStatus();
	}
  componentDidMount() {
		document.body.id = 'login';
	}
  componentWillUnmount() {
		document.body.id = '';
	}
  // Handle changes to form inputs on sign-in
  handleFormInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  AuthComponent = () => {
    switch (this.state.status) {
      case "Welcome":
        return (
          <div id="content">
            <Welcome
              {...this.state}
            />
          </div>
        );
        break;
      case 'SignIn':
        return (
          <div id="content">
            <SignIn
              username={this.state.username}
              switchComponent={this.switchComponent}
              handleFormInput={this.handleFormInput}
              inputs={this.state}
            />
          </div>
        );
        break;
      default:
        return(
          <LoadingSpinner />
        );
        break;
    }
  };
  switchComponent = status => {
    this.setState({ status });
  };
  render() {
    return <>{this.AuthComponent()}</>;
  }
}