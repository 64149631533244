import React from 'react';
import * as MHGrid from '../datagrid';
import LoadingSpinner from '../loadingSpinner';
import * as misc from '../../lib/misc';
import NoData from '../../core/components/notifications/NoData';

class DataGridTabBody extends React.Component {
	constructor(props) {
		super(props);
		this.tabDef = props.tabDef;
		this.state = { selectedTab: 0 };
	}

	tabChanged(selectedTab) {
		let state = this.state;
		state.selectedTab = selectedTab;
		this.setState(state);
	}

	customGrid() {
		return this.props.GridControl || null;
	}

	pillTabs() {
		return this.tabDef.map(({ id, title } = {}) => (
			<li
				className={
					this.state.selectedTab === id
						? 'react-tabs__tab react-tabs__tab--selected  d-block'
						: 'react-tabs__tab d-block'
				}
			>
				<a className="d-block" onClick={() => this.tabChanged(id)}>
					{title.toString()}
				</a>
			</li>
		));
	}

	toggleTabs() {
		return this.tabDef.map(({ id, title } = {}) => (
			<li
				className={
					this.state.selectedTab === id
						? 'react-tabs__tab react-tabs__tab--selected'
						: 'react-tabs__tab'
				}
				onClick={() => this.tabChanged(id)}
			>
				<a data-toggle="tab" className="mx-3">
					{title.toString()}
				</a>
			</li>
		));
	}

	panelTab() {
		return this.tabDef.map(({ id, title } = {}) => (
			<div className={this.state.selectedTab === id ? 'tab-pane active' : 'tab-pane'}>
				{(this.tabDef[id] && this.tabDef[id].component) || null}
			</div>
		));
	}

	tabBody() {
		return (
			<div className="col-12">
				<div className="dropdown show d-flex col mx-auto d-md-none react-tabs__tab-list">
					<a
						className="react-tabs__tab react-tabs__tab--selected"
						href="#"
						role="button"
						id="dropdownMenuLink"
						data-toggle="dropdown"
					>
						{this.tabDef[this.state.selectedTab].title.toString()}
					</a>

					<div className="dropdown-menu text-center mx-auto" aria-labelledby="dropdownMenuLink">
						<ul className="nav nav-stacked d-block d-md-none">{this.pillTabs()}</ul>
					</div>
				</div>
				<ul
					className="d-none d-md-flex px-0 col-md-12 mx-auto react-tabs__tab-list mt-1"
					role="tablist"
				>
					{this.toggleTabs()}
					<div className="ml-auto mr-0">
						<div className="pull-right">{this.customGrid()}</div>
					</div>
				</ul>
				<div className="tab-content p-0 col-md-12">{this.panelTab()}</div>
			</div>
		);
	}

	render() {
		return this.tabBody();
	}
}

export default DataGridTabBody;
