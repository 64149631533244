import React from 'react';
import FieldDlg from '../../components/searchBar/filter-dialog';
import * as Map from '../../../tools/map';
import * as misc from '../../../lib/misc';
import Accordion from '../../../tools/ui-templates/accordion';
import CheckboxList from '../../../tools/ui-templates/checkbox-list';
import * as api from '../../../lib/ApiUtil';
import TTState from './tooltip-state';
const RenderPopup = x => {
	return;
	`
		<div class="popup-content">
			<b>${x.state + '-' + x.state_name || ''}</b>
		</div>	
		`;
};

export default class MapExplorer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			key: misc.Guid(),
			mapkey: misc.Guid(),
			prvdr_key: misc.Guid(),
			providers: [],
			shapes: [],
			markers: [],
			hosp: true,
			snf: true
		};

		this.onProviderChange = this.onProviderChange.bind(this);
		this.onFilterChanged = this.onFilterChanged.bind(this);
		this.onDomainChanged = this.onDomainChanged.bind(this);
	}

	loadStateCoordinates() {
		let filters = [];
		api
			.APIPost('/search-states', {
				filters: filters
			})
			.then(
				data => {
					let state = this.state;
					let states = data.data.result;
					let Shapes = [];
					let markers = [];
					if (states && Array.isArray(states)) {
						states.forEach(x => {
							let obj = new Map.MapGeoObject(
								x.geom ? JSON.parse(x.geom) : {},
								x.state,
								x.state_name,
								'#d4eff7',
								RenderPopup(x)
							);
							Shapes.push(obj);
						});
						states.forEach(x => {
							let Marker = new Map.MapMarker();
							Marker.content = <TTState state_name={x.state_name} state={x.state} />;
							Marker.lat = x.lat;
							Marker.lng = x.long;
							Marker.icon = Map.MarkerDivIcon('fa fa-map-marker', 'fa-lg', '#304771');
							markers.push(Marker);
						});
					}
					state.shapes = Shapes;
					state.markers = markers;

					state.mapkey = misc.Guid();
					this.setState(state);
				},
				err => {}
			);
	}

	UNSAFE_componentWillMount() {
		this.loadStateCoordinates();
	}

	onDomainChanged(hosp, snf, hha) {
		let state = this.state;
		state.hosp = hosp;
		state.snf = snf;
		state.hha = hha;
		this.setState(state);
	}

	onFilterChanged(filters) {
		api
			.APIPost('/search-facilities', {
				filters: filters,
				hosp: this.state.hosp,
				snf: this.state.snf
			})
			.then(
				data => {
					let state = this.state;
					state.providers = data.data.result;
					state.prvdr_key = misc.Guid();
					this.setState(state);
				},
				err => {}
			);
	}

	onProviderChange(values) {}

	buildRightPanel() {
		let Headers = ['Providers', 'Metrics'];
		let Content = [];

		const Prvdr_List = (
			<CheckboxList
				data={this.state.providers}
				onChange={this.onProviderChange}
				value="prvdr_num"
				label="fac_name"
			/>
		);

		Content.push(Prvdr_List);
		Content.push(<div>Metrics</div>);

		return <Accordion key={this.state.prvdr_key} headers={Headers} content={Content} />;
	}
	render() {
		let center = [37.0902, 95.7129];
		let markers = [];
		let geoShapes = [];

		return (
			<div className="px-3">
				<div className="row">
					<div
						className="col-3 p-0 pl-2"
						style={{
							'max-height': '685px',
							'overflow-y': 'auto'
						}}
					>
						<FieldDlg
							className=""
							onFilterChanged={this.onFilterChanged}
							onDomainChanged={this.onDomainChanged}
						/>
					</div>
					<div className="col-7 p-0" style={{}}>
						<Map.DataMap
							key={this.state.mapkey}
							center={center}
							markers={this.state.markers}
							zoomControl={true}
							scrollWheelZoom={false}
							doubleClickZoom={true}
							dragging={true}
							zoom={4}
							height={'100vh'}
							geoShapes={this.state.shapes}
						/>
					</div>
					<div className="col-2 p-0 " style={{}}>
						{this.buildRightPanel()}
					</div>
				</div>
			</div>
		);
	}
}
