import { icon } from '@fortawesome/fontawesome';
import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

class LocationMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lat: props.lat || 28.01097, // this.props.lat,,
			lng: props.lng || -82.70793, //this.props.lng,
			zoom: 16
		};
	}

	render() {
		// const image = Leaflet.Icon({
		//                iconUrl: '/images/hospital-icon.png',
		//                iconSize:     [17, 20], // size of the icon
		//                iconAnchor:   [22, 26], // point of the icon which will correspond to marker's location
		//            });

		const metIcon = L.icon({
			iconUrl: '/images/red.png',
			iconSize: [40, 40],
			iconAnchor: [7, 55]
		});
		const position = [this.state.lat, this.state.lng];
		// console.log(this.props);
		return (
			<Map
				center={position}
				zoom={16}
				zoomControl={true}
				scrollWheelZoom={false}
				doubleClickZoom={true}
				dragging={true}
				style={{ height: '360px', width: '100%' }}
			>
				<TileLayer
					attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OSM</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
					url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_labels_under/{z}/{x}/{y}.png"
				/>
				<Marker position={position} icon={metIcon}>
					<Popup>
						<a href="/">{this.props.facilityName}<br/></a>
						<span>{this.props.facilityAddress}<br/></span>
						<span>{this.props.facilityCity}, {this.props.facilityState} {this.props.facilityZip}</span>
					</Popup>
				</Marker>
			</Map>
		);
	}
}

export default LocationMap;
