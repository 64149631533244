export const PopulationMetrics = [
	'total_population',
	'over_65_population',
	'per_over_65_population',
	'total_male_population',
	'per_male_population',
	'total_female_population',
	'per_female_population',
	'below_poverty'
];

export const InsuredMetrics = [
	'insured_under_65',
	'uninsured_under_65',
	'insured_medicare_eligible',
	'uninsured_medicare_eligible',
	'one_source_coverage',
	'two_source_coverage',
	'one_source_employer_ins',
	'one_source_direct_ins',
	'one_source_medicare_ins',
	'one_source_medicaid_ins',
	'one_source_tricare_ins',
	'one_source_va_ins',
	'two_source_direct_ins',
	'two_source_medicare_ins',
	'two_source_medicaid_ins',
	'two_source_other_private_ins',
	'two_source_other_public_ins',
	'two_source_other_ins'
];

export const DemographicsMetrics = () => {
	let list = [];
	list = list.concat(PopulationMetrics);
	list = list.concat(InsuredMetrics);
	return list;
};

export const All = () => {
	let list = [];
	list = list.concat(DemographicsMetrics());
	return list;
};

export const Info = () => {
	return {
		topic: 'Demographics',
		groups: [
			{
				key: 'Demographics',
				label: 'Demographics',
				fields: DemographicsMetrics()
			}
		]
	};
};
