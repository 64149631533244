import React, { Component } from "react";
import '../../styles.css';
import { Auth } from "aws-amplify";

class Confirm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      error: ''
    }
  }
  componentDidMount() {
    let state = this.state,
    winH = window.innerHeight,
    bodyH = document.getElementById('root').clientHeight;
    const username = this.props.regInfo.email;
    const password = this.props.regInfo.password;
    Auth.signIn({ username, password })
      .then(user => {
        sessionStorage.setItem('authUser', user);
        state.error = '';
        // window.location.href = '/';
      })
      .catch(err => {
        // console.log(err);
        state.error = err.message;
      });
    this.setState(state);
    if(winH > bodyH) {
      let diffH = winH -bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
    // console.log(this.props);
  };
  render() {
    return (
      <section id="content">
        <div className="container" id="confirm">
          <div className="row">
            <div className="col-12 text-center">
              {this.props.currentPlan.promo ?
                <>
                  <h1>Congratulations {this.props.regInfo.firstName}</h1>
                  <p>You have successfully activated your <strong>{this.props.currentPlan.description} Promotional License</strong>.<br />Your <strong>{this.props.currentPlan.plan === 'SNF' ? 'Skilled Nursing Facility' : 'Premium'} Promotional License</strong> is valid until: <strong>{this.props.currentPlan.expDate}</strong></p>
                  <p>You are logged in and can begin looking up metrics and creating reports immediately.</p>
                  <p>You can login at anytime with the Username: <strong>{this.props.regInfo.email}</strong></p>
                </>
              : ''}
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <a href="/"><h4 className="loginBttn">Return Home</h4></a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default(Confirm);