import React, { Component } from "react";
import react from 'react';
import { Auth } from "aws-amplify";
import zxcvbn from "zxcvbn";
import '../../styles.css';
import StateManager from "react-select";
import * as UserUtil from '../../lib/UserUtil';

export default class SignIn extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stage: 1,
      checked: null,
      value: true,
      type: 'password',
      code: '',
      userObj: {},
      password: '',
      conPass: '',
      error: '',
      errorCode: '',
      errorPass: '',
      errorCon: ''
    };
    this.handleRemChange = this.handleRemChange.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.getVerifyCode = this.getVerifyCode.bind(this);
    this.showHide = this.showHide.bind(this);
    this.subNewPass = this.subNewPass.bind(this);
    this.completePassword = this.completePassword.bind(this);
    this.handleVerifyEmail = this.handleVerifyEmail.bind(this);
  }

  componentDidMount() {
    let winH = window.innerHeight;
    let bodyH = document.getElementById('root').clientHeight;
    if(winH > bodyH) {
      let diffH = winH - bodyH;
      document.getElementById('content').style.paddingBottom = diffH+'px';
    }
    if(localStorage.getItem('username')) {
      let state = this.state;
      state.checked = true;
      this.setState(state);
    }
  };

  handleRemChange = () => {
    let state = this.state;
    state.checked = !this.state.checked;
    this.setState(state);
    if(this.state.checked === true && this.props.username) {
      localStorage.setItem('username', this.props.username);
    } else {
      localStorage.clear('username');
    }
  };

  handleVerifyEmail = async e => {
    // debugger;
    let result = await UserUtil.confirmRegistration({ "userId": this.props.username, "key": this.state.code}).then(
      data => {      
        sessionStorage.setItem('authUser', user);
        window.location.href = '/';
      }
      ,err => {}
    );
  }
  
  handleSignIn = e => {
    e.preventDefault();
    let state = this.state;
    const username = this.props.username;
    const password = this.state.password;
    let remUser = localStorage.getItem('username');
    state.value = true;
    this.setState(state);
    if(state.checked === true && remUser !== username) {
      localStorage.setItem('username', username);
    }
    // You can pass an object which has the username, password and validationData which is sent to a PreAuthentication Lambda trigger
    Auth.signIn({ username, password })
      .then(user => {
        // debugger;
        if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          state.stage = 4;
          state.password = '';
          state.code = true;
          state.userObj = user;
          this.setState(state);
        } else {
          sessionStorage.setItem('authUser', user);
          if(sessionStorage.getItem('report')) {
            window.location.href = '/reports';
          } else {
            window.location.href = '/';
          }
        }
      },
      err => {
        debugger;
        if(err.code == 'UserNotConfirmedException')
        {
          UserUtil.sendConfirmationCode({email: this.props.username });
          state.stage = 5;
          state.code = true;
          state.userObj = user;
        } else {
          state.error = err.message;
          state.value = false;
          state.password = '';
        }
        this.setState(state);
      }).catch(err => {
        // debugger;
        state.error = err.message;
        state.value = false;
        state.password = '';
        state.conPass = '';
        this.setState(state);
      });
  };
  
  forgotPass = e => {
    e.preventDefault();
    let state = this.state;
    state.error = '';
    state.stage = 2;
    state.value = false;
    this.setState(state);
  };
  
  getVerifyCode = e => {
    e.preventDefault();
    let username = this.props.username;
    let state = this.state;
    state.value = true;
    this.setState(state);
    Auth.forgotPassword(username)
      .then(data => {
        if(state.stage === 3) {
          state.error = 'We just sent you a new code.';
        } else {
          state.stage = 3;
        }
        this.setState(state);
      })
      .catch(err => {
        state.error = err.message;
        state.value = false;
        this.setState(state);
      });
  };

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    let state = this.state;
    switch (name) {
      case 'code':
        state.errorCode =
          value.length !== 6
            ? 'Valid code is required'
            : '';
          state.code = e.target.value;
        break;
      case 'password':
        state.password = e.target.value;
        if(state.password.length >= 8) {
          state.value = false;
        } else {
          state.value = true;
        }
        break;
      case 'newPass':
        state.errorPass =
          value.length < 8
            ? 'Password must be at least 8 characters'
            : '';
          state.password = e.target.value;
        if(state.conPass && state.conPass !== state.password) {
          state.errorCon = 'Passwords must match';
        } else {
          state.errorCon = '';
        }
        break;
      case 'conPass':
        if(value === state.password) {
          state.errorCon = '';
        } else {
          state.errorCon = 'Passwords must match';
          state.value = true;
        }
        state.conPass = e.target.value;
        break;
      default:
        break;
    }
    if(state.stage >= 3 && state.code && !state.errorCode && state.password && !state.errorPass && state.conPass && !state.errorCon) {
      state.value = false;
    }
    this.setState(state);
  };

  passScoreLabel = result => {
    switch (result.score) {
      case 0:
        return 'N/A';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  }

  showHide = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password'
    })
  };

  subNewPass = e => {
    e.preventDefault();
    let state = this.state;
    let username = this.props.username;
    let code = state.code;
    let new_password = state.password;
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then(data => {
        state.stage = 1;
        this.setState(state);
      })
      .catch(err => {
        state.error = err.message;
        this.setState(state);
      });
  };

  completePassword = e => {
    e.preventDefault();
    let state = this.state;
    const username = this.props.username;
    const password = this.state.password;
    state.value = true;
    this.setState(state);

    Auth.completeNewPassword( state.userObj, password, {email: username} )
      .then(user => {
        sessionStorage.setItem('authUser', user);
        window.location.href = '/';
      })
      .catch(err => {
        state.error = err.message;
        state.value = true;
        state.password = '';
        state.conPass = '';
        this.setState(state);
      });
  };

  render() {
    const testedResult = zxcvbn(this.state.password);

    return (
      <>
        <div id="loginFrame">
          <div id="placeholder"></div>
          {this.state.stage === 1 && (
            <form className="authentication__form" onSubmit={this.handleSignIn}>
              <div className="inputFrame">
                <h1>Login</h1>
                <div>
                  <label htmlFor="usr"><i className="fas fa-at"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="usr" 
                    name="username" 
                    placeholder="Username"
                    value={this.props.username}
                    onChange={this.props.handleFormInput}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="pwd"><i className="fas fa-unlock-alt"></i></label>
                  <input 
                    type="password" 
                    className="loginInput" 
                    id="pwd" 
                    name="password" 
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div id="remember">
                  <label htmlFor="rem">Remember Me</label> 
                  <input 
                    type="checkbox" 
                    id="rem" 
                    name="rem"
                    checked={this.state.checked}
                    onChange={this.handleRemChange} 
                  />
                </div>
                {this.state.error && <p className="error text-center">{this.state.error}</p>}
                <input 
                  type="submit" 
                  className="loginBttn"
                  name="submit" 
                  value="Login" 
                  disabled={this.state.value}
                />
                <input 
                  type="submit" 
                  className="forgot" 
                  name="forgot" 
                  value="Forgot Password" 
                  onClick={this.forgotPass}
                />
              </div>
            </form>
          )}
          {this.state.stage === 2 && (
            <form className="authentication__form" onSubmit={this.getVerifyCode}>
              <div className="inputFrame">
                <h2>Forgot Password?</h2>
                <p>Please verify the email address so we can send you a verification code.</p>
                <div>
                  <label htmlFor="usr"><i className="fas fa-user"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="usrForgot" 
                    name="username" 
                    placeholder="@Email"
                    value={this.props.username}
                    onChange={this.props.handleFormInput}
                    required
                  />
                </div>
                {this.state.error && <p className="error text-center">{this.state.error}</p>}
                <input 
                  type="submit" 
                  className="loginBttn" 
                  name="submitCode" 
                  value="Get Code" 
                  disabled={this.state.value}
                />
                <input 
                  type="submit" 
                  className="forgot"  
                  name="backLogin" 
                  value="Back to Login" 
                  onClick={() => this.setState({
                    stage: 1,
                    value: true
                  })}
                />
              </div>
            </form>
          )}
          {this.state.stage === 3 && (
            <form className="authentication__form" onSubmit={this.subNewPass}>
              <div className="inputFrame">
                <h2>Reset Password</h2>
                <p>Check your email for the verification code. Enter it and the New Password.</p>
                <div>
                  <label htmlFor="pwd"><i className="fas fa-fingerprint"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="code" 
                    name="code" 
                    placeholder="Verification Code"
                    onChange={this.handleChange}
                    required
                  />
                  {this.state.errorCode.length > 0 && <p className="error passError">{this.state.errorCode}</p>}
                </div>
                <div>
                  <div>
                    <label htmlFor="pwd"><i className="fas fa-unlock-alt"></i></label>
                    <input 
                      type={this.state.type} 
                      className="loginInput" 
                      id="newPass" 
                      name="newPass" 
                      placeholder="Password"
                      autoComplete="off"
                      value={this.state.password}
                      onChange={this.handleChange}
                      required
                    />
                    <span 
                      className="showHide" 
                      onClick={this.showHide}
                    >
                      {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
                    </span>
                    {this.state.errorPass.length > 0 && <p className="error passError">{this.state.errorPass}</p>}
                  </div>
                  <div>
                    <label htmlFor="conPass"><i className="fas fa-lock"></i></label>
                    <input 
                      type={this.state.type} 
                      className="loginInput" 
                      id="conPass" 
                      name="conPass" 
                      placeholder="Confirm Password"
                      value={this.state.conPass}
                      onChange={this.handleChange}
                      required
                    />
                    <span 
                      className="showHide" 
                      onClick={this.showHide}
                    >
                      {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
                    </span>

                    <label className="passStrength" id="newPass">
                      {this.state.password && (
                        <>
                          <progress
                            className={`passScore score-${this.passScoreLabel(testedResult)}`}
                            value={testedResult.score}
                            max="4"
                          />
                          <span><strong>Password strength:</strong> {this.passScoreLabel(testedResult)}</span>
                        </>
                      )}
                    </label>
                    
                    {this.state.errorCon.length > 0 && <p className="error passError">{this.state.errorCon}</p>}
                  </div>
                </div>
                {this.state.error && <p className="error text-center">{this.state.error}</p>}
                <input 
                  type="submit" 
                  className="loginBttn" 
                  name="subCode" 
                  value="Reset Password" 
                  disabled={this.state.value}
                />
                <input 
                  type="submit" 
                  className="forgot"  
                  name="backLogin" 
                  value="Get Another Code" 
                  onClick={this.getVerifyCode}
                />
              </div>
            </form>
          )}
          {this.state.stage === 4 && (
            <form className="authentication__form" onSubmit={this.completePassword}>
              <div className="inputFrame">
                  <h4>Password Change Required</h4>
                  <p>Welcome <span>{this.props.username}</span>, our records indicate that your password needs to be reset. Passwords can be whatever you like but they must contain a minimal of 8 characters.</p>
                  <div>
                    <div>
                      <label htmlFor="pwd"><i className="fas fa-unlock-alt"></i></label>
                      <input 
                        type={this.state.type} 
                        className="loginInput" 
                        id="newPass" 
                        name="newPass" 
                        placeholder="New Password"
                        autoComplete="off"
                        value={this.state.password}
                        onChange={this.handleChange}
                        required
                      />
                      <span 
                        className="showHide" 
                        onClick={this.showHide}
                      >
                        {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
                      </span>
                      {this.state.errorPass.length > 0 && <p className="error passError">{this.state.errorPass}</p>}
                    </div>
                    <div>
                      <label htmlFor="conPass"><i className="fas fa-lock"></i></label>
                      <input 
                        type={this.state.type} 
                        className="loginInput" 
                        id="conPass" 
                        name="conPass" 
                        placeholder="Confirm Password"
                        value={this.state.conPass}
                        onChange={this.handleChange}
                        required
                      />
                      <span 
                        className="showHide" 
                        onClick={this.showHide}
                      >
                        {this.state.type === 'password' ? <i className="fas fa-eye" title="Show Password"></i> : <i className="fas fa-eye-slash" title="Hide Password"></i>}
                      </span>
                      <label className="passStrength" id="newPass">
                        {this.state.password && (
                          <>
                            <progress
                              className={`passScore score-${this.passScoreLabel(testedResult)}`}
                              value={testedResult.score}
                              max="4"
                            />
                            <span><strong>Password strength:</strong> {this.passScoreLabel(testedResult)}</span>
                          </>
                        )}
                      </label>
                      {this.state.errorCon.length > 0 && <p className="error passError">{this.state.errorCon}</p>}
                    </div>
                  </div>
                  {this.state.error && <p className="error text-center">{this.state.error}</p>}
                  <input 
                    type="submit" 
                    className="loginBttn" 
                    name="subCode" 
                    value="Change Password" 
                    disabled={this.state.value}
                  />
                </div>
            </form>
          )}
          {this.state.stage === 5 && (
            <form className="authentication__form" onSubmit={this.handleVerifyEmail}>
              <div className="inputFrame">
                <h2>Confirm Email Address</h2>
                <p>We have sent a verification code to the email:<br /><strong>{this.props.username}</strong></p>
                <p>Please enter the code below and submit to verify your email address.</p>
                <div>
                  <label htmlFor="pwd"><i className="fas fa-fingerprint"></i></label>
                  <input
                    type="text"
                    className="loginInput" 
                    id="code" 
                    name="code" 
                    placeholder="Verification Code"
                    onChange={this.handleChange}
                    required
                  />
                  {this.state.errorCode.length > 0 && <p className="error passError">{this.state.errorCode}</p>}
                </div>                
                <input 
                  type="submit" 
                  className="loginBttn" 
                  name="getCode" 
                  value="Submit Code" 
                  disabled={this.state.value}
                />
                <input 
                  type="submit" 
                  className="forgot"  
                  name="backLogin" 
                  value="Back to Login" 
                  onClick={() => this.setState({
                    stage: 1,
                    value: true
                  })}
                />
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
}