import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MHGrid from '../../../tools/datagrid';
import LoadingSpinner from '../../../tools/loadingSpinner';
import ReChartTool from '../../../tools/charts/ReChartTool';
import _ from 'lodash';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import * as misc from '../../../lib/misc';
import { loadMarketShare as load } from './MarketDataAction';
import * as Map from '../../../tools/map';
import { LegendItem, Legend } from '../../../tools/charts/legend';
import * as format from '../../../lib/FormatUtil';
import InfoPopup from '../../../tools/info-popup';
import * as callouts from './MarketCallouts';
import DataGridFooterNotation from '../../../tools/datagrid/extras/DataGridFooterNotation';

const BuildInitForProvider = name => {
	let initials = name.match(/\b\w/g) || [];
	initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

	return initials;
};

const RenderPSAMarketBasedMedicareCasesChart = (provider, data) => {
	let ChartData = [];

	if (data && data.PSAMarketBasedMedCases) {
		ChartData = data.PSAMarketBasedMedCases.map(x => {
			return { year: x.year, marketShare: misc.round(x.marketShare) };
		});
	}
	const Series = [
		{
			xAxis: 'year',
			xLabel: '',
			yLabel: 'Market Share',
			type: 'Bar',
			barSize: 25,
			color: '#5caec5',
			name: 'marketShare',
			display: '% of Total Medicare Cases'
		}
	];
	const title = new MHGrid.DataGridTitle(
		provider.name,
		'Proportion of PSA Market-Based Medicare Cases',
		'',
		5
	);
	return (
		<ReChartTool
			title={title.render()}
			height={300}
			series={Series}
			data={ChartData}
			YAxis={'percent'}
			ShowTooltip={true}
			ShowLegend={true}
		/>
	);
};

const RenderPSAMarketBasedMedicareALOSChart = (provider, data) => {
	let ChartData = [];

	if (data && data.PSAMarketBasedMedCases) {
		ChartData = data.PSAMarketBasedMedCases.map(x => {
			return {
				year: x.year,
				facility_psa_alos:
					misc.round(x.facility_psa_alos) > 50 ? null : misc.round(x.facility_psa_alos),
				market_psa_alos: misc.round(x.market_psa_alos)
			};
		});
	}
	const title = new MHGrid.DataGridTitle(
		'Average Length of Stay Over Time',
		provider.name + ' Relative to PSA Market',
		'',
		5
	);

	const Series = [
		{
			xAxis: 'year',
			xLabel: '',
			yLabel: '% Change',
			color: '#56AADB',
			type: 'Bar',
			name: 'facility_psa_alos',
			display: provider.name + ' ALOS'
		},
		{
			xAxis: 'year',
			type: 'Bar',
			color: '#F1AB3F',
			name: 'market_psa_alos',
			display: 'Market ALOS'
		}
	];

	return (
		<ReChartTool
			title={title.render()}
			height={300}
			series={Series}
			data={ChartData}
			YAxis={'number'}
			ShowTooltip={true}
			ShowLegend={true}
		/>
	);
};

const RenderCompetitorsTable = (year, provider, data) => {
	let facilityInfo = misc.getFacilityInfo();
	let display_year = facilityInfo.data_hsaf_last_year
		? facilityInfo.data_hsaf_last_year
		: misc.getFacilityYear();

	let Title = new MHGrid.DataGridTitle(
		provider.name + ' Patient Service Area (' + BuildInitForProvider(provider.name) + ' PSA)',
		'Medicare Beneficiary Inpatient Utilization',
		'Calendar Year ' + display_year
	);

	Title.header1Size = 5;
	Title.header2Size = 6;
	Title.header3Size = 6;

	// Create Column Headers for Competitors Grid
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn('name', new MHGrid.DataGridHeader('Hospital', '', '', 'left'), 280),
		new MHGrid.DataGridColumn(
			'total_cases_psa',
			new MHGrid.DataGridHeader('# of Medicare', 'Cases in Primary ', 'Service Area', 'center'),
			135,
			MHGrid.ColType_Integer
		),
		new MHGrid.DataGridColumn(
			'total_cases_ssa',
			new MHGrid.DataGridHeader('# of Medicare', 'Cases in Secondary ', 'Service Area', 'center'),
			135,
			MHGrid.ColType_Integer
		),
		new MHGrid.DataGridColumn(
			'total_cases',
			new MHGrid.DataGridHeader(
				'# of Medicare Cases In ',
				'Primary & Secondary  ',
				'Service Area ',
				'center'
			),
			135,
			MHGrid.ColType_Integer
		),
		new MHGrid.DataGridColumn(
			'market_share',
			new MHGrid.DataGridHeader(
				'% Marketshare in ',
				'Primary & Secondary',
				'Service Areas ',
				'center'
			),
			150,
			MHGrid.ColType_PercentInt
		),
		new MHGrid.DataGridColumn(
			'overall_total_cases',
			new MHGrid.DataGridHeader('# of Total ', 'Medicare Cases', '', 'center'),
			135,
			MHGrid.ColType_Integer
		),
		new MHGrid.DataGridColumn(
			'per_cases',
			new MHGrid.DataGridHeader(
				'% of Cases from ',
				'Primary & Secondary',
				'Service Areas',
				'center'
			),
			135,
			MHGrid.ColType_PercentInt
		),
		new MHGrid.DataGridColumn(
			'total_days_care',
			new MHGrid.DataGridHeader(
				'Medicare Patient ',
				'Days in Primary & Secondary ',
				'Service Area',
				'center'
			),
			135,
			MHGrid.ColType_Integer
		),
		new MHGrid.DataGridColumn(
			'alos_in_psa',
			new MHGrid.DataGridHeader(
				'Medicare ALOS',
				'in Primary & Secondary ',
				'Service Area ',
				'center'
			),
			135,
			MHGrid.ColType_Number
		)
	);

	let CompetitorsData = [];

	if (data && data.PSACompetitors) {
		CompetitorsData = data.PSACompetitors;

		for (let i = 0; i < CompetitorsData.length; i++) {
			if (CompetitorsData[i].prvdr_num === provider.providerId)
				CompetitorsData[i].fieldStyle = 'LINE_SHADE';
			if (
				CompetitorsData[i].name &&
				(CompetitorsData[i].name.toUpperCase() === 'TOTALS' ||
					CompetitorsData[i].name.toUpperCase() === 'TOTAL')
			)
				CompetitorsData[i].fieldStyle = 'TOTAL_LINE';
		}
	}

	return (
		<>
			<MHGrid.DataGrid columns={Cols} rows={CompetitorsData} title={Title} />
			<DataGridFooterNotation text="Medicare = includes fee-for-service claims only; Medicare Advantage and other managed care volumes are not included" />
		</>
	);
};

const RenderZipcodeGrid = (year, provider, data) => {
	let facilityInfo = misc.getFacilityInfo();
	let display_year = facilityInfo.data_hsaf_last_year
		? facilityInfo.data_hsaf_last_year
		: misc.getFacilityYear();

	let Title = new MHGrid.DataGridTitle(
		'Zip Code Market Analysis Trend',
		provider.name + ' Primary Patient Service Area',
		year + ' Statistics'
	);

	Title.header1Size = 4;
	Title.header2Size = 5;
	Title.header3Size = 6;

	// Create Column Headers for Competitors Grid
	let Cols = new MHGrid.DataGridColumns(
		new MHGrid.DataGridColumn('Zipcode', new MHGrid.DataGridHeader('Zip Codes in ', 'PSA', ''), 80),
		new MHGrid.DataGridColumn(
			'TotalCases',
			new MHGrid.DataGridHeader('Total', 'Cases', '', 'right'),
			90,
			MHGrid.ColType_Number
		),
		new MHGrid.DataGridColumn(
			'TotalDays',
			new MHGrid.DataGridHeader('Total', 'Days', '', 'right'),
			90,
			MHGrid.ColType_Number
		),
		new MHGrid.DataGridColumn(
			'TotalCharges',
			new MHGrid.DataGridHeader('Total', 'Charges', '', 'right'),
			85,
			MHGrid.ColType_Money
		),
		new MHGrid.DataGridColumn(
			'PropZipChargesTotal',
			new MHGrid.DataGridHeader('Proportion of Zip', 'Code Charges Total', '', 'right'),
			100,
			MHGrid.ColType_Percent
		),
		new MHGrid.DataGridColumn(
			'FacilityTrend',
			new MHGrid.DataGridHeader('Facility', '5-Year Case Trend', '', 'center'),
			120,
			MHGrid.ColType_SparkLine
		)
	);

	return (
		<div>
			{Title.render()}
			<div>
				<div className="sticky_table_header">
					<MHGrid.DataGrid columns={Cols} rows={[]} showHeaders={true} headersOnly={true} />
				</div>
				<MHGrid.DataGrid
					columns={Cols}
					rows={data ? data.zipCodeMarketTrend : []}
					showHeaders={false}
				/>
			</div>
		</div>
	);
};
const BuildCompetitorPopup = Competitor => {
	return (
		<ul className="list-group">
			<li className="list-group-item">
				<b>{Competitor.prvdr_num + ' ' + Competitor.name}</b>
			</li>
			<li className="list-group-item">
				PSA Cases: <span className="badge">{format.getInt(Competitor.total_cases_psa)}</span>
			</li>
			<li className="list-group-item">
				SSA Cases: <span className="badge">{format.getInt(Competitor.total_cases_ssa)}</span>
			</li>
			<li className="list-group-item">
				Total Cases: <span className="badge">{format.getInt(Competitor.total_cases)}</span>
			</li>
			<li className="list-group-item">
				Total Days Care: <span className="badge">{format.getInt(Competitor.overall_total_cases)}</span>
			</li>
		</ul>
	);
};

const RenderPopup = x => {
	return `
		<div className="popup-content">
			<b>${x.Zipcode || ''}</b>
	
			<div>Total Cases: ${x.TotalCases || ''}</div>
			
			<div>Total Days: ${x.TotalDays || ''}</div>
			
			<div>Avg LOS: ${x.AvgLOS || ''}</div>
			
			<div>Total Charges: ${x.TotalCharges || ''}</div>
			
			<div>Proportion of Zip: ${format.getPercent(x.PropZipChargesTotal) || ''}</div>
		</div>	
		`;
};
const RenderCompetitorsMap = (provider, data) => {
	let center = [];
	let markers = [];
	let geoShapes = [];

	if (provider) {
		center.push(provider.lat);
		center.push(provider.lng);

		// Loop through the comp
		if (data && data.PSACompetitors) {
			let Competitors = data.PSACompetitors;
			// Build the FOI Popup
			let prvdr = _.find(Competitors, x => x.prvdr_num == provider.providerId);

			if (prvdr) {
				let Marker = new Map.MapMarker();
				Marker.content = BuildCompetitorPopup(prvdr);
				Marker.lat = prvdr.prvdr_intplat;
				Marker.lng = prvdr.prvdr_intlong;
				markers.push(Marker);
			} else {
				// FOI was not in the Competitor List
				let marker = new Map.MapMarker(provider);
				markers.push(marker);
			}
			Competitors.forEach(x => {
				if (x.prvdr_num != provider.providerId) {
					let Marker = new Map.MapMarker();
					Marker.content = BuildCompetitorPopup(x);
					Marker.lat = x.prvdr_intplat;
					Marker.lng = x.prvdr_intlong;
					markers.push(Marker);
				}
			});
		}
	}

	if (data && data.zipCodeMarketTrend) {
		data.zipCodeMarketTrend.forEach(x => {
			if (x && x.geom_id) {
				let getColor = '';
				const val = Number(x.PropZipChargesTotal) * 100;

				if (val >= 45) {
					getColor = Map.MapColors.Best;
				} else if (val >= 30 && val < 45) {
					getColor = Map.MapColors.Good;
				} else if (val >= 15 && val < 30) {
					getColor = Map.MapColors.Poor;
				} else if (val < 15) {
					getColor = Map.MapColors.Worst;
				} else {
					getColor = Map.MapColors.Worst;
				}

				let obj = new Map.MapGeoObject(x.geom_id, x.Zipcode, x.Zipcode, getColor, RenderPopup(x));
				geoShapes.push(obj);
			}
		});
	}

	return (
		<Row>
			<Col xs={12} md={12}>
				<Map.DataMap
					center={center}
					markers={markers}
					zoomControl={true}
					geoShapes={geoShapes}
					scrollWheelZoom={false}
					doubleClickZoom={true}
					dragging={true}
					zoom={10}
					height={'300px'}
				/>
			</Col>
			<Col xs={10} md={10}>
				{Legend(
					new LegendItem(Map.MapColors.Best, 'Over 45 % of Cases'),
					new LegendItem(Map.MapColors.Good, '30 - 45 % of Cases'),
					new LegendItem(Map.MapColors.Poor, '15 - 30 % of Cases'),
					new LegendItem(Map.MapColors.Worst, 'Less Than 15 % of Cases')
				)}
			</Col>
			<Col xs={2} md={2}>
				<InfoPopup title="Market Share">{callouts.marketShareMap()}</InfoPopup>
			</Col>
		</Row>
	);
};

const RenderMarketSharePage = (year, provider, data) => {
	return (
		<>
			{RenderCompetitorsMap(provider, data)}
			{RenderCompetitorsTable(year, provider, data)}
			<div className="row my-2">
				<div className="col-md-6 text-center">
					{RenderPSAMarketBasedMedicareCasesChart(provider, data)}
				</div>
				<div className="col-md-6 text-center">
					{RenderPSAMarketBasedMedicareALOSChart(provider, data)}
				</div>
			</div>
			<div className="">
				<div className="centered-text bg-white">{RenderZipcodeGrid(year, provider, data)}</div>
			</div>
		</>
	);
};

const RenderBody = (year, providers, Data) => {
	let data = null;

	if (providers) {
		if (providers.length > 1) {
			const tabs = [];
			providers.forEach(x => {
				if (Data) {
					data = _.find(Data.result, j => {
						return j.providerId === x.providerId;
					});
				}
				tabs.push(
					<Tab eventKey={x.providerId} title={x.name}>
						{RenderMarketSharePage(year, x, data)}
					</Tab>
				);
			});
			return <Tabs id="">{tabs}</Tabs>;
		} else if (providers.length === 1) {
			if (Data && Array.isArray(Data.result)) {
				data = _.find(Data.result, j => {
					return j.providerId === providers[0].providerId;
				});
			}

			return RenderMarketSharePage(year, providers[0], data);
		}
	} else return <div />;
};

const mapStateToProps = store => {
	return {
		marketShare: store.marketData,
		prvdr: store.prvdr
	};
};

class MarketShare extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			providers: (this.props.prvdr && this.props.prvdr.Providers) || []
		};
	}

	UNSAFE_componentWillMount() {}

	render() {
		const bodyRender =
			this.props.marketShare && this.props.marketShare.fetched ? (
				RenderBody(
					misc.getFacilityYear(),
					this.props.prvdr.Providers,
					this.props.marketShare.MarketShareData
				)
			) : (
				<div className="container">
					<div className="row">
						<LoadingSpinner />
					</div>
				</div>
			);

		return <Fragment>{bodyRender}</Fragment>;
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({ load }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MarketShare);
