import { combineReducers } from 'redux';
import _ from 'lodash';
import * as Types from '../actions/ActionTypes';
import '../LoginPage/LoginActions';
import { elasticSearchReducer, providerReducer, providerInfoReducer } from './SearchReducer';
import { PrvdrReducer } from './ProviderReducer';
import loginReducer from '../LoginPage/LoginReducer';
import { fieldDefinitionListDataReducer, FindQuartileField } from './FieldDefReducer';
import { archiveDatasetReducer } from './ArchiveDatasetReducer';
import { archiveDatasetDetailsReducer } from './ArchiveDatasetDetailsReducer';
import { ArticlePageReducer as ArticleReducer } from './ActionPageReducer';
import { quartileFieldListReducer } from './QuartileChartReducer';
import { DataExplorerReducer } from '../DefaultPages/dataExplorer/ExplorerReducer';
import whitePaperReducer from './WhitePaperReducer';
// --For readability please place domain specific calls in their associated category
//Hospital Reducers
import * as HospReducers from './HospReducer';
//SNF Reducers
import * as SnfReducers from './SnfReducer';
const availableReducers = {
	auth: loginReducer,
	prvdr: PrvdrReducer,
	providers: providerReducer,
	providerInfo: providerInfoReducer,
	searchResult: elasticSearchReducer,
	explorerData: DataExplorerReducer,
	articleData: ArticleReducer,
	fieldDef: fieldDefinitionListDataReducer,
	quartilePopup: FindQuartileField,
	archiveDataset: archiveDatasetReducer,
	archiveDatasetDetails: archiveDatasetDetailsReducer,
	quartileChartData: quartileFieldListReducer,
	whitePapers: whitePaperReducer
};
const reducers = combineReducers({
	...availableReducers,
	...HospReducers.Reducers,
	...SnfReducers.Reducers
});

const initialReducerState = reducers({}, {});

const appReducer = (state, action) => {
	if (action.type === Types.GLOBAL_RESET) {
		let prvdrs = state.providers;
		state = initialReducerState;
		state.providers = prvdrs;
	}
	let reduce = reducers(state, action);
	return reduce;
};
export default appReducer;
