import React, { Fragment } from 'react';
import * as misc from '../../lib/misc';
import { formatComma, initCap } from '../../lib/FormatUtil';
import LocationMap from '../../components/location-map';
import Auth from '../../core/Security/Auth';

const infoToolTip = (toolTip = '') => {
	return (
		<div
			className="MH-InfoTooltip mx-2"
			role="button"
			data-toggle="popover"
			data-trigger="hover"
			data-content={toolTip}
		>
			<i className="fa fa-info-circle" aria-hidden="true" style={{ fontSize: '0.8em' }} />
		</div>
	);
};

class FacilityInfo extends React.Component {
	render() {
		const renderName = misc.getFacilityInfo();

		return (
			<div className="container">
				{renderName && (
					<div className="row">
						<div className="col-12">
							<h2>{initCap(renderName.facility_name) || ''}</h2>
						</div>
						<div className="col-md-6 col-12">
							<div className="sumGenInfo">
								<div className="row">
									<div className="col-sm-8 col-md-7">CMS Certification Number:</div>
									<div className="col-sm-4 col-md-5">{renderName.prvdr_num}</div>
								</div>

								<div className="row">
									<div className="col-sm-8 col-md-7 d-flex">
										Geographic Classification:
										{infoToolTip(
											'Medicare classification of a facility being located in a non-urbanized area "rural" vs urban. A component that affects hospital reimbursement under Medicare PPS.'
										)}
									</div>
									<div className="col-sm-4 col-md-5">{initCap(renderName.urban_rural_flag)}</div>
								</div>
								<div className="row">
									<div className="col-sm-8 col-md-7 d-flex">
										Hospital Type or Peer Group:
										{infoToolTip(
											'Medicare facility type classification available options are: Short-Term Care, Long-Term Care, Rehabilitation, Childrens, Critical Access, Psychiatric or Other'
										)}
									</div>
									<div className="col-sm-4 col-md-5">{initCap(renderName.hosp_type_cat)}</div>
								</div>
								<div className="row">
									<div className="col-sm-8 col-md-7 d-flex">
										Graduate Medical Education:
										{infoToolTip(
											'A classification indicating if a facility has a graduate medical education or physician residency program wherein recent graduates complete serveral years of supervised hands-on training in a particular area of care.'
										)}
									</div>
									<div className="col-sm-4 col-md-5">
										{initCap(renderName.final_teach_status)}
									</div>
								</div>
								<div className="row">
									<div className="col-sm-8 col-md-7">Part of System:</div>
									<div className="col-sm-4 col-md-5">{renderName.system ? 'Yes' : 'No'}</div>
								</div>
								<div className="row">
									<div className="col-sm-8 col-md-7">System Name:</div>
									<div className="col-sm-4 col-md-5">
										{renderName.system ? initCap(renderName.system) : 'N/A'}
									</div>
								</div>
								{renderName.prvdr_website_url &&
								renderName.prvdr_website_url != 'Link Not Available' &&
								Auth.isUserAuthenticated() ? (
									<div className="row">
										<div className="col-sm-8 col-md-7">
											Website:
											{infoToolTip(
												'Click link to open ' +
													initCap(renderName.facility_name) +
													"'s website in a new tab"
											)}
										</div>
										<a
											className="col-sm-4 col-md-5"
											href={renderName.prvdr_website_url}
											target="_blank"
										>
											{initCap(
												renderName.facility_name && renderName.facility_name.length > 15
													? renderName.facility_name.substring(0, 15) + '...'
													: renderName.facility_name
											) + ' Website'}
										</a>
									</div>
								) : (
									''
								)}
							</div>
							<div className="sumGenInfo">
								<h4>
									Summary Information
								</h4>
								<div className="">
									<div className="row">
										<div className="col-sm-8 col-md-7">Latest Reported Year:</div>
										<div className="col-sm-4 col-md-5">{renderName.fy_year}</div>
									</div>
									<div className="row">
										<div className="col-sm-8 col-md-7">Total Beds:</div>
										<div className="col-sm-4 col-md-5">{formatComma(renderName.total_beds)}</div>
									</div>
									<div className="row">
										<div className="col-sm-8 col-md-7">Routine Adult and Pediatric Beds:</div>
										<div className="col-sm-4 col-md-5">
											{formatComma(renderName.total_adults_peds_number_of_beds || 0)}
										</div>
									</div>
									<div className="row">
										<div className="col-sm-8 col-md-7">Special Care Beds:</div>
										<div className="col-sm-4 col-md-5">
											{formatComma(
												Math.max(
													renderName.total_beds -
														renderName.total_adults_peds_number_of_beds -
														renderName.subacute_beds,
													0
												)
											)}
										</div>
									</div>
									<div className="row">
										<div className="col-sm-8 col-md-7">Sub-Acute Beds:</div>
										<div className="col-sm-4 col-md-5">
											{formatComma(renderName.subacute_beds || 0)}
										</div>
									</div>
									<div className="row">
										<div className="col-sm-8 col-md-7">Routine Newborn Nursery:</div>
										<div className="col-sm-4 col-md-5">{initCap(renderName.newborn_nursery)}</div>
									</div>
									<div className="row">
										<div className="col-sm-8 col-md-7">Total Acute Inpatient Days:</div>
										<div className="col-sm-4 col-md-5">
											{formatComma(renderName.inpatient_days_total_acute_care || 0)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-6" id="summMap">
							{renderName && (
								<div>
									<div className="row px-3 pt-2">
										<LocationMap
											lat={renderName.latitude}
											lng={renderName.longitude}
											facilityName={renderName.facility_name}
											facilityAddress={renderName.facility_address}
											facilityCity={renderName.facility_city}
											facilityState={renderName.facility_state_abbr}
											facilityZip={renderName.facility_zip}
										/>
									</div>
									<h6>
										Location:
									</h6>
									<address>
										{renderName.facility_address}, {renderName.facility_city},{' '}
										{renderName.facility_state_abbr} {renderName.facility_zip}
									</address>
								</div>
							)}
						</div>
					</div>
				)}
				<hr />
			</div>
		);
	}
}

export default FacilityInfo;
