import Auth from '../Security/Auth';

const defaultState = {
	token: null,
	profile: null,
	error: null,
	fetching: false,
	fetched: false
};

const loginReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'USER_LOGIN_FETCH_PENDING': {
			return { ...state, fetching: true };
		}
		case 'USER_LOGIN_FETCH_FULFILLED': {
			if (
				action.payload &&
				action.payload.data &&
				action.payload.data.session &&
				action.payload.data.session.token
			) {
				let token = action.payload.data.session.token;
				Auth.authenticateUser(token);
				if (
					action.payload &&
					action.payload.data &&
					action.payload.data.session &&
					action.payload.data.session.profile
				) {
					let profile = action.payload.data.session.profile;
					Auth.storeUserProfile(profile);
					return { ...state, fetching: false, fetched: true, token, profile };
				}
			}
		}
		case 'USER_LOGIN_FETCH_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload
			};
		}
		default: {
			break;
		}
	}
	return state;
};

export default loginReducer;
