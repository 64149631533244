import React, { Fragment } from 'react';
import Autosuggest from 'react-autosuggest';
import * as api from '../../../lib/ApiUtil';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import './auto-suggest.css';
import SearchFilter from './search-filters';

function getSuggestionValue(suggestion) {
	return suggestion.prvdr_num + ' | ' + suggestion.fac_name;
}

function renderSuggestion(suggestion) {
	return (
		<div className="row">
			<div className="col-11">
				<span>
					{suggestion.prvdr_num} | {suggestion.fac_name} <br />
					{suggestion.street_address} | {suggestion.city_name} | {suggestion.state_name}
				</span>
			</div>
			<div className="col-1">
				<FontAwesomeIcon icon={suggestion.domain == 'hosp' ? 'h-square' : 'user-md'} size="lg" />
			</div>
		</div>
	);
}

export default class AutoSuggestBox extends React.Component {
	constructor(props) {
		super(props);

		switch (props.buttons) {
			case 'small':
				this.showButtons = 'small';
				break;
			case 'hide':
				this.showButtons = 'hide';
				break;
			default:
				this.showButtons = 'default';
				break;
		}
		
		this.domain = props.domain;

		let initDomain = '';
		if (this.domain) initDomain = this.domain;
		else initDomain = sessionStorage.getItem('initDomain');

		this.state = {
			value: '',
			suggestions: [],
			query: '',
			loading: false,
			message: '',
			queued_query: '',
			filters: [],
			hosp: initDomain ? !!(initDomain === 'hosp') : true,
			snf: initDomain ? !!(initDomain === 'snf') : false,
			hha: initDomain ? !!(initDomain === 'hha') : false,
			hospID: '',
			snfID: ''
		};

		switch(props.isHome) {
			case true:
				this.state.hospID = 'search_hosp_home';
				this.state.snfID = 'search_snf_home';
				break;
			default:
				this.state.hospID = 'search_hosp';
				this.state.snfID = 'search_snf';
				break;
		}

		/* console.log(this.state);
		console.log('*-------------------*'); */

		this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
		this.onFilterChanged = this.onFilterChanged.bind(this);
		this.onDomainChanged = this.onDomainChanged.bind(this);
		this.onOptionChange = this.onOptionChange.bind(this);
	}

	buildSearch(value) {
		return {
			query: value,
			filters: this.state.filters,
			hosp: this.state.hosp,
			snf: this.state.snf,
			hha: this.state.hha
		};
	}

	onChange = (event, { newValue, method }) => {
		let state = this.state;
		state.value = newValue;
		this.setState(state);
	};

	onSuggestionsFetchRequested = ({ value }) => {
		const query = value;

		if (this.state.loading && value.length >= 3) {
			let state = this.state;
			state.queued_query = query;
			this.setState(state);
		} else if (value.length >= 2 || (value.length >= 1 && this.state.filters.length > 0)) {
			this.setState({ query, loading: true, message: '' }, () => {
				this.fetchSearchResults(query);
			});
		}
	};

	onOptionChange(domain, e) {
		let state = this.state;
		state.hosp = domain == 'hosp' ? true : false;
		state.snf = domain == 'snf' ? true : false;
		state.hha = domain == 'hha' ? true : false;
		sessionStorage.setItem('initDomain', domain);
		this.setState(state);
	}

	onSuggestionSelected(
		event,
		{ suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
	) {
		if (this.props.redirectCallback) {
			this.props.redirectCallback(suggestion);
			let state = this.state;
			this.state.value = '';
			this.setState(state);
		}
	}

	fetchSearchResults(value) {
		// By default the limit of results is 20

		if (this.cancel) {
			this.cancel.cancel();
		}

		// Create a new CancelToken
		this.cancel = api.CancelToken();
		api
			.APIPost(api.searchAPI, {
				cancelToken: this.cancel.token,
				search: this.buildSearch(value)
			})
			.then(res => {
				// const resultNotFoundMsg = !res.data.hits.length
				//   ? "There are no more search results. Please try a new search."
				//   : "";

				// Query waiting to run
				if (this.state.queued_query) {
					let query = this.state.queued_query;

					let state = this.state;
					state.loading = true;
					state.suggestions = res.data.result;
					state.loading = '';
					state.queued_query = '';
					this.setState(state, this.fetchSearchResults(query));
				} else {
					this.setState({
						suggestions: res.data.result,
						message: '',
						loading: false
					});
				}
			})
			.catch(error => {
				if (api.isCancel(error) || error) {
					this.setState({
						loading: false,
						message: 'Failed to fetch results.Please check network',
						queued_query: ''
					});
				}
			});
	}

	onSuggestionsClearRequested = () => {
		let state = this.state;
		state.suggestions = [];
		this.setState(state);
	};

	onDomainChanged(hosp, snf, hha) {
		let state = this.state;
		state.hosp = hosp;
		state.snf = snf;
		state.hha = hha;
		this.setState(state);
	}

	onFilterChanged(filters) {
		let state = this.state;
		state.filters = filters;
		this.setState(state);
		this.onChange(null, { newValue: state.value, method: null });
	}

	searchTogleSmallBtns() {
		return (
			<div className="custom-control-inline  my-2 ">
				<div className="ml-n4">
					<button
						type="button"
						className={'btn btn-outline-primary ' + (this.state.hosp ? ' active ' : '') + 'btn-sm '}
						onClick={() => {
							this.onOptionChange('hosp', null);
						}}
					>
						<FontAwesomeIcon icon="h-square" size="lg" />
					</button>
				</div>
				<div className="ml-2">
					<button
						type="button"
						className={'btn btn-outline-primary ' + (this.state.snf ? ' active ' : '') + 'btn-sm'}
						onClick={() => {
							this.onOptionChange('snf', null);
						}}
					>
						<FontAwesomeIcon icon="user-md" size="lg" />
					</button>
				</div>
			</div>
		);
	}
	
	searchTogleBtns() {
		return (
			<div className="custom-control-inline custom-checkbox text-primary my-2">
				<div className="col-3 ml-2">
					<input
						defaultChecked={this.state.hosp}
						type="radio"
						className="custom-control-input"
						id={this.state.hospID}
						name="search_domain"
						onChange={e => this.onOptionChange('hosp', e)}
					/>
					<label className="custom-control-label" htmlFor={this.state.hospID}>
						Hospital
					</label>
				</div>
				<div className="col-9 ml-3">
					<input
						defaultChecked={this.state.snf}
						type="radio"
						className="custom-control-input"
						id={this.state.snfID}
						name="search_domain"
						onChange={e => this.onOptionChange('snf', e)}
					/>
					<label className="custom-control-label" htmlFor={this.state.snfID}>
						Skilled Nursing Facility
					</label>
				</div>
			</div>
		);
	}

	autoSuggest() {
		const { value, suggestions } = this.state;
		const inputProps = {
			placeholder: 'Search by Name, CMS Provider # or Address',
			value,
			onChange: this.onChange
		};

		return (
			<Autosuggest
				id="AutoSuggestSearch"
				name="AutoSuggestSearch"
				className="m-0 p-0"
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				onSuggestionSelected={this.onSuggestionSelected}
				inputProps={inputProps}
			/>
		);
	}

	render() {
		if (this.showButtons === 'hide' || this.domain) {
			return (
				<div className="w-100">
					<div className="row">
						<div className="col-12">{this.autoSuggest()}</div>
					</div>
				</div>
			);
		} else if (this.showButtons === 'small') {
			return (
				<div className="row align-items-center">
					<div className="col-10 my-0">{this.autoSuggest()}</div>
					<div className="col-2  my-0">{this.searchTogleSmallBtns()}</div>
				</div>
			);
		} else {
			return (
				<div className="w-100">
					<div className="row">
						<div className="col-12">{this.autoSuggest()}</div>
						<div className="row  text-center">
							<div className="col-12 ">{this.searchTogleBtns()}</div>
						</div>
					</div>
				</div>
			);
		}
	}
}
