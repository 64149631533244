import * as Types from '../actions/ActionTypes';
import * as _ from 'lodash';
import * as misc from '../../lib/misc';

const defaultState = {
	fields: { hosp: [], snf: [], hha: [], market: [] },
	all: [],
	error: null,
	fetching: false,
	fetched: false,
	updateKey: null
};

const defaultStateQuartileField = {
	error: null,
	fetching: false,
	fetched: false,
	whereChanged: false,
	Data: [],
	result: {
		data: [],
		legend: [],
		metrics: [],
		datatable: []
	}
};
function add(array, value) {
	if (array.indexOf(value) === -1) {
		array.push(value);
	}
}

function mapfields(domain, fields, state) {
	switch (domain) {
		case 'hosp':
			fields.map(a => {
				add(state.fields.hosp, a);
			});
			break;
		case 'snf':
			fields.map(a => {
				add(state.fields.snf, a);
			});
			break;
		case 'hha':
			fields.map(a => {
				add(state.fields.hha, a);
			});
			break;
		case 'market':
			fields.map(a => {
				add(state.fields.market, a);
			});
	}
	return state;
}

export const fieldDefinitionListDataReducer = (state = defaultState, action) => {
	switch (action.type) {
		case Types.FIELD_DEFINITION_LIST + '_FULFILLED': {
			state = mapfields(
				action.payload.data.result.domain,
				action.payload.data.result.fields,
				state
			);
			return {
				...state,
				all: _.union(
					state.fields.hosp.map(a => {
						a.FieldDomain = 'Hospital';
						return a;
					}),
					state.fields.snf.map(a => {
						a.FieldDomain = 'Skilled Nursing';
						return a;
					}),
					state.fields.hha.map(a => {
						a.FieldDomain = 'Home Health';
						return a;
					})
				),
				fetching: false,
				fetched: true,
				updateKey: misc.Guid()
			};
		}
		default: {
			break;
		}
	}
	return state;
};

export const FindQuartileField = (state = defaultStateQuartileField, action) => {
	switch (action.type) {
		case Types.FIND_QUARTILE_FIELD + '_PENDING': {
			return { ...state, fetching: true, whereChanged: false };
		}
		case Types.FIND_QUARTILE_FIELD + '_FULFILLED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				whereChanged: false,
				Data: action.payload.data.result
			};
		}
		case Types.FIND_QUARTILE_FIELD + '_REJECTED': {
			return {
				...state,
				fetching: false,
				fetched: true,
				whereChanged: false,
				result: action.payload
			};
		}
		case 'SESSION_STORAGE_WHERE_CHANGE': {
			return {
				...state,
				whereChanged: true,
				fetching: false,
				fetched: false,
				Data: []
			};
		}
		default:
			return { ...state };
	}
};
