import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
class SkilledNursingPage extends React.Component {
	render() {
		return (
			<div id="snf">
				<Helmet>
					<title>Skilled Nursing Facilities | HMP Metrics</title>
					<meta
						name="description"
						content="HMP Metric's Skilled Nursing Facility Data Set also includes the data from HCRIS incorporated with Census and LDS claims data. The SNF Data Set includes an expanded version of the great tools and reports available to the Hospital & Health System Data Set."
					/>
				</Helmet>
				<section id="content" className="divBar">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h6>Tools and Reports Specific to Your Needs</h6>
							</div>
						</div>
					</div>
				</section>
				<section>
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h1 className="text-center">Skilled Nursing Facilities</h1>
								<p>
									See the metrics that drive reimbursement; where patients are coming from, quality
									and financial ratings of all Medicare enrolled SNF facilities in the market.
								</p>
							</div>
						</div>
					</div>
				</section>
				{/*  Some Repeat Information - Hospital Health System  */}
				<section id="bgCollage">
					<div className="container" id="tile1">
						<div className="bgCollageTile topTile"></div>
						<div className="bgCollageTile bottTile"></div>
						<div className="row">
							<div className="col-md-6 col-12">
								<h3>Detailed Financial Insight</h3>
								<p>
									The Financial Performance tool is a simple view into a provider's key financials
									in easy to read charts designed to illustrate financial viability. Financial
									indicators are sourced from CMS' Healthcare Cost Report Information System
									(HCRIS). Additional information regarding data sources can be found in the{' '}
									<a href="faq.html">FAQ section</a>.
								</p>
							</div>
							<div className="col-md-6 col-12">
								<ul className="anime slideL">
									<li>Days Cash on Hand</li>
									<li>Labor Cost as a Percentage of Total Net Patient Revenue</li>
									<li>PDPM Impact Estimator</li>
									<li>Long Term Debt to Total Assets</li>
								</ul>
								<div className="bgColCover topTile"></div>
							</div>
						</div>
					</div>
					<div className="container" id="tile2">
						<div className="bgCollageTile topTile"></div>
						<div className="bgCollageTile bottTile"></div>
						<div className="row">
							<div className="col-md-6 col-12">
								<ul className="anime slideR">
									<li>Patient Flows and Patient Migration</li>
									<li>Market share by zip code</li>
									<li>Payroll-Based Journal Nurse Staffing</li>
									<li>Operating statistics &amp; Quality measures</li>
								</ul>
								<div className="bgColCover topTile"></div>
							</div>
							<div className="col-md-6 col-12">
								<h3>100+ Strategic Market Data Sets</h3>
								<p>
									The Financial Performance tool is a simple view into a provider's key financials
									in easy to read charts designed to illustrate financial viability. Financial
									indicators are sourced from CMS' Healthcare Cost Report Information System
									(HCRIS). Additional information regarding data sources can be found in the FAQ
									section.
								</p>
							</div>
						</div>
					</div>
					<div className="container" id="tile3">
						<div className="bgCollageTile topTile"></div>
						<div className="bgCollageTile bottTile"></div>
						<div className="row">
							<div className="col-md-6 col-12">
								<h3>Dive Deep Into Quality</h3>
								<p>
									Skilled Nursing Facilities (SNFs) rely heavily on quality metrics to ensure that
									they are able to maximize patient volumes and reimbursements. HMP Metrics has one
									of the most streamlined and complete SNF quality reporting suites available. See
									all relevant quality metrics together, in one place, and effortlessly compare them
									to State, National, or specific Market Competition.
								</p>
							</div>
							<div className="col-md-6 col-12">
								<ul className="anime slideL">
									<li>
										See the Current Five Star Rating of the Facility and Compare to State and
										National Averages
									</li>
									<li>
										View All Health Inspections &amp; Inspection Status, View the Submitted Report
										all From One Screen
									</li>
									<li>
										Quickly View and Identify Health Deficiencies and Fire Safety Inspection Results
									</li>
									<li>
										View Extensive Quality of Resident Care Metrics, Complete with Detailed
										Explanations and Scope of Impact
									</li>
								</ul>
								<div className="bgColCover topTile"></div>
							</div>
						</div>
					</div>
				</section>
				<section className="divBar">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h6>
									Become a Subscriber to Gain Access to All Metrics:{' '}
									<a href="/subscribe">Subscribe Today</a>
								</h6>
							</div>
						</div>
					</div>
				</section>
				<section id="reports">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-12">
								<img
									className="fluidImg anime slideL"
									src="images/hmp-metrics-report-01.jpg"
									alt="HMP Metrics Provider Comparison Screenshot"
								/>
							</div>
							<div className="col-md-6 col-12">
								<h2>Compare Any Provider</h2>
								<p>
									The Benchmark Report highlights more than 100 critical utilization, labor and
									financial measures and allows for comparison against up to four national and state
									peers. Each metric is defined so users across the system can access data cleansed
									of outliers.
								</p>
								<ul>
									<li>Average Inpatient Length of Stay</li>
									<li>Medicare Case Mix Index</li>
									<li>Acute FTEs per AOB</li>
									<li>Total Net Patient Revenue per FTE</li>
									<li>Average Cost per FTE</li>
									<li>Medicare Disproportionate Share Hospital Payments</li>
								</ul>
							</div>
							<div className="col-md-6 col-12">
								<h2>Create Your Own Datasets!</h2>
								<p>
									Our easy to use Data Explorer tool will allow you to create your own analyses.
									Easily aggregate and create custom views of millions of records from over 160
									sources, including Medicare HCRIS, LDS and Census.
								</p>
								<ul>
									<li>Mix and Match Metrics You Want</li>
									<li>Set Custom Filters and Date Ranges</li>
									<li>Easily See Metrics and Facilities of Interest on one Screen</li>
									<li>Choose From All Available Metrics on the Site</li>
									<li>Export to Excel</li>
								</ul>
							</div>
							<div className="col-md-6 col-12">
								<img
									className="fluidImg anime slideR"
									src="images/hmp-metrics-report-02.jpg"
									alt="HMP Metrics Custom Dataset Screenshot"
								/>
							</div>
							<div className="col-md-6 col-12">
								<img
									className="fluidImg anime slideL"
									src="images/hmp-metrics-report-03.jpg"
									alt="HMP Metrics Cost Report Screenshot"
								/>
							</div>
							<div className="col-md-6 col-12">
								<h2>Download Any Cost Report in Excel</h2>
								<p>
									Download the latest submitted Cost Reports in Excel format instantly. Useful if
									you want to see the actual submission to CMS for Research, Legal or Quality
									Assurance programs; or as an aid in the submission of a Cost Report.
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default class AboutUs extends React.Component {
	render() {
		return (
			<Fragment>
				<SkilledNursingPage />
			</Fragment>
		);
	}
}
