import React from 'react';
import { connect } from 'react-redux';
import { snfRankingScore } from '../Common/SkilledNursingActions';
import * as formatUtil from './../../lib/FormatUtil';
import * as misc from './../../lib/misc';
import $ from 'jquery';

const ratingStyle = {
	paddingTop: '40px',
	fontSize: '64px',
	color: '#fff',
	fontWeight: 400,
	fontFamily: 'Roboto',
	height: '100%',
	width: '100%'
};

const containerStyle = {
	//	marginTop: '10px',
	height: '100%',
	width: '100%'
};

const borderTile = {
	border: 'solid',
	borderColor: '#fff',
	borderWidth: '1px',
	margin: 'auto'
};

const mapStateToProps = store => {
	return {
		snfRanking: store.snfRankingsScore.Benchmark,
		fetched: store.snfRankingsScore.fetched,
		whereChanged: store.qualityRating.whereChanged
	};
};

const mapDispatchToProps = dispatch => {
	return {
		GetRatings: providers => {
			dispatch(snfRankingScore(providers));
		}
	};
};
class FacilityRating extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
		this.props.GetRatings(this.props.facilityId);
	}

	componentDidMount() {
		$('.MH-InfoTooltip').popover();
	}

	createRankingTile(rptScore, labelCategory, callout) {
		let score = formatUtil.getInt(rptScore);
		let bgColor = 'bg-dark';
		let foreColor = 'text-white';

		return (
			<div className={bgColor} style={ratingStyle}>
				{score ? score + '' : '-'}
				<h5 className={foreColor} style={{ fontWeight: '300' }}>
					{labelCategory}{' '}
					<small>
						<i
							className="MH-InfoTooltip fa fa-info-circle"
							aria-hidden="true"
							style={{ color: 'white' }}
							data-toggle="popover"
							data-trigger="hover"
							data-content={callout}
						/>
					</small>
				</h5>
			</div>
		);
	}

	render() {
		let modalKey = misc.Guid();
		let data = this.props.fetched ? this.props.snfRanking.data[0] : {};
		return (
			<div className="col-lg-4 col-md-4 col-sm-12" id="groupPerc">
				<h5 id="gpHeading">
					Peer Group Percentile Ranking{' '}
						<small>
							<i
								className="MH-InfoTooltip fa fa-info-circle text-dark"
								aria-hidden="true"
								data-toggle="modal"
								data-target={'#' + modalKey}
							/>
						</small>
				</h5>
				<div className="container-fluid m-0">
					<div
						style={{
							textAlign: 'center',
							height: '100%',
							width: '100%',
							position: 'relative'
						}}
					>
						<div style={containerStyle}>
							<div className="row m-0 p-0">
								<div className="col m-0 p-0" style={{ ...borderTile }}>
									{this.createRankingTile(
										this.props.fetched ? data.financial : '',
										'Financial',
										'Based on Operating Margin, Total Overhead Costs per Patient Day, Total Liabilities, and Days Cash on Hand.'
									)}
								</div>
								<div className="col m-0 p-0" style={{ ...borderTile }}>
									{this.createRankingTile(
										this.props.fetched ? data.quality : '',
										'Quality',
										'Based on CMS Overall Rating, Facility Reported Incidents, Health Survey Scores, Substantiated Complaints, Total Fines, and Total Number of Penalties'
									)}
								</div>
							</div>
							<div className="row m-0 p-0" style={{}}>
								<div className="col m-0 p-0" style={{ ...borderTile }}>
									{this.createRankingTile(
										this.props.fetched ? data.operations : '',
										'Operations',
										'Leverages Total Direct Costs per Patient Day, Total Labor Costs per Patient Day, Occupancy Rate, and Total Direct Costs'
									)}
								</div>
								<div className="col m-0 p-0" style={{ ...borderTile }}>
									{this.createRankingTile(
										this.props.fetched ? data.strategy : '',
										'Strategy',
										'Weighted by Total Profit Margin, Occupancy, Total Liabilities, and Medicaid and Medicare Days'
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="modal" id={modalKey}>
						<div className="modal-dialog-centered mx-auto col-md-6">
							<div className="modal-content bg-primary">
								<div class="modal-header py-2 bg-primary text-white border-0 rounded-0">
									<h4 class="modal-title pl-1 py-0">Peer-Group Rankings</h4>
									<button type="button" className="close" data-dismiss="modal">
										&times;
									</button>
								</div>
								<div class="modal-body bg-white">
									<h3 className="text-primary">The Detail Behind The Numbers</h3>
									<p style={{ fontSize: '10pt' }}>
										The Skilled Nursing Facility Peer Group Percentile Ranking (SNF Rank) is a
										composite score to provide a high-level indication of how the selected facility
										is performing relative to their national peer-group across 4 different segments.
										Each score is generated using over 15 metrics using over 40 inputs.
									</p>
									<br />
									<h5>Peer-Groups</h5>
									<hr />
									<p style={{ fontSize: '10pt' }}>
										Skilled Nursing Facilities (SNF’s) are separated into peer-groups based on
										operating status - whether or not they are free-standing or hospital based.
										Free-standing facilities comprise the majority of elements are further segmented
										based on bed-size (0 - 60,61 - 150, 151+). Operating status was the initial
										determinant as the overall cost-structure of hospital-based SNFs are
										fundamentally different from free-standing facilities. Furthermore bed-size was
										split uniformly in an effort to make certain cost and operational-factors were
										equitably distributed. Note the values of each input are based on the most
										recent 3 year averages, with the more recent values weighed more heavily.
										Factors were chosen based on their contribution, evidence of overall operational
										health, as well as report frequency.
									</p>
									<hr />
									<h5 className="text-primary">RANKING | Financial</h5>
									<p style={{ fontSize: '10pt' }}>
										The Financial ranking is a composite of the Operating Margin, Total Overhead
										Costs per Patient Day, Total Liabilities, and Days Cash on Hand.
									</p>
									<h5 className="text-primary">RANKING | Operations</h5>
									<p style={{ fontSize: '10pt' }}>
										The Operations ranking is comprised of Total Direct Costs per Patient Day, Total
										Labor Costs per Patient Day, Occupancy Rate, and Total Direct Costs
									</p>
									<h5 className="text-primary">RANKING | Strategy</h5>
									<p style={{ fontSize: '10pt' }}>
										Strategy ranking consists of Total Profit Margin, Occupancy Rate Trend, Total
										Liabilities per Bed, Medicaid Days as a Percentage of Total Days, and Medicare
										Days as a Percentage of Total Days.
									</p>
									<h5 className="text-primary">RANKING | Quality</h5>
									<p style={{ fontSize: '10pt' }}>
										To rank SNF Quality the facility CMS Overall Rating, Number of Facility Reported
										Incidents weighted by Average Daily Census, the Total Weighted Health Survey
										Scores, Substantiated Complaints per Resident Day, Total Fines, and Total Number
										of Penalties were applied.
									</p>
									<hr />
									<p className="strong pull-right" style={{ fontSize: '8pt' }}>
										Note as more granular, timely, or predictive data elements are identified these
										scores will continue to be refined and improved upon.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FacilityRating);
