import React from 'react';

class FacilityRatingInfoPopup extends React.Component {
	constructor(props) {
		super(props);
	}
	getSectionHeader() {
		let header = <div />;
		switch (this.props.selectionIndex) {
			case 0:
				header = <h3>Financial</h3>;
				break;
			case 1:
				header = <h3>Quality</h3>;
				break;
			case 2:
				header = <h3>Operations</h3>;
				break;
			case 3:
				header = <h3>Strategy</h3>;
				break;
			case 4:
				header = <h3>Viability Index</h3>;
				break;
			default:
				break;
		}
		return header;
	}
	getSectionText() {
		let section = <div />;
		switch (this.props.selectionIndex) {
			case 0:
				section = (
					<div>
						<p>
							The Financial Percentile Ranking is calculated from a composite of financial metrics
							including: Total Profit Margin, Days of Net Patient Revenue in Accounts Receivable,
							Days of Net Patient Revenue in Cash and the Ratio of Operating Revenue to Operating
							Cost per Adjusted Occupied Bed (AOB).
						</p>
					</div>
				);
				break;
			case 1:
				section = (
					<div>
						<p>
							The Quality Percentile Ranking is calculated from a composite of quality metrics
							including: Total Performance Score (TPS), Star Quality Rating and Total Quality
							Payment Adjustment, which is the sum of Value-Based and Readmission Reduction
							Payments.
						</p>
					</div>
				);
				break;
			case 2:
				section = (
					<div>
						<p>
							The Operations Percentile Ranking is calculated from a composite of operations metrics
							including: Average Daily Census, Adjusted Occupied Beds (AOB), Labor Cost as a Percent
							of Net Patient Revenue and Full Time Equivalent (FTE) per Adjusted Occupied Bed (AOB).
						</p>
					</div>
				);
				break;
			case 3:
				section = (
					<div>
						<p>
							The Strategy Rating is calculated from a composite of strategy metrics including:
							Total Profit Margin, Adjusted Occupied Beds (AOB) and Primary Patient Service Area
							Relative Volume Change.
						</p>
					</div>
				);
				break;
			case 4:
				section = (
					<div>
						<p>
							The Wells' Viability Index shows a provider's level of viability and is determined in
							equal measure by the provider's Financial, Quality, Operations and Strategy ratings.
							The Index is expressed as a percentage, with 0 being low viability and 100 being high
							viability. The Viability Index is named after Dr. Aaron Wells, a highly accomplished
							statistician in the field of population health and the wider healthcare industry.
						</p>
					</div>
				);
				break;
			default:
				break;
		}
		return section;
	}
	render() {
		let id = this.props.modalGuid;
		return (
			<div className="modal" id={id}>
				<div className="modal-dialog-centered modal-lg mx-auto">
					<div className="modal-content bg-primary">
						<div className="modal-header py-2 bg-primary text-white border-0 rounded-0">
							<h4 className="modal-title pl-1 py-0">Peer Group Percentile Ranking</h4>
							<button type="button" className="close" data-dismiss="modal">
								&times;
							</button>
						</div>
						<div className="modal-body bg-light px-5">
							{/* <div style={{ padding: '0px 20px 20px 20px' }}>{this.getSectionText()}</div> */}
							<p className="row text-left">
								The Peer Group Percentile Ranking Index developed by Dr. Aaron Wells, PhD. is a
								multivariate index of 4 functional areas at a facility. Each area takes the most
								recent 3 years of data to create a projection of that area’s future performance
								compared to its peers. (100 indicating a high rank, 1 indicating low).
							</p>
							<span className="row text-left py-2">
								The inputs for each section are a combination of the following:
							</span>
							<h4 className="row text-left strong">Financial</h4>
							<p className="row text-left py-2">
								The Financial Percentile Ranking is calculated from a composite of financial metrics
								including: Total Profit Margin, Days of Net Patient Revenue in Accounts Receivable,
								Days of Net Patient Revenue in Cash and the Ratio of Operating Revenue to Operating
								Cost per Adjusted Occupied Bed (AOB).
							</p>
							<h4 className="row text-left strong">Quality</h4>
							<p className="row text-left py-2">
								The Quality Percentile Ranking is calculated from a composite of quality metrics
								including: Total Performance Score (TPS), Star Quality Rating and Total Quality
								Payment Adjustment, which is the sum of Value-Based and Readmission Reduction
								Payments.
							</p>
							<h4 className="row text-left strong">Operations</h4>
							<p className="row text-left py-2">
								The Operations Percentile Ranking is calculated from a composite of operations
								metrics including: Average Daily Census, Adjusted Occupied Beds (AOB), Labor Cost as
								a Percent of Net Patient Revenue and Full Time Equivalent (FTE) per Adjusted
								Occupied Bed (AOB).
							</p>
							<h4 className="row text-left strong">Strategy</h4>
							<p className="row text-left py-2">
								The Strategy Rating is calculated from a composite of strategy metrics including:
								Total Profit Margin, Adjusted Occupied Beds (AOB) and Primary Patient Service Area
								Relative Volume Change.
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FacilityRatingInfoPopup;
