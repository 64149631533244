import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
class StayInformed extends Component {
	constructor(props, context) {
		super(props, context);
	}

	getValidationState() {
		const length = this.state.value.length;
		if (length > 0) return 'error';
		return null;
	}

	render() {
		return (
			<div className="row mx-0 bg-white py-4">
				<div className="col-lg-9 col-md-10 mx-auto">
					<div className="row mx-0">
						<div className="col-lg-6 col-sm-12 my-5 pt-3 pb-5 px-auto px-0">
							<h3 className="text-primary pb-2 col-lg-10 px-0">Interested in learning more?</h3>
							<p className="pb-5 col-lg-10 px-0" style={{ minHeight: '50px' }}>
								Talk to a HMP Metrics specialist or join the mailing to stay up to date on the
								latest releases and news.
							</p>
							<a
								className="btn btn-primary mr-auto mt-3 col-lg-10 col-md-8"
								style={{
									borderRadius: 0,
									backgroundColor: '#1e90b0',
									borderColor: '#1e90b0',
									display: 'block',
									padding: '10px 0px'
								}}
								href="https://x.email.craincontentstudio.com/ats/show.aspx?cr=868&fm=261"
							>
								Stay Informed
							</a>
						</div>
						<div className="col-lg-6 col-sm-12 my-5 pt-3 pb-5 border-left px-auto px-0">
							<h3 className="text-primary pb-2 col-lg-10 px-0 ml-auto">
								See the platform in action
							</h3>
							<p className="pb-5 col-lg-10 px-0 ml-auto" style={{ minHeight: '50px' }}>
								Request a demo to see how you can put HMP Metrics in action to benefit your
								organization and guide strategic decisions.
							</p>
							<a
								className="btn btn-primary ml-auto mt-3 col-lg-10 col-md-8"
								style={{
									borderRadius: 0,
									backgroundColor: '#1e90b0',
									borderColor: '#1e90b0',
									display: 'block',
									padding: '10px 0px'
								}}
								href="/request-a-quote"
							>
								Request a Demo
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default StayInformed;
