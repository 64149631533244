import React from 'react';
import { Button } from 'react-bootstrap';
import SearchBar from '../../../../core/components/searchBar';
import * as misc from '../../../../lib/misc';

export default class LandingPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageContent: 'default',
			showModal: false,
			showFacilityAnalyzer: false
		};
		this.onSimpleSearch = this.onSimpleSearch.bind(this);
	}

	onSimpleSearch(selectedFacilities) {
		sessionStorage.setItem('facilityList', JSON.stringify(selectedFacilities[0]));
		this.setState({ showFacilityAnalyzer: true });
		window.location.href = '/hospital/summary/' + selectedFacilities[0].id;
	}

	render() {
		return (
			<div className="content-fluid">
				<div className="container-fluid p-0 col-lg-10 col-md-12 px-0">
					<div className="row pt-5 mx-0 px-0">
						<div className="col-md-8 px-0 mx-0">
							<h1 className="display-callout text-primary mb-5 px-0 mx-0">
								Performance at a Glance
							</h1>
						</div>
					</div>
					<div className="row mx-0 px-0">
						<div className="col-md-8 about-callout-copy">
							<div className="row pb-2 mx-0 px-0">
								The Facility Summary tool overviews a provider's capability with a high-level data
								visualization indicating performance. It provides easy-to-read metric analysis,
								allowing for comparison of a provider's size and operating capacity against state
								and national competitors or skim peer-to-peer financial and quality metric rankings.
							</div>
							<div className="row pt-2 mx-0 px-0">
								The summary includes several metrics including long-term debt to total asset ratio,
								operating margins, occupancy and readmission rates, answering simple questions or
								introducing a deeper dive into a provider's operations.
							</div>
						</div>
						<div className="col-md-4" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
							<div className="aboutcallout" style={{ contentAlign: 'center' }}>
								<div className="about-callout-text" style={{ textAlign: 'left' }}>
									Become a subscriber to gain access to all metrics.
								</div>
								<br />
								<div>
									<Button
										block
										bsStyle="primary"
										style={{
											maxWidth: '200px',
											fontSize: '16pt',
											borderRadius: 0,
											backgroundColor: '#1e90b0',
											borderColor: '#1e90b0',
											display: 'block',
											padding: '10px 40px 10px 40px',
											fontWeight: '500',
											marginLeft: '0',
											marginRight: 'auto'
										}}
										href={misc.hmpMetricsHome + '/contact-2/'}
									>
										Subscribe
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				<section
					className="container-fluid py-3"
					style={{ backgroundColor: '#304771', color: 'white' }}
				>
					<div className="media-container-column my-3 pb-5" style={{ padding: '0 10%' }}>
						<div className="row">
							<h3 className="search-bar-md text-primary pt-4 pb-3 d-block d-md-none my-1">
								Try HMP Metrics:
							</h3>
							<h1 className="search-bar-md text-primary pt-4 pb-3 d-none d-md-block">
								Try HMP Metrics:
							</h1>
						</div>
						<div className="row pb-5">
							<div className="col-lg-7 col-md-9 col-sm-10 col-xs-12 px-0 ml-0">
								<SearchBar onSearch={this.onSimpleSearch} allDomains={true} />
								<div className="search-bar-tips pt-1 pb-5 mb-5">
									<text>
										Search for any facility to get a preview. Type in an address, CMS provider
										number, or facility name and get an overview of their financial and operational
										performance.
									</text>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}
