import React from 'react';
import * as GenActions from '../actions/GlobalActions';
import { connect } from 'react-redux';
import LoadingSpinner from '../../tools/loadingSpinner';
import * as misc from '../../lib/misc';
import { Panel } from 'react-bootstrap';

const mapDispatchToProps = dispatch => {
	return {
		LoadWhitePapers: () => dispatch(GenActions.whitePaperList())
	};
};

const mapStateToProps = store => {
	return {
		whitePapers: store.whitePapers
	};
};
const lightText = {
	fontSize: '13px',
	color: 'gray',
	lineHeight: '16px'
};
const AutherName = name => {
	return name ? (
		<span>
			{'Author: ' + name}
			<br />
		</span>
	) : (
		<span />
	);
};
const whitePaper = rec => {
	let paper = 'https://cdn.metrics.modernhealthcare.com/white-papers/' + rec.filename;
	let posted_dt = new Date(rec.date_posted);
	return (
		<li className="">
			<div className="row">
				<Panel
					className="px-3 py-3 label-secondary col-md-10"
					style={{
						cursor: 'pointer',
						textAlign: 'left',
						paddingBottom: '30px'
					}}
				>
					<a className="" href={paper} target="_blank">
						<div className="display-subtitle text-primary py-2">{rec.name}</div>
					</a>
					<p>{rec.description}</p>
					<span style={lightText}>
						Date Posted: {posted_dt.toLocaleDateString('en-US')}
						<br />
						{AutherName(rec.author)}
						<a className="" href={paper} target="_blank">
							Read Paper
						</a>
					</span>
				</Panel>
			</div>
		</li>
	);
};

const body = papers => {
	let paperList = [];

	papers.forEach(p => {
		paperList.push(whitePaper(p));
	});

	return (
		<div className="container-fluid  px-0" style={{ width: '100%', paddingBottom: '10%' }}>
			<div
				className="navbar navbar-default navbar-flex p-0 m-0 page-navbar"
				style={{ height: '56px' }}
			>
				<h3 className="nav pl-3 mx-auto my-auto col-lg-10 col-md-11 col-sm-11 text-white strong">
					White Paper
				</h3>
			</div>
			<div>
				<ul className="pl-3 mx-auto my-auto col-lg-10 col-md-11 col-sm-11 list-unstyled">
					{paperList}
				</ul>
			</div>
		</div>
	);
};

const WhitePapers = class WhitePapers extends React.Component {
	componentDidMount() {
		this.props.LoadWhitePapers();
	}

	render() {
		return this.props.whitePapers.fetched ? body(this.props.whitePapers.data) : <LoadingSpinner />;
	}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WhitePapers);
